import React, { FC, useState } from 'react';
import { Upload } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import {
  MetadataModalBlock,
  InputFieldType,
} from '@app/pages/settings/blocks/manual-recording-upload/metadata-modal-block';

type MetadataFillBlock = {
  show: boolean;
  changeCsvFile(files: File[]): void;
  changeCsvFiles: React.Dispatch<React.SetStateAction<File[]>>;
  csvFiles: File[];
  onInputFieldsChange: (data: InputFieldType[]) => void;
  onValidationErrors: (hasErrors: boolean) => void;
};
export const MetadataFillBlock: FC<MetadataFillBlock> = ({
  show,
  changeCsvFile,
  csvFiles,
  changeCsvFiles,
  onInputFieldsChange,
  onValidationErrors,
}) => {
  const { t } = useTranslation('pages.settings.manual_upload');
  function handleUploadCsvFile(files: File[]) {
    changeCsvFile(files);
  }
  const handleCsvTemplateDownload = () => {
    const url = '/file_metadata.csv';
    window.open(url, '_blank');
  };
  const [metaDataFillModalState, changeMetaDataFillModalState] = useState(false);
  return (
    <div className={`transition-all delay-100 easy-in-out ${!show ? 'invisible' : 'visible'}`}>
      <div className="py-[20px]">
        <h3 className="text-[18px] font-[700] ml-[20px]">{t('add_metadata')}</h3>
      </div>
      {show && (
        <MetadataModalBlock
          onClose={changeMetaDataFillModalState}
          show={metaDataFillModalState}
          onInputFieldsChange={onInputFieldsChange}
          onValidationErrors={onValidationErrors}
        />
      )}
      <div className="text-3color text-[24px] w-full flex justify-center">{t('or')}</div>
      <div className="flex flex-col items-center w-full gap-[10px] justify-center my-[30px]">
        <div>
          <Upload
            loadedFiles={csvFiles}
            changeLoadedFiles={changeCsvFiles}
            onChange={handleUploadCsvFile}
            multiple={false}
            fileAccept=".csv"
            useWithoutDropAreaStyle={true}
          />
        </div>
        <span onClick={handleCsvTemplateDownload} className="text-[14px] text-3color underline">
          {t('csv_template')}
        </span>
      </div>
    </div>
  );
};
