import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '@app/interfaces/common';
import {
  createMetricRequest,
  CreateMetricRequestType,
  editMetricRequest,
  EditMetricRequestData,
  metricListRequest,
  MetricListRequestParams,
  metricSettingsReset,
  projectMetricListRequest,
  recordMetricListRequest,
  RecordMetricListRequestType,
} from '@app/store/reducers/metric-slice';
import {
  audioRecordRequest,
  getRecordsThunk,
  recordsLiveReportsListRequest,
  recordTranscriptionRequest,
  removeRecordRequest,
  singleRecordRequest,
} from '@app/store/reducers/recordsSlice';
import { RecordMetaRequest, recordMetaRequest } from '@app/store/reducers/meta.slice';
import {
  audioChannelSettingsRequest,
  audioChannelsSettingsRequest,
} from '@app/store/reducers/audio-channels.slice';
import { CompositeRecordParams, RecordRequestData } from '@app/interfaces/records';

const mapGlobalStateToProps = (state: RootState) => ({
  recordsState: state.records,
  metricState: state.metric,
  metaState: state.meta,
  audioChannelState: state.audioChannels,
});

const mapGlobalDispatchToProps = (dispatch) => ({
  /* channel settings */
  /**
   * Возвращает настройки всех каналов
   */
  audioChannelsSettingsRequest: () => dispatch(audioChannelsSettingsRequest()),
  /**
   * Возвращает настройки всех каналов
   // * @param params
   */
  audioChannelSettingsRequest: () => dispatch(audioChannelSettingsRequest()),
  /* meta */
  recordMetaRequest: (params: RecordMetaRequest) => dispatch(recordMetaRequest(params)),
  /* records */
  compositeRecordRequest: (params: CompositeRecordParams) =>
    dispatch(getRecordsThunk.compositeRecordThunk(params)),
  getFileRecordThunk: (params: { recordId: string }) =>
    dispatch(getRecordsThunk.getFileRecordThunk({ recordId: params.recordId })),
  removeRecordRequest: (params: { items: Array<string> }) => dispatch(removeRecordRequest(params)),
  recordTranscriptionRequest: (params: { record_id: string }) =>
    dispatch(recordTranscriptionRequest(params)),
  audioRecordRequest: (params: { record_id: string }) => dispatch(audioRecordRequest(params)),
  singleRecordRequest: (params: { record_id: string }) => dispatch(singleRecordRequest(params)),
  recordsLiveReportsListRequest: (data: RecordRequestData) =>
    dispatch(recordsLiveReportsListRequest(data)),
  /* metric */
  // Сбросить настройки метрики
  metricSettingsReset: () => dispatch(metricSettingsReset()),
  recordMetricListRequest: (params: RecordMetricListRequestType) =>
    dispatch(recordMetricListRequest(params)),
  createMetricRequest: (allParams: CreateMetricRequestType) =>
    dispatch(createMetricRequest(allParams)),
  projectMetricListRequest: (data: MetricListRequestParams) =>
    dispatch(projectMetricListRequest(data)),
  metricListRequest: (data: MetricListRequestParams) => dispatch(metricListRequest(data)),
  /* reports */
  editMetricRequest: (data: EditMetricRequestData) => dispatch(editMetricRequest(data)),
});

const connector = connect(mapGlobalStateToProps, mapGlobalDispatchToProps);
export default connector;
export type PropsFromRedux = ConnectedProps<typeof connector>;
