import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  RecognitionRulesSettingsForCreate,
  RecognitionRulesSettingsObj,
  RecognizeQueue,
  BillingObject,
} from '@app/interfaces/settings.type';

import { FilterRangeItem } from '@app/interfaces/filter';

import { AsrModerType } from '@app/interfaces/asr-moder.type';

import { baseQuery } from '../baseQuery';
export const recognitionSettingsApi = createApi({
  reducerPath: 'recognitionSettingsApi',
  tagTypes: ['recognitionSettings', 'recognitionDefaultModel', 'recognitionRuleQueue'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getRecognitionsList: build.query<RecognitionRulesSettingsObj[], void>({
      query: () => ({
        url: serverRoutes.recognition_rules,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ recognize_rule_id }) => ({
                type: 'recognitionSettings' as const,
                recognize_rule_id,
              })),
              'recognitionSettings',
            ]
          : ['recognitionSettings'],
    }),
    addRecognition: build.mutation<
      RecognitionRulesSettingsForCreate,
      RecognitionRulesSettingsForCreate
    >({
      query: (body) => ({
        url: serverRoutes.recognition_rule,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['recognitionSettings'],
    }),
    deleteRecognition: build.mutation({
      query: (body: string) => ({
        url: serverRoutes.recognition_rule,
        method: 'DELETE',
        params: { id: body },
      }),
      invalidatesTags: ['recognitionSettings'],
    }),
    editRecognitionRule: build.mutation<
      RecognitionRulesSettingsObj,
      { body: RecognitionRulesSettingsObj; id: string }
    >({
      query: ({ body, id }) => ({
        url: serverRoutes.recognition_rule,
        method: 'PUT',
        params: { id: id },
        body,
      }),
      invalidatesTags: ['recognitionSettings'],
    }),
    getRecognitionRule: build.query<RecognitionRulesSettingsObj, string>({
      query: (body) => ({
        url: serverRoutes.recognition_rule,
        params: { id: body },
      }),
    }),
    getRecognitionDefaultModel: build.query<AsrModerType, void>({
      query: () => ({
        url: serverRoutes.recognition_rule_default,
      }),
    }),
    changeRecognitionDefaultModel: build.mutation<AsrModerType, string>({
      query: (id) => ({
        url: serverRoutes.recognition_rule_default,
        method: 'POST',
        body: { id: id },
        invalidatesTags: ['recognitionDefaultModel'],
      }),
    }),
    recognitionRuleQueue: build.query<RecognizeQueue, void>({
      query: () => ({
        url: serverRoutes.recognition_rule_count,
      }),
      providesTags: ['recognitionRuleQueue'],
    }),
    submitRecordForRecognition: build.mutation({
      query: ({ range, id }: { range: FilterRangeItem; id: string }) => ({
        url: serverRoutes.recognition_rule_do,
        method: 'PUT',
        params: { id: id },
        body: { range: range },
      }),
      invalidatesTags: ['recognitionRuleQueue'],
    }),
    getBillingObject: build.query<BillingObject, void>({
      query: () => ({
        url: serverRoutes.billing,
      }),
    }),
  }),
});

export const {
  useGetRecognitionsListQuery,
  useAddRecognitionMutation,
  useDeleteRecognitionMutation,
  useLazyGetRecognitionRuleQuery,
  useEditRecognitionRuleMutation,
  useSubmitRecordForRecognitionMutation,
  useGetRecognitionDefaultModelQuery,
  useChangeRecognitionDefaultModelMutation,
  useRecognitionRuleQueueQuery,
  useGetBillingObjectQuery,
} = recognitionSettingsApi;
