import React, { FC, useState } from 'react';
import { VolumeMaxIcon, VolumeMinIcon } from '@icons';

type AudioVolumeRangeProps = {
  onChange: (volume: number) => void;
};
const AudioVolumeRange: FC<AudioVolumeRangeProps> = (props) => {
  const { onChange } = props;
  const volumeStep = 0.1;
  const [audioVolume, setAudioVolume] = useState<number>(1);

  function handleChangeVolume(newVolume: number) {
    setAudioVolume(newVolume);
    onChange?.(newVolume);
  }
  return (
    <div className="inline-flex items-center">
      <div onClick={handleChangeVolume.bind(null, audioVolume > 0 ? audioVolume - volumeStep : 0)}>
        <VolumeMinIcon
          size={16}
          className="text-3color mr-[3px] select-none cursor-pointer hover:opacity-80"
        />
      </div>
      <div className="relative w-[65px] rounded-full border-gray-200 h-[4px] bg-[#CCC]">
        <div
          className="bg-action absolute top-[0px] rounded-lg left-0 h-[4px] z-0 w-3"
          style={{ width: `${audioVolume / (Number(1) / 100)}%` }}
        />
        <input
          id="small-range"
          type="range"
          min={0}
          max={1}
          step={0.1}
          value={audioVolume}
          onChange={(event) => handleChangeVolume(Number(event.target.value))}
          className="w-full h-1 bg-transparent absolute appearance-none rounded-lg cursor-pointer range-sm range-action z-10"
        />
      </div>
      <div onClick={handleChangeVolume.bind(null, audioVolume < 1 ? audioVolume + volumeStep : 1)}>
        <VolumeMaxIcon
          size={25}
          className="text-3color ml-[3px] select-none cursor-pointer hover:opacity-80"
        />
      </div>
    </div>
  );
};

export default AudioVolumeRange;
