import React, { FC } from 'react';
import RubberTable from '@app/components/rubber-table';
import { TableColumn, TableItem } from '@app/components/rubber-table/table.types';
import {
  MetricTriggerBulkRequestType,
  MetricTriggerBulkResponseType,
} from '@app/interfaces/metric-trigger.type';
import Paginator from '@app/components/ui/paginator';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { FilterItemNotOnlyArray } from '@app/interfaces/filter';
import { getFrontendDate } from '@app/utils/helpers';
import { MetricListArrType } from '@app/interfaces/pages-types/anatylics-metric.type';
import useTranslation from '@app/hooks/use-translation';
import { TableFilter } from '@app/components/table/table.type';
import { CircleButton } from '@ui';
import { GroupSharedStatus } from '@app/interfaces/analytics';
import { useOutletContext } from 'react-router';

import { AnalyticPageOutlet } from '../../single-analytic.page';

type TriggersTableBlockPropsType = {
  data: MetricTriggerBulkResponseType | undefined;
  changeRequestData: React.Dispatch<React.SetStateAction<MetricTriggerBulkRequestType>>;
  requestData: MetricTriggerBulkRequestType;
  onTrashIconClick?(trigger_id: string): void;
  onRecipientsClick?(trigger_id: string): void;
  onSettingsClick?(trigger_id: string): void;
  metricList: MetricListArrType[] | undefined;
};
const TriggersTableBlock: FC<TriggersTableBlockPropsType> = (props) => {
  const {
    data,
    requestData,
    onTrashIconClick,
    onSettingsClick,
    onRecipientsClick,
    changeRequestData,
    metricList,
  } = props;
  const { project } = useOutletContext<AnalyticPageOutlet>();

  const { t } = useTranslation('pages.triggers.table');
  const trigger_cant_be_edited = !project?.owner && project?.shared === GroupSharedStatus.VIEW;
  const columns: TableColumn[] = [
    { index: 'subject', filter: true, title: t('subject'), minWidth: 320 },
    { index: 'recipients', title: t('recipients') },
    { index: 'preset', title: t('preset') },
    { index: 'confirmed', title: t('confirmed') },
    { index: 'action', title: t('actions'), divider: 'left', maxWidth: 120 },
  ];
  function handleFilter(filter: TableFilter) {
    const val = Object.entries(filter);
    const sortDesc = +val?.[0]?.[1] === 1;
    const sortBy = (val?.[0]?.[0] || undefined) as unknown as
      | 'subject'
      | 'dt_add'
      | 'enabled'
      | undefined;
    changeRequestData((prev) => ({
      ...prev,
      sortBy: sortBy,
      sortDesc: sortDesc,
    }));
  }
  const getAliasesNameById = (id: string) => {
    return metricList?.find((metric) => metric.metric_id === id)?.name || 'N/A';
  };
  const getAliasesTypeById = (id: string) => {
    return metricList?.find((metric) => metric.metric_id === id)?.result_value_type || id;
  };
  const getFilterValues = (filter: FilterItemNotOnlyArray) => {
    if (Array.isArray(filter.value)) {
      return filter?.value.map((val, index) => {
        return (
          <span className="mr-[6px] last:mr-[0px]" key={index}>
            {val}
          </span>
        );
      });
    } else if (getAliasesTypeById(filter.id) === 'datetime') {
      return getFrontendDate(filter.value as string, 'dd.MM.yyyy HH:mm');
    } else {
      return filter.value;
    }
  };
  const tableData: TableItem[] =
    data?.project_triggers.map((trigger) => ({
      ...trigger,
      confirmed: (
        <div className={`${trigger.enabled ? 'text-basic_green' : 'text-basic_red'}`}>
          {t(String(trigger.enabled))}
        </div>
      ),
      action: (
        <div className="flex items-center gap-[5px] h-full">
          <CircleButton
            onClick={() => onSettingsClick?.(trigger.trigger_task_id)}
            className="cursor-pointer hover:text-action"
            size={17}
            icon={'SettingIcon'}
            hintTitle={t('settings')}
          ></CircleButton>
          <CircleButton
            onClick={() => onRecipientsClick?.(trigger.trigger_task_id)}
            className="cursor-pointer hover:text-action"
            icon={'UserIcon'}
            size={17}
            hintTitle={t('mailing_list_recipients')}
          ></CircleButton>
          <CircleButton
            disable={trigger_cant_be_edited}
            icon={'TrashIcon'}
            onClick={() => onTrashIconClick?.(trigger.trigger_task_id)}
            hintTitle={t('delete')}
            size={18}
            className="cursor-pointer text-basic_red"
          ></CircleButton>
        </div>
      ),
      preset: (
        <div className="w-full">
          <div className="text-0color font-[400] tracking-tight text-[14px] leading-[110%] w-fit flex truncate">
            {trigger.preset.filter.map((filter, index) => {
              return (
                <span
                  key={filter.id + index}
                  className="mr-[16px] py-2 last:mr-[0px] block w-full grow "
                >
                  <span>{getAliasesNameById(filter.id)}</span>
                  <span className="mr-[6px] ml-[6px] w-[19px] h-full border-[1px] border-solid border-[#A6ABBA] rounded-[50%] p-[0px_4px_1px_5px]">
                    {filter.condition}
                  </span>

                  {filter.value[0] !== '' && (
                    <span className="bg-basic_app_bg p-[2px_8px_3px] rounded-[4px] text-2color w-full">
                      {getFilterValues(filter)}
                    </span>
                  )}
                </span>
              );
            })}
          </div>
        </div>
      ),
    })) || [];

  function changePage({ offset, limit }: PaginationResponse) {
    changeRequestData((prev) => ({ ...prev, offset: offset, limit: limit }));
  }

  return (
    <div className="flex flex-col gap-[10px]">
      <RubberTable onFilter={handleFilter} columns={columns} dataSource={tableData} />
      <Paginator
        page={Math.ceil(Number(requestData.offset) / Number(requestData.limit)) + 1}
        count={data?.total || 0}
        limit={requestData.limit}
        onChange={changePage}
      />
    </div>
  );
};

export default TriggersTableBlock;
