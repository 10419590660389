import React, { FC, useMemo } from 'react';

import { CircleButton, TooltipWrapper } from '@ui';
import { KnownMetricsCases } from '@app/interfaces/records';
import cn from 'classnames';
import { MetricEditValue } from '@app/components';
import { SettingsMetricType } from '@app/interfaces/pages-types/anatylics-metric.type';
import { useTranslation } from '@app/hooks/use-translation';
import { v4 } from 'uuid';

export type MetricItemProps = {
  projectID: string;
  recordID: string;
  isActive?: boolean;
  settings?: SettingsMetricType;
  currentTemplate: 'chat' | 'voice';
  valueMaxWidth?: number;
  data: KnownMetricsCases[string]['known_metric'] & { result_value_type?: string };
  onChangeMetricItem?: ({ caseName, value }: { caseName: string; value: string | number }) => void;
};

const MetricItem: FC<MetricItemProps> = (props) => {
  const { t } = useTranslation('components.audio');
  const {
    data,
    settings,
    valueMaxWidth,
    isActive = false,
    onChangeMetricItem,
    recordID,
    projectID,
    currentTemplate,
  } = props;
  const { name, value, metric_id, color, type: metricType } = data;

  const metricStyle = function (color?: string) {
    return cn(
      `h-[20px] ${
        color ? `p-1.5 rounded-[3px]` : 'bg-transparent text-2color'
      } text-[14px]  flex justify-center truncate items-center w-full `,
    );
  };
  const hexToRgb = useMemo(() => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }, [color]);
  const currentNoArrayData = Array.isArray(data.value) ? data.value[0] : data.value;
  const renderMetricTypeContent = (metricType === 'c_find-words' ||
    metricType === 'c_spell-check' ||
    metricType === 'find-words') && (
    <div className="flex items-center justify-end gap-x-[5px] w-fit h-full">
      <div className="flex pt-[2px] w-[20px] h-[20px] items-center justify-center">
        <TooltipWrapper content={t('popup_hints.go_to')} id={'1'}>
          <CircleButton
            icon="SearchInTextIcon"
            size={21}
            className="text-3color"
            onClick={onChangeMetricItem?.bind(null, {
              caseName: `${metricType}_once`,
              value: metric_id,
            })}
          />
        </TooltipWrapper>
      </div>
      <div
        className={cn(
          isActive && 'bg-speech_analitics_light',
          'rounded-[6px] flex pt-[2px] w-[20px] h-[20px] items-center justify-center',
        )}
      >
        <TooltipWrapper content={t('popup_hints.show_close')} id={'2'}>
          <CircleButton
            onClick={onChangeMetricItem?.bind(null, { caseName: metricType, value: metric_id })}
            icon="SplitedIcon"
            size={18}
            className="text-speech_analitics"
          />
        </TooltipWrapper>
      </div>
    </div>
  );

  const valueTypeRender =
    data.type === 'tags' ? (
      <div className="flex justify-end w-full">
        {settings && (
          <MetricEditValue
            currentTemplate={currentTemplate}
            withPadding={false}
            valueMaxWidth={valueMaxWidth}
            settings={settings}
            project_id={projectID}
            metric_id={data.metric_id}
            valueType={data.result_value_type}
            record_id={recordID}
            currentValue={currentNoArrayData}
          />
        )}
      </div>
    ) : typeof value === 'string' ? (
      <div className="text-[13px] col-span-2 text-2color h-full w-full">
        <div className="w-min float-right">
          <TooltipWrapper content={String(value)} id={String(value) + v4()} className="">
            <div className="max-w-[250px] truncate">{value}</div>
          </TooltipWrapper>
        </div>
      </div>
    ) : (
      <div className={cn(metricType !== 'find-words' && 'col-span-2', 'w-full items-center')}>
        <div className="flex w-fit h-full items-center float-right">
          <div className="relative mr-2 w-full">
            <TooltipWrapper className="w-full" content={String(value)} id={String(value) + v4()}>
              <div
                className={metricStyle(color)}
                style={
                  color
                    ? {
                        color: `#${color}`,
                        background: `rgba(${hexToRgb?.r}, ${hexToRgb?.g}, ${hexToRgb?.b}, 0.1)`,
                      }
                    : {}
                }
              >
                {typeof value === 'object' ? String(value) : value}
              </div>
            </TooltipWrapper>
          </div>
        </div>
      </div>
    );

  return (
    <div className="grid grid-cols-[1fr_50px_250px] gap-2 px-[26px] items-center selected-none  hover:bg-basic_app_bg rounded-[3px] py-[5px] transition w-full content-center ">
      <div className="text-1color text-[14px] truncate w-[100%]">
        <TooltipWrapper content={String(name)} id={String(name)}>
          {name}
        </TooltipWrapper>
      </div>
      <div></div>
      <div className="flex items-center">
        {valueTypeRender}
        {renderMetricTypeContent}
      </div>
    </div>
  );
};
export default MetricItem;
