import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const LoadingIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ margin: 'auto', background: 'none' }}
        width={size}
        height={size}
        display="block"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 100 100"
      >
        <circle cx="84" cy="50" r="10" fill="#8ab8ff">
          <animate
            attributeName="r"
            begin="0s"
            calcMode="spline"
            dur="0.25s"
            keySplines="0 0.5 0.5 1"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="5;0"
          ></animate>
          <animate
            attributeName="fill"
            begin="0s"
            calcMode="discrete"
            dur="1s"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="#8ab8ff;#ffffff;#f1ccff;#ffffff;#8ab8ff"
          ></animate>
        </circle>
        <circle cx="16" cy="50" r="10" fill="#8ab8ff">
          <animate
            attributeName="r"
            begin="0s"
            calcMode="spline"
            dur="1s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="0;0;5;5;5"
          ></animate>
          <animate
            attributeName="cx"
            begin="0s"
            calcMode="spline"
            dur="1s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="16;16;16;50;84"
          ></animate>
        </circle>
        <circle cx="50" cy="50" r="10" fill="#fff">
          <animate
            attributeName="r"
            begin="-0.25s"
            calcMode="spline"
            dur="1s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="0;0;5;5;5"
          ></animate>
          <animate
            attributeName="cx"
            begin="-0.25s"
            calcMode="spline"
            dur="1s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="16;16;16;50;84"
          ></animate>
        </circle>
        <circle cx="84" cy="50" r="10" fill="#f1ccff">
          <animate
            attributeName="r"
            begin="-0.5s"
            calcMode="spline"
            dur="1s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="0;0;5;5;5"
          ></animate>
          <animate
            attributeName="cx"
            begin="-0.5s"
            calcMode="spline"
            dur="1s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="16;16;16;50;84"
          ></animate>
        </circle>
        <circle cx="16" cy="50" r="10" fill="#fff">
          <animate
            attributeName="r"
            begin="-0.75s"
            calcMode="spline"
            dur="1s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="0;0;5;5;5"
          ></animate>
          <animate
            attributeName="cx"
            begin="-0.75s"
            calcMode="spline"
            dur="1s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="16;16;16;50;84"
          ></animate>
        </circle>
      </svg>
    </div>
  );
};

export default LoadingIcon;
