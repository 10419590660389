import { usePermissionCheck } from '@app/hooks/use-permission';
import { PermissionsType } from '@app/interfaces/permissions.type';
import React, { FC, ReactNode } from 'react';
type PermissionAccessWrapperProps = {
  children: ReactNode;
};
const PermissionAccessWrapper: FC<PermissionAccessWrapperProps & PermissionsType> = (props) => {
  const { children, tag, permissions } = props;
  const isPermission = usePermissionCheck({ tag, permissions });
  if (isPermission) return <></>;
  return <>{children}</>;
};
export default PermissionAccessWrapper;
