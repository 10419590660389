import React, { FC } from 'react';

import { IconType } from '../icon.type';

const FlameCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_813_277)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM14.0264 18.752C16.5312 18.752 18.1836 17.0557 18.1836 14.5332C18.1836 10.3232 14.6064 8.57422 12.1279 8.57422C11.6533 8.57422 11.3633 8.75879 11.3633 9.08398C11.3633 9.21582 11.416 9.34766 11.5215 9.4707C12.084 10.1562 12.6201 10.9033 12.6289 11.7998C12.6289 11.9053 12.6201 11.9932 12.5938 12.0898C12.3301 11.5186 11.8643 11.1846 11.4512 11.1846C11.2402 11.1846 11.0996 11.334 11.0996 11.5713C11.0996 11.7031 11.126 11.9668 11.126 12.1953C11.126 13.2588 10.3174 13.8125 10.3174 15.5176C10.3174 17.4512 11.7939 18.752 14.0264 18.752ZM14.1406 17.3281C13.2881 17.3281 12.6992 16.8096 12.6992 16.0273C12.6992 15.2188 13.2793 14.9463 13.3496 14.4014C13.3584 14.3398 13.4023 14.3223 13.4463 14.3574C13.6572 14.542 13.7803 14.7793 13.9033 15.0605C14.1494 14.7002 14.2373 14.0234 14.1494 13.3115C14.1406 13.2324 14.1846 13.2148 14.2373 13.2324C15.2568 13.6982 15.7754 14.6914 15.7754 15.6143C15.7754 16.5371 15.2305 17.3281 14.1406 17.3281Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_813_277">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default FlameCircleIcon;
