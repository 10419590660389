import React, { FC, useRef, useState } from 'react';
import cn from 'classnames';

import { ChevronDownIcon, TriangleBottomIcon } from '@app/components/ui/icons/icons-list';
import {
  popoverIconClassName,
  popoverMenuClassName,
} from '@app/components/ui/popover/popover.styles';
import { DropMenuItemColor } from '@app/components/ui/drop-menu/drop-menu';
import Icon from '@app/components/ui/icons';

import useOnClickOutside from '@app/hooks/use-onclick-outside';

import { v4 } from 'uuid';

import { PermissionAccessWrapper } from '@ui';

import { IconNameType } from '../icons/icon-name.type';

import TooltipWrapper from '../tooltip-wrapper';

import { PopoverMenuItem, PopoverPosition } from './popover.type';
import {
  popoverItemWrapperClassName,
  popoverListItemClassName,
  popoverTriangleClassName,
} from './popover.styles';

type PopoverProps = {
  label?: string;
  icon?: IconNameType;
  sizeIcon?: number;
  position?: PopoverPosition;
  menu: Array<PopoverMenuItem>;
  onClick?: (key: string) => void;
  size?: number;
  hintTitle?: string;
  disabled?: boolean;
};

const Popover: FC<PopoverProps> = (props) => {
  const {
    label,
    position = 'start',
    menu,
    onClick,
    icon,
    sizeIcon,
    size,
    hintTitle,
    disabled,
  } = props;
  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);
  const dropMenuRef = useRef(null);
  const popoverPosition: Record<PopoverPosition, string> = {
    end: 'right-[18px]',
    start: 'left-[calc(50%-12px)]',
  };
  const menuColors: Record<DropMenuItemColor, string> = {
    primary: 'text-1color',
    danger: 'text-basic_red',
  };
  const popoverButtonClassName = cn(
    'rounded-[10px]',
    'hover:opacity-90',
    'transition',
    'active:scale-[99%] shadow-lg',
    'border-[1px]',
    disabled ? 'border-bg-b-light' : 'border-action',
    'truncate',
    disabled ? 'bg-b-light' : 'bg-action',
    'inline-flex',
    'items-center',
    'justify-between',
  );

  function handleCLickOnMenu(key: string) {
    setIsOpenPopover(false);
    onClick?.(key);
  }

  const renderMenu = menu.map(
    ({ title, key, icon, content, color = menuColors.primary, disabled = false, permission }) => (
      <PermissionAccessWrapper
        key={key}
        permissions={permission?.permissions || 'wtf'}
        tag={permission?.tag}
      >
        <li
          onClick={() => !disabled && handleCLickOnMenu(key)}
          className={popoverListItemClassName}
        >
          {content ? (
            <div>{content}</div>
          ) : (
            <div
              className={popoverItemWrapperClassName({
                color: menuColors[color],
                isNotDanger: color !== 'danger',
                disabled,
              })}
            >
              {icon && (
                <div className="mb-[-2px] mr-[9px]">
                  <Icon
                    size={14}
                    name={icon}
                    className={cn(disabled ? 'text-3color' : menuColors[color])}
                  />
                </div>
              )}
              {title}
            </div>
          )}
        </li>
      </PermissionAccessWrapper>
    ),
  );

  function handleToggleShowPopover() {
    setIsOpenPopover((prevState) => !prevState);
  }

  useOnClickOutside(dropMenuRef, () => setIsOpenPopover(false));

  return (
    <div className="relative w-fit h-[34px]" ref={dropMenuRef}>
      <TooltipWrapper content={hintTitle} id={hintTitle + v4()}>
        <button
          className={popoverButtonClassName}
          onClick={handleToggleShowPopover}
          disabled={disabled}
        >
          <div
            className={`${
              disabled && '!text-4color'
            } pl-[16px] pr-[8px] py-[5px] text-white text-[15px] font-bold`}
          >
            {label}
          </div>
          {icon && <Icon className="text-[#fff] pr-[2px]" name={icon} size={sizeIcon} />}
          <div className={popoverIconClassName}>
            <ChevronDownIcon
              size={11}
              className={cn(isOpenPopover && 'rotate-180', 'transition', 'text-white')}
            />
          </div>
        </button>
      </TooltipWrapper>
      <div
        style={{
          width: `${size}px`,
        }}
        className={popoverMenuClassName({
          isOpenPopover,
          position,
        })}
      >
        <div className={popoverTriangleClassName(popoverPosition[position])}>
          <TriangleBottomIcon size={13} className="-rotate-90 text-white shadow-lg" />
        </div>
        {renderMenu}
      </div>
    </div>
  );
};

export default Popover;
