import React, { createContext, useContext, useState } from 'react';
import { AuthResponse } from '@app/interfaces/auth';
import { loginSession, logoutSession } from '@app/api/common.api';

interface AuthContextType {
  isAuth: boolean;
  setIsAuth: (boolean) => void;
  login: (session: AuthResponse) => void;
  logout: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const authContext = createContext<AuthContextType>(null!);

export const useAuthContext = () => useContext(authContext);

const useProvideAuth = (): AuthContextType => {
  const [isAuth, setIsAuth] = useState(!!localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
  return {
    isAuth,
    setIsAuth,
    login: (authData: AuthResponse) => {
      loginSession(authData);
    },
    logout: () => {
      setIsAuth(false);
      logoutSession();
    },
  };
};

const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export default AuthProvider;
