import React, { FC } from 'react';

import { IconType } from '../icon.type';

const LockDisplayIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_609_144)">
          <path
            d="M5.51855 19.8154H11.126V21.4062H11.0908C10.6514 21.4062 10.291 21.7666 10.291 22.2061C10.291 22.6367 10.6514 22.9971 11.0908 22.9971H16.9004C17.3398 22.9971 17.7002 22.6367 17.7002 22.2061C17.7002 21.7666 17.3398 21.4062 16.9004 21.4062H16.8652V19.8154H22.4639C23.835 19.8154 24.6611 18.998 24.6611 17.627V7.03613C24.6611 5.66504 23.835 4.84766 22.4639 4.84766H5.51855C4.15625 4.84766 3.33008 5.66504 3.33008 7.03613V17.627C3.33008 18.998 4.15625 19.8154 5.51855 19.8154ZM5.62402 18.1367C5.24609 18.1367 5.01758 17.8994 5.01758 17.5303V7.13281C5.01758 6.76367 5.24609 6.52637 5.62402 6.52637H22.3672C22.7451 6.52637 22.9736 6.76367 22.9736 7.13281V17.5303C22.9736 17.8994 22.7451 18.1367 22.3672 18.1367H5.62402ZM12.4971 15.333H15.5117C15.9951 15.333 16.2324 15.0781 16.2324 14.5684V12.2656C16.2324 11.8613 16.0742 11.624 15.7754 11.5273V10.9473C15.7754 9.76074 15.0635 8.95215 14 8.95215C12.9453 8.95215 12.2246 9.76074 12.2246 10.9473V11.5449C11.9258 11.624 11.7676 11.8613 11.7676 12.2656V14.5684C11.7676 15.0781 12.0137 15.333 12.4971 15.333ZM13.1475 11.501V10.8857C13.1475 10.2529 13.4814 9.84863 14 9.84863C14.5186 9.84863 14.8613 10.2529 14.8613 10.8857V11.501H13.1475Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_609_144">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default LockDisplayIcon;
