import React, { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import useTranslation from '@app/hooks/use-translation';
import { Loading } from '@ui';
import { NotFoundedPage } from '@app/pages';
import { ReportOutletContext } from '@app/pages/reports/report.outlet';
import { useGetLiveReportSettingsQuery } from '@app/store/api/live-reports.api';
import { useGetProjectMetricsListQuery } from '@app/store/api/metrics.api';

const ProjectReportsOutlet = () => {
  const { t } = useTranslation('pages.reports');
  const { report_id } = useParams();
  const [chartModalIsOpen, changeIsOpenChartModal] = useState<boolean>(false);
  const { currentData: liveReport, isLoading: liveReportLoading } = useGetLiveReportSettingsQuery(
    { id: report_id || '' },
    { skip: !report_id },
  );
  const { data: metricList, isLoading: projectMetricLoading } = useGetProjectMetricsListQuery(
    {
      project_id: liveReport?.project.project_id || '',
    },
    { skip: !liveReport?.project.project_id },
  );
  if (liveReportLoading) return <Loading />;
  if (!liveReport && !projectMetricLoading)
    return <NotFoundedPage text={t('not_found')} icon="BrokenDocIcon" />;

  return (
    <div>
      <Outlet
        context={
          {
            projectMetrics: metricList,
            report: liveReport,
            chartModalIsOpen,
            changeIsOpenChartModal,
          } as unknown as ReportOutletContext
        }
      />
    </div>
  );
};

export default ProjectReportsOutlet;
