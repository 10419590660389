function useTimeLengthHumanize(format = 'H:M:S') {
  function zeroBefore(timeInterval: number) {
    return timeInterval < 10 ? String(timeInterval).padStart(2, '0') : String(timeInterval);
  }

  return (durationInSecond: number) => {
    const hours = Math.floor(durationInSecond / 3600);
    const minutes = Math.floor((durationInSecond - hours * 3600) / 60);
    const seconds = Math.floor(durationInSecond - hours * 3600 - minutes * 60);
    return format
      .replace('H', zeroBefore(hours))
      .replace('M', zeroBefore(minutes))
      .replace('S', zeroBefore(seconds));
  };
}
export default useTimeLengthHumanize;
