import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  IntegrationAccessKeyCreateObject,
  IntegrationAccessKeyObject,
} from '@app/interfaces/settings.type';

import { baseQuery } from '../baseQuery';
export const integrationSettingsApi = createApi({
  reducerPath: 'integrationSettingsApi',
  tagTypes: ['integration'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getIntegrationsAccessKeysList: build.query<IntegrationAccessKeyObject[], void>({
      query: () => ({
        url: serverRoutes.integrationsAccessKeys,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ access_key }) => ({
                type: 'integration' as const,
                access_key,
              })),
              'integration',
            ]
          : ['integration'],
    }),
    getIntegrationsAccessKey: build.query<IntegrationAccessKeyObject, { access_key: string }>({
      query: (params) => ({
        url: serverRoutes.integrationsAccessKey,
        params,
      }),
    }),
    createIntegrationsAccessKey: build.mutation<IntegrationAccessKeyCreateObject, { name: string }>(
      {
        query: (body) => ({
          url: serverRoutes.integrationsAccessKey,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['integration'],
      },
    ),
    editIntegrationsAccessKey: build.mutation<
      IntegrationAccessKeyObject,
      { body: { name: string }; params: { access_key: string } }
    >({
      query: ({ body, params }) => ({
        url: serverRoutes.integrationsAccessKey,
        method: 'PUT',
        body,
        params,
      }),
      invalidatesTags: ['integration'],
    }),
    deleteIntegrationsAccessKey: build.mutation({
      query: (params: { access_key: string }) => ({
        url: serverRoutes.integrationsAccessKey,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['integration'],
    }),
  }),
});

export const {
  useGetIntegrationsAccessKeysListQuery,
  useCreateIntegrationsAccessKeyMutation,
  useEditIntegrationsAccessKeyMutation,
  useDeleteIntegrationsAccessKeyMutation,
  useLazyGetIntegrationsAccessKeyQuery,
} = integrationSettingsApi;
