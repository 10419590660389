import { createSlice } from '@reduxjs/toolkit';
import { httpRequest } from '@app/store/middleware/rest';
import serverRoutes from '@app/utils/server-routes';

const initialState: AudioChannelState = {
  audioChannel: null,
  audioChannels: [],
  isPending: false,
  error: null,
};

const audioChannelsSlice = createSlice({
  name: 'audioChannelSlice',
  initialState,
  reducers: {
    audioChannelSettingsResponse: (state, { payload }: { payload: AudioChannel }) => {
      state.audioChannel = payload;
    },
    audioChannelsSettingsResponse: (state, { payload }: { payload: Array<AudioChannel> }) => {
      state.audioChannels = payload;
    },
    audioChannelPending: (state, { payload }) => {
      state.isPending = payload;
    },
    audioChannelError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export const {
  audioChannelSettingsResponse,
  audioChannelPending,
  audioChannelError,
  audioChannelsSettingsResponse,
} = audioChannelsSlice.actions;

export default audioChannelsSlice.reducer;

export const audioChannelSettingsRequest = () =>
  httpRequest({
    url: serverRoutes.audioChannel,
    method: 'GET',
    onSuccess: audioChannelSettingsResponse.type,
    onLoading: audioChannelPending.type,
    onError: audioChannelError.type,
  });

export const audioChannelsSettingsRequest = () =>
  httpRequest({
    url: serverRoutes.audioChannels,
    method: 'GET',
    onSuccess: audioChannelsSettingsResponse.type,
    onLoading: audioChannelPending.type,
    onError: audioChannelError.type,
  });

export type AudioChannel = {
  settings_channels_id: string;
  name: string;
  channel: number;
  icon: string;
};

export type AudioChannelState = {
  audioChannel: AudioChannel | null;
  audioChannels: Array<AudioChannel>;
  isPending: boolean;
  error: string | Error | null;
};

export type AudioChannelRequestParams = { id: string };
