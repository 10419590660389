import { Button, Input, Select } from '@app/components/ui';
import useTranslation from '@app/hooks/use-translation';
import { UserRoleList } from '@app/interfaces/users';
import React, { FC, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { emailUserRegexp, upperCaseRegexp, lowerCaseRegexp, numberRegexp } from '@app/utils/regexp';

type CommonSettingsAddFormType = {
  userRole: UserRoleList;
  onSubmit: (data: {
    username: string;
    role_id: string;
    login: string;
    password?: string;
    enable?: boolean;
  }) => void;
  isEditMode: boolean;
  currentUserData: any;
};

type FormData = {
  username: string;
  role_id: string;
  login: string;
  password?: string;
  enable?: boolean;
};

export const UserFormFields: FC<CommonSettingsAddFormType> = ({
  userRole,
  onSubmit,
  isEditMode,
  currentUserData,
}) => {
  const { t } = useTranslation('pages.usersPage');
  const [showPasswordMessage, setShowPasswordMessage] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isValid },
    control,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues:
      isEditMode && currentUserData
        ? {
            username: currentUserData.username,
            login: currentUserData.login,
            role_id: currentUserData.role_id,
            enable: currentUserData.enable,
          }
        : undefined,
  });

  const getAsrModelOptionsList = () => {
    return userRole?.roles.map((role) => ({
      title: role.description,
      value: role.role_id,
    }));
  };

  const handleFormSubmit = (data: FormData) => {
    onSubmit({
      ...data,
      password: data.password,
    });
  };

  const generateRandomPassword = () => {
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const digitChars = '0123456789';
    const allChars = uppercaseChars + lowercaseChars + digitChars;

    let password = '';
    password += uppercaseChars.charAt(Math.floor(Math.random() * uppercaseChars.length));
    password += lowercaseChars.charAt(Math.floor(Math.random() * lowercaseChars.length));
    password += digitChars.charAt(Math.floor(Math.random() * digitChars.length));
    for (let i = 3; i < 12; i++) {
      password += allChars.charAt(Math.floor(Math.random() * allChars.length));
    }
    password = password
      .split('')
      .sort(() => 0.5 - Math.random())
      .join('');
    return password;
  };

  const handleGeneratePassword = () => {
    const generatedPassword = generateRandomPassword();
    setValue('password', generatedPassword, { shouldValidate: true });
    setShowPasswordMessage(true);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex flex-col gap-[23px]">
        {/* Username Field */}
        <div className="flex justify-between items-center">
          <h1>{t('modal.username')}</h1>
          <div className="w-full max-w-[380px]">
            <Input
              {...register('username', { required: t('errors.required') })}
              placeholder={t('modal.name')}
              error={errors.username?.message}
              name="username"
            />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <h1>{t('modal.login')}</h1>
          <div className="w-full max-w-[380px]">
            <Input
              {...register('login', {
                required: t('errors.required'),
                maxLength: {
                  value: 64,
                  message: t('errors.max_length'),
                },
                validate: {
                  isEmail: (value: string | undefined) =>
                    (value && emailUserRegexp.test(value)) || t('errors.invalid_email'),
                },
              })}
              placeholder={t('modal.login')}
              error={errors.login?.message}
              name="login"
            />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <h1>{t('modal.role')}</h1>
          <div className="w-full max-w-[380px]">
            <Controller
              name="role_id"
              control={control}
              rules={{ required: t('errors.required') }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  placeholder={t('modal.select_role')}
                  options={getAsrModelOptionsList() || []}
                  dropWidth={374}
                  error={error?.message}
                  onChange={(value) => {
                    field.onChange(value);
                    setValue('role_id', value as string);
                  }}
                  defaultValue={field.value}
                />
              )}
            />
          </div>
        </div>
        {isEditMode && (
          <div className="flex justify-between items-center">
            <h1>{t('modal.select_enable')}</h1>
            <div className="w-full max-w-[380px]">
              <Controller
                name="enable"
                control={control}
                defaultValue={currentUserData?.enable ?? false}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    placeholder={t('modal.select_enable')}
                    options={[
                      { title: t('modal.enabled'), value: 'true' },
                      { title: t('modal.disabled'), value: 'false' },
                    ]}
                    dropWidth={374}
                    error={error?.message}
                    onChange={(value) => {
                      const enableValue = value === 'true';
                      field.onChange(enableValue);
                      setValue('enable', enableValue);
                    }}
                    defaultValue={field.value !== undefined ? String(field.value) : undefined}
                  />
                )}
              />
            </div>
          </div>
        )}
        <div className="flex justify-between items-center">
          <h1>{t('modal.password')}</h1>
          <div className="w-full max-w-[380px]">
            <Input
              {...register('password', {
                ...(isEditMode ? {} : { required: t('errors.required') }),
                validate: {
                  minLength: (value) =>
                    !value || value.length >= 12 || t('errors.password_too_short'),
                  hasUpperCase: (value) =>
                    !value || upperCaseRegexp.test(value) || t('errors.no_uppercase'),
                  hasLowerCase: (value) =>
                    !value || lowerCaseRegexp.test(value) || t('errors.no_lowercase'),
                  hasNumber: (value) => !value || numberRegexp.test(value) || t('errors.no_number'),
                },
              })}
              placeholder={t('modal.password')}
              error={errors.password?.message}
              name="password"
            />
          </div>
        </div>
        <div className="flex justify-end flex-col items-end">
          <a type="button" className="cursor-pointer text-action" onClick={handleGeneratePassword}>
            {t('modal.generate_password')}
          </a>
          {showPasswordMessage && (
            <div className="text-red-500 text-sm">{t('modal.password_warning')}</div>
          )}
        </div>
        <div className="flex justify-center ml-[90px]">
          <div className="text-[12px]">
            <p className="text-2color">{t('modal.validation_rules_1')}</p>
            <p className="text-2color">{t('modal.validation_rules_2')}</p>
            <p className="text-2color">{t('modal.validation_rules_3')}</p>
            <p className="text-2color">{t('modal.validation_rules_4')}</p>
            <p className="text-2color">{t('modal.validation_rules_5')}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full mt-[20px]">
        <Button
          type="submit"
          label={t('modal.save_button')}
          isFull
          disabled={!isValid}
          className="!px-[58px] !py-[20px] mt-[32px]"
        />
      </div>
    </form>
  );
};
