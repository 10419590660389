import React from 'react';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { NavLink } from 'react-router-dom';
import logo from '@app/imgs/imagesToBuild';
const HeaderBlock = () => {
  return (
    <div className="bg-white flex sticky top-0 justify-between px-[26px] text-sm font-[500] shadow-medium items-center border-b-[1px] border-b-b_light h-[67px] w-full">
      <NavLink to={navigationRoutes.main} className="inline-flex items-center">
        <div className="text-primary_copy mr-[16px]">
          <img src={logo} alt="LOGO" />
        </div>
      </NavLink>
    </div>
  );
};

export default HeaderBlock;
