import React from 'react';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { BreadcrumbsItem } from '@app/layouts/layout';
import { Route } from 'react-router-dom';
import RequiredAuth from '@app/routes/required-auth.route';
import { PresetsPage } from '@app/pages';
import { SinglePresetSettings } from '@app/pages/presets/blocks/single-preset-settings';

export const presetTemplateRoute = (
  <Route
    path={navigationRoutes.presets}
    handle={{
      crumb: () => <BreadcrumbsItem path={navigationRoutes.presets} crumb="preset_templates" />,
    }}
  >
    <Route
      index
      element={
        <RequiredAuth>
          <PresetsPage />
        </RequiredAuth>
      }
    />
    <Route
      path="new"
      element={<SinglePresetSettings newPreset={true} />}
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={`${navigationRoutes.presets}/:id`} crumb="preset_template" />
        ),
      }}
    />
    <Route
      path=":id"
      element={<SinglePresetSettings />}
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={`${navigationRoutes.presets}/:id`} crumb="preset_template" />
        ),
      }}
    />
  </Route>
);
