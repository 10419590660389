import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ChartLineUptrendXyaxisCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_442)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM9.31543 16.8623C9.31543 17.293 9.60547 17.583 10.0361 17.583H18.1309C18.4121 17.583 18.6406 17.3545 18.6406 17.0732C18.6406 16.7832 18.4121 16.5547 18.1309 16.5547H10.3701C10.3525 16.5547 10.3438 16.5459 10.3438 16.5283V14.7881L11.9961 13.127C12.04 13.0742 12.084 13.0742 12.1367 13.127L13.5869 14.6299C13.8066 14.8408 14.0176 14.9375 14.2637 14.9375C14.5098 14.9375 14.7383 14.8408 14.9404 14.6299L16.5576 12.9775L17.1465 13.584C17.375 13.8037 17.709 13.6807 17.7969 13.3467L18.4297 10.8945C18.5088 10.6045 18.2363 10.3408 17.9375 10.4199L15.4941 11.0352C15.1689 11.1143 15.0371 11.4658 15.2656 11.6855L15.8545 12.2656L14.334 13.8125C14.29 13.874 14.2461 13.874 14.2021 13.8213L12.7256 12.3008C12.5322 12.0898 12.3125 11.9932 12.0664 11.9932C11.8115 11.9932 11.5918 12.0898 11.3896 12.3008L10.3438 13.3643V9.99805C10.3438 9.7168 10.1152 9.47949 9.83398 9.47949C9.55273 9.47949 9.31543 9.7168 9.31543 9.99805V16.8623Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_442">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ChartLineUptrendXyaxisCircleIcon;
