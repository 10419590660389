import React, { FC } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';
import {
  MetricListArrType,
  MetricObjType,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import { pictogramsData } from '@app/components/metric-table-value-build';
import { Button, Checkbox, Input, Select, TextArea, Toggle } from '@ui';
import {
  fieldBlockClassName,
  fieldLabelClassName,
} from '@app/pages/analytics/metric/analytics-metric-tab/blocks/modals/handle-metric-modal/handle-metric-modal.style';
import HandleMetricSpecialToggle from '@app/pages/analytics/metric/analytics-metric-tab/blocks/modals/handle-metric-modal/blocks/handle-metric-special-toggle';
import Icon from '@app/components/ui/icons';
import { toast } from 'react-toastify';
import {
  useCreateProjectMetricGroupMutation,
  useEditProjectMetricGroupMutation,
} from '@app/store/api/metrics.api';
import {
  useCreateProjectChatMetricGroupMutation,
  useEditProjectChatMetricGroupMutation,
} from '@app/store/api/chat-api/chat-metric.api';
import useTranslation from '@app/hooks/use-translation';

type HandleMetricSelectBuilder = {
  value: string;
  icon: number | null;
  id: number;
};

type HandleMetricForm = {
  type: 'tags';
  result_value_type: 'num' | 'str';
  visualization: 'native' | 'icons' | 'mix';
  name: string; //
  description: string; //
  settings: {
    multiselect: boolean;
    input: 'input' | 'select';
    default: {
      type: 'null' | 'value' | 'copy';
      value?: string | number | null;
      copy_from?: string;
    } | null;
    select?: Array<HandleMetricSelectBuilder>;
  };
};

type HandleMetricFormProps = {
  onClose: () => void;
  metricList: Array<MetricListArrType>;
  isEdit: boolean;
  metricSettings: MetricObjType;
  currentTemplate: 'chat' | 'voice';
};

const HandleMetricForm: FC<HandleMetricFormProps> = (props) => {
  const { onClose, metricList, metricSettings, currentTemplate, isEdit } = props;
  const { t } = useTranslation('pages.metricTemplates.manualMetric');
  const metricResultTypes = { str: 'text', num: 'number' };
  const resultValueTypeOptions = [
    { title: t('number'), value: 'num' },
    { title: t('text'), value: 'str' },
  ];
  const settingsInputOptions = [
    { title: t('manual_entry'), value: 'input' },
    { title: t('select_from_list'), value: 'select' },
  ];
  const visualizationsOptions = [
    { title: t('pictogram'), value: 'icons' },
    { title: t('value'), value: 'native' },
    { title: t('both'), value: 'mix' },
  ];
  const iconsOptionList = Object.entries(pictogramsData).map(([iconIndex]) => {
    return { value: iconIndex, title: iconIndex };
  });
  const [createMetricChat] = useCreateProjectChatMetricGroupMutation();
  const [editMetricChat] = useEditProjectChatMetricGroupMutation();
  const [createMetricVoice] = useCreateProjectMetricGroupMutation();
  const [editMetricVoice] = useEditProjectMetricGroupMutation();
  const createMetricMutation = { chat: createMetricChat, voice: createMetricVoice };
  const editMetricMutation = { chat: editMetricChat, voice: editMetricVoice };
  const { project } = useOutletContext<AnalyticPageOutlet>();
  const disableByProjectType = project?.status === 'CREATED' ? false : isEdit;
  const {
    register,
    clearErrors,
    control,
    // reset,
    watch,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<HandleMetricForm>({
    defaultValues: {
      type: 'tags',
      result_value_type: isEdit
        ? (metricSettings?.result_value_type as 'num' | 'str') || 'str'
        : 'str',
      visualization: isEdit
        ? (metricSettings?.visualization as 'native' | 'icons' | 'mix') || 'native'
        : 'native',
      name: isEdit ? metricSettings?.name || '' : '',
      description: isEdit ? metricSettings?.description || '' : '',
      settings: {
        multiselect: isEdit ? metricSettings?.settings?.multiselect || false : false,
        input: isEdit ? metricSettings?.settings?.input || 'input' : 'input',
        default: {
          type: isEdit ? metricSettings?.settings?.default?.type || 'null' : 'null',
          value: isEdit ? metricSettings?.settings?.default?.value || null : null,
          copy_from: isEdit ? metricSettings?.settings?.default?.copy_from || undefined : undefined,
        },
        select: isEdit
          ? (metricSettings?.settings?.select as Array<HandleMetricSelectBuilder>) || [
              { id: 1, value: '', icon: null },
            ]
          : [{ id: 1, value: '', icon: null }],
      },
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'settings.select',
  });

  function resetOnChangeResultValueType() {
    if (watch('settings.input') === 'input') {
      !!watch('settings.default.copy_from') && setValue('settings.default.copy_from', undefined);
      watch('settings.default.type') !== 'null' && setValue('settings.default.type', 'null');
      setValue('settings.default.value', null);
    }
  }
  function resetByInputType() {
    if (watch('settings.input') === 'input') {
      setValue('settings.multiselect', false);
      setValue('visualization', 'native');
      setValue('settings.select', undefined);
    }
  }
  function onSubmitForm() {
    resetByInputType();
    if (
      !watch('settings.default.value') &&
      !watch('settings.default.copy_from') &&
      watch('settings.input') === 'input'
    ) {
      setValue('settings.default.type', 'null');
      setValue('settings.default.value', null);
    }
    if (!project) return;
    if (isEdit) {
      editMetricMutation[currentTemplate || 'voice']({
        params: { metric_id: String(metricSettings.metric_id), project_id: project.project_id },
        body: getValues() as MetricObjType,
      })
        .unwrap()
        .then(() => {
          toast.success(t('toast_edit_success_message'));
          onClose();
        })
        .catch(() => {
          toast.error(t('toast_edit_error_message'));
        });
    } else {
      createMetricMutation[currentTemplate || 'voice']({
        params: { project_id: project.project_id },
        body: getValues() as MetricObjType,
      })
        .unwrap()
        .then(() => {
          toast.success(t('toast_create_success_message'));
          onClose();
        })
        .catch(() => {
          toast.error(t('toast_create_error_message'));
        });
    }
  }

  const selectAdditionalBlock = (
    <div className="flex flex-col gap-[20px]">
      <div className={fieldBlockClassName}>
        <label className={`${fieldLabelClassName} !font-[600]`}>
          {t('available_variant_list')}
        </label>
        <div className="flex items-center gap-[5px]">
          <Checkbox
            disabled={disableByProjectType}
            checked={watch('settings.multiselect')}
            onChange={() => {
              setValue('settings.multiselect', !watch('settings.multiselect'));
            }}
          />
          <label className={fieldLabelClassName + (disableByProjectType ? ' !text-4color' : '')}>
            {t('allow_multiple_selection')}
          </label>
        </div>
      </div>
      <div className={fieldBlockClassName}>
        <label className={fieldLabelClassName}>{t('display_type')}</label>
        <div className="w-[65%]">
          <HandleMetricSpecialToggle
            isActive={watch('visualization')}
            onChange={(value) => {
              clearErrors();
              setValue('visualization', value as 'native' | 'mix' | 'icons');
            }}
            valuesList={visualizationsOptions}
          />
        </div>
      </div>
      <div className="flex flex-col gap-[10px]">
        <div className="flex items-center justify-between font-[600]">
          <div>
            {t('pictogram')} / {t('value')}
          </div>
          <div>{t('by_default')}</div>
        </div>
        {fields.map((selectField, index) => (
          <div className=" relative flex items-center" key={selectField.id}>
            <div className="flex items-center gap-[10px] w-full rounded-[12px] bg-basic_app_bg py-[16px] px-[10px]">
              <Icon className="text-4color" name="GridIcon" />
              <div className="min-w-[75px]">
                <Controller
                  control={control}
                  rules={{
                    required: {
                      value:
                        (watch('settings.input') === 'select' &&
                          watch('visualization') === 'mix') ||
                        (watch('settings.input') === 'select' &&
                          watch('visualization') === 'icons'),
                      message: ' ',
                    },
                  }}
                  render={() => (
                    <Select
                      defaultValue={watch(`settings.select.${index}.icon`)?.toString() || undefined}
                      onChange={(value) => setValue(`settings.select.${index}.icon`, Number(value))}
                      dropTop
                      options={iconsOptionList}
                      error={errors.settings?.select?.[index]?.icon?.message}
                      pictogramsMode
                      dropWidth={320}
                    />
                  )}
                  name={`settings.select.${index}.icon`}
                />
              </div>
              <div className="w-full">
                <Input
                  {...register(`settings.select.${index}.value`, {
                    required: {
                      value: watch('settings.input') === 'select',
                      message: t('field_required'),
                    },
                    valueAsNumber: metricResultTypes[watch('result_value_type')] === 'number',
                  })}
                  error={errors.settings?.select?.[index]?.value?.message}
                  placeholder={t('placeholder')}
                  type={metricResultTypes[watch('result_value_type')] as 'number' | 'text'}
                />
              </div>
              <div>
                <Toggle
                  disabled={!watch(`settings.select.${index}.value`)}
                  checked={watch('settings.default.value') === watch(`settings.select.${index}.id`)}
                  onChecked={(value) => {
                    if (value) {
                      setValue('settings.default.type', 'value');
                      setValue('settings.default.copy_from', undefined);
                      setValue('settings.default.value', watch(`settings.select.${index}.id`));
                    }
                  }}
                />
              </div>
            </div>
            {index !== 0 && (
              <div className="cursor-pointer absolute right-[-20px]" onClick={() => remove(index)}>
                <Icon size={12} className="hover:opacity-[.6]" name="XIcon" />
              </div>
            )}
          </div>
        ))}
        <Button
          label={t('add_field_to_search')}
          fill="linked"
          icon="PlusIcon"
          onClick={() =>
            append({ id: (watch().settings?.select?.length || 0) + 1, value: '', icon: null })
          }
        />
      </div>
    </div>
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const inputAdditionalBlock = (
    <div>
      <div>
        <div className="flex items-start justify-between">
          <label className={fieldLabelClassName}>{t('value_by_default')}</label>
          <div className="w-[65%] flex flex-col gap-[5px]">
            <Input
              {...register('settings.default.value', {
                onChange: () => {
                  watch('settings.default.type') !== 'value' &&
                    setValue('settings.default.type', 'value');
                  !!watch('settings.default.copy_from') &&
                    setValue('settings.default.copy_from', undefined);
                },
                valueAsNumber: metricResultTypes[watch('result_value_type')] === 'number',
              })}
              type={metricResultTypes[watch('result_value_type')] as 'number' | 'text'}
            />
            <div className={fieldLabelClassName}>{t('or')}</div>
            <Select
              options={metricList
                .filter((metric) => metric.result_value_type === watch('result_value_type'))
                .map((metric) => ({
                  value: metric.metric_id,
                  title: `${metric.name} (${metric.result_value_type})`,
                }))}
              onChange={(value) => {
                setValue('settings.default.copy_from', value as string);
                setValue('settings.default.type', 'copy');
                setValue('settings.default.value', null);
              }}
              defaultValue={watch('settings.default.copy_from') || ''}
              placeholder={t('value_from_metric')}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const mainCommonBlock = (
    <div className="flex flex-col gap-[20px]">
      <div className={fieldBlockClassName}>
        <label className={fieldLabelClassName}>{t('metric_name')}</label>
        <div className="w-[65%]">
          <Input
            {...register('name', {
              required: { value: true, message: t('field_required') },
              pattern: {
                value: /^[\d\s\w а-яйА-ЯЙ]+$/,
                message: t('bad_format'),
              },
            })}
            error={errors.name?.message}
            placeholder={t('name')}
          />
        </div>
      </div>
      <div className={fieldBlockClassName}>
        <label className={fieldLabelClassName}>{t('value_type')}</label>
        <div className="w-[65%]">
          <HandleMetricSpecialToggle
            disabled={disableByProjectType}
            isActive={watch('result_value_type')}
            onChange={(value) => {
              setValue('result_value_type', value as 'num' | 'str');
              resetOnChangeResultValueType();
            }}
            valuesList={resultValueTypeOptions}
          />
        </div>
      </div>
      <div className={fieldBlockClassName}>
        <label className={fieldLabelClassName}>{t('input_method')}</label>
        <div className="w-[65%]">
          <HandleMetricSpecialToggle
            disabled={disableByProjectType}
            isActive={watch('settings.input')}
            onChange={(value) => {
              setValue('settings.input', value as 'input' | 'select');
              setValue('settings.default.value', null);
              setValue('settings.default.type', 'null');
              setValue('settings.default.copy_from', undefined);
            }}
            valuesList={settingsInputOptions}
          />
        </div>
      </div>
      {watch('settings.input') === 'input' && inputAdditionalBlock}
      <div className={fieldBlockClassName}>
        <label className={fieldLabelClassName}>{t('description')}</label>
        <div className="w-[65%]">
          <TextArea
            defaultValue={watch('description')}
            onChange={(e) => setValue('description', e.target.value)}
            placeholder={t('fill_metric_details')}
            height={90}
          />
        </div>
      </div>
      {watch('settings.input') === 'select' && selectAdditionalBlock}
    </div>
  );

  // markup

  return (
    <form className="pb-[20px] " onSubmit={handleSubmit(onSubmitForm)}>
      <div className="flex flex-col gap-[20px]">
        {mainCommonBlock}
        <div className="w-full flex items-center gap-[10px]">
          <Button label={t('save')} type="submit" />
          <Button label={t('cancel')} fill="outlined" onClick={onClose} />
        </div>
      </div>
    </form>
  );
};

export default HandleMetricForm;
