import React, { FC } from 'react';

import { IconType } from '../icon.type';

const HandThumbsupCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_425)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM11.3369 14.9814C11.3105 16.6953 12.6904 17.9785 14.8525 17.9961L15.4766 18.0049C16.0654 18.0049 16.5137 17.9609 16.7773 17.8994C17.1553 17.8027 17.5156 17.5654 17.5156 17.1172C17.5156 16.959 17.4629 16.8096 17.4189 16.7217C17.3838 16.6689 17.3926 16.6162 17.4365 16.5986C17.7266 16.4756 17.9639 16.1943 17.9639 15.8428C17.9639 15.6318 17.9023 15.4473 17.7969 15.3154C17.7529 15.2539 17.7617 15.1924 17.832 15.1484C18.043 15.0078 18.1924 14.7617 18.1924 14.4541C18.1924 14.2344 18.1221 13.9971 17.999 13.8828C17.9375 13.8213 17.9463 13.7861 18.0254 13.7334C18.1572 13.6016 18.2451 13.373 18.2451 13.127C18.2451 12.6523 17.8848 12.2832 17.4189 12.2832H15.793C15.3887 12.2832 15.1338 12.0811 15.1338 11.7471C15.1338 11.1406 15.8984 10.0068 15.8984 9.18945C15.8984 8.75 15.6084 8.47754 15.2305 8.47754C14.8965 8.47754 14.7295 8.69727 14.5449 9.06641C13.8506 10.3936 12.9365 11.4834 12.2422 12.4238C11.6445 13.2148 11.3545 13.9004 11.3369 14.9814ZM9.06934 15.0166C9.06934 16.4229 9.91309 17.5654 11.0732 17.5654H11.8818C11.0557 16.9502 10.6865 16.0273 10.7041 14.9727C10.7129 13.8301 11.1348 13.0127 11.5479 12.459H10.915C9.86035 12.459 9.06934 13.584 9.06934 15.0166Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_425">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default HandThumbsupCircleIcon;
