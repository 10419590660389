import { useGetWordsCloudQuery } from '@app/store/api/get-words-cloud.api';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router';

import { cloneDeep } from 'lodash';

import classNames from 'classnames';

import { useIsOverflow } from '@app/hooks/use-overflow';

import { Skeleton } from '@ui';

import useTranslation from '@app/hooks/use-translation';

import { WordCloudContext } from '../types/types';
import { fillPercentColorStyle } from '../styles/styles';

export const TableContent = () => {
  const { t } = useTranslation('pages.wordsCloud');
  const { data: dataForCloud } = useOutletContext<WordCloudContext>();
  const { data, isFetching } = useGetWordsCloudQuery(dataForCloud, {
    skip: dataForCloud === undefined,
  });
  const [ref, setRef] = useState<HTMLDivElement>();
  const [overflow, setOverflow] = useState<boolean>(false);
  const [value, setValue] = useState<number>();

  useIsOverflow(ref, (value) => setOverflow(value));

  function getDataForTable() {
    if (!data?.stat.length) return;
    const stat = cloneDeep(data?.stat);
    const ARRAY_STAT_SPLICER = 12;
    const statisticLength = Math.ceil(stat.length / ARRAY_STAT_SPLICER);
    return new Array(statisticLength).fill(null).map(() => stat.splice(0, ARRAY_STAT_SPLICER));
  }

  function percentCalc(value: number) {
    if (!data?.stat[0]?.nentry) return 100;
    return (value * 100) / data?.stat[0]?.nentry;
  }
  function onScroll(event) {
    setValue(event.currentTarget.scrollLeft);
  }
  useEffect(() => {
    const el = ref;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: 'auto',
        });
      };
      el.addEventListener('wheel', onWheel);
      return () => el.removeEventListener('wheel', onWheel);
    }
  }, [data, ref]);

  if (isFetching) return <Skeleton height={550} />;
  return (
    <>
      <div
        ref={(ref) => ref && setRef(ref)}
        // onWheel={onScroll}
        onScroll={onScroll}
        className={classNames('scrollHidden', 'w-full pb-[14px] overflow-y-hidden transition-all')}
      >
        <div className="flex w-fit gap-[15px]">
          {getDataForTable()?.map((item, index) => (
            <div className="" key={index}>
              <div className="flex font-[600] text-[12px] leading-[110%] tracking-tight !text-[#000] justify-between px-[30px] mb-[18px]">
                <span>{t('word')}</span>
                <span>{t('meets')}</span>
              </div>
              <div className="flex flex-col items-start gap-[4px]">
                {item.map((wordsItems, index) => {
                  return (
                    <div
                      className="relative w-[310px] bg-white h-[45px] rounded-[10px]"
                      key={index}
                    >
                      <div
                        style={{ width: `${percentCalc(wordsItems.nentry)}%` }}
                        className={fillPercentColorStyle()}
                      ></div>
                      <div className="z-[1] absolute w-full h-full flex items-center justify-between px-[30px]">
                        <span className="font-[600] text-[14px] leading-[30px] tracking-tight text-0color">
                          {wordsItems.word.charAt(0).toUpperCase() + wordsItems.word.slice(1)}
                        </span>
                        <span className="pr-[20px] font-[400] text-[14px] leading-[30px] tracking-tight text-0color">
                          {wordsItems.nentry}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={'absolute bottom-[40px]'}>
        {overflow && (
          <input
            type="range"
            className="range_thumb w-[650px]"
            max={ref?.scrollWidth && ref?.scrollWidth - 1065}
            value={value}
            min={0}
            defaultValue={0}
          />
        )}
      </div>
    </>
  );
};
