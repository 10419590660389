import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ClockBadgeCheckmarkIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_354)">
          <path
            d="M14 22.7334C15.2129 22.7334 16.3906 22.4873 17.4717 22.0303C17.0586 21.582 16.7422 21.0547 16.5137 20.4922C15.7314 20.7822 14.8877 20.9492 14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C17.6475 6.36816 20.6621 9.04004 21.1895 12.5469C21.7344 12.459 22.4551 12.4854 23.0088 12.626C22.4902 8.12598 18.6143 4.5752 13.9912 4.5752C9.0166 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.02539 22.7334 14 22.7334ZM9.55273 14.7441H13.9912C14.3867 14.7441 14.6855 14.4365 14.6855 14.0498V8.28418C14.6855 7.89746 14.3867 7.58984 13.9912 7.58984C13.6045 7.58984 13.2969 7.89746 13.2969 8.28418V13.3555H9.55273C9.15723 13.3555 8.8584 13.6543 8.8584 14.0498C8.8584 14.4365 9.15723 14.7441 9.55273 14.7441ZM21.7959 22.7773C24.2568 22.7773 26.3223 20.7207 26.3223 18.251C26.3223 15.7725 24.2744 13.7334 21.7959 13.7334C19.3174 13.7334 17.2695 15.7725 17.2695 18.251C17.2695 20.7383 19.3174 22.7773 21.7959 22.7773ZM21.2686 20.9229C21.1016 20.9229 20.8994 20.8525 20.7676 20.7119L19.1152 18.9014C19.0273 18.8135 18.9658 18.6377 18.9658 18.4883C18.9658 18.1104 19.2559 17.8643 19.5898 17.8643C19.792 17.8643 19.9502 17.9434 20.0645 18.0664L21.2334 19.3496L23.4746 16.2295C23.5977 16.0713 23.7822 15.957 24.002 15.957C24.3447 15.957 24.6348 16.2295 24.6348 16.5811C24.6348 16.6865 24.5908 16.8271 24.5029 16.9414L21.7871 20.6855C21.6816 20.8262 21.4795 20.9229 21.2686 20.9229Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_354">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ClockBadgeCheckmarkIcon;
