import useTranslation from '@app/hooks/use-translation';

type FilterConditionType = {
  id: string;
  name: string | SpecialConditionsTypes;
  values: string[];
  aliasOnly?: boolean;
  metricOnly?: boolean;
  type_meta: string[];
};
type MetaDateTimeType = { name: string; id: string };
type CalendarBindTypes = 'cd' | 'cw' | 'cm';
type SpecialConditionsTypes = 'not_calculated' | 'calculated' | 'exists' | 'not_exists';
export const UniversalFilterConstants = () => {
  const { t } = useTranslation('common.constants');
  const conditions: FilterConditionType[] = [
    {
      id: '>',
      name: '>',
      values: ['num'],
      type_meta: ['record', 'base', 'system'],
    },
    {
      id: '<',
      name: '<',
      values: ['num'],
      type_meta: ['record', 'base', 'system'],
    },
    {
      id: '>=',
      name: '>=',
      values: ['num'],
      type_meta: ['record', 'base', 'system'],
    },
    {
      id: '<=',
      name: '<=',
      values: ['num'],
      type_meta: ['record', 'base', 'system'],
    },
    {
      id: '=',
      name: '=',
      values: ['num', 'str', 'datetime'],
      type_meta: ['channel', 'record', 'base', 'system'],
    },
    {
      id: '!=',
      name: '≠',
      values: ['num', 'str', 'datetime'],
      type_meta: ['channel', 'record', 'base', 'system'],
    },
    {
      id: 'exists',
      name: t('exists'),
      values: ['num', 'str'],
      aliasOnly: true,
      type_meta: ['record', 'base'],
    },
    {
      id: 'not_exists',
      name: t('not_exists'),
      aliasOnly: true,
      values: ['num', 'str'],
      type_meta: ['record', 'base'],
    },
    {
      id: 'calculated',
      name: t('calculated'),
      metricOnly: true,
      values: ['num', 'str'],
      type_meta: ['record', 'base'],
    },
    {
      id: 'not_calculated',
      metricOnly: true,
      name: t('not_calculated'),
      values: ['num', 'str'],
      type_meta: ['record', 'base'],
    },
  ];
  const metaDateTimeCalendar: MetaDateTimeType[] = [
    {
      name: t('current_day'),

      id: 'cd',
    },
    {
      name: t('current_week'),
      id: 'cw',
    },
    {
      name: t('current_month'),
      id: 'cm',
    },
  ];

  const metaDateTimeCalendarId: string = metaDateTimeCalendar.reduce(
    (total, curr) => total + curr.id,
    '',
  );

  const metaDateTime: MetaDateTimeType[] = [
    {
      name: t('without_interval'),
      id: 'n',
    },
    {
      name: t('with_interval'),
      id: 'r',
    },
    {
      name: t('date_n_days'),
      id: 'l',
    },
    {
      name: t('calendar_interval'),
      id: metaDateTimeCalendarId,
    },
  ];
  const metaDateTimeForRangeShow: Record<string, string> = {
    r: t('range_from'),
    l: t('range_to'),
    [metaDateTimeCalendarId]: t('calendar_bin'),
  };

  const metaDateTimeCalendarForRangeShow: Record<CalendarBindTypes, string> = {
    cd: t('current_day'),
    cw: t('current_week'),
    cm: t('current_month'),
  };

  const conditionsWithoutValue: string[] = ['not_calculated', 'calculated', 'exists', 'not_exists'];
  const conditionsWithoutValueDisplay: Record<SpecialConditionsTypes, string> = {
    not_calculated: t('display_not_calculated'),
    calculated: t('display_calculated'),
    exists: t('display_exists'),
    not_exists: t('display_not_exists'),
  };
  return {
    conditionsWithoutValueDisplay,
    conditionsWithoutValue,
    metaDateTimeCalendarForRangeShow,
    metaDateTimeForRangeShow,
    metaDateTime,
    metaDateTimeCalendar,
    metaDateTimeCalendarId,
    conditions,
  };
};
