import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

// import { MetaDataRequestType } from '@app/interfaces/metadata';

import { baseQuery } from '../baseQuery';
export const manualRecordingUpload = createApi({
  reducerPath: 'manualRecordingUpload',
  tagTypes: ['manualRecordingUpload'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    manualUploadRecord: build.query({
      query: (body) => ({
        url: serverRoutes.manualRecordingUpload,
        method: 'POST',
        body,
        formData: true,
        prepareHeaders: (headers) => {
          headers.set('Content-Type', 'multipart/form-data');
          return headers;
        },
      }),
    }),
  }),
});

export const { useLazyManualUploadRecordQuery } = manualRecordingUpload;
