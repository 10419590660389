import React, { FC } from 'react';
import {
  InformMessagesResponseType,
  MixedMessagesRequestType,
  SortByInformMessagesType,
} from '@app/interfaces/inform-messages';
import RubberTable from '@app/components/rubber-table';
import { TableColumn, TableItem } from '@app/components/rubber-table/table.types';
import { Paginator } from '@app/components/ui';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import useTranslation from '@app/hooks/use-translation';
import { TableFilter } from '@app/components/table/table.type';

type PlatformsType = 'telegram' | 'email';

type InformMessagesTableBlockPropsType = {
  tableData: InformMessagesResponseType | undefined;
  currentPlatform: PlatformsType;
  messagesOffset: number;
  messagesLimit: number;
  changeRequestParams: React.Dispatch<React.SetStateAction<MixedMessagesRequestType>>;
};

const InformMessagesTableBlock: FC<InformMessagesTableBlockPropsType> = (props) => {
  const { tableData, messagesLimit, messagesOffset, changeRequestParams, currentPlatform } = props;
  // eslint-disable-next-line
  const { t } = useTranslation('pages.settings.messages_queue');

  const tableColumns: TableColumn[] = [
    { index: 'dt_add', filter: true, title: t('table.dt_add') },
    { index: 'recipient_name', title: t('table.recipient_name') },
    { index: 'status', title: t('table.status') },
  ];
  const formattedTableData: TableItem[] =
    tableData?.tasks?.map((task) => ({
      dt_add: <div>{task?.dt_add}</div>,
      status: <div>{String(task.status)}</div>,
      recipient_name: task?.recipient?.email || task?.recipient?.description,
    })) || [];
  function handlePageChange({ limit, offset }: PaginationResponse) {
    changeRequestParams((prev) => ({
      ...prev,
      [currentPlatform]: { ...prev[currentPlatform], limit, offset },
    }));
  }
  function handleFilter(filter: TableFilter) {
    const val = Object.entries(filter);
    const sortDesc = +val?.[0]?.[1] === 1;
    const sortBy = (val?.[0]?.[0] || undefined) as SortByInformMessagesType | undefined;
    changeRequestParams((prev) => ({
      ...prev,
      [currentPlatform]: { ...prev[currentPlatform], sortBy: sortBy, sortDesc: sortDesc },
    }));
  }
  return (
    <>
      <div className="flex flex-col gap-[20px]">
        <RubberTable
          onFilter={handleFilter}
          dataSource={formattedTableData}
          columns={tableColumns}
        />
        {tableData?.tasks?.length ? (
          <Paginator
            page={Math.ceil(messagesOffset / messagesLimit) + 1}
            visiblePages={1}
            count={tableData?.total || 0}
            limit={messagesLimit}
            onChange={handlePageChange}
          />
        ) : null}
      </div>
    </>
  );
};

export default InformMessagesTableBlock;
