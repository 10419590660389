import React, { FC } from 'react';

import { IconType } from '../icon.type';

const CheckmarkBubbleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_609_6)">
          <path
            d="M9.80762 23.4453C10.2998 23.4453 10.6689 23.208 11.2666 22.6807L14.2812 20.0088H19.5986C22.2441 20.0088 23.7295 18.4971 23.7295 15.8867V9.06641C23.7295 6.45605 22.2441 4.94434 19.5986 4.94434H8.39258C5.74707 4.94434 4.26172 6.45605 4.26172 9.06641V15.8867C4.26172 18.4971 5.78223 20.0088 8.33105 20.0088H8.69141V22.1797C8.69141 22.9619 9.10449 23.4453 9.80762 23.4453ZM10.2383 21.5117V19.0068C10.2383 18.4883 10.0186 18.2861 9.51758 18.2861H8.43652C6.77539 18.2861 5.98438 17.4512 5.98438 15.834V9.11914C5.98438 7.50195 6.77539 6.66699 8.43652 6.66699H19.5459C21.207 6.66699 22.0068 7.50195 22.0068 9.11914V15.834C22.0068 17.4512 21.207 18.2861 19.5459 18.2861H14.1846C13.6572 18.2861 13.3936 18.374 13.0244 18.7607L10.2383 21.5117ZM13.0596 16.6514C13.376 16.6514 13.6484 16.502 13.833 16.2119L17.7617 10.0684C17.876 9.88379 17.9814 9.67285 17.9814 9.47949C17.9814 9.04004 17.5947 8.75 17.1816 8.75C16.918 8.75 16.6807 8.89941 16.4961 9.18945L13.0244 14.7617L11.3896 12.6787C11.1787 12.4062 10.9678 12.3096 10.7041 12.3096C10.2734 12.3096 9.93945 12.6611 9.93945 13.0918C9.93945 13.3027 10.0186 13.4961 10.168 13.6807L12.2422 16.2207C12.4795 16.5195 12.7344 16.6514 13.0596 16.6514Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_609_6">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CheckmarkBubbleIcon;
