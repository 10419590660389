import { createSlice } from '@reduxjs/toolkit';
import { httpRequest } from '@app/store/middleware/rest';
import serverRoutes from '@app/utils/server-routes';

const initialState: MetaState = {
  recordMeta: undefined,
  isPending: false,
  errors: null,
};

const metaSlice = createSlice({
  name: 'MetaSlice',
  initialState,
  reducers: {
    recordMetaResponse: (state, { payload }) => {
      state.recordMeta = payload;
    },
    recordMetaPendingResponse: (state, { payload }: { payload: boolean }) => {
      state.isPending = payload;
    },
    recordMetaErrorResponse: (state, { payload }: { payload: string }) => {
      state.errors = payload;
    },
  },
});

export default metaSlice.reducer;

export const { recordMetaResponse } = metaSlice.actions;

export const recordMetaRequest = (params: RecordMetaRequest) =>
  httpRequest({
    url: serverRoutes.recordMetadata,
    method: 'GET',
    params,
    onSuccess: recordMetaResponse.type,
  });

export type MetaState = {
  recordMeta: MetaRecords | undefined;
  isPending: boolean;
  errors: string | null;
};

export type RecordMetaRequest = { record_id: string };
export type MetaRecords = {
  record_name: string;
  base_metrics_meta: { [x: string]: string };
  record_meta: { [x: string]: string };
  chat_meta: { [x: string]: string };
  system_meta: { [x: string]: string };
  channels_meta: { [x: string]: { gsm?: number; name: string } };
};

//TODO: refactor type of response, remove old response if don't use it
// export type MetaRecordData = {
//   ucid: string;
//   dt_start: string;
//   base_metrics_meta: {
//     duration: number;
//     count_channels: number;
//     completion_rate: number;
//     max_duration_speech: number;
//     max_duration_silence: number;
//     total_duration_speech: number;
//     total_duration_silence: number;
//     total_duration_speech_percent: number;
//     total_duration_silence_percent: number;
//   };
//   record_meta: {
//     score: number;
//     skill: number;
//     success: number;
//     project_id: string;
//     operator_id: string;
//   };
//   channels_meta: { [x: string]: { name: string; gsm?: number } };
// };
