import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ListBulletCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_511)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM9.89551 11.6064C10.3174 11.6064 10.6689 11.2549 10.6689 10.833C10.6689 10.4023 10.3262 10.0596 9.89551 10.0596C9.46484 10.0596 9.12207 10.4023 9.12207 10.833C9.12207 11.2637 9.46484 11.6064 9.89551 11.6064ZM12.0137 11.3955H18.2891C18.6055 11.3955 18.8516 11.1494 18.8516 10.833C18.8516 10.5166 18.6055 10.2705 18.2891 10.2705H12.0137C11.6973 10.2705 11.4512 10.5166 11.4512 10.833C11.4512 11.1406 11.7061 11.3955 12.0137 11.3955ZM9.89551 14.4365C10.3174 14.4365 10.6689 14.0762 10.6689 13.6543C10.6689 13.2324 10.3174 12.8809 9.89551 12.8809C9.46484 12.8809 9.12207 13.2324 9.12207 13.6543C9.12207 14.085 9.46484 14.4365 9.89551 14.4365ZM12.0137 14.2168H18.2891C18.6055 14.2168 18.8516 13.9795 18.8516 13.6543C18.8516 13.3379 18.6055 13.1006 18.2891 13.1006H12.0137C11.7061 13.1006 11.4512 13.3467 11.4512 13.6543C11.4512 13.9707 11.7061 14.2168 12.0137 14.2168ZM9.89551 17.2578C10.3262 17.2578 10.6689 16.915 10.6689 16.4844C10.6689 16.0625 10.3174 15.7109 9.89551 15.7109C9.46484 15.7109 9.12207 16.0537 9.12207 16.4844C9.12207 16.915 9.46484 17.2578 9.89551 17.2578ZM12.0137 17.0469H18.2891C18.6055 17.0469 18.8516 16.8008 18.8516 16.4844C18.8516 16.168 18.6055 15.9219 18.2891 15.9219H12.0137C11.7061 15.9219 11.4512 16.1768 11.4512 16.4844C11.4512 16.8008 11.6973 17.0469 12.0137 17.0469Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_511">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ListBulletCircleIcon;
