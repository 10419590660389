import React from 'react';

import { useAuth } from '@app/utils/reactTokenAuth';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { NavLink } from 'react-router-dom';

// import { RtkLogoIcon } from '@app/components/ui/icons/default';

import logo from '../../../imgs/imagesToBuild';

import AuthMenuBlock from './auth-menu.block';

const HeaderBlock = () => {
  const theme = process.env.REACT_APP_THEME;

  const [logged] = useAuth();
  const renderAuthMenu = logged && <AuthMenuBlock />;
  const renderCurrentIndexLink = logged ? navigationRoutes.main : navigationRoutes.authorization;
  const imageSizeMap = {
    mtc: { width: 100, height: 50 },
    default: {},
  };

  return (
    <div className="bg-white flex justify-between px-[26px] text-sm font-[500] shadow-medium items-center border-b-[1px] border-b-b_light h-[67px] w-full">
      <NavLink to={renderCurrentIndexLink} className="inline-flex items-center">
        <div className="text-primary_copy mr-[16px]">
          <img src={logo} alt="LOGO" {...imageSizeMap[theme || 'default']} />
        </div>
      </NavLink>
      <div>{renderAuthMenu}</div>
    </div>
  );
};

export default HeaderBlock;
