import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateSinglePresetMutation,
  useDeletePresetTemplateMutation,
  useEditSinglePresetMutation,
  useGetPresetTemplateGroupsListQuery,
  useGetSinglePresetQuery,
} from '@app/store/api/preset-template.api';
import {
  Button,
  EasyFilterView,
  EasyModal,
  EditText,
  PageHeader,
  Select,
  SkeletonPageLoading,
} from '@ui';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { useLocation } from 'react-router';
import { NameAndDescriptionPattern } from '@app/constants/validation/regexp';
import useTranslation from '@app/hooks/use-translation';
import { useGetAliasesListQuery } from '@app/store/api/alias-settings-page.api';
import { Filter, GetFilterParamsNew, Range } from '@app/components/ui/easy-filter/types';
import { SinglePresetSettingsTableBlock } from '@app/pages/presets/blocks/single-preset-settings-table-block';
import {
  PresetsCreateDataType,
  PresetsDataType,
} from '@app/interfaces/pages-types/presets-page-types/presets-table-props.type';
import { isEqual } from 'lodash';
import { useAppSelector } from '@app/store/store';

type SinglePresetSettingsPropsType = {
  newPreset?: boolean;
};
const DEFAULT_TABLE_LIMIT = 10;
const emptyFilter: Filter & Range = {
  range: {
    type: 'l',
    parameters: [{ num: 7 }],
  },
  filter: [],
};
export const SinglePresetSettings: FC<SinglePresetSettingsPropsType> = (props) => {
  const { newPreset = false } = props;
  const { t } = useTranslation('pages.presets');
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { userSettings } = useAppSelector((state) => state.userSettings);

  //api
  const { currentData: preset, isLoading } = useGetSinglePresetQuery(
    { id: id || '' },
    { skip: !id },
  );
  const { data: groups, isLoading: groupsLoading } = useGetPresetTemplateGroupsListQuery();
  const { data } = useGetAliasesListQuery();
  const [deleteSinglePreset] = useDeletePresetTemplateMutation();
  const [createPreset] = useCreateSinglePresetMutation();
  const [editPreset] = useEditSinglePresetMutation();
  //useState
  const [presetName, setPresetName] = useState<string>();
  const [presetGroupId, setPresetGroupId] = useState<string>();
  const [recordsParams, getRecordsParams] = useState<GetFilterParamsNew>({
    offset: 0,
    limit: userSettings?.tablesLimit?.singlePresetSettings || DEFAULT_TABLE_LIMIT,
    preset_mode: false,
    filter: emptyFilter,
  });
  const [showDeletePresetModal, setShowDeletePresetModal] = useState(false);
  //handlers
  const getDefaultGroup = useCallback(() => {
    return (groups?.find((group) => group.is_default === true)?.preset_group_id as string) || '';
  }, [groups]);

  useEffect(() => {
    !newPreset &&
      userSettings?.tablesLimit?.singlePresetSettings &&
      getRecordsParams((prev) => ({
        ...prev,
        limit: userSettings?.tablesLimit?.singlePresetSettings as number,
      }));
  }, [newPreset, userSettings?.tablesLimit?.singlePresetSettings]);

  function changePresetName(name: string) {
    const value = name.replace(NameAndDescriptionPattern, '');
    setPresetName(value);
  }
  function changePresetGroup(value: string | number) {
    setPresetGroupId(value as string);
  }
  function getFilteringKeys() {
    return data?.aliases
      .filter((item) => !item.forbidden_rules)
      .map((alias) => ({
        id: alias.alias_id,
        title: alias.name,
        type_meta: alias.type_meta,
        type_value: alias.type_value,
      }));
  }
  function onChangeFilterHandler(data: Filter & Range) {
    getRecordsParams((prev) => ({ ...prev, filter: data }));
  }
  const getDisabledSavePresetButton = () => {
    if (recordsParams.filter === emptyFilter) return true;
    const currentFilter = { ...recordsParams.filter };
    if (
      isEqual(preset?.rules, currentFilter) &&
      presetName === preset?.name &&
      presetGroupId === preset?.preset_group_id
    )
      return true;
  };
  function savePreset() {
    if (!presetName || !presetGroupId) return;
    const createEditPresetData: PresetsCreateDataType = {
      name: presetName,
      description: '',
      preset_group_id: presetGroupId,
      shared: false,
      rules: recordsParams.filter,
    };
    newPreset
      ? createPreset(createEditPresetData).then(() => {
          navigate(`/${navigationRoutes.presets}`);
        })
      : preset?.preset_id &&
        editPreset({ body: createEditPresetData, params: { id: preset?.preset_id } }).then(() => {
          navigate(`/${navigationRoutes.presets}`);
        });
  }

  function saveAsNewTemplate() {
    if (!presetName || !presetGroupId) return;
    const newPreset: PresetsCreateDataType = {
      name: presetName + ` ${t('system.additional_name_to_copy')}`,
      description: '',
      preset_group_id: presetGroupId,
      shared: false,
      rules: recordsParams.filter,
    };
    createPreset(newPreset).then((data) => {
      const { data: presetData } = data as unknown as { data: PresetsDataType };
      navigate(`/${navigationRoutes.presets}/${presetData.preset_id}`);
    });
  }
  function deletePreset() {
    if (!preset?.preset_id) return;
    deleteSinglePreset({ id: preset?.preset_id }).then(() => {
      navigate(`/${navigationRoutes.presets}`);
    });
  }
  //use-effect
  useEffect(() => {
    getRecordsParams((prev) => ({
      ...prev,
      filter: (preset?.rules as Filter & Range) || emptyFilter,
    }));
    if (newPreset) {
      setPresetName(t('system.write_template_name'));
      setPresetGroupId(getDefaultGroup() || groups?.[0].preset_group_id);
    } else {
      setPresetGroupId(preset?.preset_group_id);
      setPresetName(preset?.name as string);
    }
  }, [getDefaultGroup, groups, newPreset, preset?.name, preset?.preset_group_id, preset?.rules, t]);
  //markup
  const renderPageLabel = presetName && (
    <EditText
      textStyle="font-[700] text-[24px] text-1color"
      text={presetName}
      onEdited={changePresetName}
      defaultOpen={newPreset || location.state === `/${navigationRoutes.records}`}
    />
  );
  const getTitleFilter = <h2 className="text-1color font-bold">{t('filter.title')}</h2>;
  const getFooterFilter = (
    <div className="flex items-center">
      <div className="grow">
        <Button
          label={t('filter.buttons.save')}
          className="mr-[6px]"
          disabled={getDisabledSavePresetButton()}
          onClick={() => savePreset()}
        />
        {!newPreset && (
          <Button
            fill="linked"
            label={t('filter.buttons.save_as_preset')}
            className="mr-[-12px]"
            onClick={() => saveAsNewTemplate()}
          />
        )}
        <Button
          fill="linked"
          label={t('filter.buttons.cancel')}
          link={'/' + navigationRoutes.presets}
        />
      </div>
      {!newPreset && (
        <div>
          <Button
            fill="linked"
            label={t('filter.buttons.delete')}
            icon="TrashIcon"
            variant="danger"
            onClick={() => setShowDeletePresetModal(true)}
          />
        </div>
      )}
    </div>
  );
  if (isLoading || groupsLoading) return <SkeletonPageLoading />;
  return (
    <>
      <PageHeader popLink={'/' + navigationRoutes.presets} label={renderPageLabel}>
        <span className="inline-block mr-[11px]">{t('page_header_group')}: </span>
        <Select
          dropWidth={200}
          size={204}
          defaultValue={presetGroupId}
          options={
            groups?.map((group) => ({
              title: group.name,
              value: group.preset_group_id,
            })) || []
          }
          isEmpty={true}
          placeholder={t('page_header_group_placeholder')}
          onChange={(value) => changePresetGroup(value)}
        />
      </PageHeader>
      <EasyFilterView
        dataIdEndDate="preset-templates-date-to"
        dataIdStartDate="preset-templates-date-from"
        titleMarkup={getTitleFilter}
        footerMarkup={getFooterFilter}
        data={(preset?.rules as Filter & Range) || recordsParams.filter}
        onChangeHandler={onChangeFilterHandler}
        keysForFiltering={getFilteringKeys() || []}
      />
      <SinglePresetSettingsTableBlock
        hideDragMode={newPreset}
        recordsParams={recordsParams}
        getRecordsParams={getRecordsParams}
      />
      <EasyModal
        show={showDeletePresetModal}
        onClose={setShowDeletePresetModal.bind(null, false)}
        variant={'removal'}
        onRemove={deletePreset}
        withoutFooter
        label={t('preset_remove_modal.remove_label')}
      >
        <div className={'flex w-full items-center justify-center text-center'}>
          {t('preset_remove_modal.remove_description')}
        </div>
      </EasyModal>
    </>
  );
};
