import { v4 } from 'uuid';
import {
  MetricCasesWords,
  MetricListArrType,
  MetricRecordDataResult,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import {
  CalculatedRecordMetric,
  Dialog,
  DialogWords,
  EmotionTrack,
  KnownMetricsCases,
  KnownProjectMetric,
  RecordMutationResult,
  RecordTranscriptionResponse,
  WordsTimeList,
} from '@app/interfaces/records';
import { AudioChannel } from '@app/store/reducers/audio-channels.slice';
import { UNKNOWN_CHANNEL } from '@app/constants/constants';
import { DEFAULT_EMOTION } from '@app/constants/emotions';

export function recordResponseMutation({
  metricProjectData,
  metricRecordData,
  transitionData,
  translationChannelsData,
  showMetricMarkup,
}: {
  metricProjectData?: MetricListArrType[] | undefined;
  metricRecordData: MetricRecordDataResult<MetricCasesWords> | undefined;
  transitionData: RecordTranscriptionResponse | undefined;
  translationChannelsData: Array<AudioChannel>;
  showMetricMarkup: boolean;
}): RecordMutationResult {
  let known_metrics_cases: KnownMetricsCases = {};
  let known_project_metrics: Array<KnownProjectMetric> = [];
  let calculatedRecordMetric: CalculatedRecordMetric = {};
  const wordsTimeList: WordsTimeList = {};
  const emotionTrack: Array<EmotionTrack> = [];
  const recordIsRecognized = transitionData !== undefined;
  const dialog_words =
    (transitionData &&
      Object.entries(transitionData.channels)
        .reduce<Array<DialogWords>>((acc, [channel_id, channel]) => {
          return Object.entries(channel).reduce((acc, [segment_id, segment_info]) => {
            return segment_info.nbest['0'].timing_prob.reduce((acc, timing_prob, currentIndex) => {
              acc.push({
                emotion: segment_info.nbest[0].emotions,
                from: timing_prob.from,
                to: timing_prob?.to || transitionData.duration,
                word_idx: `word_${channel_id}_${segment_id}_${currentIndex}`,
                word: timing_prob.word,
                channel_id: channel_id,
                segment_id: segment_id,
              });
              wordsTimeList[`word_${channel_id}_${segment_id}_${currentIndex}`] = {
                from: timing_prob.from,
                to: timing_prob?.to || transitionData.duration,
              };
              return acc;
            }, acc);
          }, acc);
        }, [])
        .sort((a, b) => {
          if (a.from <= b.from) return -1;
          if (a.from > b.from) return 1;
          return 0;
        })) ||
    [];

  const dialogChannels = translationChannelsData.reduce((acc, channelItem) => {
    acc[channelItem.channel] = channelItem;
    return acc;
  }, {});

  const dialogs = transitionData
    ? dialog_words.reduce<Array<Dialog>>((acc, word, currentIndex) => {
        if (
          word.channel_id !== dialog_words[currentIndex - 1]?.channel_id ||
          typeof dialog_words[currentIndex - 1] === 'undefined' ||
          word.from !== dialog_words[currentIndex - 1]?.to
        ) {
          const messageId = `chat_message_${v4()}`;
          wordsTimeList[word.word_idx]['message_id'] = messageId;
          const currentEmotion = Object.keys(word.emotion).length ? word.emotion : DEFAULT_EMOTION;
          acc.push({
            id: messageId,
            channel_id: word.channel_id,
            to: word.to || transitionData.duration,
            from: word.from,
            segment_id: word.segment_id,
            words: [word],
            channel_config: dialogChannels[word.channel_id] || {
              ...UNKNOWN_CHANNEL,
              name: String(word.channel_id),
            },
            emotion: currentEmotion,
          });
          acc[acc.length - 1]['emotion'] = currentEmotion;
          emotionTrack[acc.length - 1] = {
            ...currentEmotion,
            from: word.from,
            to: word.to || transitionData.duration,
          };
        } else {
          acc[acc.length - 1]['words'].push(word);
          acc[acc.length - 1]['to'] = word.to || 0;
          if (word?.to) emotionTrack[acc.length - 1].to = word.to;
        }
        return acc;
      }, [])
    : [];

  if (metricProjectData && metricRecordData) {
    known_project_metrics = metricProjectData?.reduce<Array<KnownProjectMetric>>((acc, metric) => {
      acc.push({
        metric_id: metric.metric_id,
        name: metric.name,
        color: metric.settings?.color || '',
        type: metric.type,
        settings: metric.settings,
      });
      return acc;
    }, []);

    const metricValuesSortByMetricId = metricRecordData?.metrics_results?.metrics_values.reduce(
      (acc, metricValue) => {
        acc[metricValue.metric_id] = metricValue.value;
        return acc;
      },
      {},
    );
    known_metrics_cases = known_project_metrics.reduce<KnownMetricsCases>((acc, known_metric) => {
      const cases = metricRecordData?.metrics_results?.metrics_cases[known_metric.metric_id];
      if (!cases) {
        return acc;
      }
      acc[known_metric.metric_id] = {
        known_metric: {
          ...known_metric,
          value: metricValuesSortByMetricId[known_metric.metric_id],
        },
        cases: Object.entries(cases).reduce((acc, [channel_id, segments]) => {
          acc[channel_id] = Object.entries(segments).reduce((acc, [segment_id, metric_cases]) => {
            const metricCaseCheckedToArray = Array.isArray(metric_cases)
              ? metric_cases
              : [metric_cases];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            acc[segment_id] = metricCaseCheckedToArray.reduce((acc, metric_case) => {
              for (let i = metric_case.from; i <= metric_case.to; i++) {
                acc[i] = true;
              }
              return acc;
            }, {});
            return acc;
          }, {});
          return acc;
        }, {}),
      };
      return acc;
    }, {});

    calculatedRecordMetric =
      metricRecordData.metrics_results.metrics_values.reduce<CalculatedRecordMetric>(
        (acc, metric) => {
          metricRecordData?.metrics_results?.metrics_cases[metric.metric_id], 'check';

          acc[metric.metric_id] = {
            metric_id: metric.metric_id,
            color: metric.settings.color,
            type: metric.settings.type,
            name: metric.name,
            result_value_type: metricProjectData.find((i) => i.metric_id === metric.metric_id)
              ?.result_value_type,
            value: metric.value,
            // TODO жесткие заглушки на запрет массивов и Undefined изменить при добавлении других метрик
            settings:
              !Array.isArray(metricRecordData?.metrics_results?.metrics_cases[metric.metric_id]) &&
              !!metricRecordData?.metrics_results?.metrics_cases[metric.metric_id]
                ? Object.entries(
                    metricRecordData?.metrics_results.metrics_cases[metric.metric_id],
                  ).reduce<CalculatedRecordMetric[string]['settings']>(
                    (acc, [channelID, channelCase], index) => {
                      acc = Object.entries(channelCase).reduce((acc, [segmentIdD, segmentCase]) => {
                        const wordInfo: CalculatedRecordMetric[string]['settings'] = {
                          ids: [],
                          distance: [],
                          messageId: [],
                        };
                        const wordIds = segmentCase.map(
                          (word) => `word_${channelID}_${segmentIdD}_${word.from - 1}`,
                        );
                        wordInfo['ids'] = wordIds;
                        wordInfo['distance'] = segmentCase.map((word) => word.to - word.from + 1);
                        wordInfo['messageId'] = wordIds.map((word) => {
                          return (
                            dialogs.find((dialog) =>
                              dialog.words.find((dialogWord) => dialogWord.word_idx === word),
                            )?.id ||
                            acc?.['messageId'][index - 1] ||
                            undefined
                          );
                        });
                        if (acc) {
                          acc['ids'] = [...acc['ids'], ...wordInfo['ids']];
                          acc['distance'] = [...acc['distance'], ...wordInfo['distance']];
                          acc['messageId'] = [...acc['messageId'], ...wordInfo['messageId']];
                        }
                        return acc;
                      }, acc);
                      return acc;
                    },
                    { ids: [], distance: [], messageId: [] },
                  )
                : null,
          };

          return acc;
        },
        {},
      );
  }
  return {
    currentAsrModel: transitionData?.asr_model.asr_model_id || '',
    showMetricMarkup,
    wordsTimeList,
    calculatedRecordMetric,
    dialogChannels,
    known_metrics_cases,
    known_project_metrics,
    dialog_words,
    dialogs,
    emotionTrack,
    recordIsRecognized,
  };
}
