import React, { FC } from 'react';
import { EasyModal } from '@ui';
import AddRecipientsForm from '@app/components/add-recipients-modal/blocks/add-recipients-form';
import { MixedRecipientsRequestType } from '@app/interfaces/tenant-recipients.type';
import {
  MixedTriggerRecipientsType,
  TriggerPlatformType,
} from '@app/interfaces/metric-trigger.type';
import useTranslation from '@app/hooks/use-translation';

import { EasyModalVariant } from '../ui/easy-modal/easy-modal';
type AddRecipientsModalPropsType = {
  show: boolean;
  editableRecipientData?: {
    description: string;
    recipient_id: string;
  };
  editMode?: boolean;
  subscribeDisable?: boolean;
  hideAddRecipientsFeature?: boolean;
  tgBotLink?: string;
  onlyForCreate?: boolean;
  onClose(): void;
  triggerSubscribedRecipients?: MixedTriggerRecipientsType;
  onEditRecipientDescription?(body: { recipient_id: string; description: string }): void;
  tenantRecipients?: {
    telegram: {
      recipients:
        | {
            description: string;
            recipient_id: string;
            confirmed: boolean;
          }[]
        | undefined;
      total: number | undefined;
    };
    email: {
      recipients:
        | {
            description: string;
            email: string | undefined;
            confirmed: boolean;
            recipient_id: string;
          }[]
        | undefined;
      total: number | undefined;
    };
  };
  changeTriggerSubscribedRecipients?: React.Dispatch<
    React.SetStateAction<MixedTriggerRecipientsType>
  >;
  handleChangeRecipientStatus?(
    status: boolean,
    platform: TriggerPlatformType,
    recipient_id: string,
  ): void;
  onCreateNewRecipient(
    platform: 'telegram' | 'email',
    body: {
      email: string;
      description: string;
    },
  ): Promise<{ status: boolean; recipient_id?: string; hash?: string }> | undefined;
  modalSizeVariant?: EasyModalVariant;
  filterParams?: MixedRecipientsRequestType;
  activePlatformFromParent?: TriggerPlatformType;
  changeFilterParams?: React.Dispatch<React.SetStateAction<MixedRecipientsRequestType>>;
};
const AddRecipientsModal: FC<AddRecipientsModalPropsType> = (props) => {
  const {
    show,
    onClose,
    tenantRecipients,
    onCreateNewRecipient,
    changeTriggerSubscribedRecipients,
    triggerSubscribedRecipients,
    handleChangeRecipientStatus,
    changeFilterParams,
    filterParams,
    tgBotLink,
    hideAddRecipientsFeature,
    onlyForCreate,
    activePlatformFromParent,
    modalSizeVariant,
    subscribeDisable,
    editMode = false,
    editableRecipientData,
    onEditRecipientDescription,
  } = props;
  const { t } = useTranslation('components.recipientsModal');
  return (
    <EasyModal
      customClassName={`${onlyForCreate ? '' : '!bg-basic_app_bg'}`}
      label={t('recipients')}
      variant={modalSizeVariant || 'large'}
      withoutFooter
      show={show}
      onClose={onClose}
    >
      {show && (
        <AddRecipientsForm
          tgBotLink={tgBotLink}
          subscribeDisable={subscribeDisable}
          editMode={editMode}
          onEditRecipientDescription={onEditRecipientDescription}
          editableRecipientData={editableRecipientData}
          onClose={onClose}
          activePlatformFromParent={activePlatformFromParent}
          onlyForCreate={onlyForCreate}
          hideAddRecipientsFeature={hideAddRecipientsFeature}
          changeTriggerSubscribedRecipients={changeTriggerSubscribedRecipients}
          tenantRecipients={tenantRecipients}
          onCreateNewRecipient={onCreateNewRecipient}
          triggerSubscribedRecipients={triggerSubscribedRecipients}
          handleChangeRecipientStatus={handleChangeRecipientStatus}
          changeFilterParams={changeFilterParams}
          filterParams={filterParams}
        />
      )}
    </EasyModal>
  );
};

export default AddRecipientsModal;
