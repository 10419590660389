import React, { FC, useState } from 'react';

import connector, { PropsFromRedux } from '@app/utils/store';
import { Empty } from '@ui';
import useTranslation from '@app/hooks/use-translation';

import AudioContainer from './audio-container';
import audioContextProvider from './record-provider';

type AudioBuildProps = {
  recordId: string;
  projectId?: string;
  withMetric?: boolean;
  currentTemplate: 'chat' | 'voice';
} & PropsFromRedux;

const AudioBuild: FC<AudioBuildProps> = (props) => {
  const { recordId, projectId, withMetric = false, currentTemplate } = props;
  const [toggleEmotions, changeToggleEmotions] = useState<boolean>(true);
  const { t } = useTranslation('components.audio');
  const contextState = {
    withMetric,
    projectId,
    recordId,
    toggleEmotions,
    currentTemplate,
    changeToggleEmotions,
  };

  if (!recordId) {
    return (
      <>
        <Empty title={t('record_not_found')} />
      </>
    );
  }

  return (
    <audioContextProvider.Provider value={contextState}>
      <AudioContainer />
    </audioContextProvider.Provider>
  );
};

export default connector(AudioBuild);
