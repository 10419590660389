import React, { ChangeEvent, FC, ForwardedRef, forwardRef } from 'react';

type ColorInputProps = {
  color?: string;
  changeColor?: (color: string) => void;
  label?: string;
};

const ColorInput: FC<ColorInputProps> = forwardRef(
  (props, forwardedRef: ForwardedRef<HTMLInputElement>) => {
    const { color = '#c592f7', changeColor, label, ...rest } = props;

    function handleChangeColor(event: ChangeEvent<HTMLInputElement>) {
      changeColor?.(event.target.value);
    }
    return (
      <label className="inline-flex items-center  cursor-pointer">
        <div
          className={`rounded-full ${
            (color === '#FFFFFF' || color === '#FFF' || color === '#fff' || color === '#ffffff') &&
            'border-0color border-[1px] border-solid'
          }  w-[22px] h-[22px] active:scale-[95%] overflow-hidden`}
        >
          <input
            ref={forwardedRef}
            className="rounded-full w-[50px] h-[50px] mt-[-10px] ml-[-10px] cursor-pointer border-0 border-none rounded-full"
            type="color"
            onChange={handleChangeColor}
            value={color}
            {...rest}
          />
        </div>
        {label && <div className=" ml-[12px] text-[15px] font-[500]">{label}</div>}
      </label>
    );
  },
);

ColorInput.displayName = 'ColorInput';

export default ColorInput;
