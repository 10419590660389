import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router';

import { PageHeader, Select, Skeleton } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { OptionItem } from '@app/components/ui/select/select.type';
import { useGetChannelsQuery } from '@app/store/api/get-channels-info.api';
import { useGetAnalyticProjectsForLastProjectsQuery } from '@app/store/api/analytics-projects.api';
import { useGetAllDictionariesListQuery } from '@app/store/api/dictionaries.api';
import { useAppSelector } from '@app/store/store';
import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';

import { WordsCloudFilter } from './blocks/words-cloud-filter';
import { WordCloudContext, WordsCloudRequestType } from './types/types';
import WordsCloudSkeleton from './blocks/words-cloud.skeleton';

const WordsCloud = () => {
  const { t } = useTranslation('components.menuBar');
  const { t: ti } = useTranslation('pages.reports');
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const [wordForSearch, changeWordForSearch] = useState<string[] | undefined>([]);
  const [dataForCloud, setDataForCloud] = useState<WordsCloudRequestType>();
  const { data, isLoading } = useGetChannelsQuery(undefined);
  const { data: projectsList, isLoading: loading } = useGetAnalyticProjectsForLastProjectsQuery();
  const { data: dictionaries, isLoading: dictLoading } = useGetAllDictionariesListQuery();
  const [updateUserSettings] = useUpdateUserSettingsMutation();

  useEffect(() => {
    if (userSettings?.wordsCloudId) return;
    updateUserSettings({ ...userSettings, wordsCloudId: projectsList?.[0]?.project_id });
  }, [projectsList, updateUserSettings, userSettings]);

  function handleSelectOnChange(option: string | number) {
    updateUserSettings({ ...userSettings, wordsCloudId: option as string });
  }

  const renderSelectProjectOptions: Array<OptionItem> = projectsList?.length
    ? projectsList?.map(({ name, project_id }) => ({
        title: name,
        value: project_id,
      }))
    : [];
  function onFormSubmit(data) {
    if (!userSettings?.wordsCloudId) return;
    setDataForCloud({ data: data, project_id: userSettings?.wordsCloudId });
  }

  const renderSelectProject = loading ? (
    <Skeleton width={234} height={32} />
  ) : (
    <Select
      size={240}
      onChange={handleSelectOnChange}
      placeholder={ti('select.all_projects')}
      options={renderSelectProjectOptions}
      defaultValue={userSettings?.wordsCloudId || ''}
      isEmpty
      dropWidth={238}
    />
  );
  if (loading || dictLoading || isLoading) return <WordsCloudSkeleton />;
  return (
    <>
      <PageHeader label={t('words_cloud')} icon="CloudIcon">
        <div className="inline-flex font-[500] text-[15px] text-1color items-center">
          {ti('choose_project')}:<div className="ml-[11px]">{renderSelectProject}</div>
        </div>
      </PageHeader>
      <div className="flex items-start justify-between gap-[10px] mt-[10px]">
        <Outlet
          context={
            { data: dataForCloud, changeWordForSearch: changeWordForSearch } as WordCloudContext
          }
        />
        <div>
          <WordsCloudFilter
            disableSaveButton={!!userSettings?.wordsCloudId}
            onFormSubmit={onFormSubmit}
            dictionaries={dictionaries}
            channels={data}
            wordForSearch={wordForSearch}
          />
        </div>
      </div>
    </>
  );
};

export default WordsCloud;
