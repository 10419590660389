import React from 'react';
import ChartListBlock from '@app/pages/reports/blocks/chart-list.block';
import { useParams } from 'react-router-dom';
import { NotFoundedPage } from '@app/pages';

const ProjectChartsPage = () => {
  const { report_id } = useParams();
  if (!report_id) return <NotFoundedPage />;
  return (
    <div>
      <ChartListBlock live_report_id={report_id} />
    </div>
  );
};

export default ProjectChartsPage;
