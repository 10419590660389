module.exports = {
  part_of_speech_data: {
    all: 'All parts of speech',
    NOUN: 'Noun',
    VERB: 'Verb',
    ADJ: 'Adjective',
    ADVB: 'Adverb',
    NUMR: 'Numerical',
    NPRO: 'Pronoun',
    CONJ: 'Prepositions/ conjunctions/particles',
  },
  occurrence: 'Occurrence',
  word: 'word',
  meets: 'meets',
  cloud_deep: 'Cloud depth',
  // placeholders
  channel_select_placeholder: 'Channel',
  permitted_part_of_speech_select_placeholder: 'Parts of speech',
  frequency_select_placeholder: 'Occurrence',
  stop_words_select_placeholder: 'Stop words',
  //
  all_channels_option: 'All channels',
  apply_button: 'Apply'
};
