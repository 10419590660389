import React, { FC, useEffect, useRef, useState } from 'react';

import { IconNameType } from '@app/components/ui/icons/icon-name.type';
import Icon from '@app/components/ui/icons';

import { ChevronDownV2Icon, TriangleBottomIcon } from '@icons';

import useOnClickOutside from '@app/hooks/use-onclick-outside';

import { LabelColor, LabelDropItem } from './label.type';
import {
  dropClassName,
  dropListUlClassName,
  triangleDropClassName,
  wrapperLabelClassName,
} from './label.styles';

type LabelProps = {
  title: string;
  value: string | number;
  variant?: LabelColor;
  icon?: IconNameType;
  isDrop?: boolean;
  dropMenu?: Array<LabelDropItem>;
  onDropSelected?: (dropItem: LabelDropItem) => void;
  onShow?: (status: boolean) => void;
  show?: boolean;
  isDropLeft?: boolean;
  isDropRight?: boolean;
};

const Label: FC<LabelProps> = (props) => {
  const {
    title,
    value,
    icon,
    show = false,
    onShow,
    variant = 'primary',
    onDropSelected,
    dropMenu,
    isDrop,
    isDropLeft,
    isDropRight,
  } = props;
  const [isOpenLabel, changeOpenLabel] = useState<boolean>(show);
  const [currentLabel, setCurrentLabel] = useState<LabelDropItem>({ title, value, variant, icon });
  const labelRef = useRef(null);
  const colorsListLabel: Record<LabelColor, string> = {
    primary: 'text-white bg-action',
    danger: 'text-white bg-basic_red',
    success: 'text-white bg-basic_green',
    success_light: 'text-basic_green bg-green_pal',
  };

  useEffect(() => {
    setCurrentLabel((label) => ({
      ...label,
      value,
      title,
      variant,
      icon,
    }));
  }, [value, title, variant, icon]);

  const renderLabelIcon = (icon: IconNameType | undefined) => {
    return (
      icon && (
        <div className="mr-[4px]">
          <Icon name={icon} size={14} className={colorsListLabel[variant]} />
        </div>
      )
    );
  };

  function handleShowHidden(status: boolean) {
    onShow?.(status);
    changeOpenLabel(status);
  }

  function onDropLabelSelected(labelItem: LabelDropItem) {
    onDropSelected?.(labelItem);
    onShow?.(false);
    changeOpenLabel(false);
    // setCurrentLabel(labelItem);
  }

  const renderLabelDropList =
    isDrop &&
    dropMenu?.map(({ variant, value, title, icon }) => {
      return (
        <li
          key={value}
          className="inline-flex items-center w-full"
          onClick={onDropLabelSelected?.bind(null, { title, variant, value, icon })}
        >
          <div className={wrapperLabelClassName({ variant: colorsListLabel[variant] })}>
            {renderLabelIcon(icon)}
            <span className={colorsListLabel[variant]}>{title}</span>
          </div>
        </li>
      );
    });

  useOnClickOutside(labelRef, () => handleShowHidden(false));

  return (
    <div className="relative">
      <div
        className={wrapperLabelClassName({ variant: colorsListLabel[currentLabel.variant] })}
        onClick={handleShowHidden.bind(null, !isOpenLabel)}
      >
        {renderLabelIcon(currentLabel.icon)}
        <span className={colorsListLabel[currentLabel.variant]}>{currentLabel.title}</span>
        {isDrop && (
          <div className="ml-[4px] text-3color">
            <ChevronDownV2Icon size={11} className={colorsListLabel[currentLabel.variant]} />
          </div>
        )}
      </div>
      {isDrop && isOpenLabel && (
        <div
          ref={labelRef}
          className={dropClassName({ show: isOpenLabel, isLeft: isDropLeft, isRight: isDropRight })}
        >
          <div>
            <TriangleBottomIcon
              className={triangleDropClassName({ isLeft: isDropLeft, isRight: isDropRight })}
              size={12}
            />
          </div>
          <div>
            <ul className={dropListUlClassName({ isLeft: isDropLeft, isRight: isDropRight })}>
              {renderLabelDropList}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Label;
