import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const AvgConflict: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 57 57"
      >
        <circle cx="28.5" cy="28.5" r="28.5" fill="#EEF9FD"></circle>
        <g clipPath="url(#clip0_2319_16836)">
          <path
            fill="#2987CC"
            d="M44.578 38.21l-12-24a4 4 0 00-7.155 0l-12 24A3.999 3.999 0 0017 44h24a4.001 4.001 0 003.578-5.79zm-13.58.79a1 1 0 01-1 1h-2a.998.998 0 01-1-1v-2a1 1 0 011-1h2a1 1 0 011 1v2zm0-8a1 1 0 01-1 1h-2a1 1 0 01-1-1v-9.999a1 1 0 011-1.002h2a1 1 0 011 1v10.002z"
          ></path>
          <path
            fill="#2987CC"
            d="M30.999 31a1 1 0 01-1 1h-2a1 1 0 01-1-1v-9.999a1 1 0 011-1.002h2a1 1 0 011 1v10.002zM30.999 39a1 1 0 01-1 1h-2a.998.998 0 01-1-1v-2a1 1 0 011-1h2a1 1 0 011 1v2z"
          ></path>
          <path
            fill="#fff"
            d="M32.85 38.304l-1.183-3.764h-5.452l-1.183 3.764h-3.334l5.388-15.519h3.818l5.399 15.52H32.85zm-3.947-12.55l-1.99 6.356h4.055l-1.99-6.356h-.075z"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default AvgConflict;
