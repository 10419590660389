import React, { FC } from 'react';
import { PuzzleIcon } from '@icons';
import { EasyModal } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { IntegrationForm } from '@app/pages/settings/blocks/integration-settings/integration-form';
import { IntegrationAccessKeyObject } from '@app/interfaces/settings.type';
//todo
type ModalWithFormPropsType = {
  isOpenAddIntegrationModal: boolean;
  changeOpenAddIntegrationModal: React.Dispatch<React.SetStateAction<boolean>>;
  changeEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  onFormSubmitHandler(data: { name: string }, newKeys?: boolean): void;
  integrationData: IntegrationAccessKeyObject | undefined;
  editMode: boolean;
  onFormDeleteHandler(): void;
};
export const ModalWithForm: FC<ModalWithFormPropsType> = (props) => {
  const { t } = useTranslation('pages.settings.integrations');
  const {
    isOpenAddIntegrationModal,
    changeOpenAddIntegrationModal,
    editMode,
    changeEditMode,
    integrationData,
    onFormSubmitHandler,
    onFormDeleteHandler,
  } = props;
  return (
    <EasyModal
      show={isOpenAddIntegrationModal}
      onRemove={() => changeEditMode(false)}
      label={
        <div className="flex flex-col gap-[13px] items-center justify-center">
          <div className="w-[57px] h-[57px] rounded-full bg-blue_pal flex items-center justify-center">
            <PuzzleIcon className="text-action" size={25} />
          </div>
          <span className="text-0color text-[24px] font-[700] leading-[29px]">
            {editMode ? t('modal_title.edit') : t('modal_title.create')}
          </span>
        </div>
      }
      onClose={() => {
        editMode && changeEditMode(false);
        changeOpenAddIntegrationModal(false);
      }}
      variant="mediumW650"
      withoutContentPadding
      withoutFooter
    >
      <div className="px-[50px] pb-[48px] pt-[35px]">
        <IntegrationForm
          editMode={editMode}
          onFormSubmitHandler={onFormSubmitHandler}
          integrationData={integrationData}
          onFormDeleteHandler={onFormDeleteHandler}
        />
      </div>
    </EasyModal>
  );
};
