import classNames from 'classnames';

export function getFirstRunSelectionBlockItemClassName(active: boolean) {
  return classNames(
    'w-[264px] h-[226px] overflow-hidden bg-white rounded-defaultR cursor-pointer',
    'shadow-medium',
    active ? 'border-[2px] border-speech_analitics' : 'border-[1px] border-b_medium',
  );
}

export function getFirstRunSketchToBlockClassName(from_template = false) {
  return classNames(
    'w-[264px] h-[162px] flex justify-center items-center',
    !from_template ? 'bg-[#8F529E]/[.1]' : 'bg-bg_1 flex-wrap',
  );
}
