module.exports = {
  title: 'Authorization',
  button: {
    login: 'Login',
  },
  notification: {
    success: 'Authorization successful',
  },
  input: {
    login: 'Login',
    password: 'Password',
    placeholder: {
      login: 'Enter email',
      password: 'Enter Password',
    },
    errors: {
      empty_email: 'The email field is not filled in',
      wrong_email: 'Invalid Login/Password',
      wrong_password: 'Contact your organization administrator',
      empty_password: 'Password not specified',
      length_password: 'Password must be 6 characters long',
    },
  },
};
