import { Button, Input } from '@app/components/ui';
import useTranslation from '@app/hooks/use-translation';
import { ChannelSettingObject, CreateChannelType } from '@app/interfaces/settings.type';
import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Icon from '@app/components/ui/icons';

type CommonSettingsAddFormType = {
  onFormSubmit: (data: CreateChannelType) => void;
  editMode: boolean;
  channelData?: ChannelSettingObject | undefined;
};

export const CommonSettingsAddForm: FC<CommonSettingsAddFormType> = ({
  onFormSubmit,
  editMode,
  channelData,
}) => {
  const { t } = useTranslation('pages.settings');
  const formSetup = useMemo(
    () =>
      editMode
        ? { name: channelData?.name, channel: channelData?.channel, icon: channelData?.icon }
        : undefined,
    [channelData, editMode],
  );
  const { handleSubmit, register, watch, setValue } = useForm<CreateChannelType>({
    defaultValues: formSetup,
  });

  function onSubmit(data) {
    editMode
      ? channelData &&
        onFormSubmit({ ...data, settings_channels_id: channelData.settings_channels_id })
      : onFormSubmit(data);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-[20px]">
        <div className="flex items-center gap-[55px]">
          <span className="text-[15px] font-[500] leading-[110%] text-1color tracking-tight  whitespace-nowrap">
            {t('common_setting.form.channel_name')}
          </span>
          <Input
            placeholder={t('common_setting.form.channel_name_placeholder')}
            {...register('name')}
            name="name"
          />
        </div>
        <div className="flex items-center gap-[60px] pr-[177px]">
          <span className="text-[15px] font-[500] leading-[110%] text-1color tracking-tight  whitespace-nowrap">
            {t('common_setting.form.channel_number')}
          </span>
          <Input {...register('channel')} name="channel" />
        </div>
        <div className={'flex items-start gap-[120px]'}>
          <span
            className={
              'text-[15px] font-[500] leading-[110%] text-1color tracking-tight  whitespace-nowrap'
            }
          >
            {t('common_setting.form.icon')}
          </span>
          <div>
            <div className="flex items-center gap-[8px]">
              <input
                name="type_value"
                onChange={() => setValue('icon', 'operator')}
                type="checkbox"
                checked={watch().icon === 'operator'}
                value={'str'}
                className="focus:ring-offset-0 focus:ring-0 rounded-full"
              />
              <div className="flex items-center h-full gap-[7px]">
                <Icon className="text-3color" size={17} name={'HeadphoneIcon'} />
              </div>
              <span className={'text-1color text-[14px] font-[400] leading-[30px] tracking-tight'}>
                {t('common_setting.form.operator')}
              </span>
            </div>
            <div className="flex items-center gap-[8px]">
              <input
                name="type_value"
                onChange={() => setValue('icon', 'client')}
                type="checkbox"
                checked={watch().icon === 'client'}
                value={'str'}
                className="focus:ring-offset-0 focus:ring-0 rounded-full"
              />
              <div className="flex items-center h-full gap-[7px]">
                <Icon className="text-3color" size={17} name={'ProfileIcon'} />
              </div>
              <span className={'text-1color text-[14px] font-[400] leading-[30px] tracking-tight'}>
                {t('common_setting.form.client')}
              </span>
            </div>
            <div className="flex items-center gap-[8px]">
              <input
                name="type_value"
                onChange={() => setValue('icon', 'other')}
                type="checkbox"
                checked={watch().icon === 'other'}
                value={'str'}
                className="focus:ring-offset-0 focus:ring-0 rounded-full"
              />
              <div className="flex items-center h-full gap-[7px]">
                <Icon className="text-3color" size={17} name={'HelpCircleIcon'} />
              </div>
              <span className={'text-1color text-[14px] font-[400] leading-[30px] tracking-tight'}>
                {t('common_setting.form.other')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full">
        <Button
          type="submit"
          label={t('common_setting.form.button_label')}
          isFull
          className="!px-[58px] !py-[20px] mt-[32px]"
        />
      </div>
    </form>
  );
};
