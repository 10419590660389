module.exports = {
  variables: 'Переменные',
  CANNOT_DELETE_VARIABLE_PROCEDURE: "Невозможно удалить переменную '%1', поскольку она является частью определения функции '%2'",
  add_comment: 'Добавить комментарий',
  expressions: 'Выражения',
  formula_expression: 'Логические функции',
  add: 'Добавить операцию',
  delete: 'Удалить операцию',
  add_val: 'Добавить значение',
  delete_val: 'Удалить значение',
  if: 'Если',
  then: 'Тогда',
  otherwise: 'Иначе',
  not: 'нет',
  metrics: 'Метрики',
  metric: 'Метрика',
  and: 'и',
  or: 'или',
  none_metric: 'Метрика',
  not_help: 'Изменяет результат логического выражения с ИСТИНА на ЛОЖЬ и с ЛОЖЬ на ИСТИНА.',
  logic_operators_help: 'Выполняет логическую операцию И или ИЛИ между заданными выражениями.',
  rate_func: 'Оценивает данное логическое выражение и возвращает со вторым параметром, если оно истинно, или третьим, если оно ложно.',
  formula_relop: 'Выполняет выбранную реляционную операцию с заданными выражениями.',
  formula_math_help: 'Выполняет выбранную математическую операцию с заданными выражениями.',
  logic_func: 'Любое выражение (строка, число, булевое)\nЭтот блок может заменять другие блоки выражения.',
  formula_start: 'Это начало формулы. Все, что вы разместите за пределами этого блока, не будет использовано.',
  success_create_metric: 'Метрика добавлена',
  composite_formula_name: 'формула',
  composite_name: 'Имя метрики',
  composite_success_edit_metric: 'Метрика отредактирована',
  composite_fail_edit_metric: 'Не удалось отредактировать метрику. Возможно формула составлена не верно и не может быть сохранена, просим ознакомится с инструкцией',
  composite_fail_create_metric: 'Не удалось создать метрику. Возможно формула составлена не верно и не может быть сохранена, просим ознакомится с инструкцией',
  composite_fail_metric_formula: 'Неверная формула',
  error_metric_build: 'Ошибка загрузки метрики, попробуйте еще раз',
  logic_expressions: 'Логические',
  math_expressions: 'Математические',
  metric_expressions: 'Метрики',
  select: {
    composite_visualization: {
      label: 'Формат отображения',
      options: {
        composite_visualization_native: 'Стандартный',
        composite_visualization_score: 'Бар от 1 до 5',
        composite_visualization_score100: 'Бар от 0 до 100',
      },
    },
    composite_metric_type: {
      labelDisplayType: 'Выбор типа отображения',
      label: 'Выбрать тип результата',
      options: {
        str: 'Строка',
        num: 'Число',
      },
    },
  },
};
