import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ParkingsignCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_262)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM12.0928 17.6797C12.6289 17.6797 12.9277 17.3193 12.9277 16.748V15.1133H14.5186C16.2676 15.1133 17.4189 14.0234 17.4189 12.3623C17.4189 10.7188 16.2588 9.61133 14.5361 9.61133H12.1191C11.5566 9.61133 11.2578 9.98047 11.2578 10.5605V16.748C11.2578 17.3105 11.5566 17.6797 12.0928 17.6797ZM12.9277 13.8652V10.8594H14.29C15.2305 10.8594 15.8105 11.4219 15.8105 12.3711C15.8105 13.3027 15.2393 13.8652 14.29 13.8652H12.9277Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_262">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ParkingsignCircleIcon;
