import React, { FC, MouseEvent } from 'react';
import { IconNameType } from '@app/components/ui/icons/icon-name.type';
import Icon from '@app/components/ui/icons';
import cn from 'classnames';
import { useNavigate } from 'react-router';

import { v4 } from 'uuid';

import TooltipWrapper from '../tooltip-wrapper';
export type ButtonVariant = 'primary' | 'danger';
type ButtonSize = 'medium' | 'large';
type ButtonProps = {
  fill?: 'filled' | 'outlined' | 'linked';
  label?: string;
  dataId?: string;
  disabled?: boolean;
  icon?: IconNameType;
  sizeIcon?: number;
  variant?: ButtonVariant;
  type?: 'button' | 'submit';
  link?: string;
  className?: string;
  size?: ButtonSize;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  isFull?: boolean;
  hintTitle?: string;
  hintTitlePosition?: 'bottom' | 'top' | 'right' | 'left';
};

const Button: FC<ButtonProps> = (props) => {
  const {
    type = 'button',
    fill = 'filled',
    label,
    isFull = false,
    variant = 'primary',
    icon,
    sizeIcon = 18,
    size = 'medium',
    disabled = false,
    onClick,
    link,
    className,
    hintTitle,
    hintTitlePosition = 'bottom',
    dataId,
    ...remindedProps
  } = props;
  const navigate = useNavigate();
  const buttonSize: Record<ButtonSize, string> = {
    medium: 'h-[34px]',
    large: 'h-[44px]',
  };
  const variantListStyle: Record<ButtonVariant, string> = {
    primary: cn(
      fill === 'filled' &&
        (disabled
          ? 'border-bg-b-light bg-b-light text-4color border-[1px]'
          : 'border-[1px] border-action bg-action text-white'),
      fill === 'outlined' &&
        (disabled
          ? 'text-4color border-b-light bg-transparent'
          : 'text-action border-[1px] border-action bg-transparent'),
      fill === 'linked' &&
        (disabled
          ? 'border-[1px] text-4color border-transparent'
          : 'border-[1px] border-transparent text-action'),
    ),
    danger: cn(
      fill === 'filled' &&
        (disabled
          ? 'bg-red_pal text-white border-[1px] border-red_pal'
          : 'bg-basic_red border-[1px] border-basic_red text-white'),
      fill === 'outlined' &&
        (disabled
          ? 'text-red_pal border-red_pal border-[1px] bg-transparent'
          : 'text-basic_red border-[1px] border-basic_red bg-transparent'),
      fill === 'linked' &&
        (disabled ? 'text-red_pal border-transparent' : 'text-basic_red border-transparent'),
    ),
  };

  const renderButtonIcon = icon && (
    <Icon
      name={icon}
      size={sizeIcon}
      className={cn(variantListStyle[variant], 'border-none', !!label && 'mr-[10px]')}
    />
  );

  function handlerOnClickButton(event: MouseEvent<HTMLButtonElement>) {
    if (link) navigate(link);
    else onClick?.(event);
  }
  return (
    <div className={`${!disabled && 'active:scale-95 shadow-lg hover:opacity-90'} inline-block `}>
      <TooltipWrapper place={hintTitlePosition} content={hintTitle} id={hintTitle + v4()}>
        <button
          type={type}
          onClick={(event) => handlerOnClickButton(event)}
          {...(dataId ? { 'data-id': dataId } : {})}
          className={cn(
            isFull && 'w-full',
            variantListStyle[variant],
            'rounded-[10px] text-[15px] font-bold',
            'transition',
            'flex items-center justify-center',
            label ? 'px-[18px]' : 'px-[11px]',
            buttonSize[size],
            className || '',
          )}
          disabled={disabled}
          {...remindedProps}
        >
          <div
            className={cn(
              variantListStyle[variant],
              'border-none',
              'inline-flex items-center',
              // icon && ' mt-[3px]',
            )}
          >
            {renderButtonIcon}
            <div className={cn('truncate border-none', variantListStyle[variant])}>{label}</div>
          </div>
        </button>
      </TooltipWrapper>
    </div>
  );
};

export default Button;
