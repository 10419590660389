import React from 'react';

import { Outlet } from 'react-router-dom';

import HeaderBlock from './blocks/header.block';
import FooterBlock from './blocks/footer.block';

const EmailLayout = () => {
  return (
    <div className="min-h-screen relative grid grid-cols-1 grid-rows-[auto_1fr_auto]">
      <div className="z-[99] sticky top-0">
        <HeaderBlock />
      </div>
      <main className=" h-full px-[20px]">
        <Outlet />
      </main>
      <div>
        <FooterBlock />
      </div>
    </div>
  );
};

export default EmailLayout;
