import { Button, CircleButton, Input, Select, TooltipWrapper } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { AllStatusProject } from '@app/interfaces/analytics';
import {
  MetricObjType,
  MetricTypesArrType,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { useGetAliasesListQuery } from '@app/store/api/alias-settings-page.api';
import {
  useCreateProjectMetricGroupMutation,
  useEditProjectMetricGroupMutation,
} from '@app/store/api/metrics.api';
import {
  useCreateProjectChatMetricGroupMutation,
  useEditProjectChatMetricGroupMutation,
} from '@app/store/api/chat-api/chat-metric.api';
type FormType = {
  name: string;
  alias_id: string;
};
export const MetaMetricModal: React.FC<{
  metric: MetricTypesArrType;
  editModal?: boolean;
  modalsSetState: (type: string) => void;
  status?: AllStatusProject | undefined;
  unitVal?: string;
  metricSettings?: MetricObjType | undefined;
  currentTemplate: 'chat' | 'voice';
}> = ({ metric, editModal, modalsSetState, status, metricSettings, currentTemplate }) => {
  const { id } = useParams();
  const { t } = useTranslation('pages.metricTemplates');
  const { t: tf } = useTranslation('common.forms');

  // api
  const { data: aliases } = useGetAliasesListQuery();
  // const [, { data: metricSettings }] = useLazyGetProjectMetricQuery();
  const [createMetricChat] = useCreateProjectChatMetricGroupMutation();
  const [editMetricChat] = useEditProjectChatMetricGroupMutation();
  const [createMetricVoice] = useCreateProjectMetricGroupMutation();
  const [editMetricVoice] = useEditProjectMetricGroupMutation();
  const createMetric = { chat: createMetricChat, voice: createMetricVoice };
  const editMetric = { chat: editMetricChat, voice: editMetricVoice };
  const defaultValues: FormType = useMemo(
    () =>
      editModal
        ? {
            name: metricSettings?.name || '',
            alias_id: metricSettings?.settings?.alias_id || 'default',
          }
        : { name: '', alias_id: 'default' },
    [editModal, metricSettings],
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: defaultValues,
  });
  const disableFunc = () => {
    if (status === AllStatusProject.PAUSE) {
      return false;
    }
    return status !== AllStatusProject.CREATED;
  };
  const copyToClipboard = useCallback(() => {
    event?.preventDefault();
    const textToCopy = metricSettings?.metric_id;
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy);
    }
  }, [metricSettings?.metric_id]);

  const closeModal = () => {
    modalsSetState(metric.type);
  };

  const getResultValueType = (id: string) => {
    return aliases?.aliases.find((alias) => alias.alias_id === id)?.type_value;
  };

  const onSubmit = (data: FormType) => {
    const obj: MetricObjType = {
      name: data.name,
      description: ' ',
      type: metric.type,
      result_value_type: getResultValueType(watch('alias_id')),
      settings: {
        alias_id: data.alias_id,
      },
    };
    if (!editModal) {
      id && createMetric[currentTemplate || 'voice']({ params: { project_id: id }, body: obj });
    } else {
      if (id && metricSettings?.metric_id) {
        editMetric[currentTemplate || 'voice']({
          params: { project_id: id, metric_id: metricSettings.metric_id },
          body: obj,
        });
      }
    }
    closeModal();
  };
  const selectSetter = (value: string | number) => {
    setValue('name', aliases?.aliases.find((alias) => alias.alias_id === value)?.name as string);
    setValue('alias_id', value as string);
    errors.alias_id?.message && clearErrors();
  };
  const getSelectItems = () => {
    return (
      aliases?.aliases
        .filter((e) => e.type_meta !== 'system')
        .map((e) => {
          return { title: e.name, value: e.alias_id };
        }) || []
    );
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="min-w-[550px] m-[0px_auto] px-[50px]">
        <div className="flex flex-col gap-[20px]">
          <div className="flex items-center justify-between">
            <span className="text-0color text-[15px]">{t('metric_group')}</span>
            <span className="mr-[168px]">
              <b>
                {metric.group.toUpperCase()} {t('one_metric')}
              </b>
            </span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-0color text-[15px]">{t('alias')}</span>
            <div className="w-[280px]">
              <Controller
                render={() => (
                  <Select
                    dropWidth={276}
                    dropHeight={130}
                    defaultValue={watch('alias_id')}
                    error={errors.alias_id?.message}
                    options={[
                      ...getSelectItems(),
                      {
                        title: t('choose') + '...',
                        value: 'default',
                        disabled: true,
                        nullable: true,
                      },
                    ]}
                    onChange={selectSetter}
                  />
                )}
                name={'alias_id'}
                rules={{ validate: (value) => value !== 'default' || tf('required') }}
                control={control}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-0color text-[15px]">{t('metric_name')}</span>
            <div className="w-[280px]">
              <Input
                error={errors.name?.message}
                {...register('name', { required: { value: true, message: tf('required') } })}
                name={'name'}
              />
            </div>
          </div>
          {editModal && (
            <div className="flex items-center justify-between">
              <span className="text-0color text-[15px]">{t('metric_id')}</span>
              <div className="flex gap-1">
                <p>{metricSettings?.metric_id}</p>
                <TooltipWrapper content={t('popup_hints.copy_id')} id={'meta_metrick_copy'}>
                  <CircleButton
                    onClick={copyToClipboard}
                    icon="CopyIcon"
                    className="text-action mb-[5px]"
                    size={16}
                  />
                </TooltipWrapper>
              </div>
            </div>
          )}
          <div className="sticky bottom-0">
            <div className="bg-[#fff]">
              <div className="flex items-center gap-[15px] pt-4 pb-[40px]">
                <Button type="submit" disabled={disableFunc()} label={t('save')} />
                <Button onClick={() => closeModal()} fill="linked" label={t('cancel')} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
