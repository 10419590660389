import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const PuzzleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 23 18">
        <path
          fill="currentColor"
          d="M.462 14.517c0 1.766.984 2.733 2.786 2.733h10.837c1.793 0 2.777-.967 2.777-2.733v-2.945c0-.14.097-.21.264-.105.518.351 1.125.598 1.82.598 1.704 0 3.26-1.345 3.26-3.393S20.65 5.27 18.946 5.27c-.695 0-1.302.246-1.82.597-.167.106-.264.044-.264-.105V2.766c0-1.767-.984-2.734-2.777-2.734H3.248C1.446.032.462 1 .462 2.766v3.876c0 .79.527 1.292 1.204 1.292.378 0 .73-.15 1.072-.449.413-.36.87-.597 1.371-.597.958 0 1.74.694 1.74 1.766 0 1.064-.782 1.767-1.74 1.767-.5 0-.958-.246-1.37-.607-.343-.29-.695-.448-1.073-.448-.677 0-1.204.501-1.204 1.292v3.859zm1.652-.08V11.66c0-.369.194-.246.36-.14.502.307 1.064.527 1.723.527 1.697 0 3.252-1.354 3.252-3.393 0-2.048-1.555-3.401-3.252-3.401-.659 0-1.221.228-1.722.536-.167.106-.36.229-.36-.15V2.846c0-.747.43-1.16 1.15-1.16H14.06c.72 0 1.16.413 1.16 1.16v3.797c0 .79.518 1.31 1.195 1.31.378 0 .73-.15 1.072-.45.413-.36.87-.597 1.38-.597.95 0 1.732.703 1.732 1.767 0 1.063-.783 1.766-1.732 1.766-.51 0-.967-.237-1.38-.597-.342-.299-.694-.457-1.072-.457-.677 0-1.195.527-1.195 1.318v3.736c0 .747-.44 1.168-1.16 1.168H3.266c-.721 0-1.152-.421-1.152-1.168z"
        ></path>
      </svg>
    </div>
  );
};

export default PuzzleIcon;
