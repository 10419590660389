import React, { FC } from 'react';

import { IconType } from '../icon.type';

const TagSquareIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_606_14)">
          <path
            d="M8.67383 21.8721H19.3174C21.2158 21.8721 22.2002 20.8877 22.2002 19.0156V8.31934C22.2002 6.43848 21.2158 5.46289 19.3174 5.46289H8.67383C6.77539 5.46289 5.79102 6.43848 5.79102 8.31934V19.0156C5.79102 20.8965 6.77539 21.8721 8.67383 21.8721ZM8.7793 20.1494C7.96191 20.1494 7.51367 19.7188 7.51367 18.8662V8.45996C7.51367 7.60742 7.96191 7.18555 8.7793 7.18555H19.2119C20.0205 7.18555 20.4775 7.60742 20.4775 8.45996V18.8662C20.4775 19.7188 20.0205 20.1494 19.2119 20.1494H8.7793ZM12.1631 18.1719C12.7168 18.7256 13.2881 18.7607 13.8594 18.1895L18.0693 14.0059C18.4033 13.6895 18.6318 13.4521 18.6318 12.9248V11.2988C18.6318 10.8945 18.5264 10.666 18.2539 10.3848L17.4717 9.61133C17.1992 9.33887 16.9619 9.18066 16.5576 9.18066H14.8789C14.3604 9.18066 14.123 9.41797 13.7979 9.74316L9.63184 13.9531C9.06055 14.5244 9.08691 15.0869 9.64062 15.6406L12.1631 18.1719ZM15.4766 12.3359C15.2217 12.0811 15.2217 11.6504 15.4854 11.3955C15.7402 11.1318 16.1533 11.1318 16.417 11.3955C16.6719 11.6416 16.6719 12.0635 16.4082 12.3271C16.1533 12.5908 15.7314 12.5908 15.4766 12.3359Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_606_14">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default TagSquareIcon;
