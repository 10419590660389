import React, { FC } from 'react';

import { IconType } from '../icon.type';

const Rectangle2SwapIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_227)">
          <path
            d="M4.79785 12.415H11.1084C13.0068 12.415 13.9912 11.4307 13.9912 9.55859V6.13965C13.9912 4.26758 13.0068 3.2832 11.1084 3.2832H4.79785C2.89941 3.2832 1.91504 4.25879 1.91504 6.13965V9.55859C1.91504 11.4395 2.89941 12.415 4.79785 12.415ZM4.90332 10.6924C4.08594 10.6924 3.6377 10.2617 3.6377 9.40918V6.28906C3.6377 5.43652 4.08594 5.00586 4.90332 5.00586H11.0117C11.8203 5.00586 12.2686 5.43652 12.2686 6.28906V9.40918C12.2686 10.2617 11.8203 10.6924 11.0117 10.6924H4.90332ZM21.2422 13.2676C21.5234 13.6455 21.8662 13.6367 22.1475 13.2676L23.8613 10.9648C24.248 10.4551 24.0723 9.91016 23.3955 9.91016H22.2617V9.6377C22.2617 7.0625 20.4951 5.2959 17.9111 5.2959H16.5225C16.1797 5.2959 15.8896 5.58594 15.8896 5.92871C15.8896 6.28027 16.1797 6.57031 16.5225 6.57031H17.9023C19.7568 6.57031 21.0752 7.77441 21.0752 9.6377V9.91016H19.9854C19.335 9.91016 19.1592 10.4551 19.5371 10.9648L21.2422 13.2676ZM10.0801 22.0039H11.46C11.8115 22.0039 12.0928 21.7227 12.0928 21.3711C12.0928 21.0283 11.8115 20.7383 11.46 20.7383H10.0801C8.22559 20.7383 6.90723 19.5342 6.90723 17.6709V17.3896H7.99707C8.65625 17.3896 8.82324 16.8535 8.44531 16.3438L6.74902 14.0322C6.46777 13.6543 6.11621 13.6631 5.84375 14.0322L4.12988 16.3438C3.74316 16.8535 3.91016 17.3896 4.58691 17.3896H5.72949V17.6709C5.72949 20.2461 7.4873 22.0039 10.0801 22.0039ZM16.874 24.0166H23.1934C25.083 24.0166 26.0762 23.041 26.0762 21.1689V17.75C26.0762 15.8691 25.083 14.8936 23.1934 14.8936H16.874C14.9844 14.8936 13.9912 15.8691 13.9912 17.75V21.1689C13.9912 23.0498 14.9844 24.0166 16.874 24.0166ZM16.9795 22.2939C16.1621 22.2939 15.7139 21.8721 15.7139 21.0195V17.8994C15.7139 17.0381 16.1621 16.6162 16.9795 16.6162H23.0879C23.8965 16.6162 24.3535 17.0381 24.3535 17.8994V21.0195C24.3535 21.8721 23.8965 22.2939 23.0879 22.2939H16.9795Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_227">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Rectangle2SwapIcon;
