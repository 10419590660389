import cn from 'classnames';

export const gridButtonClassName = cn(
  'bg-speech_analitics',
  'cursor-pointer',
  'ease-in',
  'transition',
  'hover:translate-x-0.5',
  'inline-flex',
  'items-center',
  'justify-center',
  'w-[50px]',
  'ml-[-7px]',
  'rounded-r-full',
  'h-[36px]',
);

export const collapsedButtonClassName = (isCollapsed: boolean) =>
  cn(isCollapsed && ' ml-[24px] mb-[21px]');

export const collapseBarClassName = cn(
  'w-[22px]',
  'cursor-pointer',
  'hover:opacity-80',
  'h-[22px]',
  'mb-[9px]',
  'mr-[9px]',
  'rounded-[4px]',
  'bg-bg_4',
  'inline-flex',
  'items-center',
  'justify-center',
);

export const menuBarClassName = ({ isCollapsed }: { isCollapsed: boolean }) =>
  cn(
    isCollapsed ? 'w-[64px]' : 'w-[295px]',
    'h-[calc(100vh-67px)]',
    'bg-white',
    'ease-in-out',
    'z-30',
    'duration-75',
    'overflow-y-auto',
  );

export const menuBarItemClassName = ({
  isActive,
  isCollapsed,
}: {
  isActive: boolean;
  isCollapsed: boolean;
}) =>
  cn(
    'py-2',
    'block',
    'my-1',
    'ease-in-out',
    'group',
    'inline-flex',
    'items-center',
    'w-full',
    isCollapsed
      ? cn(
          isActive ? 'bg-basic_app_bg isActiveBar' : 'isNoActiveBar',
          'mx-[9px]',
          '!w-[44px]',
          'h-[44px]',
          'mb-[12px]',
          'justify-center',
          'rounded-[10px]',
        )
      : 'rounded-full px-[16px] isNoActiveBar',
    isActive ? ' bg-basic_app_bg ' : 'hover:bg-basic_app_bg',
  );

export const menuBarItemTextClassName = cn(
  'ease-in-out',
  'ml-[16px]',
  'group-hover:text-primary',
  'text-[15px]',
);

export const menuSecondBarClassName = ({ isOpen }: { isOpen: boolean }) =>
  cn(
    'bg-speech_analitics',
    'h-full',
    'top-0',
    'w-[332px]',
    'left-[-332px]',
    'z-[99999]',
    'duration-150',
    'fixed',
    'ease-in-out',
    isOpen && 'translate-x-[332px]',
  );

export const menuSecondBarItem = ({ isActive }: { isActive: boolean }) =>
  cn(
    isActive ? 'bg-speech_analitics_middle text-white' : 'text-speech_analitics_light',
    'py-[8px]',
    'w-[175px]',
    'block',
    'mx-[45px]',
    'px-[17px]',
    'text-[17px]',
    'font-[700]',
    'rounded-[10px]',
    'z-[99999]',
    'hover:bg-speech_analitics_middle',
  );
