import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const TagsMetricIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 57 57"
      >
        <g clipPath="url(#clip0_10498_31498)">
          <circle cx="28.5" cy="28.5" r="28.5" fill="#EEF9FD"></circle>
          <path
            fill="#2987CC"
            d="M22.402 15a6.337 6.337 0 016.299 5.785c.011.16.023.331.011.434-.022.549-.445.926-1.017.926-.526 0-.88-.331-.949-.903-.011-.091-.011-.149-.011-.217C26.552 18.76 24.665 17 22.402 17c-2.435 0-4.401 1.954-4.401 4.378 0 1.018.354 1.99.926 2.732.571.755-.012 1.6-.778 1.6-.32 0-.64-.136-.892-.48C16.48 24.192 16 22.763 16 21.38 16 17.858 18.87 15 22.402 15zm11.066 26.637c-4.047 1.486-7.728.354-10.769-3.304l-3.921-4.71a4.23 4.23 0 01-.674-1.178c-.492-1.36.217-2.549 1.474-3.006.8-.297 1.624-.126 2.31.503l.971 1.029c.034.034.069.045.103.034.057-.011.069-.069.046-.126l-2.984-8.197c-.572-1.566.092-2.983 1.6-3.532 1.475-.537 2.882.126 3.453 1.692l1.098 2.995c.16-.114.331-.217.514-.274.972-.355 1.91-.092 2.572.663.24-.183.503-.32.8-.435.95-.343 1.875-.114 2.481.583 2.16-.72 4.024.389 4.962 2.984l1.006 2.732c1.955 5.385-.046 9.73-5.042 11.547zm-.697-2.104c3.841-1.395 5.247-4.664 3.704-8.917l-.926-2.55c-.503-1.382-1.223-1.977-2.024-1.68l.355.972a.917.917 0 01-.549 1.166c-.469.16-.995-.046-1.178-.537l-.617-1.703c-.16-.435-.537-.63-.96-.47a1.451 1.451 0 00-.492.298l.686 1.91a.868.868 0 01-.526 1.13c-.468.172-.983-.033-1.166-.525l-.926-2.56c-.171-.447-.537-.63-.96-.48a1.829 1.829 0 00-.503.296l1.006 2.756a.904.904 0 01-.549 1.154.905.905 0 01-1.166-.526l-2.858-7.854c-.137-.365-.48-.537-.846-.411-.354.137-.514.491-.377.857l4.093 11.284c.24.629-.046 1.2-.583 1.394-.435.16-.892.103-1.36-.354l-2.79-2.744c-.218-.24-.457-.343-.732-.24-.366.137-.492.469-.377.766.057.149.114.252.217.366l3.967 4.607c2.607 3.052 5.373 3.716 8.437 2.595z"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default TagsMetricIcon;
