import React, { FC } from 'react';
import LayoutOutlet from '@app/layouts/layout';
import { useLocation } from 'react-router-dom';
import * as Layouts from '@app/layouts';

type LayoutSwitcherProps = { switchOffLayout: { [path: string]: string } };
const LayoutSwitcher: FC<LayoutSwitcherProps> = (props) => {
  const { switchOffLayout } = props;
  const location = useLocation();
  const currentLocationKey = location.pathname.replace('/', '');
  if (switchOffLayout && Object.keys(switchOffLayout).includes(currentLocationKey)) {
    const CurrentLayout = Object(Layouts)[switchOffLayout[currentLocationKey]];
    return <CurrentLayout />;
  }
  return <LayoutOutlet />;
};

export default LayoutSwitcher;
