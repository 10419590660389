import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  NGramCloudStatusType,
  WordsNGramSettingsResponse,
  WordsNGramSettingsUpdateType,
  WordsNGramStaticRequestType,
  WordsNGramStaticResponseType,
} from '@app/interfaces/words-n-gramm.type';

import { baseQuery } from '../baseQuery';
export const wordsNGramApi = createApi({
  reducerPath: 'wordsNGramApi',
  tagTypes: [
    'wordsNGram',
    'wordsNGramSettings',
    'cloudStatus',
    'wordsNGramChat',
    'wordsNGramSettingsChat',
    'cloudStatusChat',
  ],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    // voice cloud
    getWordsNGramStaticCloud: build.query<
      WordsNGramStaticResponseType,
      WordsNGramStaticRequestType
    >({
      query: (params) => ({
        url: serverRoutes.gramCloudData,
        params: params,
      }),
      providesTags: ['wordsNGram'],
    }),
    getWordsNGramSettings: build.query<WordsNGramSettingsResponse, { live_report_id: string }>({
      query: (params) => ({
        url: serverRoutes.gramCloud,
        params,
      }),
      providesTags: ['wordsNGramSettings'],
    }),
    updateWordsNGramStaticCloudSettings: build.mutation({
      query: ({ params, body }: WordsNGramSettingsUpdateType) => ({
        url: serverRoutes.gramCloud,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['wordsNGramSettings'],
    }),
    startCalculateWordNGramCloud: build.mutation({
      query: (params: { live_report_id: string }) => ({
        url: serverRoutes.gramCloudUpdate,
        method: 'PUT',
        params,
      }),
      invalidatesTags: ['cloudStatus'],
    }),
    getCloudStatus: build.query<NGramCloudStatusType, { live_report_id: string }>({
      query: (params) => ({
        url: serverRoutes.gramCloudStatus,
        method: 'GET',
        params,
      }),
      providesTags: ['cloudStatus'],
    }),
    // voice cloud
    // chat cloud
    getChatWordsNGramStaticCloud: build.query<
      WordsNGramStaticResponseType,
      WordsNGramStaticRequestType
    >({
      query: (params) => ({
        url: serverRoutes.gramCloudDataChat,
        params: params,
      }),
      providesTags: ['wordsNGramChat'],
    }),
    getChatWordsNGramSettings: build.query<WordsNGramSettingsResponse, { live_report_id: string }>({
      query: (params) => ({
        url: serverRoutes.gramCloudChat,
        params,
      }),
      providesTags: ['wordsNGramSettingsChat'],
    }),
    updateChatWordsNGramStaticCloudSettings: build.mutation({
      query: ({ params, body }: WordsNGramSettingsUpdateType) => ({
        url: serverRoutes.gramCloudChat,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['wordsNGramSettingsChat'],
    }),
    startCalculateChatWordNGramCloud: build.mutation({
      query: (params: { live_report_id: string }) => ({
        url: serverRoutes.gramCloudUpdateChat,
        method: 'PUT',
        params,
      }),
      invalidatesTags: ['cloudStatusChat'],
    }),
    getChatCloudStatus: build.query<NGramCloudStatusType, { live_report_id: string }>({
      query: (params) => ({
        url: serverRoutes.gramCloudStatusChat,
        method: 'GET',
        params,
      }),
      providesTags: ['cloudStatusChat'],
    }),
    // chat cloud
  }),
});

export const {
  useGetWordsNGramStaticCloudQuery,
  useUpdateWordsNGramStaticCloudSettingsMutation,
  useStartCalculateWordNGramCloudMutation,
  useGetWordsNGramSettingsQuery,
  useGetCloudStatusQuery,
  useGetChatCloudStatusQuery,
  useGetChatWordsNGramSettingsQuery,
  useGetChatWordsNGramStaticCloudQuery,
  useStartCalculateChatWordNGramCloudMutation,
  useUpdateChatWordsNGramStaticCloudSettingsMutation,
} = wordsNGramApi;
