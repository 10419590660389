export type NavigationRoute =
  | 'main'
  | 'records'
  | 'analytics'
  | 'reports'
  | 'presets'
  | 'templates'
  | 'settings'
  | 'users'
  | 'dictionaries'
  | 'createDictionaries'
  | 'authorization'
  | 'projects'
  | 'metric'
  | 'triggers'
  | 'newProject'
  | 'reportCharts'
  | 'reportRecords'
  | 'projectRecords'
  | 'auditLogs'
  | 'wordsCloud'
  | 'error'
  | 'settingsAlias'
  | 'settingsRecognitionRules'
  | 'integration'
  | 'wordsNGramm'
  | 'login'
  | 'manualRecordingUpload'
  | 'staticReport'
  | 'messagesHistory'
  | 'newChatProject'
  | 'messagesManaging'
  | 'settingsCommon'
  | 'subscribe'
  | 'subscribeError'
  | 'unsubscribeFromTrigger'
  | 'unsubscribeFromAll'
  | 'unsubscribeFromReport'
  | 'unsubscribeFromTriggerError'
  | 'unsubscribeFromAllError'
  | 'unsubscribeFromReportError'
  | 'chatReports'
  | 'chatWordsNGramm'
  | 'chatProjectRecords';

export type ChatNavigationRoutes = 'allChats' | 'chatAnalytic' | 'chatMetricTemplates';
export type NavigationType = NavigationRoute | ChatNavigationRoutes;

export const navigationRoutes: Record<NavigationType, string> = {
  main: '/',
  login: 'authorization',
  records: 'records',
  analytics: 'analytics',
  reports: 'reports',
  chatReports: 'chat-reports',
  presets: 'presets',
  templates: 'templates',
  settings: 'settings',
  users: 'users',
  settingsCommon: 'common',
  settingsAlias: 'alias',
  settingsRecognitionRules: 'recognitionRules',
  newChatProject: 'new_chat_project',
  dictionaries: 'dictionaries',
  createDictionaries: 'createDictionaries',
  authorization: 'authorization',
  projectRecords: 'project-records',
  chatProjectRecords: 'chat-project-records',
  projects: 'projects',
  metric: 'metricTemplates',
  newProject: 'new_project',
  triggers: 'triggers',
  reportCharts: 'report-charts',
  reportRecords: 'report-records',
  auditLogs: 'audit-logs',
  wordsCloud: 'words-cloud',
  wordsNGramm: 'words-phrase-cloud',
  chatWordsNGramm: 'chat-words-phrase-cloud',
  error: 'error',
  integration: 'integrationSettings',
  manualRecordingUpload: 'manualRecordingUpload',
  staticReport: 'staticReport',
  messagesHistory: 'messagesHistory',
  messagesManaging: 'messagesManaging',
  allChats: 'chats',
  chatAnalytic: 'chatAnalytic',
  chatMetricTemplates: 'chatMetricTemplates',
  subscribe: 'subscribe',
  subscribeError: 'subscribe_error',
  unsubscribeFromTrigger: 'unsubscribe_trigger',
  unsubscribeFromTriggerError: 'unsubscribe_trigger_error',
  unsubscribeFromAll: 'unsubscribe',
  unsubscribeFromAllError: 'unsubscribe_error',
  unsubscribeFromReport: 'unsubscribe_report',
  unsubscribeFromReportError: 'unsubscribe_report_error',
};

export type NavigationRoutes = typeof navigationRoutes;
