import { StatusVariant } from '@app/components/status-label/status-label';
import { ReportChartGraphType } from '@app/interfaces/report.type';

export const reportStatusPropertiesData: Partial<Record<StatusVariant, StatusVariant[]>> = {
  CREATED: ['UPDATING', 'ARCHIVE', 'DELETING', 'PAUSE'],
  UPDATING: ['ARCHIVE', 'DELETING', 'PAUSE'],
  DONE: ['UPDATING', 'ARCHIVE', 'DELETING', 'PAUSE', 'DONE'],
  ARCHIVE: ['UPDATING', 'DELETING', 'PAUSE'],
  PAUSE: ['UPDATING', 'ARCHIVE', 'DELETING'],
  ERROR: ['UPDATING', 'ARCHIVE', 'DELETING', 'PAUSE'],
};
export const chartsTypeData: { name: string; type: ReportChartGraphType }[] = [
  {
    name: 'barchart',
    type: 'barchart',
  },
  {
    name: 'horizontal_barchart',
    type: 'horizontal_barchart',
  },
  {
    name: 'columnchart',
    type: 'columnchart',
  },
  {
    name: 'piechart',
    type: 'piechart',
  },
  {
    name: 'linechart',
    type: 'linechart',
  },
  // {
  //   name: 'number',
  //   type: 'number',
  // },
];
