import React, { FC } from 'react';

import { IconType } from '../icon.type';

const IphoneHomebuttonSlashCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_609_187)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM15.9072 8.15234H12.0928C11.4951 8.15234 11.0645 8.41602 10.8535 8.88184V8.9082L12.0049 10.0596V9.99805H16.4082V14.4717L17.3486 15.4121V9.54102C17.3486 8.71484 16.7686 8.15234 15.9072 8.15234ZM12.9805 9.11035C12.9805 8.97852 13.0684 8.89941 13.1826 8.89941H14.8086C14.9316 8.89941 15.0107 8.97852 15.0107 9.11035C15.0107 9.2334 14.9316 9.3125 14.8086 9.3125H13.1826C13.0684 9.3125 12.9805 9.2334 12.9805 9.11035ZM17.7793 18.0576C17.9463 18.2246 18.2275 18.2246 18.3945 18.0576C18.5703 17.8818 18.5703 17.6094 18.3945 17.4424L10.2031 9.24219C10.0273 9.06641 9.74609 9.06641 9.5791 9.24219C9.40332 9.41797 9.40332 9.69922 9.5791 9.86621L17.7793 18.0576ZM12.0928 19.1562H15.9072C16.4873 19.1562 16.9268 18.8926 17.1289 18.4355V18.4004L15.96 17.2314V17.3105H11.583V12.8545L10.6426 11.9141V17.7676C10.6426 18.5938 11.2227 19.1562 12.0928 19.1562ZM14.0088 18.5938C13.7627 18.5938 13.5518 18.3916 13.5518 18.1455C13.5518 17.9082 13.7627 17.7061 14.0088 17.7061C14.2461 17.7061 14.4395 17.9082 14.4395 18.1455C14.4395 18.3916 14.2461 18.5938 14.0088 18.5938Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_609_187">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default IphoneHomebuttonSlashCircleIcon;
