import { PageHeader, Paginator } from '@app/components/ui';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { TableColumn } from '@app/components/rubber-table/table.types';
import { TableFilter } from '@app/components/table/table.type';
import {
  auditLogObjectType,
  auditLogsRequestType,
} from '@app/interfaces/slices-types/audit-logs-slice.type';
import { useGetAuditLogsQuery, useLazyGetAuditLogDetailQuery } from '@app/store/api/audit-logs.api';
import React, { useCallback, useEffect, useState } from 'react';
import { EasyModal, SkeletonPageLoading } from '@ui';
import { v4 } from 'uuid';
import { useAppSelector } from '@app/store/store';
import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';
import RubberTable from '@app/components/rubber-table';
import useTranslation from '@app/hooks/use-translation';
const AuditLogs = () => {
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const { t } = useTranslation('pages.auditLogs');
  const [auditLogsRequestBody, changeAuditLogsRequestBody] = useState<auditLogsRequestType>({
    offset: 0,
    limit: userSettings?.tablesLimit?.auditLogs || 10,
  });
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const { data: auditLogs, isFetching: isLoading } = useGetAuditLogsQuery(auditLogsRequestBody);
  const [getAuditLog] = useLazyGetAuditLogDetailQuery();
  const [detailInfoModalState, changeDetailInfoModalState] = useState(false);
  const [auditLogInfo, changeAuditLogInfo] = useState<auditLogObjectType>();
  function auditLogHandleClick(id: string) {
    getAuditLog(id).then((data) => {
      changeAuditLogInfo(data.data);
      changeDetailInfoModalState(true);
    });
  }

  useEffect(() => {
    userSettings?.tablesLimit?.auditLogs &&
      changeAuditLogsRequestBody((prev) => ({
        ...prev,
        limit: userSettings?.tablesLimit?.auditLogs as number,
      }));
  }, [userSettings?.tablesLimit?.auditLogs]);
  const getAuditLogInfo = useCallback(() => {
    if (!auditLogInfo) return;
    const detailInfoArray = Object.entries(auditLogInfo);
    return detailInfoArray.slice(0, detailInfoArray.length - 2);
  }, [auditLogInfo]);
  const cols: TableColumn[] = auditLogs?.audit_logs?.length
    ? Object.keys(auditLogs?.audit_logs[0]).map((item, index) => ({
        index: item,
        title: item,
        filter: true,
        truncate: Object.keys(auditLogs?.audit_logs[0]).length - 1 === index,
      }))
    : [];
  const data = auditLogs?.audit_logs.map((item) => ({
    ...item,
    action_success: item.action_success.toString(),
    audit_logs_id: (
      <div
        onClick={() => auditLogHandleClick(item.audit_logs_id)}
        className="hover:text-4color cursor-pointer"
      >
        {item.audit_logs_id}
      </div>
    ),
  }));
  function handlePageChange({ offset, limit }: PaginationResponse) {
    if (auditLogsRequestBody.limit !== limit) {
      updateUserSettings({
        ...userSettings,
        tablesLimit: { ...userSettings?.tablesLimit, auditLogs: limit },
      });
      changeAuditLogsRequestBody((prev) => ({ ...prev, limit: limit }));
    }
    auditLogsRequestBody.offset !== offset &&
      changeAuditLogsRequestBody((prev) => ({ ...prev, offset: offset }));
  }
  function onChangeFilterHandler(filter: TableFilter) {
    const filterValue = Object.entries(filter)?.[0];
    const sortBy = filterValue?.[0];
    const sortDesc = sortBy ? filterValue?.[1] === 1 : undefined;
    changeAuditLogsRequestBody((prev) => ({ ...prev, sortBy: sortBy, sortDesc: sortDesc }));
  }

  if (isLoading) return <SkeletonPageLoading />;
  return (
    <div>
      <PageHeader icon="BookOpenIcon" label={t('audit_logs')} />
      <RubberTable onFilter={onChangeFilterHandler} columns={cols} dataSource={data || []} />
      <div className="mt-[25px] pb-[20px]">
        <Paginator
          page={
            Math.ceil(
              (auditLogsRequestBody.offset as number) / (auditLogsRequestBody.limit as number),
            ) + 1
          }
          visiblePages={1}
          limit={auditLogsRequestBody.limit as number}
          count={auditLogs?.total || 0}
          onChange={handlePageChange}
        />
      </div>
      <EasyModal
        variant={'mediumW650'}
        show={detailInfoModalState}
        onClose={changeDetailInfoModalState.bind(null, false)}
        withoutContentPadding
        withoutFooter
      >
        <div className="flex flex-col px-[50px] py-[20px] item-start gap-[10px]">
          {getAuditLogInfo()?.map((item) => (
            <div
              className="flex items-center gap-[10px] leading-[110%] text-3color text-[18px] tracking-tight"
              key={v4()}
            >
              <div className="font-[600]">{item[0]}</div>
              <div className="font-[400]">{String(item[1])}</div>
            </div>
          ))}
        </div>
      </EasyModal>
    </div>
  );
};

export default AuditLogs;
