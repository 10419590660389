import { createSlice } from '@reduxjs/toolkit';
import { UserSettingsType } from '@app/interfaces/slices-types/user-settings-type';

const initialState: { userSettings: UserSettingsType | null; isLoading: boolean } = {
  userSettings: null,
  isLoading: false,
};

const userSettingsSlice = createSlice({
  name: 'userSettingsSlice',
  initialState,
  reducers: {
    setUserSettings: (state, { payload }) => {
      state.isLoading = true;
      state.userSettings = payload;
      setTimeout(() => {
        return;
      }, 500);
      state.isLoading = false;
    },
  },
});

export default userSettingsSlice.reducer;

export const { setUserSettings } = userSettingsSlice.actions;
