import React, { FC } from 'react';

import { IconType } from '../icon.type';

const RectanglesGroupBubbleLeftIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_609_10)">
          <path
            d="M9.80762 23.4453C10.2998 23.4453 10.6689 23.208 11.2666 22.6807L14.2812 20.0088H19.5986C22.2441 20.0088 23.7295 18.4971 23.7295 15.8867V9.06641C23.7295 6.45605 22.2441 4.94434 19.5986 4.94434H8.39258C5.74707 4.94434 4.26172 6.45605 4.26172 9.06641V15.8867C4.26172 18.4971 5.78223 20.0088 8.33105 20.0088H8.69141V22.1797C8.69141 22.9619 9.10449 23.4453 9.80762 23.4453ZM10.2383 21.5117V19.0068C10.2383 18.4883 10.0186 18.2861 9.51758 18.2861H8.43652C6.77539 18.2861 5.98438 17.4512 5.98438 15.834V9.11914C5.98438 7.50195 6.77539 6.66699 8.43652 6.66699H19.5459C21.207 6.66699 22.0068 7.50195 22.0068 9.11914V15.834C22.0068 17.4512 21.207 18.2861 19.5459 18.2861H14.1846C13.6572 18.2861 13.3936 18.374 13.0244 18.7607L10.2383 21.5117ZM9.08691 12.2656H12.7783C13.4375 12.2656 13.7715 11.9404 13.7715 11.2812V9.62891C13.7715 8.96973 13.4375 8.65332 12.7783 8.65332H9.08691C8.41895 8.65332 8.08496 8.96973 8.08496 9.62891V11.2812C8.08496 11.9404 8.41895 12.2656 9.08691 12.2656ZM16.2061 15.7373H18.834C19.502 15.7373 19.8271 15.4209 19.8271 14.7529V10.2266C19.8271 9.56738 19.502 9.24219 18.834 9.24219H16.2061C15.5381 9.24219 15.2129 9.56738 15.2129 10.2266V14.7529C15.2129 15.4209 15.5381 15.7373 16.2061 15.7373ZM10.0801 16.2734H13.2705C13.9385 16.2734 14.2637 15.957 14.2637 15.2891V14.1816C14.2637 13.5137 13.9385 13.1973 13.2705 13.1973H10.0801C9.41211 13.1973 9.08691 13.5137 9.08691 14.1816V15.2891C9.08691 15.957 9.41211 16.2734 10.0801 16.2734Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_609_10">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default RectanglesGroupBubbleLeftIcon;
