import cn from 'classnames';

export const chartWrapperClassName = cn('bg-white rounded-[10px] h-[calc(100vh-212px)]');
export const chatContainerClassName = cn(
  'inline-flex',
  'w-full',
  'justify-between',
  'items-center',
  'px-[26px]',
  'pt-[19px]',
  'pb-[10px]',
  'mb-2',
  'border-b-[1px]',
  'border-b-[#E5E8EB]',
);
