import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import {
  tableTitleCellClassName,
  tableTitleCellItemClassName,
} from '@app/components/table/table.style';

const TitleWrapper: FC<{ position?: 'first' | 'last'; children: ReactNode; size: number }> = (
  props,
) => {
  const { children, position, size } = props;
  const positionClassName = {
    common: 'bg-3color',
    first: 'absolute left-0 z-[10]',
    last: 'absolute right-0',
  };
  const renderChildren = <div className={tableTitleCellItemClassName()}>{children}</div>;
  if (position) {
    return (
      // <div className="w-full h-full">
      <div
        className={cn(tableTitleCellClassName, 'h-full', positionClassName[position])}
        style={{ width: `${size}px` }}
      >
        <div className={tableTitleCellItemClassName()}>{renderChildren}</div>
      </div>
      // </div>
    );
  }
  return (
    <div className={tableTitleCellClassName}>
      <div className={tableTitleCellItemClassName()}>{renderChildren}</div>
    </div>
  );
};

export default TitleWrapper;
