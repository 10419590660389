import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { ChatRoleSettingsType } from '@app/interfaces/chat-roles-settings.type';
import { baseQuery } from '@app/store/baseQuery';
import { GroupsListObjectItem } from '@app/interfaces/analytics';

export const chatRolesApi = createApi({
  reducerPath: 'chatRolesApi',
  tagTypes: ['chatRoles'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getChatRoles: build.query<ChatRoleSettingsType[], void>({
      query: () => ({
        url: serverRoutes.c_role_settings_bulk,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ crole_id }) => ({
                type: 'chatRoles' as const,
                crole_id,
              })),
              'chatRoles',
            ]
          : ['chatRoles'],
    }),
    createChatRoles: build.mutation<ChatRoleSettingsType, { body: ChatRoleSettingsType }>({
      query: ({ body }) => ({
        url: serverRoutes.c_role_settings,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['chatRoles'],
    }),
    editChatRole: build.mutation<{ body: ChatRoleSettingsType }, { body: ChatRoleSettingsType }>({
      query: ({ body }) => ({
        url: serverRoutes.c_role_settings,
        method: 'PUT',
        params: { id: body.crole_id },
        body,
      }),
      invalidatesTags: ['chatRoles'],
    }),
    deleteChatRole: build.mutation<GroupsListObjectItem, { id: string }>({
      query: (params) => ({
        url: serverRoutes.c_role_settings,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['chatRoles'],
    }),
  }),
});

export const {
  useGetChatRolesQuery,
  useCreateChatRolesMutation,
  useEditChatRoleMutation,
  useDeleteChatRoleMutation,
} = chatRolesApi;
