import { EasyModal } from '@app/components/ui';
import { CircleChannelIcon } from '@app/components/ui/icons/icons-list';
import React, { FC } from 'react';

import { ChatRoleSettingsType, CreateRoleType } from '@app/interfaces/chat-roles-settings.type';

import useTranslation from '@app/hooks/use-translation';

import { ChatCommonSettingsAddForm } from '@app/pages/settings/blocks/common-settings/chat.common-settings-add-form';

type ModalWithForm = {
  isOpenAddChatRoleModal: boolean;
  changeOpenAddChatRoleModal: React.Dispatch<React.SetStateAction<boolean>>;
  changeEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  onFormSubmitChatHandler(data: CreateRoleType): void;
  editMode: boolean;
  channelData: ChatRoleSettingsType | undefined;
};

export const ChatModalWithForm: FC<ModalWithForm> = (props) => {
  const { t } = useTranslation('pages.settings');
  const {
    isOpenAddChatRoleModal,
    changeOpenAddChatRoleModal,
    onFormSubmitChatHandler,
    editMode,
    changeEditMode,
    channelData,
  } = props;
  return (
    <EasyModal
      show={isOpenAddChatRoleModal}
      onRemove={() => changeEditMode(false)}
      label={
        <div className="flex flex-col gap-[13px] items-center justify-center">
          <div className="w-[57px] h-[57px] rounded-full bg-blue_pal flex items-center justify-center">
            <CircleChannelIcon className="text-action" size={25} />
          </div>
          <span className="text-0color text-[24px] font-[700] leading-[29px]">
            {editMode
              ? t('common_setting.chatRoles.modal.edit')
              : t('common_setting.chatRoles.modal.title')}
          </span>
        </div>
      }
      onClose={() => {
        editMode && changeEditMode(false);
        changeOpenAddChatRoleModal(false);
      }}
      variant="mediumW650"
      withoutContentPadding
      withoutFooter
    >
      <div className="px-[50px] pb-[48px] pt-[35px]">
        <ChatCommonSettingsAddForm
          channelData={channelData}
          editMode={editMode}
          onFormSubmit={onFormSubmitChatHandler}
        />
      </div>
    </EasyModal>
  );
};
