import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';

import { Paginator, Skeleton, TooltipWrapper } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { useGetRecordsQuery } from '@app/store/api/records.api';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { FilterTableData, TableItem, TableTitle } from '@app/components/table/table.type';
import { navigationRoutes } from '@app/utils/navigation-routes';
import TimeDuration from '@app/pages/analytics/project-records/blocks/time-duration';
import { DownloadCloudIcon, Oscilogram } from '@icons';

import { GetFilterParamsNew } from '@app/components/ui/easy-filter/types';
import { tableUtilIconsClassName } from '@app/pages/records/blocks/records-table/records-table.styles';
import { getRecordAudio } from '@app/api/record.api';
import fileDownload from 'js-file-download';
import { useGetAliasesListQuery } from '@app/store/api/alias-settings-page.api';
import { useAppSelector } from '@app/store/store';
import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';
import { ChartsLayoutType } from '@app/interfaces/dashboards.type';
import { useParams } from 'react-router-dom';
import { OneTableColsLayoutType } from '@app/interfaces/slices-types/user-settings-type';
import Table from '@app/components/table';

type RecordsTableBlockPropsType = {
  recordsParams: GetFilterParamsNew;
  getRecordsParams: React.Dispatch<React.SetStateAction<GetFilterParamsNew>>;
  hideDragMode?: boolean;
};

export const SinglePresetSettingsTableBlock: FC<RecordsTableBlockPropsType> = (props) => {
  const { recordsParams, getRecordsParams, hideDragMode } = props;
  const { id: presetId } = useParams();
  const { t } = useTranslation('pages.presets');
  const pageOptionsData = [
    { title: '10 ' + t('system.calls'), value: 10 },
    { title: '15 ' + t('system.calls'), value: 15 },
    { title: '25 ' + t('system.calls'), value: 25 },
    { title: '50 ' + t('system.calls'), value: 50 },
  ];
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const [updateUserSettings, { isLoading: userLoading }] = useUpdateUserSettingsMutation();
  // const [deleteRecordIds, changeDeleteRecordsIds] = useState<Array<string>>([]);
  const { data: recordsList, isLoading } = useGetRecordsQuery(recordsParams);
  // const [deleteRecordRequest, { isLoading: isDeleteLoading }] = useDeleteRecordsMutation();
  const { data } = useGetAliasesListQuery();
  const [userSettingsLoading, setUserSettingsLoading] = useState(false);

  useEffect(() => {
    if (userLoading) {
      setUserSettingsLoading(true);
    }
    if (!userLoading) {
      setTimeout(() => setUserSettingsLoading(false), 300);
    }
  }, [userLoading]);
  const checkingAliasForSorting = (alias_id: string) => {
    const alias = data?.aliases.find((alias) => alias.alias_id === alias_id);
    if (alias) {
      return alias.type_meta !== 'channel';
    }
    return false;
  };

  const tableTitles: TableTitle[] = recordsList?.headers.length
    ? [
        {
          index: 'duration_records_items',
          title: t('preset_settings_table.first_label'),
          size: 160,
        },
        ...recordsList.headers.map((column) => ({
          index: column.alias_id,
          title: column.name,
          filter: checkingAliasForSorting(column.alias_id),
        })),
        { index: 'table_record_details', title: t('preset_settings_table.last_label'), size: 120 },
      ]
    : [];
  const tableData: TableItem[] =
    recordsList?.records.reduce<TableItem[]>((dataTable, record) => {
      const cell: TableItem = {};
      cell['duration_records_items'] = (
        <div className="truncate w-full flex items-center justify-center">
          <TooltipWrapper
            className="flex cursor-pointer"
            content={t('popup_hints.to_record_title')}
            id={'preset_time_duration'}
          >
            <TimeDuration
              link={`/${navigationRoutes.records}/${record.record_id}`}
              duration={record.duration}
            />
          </TooltipWrapper>
        </div>
      );
      cell['table_record_details'] = (
        <div className="flex items-center w-[110px] gap-[15px] justify-center">
          <DownloadCloudIcon
            hintTitle={t('popup_hints.download_record')}
            size={18}
            className={tableUtilIconsClassName}
            onClick={() => downloadAudioHandler({ record_id: record.record_id })}
          />
          <Oscilogram
            hintTitle={t('popup_hints.to_record_title')}
            size={22}
            className={cn(tableUtilIconsClassName, 'text-bold')}
            onClick={() =>
              window.open(`/${navigationRoutes.records}/${record.record_id}`, '_blank')
            }
          />
        </div>
      );
      for (const tableCell of record.data) {
        cell[tableCell.alias_id] = tableCell.value;
      }
      dataTable.push(cell);
      return dataTable;
    }, []) || [];

  function paginatorChangePageHandler(paginationData: PaginationResponse) {
    getRecordsParams((prevState) => ({ ...prevState, ...paginationData }));
    updateUserSettings({
      ...userSettings,
      tablesLimit: { ...userSettings?.tablesLimit, singlePresetSettings: paginationData.limit },
    });
  }
  function tableChangeFilterHandler(tableFilter: FilterTableData | undefined) {
    getRecordsParams((prevState) => ({ ...prevState, ...tableFilter }));
  }

  async function downloadAudioHandler(params: { record_id: string }) {
    return await getRecordAudio({ id: params.record_id }).then((res) =>
      fileDownload(
        res.data,
        res.headers['content-disposition'].replace(/"/gim, '').replace('attachment; filename=', ''),
      ),
    );
  }

  // function removeRecords() {
  //   changeDeleteRecordsIds((prevState) => {
  //     deleteRecordRequest({ items: prevState });
  //     return [];
  //   });
  // }

  const [tableLayouts, changeTableLayouts] = useState(
    userSettings?.tableColsSize?.singlePresetSettings,
  );

  useEffect(() => {
    if (hideDragMode) return;
    const condition = userSettings?.tableColsSize?.singlePresetSettings?.find(
      (layoutItem) => layoutItem.id === presetId,
    );
    if (!condition) {
      const systemArr = [
        ...(userSettings?.tableColsSize?.singlePresetSettings || []),
        { id: presetId, layout: [] },
      ];
      updateUserSettings({
        ...userSettings,
        tableColsSize: {
          ...userSettings?.tableColsSize,
          singlePresetSettings: systemArr as OneTableColsLayoutType[],
        },
      });
    } else {
      changeTableLayouts(userSettings?.tableColsSize?.singlePresetSettings);
    }
  }, [hideDragMode, presetId, updateUserSettings, userSettings]);

  function onChangeLayoutHandler(layout: ChartsLayoutType[]) {
    const currentChangedLayout = tableLayouts?.map((tablesLayout) => {
      if (tablesLayout.id === presetId) {
        return { id: tablesLayout.id, layout: layout.map((item) => ({ ...item, maxW: 24 })) };
      }
      return tablesLayout;
    });
    updateUserSettings({
      ...userSettings,
      tableColsSize: {
        ...userSettings?.tableColsSize,
        singlePresetSettings: currentChangedLayout,
      },
    });
  }

  const LoadingSkeleton = (
    <div className={'flex flex-col gap-[10px] pb-2'}>
      <Skeleton height={400} className={'mt-6'} />
      <Skeleton height={50} />
    </div>
  );
  if (isLoading) return LoadingSkeleton;

  return (
    <div className="relative">
      <div className="flex flex-col gap-[10px] pb-2">
        {userSettingsLoading && <Skeleton height={800} className={'absolute z-[999] top-0 mt-6'} />}
        <Table
          hideDragMode={hideDragMode}
          columns={tableTitles}
          dataSource={tableData}
          isPending={isLoading}
          onFilter={tableChangeFilterHandler}
          onLayoutChange={onChangeLayoutHandler}
          layout={tableLayouts
            ?.find((layoutItem) => layoutItem.id === presetId)
            ?.layout.map((item) => ({ ...item, maxW: 16 }))}
        />
        <Paginator
          limit={recordsParams.limit}
          page={Math.ceil(Number(recordsParams.offset) / recordsParams.limit) + 1}
          count={Number(recordsList?.total) || 0}
          onChange={paginatorChangePageHandler}
          pageOptions={pageOptionsData}
        />
      </div>
      {/*<Modal*/}
      {/*  id="deleteRecordFormModal"*/}
      {/*  value={Boolean(deleteRecordIds.length)}*/}
      {/*  setValue={changeDeleteRecordsIds.bind(null, [])}*/}
      {/*  title={*/}
      {/*    deleteRecordIds.length > 1 ? (*/}
      {/*      <>*/}
      {/*        {t('popover.delete_selected')}*/}
      {/*        <span className="text-basic_red">{changeDeleteRecordsIds.length}</span>*/}
      {/*      </>*/}
      {/*    ) : (*/}
      {/*      <>*/}
      {/*        {t('delete_record')} <span className="text-basic_red">{}</span>*/}
      {/*      </>*/}
      {/*    )*/}
      {/*  }*/}
      {/*  onApply={removeRecords}*/}
      {/*  onClose={changeDeleteRecordsIds.bind(null, [])}*/}
      {/*  loading={isDeleteLoading}*/}
      {/*  size="xs"*/}
      {/*  variant="removal"*/}
      {/*>*/}
      {/*  {deleteRecordIds.length > 1 ? t('delete_all_records') : t('record_wb_deleted')}*/}
      {/*</Modal>*/}
    </div>
  );
};
