import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  MetricListArrType,
  ChatMetricObjType,
  MetricTypesArrType,
  TagMetricRequest,
  // MetricClassifierModelsType,
  // MetricObjType,
  // MetricListArrType,
  // TagMetricRequest,
} from '@app/interfaces/pages-types/anatylics-metric.type';

import { baseQuery } from '../../baseQuery';
import { metricRecordsApi } from '../metric-records.api';
// import { metricRecordsApi } from '../metric-records.api';

export const chatMetricApi = createApi({
  reducerPath: 'chatMetricApi',
  tagTypes: ['chatMetrics', 'singleMetric'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    // metric types
    getChatMetricTypesList: build.query<MetricTypesArrType[], void>({
      query: () => ({
        url: serverRoutes.chatMetricsTypes,
      }),
    }),
    // classifierModelsList
    // getClassifierModelsList: build.query<MetricClassifierModelsType[], void>({
    //   query: () => ({
    //     url: serverRoutes.metricsClassifierModels,
    //   }),
    //   transformResponse: (value: { models: MetricClassifierModelsType[] }) => value.models,
    // }),
    // project metric
    getChatProjectMetric: build.query<ChatMetricObjType, { project_id: string; metric_id: string }>(
      {
        query: (params) => ({
          url: serverRoutes.metricProjectChat,
          params,
        }),
        providesTags: ['singleMetric'],
      },
    ),
    getChatProjectMetricsList: build.query<MetricListArrType[], { project_id: string }>({
      query: (params) => ({
        url: serverRoutes.metricChat,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ metric_id }) => ({
                type: 'chatMetrics' as const,
                metric_id,
              })),
              'chatMetrics',
            ]
          : ['chatMetrics'],
    }),
    createProjectChatMetricGroup: build.mutation<
      ChatMetricObjType,
      {
        params: {
          project_id: string;
        };
        body: ChatMetricObjType;
      }
    >({
      query: ({ body, params }) => ({
        url: serverRoutes.metricProjectChat,
        method: 'POST',
        body,
        params,
      }),
      invalidatesTags: ['chatMetrics'],
    }),
    editProjectChatMetricGroup: build.mutation<
      ChatMetricObjType,
      {
        params: {
          project_id: string;
          metric_id: string;
        };
        body: ChatMetricObjType;
      }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.metricProjectChat,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['chatMetrics', 'singleMetric'],
    }),
    deleteProjectChatMetric: build.mutation({
      query: (params: { project_id: string; metric_id: string }) => ({
        url: serverRoutes.metricProjectChat,
        method: 'DELETE',
        params,
        redirect: 'manual',
      }),

      invalidatesTags: ['chatMetrics'],
    }),
    /* chat manual metric set values */
    setValueTagChatMetric: build.mutation<{ success: boolean }, TagMetricRequest>({
      query: ({ params, body }) => ({
        url: serverRoutes.chatProjectMetricValue,
        method: 'PUT',
        params: {
          project_id: params.project_id,
          metric_id: params.metric_id,
          chat_id: params.record_id,
        },
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(metricRecordsApi.util.invalidateTags(['chatMetricRecords']));
      },
      // invalidatesTags: ['metrics'],
    }),
  }),
});
export const {
  useGetChatMetricTypesListQuery,
  useGetChatProjectMetricsListQuery,
  useCreateProjectChatMetricGroupMutation,
  useEditProjectChatMetricGroupMutation,
  useLazyGetChatProjectMetricQuery,
  useDeleteProjectChatMetricMutation,
  useSetValueTagChatMetricMutation,
} = chatMetricApi;
