import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  DashboardCreateObject,
  DashboardsListType,
  DashboardsSettingsType,
} from '@app/interfaces/dashboards.type';

import { baseQuery } from '../baseQuery';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  tagTypes: ['dashboard', 'dashboardData'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getDashboardsList: build.query<{ dashboard: DashboardsListType[] }, void>({
      query: () => ({
        url: serverRoutes.dashboards,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.dashboard.map(({ dashboard_id }) => ({
                type: 'dashboard' as const,
                dashboard_id,
              })),
              'dashboard',
            ]
          : ['dashboard'],
    }),
    addNewDashboard: build.mutation<DashboardsSettingsType, DashboardCreateObject>({
      query: (body) => ({
        url: serverRoutes.dashboard,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['dashboard', 'dashboardData'],
    }),
    deleteDashboard: build.mutation({
      query: (body: string) => ({
        url: serverRoutes.dashboard,
        method: 'DELETE',
        params: { id: body },
      }),
      invalidatesTags: ['dashboard'],
    }),
    editDashboard: build.mutation<
      DashboardsSettingsType,
      { body: DashboardCreateObject; id: string }
    >({
      query: ({ body, id }) => ({
        url: serverRoutes.dashboard,
        method: 'PUT',
        params: { id: id },
        body,
      }),
      invalidatesTags: ['dashboard'],
    }),
    editDashboardOwner: build.mutation<DashboardsSettingsType, { id: string }>({
      query: ({ id }) => ({
        url: serverRoutes.dashboardTake,
        method: 'PUT',
        params: { id: id },
      }),
      invalidatesTags: ['dashboardData'],
    }),
    getDashboard: build.query<DashboardsSettingsType, string>({
      query: (body) => ({
        url: serverRoutes.dashboard,
        params: { id: body },
      }),
      providesTags: ['dashboardData'],
    }),
  }),
});

export const {
  useGetDashboardsListQuery,
  useDeleteDashboardMutation,
  useEditDashboardMutation,
  useAddNewDashboardMutation,
  useGetDashboardQuery,
  useLazyGetDashboardQuery,
  useEditDashboardOwnerMutation,
} = dashboardApi;
