import React, { FC } from 'react';

import { IconType } from '../icon.type';

const BubbleLeftAndExclamationmarkBubbleRightIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_609_18)">
          <path
            d="M7.13574 22.7861C7.62793 22.7861 8.05859 22.5752 8.66504 22.0566L11.5039 19.5254C12.1455 20.2021 13.1211 20.5713 14.3428 20.5713H17.3398L20.2666 23.0762C20.8467 23.5684 21.2422 23.8408 21.7168 23.8408C22.4111 23.8408 22.8242 23.3486 22.8242 22.584V20.5713H23.2197C25.4346 20.5713 26.9111 19.209 26.9111 16.9238V11.5801C26.9111 9.25977 25.4082 7.88867 23.1582 7.88867H21.6377V7.2998C21.6377 4.90918 20.1787 3.49414 17.7705 3.49414H4.96484C2.62695 3.49414 1.08887 4.90918 1.08887 7.2998V15.5264C1.08887 17.8818 2.60059 19.2793 4.90332 19.2793H6.01074V21.5381C6.01074 22.3115 6.42383 22.7861 7.13574 22.7861ZM7.56641 18.4443C7.56641 17.917 7.24121 17.6533 6.81055 17.6533H5.1582C3.69043 17.6533 2.81152 16.8271 2.81152 15.2979V7.48438C2.81152 5.94629 3.69922 5.12012 5.1582 5.12012H17.5684C19.0361 5.12012 19.915 5.94629 19.915 7.48438V7.88867H14.3428C12.0049 7.88867 10.5811 9.25977 10.5811 11.5801V16.9326C10.5811 17.3105 10.6162 17.6621 10.6953 17.9873L7.56641 20.9053V18.4443ZM21.2773 21.96L18.5088 19.4551C18.1309 19.1123 17.8145 18.9629 17.3047 18.9629H14.5186C13.1299 18.9629 12.2773 18.1895 12.2773 16.7217L12.2861 11.7383C12.2861 10.2793 13.1299 9.48828 14.5186 9.48828H22.9736C24.3711 9.48828 25.2148 10.2793 25.2148 11.7383V16.7217C25.2148 18.1807 24.3623 18.9629 22.9736 18.9629H22.0332C21.6025 18.9629 21.2773 19.2178 21.2773 19.7451V21.96ZM18.7549 15.2451C19.1943 15.2451 19.4404 14.999 19.458 14.5332L19.5811 11.6768C19.5986 11.1758 19.2383 10.8418 18.7461 10.8418C18.2539 10.8418 17.9023 11.1758 17.9287 11.668L18.043 14.5332C18.0605 14.9902 18.3066 15.2451 18.7549 15.2451ZM18.7549 17.9258C19.2822 17.9258 19.7041 17.5479 19.7041 17.0293C19.7041 16.5195 19.2822 16.1416 18.7549 16.1416C18.21 16.1416 17.7969 16.5283 17.7969 17.0293C17.7969 17.5391 18.2188 17.9258 18.7549 17.9258Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_609_18">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default BubbleLeftAndExclamationmarkBubbleRightIcon;
