module.exports = {
  pageTitle: 'Пользователи',
  add_user_button: 'Добавить пользователя',
  table: {
    userName: 'Имя пользователя',
    email: 'Логин',
    role: 'Роль пользователя',
    status: 'Статус',
    actions: 'Действия',
    delete: 'Удалить пользователя',
    edit: 'Редактировать пользователя'
  },
  modal: {
    title_create: 'Добавить пользователя',
    username: 'Имя пользователя',
    login:'Логин/Email',
    role:'Роль пользователя',
    password:'Пароль пользователя',
    save_button: 'Сохранить',
    name: 'Ваше имя',
    select_role: 'Выберете роль',
    select_enable: 'Статус пользователя',
    enabled: 'Активен',
    disabled: 'Неактивен',
    title_edit: 'Изменить пользователя',
    generate_password: 'Сгенерировать пароль',
    password_warning: 'Не забудьте сохранить пароль!',
    validation_rules_1: 'Требования к паролю',
    validation_rules_2: '-длина пароля не менее 12 символов;',
    validation_rules_3: '-содержится минимум 1 цифра;',
    validation_rules_4: '-содержится минимум 1 буква в верхнем регистре;',
    validation_rules_5: '-содержится минимум 1 буква в нижнем регистре;',
  },
  errors: {
    required: 'Это поле обязательно',
    max_length: 'Максимальная длина 64 символа',
    invalid_characters: 'Недопустимые символы',
    password_too_short: 'Пароль меньше 12 символов',
    no_uppercase: 'Нет букв в верхнем регистре',
    no_lowercase: 'Нет букв в нижнем регистре',
    no_number: 'Нет чисел в пароле',
    no_special_char: 'Нет специальных символов',
    invalid_email: 'Неверный формат email'
  },
  toast: {
    success_delete: 'Пользователь успешно удален',
    success_add: 'Пользователь успешно добавлен',
    success_edit: 'Пользователь успешно изменен',
    error_while_edit: 'Ошибка при изменении пользователя:',
    error_while_add: 'Ошибка при добавлении пользователя:'
  },
  warning: 'Предупреждение',
  delete_user_text: 'Вы уверены что хотите удалить пользователя?'
}
