import React, { FC } from 'react';

import { IconType } from '../icon.type';

const PinSquareIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_609_123)">
          <path
            d="M8.67383 21.8721H19.3174C21.2158 21.8721 22.2002 20.8877 22.2002 19.0156V8.31934C22.2002 6.43848 21.2158 5.46289 19.3174 5.46289H8.67383C6.77539 5.46289 5.79102 6.43848 5.79102 8.31934V19.0156C5.79102 20.8965 6.77539 21.8721 8.67383 21.8721ZM8.7793 20.1494C7.96191 20.1494 7.51367 19.7188 7.51367 18.8662V8.45996C7.51367 7.60742 7.96191 7.18555 8.7793 7.18555H19.2119C20.0205 7.18555 20.4775 7.60742 20.4775 8.45996V18.8662C20.4775 19.7188 20.0205 20.1494 19.2119 20.1494H8.7793ZM10.5547 15.2891C10.5547 15.6758 10.8096 15.9307 11.2139 15.9307H13.4199V17.4775C13.4199 18.3477 13.833 19.1914 14.0088 19.1914C14.1758 19.1914 14.5977 18.3477 14.5977 17.4775V15.9307H16.7949C17.1992 15.9307 17.4629 15.6758 17.4629 15.2891C17.4629 14.3574 16.6807 13.3994 15.459 12.9512L15.3184 10.9297C15.9512 10.5693 16.4609 10.165 16.6895 9.875C16.8037 9.72559 16.8652 9.55859 16.8652 9.41797C16.8652 9.13672 16.6543 8.93457 16.3291 8.93457H11.6885C11.3633 8.93457 11.1436 9.13672 11.1436 9.41797C11.1436 9.55859 11.2051 9.73438 11.3369 9.88379C11.5654 10.1738 12.0664 10.5693 12.6992 10.9297L12.5586 12.9512C11.3369 13.3994 10.5547 14.3574 10.5547 15.2891Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_609_123">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default PinSquareIcon;
