import React, { FC } from 'react';

import { IconType } from '../icon.type';

const EyeTrianglebadgeTxclamationmarkFillIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_607_15)">
          <path
            d="M14 21.1328C15.2891 21.1328 16.5 20.9141 17.5938 20.5625C17.4453 19.9062 17.5547 19.4219 17.9141 18.7891C16.7344 19.2969 15.4141 19.6328 14 19.6328C9.00781 19.6328 5.03906 15.4609 5.03906 14.3672C5.03906 13.4531 9.00781 9.10938 14 9.10938C17.2344 9.10938 20.0312 10.9375 21.5938 12.4766C22.0078 11.9922 22.5859 11.7266 23.2344 11.7578C21.4453 9.79688 18.1562 7.60938 14 7.60938C7.6875 7.60938 3.26562 12.7656 3.26562 14.3672C3.26562 15.9688 7.67969 21.1328 14 21.1328ZM14 18.5781C16.3438 18.5781 18.2188 16.6641 18.2188 14.3672C18.2188 12.0156 16.3438 10.1641 14 10.1641C11.6484 10.1641 9.76562 12.0156 9.78125 14.3672C9.78906 16.6641 11.6484 18.5781 14 18.5781ZM19.7656 21.1328H26.5312C27.1953 21.1328 27.6328 20.6406 27.6328 20.0391C27.6328 19.8516 27.5781 19.6719 27.4766 19.5L24.0938 13.4453C23.8828 13.0703 23.5156 12.8906 23.1484 12.8906C22.7812 12.8906 22.4062 13.0703 22.2031 13.4453L18.8203 19.5C18.7188 19.6719 18.6719 19.8516 18.6719 20.0391C18.6719 20.6406 19.1016 21.1328 19.7656 21.1328ZM14 15.7266C13.2422 15.7266 12.6328 15.1172 12.6328 14.3672C12.6328 13.6172 13.2422 13.0078 14 13.0078C14.7578 13.0078 15.3672 13.6172 15.3672 14.3672C15.3672 15.1172 14.7578 15.7266 14 15.7266ZM23.1484 18.0391C22.8516 18.0391 22.6328 17.8359 22.625 17.5391L22.5625 15.3672C22.5547 15.0234 22.7969 14.7812 23.1484 14.7812C23.5078 14.7812 23.75 15.0156 23.7422 15.3672L23.6797 17.5391C23.6641 17.8359 23.4531 18.0391 23.1484 18.0391ZM23.1484 19.9453C22.7578 19.9453 22.4453 19.6406 22.4453 19.25C22.4453 18.8672 22.7578 18.5625 23.1484 18.5625C23.5469 18.5625 23.8594 18.875 23.8594 19.25C23.8516 19.6406 23.5469 19.9453 23.1484 19.9453Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_607_15">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default EyeTrianglebadgeTxclamationmarkFillIcon;
