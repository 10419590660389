import { useRef, useEffect, Dispatch, SetStateAction } from 'react';

export default function useResizeObserver<T extends HTMLDivElement = HTMLDivElement>(
  ref: T | undefined | null,
  setState: Dispatch<SetStateAction<number>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
) {
  // setLoading(true);
  const observer = useRef(
    // eslint-disable-next-line compat/compat
    new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      setState(width);
      setLoading(false);
    }),
  );
  useEffect(() => {
    setLoading(true);
    ref && observer.current.observe(ref);
  }, [ref, observer, setLoading]);
}
