import React, { FC } from 'react';

import { IconType } from '../icon.type';

const AirplaneDepartureIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_610_231)">
          <path
            d="M7.6543 15.6055L22.7451 11.5801C24.4941 11.1143 25.7598 9.82227 25.2939 8.53906C24.8545 7.37891 23.1318 7.05371 21.5674 7.58984L16.2588 9.40039C15.8984 9.52344 15.6436 9.51465 15.2217 9.3125L7.87402 5.84961C7.61914 5.72656 7.33789 5.70898 7.08301 5.7793L6.47656 5.97266C6.22168 6.05176 6.14258 6.35059 6.3623 6.58789L10.7744 11.2812L7.9707 12.2393L3.76953 10.7451C3.49707 10.6572 3.26855 10.7012 3.02246 10.9297L2.75879 11.167C2.55664 11.3516 2.54785 11.5977 2.72363 11.7822L5.7998 15.2451C6.23047 15.7197 6.86328 15.8164 7.6543 15.6055ZM4.36719 21.6699H23.4131C23.8877 21.6699 24.2744 21.2832 24.2744 20.8086C24.2744 20.334 23.8877 19.9473 23.4131 19.9473H4.36719C3.90137 19.9473 3.50586 20.334 3.50586 20.8086C3.50586 21.2832 3.90137 21.6699 4.36719 21.6699Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_231">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default AirplaneDepartureIcon;
