import React, { useMemo, useState } from 'react';
import {
  useAddRecipientToEmailTriggerMutation,
  useAddRecipientToTgTriggerMutation,
  useCreateNewEmailRecipientTriggerMutation,
  useCreateNewTelegramRecipientTriggerMutation,
  useDeleteMetricTriggerMutation,
  useDeleteRecipientFromEmailTriggerMutation,
  useDeleteRecipientFromTgTriggerMutation,
  useGetMetricTriggersBulkQuery,
  useLazyGetMetricTriggerObjectQuery,
  useLazyGetTriggerRecipientsBulkQuery,
} from '@app/store/api/metric-trigger.api';
import { Button, EasyModal } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import AddTriggerForm from '@app/pages/analytics/metric-trigger/blocks/add-trigger-form';
import { useOutletContext } from 'react-router-dom';
import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';
import { useGetProjectMetricsListQuery } from '@app/store/api/metrics.api';
import {
  MetricTriggerBulkRequestType,
  MetricTriggerObject,
  MixedTriggerRecipientsType,
  TriggerPlatformType,
} from '@app/interfaces/metric-trigger.type';
import TriggersTableBlock from '@app/pages/analytics/metric-trigger/blocks/triggers-table.block';
import { AddRecipientsModal } from '@app/components';
import { useGetTenantRecipientsTgAndEmailQuery } from '@app/store/api/tenant-recipients.api';
import { toast } from 'react-toastify';
import { MixedRecipientsRequestType } from '@app/interfaces/tenant-recipients.type';
import { GroupSharedStatus } from '@app/interfaces/analytics';

const MetricTriggerPage = React.memo(() => {
  const { t } = useTranslation('pages.triggers');
  const { project } = useOutletContext<AnalyticPageOutlet>();

  const [triggerSubscribedRecipients, changeTriggerSubscribedRecipients] =
    useState<MixedTriggerRecipientsType>({
      telegram: [],
      email: [],
    });
  const [filterParams, changeFilterParams] = useState<MixedRecipientsRequestType>({
    telegram: { offset: 0, limit: 10, confirmed: 'confirmed' },
    email: { offset: 0, limit: 10, confirmed: 'confirmed' },
  });
  const [deletedTriggerId, changeDeletedTriggerId] = useState<string>();
  const [triggerSettingsModalShow, changeTriggerModalState] = useState(false);
  const [addRecipientTriggerId, changeAddRecipientTriggerId] = useState<string>();
  const [metricTriggerObject, changeTriggerObject] = useState<MetricTriggerObject>();
  const [modalEditState, changeModalEditState] = useState(false);
  const [metricTriggerRequestData, changeMetricTriggerRequestData] =
    useState<MetricTriggerBulkRequestType>({
      project_id: project?.project_id || '',
      limit: 10,
      offset: 0,
    });
  const [addEmailRecipientToTrigger] = useAddRecipientToEmailTriggerMutation();
  const [deleteEmailRecipientFromTrigger] = useDeleteRecipientFromEmailTriggerMutation();
  const [addTgRecipientToTrigger] = useAddRecipientToTgTriggerMutation();
  const [deleteTgRecipientFromTrigger] = useDeleteRecipientFromTgTriggerMutation();
  const [getTriggerRecipients] = useLazyGetTriggerRecipientsBulkQuery();
  const [createNewEmailRecipient] = useCreateNewEmailRecipientTriggerMutation();
  const [createNewTelegramRecipient] = useCreateNewTelegramRecipientTriggerMutation();
  const { data: tenantRecipients, refetch } = useGetTenantRecipientsTgAndEmailQuery(filterParams, {
    refetchOnMountOrArgChange: true,
  });
  const [deleteTrigger] = useDeleteMetricTriggerMutation();
  const [getTriggerObject] = useLazyGetMetricTriggerObjectQuery();
  const { data } = useGetMetricTriggersBulkQuery(metricTriggerRequestData, {
    skip: !project?.project_id,
  });

  const { data: metricList } = useGetProjectMetricsListQuery(
    {
      project_id: project?.project_id || '',
    },
    { skip: !project?.project_id },
  );

  const trigger_cant_be_edited = !project?.owner && project?.shared === GroupSharedStatus.VIEW;

  function handleRemoveTrigger() {
    if (!deletedTriggerId) return;
    deleteTrigger({ trigger_id: deletedTriggerId })
      .unwrap()
      .then(() => changeDeletedTriggerId(undefined));
  }

  function onCloseModal() {
    changeTriggerModalState(false);
    changeModalEditState(false);
  }
  function handleDelete(trigger_id: string) {
    changeDeletedTriggerId(trigger_id);
  }
  function handleRecipients(trigger_id: string) {
    refetch();
    handleGetSubscribedRecipients(trigger_id);
  }
  function handleGetSubscribedRecipients(trigger_id: string) {
    getTriggerRecipients({ trigger_id })
      .unwrap()
      .then((data) => {
        changeAddRecipientTriggerId(trigger_id);
        changeTriggerSubscribedRecipients(data);
      });
  }
  function handleSettings(trigger_id: string) {
    getTriggerObject({ trigger_id })
      .unwrap()
      .then((data) => {
        changeModalEditState(true);
        changeTriggerObject(data);
        changeTriggerModalState(true);
      });
  }
  function onCreateNewRecipient(
    platform: 'telegram' | 'email',
    body: { email: string; description: string },
  ) {
    if (!addRecipientTriggerId) return;
    return new Promise<{ status: boolean; recipient_id?: string; hash?: string }>((resolve) => {
      if (platform === 'email') {
        createNewEmailRecipient({ ...body, trigger_id: addRecipientTriggerId })
          .unwrap()
          .then((data) => {
            handleGetSubscribedRecipients(addRecipientTriggerId);
            resolve({ status: true, recipient_id: data.recipient_id });
          })
          .catch(() => resolve({ status: false }))
          .finally(() => setTimeout(() => refetch(), 100));
      } else {
        createNewTelegramRecipient({
          trigger_id: addRecipientTriggerId,
          description: body.description,
        })
          .unwrap()
          .then((data) => {
            handleGetSubscribedRecipients(addRecipientTriggerId);
            resolve({ status: true, recipient_id: data.recipient_id, hash: data.hash });
          })
          .catch(() => resolve({ status: false }))
          .finally(() => setTimeout(() => refetch(), 100));
      }
    });
  }
  function handleChangeRecipientStatus(
    status: boolean,
    platform: TriggerPlatformType,
    recipient_id: string,
  ) {
    const trigger_id = addRecipientTriggerId;
    if (!trigger_id) return;
    switch (platform) {
      case 'telegram':
        status
          ? addTgRecipientToTrigger({ recipient_id, trigger_id })
              .unwrap()
              .then(() => toast.success(t('errors.success')))
              .catch(() => toast.error(t('errors.error')))
              .finally(() => handleGetSubscribedRecipients(trigger_id))
          : deleteTgRecipientFromTrigger({
              trigger_id,
              trigger_telegram_recipient_id: recipient_id,
            })
              .unwrap()
              .then(() => toast.success(t('errors.success')))
              .catch(() => toast.error(t('errors.error')))
              .finally(() => handleGetSubscribedRecipients(trigger_id));
        break;
      case 'email':
        status
          ? addEmailRecipientToTrigger({ recipient_id, trigger_id })
              .unwrap()
              .then(() => toast.success(t('errors.success')))
              .catch(() => toast.error(t('errors.error')))
              .finally(() => handleGetSubscribedRecipients(trigger_id))
          : deleteEmailRecipientFromTrigger({
              trigger_email_recipient_id: recipient_id,
              trigger_id,
            })
              .unwrap()
              .then(() => toast.success(t('errors.success')))
              .catch(() => toast.error(t('errors.error')))
              .finally(() => handleGetSubscribedRecipients(trigger_id));
        break;
      default:
        break;
    }
  }
  const telegramDefaultRecipients = useMemo(
    () =>
      tenantRecipients?.telegram.recipients.map(({ description, recipient_id, confirmed }) => ({
        description: description,
        recipient_id: recipient_id,
        confirmed,
      })),
    [tenantRecipients?.telegram.recipients],
  );

  const emailDefaultRecipients = useMemo(
    () =>
      tenantRecipients?.email.recipients.map(({ description, email, recipient_id, confirmed }) => ({
        description: description,
        email: email,
        confirmed,
        recipient_id: recipient_id,
      })),
    [tenantRecipients?.email.recipients],
  );

  const recipientsDefaultValue = {
    telegram: {
      recipients: telegramDefaultRecipients,
      total: tenantRecipients?.telegram.total,
    },
    email: { recipients: emailDefaultRecipients, total: tenantRecipients?.email.total },
  };

  return (
    <>
      <div className="flex justify-between items-center py-[25px]">
        <h2 className="text-[24px]">{t('chapter_label')}</h2>
        <div className="flex items-center relative z-[51] gap-[10px]">
          <Button
            icon="CirclePlusIcon"
            label={t('buttons.add_trigger')}
            onClick={() => changeTriggerModalState(true)}
            disabled={trigger_cant_be_edited}
          />
        </div>
      </div>
      <TriggersTableBlock
        onSettingsClick={handleSettings}
        onTrashIconClick={handleDelete}
        onRecipientsClick={handleRecipients}
        requestData={metricTriggerRequestData}
        changeRequestData={changeMetricTriggerRequestData}
        data={data}
        metricList={metricList}
      />
      <EasyModal
        variant="mediumW650"
        label={t('modal.label')}
        withoutFooter
        show={triggerSettingsModalShow}
        onClose={onCloseModal}
      >
        {triggerSettingsModalShow && (
          <AddTriggerForm
            metricTriggerObject={metricTriggerObject}
            modalEditState={modalEditState}
            metricList={metricList}
            project_id={project?.project_id || ''}
            onClose={onCloseModal}
            trigger_cant_be_edited={trigger_cant_be_edited}
          />
        )}
      </EasyModal>
      <EasyModal
        variant="removal"
        show={!!deletedTriggerId}
        onClose={() => changeDeletedTriggerId(undefined)}
        label={t('system.warning')}
        onRemove={handleRemoveTrigger}
      >
        <div className="px-[20px] text-center">{t('system.delete_trigger_text')}</div>
      </EasyModal>
      <AddRecipientsModal
        hideAddRecipientsFeature
        filterParams={filterParams}
        subscribeDisable={trigger_cant_be_edited}
        changeFilterParams={changeFilterParams}
        tenantRecipients={recipientsDefaultValue}
        show={!!addRecipientTriggerId}
        onClose={() => changeAddRecipientTriggerId(undefined)}
        onCreateNewRecipient={onCreateNewRecipient}
        triggerSubscribedRecipients={triggerSubscribedRecipients}
        handleChangeRecipientStatus={handleChangeRecipientStatus}
        changeTriggerSubscribedRecipients={changeTriggerSubscribedRecipients}
      />
    </>
  );
});
MetricTriggerPage.displayName = 'MetricTriggerPage';
export default MetricTriggerPage;
