import React, { FC, Fragment } from 'react';
import { Select } from '@ui';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { OptionItem } from '@app/components/ui/select/select.type';

import Icon from '@app/components/ui/icons';

import useTranslation from '@app/hooks/use-translation';

import { KeysForFilteringType } from '@app/components/ui/easy-filter/types';

import { UniversalFilterConstants } from '@app/hooks/use-filter-data';

import NestedField from './nested-field';
type FilterFieldType = {
  keysForFiltering: KeysForFilteringType[];
  modalMode?: boolean;
};
const Field: FC<FilterFieldType> = (props) => {
  const { keysForFiltering } = props;
  const { t } = useTranslation('components.filter');
  const { control, setValue, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filter',
  });
  const { conditionsWithoutValue, conditions } = UniversalFilterConstants();
  function getParamsSelectOptions(): OptionItem[] {
    return keysForFiltering.map((item) => ({ title: item.title, value: item.id }));
  }
  // const isAliases = keysForFiltering.map((key) => !!key.type_meta)?.[0];
  function getConditionsSelectOptions(value?: string) {
    const alias = keysForFiltering.find((alias) => alias.id === value);
    if (value && alias && alias.type_meta) {
      return conditions
        .filter((condition) => !condition.metricOnly)
        .filter((condition) => condition.type_meta.includes(alias?.type_meta as string))
        .filter((condition) => alias.type_value && condition.values.includes(alias.type_value))
        .map((item) => ({ title: item.name, value: item.id }));
    } else
      return conditions
        .filter((condition) => !condition.aliasOnly)
        .filter((condition) => alias?.type_value && condition.values.includes(alias.type_value))
        .map((item) => ({ title: item.name, value: item.id }));
  }
  function getConditionOptionsWhenMoreThatOneValue() {
    return conditions
      .filter((condition) => condition.id === '=' || condition.id === '!=')
      .map((item) => ({ title: item.name, value: item.id }));
  }
  function onChangeParams(value, index) {
    setValue(`filter[${index}].id`, value);
    watch(`filter[${index}].value`)?.length > 1
      ? setValue(`filter[${index}].condition`, getConditionOptionsWhenMoreThatOneValue()[0].value)
      : setValue(`filter[${index}].condition`, getConditionsSelectOptions(value)[0].value);
  }

  return (
    <ul className={'flex flex-col px-[32px]'}>
      {fields.map((item, index) => (
        <Fragment key={item.id}>
          {index > 0 && (
            <div
              className={
                'h-[17px] w-full flex items-center justify-center text-[14px] leading-[17px] font-[400] text-4color'
              }
            >
              {t('and')}
            </div>
          )}
          <li className="flex items-center py-4 relative bg-defaultBg rounded-[12px] border-solid border-l-[2px] border-speech_analitics px-[24px]">
            <div className={'flex items-start justify-center gap-[16px]'}>
              <div className={'w-[360px]'}>
                <Select
                  dropWidth={356}
                  defaultValue={watch(`filter[${index}].id`)}
                  onChange={(value) => onChangeParams(value, index)}
                  options={getParamsSelectOptions()}
                  dropTop={index >= 2}
                />
              </div>
              <div className={'w-[120px]'}>
                <Select
                  defaultValue={watch(`filter[${index}].condition`)}
                  onChange={(value) => {
                    setValue(`filter[${index}].condition`, value);
                    if (
                      conditionsWithoutValue.includes(value.toString()) &&
                      fields.length < index + 2
                    ) {
                      append('');
                    }
                  }}
                  dropTop={index >= 2}
                  options={
                    watch(`filter[${index}].value`)?.length > 1
                      ? getConditionOptionsWhenMoreThatOneValue()
                      : getConditionsSelectOptions(watch(`filter[${index}].id`))
                  }
                />
              </div>
              <NestedField
                getConditionOptionsWhenMoreThatOneValue={getConditionOptionsWhenMoreThatOneValue}
                getConditionsSelectOptions={getConditionsSelectOptions}
                fieldsLength={fields.length}
                appendFilter={append}
                nestIndex={index}
                keysForFiltering={keysForFiltering}
              />
            </div>
            {fields.length !== index + 1 && (
              <div className={'cursor-pointer absolute right-[-25px]'}>
                <Icon
                  name="TrashIcon"
                  className="!text-basic_red"
                  size={14}
                  onClick={() => remove(index)}
                />
              </div>
            )}
          </li>
        </Fragment>
      ))}
    </ul>
  );
};

export default Field;
