const formsEn = require('./forms');
const humanizeEn = require('./humanize');
const breadcrumbsEn = require('./breadcrumbs');
const sharedStatusEn = require('./sharedStatus');
const pageTitleEn = require('./pageTitle');
const uiPopupHintsEn = require('./popup-hints-for-ui');
const constantsEn = require('./constants');
const emailConfirmationEn = require('./emailConfirmation');


module.exports = {
  forms:formsEn,
  humanize:humanizeEn,
  breadcrumbs:breadcrumbsEn,
  sharedStatus:sharedStatusEn,
  pageTitle:pageTitleEn,
  uiPopupHints:uiPopupHintsEn,
  constants:constantsEn,
  emailConfirmation:emailConfirmationEn
};