import React, { FC, ReactNode, useEffect, useState } from 'react';

import { Button, NavigationTabs, PageHeader, SharedStatus } from '@app/components/ui';
import { AllStatusProject, ProjectItem } from '@app/interfaces/analytics';
import AnalyticsStatus from '@app/components/ui/analytics-status/analytics-status';
import { toast } from 'react-toastify';
import useTranslation from '@app/hooks/use-translation';

import { useParams } from 'react-router';
import { NavigationTabsItem } from '@app/components/ui/navigation-tabs/navigation-tabs';

import {
  useChangeStatusOfChatAnalyticProjectMutation,
  useGetStatusOfChatAnalyticProjectQuery,
} from '@app/store/api/chat-api/chat-analytic.api';

import { navigationData } from './navigation.data';

type AnalyticsPageHeaderProps = {
  project: ProjectItem | undefined;
  children?: ReactNode;
};

const ChatAnalyticPageHeader: FC<AnalyticsPageHeaderProps> = (props) => {
  const { project, children } = props;
  const { t } = useTranslation('pages.analytics');
  const { t: navT } = useTranslation('components.navigateTabs');

  const { id } = useParams();
  const [pollingSkip, changePollingSkip] = useState(false);
  const [projectStatus, changeProjectStatus] = useState<AllStatusProject>();
  const { data: status } = useGetStatusOfChatAnalyticProjectQuery(
    { id: id },
    { pollingInterval: pollingSkip ? undefined : 2000 },
  );
  const data: NavigationTabsItem[] = navigationData(project?.project_id).map((item) => ({
    ...item,
    label: navT(item.label),
  }));
  const [changeStatus] = useChangeStatusOfChatAnalyticProjectMutation();

  useEffect(() => {
    if (!status) return;
    changeProjectStatus(status.project_status);
  }, [status]);

  useEffect(() => {
    if (projectStatus === 'ACTIVE') return changePollingSkip(true);
    if (projectStatus === 'PAUSE') return changePollingSkip(true);
    if (projectStatus === 'CREATED') return changePollingSkip(true);
    return changePollingSkip(false);
  }, [projectStatus]);

  function editProject() {
    if (!id) return;
    if (
      projectStatus !== AllStatusProject.ACTIVE &&
      projectStatus !== AllStatusProject.CREATED &&
      projectStatus !== AllStatusProject.PAUSE
    )
      return;
    const params = {
      params: { id: id },
      body: {
        status:
          projectStatus === AllStatusProject.ACTIVE
            ? AllStatusProject.STOPPING
            : projectStatus === AllStatusProject.CREATED
            ? AllStatusProject.FILLING
            : AllStatusProject.STARTING,
      },
    };
    changeStatus(params).then((data) => {
      const { error } = data as { error: { status: number } };
      if (error) {
        error.status === 403 && toast.info(t('preset_fill_error'));
      }
    });
  }
  function disableStatusChangeButton() {
    if (project?.owner) {
      return false;
    } else {
      return project?.shared !== 'EDIT';
    }
  }

  const buttonsRenderFunction = () => {
    if (projectStatus === AllStatusProject.PAUSE || projectStatus === AllStatusProject.CREATED) {
      return (
        <Button
          icon="TriangleRightIcon"
          sizeIcon={15}
          fill="outlined"
          onClick={() => editProject()}
          label={t('start_project')}
          disabled={disableStatusChangeButton()}
        />
      );
    } else if (projectStatus === AllStatusProject.ACTIVE) {
      return (
        <Button
          icon="PauseIcon"
          sizeIcon={15}
          fill="outlined"
          onClick={() => editProject()}
          label={t('stop_project')}
          disabled={disableStatusChangeButton()}
        />
      );
    }
  };

  return (
    <>
      <PageHeader
        label={
          <div className="flex items-center gap-[10px]">
            <div className="mt-[-2px] font-[600]">{project?.name}</div>
            <AnalyticsStatus
              dataId={`ap-status-${projectStatus}`}
              status={projectStatus || 'CREATED'}
            />
            <SharedStatus
              dataId={`ap-shared-status-${project?.shared}`}
              status={project?.shared || ''}
            />
          </div>
        }
        popLink={window.location.pathname
          .split('/')
          .splice(0, window.location.pathname.split('/').length - 1)
          .join('/')}
      >
        {buttonsRenderFunction()}
        {children}
      </PageHeader>
      <NavigationTabs items={data} />
    </>
  );
};

export { ChatAnalyticPageHeader };
