import { Link, useRouteError } from 'react-router-dom';
import React from 'react';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { BrokenRobotIcon } from '@app/components/ui/icons/icons-list';
import useTranslation from '@app/hooks/use-translation';
import { NotFoundedPage } from '@app/pages';

function ErrorBoundaryPage() {
  const { t } = useTranslation('pages.notFound');
  const error = useRouteError() as string;

  if (process.env.NODE_ENV === 'development')
    return (
      <div className="w-screen h-screen inline-flex items-center justify-center">
        <div className="flex flex-col items-center">
          <BrokenRobotIcon size={65} className="text-speech_analitics" />
          <h1 className="text-[20px] text-center font-[500] uppercase">{t('title')}</h1>
          <code className="bg-speech_analitics rounded-[10px] shadow-medium p-[10px] text-white w-[400px] text-[10px] font-bold">
            {error.toString()}
          </code>
          <Link
            className="text-active text-center hover:opacity-80 lowercase"
            to={navigationRoutes.main}
          >
            {t('link.main')}
          </Link>
        </div>
      </div>
    );
  return <NotFoundedPage />;
}

export default ErrorBoundaryPage;
