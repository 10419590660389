import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ShieldLefthalfFilledIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_335)">
          <path
            d="M6.57324 15.4121C6.57324 18.6113 8.33105 19.8682 13.376 22.6279C13.6045 22.751 13.8418 22.8125 13.9912 22.8125C14.1494 22.8125 14.3867 22.751 14.6152 22.6279C19.6602 19.877 21.418 18.6113 21.418 15.4121V8.75879C21.418 7.72168 21.0137 7.35254 20.1436 6.9834C19.168 6.57031 15.8721 5.41016 14.9053 5.09375C14.624 4.99707 14.29 4.93555 13.9912 4.93555C13.7012 4.93555 13.3672 5.01465 13.0771 5.09375C12.1191 5.35742 8.82324 6.5791 7.84766 6.9834C6.97754 7.34375 6.57324 7.72168 6.57324 8.75879V15.4121ZM14.1494 20.9404V6.60547C14.2285 6.61426 14.3164 6.64062 14.4482 6.68457C15.6348 7.15039 18.1396 8.03809 19.458 8.5127C19.7041 8.60059 19.7656 8.73242 19.7656 9.04004V15.0781C19.7656 17.6709 18.4736 18.374 14.501 20.7646C14.3516 20.8613 14.2461 20.9141 14.1494 20.9404Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_335">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ShieldLefthalfFilledIcon;
