import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChartsLayoutType,
  DashboardChartSettings,
  dashboardChartsSettings,
} from '@app/interfaces/dashboards.type';
import { ReportChart } from '@app/interfaces/report.type';

const initialState: {
  charts: (dashboardChartsSettings & ReportChart)[] | null;
  isLoading: boolean;
  dashboardLayout: ChartsLayoutType[] | null;
} = {
  charts: null,
  dashboardLayout: null,
  isLoading: true,
};

const dashboardChartsSlice = createSlice({
  name: 'dashboardChartsSlice',
  initialState,
  reducers: {
    setInitialCharts: (state, { payload }) => {
      state.isLoading = true;
      const { data, type } = payload;
      state.charts = data;
      if (type === 'initialSetup') {
        state.dashboardLayout = data.map((chart) => chart.layout);
      }
      state.isLoading = false;
    },
    changeInitialLayout: (state, { payload }) => {
      state.dashboardLayout = payload;
    },
    changeDashboardChartsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    addNewChartOnDashboard: (
      state,
      {
        payload: { createdChartOnDashboard, createdChartData },
      }: PayloadAction<{
        createdChartOnDashboard: DashboardChartSettings;
        createdChartData: ReportChart;
      }>,
    ) => {
      // state.isLoading = true;
      const layout = {
        ...createdChartOnDashboard.layout,
        i: createdChartOnDashboard.dashboard_graph_id,
      };
      const formattedChartObject: dashboardChartsSettings & ReportChart = {
        ...createdChartData,
        layout: layout,
        dashboard_graph_id: createdChartOnDashboard.dashboard_graph_id,
        live_report_id: createdChartOnDashboard.live_report_id,
      };
      state.charts?.push(formattedChartObject);
      state.dashboardLayout?.push(layout);
      // state.isLoading = false;
    },
    editSelectedChartOnDashboard: (
      state,
      {
        payload: { editedChartOnDashboard, editedChartData },
      }: PayloadAction<{
        editedChartOnDashboard: DashboardChartSettings;
        editedChartData: ReportChart;
      }>,
    ) => {
      const layout = {
        ...editedChartOnDashboard.layout,
        i: editedChartOnDashboard.dashboard_graph_id,
      };
      const formattedChartObject: dashboardChartsSettings & ReportChart = {
        ...editedChartData,
        layout: layout,
        dashboard_graph_id: editedChartOnDashboard.dashboard_graph_id,
        live_report_id: editedChartOnDashboard.live_report_id,
      };
      state.charts =
        state.charts?.map((draftChart) => {
          if (draftChart.dashboard_graph_id === editedChartOnDashboard.dashboard_graph_id) {
            return formattedChartObject;
          }
          return draftChart;
        }) || [];
    },
    editSelectedChartRangeDashboard: (
      state,
      {
        payload: { chartData, layout, id },
      }: PayloadAction<{
        chartData: ReportChart;
        layout?: ChartsLayoutType;
        id: string;
      }>,
    ) => {
      state.charts =
        state.charts?.map((draftChart) => {
          if (draftChart.dashboard_graph_id === id) {
            return {
              ...draftChart,
              categories: chartData.categories,
              series: chartData.series,
              system_date_diapason: chartData.system_date_diapason,
              range: chartData.range,
              layout: layout || draftChart.layout,
            };
          }
          return draftChart;
        }) || [];
    },
    deleteChartFromDashboard: (state, { payload }: PayloadAction<string>) => {
      state.charts = state.charts?.filter((chart) => chart.dashboard_graph_id !== payload) || [];
    },
    changeAllCharts: (
      state,
      { payload }: PayloadAction<(dashboardChartsSettings & ReportChart)[] | null>,
    ) => {
      state.isLoading = true;
      state.charts = payload;
      state.isLoading = false;
    },
  },
});

export default dashboardChartsSlice.reducer;

export const {
  setInitialCharts,
  changeInitialLayout,
  addNewChartOnDashboard,
  deleteChartFromDashboard,
  editSelectedChartOnDashboard,
  changeDashboardChartsLoading,
  changeAllCharts,
  editSelectedChartRangeDashboard,
} = dashboardChartsSlice.actions;
