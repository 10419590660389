import React, { FC, ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';
import { TableTitle } from '@app/components/table/table.type';
// const DEFAULT_CELL_SIZE = 180;
// const DEFAULT_FILTER_SIZE = 30;
export const TableWrapperForColsAndData: FC<{
  children: ReactNode;
  columns: TableTitle[];
  isScrollArea: boolean;
  colsMode?: boolean;
  colsLayout?: { x: number; w: number; y: number; h: number; i: string }[];
  colWidth?: number;
  firstSize?: number;
  secondSize?: number;
  scrollWidth?: number;
}> = ({
  children,
  columns,
  secondSize,
  firstSize,
  isScrollArea,
  colWidth,
  colsMode = false,
  colsLayout,
  scrollWidth,
}) => {
  const [templateCols, changeTemplateCols] = useState<string>();
  useEffect(() => {
    const colsData = columns
      .map((column) =>
        colsLayout
          ? `${Math.floor(
              (colsLayout?.find((col) => col.i === column.index)?.w || 3) * (colWidth || 30),
            )}px`
          : `${100}px`,
      )
      .join(' ');
    changeTemplateCols(colsData);
  }, [colWidth, colsLayout, columns]);
  return (
    <div
      // gap-x-[10px]
      className={cn('grid self-baseline  gap-y-[5px] w-full')}
      style={{
        gridTemplateColumns: !isScrollArea
          ? colsMode
            ? `${templateCols}`
            : `${firstSize || 160}px ${templateCols} ${
                (scrollWidth || 0) < 1050 ? '1fr' : `${secondSize || 140}px`
              }`
          : `${firstSize || 160}px ${templateCols} 1fr`,
      }}
    >
      {children}
    </div>
  );
};
