// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as Blockly from 'blockly';

import { formulaBlocks, getBlockLocale } from '../data/blockly-colour.data';

Blockly.Blocks['formula_if'] = {
  init: function () {
    const locale = getBlockLocale('formula_if');
    this.setInputsInline(false);
    this.setOutput(true);
    this.appendDummyInput().appendField(locale.name);
    this.appendValueInput('EXPRESSION');
    this.appendDummyInput().appendField(locale.then);
    this.appendValueInput('VALUE_IF_TRUE');
    this.appendDummyInput().appendField(locale.otherwise);
    this.appendValueInput('VALUE_IF_FALSE');
    this.setTooltip(locale.help);
    this.setColour(formulaBlocks.colors.blue.c1);
  },
};

Blockly.Blocks['formula_not'] = {
  init: function () {
    const locale = getBlockLocale('formula_not');
    this.setInputsInline(false);
    this.setOutput(true);
    this.appendDummyInput().appendField(locale.name);
    this.appendValueInput('EXPRESSION');
    this.setTooltip(locale.help);
    this.setColour(formulaBlocks.colors.blue.c3);
  },
};

Blockly.Blocks['formula_and_or'] = {
  init: function () {
    this.inputName = 'EXPRESSION';
    const locale = getBlockLocale('formula_and_or');
    this.setInputsInline(false);
    this.setOutput(true);
    this.appendDummyInput().appendField(new Blockly.FieldDropdown(locale.name), 'NAME');
    this.addInput(1);
    this.addInput(2);
    this.setTooltip(locale.help);
    this.setColour(formulaBlocks.colors.blue.c2);
    // this.appendDummyInput().appendField(
    //   new Blockly.FieldDropdown([
    //     ['AND', 'and'],
    //     ['OR', 'or'],
    //   ]),
    //   'NAME',
    // );
  },
  // customContextMenu: function (options) {
  //   // eslint-disable-next-line @typescript-eslint/no-this-alias
  //   const block = this;
  //   const locale = getBlockLocale('formula_and_or');
  //
  //   const optionAdd = {
  //     enabled: this.inputList.length < 11,
  //     text: locale.button.add,
  //     callback: function () {
  //       const count = block.inputList.length;
  //       block.addInput(count);
  //     },
  //   };
  //   options.push(optionAdd);
  //
  //   const optionRemove = {
  //     enabled: block.inputList.length > 3,
  //     text: locale.button.remove,
  //     callback: function () {
  //       const count = block.inputList.length;
  //       block.deleteInput(count - 1);
  //     },
  //   };
  //   options.push(optionRemove);
  // },
  // mutationToDom: function () {
  //   const count = this.inputList.length;
  //   const container = Blockly.utils.xml.createElement('mutation');
  //   container.setAttribute('count', count);
  //   return container;
  // },
  // domToMutation: function (xmlElement) {
  //   const count = parseInt(xmlElement.getAttribute('count'));
  //   if (count > this.inputList.length) {
  //     for (let i = this.inputList.length; i < count; i++) {
  //       this.addInput(i);
  //     }
  //   }
  // },
  addInput(num) {
    this.appendValueInput(this.inputName + '_' + num);
    this.save();
  },
  // deleteInput(num) {
  //   this.removeInput(this.inputName + '_' + num);
  //   this.save();
  // },
  save() {
    this.workspace.listeners.forEach((l) => {
      if (l.name === 'bound saveBlockly') {
        l();
      }
    });
  },
};
