import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { ChannelSettingObject, CreateChannelType } from '@app/interfaces/settings.type';

import { baseQuery } from '../baseQuery';
export const commonSettingsApi = createApi({
  reducerPath: 'commonSettingsApi',
  tagTypes: ['commonSettings'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getCommonSettings: build.query<ChannelSettingObject[], void>({
      query: () => ({
        url: serverRoutes.commonSettingsChannels,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ settings_channels_id }) => ({
                type: 'commonSettings' as const,
                settings_channels_id,
              })),
              'commonSettings',
            ]
          : ['commonSettings'],
    }),
    addChannel: build.mutation<ChannelSettingObject, CreateChannelType>({
      query: (body) => ({
        url: serverRoutes.commonSettingChannel,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['commonSettings'],
    }),
    deleteChannel: build.mutation({
      query: (body: string) => ({
        url: serverRoutes.commonSettingChannel,
        method: 'DELETE',
        params: { id: body },
      }),
      invalidatesTags: ['commonSettings'],
    }),
    editChannel: build.mutation<ChannelSettingObject, ChannelSettingObject>({
      query: (body) => ({
        url: serverRoutes.commonSettingChannel,
        method: 'PUT',
        params: { id: body.settings_channels_id },
        body,
      }),
      invalidatesTags: ['commonSettings'],
    }),
    getChannel: build.query<ChannelSettingObject, string>({
      query: (body) => ({
        url: serverRoutes.commonSettingChannel,
        params: { id: body },
      }),
    }),
  }),
});

export const {
  useGetCommonSettingsQuery,
  useAddChannelMutation,
  useDeleteChannelMutation,
  useLazyGetChannelQuery,
  useEditChannelMutation,
} = commonSettingsApi;
