import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { BreadcrumbsItem } from '@app/layouts/layout';
import {
  ReportChartPage,
  ReportsPage,
  ReportOutlet,
  ReportRecordsPage,
  ReportSettingsPage,
  ReportRecordPage,
} from '@app/pages';
import RequiredAuth from '@app/routes/required-auth.route';

export const chatReportsRoute = (
  <Route
    path={navigationRoutes.chatReports}
    handle={{
      crumb: () => <BreadcrumbsItem path={navigationRoutes.chatReports} crumb="reports" />,
    }}
  >
    <Route
      index
      element={
        <RequiredAuth>
          <ReportsPage currentTemplate="chat" />
        </RequiredAuth>
      }
    />
    <Route
      path=":id"
      element={<ReportOutlet currentTemplate={'chat'} />}
      handle={{
        crumb: () => <BreadcrumbsItem path={navigationRoutes.chatReports} crumb="report" />,
      }}
    >
      <Route
        index
        element={
          <RequiredAuth>
            <ReportChartPage />
          </RequiredAuth>
        }
      />
      <Route
        path={navigationRoutes.settings}
        element={<ReportSettingsPage />}
        handle={{
          crumb: () => (
            <BreadcrumbsItem path={navigationRoutes.chatReports} crumb="report_settings" />
          ),
        }}
      />
      <Route
        path={navigationRoutes.reportRecords}
        handle={{
          crumb: () => (
            <BreadcrumbsItem
              path={`${navigationRoutes.chatReports}/${navigationRoutes.reportRecords}`}
              crumb="report_list"
            />
          ),
        }}
      >
        <Route
          index
          element={
            <RequiredAuth>
              <ReportRecordsPage />
            </RequiredAuth>
          }
        />
        <Route
          path=":analytics_id/:record_id"
          handle={{
            crumb: () => <BreadcrumbsItem path={navigationRoutes.chatReports} crumb="recordsId" />,
          }}
          element={
            <RequiredAuth>
              <ReportRecordPage />
            </RequiredAuth>
          }
        />
      </Route>
    </Route>
  </Route>
);
