import React, { FC } from 'react';

import { IconType } from '../icon.type';

const XmarkAppIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_464)" filter="url(#filter0_d_610_464)">
          <path
            d="M21.1191 6.5791C20.2139 5.67383 18.9658 5.49805 17.3662 5.49805H10.5986C9.02539 5.49805 7.76855 5.68262 6.87207 6.5791C5.9668 7.48438 5.7998 8.72363 5.7998 10.3057V17.0117C5.7998 18.6113 5.9668 19.8506 6.86328 20.7559C7.77734 21.6523 9.02539 21.8281 10.625 21.8281H17.3662C18.9658 21.8281 20.2139 21.6523 21.1191 20.7559C22.0244 19.8506 22.1914 18.6113 22.1914 17.0117V10.3232C22.1914 8.72363 22.0244 7.47559 21.1191 6.5791ZM20.4688 10.0859V17.249C20.4688 18.1631 20.3457 19.0068 19.8623 19.4902C19.3701 19.9824 18.5176 20.1055 17.6123 20.1055H10.3789C9.47363 20.1055 8.62109 19.9824 8.12891 19.4902C7.63672 19.0068 7.52246 18.1631 7.52246 17.249V10.1035C7.52246 9.17188 7.63672 8.32812 8.12012 7.83594C8.6123 7.34375 9.47363 7.2207 10.3965 7.2207H17.6123C18.5176 7.2207 19.3701 7.35254 19.8623 7.83594C20.3545 8.32812 20.4688 9.17188 20.4688 10.0859ZM11.1084 17.3457C11.3369 17.3457 11.5303 17.2666 11.6797 17.1084L14 14.7881L16.3291 17.1084C16.4785 17.2578 16.6631 17.3457 16.9004 17.3457C17.3398 17.3457 17.6914 16.9941 17.6914 16.5459C17.6914 16.335 17.6035 16.1416 17.4541 15.9922L15.125 13.6631L17.4629 11.3252C17.6211 11.167 17.7002 10.9824 17.7002 10.7715C17.7002 10.332 17.3486 9.98047 16.9092 9.98047C16.6895 9.98047 16.5137 10.0596 16.3467 10.2266L14 12.5557L11.6709 10.2354C11.5215 10.0771 11.3369 9.99805 11.1084 9.99805C10.6689 9.99805 10.3174 10.3408 10.3174 10.7803C10.3174 10.9912 10.4053 11.1846 10.5547 11.334L12.8838 13.6631L10.5547 16.001C10.4053 16.1416 10.3174 16.335 10.3174 16.5459C10.3174 16.9941 10.6689 17.3457 11.1084 17.3457Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_610_464"
            x="-4"
            y="0"
            width="36"
            height="36"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_610_464" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_610_464"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_610_464">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default XmarkAppIcon;
