import React, { FC } from 'react';
import cn from 'classnames';
import { LoadingIcon } from '@icons';

type SkeletonProps = {
  width?: number | string;
  height?: number | string;
  count?: number;
  className?: string;
  label?: string;
  labelSplitByComa?: boolean;
  skeletonWithLoading?: boolean;
};
const Skeleton: FC<SkeletonProps> = (props) => {
  const {
    width = '100%',
    skeletonWithLoading,
    labelSplitByComa,
    height = 32,
    count = 1,
    className,
    label,
  } = props;
  const skeletonStyle = { width: checkSizeForStyles(width), height: checkSizeForStyles(height) };

  function checkSizeForStyles(size: string | number) {
    return typeof size === 'number' ? `${size}px` : size;
  }

  return (
    <>
      {Array(count)
        .fill('_')
        .map((_, index) => (
          <div
            key={index}
            className={cn(
              'bg-b_dark/80 flex items-center relative justify-center shadow-medium animate-pulse rounded-defaultR',
              className,
            )}
            style={skeletonStyle}
          >
            <div className="text-action text-center text-[14px] p-2">
              {labelSplitByComa
                ? label?.split('.').map((str, ind) => (
                    <React.Fragment key={ind}>
                      {str}. <br />
                    </React.Fragment>
                  ))
                : label}
            </div>
            {skeletonWithLoading && <LoadingIcon className="absolute top-[8em]" size={100} />}
          </div>
        ))}
    </>
  );
};

export default Skeleton;
