import React, { FC } from 'react';

import { IconType } from '../icon.type';

const LocationSquareIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_605_1171)">
          <path
            d="M8.67383 21.8721H19.3174C21.2158 21.8721 22.2002 20.8877 22.2002 19.0156V8.31934C22.2002 6.43848 21.2158 5.46289 19.3174 5.46289H8.67383C6.77539 5.46289 5.79102 6.43848 5.79102 8.31934V19.0156C5.79102 20.8965 6.77539 21.8721 8.67383 21.8721ZM8.7793 20.1494C7.96191 20.1494 7.51367 19.7188 7.51367 18.8662V8.45996C7.51367 7.60742 7.96191 7.18555 8.7793 7.18555H19.2119C20.0205 7.18555 20.4775 7.60742 20.4775 8.45996V18.8662C20.4775 19.7188 20.0205 20.1494 19.2119 20.1494H8.7793ZM9.54395 14.3838H13.0596C13.2266 14.3838 13.3408 14.498 13.3408 14.6738V18.1455C13.3408 18.9541 14.2285 19.0947 14.5361 18.4355L18.2627 10.4463C18.5967 9.73438 17.9639 9.10156 17.2344 9.43555L9.2627 13.1885C8.60352 13.5049 8.75293 14.3838 9.54395 14.3838Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_605_1171">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default LocationSquareIcon;
