import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { WordCloudResponseType, WordCloudType } from '@app/interfaces/words-cloud.type';

import { baseQuery } from '../baseQuery';
type requestType = { data: Omit<WordCloudType, 'project_id'>; project_id: string } | undefined;
export const wordsCloudApi = createApi({
  reducerPath: 'wordsCloudApi',
  tagTypes: ['wordCloud'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getWordsCloud: build.query<WordCloudResponseType, requestType>({
      query: (data) => ({
        url: serverRoutes.wordsCloud,
        body: data?.data,
        params: { project_id: data?.project_id },
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetWordsCloudQuery } = wordsCloudApi;
