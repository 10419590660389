import React, { FC } from 'react';

import { IconType } from '../icon.type';

const EllipsisVerticalBubbleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_609_14)">
          <path
            d="M9.80762 23.4453C10.2998 23.4453 10.6689 23.208 11.2666 22.6807L14.2812 20.0088H19.5986C22.2441 20.0088 23.7295 18.4971 23.7295 15.8867V9.06641C23.7295 6.45605 22.2441 4.94434 19.5986 4.94434H8.39258C5.74707 4.94434 4.26172 6.45605 4.26172 9.06641V15.8867C4.26172 18.4971 5.78223 20.0088 8.33105 20.0088H8.69141V22.1797C8.69141 22.9619 9.10449 23.4453 9.80762 23.4453ZM10.2383 21.5117V19.0068C10.2383 18.4883 10.0186 18.2861 9.51758 18.2861H8.43652C6.77539 18.2861 5.98438 17.4512 5.98438 15.834V9.11914C5.98438 7.50195 6.77539 6.66699 8.43652 6.66699H19.5459C21.207 6.66699 22.0068 7.50195 22.0068 9.11914V15.834C22.0068 17.4512 21.207 18.2861 19.5459 18.2861H14.1846C13.6572 18.2861 13.3936 18.374 13.0244 18.7607L10.2383 21.5117ZM12.7871 8.94336C12.7871 9.62012 13.3232 10.165 14 10.165C14.6768 10.165 15.2217 9.62012 15.2217 8.94336C15.2217 8.2666 14.6768 7.72168 14 7.72168C13.3232 7.72168 12.7871 8.2666 12.7871 8.94336ZM12.7871 12.4766C12.7871 13.1533 13.3232 13.6895 14 13.6895C14.6768 13.6895 15.2217 13.1533 15.2217 12.4766C15.2217 11.7998 14.6768 11.2549 14 11.2549C13.3232 11.2549 12.7871 11.7998 12.7871 12.4766ZM12.7871 16.0098C12.7871 16.6777 13.3232 17.2227 14 17.2227C14.6768 17.2227 15.2217 16.6777 15.2217 16.0098C15.2217 15.333 14.6768 14.7881 14 14.7881C13.3232 14.7881 12.7871 15.333 12.7871 16.0098Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_609_14">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default EllipsisVerticalBubbleIcon;
