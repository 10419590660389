import cn from 'classnames';

export const tableTitleClassName = cn('uppercase', 'text-xs', 'font-bold');
export const tableValueClassName = cn('text-3color');
export const tableRowsClassName = (isNotLast: boolean) =>
  cn(
    'grid',
    'grid-cols-[2fr_2fr_1fr]',
    isNotLast && 'border-b-b_medium border-dotted border-b',
    'gap-2',
    'mx-[26px]',
  );
export const tableRowClassName = cn(
  'text-sm',
  'truncate',
  'text-1color',
  'h-[31px]',
  'inline-flex',
  'items-center',
);
