import React, { FC } from 'react';

import { IconType } from '../icon.type';

const EyeSlashCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_607_11)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM17.9639 18.2422C18.1309 18.4092 18.4121 18.418 18.5791 18.2422C18.7549 18.0664 18.7549 17.8027 18.5791 17.627L10.0098 9.05762C9.84277 8.88184 9.56152 8.88184 9.38574 9.05762C9.21875 9.22461 9.21875 9.50586 9.38574 9.67285L17.9639 18.2422ZM16.3379 14.4014L18.0605 16.1328C19.3086 15.2363 20.0469 14.1465 20.0469 13.6543C20.0469 12.7578 17.5947 9.84863 13.9912 9.84863C13.3145 9.84863 12.6729 9.9541 12.084 10.1387L13.2529 11.3164C13.4814 11.2285 13.7363 11.1846 13.9912 11.1846C15.3623 11.1846 16.4697 12.2832 16.4697 13.6631C16.4697 13.918 16.4258 14.1641 16.3379 14.4014ZM15.916 17.1787L14.7383 15.9922C14.501 16.0801 14.2549 16.1328 13.9912 16.1328C12.6201 16.1328 11.5127 14.999 11.5039 13.6631C11.5039 13.3994 11.5566 13.1357 11.6445 12.9072L9.93066 11.1934C8.68262 12.0811 7.93555 13.1709 7.93555 13.6543C7.93555 14.5596 10.4229 17.4688 13.9912 17.4688C14.6768 17.4688 15.3271 17.3545 15.916 17.1787ZM14.2637 12.3271L15.3184 13.3818C15.3008 12.8281 14.8438 12.3447 14.2637 12.3271ZM13.7275 14.9814L12.6641 13.918C12.6553 14.4629 13.1299 14.9814 13.7275 14.9814Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_607_11">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default EyeSlashCircleIcon;
