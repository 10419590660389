import React, { FC } from 'react';
import { optionItemClassName } from '@app/components/ui/select/select.styles';
import { OptionItem } from '@app/components/ui/select/select.type';
import cn from 'classnames';
import Icon from '@app/components/ui/icons';
import { pictogramsData } from '@app/components/metric-table-value-build';
import { IconNameType } from '@app/components/ui/icons/icon-name.type';

const SelectItem: FC<{
  onChange: (option: OptionItem) => void;
  option: OptionItem;
  active: boolean;
  isMulti?: boolean;
  pictogramsMode?: boolean;
  withoutPadding?: boolean;
  icon?: IconNameType;
}> = (props) => {
  const {
    onChange,
    option,
    active,
    isMulti,
    withoutPadding = false,
    pictogramsMode = false,
    icon,
  } = props;

  function handleOnClickOption(option: OptionItem) {
    if (option?.disabled) return;
    onChange?.(option);
  }
  return (
    <li
      className={optionItemClassName({
        nullable: option.nullable || false,
        active,
        disabled: option?.disabled || false,
        pictogramsMode,
        withoutPadding,
      })}
      onClick={handleOnClickOption.bind(null, option)}
      title={String(option.title)}
    >
      {pictogramsMode ? (
        <div className="flex h-full w-full items-center justify-center p-1 bg-action/[.08] rounded-full">
          <Icon size={22} className="text-action" name={pictogramsData[option.title]} />
        </div>
      ) : (
        <>
          <div className="truncate w-full flex items-center">
            {icon && <Icon className="text-action" size={20} name={icon} />}
            <span
              className={cn(
                option.value === '' && 'text-b_dark',
                option.disabled && '!text-3color',
                'text-1color font-[500] tracking-tight leading-[110%] ml-[5px]',
                `${active && '!text-action'}`,
              )}
            >
              {option.title}
            </span>
          </div>
          {isMulti && active && <Icon className={'text-action'} size={14} name={'CheckIcon'} />}
        </>
      )}
    </li>
  );
};

export default SelectItem;
