import cn from 'classnames';
export const getMetricScoreStyles = (value: number) => {
  switch (value) {
    case 5:
      return cn('bg-[#3BA86D] w-[135px]');
    case 4:
      return cn('bg-[#7EC14E] w-[108px]');
    case 3:
      return cn('bg-[#F2CC31] w-[81px]');
    case 2:
      return cn('bg-[#FFA12E] w-[54px]');
    case 1:
      return cn('bg-[#E92E5D] w-[27px]');

    default:
      return;
  }
};
export const getMetricScoreStyles100 = (value: number) => {
  if (value >= 81 && value <= 100) return cn('bg-[#3BA86D]');
  if (value >= 61 && value <= 80) return cn('bg-[#7EC14E]');
  if (value >= 41 && value <= 60) return cn('bg-[#F2CC31]');
  if (value >= 21 && value <= 40) return cn('bg-[#FFA12E]');
  if (value >= 1 && value <= 20) return cn('bg-[#E92E5D]');
  if (value === 0) return cn('bg-[#E92E5D] pl-[20px]');
};
