import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Loading } from '@ui';
type RouterLoadingHookPropsType = {
  children: ReactNode;
};
export const RouterLoadingHook: FC<RouterLoadingHookPropsType> = ({ children }) => {
  const [loading, changeLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => changeLoading((loading) => !loading), 1000);
  }, []);
  if (loading) return <Loading />;
  return <>{children}</>;
};
