module.exports = {
  required: 'Поле не заполнено',
  fields: {
    email: 'Email',
    login: 'Логин',
    password: 'Пароль',
  },
  placeholders: {
    email: 'email@domain.com',
    password: '******',
    login: 'Введите логин',
  },
  errors: {
    email: {
      required: 'Поле не заполнено',
      invalid: 'Неверный email',
    },
    password: {
      required: 'Пароль не заполнен',
      short: 'Пароль должен быть длинней 6 символов',
    },
  },
  dropdown: {
    placeholder: 'Select',
    multiSelected: '%{count} элементов выбрано',
  },
  search: {
    placeholder: 'Поиск',
  },
};
