import React, { FC } from 'react';
import AudioBuild from '@app/components/audio-build';
import { NotFoundedPage } from '@app/pages';
import { useOutletContext, useParams } from 'react-router';

import { ReportOutletContext } from './report.outlet';

const ReportRecordPage: FC = () => {
  const { record_id, analytics_id } = useParams();
  const { currentTemplate } = useOutletContext<ReportOutletContext>();

  if (!record_id) return <NotFoundedPage />;
  return (
    <AudioBuild
      currentTemplate={currentTemplate}
      recordId={record_id}
      projectId={analytics_id}
      withMetric
    />
  );
};

export default ReportRecordPage;
