module.exports = {
  created: 'New',
  life: 'LIFE',
  updating: 'Updating',
  done: 'Done',
  archive: 'Archive',
  pause: 'Pause',
  deleting: 'Deleting',
  filling: 'Filling',
  active: 'Active',
  stopping: 'Stop',
  starting: 'Start',
  actualization: 'Updated',
  removing: 'Is being removed',
  disabled: 'inactive',
  onactive: 'active'
};
