import React, { useEffect, useMemo, useState } from 'react';

import useTranslation from '@app/hooks/use-translation';
import { useGetInformMessagesFromTgAndEmailQuery } from '@app/store/api/inform-messages.api';
import InformMessagesTableBlock from '@app/pages/settings/blocks/inform-messages/inform-messages-table.block';
import { Select } from '@ui';
import { OptionItem } from '@app/components/ui/select/select.type';
import {
  MessagesRequestSourceEmailType,
  MessagesRequestSourceTelegramType,
  MixedMessagesRequestType,
  MixedMessagesType,
} from '@app/interfaces/inform-messages';
const informMessagesPlatforms: OptionItem[] = [
  { title: 'Telegram', value: 'telegram' },
  { title: 'E-mail', value: 'email' },
];
type PlatformsType = 'telegram' | 'email';

const DEFAULT_LIMIT = 10;
const DEFAULT_OFFSET = 0;
const InformMessagesPage = () => {
  const { t } = useTranslation('pages.settings.messages_queue');

  const [currentPlatform, changePlatform] = useState<PlatformsType>(
    String(informMessagesPlatforms[1].value) as PlatformsType,
  );

  const [messagesData, setMessagesData] = useState<MixedMessagesType | undefined>();
  const [filterParams, changeFilterParams] = useState<MixedMessagesRequestType>({
    telegram: {
      offset: DEFAULT_OFFSET,
      limit: DEFAULT_LIMIT,
      source: (messagesData?.telegram.source as MessagesRequestSourceTelegramType) || 'report',
    },
    email: {
      offset: DEFAULT_OFFSET,
      limit: DEFAULT_LIMIT,
      source: messagesData?.email.source || 'report',
    },
  });

  const { data } = useGetInformMessagesFromTgAndEmailQuery(filterParams);
  useEffect(() => {
    setMessagesData(data);
  }, [data]);
  const informMessagesSourceOptions: OptionItem[] = useMemo(
    () =>
      currentPlatform === 'telegram'
        ? [
            { title: t('reports'), value: 'report' },
            { title: t('triggers'), value: 'trigger' },
          ]
        : [
            { title: t('reports'), value: 'report' },
            { title: t('triggers'), value: 'trigger' },
            { title: t('system_messages'), value: 'sender' },
          ],
    [currentPlatform, t],
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars

  return (
    <div className="pt-[30px] w-full">
      <div className="flex  w-full max-w-[650px] items-start gap-[20px] ">
        <div className="flex items-center w-full max-w-[250px] justify-start gap-[5px]">
          <h1 className="w-full max-w-[130px]">{t('send_method')}</h1>
          <div className="w-full max-w-[120px]">
            <Select
              defaultValue={currentPlatform}
              onChange={(value) => {
                changePlatform(String(value) as PlatformsType);
                changeFilterParams((prev) => ({
                  ...prev,
                  [currentPlatform]: {
                    ...prev[currentPlatform],
                    limit: DEFAULT_LIMIT,
                    offset: DEFAULT_OFFSET,
                  },
                }));
              }}
              options={informMessagesPlatforms}
            />
          </div>
        </div>
        <div className="flex items-center gap-[5px] w-full max-w-[350px]">
          <h1 className="w-fit ">{t('message_type')}</h1>
          <div className="max-w-[210px] w-full">
            <Select
              defaultValue={
                filterParams?.[currentPlatform].source ||
                messagesData?.[currentPlatform].source ||
                'report'
              }
              onChange={(value) =>
                changeFilterParams((prev) => ({
                  ...prev,
                  [currentPlatform]: {
                    ...prev[currentPlatform],
                    source: value as MessagesRequestSourceEmailType,
                    limit: DEFAULT_LIMIT,
                    offset: DEFAULT_OFFSET,
                  },
                }))
              }
              options={informMessagesSourceOptions}
            />
          </div>
        </div>
      </div>
      <div className="w-full pt-[30px]">
        <InformMessagesTableBlock
          changeRequestParams={changeFilterParams}
          currentPlatform={currentPlatform}
          messagesOffset={filterParams[currentPlatform].offset as number}
          // setMessagesOffset={setMessagesOffset}
          messagesLimit={filterParams[currentPlatform].limit as number}
          // setMessagesLimit={setMessagesLimit}
          tableData={messagesData?.[currentPlatform]}
        />
      </div>
    </div>
  );
};

export default InformMessagesPage;
