module.exports = {
  popup_hints: {
    to_record_title: 'View record',
    download_record_button: 'Download record',
  },
  filter: {
    title: 'Filter project records',
    buttons: {
      create_report: 'Create a LIVE report based on a filter',
      create_report_rtk: 'Create report based on a filter',
      reset_filter: 'Reset',
      cancel: 'Clear'
    }
  },
  table: {
    first_column_title: 'Duration',
    last_column_title: 'Actions',
  },
};
