import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ArrowshapeTurnUpRightCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_614_2217)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM9.80762 17.4248C10.0449 17.4248 10.2734 17.3457 10.4492 17.0205C11.1875 15.6318 12.2422 15.1396 13.9561 15.1396H14.0791V16.8359C14.0791 17.1787 14.3428 17.4424 14.6943 17.4424C14.9404 17.4424 15.1338 17.3369 15.3711 17.1172L19.0537 13.6631C19.2383 13.4961 19.2998 13.3027 19.2998 13.127C19.2998 12.96 19.2383 12.7666 19.0537 12.5996L15.3711 9.17188C15.1074 8.92578 14.9404 8.82031 14.6943 8.82031C14.3428 8.82031 14.0791 9.10156 14.0791 9.45312V11.1318H13.9561C10.7393 11.1318 9.24512 13.1973 9.24512 16.5811C9.24512 17.1348 9.5 17.4248 9.80762 17.4248Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_614_2217">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ArrowshapeTurnUpRightCircleIcon;
