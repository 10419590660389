import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  auditLogObjectType,
  auditLogsList,
  auditLogsRequestType,
} from '@app/interfaces/slices-types/audit-logs-slice.type';

import { baseQuery } from '../baseQuery';

export const auditLogsApi = createApi({
  reducerPath: 'auditLogsApi',
  tagTypes: ['auditLogs'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getAuditLogs: build.query<auditLogsList, auditLogsRequestType>({
      query: (body) => ({
        url: serverRoutes.audit_logs,
        body: body,
        // params: { project_id: data?.project_id },
        method: 'POST',
      }),
    }),
    getAuditLogDetail: build.query<auditLogObjectType, string>({
      query: (body) => ({
        url: serverRoutes.audit_log,
        params: { audit_logs_id: body },
      }),
    }),
  }),
});

export const { useGetAuditLogsQuery, useLazyGetAuditLogDetailQuery } = auditLogsApi;
