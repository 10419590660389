import { StaticReportType } from '@app/interfaces/staticReport';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

const customBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  prepareHeaders: (headers) => headers,
});

export const staticReportApi = createApi({
  reducerPath: 'staticReport',
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    getStaticReport: build.query<StaticReportType, { hash: string }>({
      query: (params) => ({ url: 'static_report', params, headers: {} }),
    }),
  }),
});

export const { useLazyGetStaticReportQuery } = staticReportApi;
