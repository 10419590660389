import React, { FC } from 'react';

import { IconType } from '../icon.type';

const StarBubbleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_609_2)">
          <path
            fill="currentColor"
            d="M9.808 23.445c.492 0 .86-.237 1.459-.764l3.014-2.672H19.6c2.645 0 4.13-1.512 4.13-4.122v-6.82c0-2.611-1.485-4.123-4.13-4.123H8.393c-2.646 0-4.131 1.512-4.131 4.122v6.82c0 2.611 1.52 4.123 4.069 4.123h.36v2.17c0 .783.413 1.266 1.117 1.266zm.43-1.933v-2.505c0-.519-.22-.72-.72-.72H8.437c-1.662 0-2.453-.836-2.453-2.453V9.119c0-1.617.791-2.452 2.453-2.452h11.109c1.661 0 2.46.835 2.46 2.452v6.715c0 1.617-.799 2.452-2.46 2.452h-5.361c-.528 0-.791.088-1.16.475l-2.787 2.75zm1.925-5.713l1.855-1.362 1.854 1.362c.378.281.826.026.65-.484l-.72-2.17 1.89-1.363c.386-.281.237-.764-.238-.764h-2.338l-.694-2.163c-.15-.448-.668-.448-.809 0l-.703 2.163h-2.347c-.465 0-.624.483-.228.764l1.898 1.363-.73 2.17c-.175.501.273.765.66.484z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_609_2">
            <path fill="#fff" d="M0 0H28V28H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default StarBubbleIcon;
