import React, { FC } from 'react';
import { CircleButton } from '@ui';
import { CircleChannelIcon } from '@icons';
import { ChannelSettingObject } from '@app/interfaces/settings.type';
import { TableColumn, TableItem } from '@app/components/rubber-table/table.types';
import RubberTable from '@app/components/rubber-table';
import useTranslation from '@app/hooks/use-translation';

type ChannelsTablePropsType = {
  tableData: ChannelSettingObject[];
  isLoading?: boolean;
  onEditIconClick: (id: string) => void;
  onDeleteIconClick: (id: string) => void;
};

const ChannelsTableBlock: FC<ChannelsTablePropsType> = (props) => {
  const { t } = useTranslation('pages.settings');
  const { tableData, isLoading, onEditIconClick, onDeleteIconClick } = props;
  const tableRecognitionColumns: Array<TableColumn> = [
    { index: 'name', title: t('common_setting.table.name') },
    { index: 'channel', title: t('common_setting.table.channel') },
    { index: 'actions', title: t('common_setting.table.actions'), maxWidth: 110, divider: 'left' },
  ];
  const tableRecognitionValues: Array<TableItem> = tableData.map((item) => ({
    ...item,
    name: (
      <div className="flex items-center text-[14px] h-full font-[700] text-0color">
        <CircleChannelIcon size={22} className="text-action mr-[10px]" />
        {item.name}
      </div>
    ),
    channel: (
      <div className="inline-flex items-center text-[14px] font-[400] text-0color">
        {item.channel}
      </div>
    ),
    actions: (
      <div className="inline-flex items-center">
        <CircleButton
          onClick={() => onEditIconClick(item.settings_channels_id)}
          className="mr-[20px] text-1color"
          hintTitle={t('alias.popup_hints.setting')}
          size={18}
          dataId={`channel-${item.name}-configure`}
          icon="SettingIcon"
        />
        <CircleButton
          onClick={() => onDeleteIconClick(item.settings_channels_id)}
          hintTitle={t('alias.popup_hints.delete')}
          className="text-basic_red"
          size={18}
          dataId={`channel-${item.name}-delete`}
          icon="TrashIcon"
        />
      </div>
    ),
  }));
  return (
    <RubberTable
      isPending={isLoading}
      columns={tableRecognitionColumns}
      dataSource={tableRecognitionValues}
    />
  );
};

export default ChannelsTableBlock;
