import React, { useState } from 'react';

import useTranslation from '@app/hooks/use-translation';

import { DropMenu } from '@app/components/ui';

import { Breadcrumbs } from './breadcrumbs';

const FooterBlock = () => {
  const theme = process.env.REACT_APP_THEME;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const { t } = useTranslation('components.footer');
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'ru');

  const copyrightMap = {
    default: t('copyright'),
    calltraffic: t('copyrightCallTraffic'),
    mtc: t('copyrightMtc'),
  };

  const versionMap = {
    default: t('version'),
    mtc: t('versionMtc'),
  };
  return (
    <div className="h-[40px] bg-bg_3 w-full">
      <div className="inline-flex w-full items-center h-full justify-between px-[20px]">
        <div className="inline-flex items-center">
          <Breadcrumbs />
        </div>

        {theme !== 'rtk' && (
          <div className="text-[14px] font-[500px] inline-flex items-center text-2color gap-[10px]">
            <div className="mr-[20px]">{versionMap[theme || 'default']}</div>
            <div>
              {copyrightMap[theme || 'default']}
              {new Date().getFullYear()}
            </div>
            {process.env.REACT_APP_LANG === 'true' && (
              <DropMenu
                dropSize={55}
                position="center"
                dropTop
                onClick={function (key: string): void {
                  setLang(key);
                  localStorage.setItem('lang', key);
                  window.location.reload();
                }}
                menu={[
                  { key: 'ru', title: 'RU' },
                  { key: 'en', title: 'EN' },
                ]}
              >
                <div className="uppercase">{lang}</div>
              </DropMenu>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FooterBlock;
