export const pieChartPalette = [
  { name: 'palette1', colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'] },
  { name: 'palette2', colors: ['#3F51B5', '#03A9F4', '#4CAF50', '#F9CE1D', '#FF9800'] },
  { name: 'palette3', colors: ['#33B2DF', '#546E7A', '#D4526E', '#13D8AA', '#A5978B'] },
  { name: 'palette4', colors: ['#4ECDC4', '#C7F464', '#81D4FA', '#546E7A', '#FD6A6A'] },
  { name: 'palette5', colors: ['#2B908F', '#F9A3A4', '#90EE7E', '#FA4443', '#69D2E7'] },
  { name: 'palette6', colors: ['#449DD1', '#F86624', '#EA3546', '#662E9B', '#C5D86D'] },
  { name: 'palette7', colors: ['#D7263D', '#1B998B', '#2E294E', '#F46036', '#E2C044'] },
  { name: 'palette8', colors: ['#662E9B', '#F86624', '#F9C80E', '#EA3546', '#43BCCD'] },
  { name: 'palette9', colors: ['#5C4742', '#A5978B', '#8D5B4C', '#5A2A27', '#C4BBAF'] },
  { name: 'palette10', colors: ['#A300D6', '#7D02EB', '#5653FE', '#2983FF', '#00B1F2'] },
];
