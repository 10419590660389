import React, { useState } from 'react';
import { Button, CircleButton, PageHeader, EasyModal } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { toast } from 'react-toastify';
import {
  useGetUsersQuery,
  useGetUserRoleQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
} from '@app/store/api/users.api';
import RubberTable from '@app/components/rubber-table';
import { DictionariesSkeleton } from '@app/pages/dictionaries-page/blocks/dictionaries-skeleton';
import AnalyticsStatus from '@app/components/ui/analytics-status/analytics-status';
import { UserModalForm } from '@app/pages/users/blocks/user-modal-form';

const UserPage = () => {
  const { t } = useTranslation('pages.usersPage');
  const { data: userBulkData, isLoading } = useGetUsersQuery(undefined, {
    pollingInterval: 10000,
  });
  const { data: userRole } = useGetUserRoleQuery();
  const [addUser] = useAddUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [editUser] = useEditUserMutation();

  const [isOpenAddUserModal, changeOpenAddUserModal] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [userIdForDelete, changeUserIdForDelete] = useState<string>();
  const [isOpenDeleteModal, changeOpenDeleteModal] = useState<boolean>(false);
  const [currentUserData, setCurrentUserData] = useState<any>(null);

  const getRoleNameById = (roleId: string) => {
    const role = userRole?.roles?.find((role) => role.role_id === roleId);
    return role ? role.description : 'Unknown Role';
  };

  const deleteHandleClick = (id: string) => {
    changeUserIdForDelete(id);
    changeOpenDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (userIdForDelete) {
      deleteUser(userIdForDelete)
        .unwrap()
        .then(() => {
          toast.success(t('toast.success_delete'));
          changeOpenDeleteModal(false);
        })
        .catch((error) => toast.error(`Ошибка: ${error.message}`));
    }
  };

  const editHandleClick = (user: any) => {
    setCurrentUserData(user);
    setIsEditMode(true);
    changeOpenAddUserModal(true);
  };

  const onFormSubmitHandler = (data: {
    username: string;
    role_id: string;
    login: string;
    password?: string;
    enable?: boolean;
  }) => {
    if (isEditMode) {
      editUser({
        ...data,
        id: currentUserData.id.toString(),
        enable: data.enable ?? false,
      })
        .unwrap()
        .then(() => {
          toast.success(t('toast.success_edit'));
          changeOpenAddUserModal(false);
          setIsEditMode(false);
          setCurrentUserData(null);
        })
        .catch((error) => {
          const errorMessage = error.data?.detail || error.error || error.message;
          toast.error(`${t('toast.error_while_edit')} ${errorMessage}`);
        });
    } else {
      addUser(data)
        .unwrap()
        .then(() => {
          toast.success(t('toast.success_add'));
          changeOpenAddUserModal(false);
          setIsEditMode(false);
          setCurrentUserData(null);
        })
        .catch((error) => {
          const errorMessage = error.data?.detail || error.error || error.message;
          toast.error(`${t('toast.error_while_add')} ${errorMessage}`);
        });
    }
  };

  const columns = [
    {
      title: t('table.userName'),
      index: 'nameCol',
    },
    {
      title: t('table.email'),
      index: 'email',
    },
    {
      title: t('table.role'),
      index: 'role',
    },
    {
      title: t('table.status'),
      index: 'status',
      maxWidth: 150,
    },
    {
      title: t('table.actions'),
      index: 'actions',
      maxWidth: 120,
    },
  ];

  const filteredArr = userBulkData?.users?.map((item) => ({
    ...item,
    nameCol: (
      <div>
        <h1>{item.username}</h1>
      </div>
    ),
    email: (
      <div>
        <h1>{item.login}</h1>
      </div>
    ),
    role: (
      <div>
        <h1>{getRoleNameById(item.role_id)}</h1>
      </div>
    ),
    status: (
      <div className="max-w-[80px]">
        <AnalyticsStatus status={item?.enable ? 'ONACTIVE' : 'DISABLED'} />
      </div>
    ),
    actions: (
      <div className="border-l-[1px] pl-[10px]">
        <CircleButton
          hintTitle={t('table.edit')}
          icon="SettingIcon"
          size={19}
          onClick={() => editHandleClick(item)}
        />
        <CircleButton
          hintTitle={t('table.delete')}
          icon="TrashIcon"
          className="text-basic_red ml-[20px]"
          onClick={() => deleteHandleClick(item.id)}
          disable={!item.can_be_deleted}
          size={19}
        />
      </div>
    ),
  }));

  const renderModal = () => {
    if (!isOpenAddUserModal || !userRole) return null;
    return (
      <UserModalForm
        userRole={userRole}
        isOpenAddUserModal={isOpenAddUserModal}
        changeOpenAddUserModal={changeOpenAddUserModal}
        onFormSubmit={onFormSubmitHandler}
        isEditMode={isEditMode}
        currentUserData={currentUserData}
      />
    );
  };

  if (isLoading) return <DictionariesSkeleton />;
  return (
    <div>
      <PageHeader icon="UserIcon" label={t('pageTitle')}>
        <Button
          data-id="new-user"
          label={t('add_user_button')}
          onClick={() => {
            changeOpenAddUserModal(true);
            setIsEditMode(false);
            setCurrentUserData(null);
          }}
          icon="CirclePlusIcon"
        />
      </PageHeader>
      <RubberTable columns={columns} dataSource={filteredArr || []} />
      {renderModal()}
      <EasyModal
        withoutFooter
        onClose={changeOpenDeleteModal.bind(null, false)}
        label={t('warning')}
        show={isOpenDeleteModal}
        variant="removal"
        onRemove={handleDeleteUser}
      >
        <div className="flex items-center justify-center">{t('delete_user_text')}</div>
      </EasyModal>
    </div>
  );
};

export default UserPage;
