module.exports = {
  presetFillChoose: {
    title: 'Conditions for selecting records',
    description: 'You can create a new preset or use a template',
    new_preset: 'New preset',
    from_template: 'from template',
    create_preset_button: 'Create preset'
  },
  system: {
    create_as_template_name: 'Enter template name',
  },
  filter: {
    title: 'Conditions for selecting records for the analytical project',
    buttons: {
      save: 'Save',
      save_as_template: 'Save as preset template',
      cancel: 'Cancel',
      delete: 'Delete'
    }
  },
  templatesModal: {
    title: 'Select a preset template',
    empty_templates_list: 'No templates'
  },
  table: {
    calls:  'calls',
    first_column_title: 'Duration',
    last_column_title: 'Actions',
  },
  popup_hints: {
    download_record_button: 'Download record',
    to_record_title_button: 'View record'
  },
};
