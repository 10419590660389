import React, { FC } from 'react';

import { IconType } from '../icon.type';

const HammerCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_609_77)" filter="url(#filter0_d_609_77)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM17.7705 14.3486C17.9727 14.542 18.2012 14.5596 18.4033 14.3662L19.6514 13.1182C19.8447 12.9336 19.8359 12.6875 19.6338 12.4941L19.335 12.1865C19.1592 12.0107 19.0449 11.9844 18.8516 12.002L18.3945 12.0547L18.0781 11.7559L18.2188 11.1934C18.2891 10.877 18.1836 10.5693 17.8584 10.2529L17.0234 9.41797C15.8281 8.24023 13.6221 8.11719 12.4092 9.33008C12.1982 9.54102 12.2246 9.78711 12.3477 9.94531C12.4619 10.0596 12.6465 10.1299 12.8223 10.0684C13.6221 9.83105 14.3252 9.78711 14.9053 10.1387L14.6064 10.9297C14.4658 11.3076 14.5361 11.5801 14.7734 11.8262L15.6875 12.7314C15.8896 12.9424 16.1006 12.9951 16.3994 12.9336L17.0234 12.8105L17.3311 13.1182L17.2871 13.5752C17.2607 13.7686 17.2871 13.9004 17.4629 14.0586L17.7705 14.3486ZM10.2119 18.0488C10.6162 18.4443 11.0732 18.418 11.4688 17.9609L15.3975 13.4785L14.0791 12.1689L9.58789 16.0713C9.13086 16.4756 9.10449 16.9326 9.5 17.3281L10.2119 18.0488Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_609_77"
            x="-4"
            y="0"
            width="36"
            height="36"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_609_77" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_609_77"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_609_77">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default HammerCircleIcon;
