import React, { FC } from 'react';
import AccordionUI from '@app/components/ui/Accordion/Accordion';
import AccordionItemUI from '@app/components/ui/Accordion/AccordionItem';
import { PresetsGroupsType } from '@app/interfaces/slices-types/presets-slice.type';
import { AccordionItemUIPropsClickChildParams } from '@app/interfaces/userInterface';
import { Filter, Range } from '@app/components/ui/easy-filter/types';
type AddFromTemplateModalType = {
  groups: PresetsGroupsType[];
  addFilterFromTemplateHandler?(rules: Filter & Range): void;
};
export const AddFromTemplateModal: FC<AddFromTemplateModalType> = (props) => {
  const { groups, addFilterFromTemplateHandler } = props;
  const [activeAccordionItem, setActiveAccordionItem] = React.useState<string | undefined>(
    undefined,
  );
  const clickAccordionHeaderBtn = (event: AccordionItemUIPropsClickChildParams) => {
    if (event.id === activeAccordionItem) {
      if (event.id === '') {
        setActiveAccordionItem(undefined);
      } else {
        setActiveAccordionItem('');
      }
    } else {
      setActiveAccordionItem(event.id);
    }
  };
  return (
    <div className="w-full">
      <AccordionUI>
        {groups.map((item) => {
          return (
            <AccordionItemUI
              key={item.preset_group_id}
              id={item.preset_group_id}
              title={item.name}
              clickBtnChild={clickAccordionHeaderBtn}
              active={activeAccordionItem === item.preset_group_id}
            >
              {activeAccordionItem === item.preset_group_id && (
                <ul className="flex w-full rounded-[12px] flex-col border-[1px] border-basic_app_bg">
                  {item.presets.map((item) => {
                    return (
                      <li
                        className="w-full items-center flex justify-between border-b-[1px] border-basic_app_bg text-1color p-4 text-[16px]"
                        key={item.preset_id}
                      >
                        <div
                          onClick={() =>
                            addFilterFromTemplateHandler?.(item.rules as Filter & Range)
                          }
                          className="cursor-pointer hover:text-4color"
                        >
                          {item.name}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </AccordionItemUI>
          );
        })}
      </AccordionUI>
    </div>
  );
};
