import React, { FC } from 'react';

import { IconType } from '../icon.type';

const CheckBubbleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_609_6)">
          <path
            fill="currentColor"
            d="M9.808 23.445c.492 0 .86-.237 1.459-.764l3.014-2.672H19.6c2.645 0 4.13-1.512 4.13-4.122v-6.82c0-2.611-1.485-4.123-4.13-4.123H8.393c-2.646 0-4.131 1.512-4.131 4.122v6.82c0 2.611 1.52 4.123 4.069 4.123h.36v2.17c0 .783.413 1.266 1.117 1.266zm.43-1.933v-2.505c0-.519-.22-.72-.72-.72H8.437c-1.662 0-2.453-.836-2.453-2.453V9.119c0-1.617.791-2.452 2.453-2.452h11.109c1.661 0 2.46.835 2.46 2.452v6.715c0 1.617-.799 2.452-2.46 2.452h-5.361c-.528 0-.791.088-1.16.475l-2.787 2.75zm2.822-4.86a.89.89 0 00.773-.44l3.929-6.144c.114-.184.22-.395.22-.589 0-.439-.387-.729-.8-.729-.264 0-.501.15-.686.44l-3.472 5.572-1.634-2.083c-.211-.273-.422-.37-.686-.37a.769.769 0 00-.765.783c0 .21.08.404.229.589l2.074 2.54c.237.299.492.43.818.43z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_609_6">
            <path fill="#fff" d="M0 0H28V28H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CheckBubbleIcon;
