import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { ProjectItem } from '@app/interfaces/analytics';
import { NotFoundedPage } from '@app/pages';
import { Loading } from '@app/components/ui';

import { useGetSingleAnalyticProjectQuery } from '@app/store/api/analytics-projects.api';

import AnalyticsPageHeader from './blocks/analytics-page-header';

export type AnalyticPageOutlet = {
  project?: ProjectItem | null;
  loading?: boolean;
  currentTemplate: 'chat' | 'voice';
};

const SingleAnalyticPage = React.memo(() => {
  const { id } = useParams();
  //api
  const {
    data: analyticProject,
    isError,
    isLoading,
  } = useGetSingleAnalyticProjectQuery({ id: id || '' }, { pollingInterval: 30000 });
  //api

  const analyticPageOutlet: AnalyticPageOutlet = {
    project: analyticProject as ProjectItem,
    loading: isLoading,
    currentTemplate: 'voice',
  };

  if (isError) return <NotFoundedPage />;
  if (isLoading) return <Loading />;
  return (
    <div className="pb-[35px]">
      <AnalyticsPageHeader project={analyticProject} />
      <Outlet context={analyticPageOutlet} />
    </div>
  );
});
SingleAnalyticPage.displayName = 'SingleAnalyticPage';
export default SingleAnalyticPage;
