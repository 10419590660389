module.exports = {
  title: 'Авторизация',
  button: {
    login: 'Войти',
  },
  notification: {
    success: 'Авторизация прошла успешно',
  },
  input: {
    login: 'Логин',
    password: 'Пароль',
    placeholder: {
      login: 'Укажите email',
      password: 'Укажите пароль',
    },
    errors: {
      empty_email: 'Поле email не заполнено',
      wrong_email: 'Неверный Логин/Пароль',
      wrong_password: 'Обратитесь к администратору вашей организации',
      empty_password: 'Пароль не указан',
      length_password: 'Пароль должен быть больше 6 символов',
    },
  },
};
