import React from 'react';
import { Skeleton, SkeletonPageLoading } from '@ui';

const WordsCloudSkeleton = ({
  label,
  withoutPageHeader,
}: {
  label?: string;
  withoutPageHeader?: boolean;
}) => {
  return (
    <div>
      {!withoutPageHeader && <SkeletonPageLoading />}
      <div className="pb-[20px] w-full flex flex-wrap relative">
        <div className="flex w-full absolute gap-[10px]">
          <Skeleton height={80} width={'60%'} />
          <Skeleton height={560} width={'40%'} />
        </div>
        <div className="flex items-start w-full mt-[90px] gap-[10px]">
          <Skeleton
            skeletonWithLoading
            height={470}
            width={'59.5%'}
            labelSplitByComa
            label={label}
          />
          {/*<Skeleton height={470} className="mt-[-10px]" width={'40%'} />*/}
        </div>
      </div>
    </div>
  );
};

export default WordsCloudSkeleton;
