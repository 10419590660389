import React, { FC } from 'react';

import { IconType } from '../icon.type';

const IphoneHomebuttonCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_609_179)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM10.6426 17.7676C10.6426 18.5938 11.2314 19.1562 12.0928 19.1562H15.9072C16.7773 19.1562 17.3574 18.5938 17.3574 17.7676V9.54102C17.3574 8.71484 16.7773 8.15234 15.9072 8.15234H12.0928C11.2314 8.15234 10.6426 8.71484 10.6426 9.54102V17.7676ZM12.9893 9.11035C12.9893 8.97852 13.0771 8.89941 13.1914 8.89941H14.8086C14.9316 8.89941 15.0107 8.97852 15.0107 9.11035C15.0107 9.2334 14.9316 9.3125 14.8086 9.3125H13.1914C13.0771 9.3125 12.9893 9.2334 12.9893 9.11035ZM11.5918 17.3105V9.99805H16.417V17.3105H11.5918ZM14.0088 18.5938C13.7627 18.5938 13.5605 18.3916 13.5605 18.1455C13.5605 17.9082 13.7627 17.7061 14.0088 17.7061C14.2461 17.7061 14.4395 17.9082 14.4395 18.1455C14.4395 18.3916 14.2461 18.5938 14.0088 18.5938Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_609_179">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default IphoneHomebuttonCircleIcon;
