import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { AliasSettingsForCreate, AliasSettingsObj } from '@app/interfaces/settings.type';

import { baseQuery } from '../baseQuery';
export const aliasSettingsApi = createApi({
  reducerPath: 'aliasSettingsApi',
  tagTypes: ['aliasSettings'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getAliasesList: build.query<{ aliases: AliasSettingsObj[] }, void>({
      query: () => ({
        url: serverRoutes.aliasSettings,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.aliases.map(({ alias_id }) => ({
                type: 'aliasSettings' as const,
                alias_id,
              })),
              'aliasSettings',
            ]
          : ['aliasSettings'],
    }),
    addAlias: build.mutation<AliasSettingsObj, AliasSettingsForCreate>({
      query: (body) => ({
        url: serverRoutes.aliasSetting,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['aliasSettings'],
    }),
    deleteAlias: build.mutation({
      query: (body: string) => ({
        url: serverRoutes.aliasSetting,
        method: 'DELETE',
        params: { alias_id: body },
      }),
      invalidatesTags: ['aliasSettings'],
    }),
    editAlias: build.mutation<AliasSettingsObj, { body: AliasSettingsForCreate; id: string }>({
      query: ({ body, id }) => ({
        url: serverRoutes.aliasSetting,
        method: 'PUT',
        params: { alias_id: id },
        body,
      }),
      invalidatesTags: ['aliasSettings'],
    }),
    getAlias: build.query<AliasSettingsObj, string>({
      query: (body) => ({
        url: serverRoutes.aliasSetting,
        params: { alias_id: body },
      }),
    }),
  }),
});

export const {
  useGetAliasesListQuery,
  useAddAliasMutation,
  useDeleteAliasMutation,
  useLazyGetAliasQuery,
  useEditAliasMutation,
} = aliasSettingsApi;
