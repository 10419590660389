import React, { FC, useRef } from 'react';

import Button from '@app/components/ui/button/index';

import Loading from '@app/components/ui/loading/index';

import cn from 'classnames';

import useTranslation from '@app/hooks/use-translation';

import { TrashIcon, XIcon } from '../icons/icons-list';

import { ModalProps } from './Modal.type';

import {
  getModalBackgroundClassName,
  getModalWindowClassName,
  getModalWindowWrapperClassName,
  getModalHeaderClassName,
  getModalBodyClassName,
  getModalFooterClassName,
  getModalWindowInternalWrapperClassName,
  getModalWindowCloseBtnClassName,
  heightModalHeader,
  heightModalFooter,
  modalMaxHeight,
  modalMinHeight,
} from './Modal.style';

const Modal: FC<ModalProps> = (props) => {
  const {
    id,
    value = false,
    setValue,
    title,
    onClose,
    onApply,
    children,
    noTitle,
    noFooter,
    confirmButtonText,
    cancelButtonText,
    loading = false,
    size = 'lg',
    variant = 'regular',
    addFromTemplate = false,
    addFromTemplateClick,
    disabled,
  } = props;
  const modalWrapperBackgroundRef = useRef<HTMLDivElement>(null);
  const modalWindowWrapperId = id + 'WrapperWindow';
  const transition = 150;
  const { t } = useTranslation('components.easyModal');

  const closeModal = () => {
    setValue(false);
    onClose?.();
  };

  return (
    <>
      <div
        ref={modalWrapperBackgroundRef}
        className={cn(getModalBackgroundClassName(transition), value ? 'visible' : 'invisible')}
        id={id}
        onClick={(e) => (e.target as HTMLDivElement).id === id && closeModal()}
      >
        <div
          className={getModalWindowWrapperClassName()}
          id={modalWindowWrapperId}
          onClick={(e) => (e.target as HTMLDivElement).id === modalWindowWrapperId && closeModal()}
        >
          <div className={getModalWindowClassName(size)}>
            <div className={getModalWindowInternalWrapperClassName(noFooter, size)}>
              <div className={getModalWindowCloseBtnClassName()} onClick={() => closeModal()}>
                {!noTitle && <XIcon size={13} className="text-3color" />}
              </div>
              {loading && <Loading />}
              {variant === 'removal' && (
                <div className="flex justify-center pt-[50px]">
                  <div className="w-[57px] h-[57px] rounded-full flex justify-center items-center bg-basic_red/[.1]">
                    <TrashIcon size={25} className="text-basic_red" />
                  </div>
                </div>
              )}
              {!noTitle && <h2 className={getModalHeaderClassName(variant)}>{title}</h2>}
              <div className={cn('h-fit w-full overflow-y-auto')}>
                <div
                  className={cn(getModalBodyClassName(variant, size))}
                  style={{
                    maxHeight:
                      variant === 'regular'
                        ? `calc(${modalMaxHeight[size]} - ${
                            !noTitle ? heightModalHeader : '0px'
                          } - ${!noFooter ? heightModalFooter : '0px'})`
                        : 'auto',
                    minHeight:
                      variant === 'regular'
                        ? `calc(${modalMinHeight[size]} - ${
                            !noTitle ? heightModalHeader : '0px'
                          } - ${!noFooter ? heightModalFooter : '0px'})`
                        : 'auto',
                  }}
                >
                  {children}
                </div>
              </div>
              {!noFooter && (
                <div className={getModalFooterClassName(props.variant)}>
                  {variant === 'regular' ? (
                    <>
                      <div className="flex items-center w-full">
                        <div className="mr-[25px]">
                          <Button
                            label={confirmButtonText || t('confirm')}
                            onClick={() => onApply?.()}
                          />
                        </div>
                        <div>
                          <Button
                            label={cancelButtonText || t('cancel')}
                            fill="linked"
                            onClick={() => closeModal()}
                          />
                        </div>
                      </div>
                      {addFromTemplate && (
                        <div className="">
                          <Button
                            label={t('fillFromTemplate')}
                            fill="linked"
                            icon="FolderConfigIcon"
                            onClick={() => addFromTemplateClick?.()}
                          />
                        </div>
                      )}
                    </>
                  ) : variant === 'removal' ? (
                    <>
                      <Button
                        label={confirmButtonText || t('remove')}
                        variant="danger"
                        className="w-[228px] h-[44px] mb-[13px]"
                        onClick={() => onApply?.()}
                        disabled={disabled}
                      />
                      <Button
                        label={cancelButtonText || t('cancel')}
                        fill="linked"
                        className="mb-[26px]"
                        onClick={() => closeModal()}
                      />
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;

Modal.displayName = 'Modal';
