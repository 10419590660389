import { Button } from '@app/components/ui';
import useTranslation from '@app/hooks/use-translation';
import React from 'react';

const UnsubscribeFromTrigger = () => {
  const { t } = useTranslation('common.emailConfirmation');
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="bg-white p-[40px] shadow-hard rounded-[10px] w-[409px] flex flex-col gap-[30px]">
        <div className="flex flex-col gap-[15px]">
          <span className="text-0color text-[22px] font-[700] text-center">
            {t('trigger.title')}
          </span>
          <span className="text-center text-3color text-[16px]">{t('trigger.description')}</span>
        </div>
        <div className="flex self-center">
          <Button link="/" label={t('button_label')} />
        </div>
      </div>
    </div>
  );
};

export default UnsubscribeFromTrigger;
