module.exports = {
  popup_hints: {
    grid_layout_settings: 'Resize and rearrange charts',
  },
  interval_under_label: {
    from: 'from',
    to: 'to'
  },
  system: {
    none_projectMetric_info: 'There are no metrics created in this analytics project, the chart cannot be plotted',
    no_metric:'One of the chart metrics is deleted, the chart cannot be plotted',
    no_access:'No access to report and chart data.',
    no_data:'No data',
  },
  choose_category: 'Choose category',
  status: 'Status',
  empty: 'No charts in the report',
  new_chart: 'New chart',
  loading: 'Searching for charts',
  menu: 'Menu',
  barchart:'Histogram',
  horizontal_barchart:'Horizontal bars',
  columnchart:'Segmented histogram',
  piechart:'Circular',
  linechart:'Linear chart',
  yaxis_min_value: 'Vertical axis from the minimum value',
  popover: {
    add_diagram: 'Add diagram',
    gist: 'Histogram',
  },
  drop_menu: {
    intervals: 'Set the time period for all charts',
    edit: 'Edit',
    remove: 'Delete',
    download_svg:"Download SVG",
    download_csv: "Download CSV",
    download_png: "Download PNG",
    to_report: 'Go to report',
    toolbar: 'Toolbar',
  },
  order_by_options: {
    asc: 'Ascending',
    desc: 'Descending',
    none: 'None'
  },
  legend_position_options: {
    bottom: 'Underneath',
    left: 'Left',
    right: 'Right'
  },
  value_positions_options: {
    inside: 'Inside',
    outside: 'Outside'
  },
  empty_charts: 'The report has no graphs yet',
  remove_chart_title: 'Delete the chart',
  remove_chart_body: 'Do you really want to delete?',
  select: {
    Date_placeholder: 'Select date',
    day: '1 day',
    weekdays: '7 days',
    monthDays: '30 days',
    today: 'Today',
    week: 'Current week',
    month: 'Current month',
    all_data: 'All data',
    custom_Date: 'Range',
    aggregate_choices: 'Choose',
    metric_choices: 'Choose',
    color_choices: 'Change',
  },
  labels: {
    customizing_data_to_display_on_a_histogram_y1:
      'Setting the data to be displayed on the histogram (vertical axis Y1))',
    customizing_data_to_display_on_a_histogram_y2:
      'Setting the data to be displayed on the histogram (vertical Y2 axis)',
    additional_vertical_axis: 'Additional vertical axis',
    metric_axis: 'Metric selection',
    metric_axis_y2: 'Metric selection (axis Y2)',
    aggregate_data: 'Aggregation of data',
    label_color_col: 'Color',
    label_color_line: 'Line color',
  },
  button: {
    remove_chart: 'Delete the chart',
    color_edit: 'Change',
  },
  interval_error: 'Interval is not specified or incorrect interval',
  modal_remove_chart_title: 'Confirm delete',
  modal_interval_title: 'Set period for all charts',
  modal_interval_success_button: 'Apply',
  modal_interval_before: 'from',
  modal_interval_after: 'to',
  modal_interval_select_placeholder: 'Period: Specify period',
  modal_interval_or: 'or',
  aggregate: {
    mid: 'average',
    sum: 'sum',
    count: 'amount',
    min: 'min Value',
    max: 'max Value',
    prop_sum: 'share of column sum',
    prop_num: 'share',
  },
  Date_select: {
    h: 'by hours',
    d: 'by days',
    w: 'by weeks',
    m: 'by months',
  },
  add_chart: 'Add chart',
  settings: 'General settings',
  axisSettings: 'Axis settings',
  additionalSettings: 'Additional settings',
  chart_name: 'Chart name',
  not_filled: 'Field not filled in',
  diagram_type: 'Diagram type',
  default: 'Select the default period',
  display_values: 'Display values in the diagram',
  display_legend: 'Display legend',
  nullable_values: 'Display null values',
  order_by: 'Sort by',
  empty_values: 'Display values “empty”',
  max_group_display: 'Number of value groups on the chart',
  data_hor_x: 'Selecting data for grouping (setting the horizontal X axis)',
  data_x: 'Data for grouping (X-axis)',
  line_settings: 'Norm line configuration',
  line_settings_color: 'Color',
  line_settings_value: 'Value',
  styles: 'Additional style settings',
  styles_header_size: 'Font size of the diagram title',
  styles_text_size: "Font size of values",
  styles_text_color: "Font color of values",
  styles_value_position: "Location of values",
  styles_grid: "Display of guides",
  styles_legend_position: "Location of legend",
  data_settings: 'Customizing the data to be displayed on the chart',
  conditional_formatting_add: 'Add conditional formatting settings',
  conditional_formatting: 'Conditional formatting',
  conditional_formatting_axis: 'Axis',
  conditional_formatting_value: 'Range',
  conditional_formatting_from: 'from',
  conditional_formatting_before: 'to',
  choose: 'Choose',
  Date_call: 'Call Date and time',
  metric: 'Metric',
  analytic_project: 'Analytical project',
  ap: 'AP',
  palettes:'Color sets',
  palette:'Color set',
  chart_toolbar:{
    download: 'Download',
    selection: 'Select',
    selectionZoom: 'Zoom in on selected',
    zoomIn: 'Zoom in ',
    zoomOut: 'Zoom out',
    pan: 'Move',
    reset: 'Reset settings',
  }
};
