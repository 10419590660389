import React from 'react';
import {
  activeClassName,
  chartMessageIconClassName,
} from '@app/components/audio-build/audio-chat/audio-chat.types';
import classNames from 'classnames';
import { Skeleton } from '@ui';

const AudioChatLoadingItem = ({ count }: { count: number }) => {
  const renderChatSkeleton = (key: number) => (
    <li key={key} className="mb-2 ml-6 hover:rounded-[6px]">
      <span className={chartMessageIconClassName('bg-gray', false)}>
        <Skeleton height={32} width={32} className="rounded-full bg-primary_copy" />
        <div className="absolute text-[11px] font-normal text-3color left-[-50px]">
          <Skeleton width={40} height={11} className="bg-3color" />
        </div>
      </span>
      <div className={activeClassName({ active: false })}>
        <div className="mt-[-5px]">
          <h3 className="flex items-center text-[15px] font-bold text-gray-900">
            <Skeleton width={100} height={15} className="bg-3color mb-[10px]" />
          </h3>
          <div className={classNames('mb-4', 'text-base', 'text-sm', 'font-[500]', 'text-0color')}>
            <Skeleton width="100%" height={100} className="bg-3color" />
          </div>
        </div>
      </div>
    </li>
  );

  return (
    <>
      {Array(count)
        .fill('_')
        .map((_, index) => renderChatSkeleton(index))}
      ;
    </>
  );
};

export default AudioChatLoadingItem;
