import React, { FC } from 'react';
import { useOutletContext } from 'react-router-dom';

import ChartListBlock from '@app/pages/reports/blocks/chart-list.block';

import { ReportOutletContext } from './report.outlet';

const ReportChartPage: FC = () => {
  const { liveReport } = useOutletContext<ReportOutletContext>();

  return (
    <div>
      <ChartListBlock live_report_id={liveReport?.live_report_id || ''} />
    </div>
  );
};

export default ReportChartPage;
