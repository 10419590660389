import React, { FC } from 'react';
import { NavigationTabs } from '@ui';
import { NavigationTabsItem } from '@app/components/ui/navigation-tabs/navigation-tabs';
import useTranslation from '@app/hooks/use-translation';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { LiveReport } from '@app/interfaces/report.type';

const ReportNavigatorBlock: FC<{
  changeIsOpenChartModal: (status: boolean) => void;
  liveReport: LiveReport | undefined;
  currentTemplate: 'chat' | 'voice';
}> = ({ changeIsOpenChartModal, liveReport, currentTemplate }) => {
  const { t } = useTranslation('pages.reports.navigation');
  const navigationIndexes = {
    chart: 'chart',
    settings: 'settings',
    records: 'records',
  };

  const rootPathByTemplate = {
    chat: navigationRoutes.chatReports,
    voice: navigationRoutes.reports,
  };
  const navigatorData: Array<NavigationTabsItem> = [
    {
      label: t('charts'),
      icon: 'ChartIcon',
      index: navigationIndexes.chart,
      link: `/${rootPathByTemplate[currentTemplate || 'voice']}/${liveReport?.live_report_id}`,
      dataId: 'reports-section-charts',
    },
    {
      icon: 'SettingIcon',
      label: t('settings'),
      index: navigationIndexes.settings,
      link: `/${rootPathByTemplate[currentTemplate || 'voice']}/${liveReport?.live_report_id}/${
        navigationRoutes.settings
      }`,
      dataId: 'reports-section-settings',
    },
    {
      icon: 'TableIcon',
      label: t('records'),
      index: navigationIndexes.records,
      link: `/${rootPathByTemplate[currentTemplate || 'voice']}/${liveReport?.live_report_id}/${
        navigationRoutes.reportRecords
      }`,
      dataId: 'reports-section-records',
    },
  ];

  function handlerOnChange(clickedTab: NavigationTabsItem) {
    clickedTab.index === navigationIndexes.settings && changeIsOpenChartModal(true);
  }

  return <NavigationTabs onChange={handlerOnChange} items={navigatorData} />;
};

export default ReportNavigatorBlock;
