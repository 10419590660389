import React, { FC } from 'react';

import { IconType } from '../icon.type';

const FlagCrossedIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_605_1158)">
          <path
            fill="currentColor"
            d="M17.78 20.169L10.52 7.598c-.307-.534-.751-.698-1.264-.65-.424.055-.978.267-1.504.574-1.675.971-2.345 2.496-3.951 3.418-.697.404-1.32.561-1.627.739-.41.239-.595.656-.349 1.08l2.748 4.758c.308.533.752.69 1.265.642.424-.04.977-.266 1.504-.574 1.675-.97 2.345-2.488 3.944-3.418.492-.28.944-.438 1.237-.554l4.157 7.192a.63.63 0 00.868.239c.3-.178.41-.574.232-.875zM14.5 12.752l.738 1.278.233-.417c.3.117.745.274 1.237.554 1.6.93 2.27 2.447 3.951 3.418.52.308 1.073.533 1.497.574.513.048.964-.11 1.272-.642l2.741-4.758c.246-.424.068-.841-.342-1.08-.314-.178-.936-.335-1.634-.739-1.6-.922-2.27-2.447-3.95-3.418-.52-.307-1.074-.52-1.505-.574-.512-.048-.95.116-1.264.65l-2.974 5.154zm-2.639-.28c-.39.123-.861.39-1.21.588-1.79 1.032-2.392 2.529-3.958 3.424-.157.096-.601.342-1.025.37l-2.434-4.218c.397-.116.862-.383 1.21-.581 1.791-1.032 2.393-2.523 3.952-3.425.164-.096.608-.349 1.032-.376l2.433 4.218zm4.273 0l2.433-4.218c.424.027.869.28 1.033.376 1.565.902 2.167 2.393 3.958 3.425.341.198.813.465 1.21.58l-2.44 4.219c-.418-.028-.862-.274-1.026-.37-1.566-.895-2.167-2.392-3.958-3.424-.342-.199-.814-.465-1.21-.588zm-2.632 4.56l-.738-1.28-2.55 4.417c-.171.3-.068.697.24.875.307.178.69.068.867-.24l2.181-3.773z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_605_1158">
            <path fill="#fff" d="M0 0H28V28H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default FlagCrossedIcon;
