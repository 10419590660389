export const WAVE_COMPONENT_CONFIG = {
  regionOpacity: '40',
  regionPrefix: 'region',
  defaultRegionColor: 'rgb(197,241,218, 0.3)',
  currentTimingMs: 1000,
  initialTimeFormat: '0:00',
  zoomLimit: [0, 200],
  channelsLimit: 2,
  regionMinimalSpaceTime: 0,
  defaultRecordName: 'undefined',
};
