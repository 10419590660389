import React, { FC } from 'react';
import { EditText, PageHeader, PermissionAccessWrapper, SharedStatus, TooltipWrapper } from '@ui';
import { StatusLabel } from '@app/components';
import { navigationRoutes } from '@app/utils/navigation-routes';
import useTranslation from '@app/hooks/use-translation';
import { StatusVariant } from '@app/components/status-label/status-label';
import { reportStatusPropertiesData } from '@app/pages/reports/data/report-status-properties.data';
import { GroupSharedStatus } from '@app/interfaces/analytics';
import { useLocation, useNavigate } from 'react-router';
import {
  useEditLiveReportMutation,
  useEditLiveReportStatusMutation,
} from '@app/store/api/live-reports.api';
import { usePermissionCheck } from '@app/hooks/use-permission';
import { userPermissions } from '@app/utils/user-permissions';

import { LiveReport } from '@app/interfaces/report.type';
import { useEditChatLiveReportMutation } from '@app/store/api/chat-api/live-reports.api';

const ReportHeaderWithStatusBlock: FC<{
  liveReport?: LiveReport;
  currentTemplate: 'chat' | 'voice';
}> = ({ liveReport, currentTemplate }) => {
  const status: StatusVariant = 'LIFE';
  const navigate = useNavigate();
  const history = useLocation();
  const { t } = useTranslation('pages.chartPage');

  const [editLiveReportChat] = useEditChatLiveReportMutation();
  const [editLiveReportVoice] = useEditLiveReportMutation();

  const editLiveReport = { chat: editLiveReportChat, voice: editLiveReportVoice };

  const [editLiveReportStatus] = useEditLiveReportStatusMutation();
  const reportCanBeEdited = usePermissionCheck({
    tag: userPermissions.action.actionEditLiveReport,
    permissions: 'action',
  });
  function handleChangeStatus(new_status) {
    if (!liveReport) return;
    editLiveReportStatus({ id: liveReport?.live_report_id, new_status });
  }

  function handleChangeReportName(name: string) {
    if (!liveReport) return;
    editLiveReport[currentTemplate]({
      params: { id: liveReport?.live_report_id },
      body: { name, filter: liveReport?.filter },
    });
  }

  return (
    <PageHeader
      label={
        <div className="flex items-center gap-[20px]">
          <EditText
            disableEdit={!!reportCanBeEdited}
            editIconClassNames="mt-[3px]"
            defaultOpen={history.state?.['filter'] ? false : history.state !== null}
            showEdit={
              !liveReport?.project?.owner && liveReport?.project?.shared === GroupSharedStatus.VIEW
            }
            textStyle="font-[700] text-[24px] text-1color"
            text={liveReport?.name || ''}
            onEdited={handleChangeReportName}
          />
          <PermissionAccessWrapper
            permissions={'display'}
            tag={userPermissions.display.displayRepStatusCol}
          >
            <div className="flex items-end gap-[10px]">
              <StatusLabel
                status={status as StatusVariant}
                onChangeStatus={handleChangeStatus}
                statusProperties={reportStatusPropertiesData}
              />
              <SharedStatus status={liveReport?.project?.shared || ''} />
            </div>
          </PermissionAccessWrapper>
        </div>
      }
      popLink={-1}
      // afterAction={handleEditReportName}
    >
      <div className="flex items-center font-[500] text-[14px] leading-[17px] text-1color">
        <TooltipWrapper content={t('analytic_project')} id={'AP_project'}>
          <div className="cursor-default">{t('ap')}:</div>
        </TooltipWrapper>
        <div
          onClick={() =>
            navigate(`/${navigationRoutes.reports}?project=${liveReport?.project.project_id}`)
          }
          className="cursor-pointer"
        >
          &nbsp;{liveReport?.project.project_name}
        </div>
      </div>
    </PageHeader>
  );
};

export default ReportHeaderWithStatusBlock;
