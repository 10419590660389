import React, { ChangeEvent, forwardRef, useState } from 'react';
import cn from 'classnames';
type TextAreaProps = {
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
  error?: string;
  placeholder?: string;
  height?: number;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
};
const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, forwardedRef) => {
  const {
    label,
    placeholder = '',
    onChange,
    defaultValue,
    error,
    disabled = false,
    height = 200,
    ...rest
  } = props;
  const [textareaValue, setTextAreaValue] = useState<string>(defaultValue || '');
  const textAreaClassName = cn(
    'border-[1px]',
    disabled ? 'text-2color bg-b_light' : 'text-0color',
    error ? 'border-basic_red' : 'border-b_dark',
    'text-sm',
    'py-[15px]',
    'px-[10px]',
    'relative',
    'bg-white',
    'rounded-defaultR',
    'flex',
    'items-center',
    'block',
    'w-full',
    'min-h-[84px]',
  );
  function handleChangeTextAreaValue(event: ChangeEvent<HTMLTextAreaElement>) {
    setTextAreaValue(event.target.value);
    onChange?.(event);
  }
  return (
    <div>
      {label && <label className="block ml-[6px] mb-[2px] text-sm font-[500] ">{label}</label>}
      <div>
        <textarea
          ref={forwardedRef}
          defaultValue={defaultValue}
          name={label}
          value={textareaValue}
          placeholder={placeholder}
          onChange={handleChangeTextAreaValue}
          className={textAreaClassName}
          style={{ minHeight: `${height}px` }}
          {...rest}
        ></textarea>
      </div>
      {!!error && true && (
        <div className="mt-[1px] whitespace-nowrap text-sm text-basic_red">{error}</div>
      )}
    </div>
  );
});

TextArea.displayName = 'TextArea';

export default TextArea;
