import { IconNameType } from '@app/components/ui/icons/icon-name.type';
export const pictogramsData: { [id: string]: IconNameType } = {
  '1': 'BellBadgeCircleIcon',
  '2': 'BellSquareIcon',
  '3': 'BoltSquareIcon',
  '4': 'CheckBubbleIcon',
  '5': 'EllipsisVerticalBubbleIcon',
  '6': 'EyeSlashCircleIcon',
  '7': 'EyeSquareIcon',
  '8': 'EyeTrianglebadgeTxclamationmarkFillIcon',
  '9': 'FlagCrossedIcon',
  '10': 'FlagSquareIcon',
  '11': 'HeartSquareIcon',
  '12': 'LocationSquareIcon',
  '13': 'RectanglesGroupBubbleLeftIcon',
  '14': 'StartBubbleIcon',
  '15': 'TagSquareIcon',
  '16': 'BubbleLeftAndExclamationMarkBubbleRightIcon',
  '17': 'GearCircleIcon',
  '18': 'HammerCircleIcon',
  '19': 'BriefCaseCircleIcon',
  '20': 'TheatermasksCircleIcon',
  '21': 'AirplaneDepartureIcon',
  '22': 'ArrowBackwardSquare',
  '23': 'ArrowDownAppIcon',
  '24': 'ArrowForwardSquareIcon',
  '25': 'ArrowUpForwardAppIcon',
  '26': 'ArrowUpSquareIcon',
  '27': 'ArrowUturnLeftCircleIcon',
  '28': 'ArrowshapeTurnUpLeftCircleIcon',
  '29': 'ArrowshapeTurnUpRightCircleIcon',
  '30': 'BeatsHeadphonesIcon',
  '31': 'BedDoubleCircleIcon',
  '32': 'BoltRingClosedIcon',
  '33': 'BooksVerticalCircleIcon',
  '34': 'BubbleRightCircleIcon',
  '35': 'BuildingColumnsCircleIcon',
  '36': 'CartCircleIcon',
  '37': 'ChartLineUptrendXyaxisCircleIcon',
  '38': 'ChartXyaxisLineIcon',
  '39': 'ChecklistIcon',
  '40': 'CheckmarkBubbleIcon',
  '41': 'ClockBadgeCheckmarkIcon',
  '42': 'CreditcardCircleIcon',
  '43': 'CreditcardTrianglebadgeExclamationmarkIcon',
  '44': 'CupAndSaucericon',
  '45': 'DesktopcomputerAndArrowDownIcon',
  '46': 'DollarsignCircleIcon',
  '47': 'EllipsisCurlybracesIcon',
  '48': 'Flag2CrossedIcon',
  '49': 'FlameCircleIcon',
  '50': 'ForkKnifeCircleIcon',
  '51': 'GiftcardIcon',
  '52': 'HandRaisedCircleIcon',
  '53': 'HandThumbsdownCircleIcon',
  '54': 'HandThumbsupCircleIcon',
  '55': 'HourglassBottomhalfFilledIcon',
  '56': 'IphoneHomebuttonCircleIcon',
  '57': 'IphoneHomebuttonSlashCircleIcon',
  '58': 'LeafCircleIcon',
  '59': 'LightbulbCircleIcon',
  '60': 'ListBulletCircleIcon',
  '61': 'LockDisplayIcon',
  '62': 'MinusCircleIcon',
  '63': 'ParkingsignCircleIcon',
  '64': 'PawprintCircleIcon',
  '65': 'PersonalhotspotCircleIcon',
  '66': 'PinSquareIcon',
  '67': 'PlusCircleIcon',
  '68': 'QuestionmarkAppIcon',
  '69': 'Rectangle2SwapIcon',
  '70': 'RectangleAndHandPointUpLeftIcon',
  '71': 'RublesignCircleIcon',
  '72': 'ShieldLefthalfFilledIcon',
  '73': 'SignatureIcon',
  '74': 'SnowflakeCircleIcon',
  '75': 'StarBubbleIcon',
  '76': 'StethoscopeCircleIcon',
  '77': 'TextViewfinderIcon',
  '78': 'WaveformAndMagnifyingglassIcon',
  '79': 'WaveformBadgeExclamationmarkIcon',
  '80': 'WaveformBadgeMinusIcon',
  '81': 'WaveformBadgePlusIcon',
  '82': 'XmarkAppIcon',
  '83': 'YensignCircleIcon',
};
