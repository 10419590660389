import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ArrowUpForwardAppIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_614_2256)">
          <path
            d="M21.1191 6.5791C20.2139 5.67383 18.9658 5.49805 17.3662 5.49805H10.5986C9.02539 5.49805 7.76855 5.68262 6.87207 6.5791C5.9668 7.48438 5.7998 8.72363 5.7998 10.3057V17.0117C5.7998 18.6113 5.9668 19.8506 6.86328 20.7559C7.77734 21.6523 9.02539 21.8281 10.625 21.8281H17.3662C18.9658 21.8281 20.2139 21.6523 21.1191 20.7559C22.0244 19.8506 22.1914 18.6113 22.1914 17.0117V10.3232C22.1914 8.72363 22.0244 7.47559 21.1191 6.5791ZM20.4688 10.0859V17.249C20.4688 18.1631 20.3457 19.0068 19.8623 19.4902C19.3701 19.9824 18.5176 20.1055 17.6123 20.1055H10.3789C9.47363 20.1055 8.62109 19.9824 8.12891 19.4902C7.63672 19.0068 7.52246 18.1631 7.52246 17.249V10.1035C7.52246 9.17188 7.63672 8.32812 8.12012 7.83594C8.6123 7.34375 9.47363 7.2207 10.3965 7.2207H17.6123C18.5176 7.2207 19.3701 7.35254 19.8623 7.83594C20.3545 8.32812 20.4688 9.17188 20.4688 10.0859ZM16.7422 15.9395C17.1729 15.9395 17.4629 15.6143 17.4629 15.1484V11.0615C17.4629 10.4551 17.1201 10.1914 16.5752 10.1914H12.4619C11.9961 10.1914 11.6885 10.4727 11.6885 10.9033C11.6885 11.3428 11.9961 11.624 12.4707 11.624H13.9561L15.1602 11.4922L13.8594 12.6787L10.7744 15.7637C10.625 15.9131 10.5283 16.1152 10.5283 16.3262C10.5283 16.792 10.8535 17.0908 11.293 17.0908C11.5391 17.0908 11.7236 17.0117 11.8906 16.8535L14.958 13.7773L16.1445 12.4854L16.0127 13.751V15.1572C16.0127 15.6406 16.3027 15.9395 16.7422 15.9395Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_614_2256">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ArrowUpForwardAppIcon;
