import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { BreadcrumbsItem } from '@app/layouts/layout';
import RequiredAuth from '@app/routes/required-auth.route';
import { AllChatsPage } from '@app/chat-pages';
import { SingleRecordPage } from '@app/pages';

export const allChatsRoute = (
  <Route
    path={navigationRoutes.allChats}
    handle={{
      crumb: () => <BreadcrumbsItem path={navigationRoutes.records} crumb="all_chats" />,
    }}
  >
    <Route
      index
      element={
        <RequiredAuth>
          <AllChatsPage />
        </RequiredAuth>
      }
    />
    <Route
      path={`:id`}
      element={
        <RequiredAuth>
          <SingleRecordPage currentTemplate="chat" />
        </RequiredAuth>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={navigationRoutes.records} crumb="recordsId" />,
      }}
    />
  </Route>
);
