import { Box, Button, Input, Select } from '@app/components/ui';
import React, { FC, useEffect } from 'react';

import useTranslation from '@app/hooks/use-translation';

import { useForm } from 'react-hook-form';

import { AllChanelsArrType } from '@app/interfaces/pages-types/anatylics-metric.type';

import { DictionariesType } from '@app/interfaces/slices-types/dictionaries-slice.type';

import { WordCloudFormType, WordCloudType } from '@app/interfaces/words-cloud.type';

import { NavLink } from 'react-router-dom';
import { navigationRoutes } from '@app/utils/navigation-routes';

import { CloudIcon, MenuSmallIcon } from '@app/components/ui/icons/icons-list';

import { WordsListComponent } from '@app/components/ui/add-words-and-dictionaries-container/wordsListComponent';

import { occurrenceData, part_of_speech_data } from '../data/selects.data';

type WordsCloudFilterPropsType = {
  channels: AllChanelsArrType[] | undefined;
  dictionaries: DictionariesType[] | undefined;
  onFormSubmit?: (data: Omit<WordCloudType, 'project_id'>) => void;
  disableSaveButton?: boolean;
  wordForSearch: string[] | undefined;
};
export const WordsCloudFilter: FC<WordsCloudFilterPropsType> = (props) => {
  const { channels, dictionaries, onFormSubmit, disableSaveButton, wordForSearch } = props;
  const { t } = useTranslation('pages.wordsCloud');
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<WordCloudFormType>({
    defaultValues: {
      limit: 100,
      frequency: Number(getOccurrenceOptions()[0].value),
      channel: ['all'],
    },
  });
  function onSubmit(data: WordCloudFormType) {
    const objectDataOfCloudForm: Omit<WordCloudType, 'project_id'> = {
      channel: data?.channel?.length
        ? data.channel.includes('all')
          ? getChannelsOptions().map((item) => item.value)
          : data.channel
        : undefined,
      frequency: data?.frequency,
      limit: data?.limit,
      permitted_part_of_speech: data?.permitted_part_of_speech?.length
        ? data?.permitted_part_of_speech
        : undefined,
      stop_words_id: data?.stop_words_id?.[0] as string,
      words: data?.words,
    };
    onFormSubmit?.(objectDataOfCloudForm);
  }
  function getPartOfSpeechData() {
    return part_of_speech_data.map((item) => ({
      ...item,
      title: t(`part_of_speech_data.${item.title}`),
    }));
  }
  function getOccurrenceOptions() {
    return occurrenceData.map((item) => ({ ...item, title: t(item.title) + ` -> ${item.value}` }));
  }
  function getChannelsOptions() {
    return (
      (channels &&
        channels?.map((item) => ({ title: item.name, value: item.settings_channels_id }))) ||
      []
    );
  }
  function getDictionariesOptions() {
    return (
      dictionaries && dictionaries?.map((item) => ({ title: item.name, value: item.dictionary_id }))
    );
  }
  function changeDictionariesArray(word: string[]) {
    setValue('stop_words_id', word);
  }
  function changeWordsArray(word: string[]) {
    setValue('words', word);
  }
  useEffect(() => {
    if (!wordForSearch?.length) return;
    setValue('words', wordForSearch);
  }, [setValue, wordForSearch]);

  return (
    <div>
      <Box className="px-[30px] py-[20px] w-[300px] h-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-start w-full gap-[25px]">
            <div className="flex flex-col items-center gap-[25px] w-full">
              <div className="flex items-center">
                <div className="flex relative items-center gap-[10px]">
                  <span className="flex items-center text-2color text-[15px] leading-[18px] font-[400]">
                    {t('cloud_deep')}
                  </span>
                  <div>
                    <Input
                      {...register('limit', {
                        setValueAs: (value) => (value ? Number(value) : undefined),
                        max: { message: 'max is 100', value: 100 },
                      })}
                      name={'limit'}
                    />
                    <span className="mt-[2px] text-basic_red text-[12px] absolute right-0 top-[28px]">
                      {errors.limit?.message}
                    </span>
                  </div>
                </div>
                <NavLink
                  end
                  to={`/${navigationRoutes.wordsCloud}/table`}
                  className={(active) =>
                    `${
                      active.isActive && 'bg-action/[0.1]'
                    } mx-[13px] hover:bg-action/[0.1] p-[3.5px]`
                  }
                >
                  {({ isActive }) => (
                    <MenuSmallIcon
                      className={`${isActive && '!text-action'} text-3color hover:text-action`}
                      size={21}
                    />
                  )}
                </NavLink>
                <NavLink
                  end
                  to={`/${navigationRoutes.wordsCloud}`}
                  className={(active) =>
                    `${active.isActive && 'bg-action/[0.1]'} hover:bg-action/[0.1] p-[3.5px]`
                  }
                >
                  {({ isActive }) => (
                    <CloudIcon
                      className={`${isActive && '!text-action'} text-3color hover:text-action`}
                      size={21}
                    />
                  )}
                </NavLink>
              </div>
              <div className="w-[240px]">
                <Select
                  placeholder={t('channel_select_placeholder')}
                  dropWidth={292}
                  options={[
                    { title: t('all_channels_option'), value: 'all' },
                    ...getChannelsOptions(),
                  ]}
                  onChangeMultiple={(value) =>
                    value.includes('all')
                      ? watch('channel')?.includes('all')
                        ? setValue(
                            'channel',
                            (value as string[]).filter((item) => item !== 'all'),
                          )
                        : setValue('channel', ['all'])
                      : setValue('channel', value as string[])
                  }
                  isMulti
                  defaultValue={watch().channel}
                />
              </div>
              <div className="w-[240px]">
                <Select
                  isMulti
                  placeholder={t('permitted_part_of_speech_select_placeholder')}
                  options={getPartOfSpeechData()}
                  onChangeMultiple={(value) =>
                    setValue('permitted_part_of_speech', value as string[])
                  }
                  dropWidth={220}
                />
              </div>
              <div className="w-[240px]">
                <Select
                  placeholder={t('frequency_select_placeholder')}
                  dropWidth={290}
                  options={getOccurrenceOptions()}
                  onChange={(value) => setValue('frequency', Number(value))}
                  defaultValue={String(watch().frequency)}
                />
              </div>
              <div className="w-[240px]">
                <Select
                  onChangeMultiple={(value) => {
                    const formattedValue = value.length ? [value[value.length - 1]] : value;
                    setValue('stop_words_id', formattedValue as string[]);
                  }}
                  placeholder={t('stop_words_select_placeholder')}
                  dropWidth={290}
                  options={getDictionariesOptions() || []}
                  isMulti
                  defaultValue={watch('stop_words_id')}
                />
              </div>
              <div className={'w-full'}>
                <WordsListComponent
                  variant={'wordsCloud'}
                  dictionaries={dictionaries}
                  className={'!min-w-full !min-h-[200px]'}
                  wordsListArray={watch('words') || []}
                  setWordsListArray={changeWordsArray}
                  dictionariesListArray={[]}
                  setDictionariesListArray={changeDictionariesArray}
                />
              </div>
              <div className="">
                <Button
                  disabled={!disableSaveButton}
                  type="submit"
                  className="!font-[400] !leading-[18px] !px-[13.5px] w-[240px]"
                  label={t('apply_button')}
                  isFull
                />
              </div>
            </div>
          </div>
        </form>
      </Box>
    </div>
  );
};
