import React, { FC } from 'react';

import { IconNameType } from './icon-name.type';
import * as svgIcons from './icons-list';
import * as chartIcons from './charts';
import * as pictograms from './pictograms';

const icons = Object({ ...svgIcons, ...chartIcons, ...pictograms });

type IconProps = {
  name: IconNameType;
  size?: number;
  className?: string;
  onClick?: () => void;
};

const Icon: FC<IconProps> = (props) => {
  const { name, size = 16, className, onClick, ...remainingProps } = props;
  const iconObject = Object(icons);
  const Elem = iconObject[name];
  if (!name) return <></>;
  const renderIcon = () => <Elem size={size} className={className} {...remainingProps} />;
  return (
    <div
      onClick={() => onClick?.()}
      className="flex justify-center items-center"
      style={{
        width: `${size}px`,
        maxHeight: `${size}px`,
        display: 'inline-flex',
        alignItems: 'center',
      }}
      {...remainingProps}
    >
      {renderIcon()}
    </div>
  );
};

export default Icon;
