import React, { FC } from 'react';

import { IconType } from '../icon.type';

const BellSquareIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_606_2)">
          <path
            d="M8.67383 21.8721H19.3174C21.2158 21.8721 22.2002 20.8877 22.2002 19.0156V8.31934C22.2002 6.43848 21.2158 5.46289 19.3174 5.46289H8.67383C6.77539 5.46289 5.79102 6.43848 5.79102 8.31934V19.0156C5.79102 20.8965 6.77539 21.8721 8.67383 21.8721ZM8.7793 20.1494C7.96191 20.1494 7.51367 19.7188 7.51367 18.8662V8.45996C7.51367 7.60742 7.96191 7.18555 8.7793 7.18555H19.2119C20.0205 7.18555 20.4775 7.60742 20.4775 8.45996V18.8662C20.4775 19.7188 20.0205 20.1494 19.2119 20.1494H8.7793ZM10.2119 16.7393H17.8057C18.2803 16.7393 18.5703 16.4844 18.5703 16.0977C18.5703 15.5967 18.1133 15.1748 17.6826 14.7529C17.3398 14.4277 17.2783 13.7422 17.2256 13.127C17.1553 11.3691 16.6455 10.1387 15.415 9.69043C15.2129 9.06641 14.7031 8.58301 14.0088 8.58301C13.3057 8.58301 12.8047 9.06641 12.6025 9.69043C11.3721 10.1387 10.8535 11.3691 10.792 13.127C10.7393 13.7422 10.6602 14.4277 10.335 14.7529C9.91309 15.1836 9.44727 15.5967 9.44727 16.0977C9.44727 16.4844 9.7373 16.7393 10.2119 16.7393ZM14.0088 18.7871C14.8438 18.7871 15.459 18.1982 15.5117 17.4863H12.5059C12.5586 18.1982 13.1738 18.7871 14.0088 18.7871Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_606_2">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default BellSquareIcon;
