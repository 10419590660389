module.exports = {
  created: 'Новый',
  life: 'LIFE',
  updating: 'Обновление',
  done: 'Готов',
  archive: 'Архив',
  pause: 'Пауза',
  deleting: 'Удаление',
  filling: 'Наполнение',
  active: 'Активен',
  stopping: 'Остановка',
  starting: 'Запуск',
  actualization: 'Актуализирован',
  removing: 'Удаляется',
  disabled: 'не активно',
  onactive: 'активно'
};
