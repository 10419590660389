import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { PresetsGroupsType } from '@app/interfaces/slices-types/presets-slice.type';

import {
  PresetsCreateDataType,
  PresetsDataType,
} from '@app/interfaces/pages-types/presets-page-types/presets-table-props.type';

import { baseQuery } from '../baseQuery';
export const presetTemplateApi = createApi({
  reducerPath: 'presetTemplate',
  tagTypes: ['presetTemplate', 'singlePreset'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getPresetTemplateGroupsList: build.query<PresetsGroupsType[], void>({
      query: () => ({
        url: serverRoutes.presetGroups,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ preset_group_id }) => ({
                type: 'presetTemplate' as const,
                preset_group_id,
              })),
              'presetTemplate',
            ]
          : ['presetTemplate'],
    }),
    createPresetTemplateGroup: build.mutation<PresetsDataType, { name: string }>({
      query: (body) => ({
        url: serverRoutes.presetGroup,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['presetTemplate'],
    }),
    deletePresetTemplateGroup: build.mutation<PresetsDataType, { id: string }>({
      query: (params) => ({
        url: serverRoutes.presetGroup,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['presetTemplate'],
    }),
    editPresetTemplateGroup: build.mutation<
      PresetsDataType,
      { params: { id: string }; body: { name: string } }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.presetGroup,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['presetTemplate'],
    }),
    //single preset
    getSinglePreset: build.query<PresetsDataType, { id: string }>({
      query: (params) => ({
        url: serverRoutes.preset,
        params,
      }),
      providesTags: ['singlePreset'],
    }),
    createSinglePreset: build.mutation<PresetsDataType, PresetsCreateDataType>({
      query: (body) => ({
        url: serverRoutes.preset,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['presetTemplate', 'singlePreset'],
    }),
    editSinglePreset: build.mutation<
      PresetsDataType,
      { params: { id: string }; body: PresetsCreateDataType }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.preset,
        body,
        params,
        method: 'PUT',
      }),
      invalidatesTags: ['presetTemplate', 'singlePreset'],
    }),
    // createSingleAnalyticProject: build.mutation<ProjectItem, CreateProjectAnalyticsParams>({
    //   query: (body) => ({
    //     url: serverRoutes.analyticsProject,
    //     method: 'POST',
    //     body,
    //   }),
    //   invalidatesTags: ['analyticsProject'],
    // }),
    // editSingleAnalyticProject: build.mutation<
    //   ProjectItem,
    //   { body: CreateProjectAnalyticsParams; params: { id: string } }
    // >({
    //   query: ({ body, params }) => ({
    //     url: serverRoutes.analyticsProject,
    //     method: 'PUT',
    //     body,
    //     params,
    //   }),
    //   invalidatesTags: ['analyticsProject'],
    // }),
    deletePresetTemplate: build.mutation({
      query: (params: { id: string }) => ({
        url: serverRoutes.preset,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['presetTemplate'],
    }),
  }),
});

export const {
  useGetPresetTemplateGroupsListQuery,
  useEditPresetTemplateGroupMutation,
  useDeletePresetTemplateGroupMutation,
  useCreatePresetTemplateGroupMutation,
  useDeletePresetTemplateMutation,
  useGetSinglePresetQuery,
  useCreateSinglePresetMutation,
  useEditSinglePresetMutation,
} = presetTemplateApi;
