import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactWordcloud, { Word } from 'react-wordcloud';

import { Box, Skeleton } from '@app/components/ui';

import { useOutletContext } from 'react-router';

import { Empty } from '@ui';

import {
  useGetChatWordsNGramStaticCloudQuery,
  useGetWordsNGramStaticCloudQuery,
} from '@app/store/api/get-words-n-gramm.api';

import useTranslation from '@app/hooks/use-translation';

import { WordCloudContext } from '../types/types';

export const WordsNGramCloudContent = () => {
  const {
    data: dataForCloud,
    cloudWasCalculated,
    currentTemplate,
  } = useOutletContext<WordCloudContext>();
  const [cloudData, changeCloudData] = useState(dataForCloud);
  const { t } = useTranslation('pages.wordsNGramm');
  const { data: dataVoice, isFetching: isFetchingVoice } = useGetWordsNGramStaticCloudQuery(
    dataForCloud,
    {
      skip: currentTemplate === 'chat' || dataForCloud === undefined,
      refetchOnMountOrArgChange: true,
    },
  );
  const { data: dataChat, isFetching: isFetchingChat } = useGetChatWordsNGramStaticCloudQuery(
    dataForCloud,
    {
      skip: currentTemplate === 'voice' || dataForCloud === undefined,
      refetchOnMountOrArgChange: true,
    },
  );
  const data = useMemo(() => ({ chat: dataChat, voice: dataVoice }), [dataChat, dataVoice]);
  const isFetching = isFetchingChat || isFetchingVoice;
  useEffect(() => {
    changeCloudData(dataForCloud);
  }, [dataForCloud]);
  const SimpleWordCloud = useCallback(() => {
    if (!data) return;
    return data[currentTemplate]?.cloud.map((item) => ({
      text: item.text,
      value: item.freq,
    })) as Word[];
  }, [currentTemplate, data]);

  if (isFetching) return <Skeleton height={550} />;
  return (
    <Box className="w-full h-full flex justify-center items-center relative">
      {cloudWasCalculated && !!SimpleWordCloud()?.length && (
        <div className="whitespace-nowrap text-3color text-[12px] absolute bottom-0 left-0 h-[40px] flex items-center pl-[1em] w-fit">
          {cloudWasCalculated}
        </div>
      )}
      <div className="h-[85%] w-[95%]">
        {SimpleWordCloud()?.length ? (
          <ReactWordcloud
            options={{
              fontSizes: [15, 46],
              rotations: 0,
              deterministic: true,
              fontFamily: 'SF Pro Display',
              randomSeed: '0.6',
              padding: 2.6,
              tooltipOptions: { theme: 'tomato' },
              spiral: 'archimedean',
              scale: 'sqrt',
            }}
            callbacks={{
              getWordTooltip: (value) => `${value.text} ${value.value}%`,
            }}
            words={SimpleWordCloud() || []}
          />
        ) : cloudData?.filter?.length ? (
          <div className={'w-full flex items-center justify-center h-full'}>
            <Empty title={t('system.cloud_is_empty_by_request')} />
          </div>
        ) : (
          <div className={'w-full flex items-center justify-center h-full'}>
            <Empty title={t('system.cloud_is_empty')} />
          </div>
        )}
      </div>
    </Box>
  );
};
