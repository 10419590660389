import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import {
  MixedRecipientsRequestType,
  MixedTenantRecipientsType,
  TenantRecipientsResponseType,
} from '@app/interfaces/tenant-recipients.type';

import { baseQuery } from '../baseQuery';

export const tenantRecipientsApi = createApi({
  reducerPath: 'tenantRecipientsApi',
  tagTypes: ['tenantRecipients'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getTenantRecipientsTgAndEmail: build.query<
      MixedTenantRecipientsType,
      MixedRecipientsRequestType
    >({
      async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
        const telegramMessages = (await fetchWithBQ({
          url: serverRoutes.telegramTenantRecipients,
          params: arg.telegram,
        })) as unknown as { data: TenantRecipientsResponseType };

        const emailMessages = (await fetchWithBQ({
          url: serverRoutes.emailTenantRecipients,
          params: arg.email,
        })) as unknown as { data: TenantRecipientsResponseType };

        const result: MixedTenantRecipientsType = {
          telegram: telegramMessages.data,
          email: emailMessages.data,
        };

        return result ? { data: result } : { error: { error: '123' } as FetchBaseQueryError };
      },
      providesTags: ['tenantRecipients'],
    }),
    deleteRecipientFromTg: build.mutation({
      query: (params: { recipient_id: string }) => ({
        url: serverRoutes.telegramTenantRecipient,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['tenantRecipients'],
    }),
    deleteRecipientFromEmail: build.mutation({
      query: (params: { recipient_id: string }) => ({
        url: serverRoutes.emailTenantRecipient,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['tenantRecipients'],
    }),
    createRecipientFromTg: build.mutation({
      query: (body: { description: string }) => ({
        url: serverRoutes.telegramTenantRecipient,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['tenantRecipients'],
    }),
    createRecipientFromEmail: build.mutation({
      query: (body: { description: string; email: string }) => ({
        url: serverRoutes.emailTenantRecipient,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['tenantRecipients'],
    }),
    editRecipientFromTg: build.mutation({
      query: (body: { description: string; recipient_id: string }) => ({
        url: serverRoutes.telegramTenantRecipient,
        method: 'PUT',
        body: { description: body.description },
        params: { recipient_id: body.recipient_id },
      }),
      invalidatesTags: ['tenantRecipients'],
    }),
    editRecipientFromEmail: build.mutation({
      query: (body: { description: string; recipient_id: string }) => ({
        url: serverRoutes.emailTenantRecipient,
        method: 'PUT',
        body: { description: body.description },
        params: { recipient_id: body.recipient_id },
      }),
      invalidatesTags: ['tenantRecipients'],
    }),
  }),
});

export const {
  useCreateRecipientFromEmailMutation,
  useCreateRecipientFromTgMutation,
  useEditRecipientFromEmailMutation,
  useEditRecipientFromTgMutation,
  useGetTenantRecipientsTgAndEmailQuery,
  useDeleteRecipientFromEmailMutation,
  useDeleteRecipientFromTgMutation,
} = tenantRecipientsApi;
