import React, { FC } from 'react';

import { IconType } from '../icon.type';

const WaveformBadgeExclamationmarkIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_458)">
          <path
            d="M12.4795 23.0234C12.9189 23.0234 13.2617 22.6719 13.2617 22.25V5.06738C13.2617 4.64551 12.9189 4.29395 12.4795 4.29395C12.04 4.29395 11.7061 4.64551 11.7061 5.06738V22.25C11.7061 22.6719 12.04 23.0234 12.4795 23.0234ZM17.7617 14.7881C18.166 14.1992 18.7021 13.6895 19.3086 13.3115V7.00977C19.3086 6.5791 18.9746 6.21875 18.5352 6.21875C18.0869 6.21875 17.7617 6.5791 17.7617 7.00977V14.7881ZM9.45605 19.6045C9.89551 19.6045 10.2383 19.2529 10.2383 18.8311V8.48633C10.2383 8.05566 9.89551 7.7041 9.45605 7.7041C9.0166 7.7041 8.68262 8.05566 8.68262 8.48633V18.8311C8.68262 19.2529 9.0166 19.6045 9.45605 19.6045ZM15.5117 18.4619C15.9424 18.4619 16.2852 18.1191 16.2852 17.6973V9.62012C16.2852 9.18945 15.9424 8.83789 15.5117 8.83789C15.0635 8.83789 14.7295 9.18945 14.7295 9.62012V17.6973C14.7295 18.1191 15.0635 18.4619 15.5117 18.4619ZM20.7852 12.6875C21.2773 12.5381 21.7959 12.4502 22.3408 12.4502V11.3428C22.3408 10.9121 21.998 10.5518 21.5586 10.5518C21.1191 10.5518 20.7852 10.9121 20.7852 11.3428V12.6875ZM6.42383 16.0625C6.87207 16.0625 7.21484 15.7109 7.21484 15.2803V12.0371C7.21484 11.6064 6.87207 11.2461 6.42383 11.2461C5.99316 11.2461 5.65918 11.6064 5.65918 12.0371V15.2803C5.65918 15.7109 5.99316 16.0625 6.42383 16.0625ZM22.3408 22.7422C24.8018 22.7422 26.8672 20.6943 26.8672 18.2158C26.8672 15.7373 24.8281 13.6982 22.3408 13.6982C19.8623 13.6982 17.8232 15.7461 17.8232 18.2158C17.8232 20.7031 19.8623 22.7422 22.3408 22.7422ZM22.3408 18.9805C21.998 18.9805 21.752 18.7432 21.7432 18.4092L21.6641 15.9219C21.6553 15.5264 21.9365 15.2539 22.3408 15.2539C22.7539 15.2539 23.0352 15.5264 23.0264 15.9219L22.9473 18.4092C22.9385 18.7432 22.6924 18.9805 22.3408 18.9805ZM22.3408 21.1689C21.8926 21.1689 21.5322 20.8174 21.5322 20.3691C21.5322 19.9297 21.8926 19.5781 22.3408 19.5781C22.7979 19.5781 23.1582 19.9297 23.1582 20.3691C23.1494 20.8174 22.7979 21.1689 22.3408 21.1689Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_458">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default WaveformBadgeExclamationmarkIcon;
