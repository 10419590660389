import React, { FC } from 'react';

import { IconType } from '../icon.type';

const HourglassBottomhalfFilledIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_481)">
          <path
            d="M10.291 21.8369H17.709C18.667 21.8369 19.2471 21.292 19.2471 20.29V20.123C19.2471 17.9521 16.9795 15.6846 15.8281 14.5508C15.2656 13.9883 15.2305 13.3379 15.8281 12.7402C16.9795 11.5977 19.2471 9.33887 19.2471 7.15918V7.01855C19.2471 6.0166 18.667 5.46289 17.709 5.46289H10.291C9.3418 5.46289 8.75293 6.0166 8.75293 7.01855V7.15918C8.75293 9.33887 11.0293 11.5977 12.1807 12.7402C12.7783 13.3379 12.7432 13.9883 12.1807 14.5508C11.0293 15.6846 8.75293 17.9521 8.75293 20.123V20.29C8.75293 21.292 9.3418 21.8369 10.291 21.8369ZM14.6943 12.2041C14.1934 12.626 13.8066 12.626 13.3057 12.2041C12.6904 11.668 10.915 9.83105 10.2998 8.80273C10.1064 8.49512 10.1943 8.21387 10.5195 8.21387H17.4805C17.8057 8.21387 17.8936 8.49512 17.7002 8.80273C17.085 9.83105 15.3184 11.668 14.6943 12.2041Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_481">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default HourglassBottomhalfFilledIcon;
