import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import Icon from '@app/components/ui/icons';
import { MenuBarItem } from '@app/constants/menu-bar-data';
import { usePermissionCheck } from '@app/hooks/use-permission';
import cn from 'classnames';

import { menuBarItemClassName, menuBarItemTextClassName } from './menu-bar.styles';

type MenuBarItemProps = {
  isCollapsed: boolean;
  itemMenu: MenuBarItem;
  tag?: string;
  soon?: boolean;
};

const MenuBarElement: FC<MenuBarItemProps> = (props) => {
  const { isCollapsed, itemMenu, tag, soon } = props;
  const { id, name, icon, route, iconSize, iconSizeHidden, routeEnd } = itemMenu;
  const isPermission = usePermissionCheck({ tag, permissions: 'display' });
  if (isPermission) return <></>;
  return (
    <li key={id}>
      <NavLink
        to={route}
        className={({ isActive }) =>
          cn(menuBarItemClassName({ isActive, isCollapsed }), `${soon && 'pointer-events-none'}`)
        }
        end={routeEnd}
      >
        <div className="inline-flex items-center overflow-y-hidden overflow-x-hidden">
          <Icon
            className="text-speech_analitics"
            name={icon}
            size={isCollapsed ? iconSizeHidden || 25 : iconSize || 18}
          />
          {!isCollapsed && (
            <span className={cn(menuBarItemTextClassName, `${soon && 'text-4color'}`)}>{name}</span>
          )}
          {soon && <div className="self-start text-[9px] ml-2 text-4color">(Скоро)</div>}
        </div>
      </NavLink>
    </li>
  );
};

export default MenuBarElement;
