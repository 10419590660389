import React, { FC } from 'react';

import { ReportTimeRange } from '@app/hooks/use-chart-date-intervals';
import { AggregateSelectForm, SettingsDateStep } from '@app/interfaces/report.type';
import SettingsFormBlock from '@app/pages/reports/blocks/chart-modal/settings-form.block';
import { useGetLiveReportGraphQuery } from '@app/store/api/live-reports-charts.api';
import { Loading } from '@ui';
import { useGetChatLiveReportGraphQuery } from '@app/store/api/chat-api/chat-live-reports-charts.api';
import { useOutletContext } from 'react-router';

import { ReportOutletContext } from '../../report.outlet';

type SettingsMetricStep = string;
export type SettingsChartForm = {
  type: string;
  name: string;
  width: 'full' | 'half';
  range: number | string | ReportTimeRange;
  display_legend: boolean;
  colors: Array<string>;
  display_data: boolean;
  group: 'metric' | 'date';
  group2: 'metric' | 'date';
  metric: 'string';
  step?: SettingsDateStep | SettingsMetricStep;
  metric_id: string;
  aggregate: AggregateSelectForm;
  color: string;
  metric_idY2: string;
  aggregateY2: AggregateSelectForm;
  colorY2: string;
  switchY2: boolean;
};

type ModalSettingsChartBlockProps = {
  isOpen: boolean;
  chartId?: string;
  onClose: (status: boolean) => void;
  onRemove?: (status: boolean) => void;
  currentTemplate?: 'voice' | 'chat';
};

const ModalSettingsChartBlock: FC<ModalSettingsChartBlockProps> = (props) => {
  const { isOpen, onClose, chartId, onRemove } = props;
  const { currentTemplate } = useOutletContext<ReportOutletContext>();

  const { data: setupChat, isLoading: loadingChat } = useGetChatLiveReportGraphQuery(
    { params: { id: chartId || '' } },
    { skip: currentTemplate === 'voice' || !chartId },
  );
  const { data: setupVoice, isLoading: loadingVoice } = useGetLiveReportGraphQuery(
    { params: { id: chartId || '' } },
    { skip: currentTemplate === 'chat' || !chartId },
  );

  const isLoading = loadingChat || loadingVoice;
  const setup = { chat: setupChat, voice: setupVoice };
  if (isLoading) return <Loading />;
  if (chartId && setup[currentTemplate || 'voice'])
    return (
      <SettingsFormBlock
        setup={setup[currentTemplate || 'voice']}
        isOpen={isOpen}
        onClose={onClose}
        onRemove={onRemove}
      />
    );
  return <>{isOpen && <SettingsFormBlock isOpen={isOpen} onClose={onClose} />}</>;
};

export default ModalSettingsChartBlock;
