import React, { FC } from 'react';

import { IconType } from '../icon.type';

const BeatsHeadphonesIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_50)">
          <path
            d="M5.11426 13.1357C5.11426 14.6738 5.50098 16.2646 6.22168 17.7676C5.98438 18.0488 5.94043 18.5674 6.19531 19.0771L7.88281 22.5137C8.0498 22.8564 8.42773 23.041 8.79688 23.041C9.22754 23.041 9.64941 22.7773 9.69336 22.2412L7.75977 18.3125C7.11816 17.0029 6.64355 15.1309 6.64355 13.1445C6.64355 8.80273 9.60547 5.87598 13.9912 5.87598C18.377 5.87598 21.3477 8.80273 21.3477 13.1445C21.3477 15.1309 20.8643 17.0029 20.2227 18.3125L18.2891 22.2412C18.333 22.7773 18.7549 23.041 19.1943 23.041C19.5547 23.041 19.9326 22.8564 20.1084 22.5137L21.7871 19.0771C22.042 18.5674 22.0068 18.0488 21.7695 17.7676C22.4902 16.2646 22.877 14.6738 22.877 13.1357C22.877 8.09961 19.3877 4.1709 13.9912 4.1709C8.59473 4.1709 5.11426 8.09961 5.11426 13.1357ZM9.92188 16.3877C9.59668 15.7197 8.97266 15.5703 8.49805 15.7637C7.9707 15.9834 7.61035 16.6162 7.99707 17.4248L10.1943 21.8809C10.5459 22.6016 11.1963 22.8564 11.8115 22.54C12.3389 22.2764 12.5234 21.6787 12.2158 21.0459L9.92188 16.3877ZM18.0605 16.3877L15.7666 21.0459C15.459 21.6787 15.6523 22.2764 16.1797 22.54C16.7949 22.8564 17.4365 22.6016 17.7969 21.8809L19.9854 17.4248C20.3809 16.6162 20.0117 15.9834 19.4844 15.7637C19.0098 15.5703 18.3945 15.7197 18.0605 16.3877Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_50">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default BeatsHeadphonesIcon;
