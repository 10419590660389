import { useTranslation } from '@app/hooks/use-translation';
import { ToolboxDefinition } from 'react-blockly';

export const useToolboxCategories = () => {
  const { t } = useTranslation('pages');
  const toolboxCategories: ToolboxDefinition = {
    kind: 'categoryToolbox',
    contents: [
      // {
      //   name: 'workspace',
      //   kind: 'workspace',
      //   colour: '#5C81A6',
      //   contents: [
      //     {
      //       kind: 'block',
      //       type: 'formula_start',
      //     },
      //   ],
      // },
      {
        kind: 'category',
        name: t('metric.logic_expressions'),
        colour: '#5C81A6',
        contents: [
          {
            kind: 'block',
            type: 'formula_if',
          },
          {
            kind: 'block',
            type: 'formula_and_or',
          },
          // {
          //   kind: 'block',
          //   type: 'formula_not',
          // },
        ],
      },
      {
        kind: 'category',
        name: t('metric.math_expressions'),
        colour: '#5CA65C',
        contents: [
          {
            kind: 'block',
            type: 'formula_mathops',
          },
          {
            kind: 'block',
            type: 'formula_relops',
          },
          {
            kind: 'block',
            type: 'formula_expression',
          },
        ],
      },
      {
        kind: 'category',
        name: t('metric.metric_expressions'),
        colour: '#5CA699',
        contents: [
          {
            kind: 'block',
            type: 'formula_metric',
          },
        ],
      },
    ],
  };
  return { toolboxCategories };
};
