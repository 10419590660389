const defaultTheme = {
  bg_components: '#f8f2fd',
  white: '#FFFFFF',
  action: '#2987CC',
  basic_app_bg: '#F5F5F7',
  bg_1: '#FBFCFE',
  bg_3: '#F0F1F2',
  bg_4: '#E3E6ED',
  bg5_modal: 'rgba(46, 56, 66, 0.5)',
  b_dark: '#DCE0E5',
  b_light: '#F1F2F3',
  red_pal: '#FDD8D8',
  basic_red: '#E92E5D',
  primary: '#8F529E',
  primary_copy: '#8F529E',
  '3color': '#5D697E',
  '2color': '#3D4858',
  '1color': '#2E3842',
  '0color': '#1A2128',
  '4color': '#A6ABBA',
  green: '#3BA86D',
  basic_green: '#3BA86D',
  b_medium: '#E5E8EB',
  blue_border: '#AFE3F4',
  green_pal: '#C6F1DA',
  speech_analitics: '#7A4487',
  speech_analitics_middle: '#6F397D',
  speech_analitics_light: '#EFE1F2',
  speech_analitics_filter: '#F9F5FA',
  defaultBg: '#F5F5F7',
  defaultBgBtn: '#2987CC',
  dangerBg: '#E92E5D',
  defaultColor: '#2987CC',
  borderDark: '#DCE0E5',
  'bg-4': '#E3E6ED',
  'b-light': '#F1F2F3',
  gray: '#F1F1F1',
  blue_pal: '#EEF9FD',
  text_white: '#FFF',
  scroll: '#A6ABBA',
  pastel_action: '#E6F1FF',
};

module.exports = defaultTheme;
