import React, { useState } from 'react';
import { Button } from '@ui';
import { GroupSharedStatus, ProjectItem } from '@app/interfaces/analytics';
import useTranslation from '@app/hooks/use-translation';
import { v4 } from 'uuid';
import {
  getFirstRunSelectionBlockItemClassName,
  getFirstRunSketchToBlockClassName,
} from '@app/pages/analytics/preset/index.style';
import classnames from 'classnames';
import { FolderOpenIcon, FolderV2Icon } from '@icons';
import { Filter, Range } from '@app/components/ui/easy-filter/types';
import { PresetTemplatesModalBlock } from '@app/pages/analytics/preset/blocks/preset-templates-modal.block';
import { PresetsGroupsType } from '@app/interfaces/slices-types/presets-slice.type';

export const PresetFillChooseBlock = ({
  project,
  createNewPresetSettings,
  presetTemplates,
}: {
  project: ProjectItem | null | undefined;
  createNewPresetSettings(filter?: Filter & Range): void;
  presetTemplates: PresetsGroupsType[] | undefined;
}) => {
  const { t } = useTranslation('pages.presetAp.presetFillChoose');
  const [presetFillChoose, changePresetFillChoose] = useState<'new_preset' | 'from_template'>(
    'new_preset',
  );
  const [fillFromTemplateModal, changeFillFromTemplateModalState] = useState(false);
  //api

  //handlers
  const handlerDataActions: Record<'new_preset' | 'from_template', () => void> = {
    new_preset: () => createNewPresetSettings(),
    from_template: () => changeFillFromTemplateModalState(true),
  };
  function onChooseFillPreset() {
    return handlerDataActions[presetFillChoose]();
  }
  return (
    <div className="text-center">
      <h3 className="text-0color text-[18px] leading-[21px] font-bold mb-[11px] mt-[55px]">
        {t('title')}
      </h3>
      <p className="text-2color text-[17px] leading-[19px] font-medium tracking-tight mb-[37px]">
        {t('description')}
      </p>
      <div className="flex justify-center mb-[35px]">
        {['new_preset', 'from_template'].map((item, index) => {
          return (
            <div
              className={classnames(
                getFirstRunSelectionBlockItemClassName(presetFillChoose === item),
                index === 0 && 'mr-[26px]',
              )}
              onClick={() => changePresetFillChoose(item as 'new_preset' | 'from_template')}
              key={`${item}-${index + v4()}`}
            >
              <div className={getFirstRunSketchToBlockClassName(index === 1)}>
                {index === 0 && <FolderOpenIcon size={73} className="text-speech_analitics" />}
                {index === 1 && (
                  <>
                    <div>
                      <FolderV2Icon
                        size={54}
                        className="text-speech_analitics mr-[19.52px] mb-[15.7px]"
                      />
                      <FolderV2Icon size={54} className="text-3color mr-[19.52px]" />
                    </div>
                    <div>
                      <FolderV2Icon size={54} className="text-3color mb-[15.7px]" />
                      <FolderV2Icon size={54} className="text-3color" />
                    </div>
                  </>
                )}
              </div>
              <div className="h-[62px] flex justify-center items-center">
                <input
                  type="radio"
                  name="preset"
                  checked={presetFillChoose === item}
                  onChange={() => changePresetFillChoose(item as 'new_preset' | 'from_template')}
                  className="mr-[7px] text-action"
                />
                {index === 0 ? t('new_preset') : t('from_template')}
              </div>
            </div>
          );
        })}
      </div>
      <Button
        label={t('create_preset_button')}
        disabled={!project?.owner && project?.shared === GroupSharedStatus.VIEW}
        onClick={() => onChooseFillPreset()}
      />
      <PresetTemplatesModalBlock
        presetTemplates={presetTemplates}
        fillFromTemplateModal={fillFromTemplateModal}
        changeFillFromTemplateModalState={changeFillFromTemplateModalState}
        createNewPresetSettings={createNewPresetSettings}
      />
    </div>
  );
};
