import React, { FC } from 'react';

import { IconType } from '../icon.type';

const RublesignCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_614_2002)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM12.5234 17.9961C12.9189 17.9961 13.1914 17.7236 13.1914 17.3193V16.5723H15.1338C15.3887 16.5723 15.5469 16.3965 15.5469 16.1592C15.5469 15.9131 15.3887 15.7373 15.1338 15.7373H13.1914V14.8936H14.6064C16.2412 14.8936 17.2959 13.8125 17.2959 12.3096C17.2959 10.7979 16.2588 9.7168 14.6328 9.7168H12.5234C12.1104 9.7168 11.8555 9.98047 11.8555 10.4111V14.0498H11.0908C10.8359 14.0498 10.6777 14.2344 10.6777 14.4717C10.6777 14.7178 10.8359 14.8936 11.0908 14.8936H11.8555V15.7373H11.0996C10.8447 15.7373 10.6865 15.9131 10.6865 16.1592C10.6865 16.3965 10.8447 16.5723 11.0996 16.5723H11.8555V17.3281C11.8555 17.7236 12.1279 17.9961 12.5234 17.9961ZM13.2002 13.7422V10.8945H14.3779C15.4062 10.8945 15.9512 11.4482 15.9512 12.3271C15.9512 13.2061 15.3975 13.7598 14.3779 13.7598L13.2002 13.7422Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_614_2002">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default RublesignCircleIcon;
