module.exports = {
  analytic_title: 'Аналитика звонков',
  templates_title: 'Шаблоны',
  settings_title: 'Настройки',
  main: '',
  dashboard: 'Рабочий стол',
  all_records: 'Все записи звонков',
  analytic_proj: 'Аналитические проекты',
  proj_records: 'Записи проекта',
  reports: 'Отчеты',
  presets_templates: 'Шаблоны пресетов',
  metrics_templates: 'Шаблоны метрик звонков',
  dictionaries: 'Словари',
  system_settings: 'Настройки системы',
  audit_logs: 'Логи аудита',
  users: 'Пользователи',
  words_cloud: 'Облако слов',
  words_n_gramm: 'Облако фраз',
  all_chats: "Все записи чатов",
  chat_analytic: "Аналитика чатов",
  chat_metric_templates: "Шаблоны метрик чатов",
};
