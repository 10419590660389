import React from 'react';
import { Route } from 'react-router-dom';
import { navigationRoutes } from '@app/utils/navigation-routes';
import RequiredAuth from '@app/routes/required-auth.route';
import { BreadcrumbsItem } from '@app/layouts/layout';
import WordsCloud from '@app/pages/words-cloud/words-cloud.page';
import { WordsCloudContent } from '@app/pages/words-cloud/blocks/cloud-content';
import { TableContent } from '@app/pages/words-cloud/blocks/table-content';

export const wordsCloudRoute = (
  <Route
    path={navigationRoutes.wordsCloud}
    element={
      <RequiredAuth>
        <WordsCloud />
      </RequiredAuth>
    }
  >
    <Route
      index
      element={
        <RequiredAuth>
          <WordsCloudContent />
        </RequiredAuth>
      }
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={`/${navigationRoutes.wordsCloud}`} crumb="wordsCloud" />
        ),
      }}
    />
    <Route
      path="table"
      element={<TableContent />}
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={`/${navigationRoutes.wordsCloud}`} crumb="wordsCloud" />
        ),
      }}
    />
  </Route>
);
