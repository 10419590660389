module.exports = {
  required: 'Field not filled in',
  fields: {
    email: 'Email',
    login: 'Login',
    password: 'Password',
  },
  placeholders: {
    email: 'email@domain.com',
    password: '******',
    login: 'Enter login',
  },
  errors: {
    email: {
      required: 'Field not filled in',
      invalid: 'Invalid email',
    },
    password: {
      required: 'Password not filled out',
      short: 'Password must be 6 characters long',
    },
  },
  dropdown: {
    placeholder: 'Select',
    multiSelected: '%{count} items selected',
  },
  search: {
    placeholder: 'Search',
  },
};
