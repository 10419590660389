import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { RecordTranscriptionModelsType } from '@app/interfaces/record';

import { baseQuery } from '../baseQuery';

export const audioRecordApi = createApi({
  reducerPath: 'audioRecordApi',
  tagTypes: ['audioRecordApi'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getRecordTranscriptionModels: build.query<RecordTranscriptionModelsType, { record_id: string }>(
      {
        query: (params) => ({
          url: serverRoutes.recordTranscriptionModels,
          params,
        }),
      },
    ),
  }),
});

export const { useGetRecordTranscriptionModelsQuery } = audioRecordApi;
