import React from 'react';
import { Box } from '@app/components/ui';
import MainSettings from '@app/pages/analytics/project-settings/analytics-main-settings/blocks/main-settings';

const ProjectSettingsPage = () => {
  return (
    <div>
      <Box>
        <MainSettings />
      </Box>
    </div>
  );
};

export default ProjectSettingsPage;
