import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { ProjectItem } from '@app/interfaces/analytics';

import { useGetSingleChatProjectQuery } from '@app/store/api/chat-api/chat-analytic.api';

import { NotFoundedPage } from '@app/pages';
import { Loading } from '@app/components/ui';

import { ChatAnalyticPageHeader } from './blocks/chat-analytic-page-header';

export type AnalyticPageOutlet = {
  project?: ProjectItem | null;
  loading?: boolean;
  disabled?: boolean;
  currentTemplate: 'chat' | 'voice';
};

const AnalyticDetailsLayout = () => {
  const { id } = useParams();

  //api
  const {
    data: analyticProject,
    isError,
    isLoading,
  } = useGetSingleChatProjectQuery({ id: id || '' }, { pollingInterval: 30000 });
  //api

  const analyticPageOutlet: AnalyticPageOutlet = {
    project: { ...analyticProject, disabled: true } as ProjectItem,
    currentTemplate: 'chat',
  };

  if (isError) return <NotFoundedPage />;
  if (isLoading) return <Loading />;
  return (
    <div className="pb-[35px]">
      <ChatAnalyticPageHeader project={analyticProject} />

      <Outlet context={analyticPageOutlet} />
    </div>
  );
};
export default AnalyticDetailsLayout;
