import React from 'react';

import { LabelColor } from '@app/components/ui/label/label.type';
import { FC } from 'react';
import useTranslation from '@app/hooks/use-translation';
import { IconNameType } from '@app/components/ui/icons/icon-name.type';
import { Label } from '@ui';

export type StatusItem = {
  title: string;
  variant: LabelColor;
  icon?: IconNameType;
};

export type StatusVariant =
  | 'LIFE'
  | 'CREATED'
  | 'UPDATING'
  | 'DONE'
  | 'ARCHIVE'
  | 'PAUSE'
  | 'DELETING'
  | 'ERROR'
  | 'FILLING'
  | 'ACTIVE'
  | 'STOPPING'
  | 'STARTING'
  | 'ACTUALIZATION'
  | 'REMOVING';

type StatusLabelProps = {
  status: StatusVariant;
  onChangeStatus?: (status: string) => void;
  isDropLeft?: boolean;
  isDropRight?: boolean;
  statusProperties?: Partial<Record<StatusVariant, Array<StatusVariant>>>;
};

export const StatusLabel: FC<StatusLabelProps> = (props) => {
  const { status, onChangeStatus, isDropLeft, isDropRight = false, statusProperties } = props;
  const { t } = useTranslation('components.reportsStatusLabel');

  const reportsList: Record<StatusVariant, StatusItem> = {
    LIFE: { title: 'LIVE', variant: 'success', icon: 'LiveIcon' },
    CREATED: { title: t('created'), variant: 'success_light' },
    UPDATING: { title: t('updating'), variant: 'primary' },
    DONE: { title: t('done'), variant: 'primary' },
    ARCHIVE: { title: t('archive'), variant: 'danger' },
    PAUSE: { title: t('pause'), variant: 'danger' },
    DELETING: { title: t('deleting'), variant: 'danger' },
    REMOVING: { title: t('removing'), variant: 'danger' },
    ERROR: { title: t('error'), variant: 'danger' },
    FILLING: { title: t('filling'), variant: 'primary' },
    ACTIVE: { title: t('active'), variant: 'success' },
    STOPPING: { title: t('stopping'), variant: 'danger' },
    STARTING: { title: t('starting'), variant: 'success_light' },
    ACTUALIZATION: { title: t('actualization'), variant: 'success_light' },
  };

  const filteredDropMenu =
    statusProperties?.[status]?.map((statusFromPropertyList) => ({
      ...reportsList[statusFromPropertyList],
      value: statusFromPropertyList,
    })) || [];

  return (
    <Label
      title={reportsList[status].title}
      value={status}
      variant={reportsList[status].variant}
      icon={reportsList[status]?.icon}
      dropMenu={filteredDropMenu}
      onDropSelected={(selectedMenu) => onChangeStatus?.(selectedMenu.value as string)}
      isDrop={Boolean(statusProperties?.[status]?.length || 0)}
      isDropLeft={isDropLeft}
      isDropRight={isDropRight}
    />
  );
};

export default StatusLabel;
