import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const LetterA: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 12 14"
      >
        <path
          fill="currentColor"
          d="M1.412 13.237c.598 0 .914-.246 1.108-.879l.958-2.707h5.01l.957 2.707c.194.633.51.88 1.108.88.632 0 1.037-.379 1.037-.967 0-.22-.035-.405-.123-.65L7.6 1.16C7.327.396 6.817.036 5.99.036c-.79 0-1.3.36-1.564 1.116L.533 11.672a1.663 1.663 0 00-.123.616c0 .589.378.95 1.002.95zM3.987 8l1.978-5.695h.053l1.977 5.695H3.987z"
        ></path>
      </svg>
    </div>
  );
};

export default LetterA;
