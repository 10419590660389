import { Button, EasyModal } from '@ui';
import Icon from '@app/components/ui/icons';
import useTranslation from '@app/hooks/use-translation';
import React, { Dispatch, FC, SetStateAction } from 'react';

type PropsType = {
  show: boolean;
  name: string;
  applyBtn: () => void;
  onClose: Dispatch<SetStateAction<boolean>>;
};

export const AddToTemplateModal: FC<PropsType> = ({ show, onClose, applyBtn, name }) => {
  const { t } = useTranslation('pages.metricTemplates');
  return (
    <EasyModal variant="small" withoutFooter show={show} onClose={() => onClose(false)}>
      <div className="flex items-center justify-center gap-[15px] flex-col">
        <div className="p-[16px] rounded-full bg-action/10 relative overflow-hidden flex items-center justify-center">
          <Icon size={22} className="text-action" name={'HelpCircleIcon'} />
        </div>
        <h2 className="text-0color text-[24px] font-[700]">{t('warning')}</h2>
        <span className="text-0color text-[17px] font-[500] text-center">
          {t('add_template')} {name}
        </span>
        <div className=" w-full py-[30px] flex items-center px-[70px]">
          <Button onClick={() => applyBtn()} className="w-full" label={t('add')} />
          <Button
            className="w-full"
            onClick={() => onClose(false)}
            fill="linked"
            label={t('cancel')}
          />
        </div>
      </div>
    </EasyModal>
  );
};
