import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewLiveReportGraph, ReportChart, ReportListChart } from '@app/interfaces/report.type';
import { ChartsLayoutType } from '@app/interfaces/dashboards.type';

const initialState: {
  charts: (ReportListChart & ReportChart)[] | null;
  isLoading: boolean;
  reportLayout: ChartsLayoutType[] | null;
  chartLoading: string | null;
} = {
  charts: null,
  reportLayout: null,
  isLoading: true,
  chartLoading: null,
};

const reportChartsSlice = createSlice({
  name: 'reportChartsSlice',
  initialState,
  reducers: {
    setInitialReportCharts: (state, { payload }) => {
      state.chartLoading = null;
      state.isLoading = true;
      const { data, type } = payload;
      state.charts = data;
      if (type === 'initialSetup') {
        state.reportLayout = data.map((chart) => chart.layout);
      }
      state.isLoading = false;
    },
    changeReportChartsInitialLayout: (state, { payload }) => {
      state.reportLayout = payload;
    },
    changeReportChartsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    addNewChartOnReport: (
      state,
      {
        payload: { createdChartOnReport, createdChartData },
      }: PayloadAction<{
        createdChartOnReport: NewLiveReportGraph;
        createdChartData: ReportChart;
      }>,
    ) => {
      const layout = { ...createdChartOnReport.layout, i: createdChartOnReport.graph_id };
      const formattedChartObject = {
        ...createdChartData,
        layout: layout,
        live_report_id: createdChartData.live_report_id,
      };
      state.charts?.push(formattedChartObject);
      state.reportLayout?.push(layout);
    },
    editChartOnReport: (
      state,
      {
        payload: { editedChartOnReport, editedChartData },
      }: PayloadAction<{
        editedChartOnReport: NewLiveReportGraph;
        editedChartData: ReportChart;
      }>,
    ) => {
      const layout = { ...editedChartOnReport.layout, i: editedChartOnReport.graph_id };
      const formattedChartObject = {
        ...editedChartData,
        layout: layout,
      };

      state.charts =
        state.charts?.map((draftChart) => {
          if (draftChart.graph_id === editedChartData.graph_id) {
            return formattedChartObject;
          }
          return draftChart;
        }) || [];
    },
    changeChartLoading: (state, { payload }: PayloadAction<string | null>) => {
      state.chartLoading = payload;
    },
    editSelectedReportChartRange: (
      state,
      {
        payload: { chartData, layout, id },
      }: PayloadAction<{
        chartData: ReportChart;
        layout?: ChartsLayoutType;
        id: string;
      }>,
    ) => {
      state.charts =
        state.charts?.map((draftChart) => {
          if (draftChart.graph_id === id) {
            return {
              ...draftChart,
              categories: chartData.categories,
              series: chartData.series,
              system_date_diapason: chartData.system_date_diapason,
              range: chartData.range,
              layout: layout || draftChart.layout,
            };
          }
          return draftChart;
        }) || [];
    },
    deleteChartFromReport: (state, { payload }: PayloadAction<string>) => {
      state.charts = state.charts?.filter((chart) => chart.graph_id !== payload) || [];
    },
    changeAllReportCharts: (
      state,
      { payload }: PayloadAction<(ReportListChart & ReportChart)[] | null>,
    ) => {
      state.isLoading = true;
      state.charts = payload;
      state.isLoading = false;
    },
  },
});

export default reportChartsSlice.reducer;

export const {
  setInitialReportCharts,
  changeReportChartsLoading,
  changeReportChartsInitialLayout,
  addNewChartOnReport,
  editChartOnReport,
  changeChartLoading,
  changeAllReportCharts,
  editSelectedReportChartRange,
  deleteChartFromReport,
} = reportChartsSlice.actions;
