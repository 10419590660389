module.exports = {
  current_day: 'Текущий день',
  current_week: 'Текущая неделя',
  current_month: 'Текущий месяц',
  without_interval: 'Без указания диапазона дат',
  with_interval: 'Дата и время : Диапазон',
  date_n_days: 'Дата и время : N дней',
  calendar_interval: 'Дата и время : Календарная привязка',
  exists: 'Существует',
  not_exists: 'Не существует',
  calculated: 'Посчитанная',
  not_calculated: 'Непосчитанная',
  // type diapason  time
  range_from: 'Дата и время : Диапазон',
  range_to: 'Дата и время : Последние',
  calendar_bin: 'Дата и время : Календарная привязка',
  // type diapason  time
  // display special conditions translate like exist
  display_exists: 'e',
  display_not_exists: '!e',
  display_calculated: 'c',
  display_not_calculated: '!c',
  // display special conditions translate like exist,
  server_error: 'Ошибка сервера',
  server_no_access: 'Нет доступа',
  print_report: 'Распечатать отчет',
}
