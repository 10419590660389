import React, { FC } from 'react';

import { IconType } from '../icon.type';

const PlusCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_614_3028)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM9.93066 13.6543C9.93066 14.1289 10.2734 14.4629 10.7656 14.4629H13.1738V16.8799C13.1738 17.3633 13.5078 17.7061 13.9824 17.7061C14.4746 17.7061 14.8174 17.3633 14.8174 16.8799V14.4629H17.2344C17.7178 14.4629 18.0518 14.1289 18.0518 13.6543C18.0518 13.1621 17.7178 12.8193 17.2344 12.8193H14.8174V10.4111C14.8174 9.91895 14.4746 9.58496 13.9824 9.58496C13.5078 9.58496 13.1738 9.91895 13.1738 10.4111V12.8193H10.7656C10.2734 12.8193 9.93066 13.1621 9.93066 13.6543Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_614_3028">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default PlusCircleIcon;
