import React, { FC, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router';

import { PageHeader, Select, Skeleton } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { OptionItem } from '@app/components/ui/select/select.type';
import { useGetChannelsQuery } from '@app/store/api/get-channels-info.api';

import { useGetAllLiveReportsListQuery } from '@app/store/api/live-reports.api';

import {
  WordNGramStatusesType,
  WordsNGramStaticRequestType,
} from '@app/interfaces/words-n-gramm.type';

import { useGetAllDictionariesListQuery } from '@app/store/api/dictionaries.api';

import {
  useGetChatCloudStatusQuery,
  useGetChatWordsNGramSettingsQuery,
  useGetCloudStatusQuery,
  useGetWordsNGramSettingsQuery,
} from '@app/store/api/get-words-n-gramm.api';

import { NotFoundedPage } from '@app/pages';

import { useGetAllChatLiveReportsListQuery } from '@app/store/api/chat-api/live-reports.api';

import { useGetChatRolesQuery } from '@app/store/api/chat-api/get-chat-roles.api';

import { WordsCloudFilter } from './blocks/words-cloud-filter';
import { WordCloudContext } from './types/types';
import WordsCloudSkeleton from './blocks/words-cloud.skeleton';

const WordsNGramm: FC<{ currentTemplate: 'chat' | 'voice' }> = ({ currentTemplate }) => {
  const localStorageKeyByTemplate = useMemo(
    () => ({ chat: 'cloud_n_gram_chat', voice: 'cloud_n_gram' }),
    [],
  );
  const { t } = useTranslation('pages.wordsNGramm');
  const [projectId, changeProjectId] = useState<string | undefined>(
    localStorage.getItem(localStorageKeyByTemplate[currentTemplate]) || undefined,
  );
  const { data: dictionaries, isLoading: dictLoading } = useGetAllDictionariesListQuery();

  const [dataForCloud, setDataForCloud] = useState<WordsNGramStaticRequestType>();
  const [nGramCloudStatus, changeStatus] = useState<WordNGramStatusesType>();
  const { data: voiceChannels, isLoading: channelLoading } = useGetChannelsQuery(undefined, {
    skip: currentTemplate === 'chat',
  });

  const { data: chatRoles, isLoading: rolesLoading } = useGetChatRolesQuery(undefined, {
    skip: currentTemplate === 'voice',
  });

  const isLoading = rolesLoading || channelLoading;

  const { data: projectsListVoice, isLoading: loadingVoice } = useGetAllLiveReportsListQuery(
    {
      limit: -1,
    },

    { skip: currentTemplate === 'chat' },
  );
  const { data: projectsListChat, isLoading: loadingChats } = useGetAllChatLiveReportsListQuery(
    {
      limit: -1,
    },
    { skip: currentTemplate === 'voice' },
  );
  const projectsList = useMemo(
    () => ({ chat: projectsListChat, voice: projectsListVoice }),
    [projectsListChat, projectsListVoice],
  );
  const loading = loadingChats || loadingVoice;

  const { data: cloudSettingsVoice, isFetching: settingsLoadingVoice } =
    useGetWordsNGramSettingsQuery(
      {
        live_report_id: projectId || '',
      },
      {
        refetchOnMountOrArgChange: currentTemplate === 'voice',
        skip: currentTemplate === 'chat' || projectId === 'undefined',
      },
    );
  const { data: cloudSettingsChat, isFetching: settingsLoadingChat } =
    useGetChatWordsNGramSettingsQuery(
      {
        live_report_id: projectId || '',
      },
      {
        refetchOnMountOrArgChange: currentTemplate === 'chat',
        skip: currentTemplate === 'voice' || projectId === 'undefined',
      },
    );

  const cloudSettings = { chat: cloudSettingsChat, voice: cloudSettingsVoice };
  const settingsLoading = settingsLoadingChat || settingsLoadingVoice;

  const { data: cloudStatusVoice } = useGetCloudStatusQuery(
    {
      live_report_id: projectId || '',
    },
    {
      refetchOnMountOrArgChange: currentTemplate === 'voice',
      pollingInterval: currentTemplate === 'chat' ? undefined : 5000,
      skip:
        currentTemplate === 'chat'
          ? true
          : projectId !== 'undefined'
          ? nGramCloudStatus === 'EMPTY' || nGramCloudStatus === 'READY'
          : true,
    },
  );
  const { data: cloudStatusChat } = useGetChatCloudStatusQuery(
    {
      live_report_id: projectId || '',
    },
    {
      refetchOnMountOrArgChange: currentTemplate === 'chat',
      pollingInterval: currentTemplate === 'chat' ? 5000 : undefined,
      skip:
        currentTemplate === 'voice'
          ? true
          : projectId !== 'undefined'
          ? nGramCloudStatus === 'EMPTY' || nGramCloudStatus === 'READY'
          : true,
    },
  );

  const cloudStatus = useMemo(
    () => ({ chat: cloudStatusChat, voice: cloudStatusVoice }),
    [cloudStatusChat, cloudStatusVoice],
  );

  useEffect(() => {
    changeStatus(cloudStatus[currentTemplate]?.status);
    if (cloudStatus[currentTemplate]?.status !== 'READY' || !projectId) return;
    setDataForCloud({ limit: 100, live_report_id: projectId });
  }, [cloudStatus, currentTemplate, projectId]);

  useEffect(() => {
    if (projectId) return;
    const project = projectsList[currentTemplate || 'voice']?.live_reports?.[0].live_report_id;
    localStorage.setItem(localStorageKeyByTemplate[currentTemplate], project as string);
    changeProjectId(project);
  }, [currentTemplate, localStorageKeyByTemplate, projectId, projectsList]);

  function refetchStatus() {
    changeStatus(undefined);
  }

  function handleSelectOnChange(option: string | number) {
    localStorage.setItem(localStorageKeyByTemplate[currentTemplate], option as string);
    changeProjectId(option as string);
    refetchStatus();
    setDataForCloud(undefined);
  }

  const renderSelectProjectOptions: Array<OptionItem> =
    projectsList[currentTemplate || 'voice']?.live_reports.map(({ name, live_report_id }) => ({
      title: name,
      value: live_report_id,
    })) || [];

  function onFormSubmit(data: Omit<WordsNGramStaticRequestType, 'live_report_id'>) {
    if (!projectId) return;
    setDataForCloud({ ...data, live_report_id: projectId });
  }

  const renderSelectProject = loading ? (
    <Skeleton width={234} height={32} />
  ) : (
    <Select
      size={240}
      onChange={handleSelectOnChange}
      placeholder={t('select_all_projects')}
      options={renderSelectProjectOptions}
      defaultValue={projectId}
      isEmpty
      dropWidth={238}
    />
  );

  if (loading || isLoading || dictLoading || settingsLoading) return <WordsCloudSkeleton />;
  if (!projectsList[currentTemplate || 'voice']?.live_reports?.length)
    return <NotFoundedPage text={t('error_page')} />;
  return (
    <>
      <PageHeader label={t('module_name')} icon="CloudIcon">
        <div className="inline-flex font-[500] text-[15px] text-1color items-center">
          {t('choose_project')}:<div className="ml-[11px]">{renderSelectProject}</div>
        </div>
      </PageHeader>

      {nGramCloudStatus === 'CALCULATING' || nGramCloudStatus === 'NEED_CALCULATE' ? (
        <WordsCloudSkeleton withoutPageHeader label={t('system.cloud_is_calculating')} />
      ) : (
        <div className="relative h-[calc(100%-90px)] w-full">
          <WordsCloudFilter
            projectId={projectId}
            currentTemplate={currentTemplate}
            disableSaveButton={!!projectId}
            onFormSubmit={onFormSubmit}
            channels={voiceChannels}
            chatRoles={chatRoles}
            dictionaries={dictionaries || []}
            cloudSettings={cloudSettings[currentTemplate]}
            refetchCloudStatus={refetchStatus}
            cloudStatus={nGramCloudStatus}
            analyticsProjectByReport={
              projectsList[currentTemplate || 'voice']?.live_reports.find(
                (report) => report.live_report_id === projectId,
              )?.project
            }
          />
          <div className=" absolute left-0 bottom-0 w-[calc(100%-410px)] h-[calc(100%-90px)]">
            <Outlet
              context={
                {
                  data: dataForCloud,
                  cloudWasCalculated: cloudSettings[currentTemplate]?.dt_calc,
                  currentTemplate,
                } as WordCloudContext
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WordsNGramm;
