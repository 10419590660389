module.exports = {
  // комопнент который внутри метрики поиск слов фраз  и в словарях (всплывающее окно)
  word_err: 'Данное слово/словосочетание уже используется',
  dict_err: 'Данный словарь уже используется',
  word_for_search: 'Слова/фразы для поиска.',
  dict: 'Словари',
  popup_hints: {
    delete_icon: 'Удалить',
    add_dictionary_from_list: 'Добавить словарь из списка',
    to_dictionary: 'Перейти в словарь',
  },
};
