import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { GetFilterMetricResultParams } from '@app/interfaces/filter';
import { MetricsBulkItems } from '@app/interfaces/metric.type';

import { baseQuery } from '../baseQuery';

import { chatMetricApi } from './chat-api/chat-metric.api';

export const metricRecordsApi = createApi({
  reducerPath: 'metricRecordsApi',
  tagTypes: ['metricRecords', 'chatMetricRecords'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getMetricRecords: build.query<
      MetricsBulkItems & { total: number; total_calculated: number },
      GetFilterMetricResultParams
    >({
      query: (body) => ({
        url: serverRoutes.metrics,
        body,
        params: { project_id: body.project_id },
        method: 'POST',
      }),
      async onQueryStarted({ project_id }, { queryFulfilled }) {
        const { data: metricList } = await queryFulfilled;
        for (const metricHeader of metricList.headers) {
          if (metricHeader.type === 'tags') {
            if (!project_id || !metricHeader.metric_id) return;
            chatMetricApi.endpoints?.getChatProjectMetric.initiate({
              project_id,
              metric_id: metricHeader.metric_id,
            });
          }
        }
      },
      providesTags: ['metricRecords'],
    }),
    getMetricRecordsChat: build.query<
      MetricsBulkItems & { total: number; total_calculated: number },
      GetFilterMetricResultParams
    >({
      query: (body) => ({
        url: serverRoutes.metricsChat,
        body,
        params: { project_id: body.project_id },
        method: 'POST',
      }),
      async onQueryStarted({ project_id }, { queryFulfilled }) {
        const { data: metricList } = await queryFulfilled;
        for (const metricHeader of metricList.headers) {
          if (metricHeader.type === 'tags') {
            if (!project_id || !metricHeader.metric_id) return;
            chatMetricApi.endpoints?.getChatProjectMetric.initiate({
              project_id,
              metric_id: metricHeader.metric_id,
            });
          }
        }
      },
      providesTags: ['chatMetricRecords'],
    }),
  }),
});

export const { useGetMetricRecordsQuery, useGetMetricRecordsChatQuery } = metricRecordsApi;
