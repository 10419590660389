import React, { FC } from 'react';

import { IconType } from '../icon.type';

const CupAndSaucericon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_813_403)">
          <path
            d="M2.37207 17.5654C2.37207 20.4219 7.18848 22.3643 13.9912 22.3643C20.8115 22.3643 25.6191 20.4219 25.6191 17.5654C25.6191 16.2734 24.626 15.1572 22.8945 14.3223C24.3447 14.0146 25.1973 13.0039 25.1973 11.5186C25.1973 9.7959 24.0459 8.72363 22.1475 8.62695C22.0771 6.4209 18.8164 4.95312 13.9912 4.95312C9.12207 4.95312 5.83496 6.45605 5.83496 8.70605V10.5693C5.83496 11.7471 6.03711 12.8369 6.40625 13.8125C3.85742 14.6562 2.37207 15.9746 2.37207 17.5654ZM13.9912 10.833C10.2559 10.833 7.46094 9.89258 7.46094 8.70605C7.46094 7.51953 10.2559 6.5791 13.9912 6.5791C17.7354 6.5791 20.5303 7.51953 20.5303 8.70605C20.5303 9.89258 17.7266 10.833 13.9912 10.833ZM22.1562 10.5693V10.2178C23.0791 10.2969 23.6064 10.7627 23.6064 11.5186C23.6064 12.3359 22.9736 12.8369 21.8838 12.8369C22.0596 12.125 22.1562 11.3691 22.1562 10.5693ZM13.9912 12.459C16.8037 12.459 19.0889 11.958 20.5127 11.0967C20.3018 14.5771 17.9814 16.8975 14.4131 16.8975H13.5693C9.99219 16.8975 7.68945 14.5859 7.47852 11.0967C8.90234 11.958 11.1875 12.459 13.9912 12.459ZM3.93652 17.5654C3.93652 16.7041 5.13184 15.8516 7.12695 15.2363C8.44531 17.2842 10.7217 18.5234 13.5693 18.5234H14.4131C17.2695 18.5234 19.5371 17.2842 20.8555 15.2451C22.8506 15.8516 24.0547 16.7041 24.0547 17.5654C24.0547 19.2354 19.5986 20.7998 13.9912 20.7998C8.39258 20.7998 3.93652 19.2354 3.93652 17.5654Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_813_403">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CupAndSaucericon;
