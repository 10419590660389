import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { navigationRoutes } from '@app/utils/navigation-routes';
import useTranslation from '@app/hooks/use-translation';
import { Icons } from '@ui';
import { IconNameType } from '@app/components/ui/icons/icon-name.type';

type NotFoundedPageProps = {
  text?: string;
  icon?: IconNameType;
  link?: string;
};

const NotFoundedPage: FC<NotFoundedPageProps> = (props) => {
  const { text, icon = 'ErrorIcon', link = navigationRoutes.main } = props;
  const { t } = useTranslation('pages.notFound');
  return (
    <div className="w-full h-full inline-flex items-center justify-center">
      <div className="inline-flex items-center flex-col justify-center p-[20px]">
        <Icons size={120} name={icon} className="text-speech_analitics_light" />
        <div className="font-bold text-[28px] uppercase text-speech_analitics">
          {text || t('title')}
        </div>
        <Link to={link} className="text-defaultColor">
          {t('link.main')}
        </Link>
      </div>
    </div>
  );
};

export default NotFoundedPage;
