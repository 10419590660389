module.exports = {
  pageTitle: 'Records',
  records_for_delete_modal: 'records',
  multi_delete_records_modal_label: 'Delete selected',
  multi_delete_records_modal_description: 'All selected records will be deleted',
  record_delete_modal_label: 'Delete record',
  change_column_display_modal: {
    modal_label: 'Columns display',
    modal_description: 'Description',
    choose_cols: 'Select displayed columns',
  },
  popup_hints: {
    upload_modal: 'Upload records',
    data_display_set_up: 'Customize data display',
    download_record: 'Download audio',
    to_record_title: 'View record',
    delete_icon: 'Delete',
    go_to_audio: 'View audio',
  },
  system: {
    calls: 'calls',
    preset_template_default_name: 'Enter template name',
  },
  popover: {
    download_records: 'Download selected records',
    delete_selected: 'Delete selected',
    buttonLabel: 'Actions'
  },
  filter: {
    buttons: {
      save_as_template: 'Save as preset template',
      reset_filter: 'Clear'
    }
  },
  table: {
    first_column: 'Duration',
    last_column: 'Actions'
  }
}
