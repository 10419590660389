import React from 'react';
import { AnalyticsIcon } from '@icons';

const FallbackPage = () => {
  return (
    <div className="w-screen h-screen items-center inline-flex justify-center bg-white">
      <AnalyticsIcon size={20} className="animate-pulse text-primary" />
    </div>
  );
};

export default FallbackPage;
