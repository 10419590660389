const UseDownloadLink = () => {
  function download({ name, format, file }: { name: string; format: string; file: Blob }) {
    const url = window.URL.createObjectURL(new Blob([file], { type: 'text/plain;charset=utf-8;' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}.${format}`);
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  }
  return download;
};

export default UseDownloadLink;
