import React, { FC } from 'react';
import { useParams } from 'react-router';
import { NotFoundedPage } from '@app/pages';
import AudioBuild from '@app/components/audio-build';

const SingleLastRecordPage: FC<{ currentTemplate: 'chat' | 'voice' }> = ({ currentTemplate }) => {
  const { recordId, recordProjectId } = useParams();

  if (!recordId) return <NotFoundedPage />;
  return (
    <AudioBuild
      currentTemplate={currentTemplate}
      recordId={recordId}
      projectId={recordProjectId}
      withMetric
    />
  );
};

export default SingleLastRecordPage;
