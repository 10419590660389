module.exports = {
  module_name: 'Phrase cloud',
  choose_project: 'Select report',
  select_all_projects: 'All reports',
  error_page: 'No reports created in the project',
  calculate_button_tooltip: 'Cloud calculation can take several minutes',
  part_of_speech_data: {
    all: 'All parts of speech',
    NOUN: 'Noun',
    VERB: 'Verb',
    ADJ: 'Adjective',
    ADVB: 'Adverb',
    NUMR: 'Numerical',
    NPRO: 'Pronoun',
    CONJ: 'Prepositions/ conjunctions/particles',
  },
  startpoint_values: {
    after_phrase: 'After a phrase with the word',
    before_phrase: 'Before a phrase with the word',
    begin: 'From the beginning of dialog',
    end: 'At the end of dialog'
  },
  form: {
    additionalSettings: 'Additional settings',
    channel:'In the channels: ',
    fields_labels: {
      search_area_number_phrases: 'phrases',
      in_channels_find: 'Search the channels',
      startpoint_type: 'Search area',
      startpoint_channels: 'Phrases with the word in channels',
      exclude_from_search: 'Exclude from search',
      exclude_first: 'first',
      exclude_last: 'final phrases',
      all_forms: 'All word forms',
      stopwords: 'Exclude phrases with the word',
    },
    fields_placeholders: {
      filter_input: 'Display phrases with the word'
    }
  },
  occurrence: 'Occurrence',
  all_channels_option: 'All channels',
  first_phrases_count: 'Number of first phrases',
  change_cloud_settings: 'Change cloud settings',
  start_cloud_calculate: 'Start cloud calculation',
  filter_error_input_message: 'One or two words are permitted',
  apply_button: 'Apply',
  // placeholders
  channel_select_placeholder: 'Channel',
  //
  word: 'word',
  meets: 'meets',
  cloud_deep: 'Display phrases in the cloud:',
  system: {
    cloud_is_empty: 'The previously calculated phrase cloud is missing, you need to specify the settings and run the calculation.',
    cloud_is_empty_by_request: 'There are no phrases with this word in the phrase cloud.',
    cloud_is_calculating: 'Cloud calculation is performed.The calculation may take a few minutes.After the calculation is finished, the result will be displayed on this page.You can stay on the page until the calculation is finished or return to this section later.',
  }
};
