import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ArrowshapeTurnUpLeftCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_614_2209)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM13.2266 17.4248C13.5781 17.4248 13.8418 17.1611 13.8418 16.8096V15.1221H13.9561C15.6787 15.1221 16.7334 15.6143 17.4717 17.0029C17.6475 17.3281 17.876 17.4072 18.1045 17.4072C18.4209 17.4072 18.667 17.1172 18.667 16.5635C18.667 13.1797 17.1816 11.1143 13.9561 11.1143H13.8418V9.43555C13.8418 9.08398 13.5781 8.80273 13.2178 8.80273C12.9717 8.80273 12.8135 8.9082 12.5498 9.14551L8.86719 12.582C8.68262 12.749 8.6123 12.9336 8.6123 13.1094C8.6123 13.2852 8.68262 13.4785 8.86719 13.6455L12.5498 17.0996C12.7871 17.3193 12.9805 17.4248 13.2266 17.4248Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_614_2209">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ArrowshapeTurnUpLeftCircleIcon;
