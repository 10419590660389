import React, { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CircleButton, ColorInput, Input, Toggle } from '@ui';
import useTranslation from '@app/hooks/use-translation';
type ConditionalFormattingNestedFieldPropsType = {
  nestedFieldIndex: number;
};
const ConditionalFormattingNestedField: FC<ConditionalFormattingNestedFieldPropsType> = (props) => {
  const { nestedFieldIndex } = props;
  const { t } = useTranslation('pages.chartPage');
  // css
  const valuesLabelPathClassName =
    'text-[15px] font-[500] leading-[16.4px] tracking-tight text-0color whitespace-nowrap';
  const fieldAndLabelBlock = 'flex items-center justify-between w-full';
  const firstBlockValueWidth = 'w-[270px]';
  // hook form
  const conditionFormattingDefaultValue = {
    value: [0, 0],
    color: '#c592f7',
  };
  const { control, setValue, register, watch } = useFormContext();
  const {
    fields: conditionalFormattingFields,
    append: appendConditionalFormattingField,
    remove: removeConditionalFormattingField,
    replace: replaceConditionalFormattingField,
  } = useFieldArray({
    name: `settings.data[${nestedFieldIndex}].conditional_formatting`,
    control,
  });
  return (
    <>
      {watch('settings.type') !== 'columnchart' &&
        watch('settings.type') !== 'linechart' &&
        watch('settings.type') !== 'piechart' && (
          <div className="pt-[25px] w-[750px] relative">
            <div className="pl-[25px] flex items-center justify-between">
              <span className={valuesLabelPathClassName}>{t('conditional_formatting')}</span>
              <div className="w-[270px]">
                <Toggle
                  checked={conditionalFormattingFields.length !== 0}
                  onChecked={() =>
                    conditionalFormattingFields.length === 0
                      ? appendConditionalFormattingField(conditionFormattingDefaultValue)
                      : replaceConditionalFormattingField([])
                  }
                />
              </div>
            </div>
            <div className="pt-[25px]">
              {conditionalFormattingFields.map((dataField, dataIndex) => (
                <div
                  key={dataField.id}
                  className="flex pb-[25px] items-start pl-[25px] flex-col gap-[25px]"
                >
                  <div className={fieldAndLabelBlock}>
                    <div className="flex items-center gap-[10px] transition-all duration-[500]"></div>
                    <div className={firstBlockValueWidth}>
                      <div className="flex items-center">
                        <div className="w-[220px]">
                          <div className="flex items-center gap-[5px]">
                            <span className={valuesLabelPathClassName}>
                              {t('conditional_formatting_from')}
                            </span>
                            <Input
                              {...register(
                                `settings.data.${nestedFieldIndex}.conditional_formatting.${dataIndex}.value.0`,
                              )}
                            />
                            <span className={valuesLabelPathClassName}>
                              {t('conditional_formatting_before')}
                            </span>
                            <Input
                              {...register(
                                `settings.data.${nestedFieldIndex}.conditional_formatting.${dataIndex}.value.1`,
                              )}
                            />
                            <ColorInput
                              color={watch(
                                `settings.data.${nestedFieldIndex}.conditional_formatting.${dataIndex}.color`,
                              )}
                              changeColor={(color) =>
                                setValue(
                                  `settings.data.${nestedFieldIndex}.conditional_formatting.${dataIndex}.color`,
                                  color,
                                )
                              }
                            />
                          </div>
                        </div>
                        {/*actions*/}
                        <div className=" transition-all duration-[300] flex items-center">
                          {conditionalFormattingFields.length - 1 === dataIndex && (
                            <div>
                              <CircleButton
                                onClick={() =>
                                  appendConditionalFormattingField(conditionFormattingDefaultValue)
                                }
                                size={14}
                                className="text-action"
                                icon="PlusIcon"
                              />
                            </div>
                          )}
                          <div>
                            <CircleButton
                              onClick={() => removeConditionalFormattingField(dataIndex)}
                              size={12}
                              className="text-basic_red"
                              icon="TrashIcon"
                            />
                          </div>
                        </div>
                        {/*actions*/}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
    </>
  );
};

export default ConditionalFormattingNestedField;
