import React, { FC } from 'react';

import { IconType } from '../icon.type';

const FlagSquareIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_605_1154)">
          <path
            d="M8.67383 21.8721H19.3174C21.2158 21.8721 22.2002 20.8877 22.2002 19.0156V8.31934C22.2002 6.43848 21.2158 5.46289 19.3174 5.46289H8.67383C6.77539 5.46289 5.79102 6.43848 5.79102 8.31934V19.0156C5.79102 20.8965 6.77539 21.8721 8.67383 21.8721ZM8.7793 20.1494C7.96191 20.1494 7.51367 19.7188 7.51367 18.8662V8.45996C7.51367 7.60742 7.96191 7.18555 8.7793 7.18555H19.2119C20.0205 7.18555 20.4775 7.60742 20.4775 8.45996V18.8662C20.4775 19.7188 20.0205 20.1494 19.2119 20.1494H8.7793ZM10.7305 18.3301C10.9854 18.3301 11.1787 18.1279 11.1787 17.873V15.3682C11.3369 15.2979 11.6709 15.21 12.1543 15.21C13.6836 15.21 14.6504 15.9658 16.1621 15.9658C16.8125 15.9658 17.0938 15.8955 17.4365 15.7461C17.8232 15.5703 17.999 15.2979 17.999 14.876V10.6133C17.999 10.3232 17.832 10.1299 17.4541 10.1299C17.1904 10.1299 16.8125 10.3145 16.1182 10.3145C14.668 10.3145 13.6836 9.54102 12.1104 9.54102C11.4424 9.54102 11.1611 9.62012 10.8359 9.76074C10.4668 9.92773 10.2734 10.2178 10.2734 10.6221V17.873C10.2734 18.1191 10.4844 18.3301 10.7305 18.3301Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_605_1154">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default FlagSquareIcon;
