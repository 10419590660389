import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ArrowUturnLeftCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_614_2390)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM18.5352 14.7002C18.5352 12.5908 17.0234 11.29 14.958 11.29H12.3564L11.4072 11.3252L12.0664 10.8154L12.9541 9.97168C13.0859 9.84863 13.165 9.68164 13.165 9.49707C13.165 9.11914 12.8662 8.82031 12.4971 8.82031C12.3037 8.82031 12.1543 8.88184 12.0225 9.02246L9.68457 11.3955C9.53516 11.5449 9.43848 11.7295 9.43848 11.9404C9.43848 12.1514 9.52637 12.3359 9.68457 12.4854L12.0312 14.876C12.1543 15.0078 12.3125 15.0693 12.5059 15.0693C12.8662 15.0693 13.165 14.7705 13.165 14.3926C13.165 14.2256 13.1035 14.041 12.9629 13.918L11.917 12.9424L11.4072 12.5732L12.3564 12.6172H14.958C16.1973 12.6172 17.1289 13.3467 17.1289 14.6387C17.1289 15.957 16.2236 16.7832 14.9492 16.7832H13.7188C13.2969 16.7832 12.9805 17.0293 12.9805 17.46C12.9805 17.8994 13.2969 18.1455 13.7275 18.1455H14.9492C17.0146 18.1455 18.5352 16.8184 18.5352 14.7002Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_614_2390">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ArrowUturnLeftCircleIcon;
