import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { DashboardChartSettingsForGraphData } from '@app/interfaces/dashboards.type';

import { ReportChart } from '@app/interfaces/report.type';

import { baseQuery } from '../baseQuery';

/* Graph Data */
export const chartsGraphDataApi = createApi({
  reducerPath: 'chartsGraphDataApi',
  tagTypes: ['chartsGraphData'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getGraphData: build.query<
      ReportChart,
      { id: string; settings: DashboardChartSettingsForGraphData }
    >({
      query: (body) => ({
        url: serverRoutes.chartData,
        body: { id: body.id, ...body.settings },
        method: 'POST',
      }),
    }),
    getChatGraphData: build.query<
      ReportChart,
      { id: string; settings: DashboardChartSettingsForGraphData }
    >({
      query: (body) => ({
        url: serverRoutes.chatChartData,
        body: { id: body.id, ...body.settings },
        method: 'POST',
      }),
    }),
  }),
});

export const { useLazyGetGraphDataQuery, useGetGraphDataQuery, useLazyGetChatGraphDataQuery } =
  chartsGraphDataApi;
