export const conditionsStyles = {
  padding: '0px 4px 1px 5px',
  border: 'solid',
  borderWidth: '1px',
  display: 'flex',
  alightItems: 'center',
  justifyContent: 'center',
  borderColor: '#A6ABBA',
  borderRadius: '16px',
  fontSize: '15px',
  color: '#3D4858',
  fontWeight: '400',
  lineHeight: '18px',
};
export const inputValueStyle = {
  padding: '2px 8px 3px',
  background: '#F5F5F7',
  borderRadius: '4px',
  marginRight: '10px',
  fontSize: '15px',
  color: '#3D4858',
  fontWeight: '400',
  maxWidth: 'fit-content',
};
