import cn from 'classnames';

export const activeClassName = ({ active, isChat }: { active: boolean; isChat?: boolean }) =>
  cn(
    isChat && 'hover:bg-speech_analitics_light',
    active
      ? isChat
        ? 'bg-blue_border'
        : 'bg-blue_border translate-x-[8px] transition duration-30'
      : isChat && 'bg-gray-50 ',
    'cursor-pointer',
    'px-[6px]',
    'pt-[7px]',
    'pb-[1px]',
    'flex-1',
    'w-full',
    'rounded',
  );

export const chartMessageIconClassName = (cssBg: string, isChat: boolean) =>
  cn(
    cssBg,
    !isChat && 'absolute left-[-40px]',
    'flex',
    'justify-center',
    'items-center',
    'w-[32px]',
    'h-[32px]',
    'rounded-full',
  );
