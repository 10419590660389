import classNames from 'classnames';

export const headerBoxClassName = ({ isOpen = false }: { isOpen: boolean }) =>
  classNames(
    'inline-flex',
    'w-full',
    'justify-between',
    'items-center',
    'px-[26px]',
    'pt-[19px]',
    'pb-[10px]',
    'cursor-pointer',
    isOpen && 'mb-2 border-b-[1px] border-b-[#E5E8EB]',
  );
