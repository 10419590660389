import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { UnsubscribeFromAllError } from '@app/pages/email-confirmation';

export const unsubscribeFromAllErrorRoute = (
  <Route
    path={navigationRoutes.unsubscribeFromAllError + '/*'}
    element={<UnsubscribeFromAllError />}
  />
);
