import React, { FC } from 'react';

import { IconType } from '../icon.type';

const EyeSquareIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_607_7)">
          <path
            d="M8.67383 21.8721H19.3174C21.2158 21.8721 22.2002 20.8877 22.2002 19.0156V8.31934C22.2002 6.43848 21.2158 5.46289 19.3174 5.46289H8.67383C6.77539 5.46289 5.79102 6.43848 5.79102 8.31934V19.0156C5.79102 20.8965 6.77539 21.8721 8.67383 21.8721ZM8.7793 20.1494C7.96191 20.1494 7.51367 19.7188 7.51367 18.8662V8.45996C7.51367 7.60742 7.96191 7.18555 8.7793 7.18555H19.2119C20.0205 7.18555 20.4775 7.60742 20.4775 8.45996V18.8662C20.4775 19.7188 20.0205 20.1494 19.2119 20.1494H8.7793ZM13.9912 17.293C17.4277 17.293 19.7656 14.5156 19.7656 13.6631C19.7656 12.8018 17.4189 10.0244 13.9912 10.0244C10.5811 10.0244 8.2168 12.8018 8.2168 13.6631C8.2168 14.5156 10.5898 17.293 13.9912 17.293ZM13.9912 16.0186C12.6816 16.0186 11.627 14.9375 11.627 13.6631C11.627 12.3447 12.6816 11.2988 13.9912 11.2988C15.3008 11.2988 16.3555 12.3447 16.3555 13.6631C16.3555 14.9375 15.3008 16.0186 13.9912 16.0186ZM14.0088 14.6914C14.5537 14.6914 15.0283 14.2168 15.0283 13.6631C15.0283 13.1094 14.5537 12.626 14.0088 12.626C13.4375 12.626 12.9629 13.1094 12.9629 13.6631C12.9629 14.2168 13.4375 14.6914 14.0088 14.6914Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_607_7">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default EyeSquareIcon;
