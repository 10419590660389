import React, { FC } from 'react';
import { Skeleton } from '@ui';

const SkeletonPageLoading: FC<{ label?: string }> = (props) => {
  const { label } = props;
  return (
    <div>
      <div className="pt-[32px] pb-[25px] inline-flex w-full items-center justify-between pl-[10px]  min-h-[85px]">
        <div className="flex items-center gap-[16px]">
          <Skeleton width={26} height={26} />
          <Skeleton width={240} height={26} />
        </div>
        <Skeleton width={126} height={33} />
      </div>
      {label && (
        <div className="w-full justify-center h-[50px] txt-3color pulse text-[13px]">{label}</div>
      )}
    </div>
  );
};

export default SkeletonPageLoading;
