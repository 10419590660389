import cn from 'classnames';
import { PopoverPosition } from '@app/components/ui/popover/popover.type';

export const popoverMenuClassName = ({
  isOpenPopover,
  position,
}: {
  isOpenPopover: boolean;
  position: PopoverPosition;
}) =>
  cn(
    isOpenPopover
      ? ' transition visible translate-y-[20px] ease-in-out'
      : 'invisible -translate-y-[0px]',
    position === 'start' && '-left-1.5',
    position === 'end' && '-right-1.5',
    'mt-[0px]',
    'w-[247px]',
    'absolute',
    'duration-[80]',
    'shadow-popover',
    'bg-white',
    'py-[8px]',
    // 'w-full',
    'z-[99999]',
    'rounded-[10px]',
  );

export const popoverTriangleClassName = (position: string) =>
  cn(position, 'absolute', 'top-[-20px]');

export const popoverListItemClassName = cn(
  'rounded-[10px]',
  'font-[500]',
  'text-[13px]',
  'px-[10px]',
  'w-full',
  'rounded-[10px]',
  'list-none',
);
export const popoverItemWrapperClassName = ({
  color,
  isNotDanger,
  disabled,
}: {
  color: string;
  isNotDanger: boolean;
  disabled: boolean;
}) =>
  cn(
    'truncate',
    isNotDanger && !disabled && '',
    disabled ? 'text-3color ' : cn(color),
    'flex',
    'rounded-[10px]',
    `cursor-${disabled ? 'not-allowed' : 'pointer'}`,
    'px-[20px]',
    'py-[8px]',
    'h-full',
    'w-full',
    'items-center',
    !disabled && 'hover:bg-b-light',
  );

export const popoverIconClassName = cn(
  'pr-[2px]',
  'w-[32px]',
  'h-[32px]',
  'block',
  'inline-flex',
  'items-center',
  'justify-center',
);
