import React, { FC, useState } from 'react';
import { RecordChannelIcon } from '@icons';
import { Button, EasyModal, Input } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import { DateIntervalKeep } from '@app/pages/reports/blocks/chart-date-picker-modal.block';
import { toast } from 'react-toastify';
import { FilterRangeItem } from '@app/interfaces/filter';

type RecognizeRuleByRangeModalPropsType = {
  changeOpenRecognizeModal: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenRecognizeModal: boolean;
  recognitionRuleName: string;
  onSuccess(range: FilterRangeItem): void;
};
const DEFAULT_TIME_START = '00:00';
const DEFAULT_TIME_END = '23:59';
const DEFAULT_DATE = moment().format('YYYY-MM-DD');
const RecognizeRuleByRangeModal: FC<RecognizeRuleByRangeModalPropsType> = (props) => {
  const { changeOpenRecognizeModal, recognitionRuleName, isOpenRecognizeModal, onSuccess } = props;
  const { t } = useTranslation('pages.settings');
  const [dateInterval, setDateInterval] = useState<DateIntervalKeep>({
    start: DEFAULT_DATE,
    end: DEFAULT_DATE,
    endTime: DEFAULT_TIME_END,
    startTime: DEFAULT_TIME_START,
  });
  function handlerChangePicker({
    date,
    type,
  }: {
    date: Date | null | string;
    type: 'start' | 'end' | 'startTime' | 'endTime';
  }) {
    setDateInterval((prevState) => ({
      ...prevState,
      [type]: type === 'end' || type === 'start' ? moment(date).format('YYYY-MM-DD') : date,
    }));
  }
  function handlerSuccessDateChoose() {
    if (
      dateInterval.start === null ||
      dateInterval.end === null ||
      dateInterval.endTime === null ||
      dateInterval.startTime === null ||
      Number(moment(dateInterval.start).unix()) > Number(moment(dateInterval.end).unix())
    ) {
      toast.info(t('recog_rules.interval_error'));
      return;
    } else {
      const range: FilterRangeItem = {
        type: 'r',
        parameters: [
          {
            value: `${dateInterval.start} ${dateInterval.startTime}`,
            condition: '>=',
          },
          {
            value: `${dateInterval.end} ${dateInterval.endTime}`,
            condition: '<=',
          },
        ],
      };
      onSuccess(range);
      changeOpenRecognizeModal((prev) => !prev);
    }
  }
  return (
    <EasyModal
      onClose={changeOpenRecognizeModal.bind(null, false)}
      withoutFooter
      show={isOpenRecognizeModal}
      withoutContentPadding
      variant={'small'}
      label={
        <div className="flex flex-col gap-[13px] items-center justify-center">
          <div className="w-[57px] h-[57px] rounded-full bg-blue_pal flex items-center justify-center">
            <RecordChannelIcon className="text-action" size={25} />
          </div>
          <span className="text-0color text-[24px] font-[700] leading-[29px]">
            {t('recog_rules.modal_title_recognize')}
          </span>
          <span className={'text-1color text-[18px] font-[700] leading-[21px]'}>
            {recognitionRuleName}
          </span>
        </div>
      }
    >
      <div className="pb-[65px]">
        <div className="flex flex-col justify-center items-center py-[40px] gap-[15px] w-full">
          <div className="flex items-center gap-[15px]">
            <div>{t('recog_rules.from')}</div>
            <DatePicker
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              onChange={(date) => handlerChangePicker({ date, type: 'start' })}
              customInput={
                <div className="w-[150px] inline-flex items-center">
                  <Input
                    name="dateStart"
                    icon="CalendarEmptyIcon"
                    defaultValue={String(dateInterval.start)}
                    disabled
                  />
                </div>
              }
            />
            <div className="w-[100px]">
              <Input
                mask="99:99"
                name="timeStart"
                icon="ClockIcon"
                defaultValue={dateInterval.startTime || DEFAULT_TIME_START}
                onChange={(event) =>
                  handlerChangePicker({ date: event.target.value, type: 'startTime' })
                }
              />
            </div>
          </div>
          <div className="flex items-center gap-[15px]">
            <div>{t('recog_rules.to')}</div>
            <DatePicker
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              onChange={(date) => handlerChangePicker({ date, type: 'end' })}
              customInput={
                <div className="w-[150px] inline-flex items-center">
                  <Input
                    name="dateEnd"
                    icon="CalendarEmptyIcon"
                    defaultValue={String(dateInterval.end)}
                    disabled
                  />
                </div>
              }
            />
            <div className="w-[100px]">
              <Input
                mask="99:99"
                defaultValue={dateInterval.endTime || DEFAULT_TIME_END}
                name="timeEnd"
                onChange={(event) =>
                  handlerChangePicker({ date: event.target.value, type: 'endTime' })
                }
                icon="ClockIcon"
              />
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center items-center">
          <Button
            onClick={handlerSuccessDateChoose}
            className="!px-[45px] !py-[20px]"
            label={t('recog_rules.add_to_recognition_queue')}
          />
        </div>
      </div>
    </EasyModal>
  );
};

export default RecognizeRuleByRangeModal;
