import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { BreadcrumbsItem } from '@app/layouts/layout';
import RequiredAuth from '@app/routes/required-auth.route';
import { ChatAnalyticPage } from '@app/chat-pages';
import {
  ChatAnalyticDetailsLayout,
  NewChatProjectRoute,
} from '@app/chat-pages/chat-analytic-details';
import MainChatSettings from '@app/chat-pages/chat-analytic-details/chat-analytic-main-settings/main-chat-settings';
import { MetricPage, PresetPage, ProjectRecordsPage, ProjectReportsPage } from '@app/pages';
import ProjectReportsOutlet from '@app/pages/analytics/project-reports/project-reports.outlet';
import ProjectChartsPage from '@app/pages/analytics/project-reports/project-charts.page';
import { MetricTriggerPage } from '@app/pages/analytics/metric-trigger';

export const chatAnalyticRoute = (
  <Route
    path={navigationRoutes.chatAnalytic}
    handle={{
      crumb: () => <BreadcrumbsItem path={navigationRoutes.records} crumb="chat_analytic" />,
    }}
  >
    <Route
      index
      element={
        <RequiredAuth>
          <ChatAnalyticPage />
        </RequiredAuth>
      }
    />
    <Route
      path={navigationRoutes.newChatProject}
      element={
        <RequiredAuth>
          <NewChatProjectRoute />
        </RequiredAuth>
      }
    />
    <Route
      path=":id"
      element={
        <RequiredAuth>
          <ChatAnalyticDetailsLayout />
        </RequiredAuth>
      }
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={`${navigationRoutes.analytics}/:id`} crumb="analytic_project" />
        ),
      }}
    >
      <Route
        index
        element={
          <RequiredAuth>
            <MainChatSettings />
          </RequiredAuth>
        }
      />
      <Route path={navigationRoutes.metric} element={<MetricPage />} />
      <Route path={navigationRoutes.presets} element={<PresetPage />} />
      <Route path={navigationRoutes.triggers} element={<MetricTriggerPage />} />
      <Route path={navigationRoutes.reports}>
        <Route
          index
          element={
            <RequiredAuth>
              <ProjectReportsPage currentTemplate="chat" />
            </RequiredAuth>
          }
        />
        <Route path=":report_id" element={<ProjectReportsOutlet />}>
          <Route
            index
            element={
              <RequiredAuth>
                <ProjectChartsPage />
              </RequiredAuth>
            }
          />
        </Route>
      </Route>
      <Route
        path={navigationRoutes.projectRecords}
        element={
          <RequiredAuth>
            <ProjectRecordsPage />
          </RequiredAuth>
        }
      />
    </Route>
  </Route>
);
