import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const NumberIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 22 17"
      >
        <path
          fill="currentColor"
          d="M3.468 16.863h15.055c1.899 0 2.883-.975 2.883-2.847V3.31c0-1.873-.984-2.848-2.883-2.848H3.468C1.569.463.585 1.43.585 3.31v10.705c0 1.872.984 2.847 2.883 2.847zm.105-1.722c-.817 0-1.265-.422-1.265-1.275V3.46c0-.853.448-1.283 1.265-1.283h14.845c.809 0 1.266.43 1.266 1.283v10.406c0 .853-.457 1.275-1.266 1.275H3.573zm12.235-2.997c1.573 0 2.619-.8 2.619-2.022 0-.87-.65-1.556-1.53-1.635V8.47c.721-.106 1.301-.774 1.301-1.556 0-1.055-.967-1.802-2.329-1.802-1.178 0-2.118.554-2.338 1.415a1.059 1.059 0 00-.044.334c0 .308.22.528.563.528.281 0 .457-.123.562-.387.167-.527.624-.835 1.24-.835.685 0 1.107.343 1.107.888 0 .562-.475.966-1.134.966h-.43c-.3 0-.519.211-.519.501 0 .3.22.51.519.51h.465c.783 0 1.31.413 1.31 1.028 0 .616-.536 1.02-1.336 1.02-.66 0-1.143-.299-1.327-.826-.132-.273-.299-.387-.58-.387-.343 0-.572.229-.572.563 0 .105.018.175.044.29.211.843 1.213 1.424 2.409 1.424zm-9.853-.01c.404 0 .65-.263.65-.694V5.833c0-.457-.263-.712-.703-.712-.316 0-.5.088-.843.316l-1.319.88c-.176.122-.255.263-.255.448 0 .263.211.474.457.474.123 0 .203-.017.387-.14l.914-.607h.07v4.948c0 .422.247.695.642.695zm2.452-.105h3.797a.528.528 0 00.554-.545c0-.325-.229-.544-.554-.544l-2.575.008v-.035l1.687-1.767c.677-.703 1.134-1.3 1.134-2.091 0-1.17-.923-1.934-2.346-1.934-1.055 0-2.022.615-2.268 1.485-.026.115-.035.211-.035.317 0 .325.21.536.545.536.29 0 .457-.158.589-.404.175-.44.544-.87 1.213-.87.65 0 1.098.378 1.098.949 0 .492-.413.931-.844 1.38l-2.346 2.443c-.159.15-.246.316-.246.519 0 .334.237.553.597.553z"
        ></path>
      </svg>
    </div>
  );
};

export default NumberIcon;
