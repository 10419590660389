import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  CreateDictionaryType,
  DictionariesType,
  DictionaryType,
} from '@app/interfaces/slices-types/dictionaries-slice.type';

import { baseQuery } from '../baseQuery';

export const dictionariesApi = createApi({
  reducerPath: 'dictionariesApi',
  tagTypes: ['dictionaries', 'dictionary'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getAllDictionariesList: build.query<DictionariesType[], void>({
      query: () => ({
        url: serverRoutes.dictionaries,
      }),
      transformResponse: (baseQueryReturnValue: { dictionaries: DictionariesType[] }) =>
        baseQueryReturnValue.dictionaries,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ dictionary_id }) => ({
                type: 'dictionaries' as const,
                dictionary_id,
              })),
              'dictionaries',
            ]
          : ['dictionaries'],
    }),
    getDictionary: build.query<DictionaryType, { id: string }>({
      query: (params) => ({
        url: serverRoutes.dictionary,
        params,
      }),
      providesTags: ['dictionary'],
    }),
    deleteDictionary: build.mutation({
      query: (params: { id: string }) => ({
        url: serverRoutes.dictionary,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['dictionaries'],
    }),
    createDictionary: build.mutation<DictionaryType, CreateDictionaryType>({
      query: (body) => ({
        url: serverRoutes.dictionary,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['dictionaries'],
    }),
    editDictionary: build.mutation<
      DictionaryType,
      { body: CreateDictionaryType; params: { id: string } }
    >({
      query: ({ body, params }) => ({
        url: serverRoutes.dictionary,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['dictionaries', 'dictionary'],
    }),
  }),
});

export const {
  useGetAllDictionariesListQuery,
  useDeleteDictionaryMutation,
  useGetDictionaryQuery,
  useCreateDictionaryMutation,
  useEditDictionaryMutation,
} = dictionariesApi;
