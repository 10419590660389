import React from 'react';
import { Outlet } from 'react-router-dom';
import { PageHeader } from '@ui';

import useTranslation from '@app/hooks/use-translation';

import SettingsNavigationBlock from './blocks/settings-navigation.block';

const SettingsOutlet = () => {
  const { t } = useTranslation('pages.settings');
  return (
    <div>
      <PageHeader label={t('title')} icon="SettingIcon" />
      <SettingsNavigationBlock />
      <Outlet />
    </div>
  );
};

export default SettingsOutlet;
