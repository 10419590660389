// const records = require('./records');
// const projectRecords = require('./projectRecords');
// const dashboard = require('./dashboard');
const presetsTemplatesEn = require('./presets-templates');
const notFoundEn = require('./notFound');
const authorizationEn = require('./authorization');
const reportsEn = require('./reports');
const chartPageEn = require('./chartPage');
const usersPageEn = require('./users');
const settingsEn = require('./settings');
const chatMetricTeaplatesEn = require('./chat_metric_template')
const chatAnalyticsEn = require('./chat_analytics')
const compositeMetricEn = require('./composite-metric');
const metricTemplatesEn = require('./analytics-project-metric-and-templates');
const dictionariesEn = require('./dictionaries');
const analyticsEn = require('./analytics');
const wordsCloudEn = require('./wordsCloud');
const analyticsProjectPresetEn = require('./analytics-project-preset');
const analyticsProjectRecordsEn = require('./analytics-project-records');
const analyticsProjectMainSettingsEn= require('./analytics-project-main-settings');
const wordsNGrammEn = require('./wordsNGramm');
const auditLogsEn = require('./auditLogs');
const dashboardPageEn = require('./dashboard')
const allRecordsEn = require('./all-records')
const allChatsEn = require('./all-chat')
const recordsByAnalyticsEn = require('./records-by-analytics-projects')
const triggersEn = require('./triggers')

module.exports = {
  dashboard:dashboardPageEn,
  allChats:allChatsEn,
  notFound:notFoundEn,
  allRecords:allRecordsEn,
  settings:settingsEn,
  chartPage:chartPageEn,
  reports:reportsEn,
  chatMetricTeaplates:chatMetricTeaplatesEn,
  chatAnalytics:chatAnalyticsEn,
  auditLogs:auditLogsEn,
  dictionaries:dictionariesEn,
  wordsNGramm:wordsNGrammEn,
  usersPage:usersPageEn,
  wordsCloud:wordsCloudEn,
  analytics:analyticsEn,
  authorization:authorizationEn,
  presetAp:analyticsProjectPresetEn,
  recordsAp:analyticsProjectRecordsEn,
  mainSettingsAp:analyticsProjectMainSettingsEn,
  metric:compositeMetricEn,
  metricTemplates:metricTemplatesEn,
  recordsByAnalytics:recordsByAnalyticsEn,
  presets:presetsTemplatesEn,
  triggers:triggersEn
  // projectRecords,
  // dashboard,
  // records,
};
