import React, { FC } from 'react';

import { IconType } from '../icon.type';

const BubbleRightCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_813_299)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM16.1533 18.6641C16.4082 18.6641 16.5488 18.4883 16.5488 18.2158V17.0029H16.7334C17.9463 17.0029 18.6758 16.2734 18.6758 15.0254V11.7471C18.6758 10.5078 17.9639 9.77832 16.707 9.77832H11.3018C10.0361 9.77832 9.32422 10.5078 9.32422 11.7471V15.0254C9.32422 16.2734 10.0361 17.0029 11.3018 17.0029H14.0879L15.6523 18.4004C15.8545 18.5938 15.9775 18.6641 16.1533 18.6641Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_813_299">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default BubbleRightCircleIcon;
