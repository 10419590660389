import cn from 'classnames';

import { ModalProps } from './Modal.type';

export const heightModalHeader = '75px';
export const heightModalFooter = '107px';
export const modalMaxHeight: Record<Required<ModalProps>['size'], string> = {
  xl: '90vh',
  lg: '85vh',
  md: '60vh',
  xs: '35vh',
  soSmall: '25vh',
  mdForMeta: '440px',
};
export const modalMinHeight: Record<Required<ModalProps>['size'], string> = {
  xl: '650px',
  lg: '550px',
  md: '450px',
  xs: '350px',
  soSmall: '320px',
  mdForMeta: '440px',
};

export function getModalBackgroundClassName(duration) {
  return cn(
    'fixed',
    'inset-0',
    'bg-black/[.6]',
    'z-[100]',
    'transition',
    duration && `duration-[${duration}ms]`,
    '!overflow-hidden',
  );
}

export function getModalWindowWrapperClassName() {
  return cn('relative', 'w-full', 'h-full', 'flex', 'justify-center', 'overflow-y-auto');
}

export function getModalWindowClassName(size: ModalProps['size'] = 'lg') {
  return cn(
    'absolute',
    size === 'xl' &&
      `w-[1127px] min-h-[${modalMinHeight[size]}] max-h-[${modalMaxHeight[size]}] top-[46px]`,
    size === 'lg' &&
      `w-[1047.2px] min-h-[${modalMinHeight[size]}] max-h-[${modalMaxHeight[size]}] top-[46px]`,
    size === 'md' &&
      `w-[650px] min-h-[${modalMinHeight[size]}] max-h-[${modalMaxHeight[size]}] top-[92px]`,
    size === 'xs' &&
      `w-[484px] min-h-[${modalMinHeight[size]}] max-h-[${modalMaxHeight[size]}] top-[150px]`,
    size === 'soSmall' &&
      `w-[227px] min-h-[${modalMinHeight[size]}] max-h-[${modalMaxHeight[size]}] top-[150px]`,
    'bg-white',
    size === 'mdForMeta' && `w-[650px] min-h-[${modalMinHeight['md']}] max-h-[440px] top-[92px]`,
    'bg-white',
    'rounded-defaultR',
  );
}

export function getModalWindowInternalWrapperClassName(noFooter, size) {
  return cn(
    'relative',
    'w-full',
    'h-full',
    'rounded-defaultR',
    '!overflow-hidden',
    noFooter && size === 'soSmall' ? 'pb-[20px]' : 'pb-[50px]',
  );
}

export function getModalWindowCloseBtnClassName() {
  return cn('absolute', 'top-[21px]', 'right-[17px]', 'cursor-pointer', 'z-10');
}

export function getModalHeaderClassName(variant: ModalProps['variant'] = 'regular') {
  return cn(
    variant === 'regular' && `pl-[42px] py-[37px] pb-[15px] h-[${heightModalHeader}]`,
    variant === 'removal' && 'py-[25px] text-center',
    'text-0color',
    'text-2xl',
    'font-bold',
  );
}

export function getModalBodyClassName(
  variant: ModalProps['variant'] = 'regular',
  size: ModalProps['size'] = 'lg',
) {
  return cn(
    'relative',
    'h-full',
    variant === 'regular' &&
      (size === 'lg'
        ? 'py-[24px] px-[32px] mr-[40px] ml-[32px]'
        : size === 'md'
        ? 'px-[42px]'
        : ''),
    variant === 'removal' && 'pb-[33px] text-center',
  );
}

export function getModalFooterClassName(varinat: ModalProps['variant'] = 'regular') {
  return cn(
    varinat === 'regular' &&
      // absolute inset-x-0 bottom-0
      `border-t border-t-b_medium px-[42px] py-[32px] rounded-b-defaultR h-[${heightModalFooter}]`,
    varinat === 'removal' && 'text-center flex-col',
    'flex',
    'items-center',
    'bg-white',
  );
}
