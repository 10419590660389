import React, { FC } from 'react';

import { IconType } from '../icon.type';

const Flag2CrossedIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_605_1158)">
          <path
            d="M17.7803 20.1689L10.5205 7.59766C10.2129 7.06445 9.76855 6.90039 9.25586 6.94824C8.83203 7.00293 8.27832 7.21484 7.75195 7.52246C6.07715 8.49316 5.40723 10.0176 3.80078 10.9404C3.10352 11.3438 2.48145 11.501 2.17383 11.6787C1.76367 11.918 1.5791 12.335 1.8252 12.7588L4.57324 17.5166C4.88086 18.0498 5.3252 18.207 5.83789 18.1592C6.26172 18.1182 6.81543 17.8926 7.3418 17.585C9.0166 16.6143 9.68652 15.0967 11.2861 14.167C11.7783 13.8867 12.2295 13.7295 12.5234 13.6133L16.6797 20.8047C16.8506 21.1123 17.2402 21.2217 17.5479 21.0439C17.8486 20.8662 17.958 20.4697 17.7803 20.1689ZM14.499 12.752L15.2373 14.0303L15.4697 13.6133C15.7705 13.7295 16.2148 13.8867 16.707 14.167C18.3066 15.0967 18.9766 16.6143 20.6582 17.585C21.1777 17.8926 21.7314 18.1182 22.1553 18.1592C22.668 18.207 23.1191 18.0498 23.4268 17.5166L26.168 12.7588C26.4141 12.335 26.2363 11.918 25.8262 11.6787C25.5117 11.501 24.8896 11.3438 24.1924 10.9404C22.5928 10.0176 21.9229 8.49316 20.2412 7.52246C19.7217 7.21484 19.168 7.00293 18.7373 6.94824C18.2246 6.90039 17.7871 7.06445 17.4727 7.59766L14.499 12.752ZM11.8604 12.4717C11.4707 12.5947 10.999 12.8613 10.6504 13.0596C8.85938 14.0918 8.25781 15.5889 6.69238 16.4844C6.53516 16.5801 6.09082 16.8262 5.66699 16.8535L3.2334 12.6357C3.62988 12.5195 4.09473 12.2529 4.44336 12.0547C6.23438 11.0225 6.83594 9.53223 8.39453 8.62988C8.55859 8.53418 9.00293 8.28125 9.42676 8.25391L11.8604 12.4717ZM16.1328 12.4717L18.5664 8.25391C18.9902 8.28125 19.4346 8.53418 19.5986 8.62988C21.1641 9.53223 21.7656 11.0225 23.5566 12.0547C23.8984 12.2529 24.3701 12.5195 24.7666 12.6357L22.3262 16.8535C21.9092 16.8262 21.4648 16.5801 21.3008 16.4844C19.7354 15.5889 19.1338 14.0918 17.3428 13.0596C17.001 12.8613 16.5293 12.5947 16.1328 12.4717ZM13.501 17.0312L12.7627 15.7529L10.2129 20.1689C10.042 20.4697 10.1445 20.8662 10.4521 21.0439C10.7598 21.2217 11.1426 21.1123 11.3203 20.8047L13.501 17.0312Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_605_1158">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Flag2CrossedIcon;
