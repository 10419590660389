import React, { FC } from 'react';

import { IconType } from '../icon.type';

const PersonalhotspotCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_813_269)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM8.19922 12.7842C8.19922 14.2168 9.20996 15.2275 10.6426 15.2275H10.915C10.8535 14.9814 10.8271 14.5508 10.8623 14.2607H10.6953C9.8252 14.2607 9.22754 13.6631 9.22754 12.7842C9.22754 11.9053 9.8252 11.2988 10.6953 11.2988H14.167C15.0371 11.2988 15.6348 11.9053 15.6348 12.7842C15.6348 13.6631 15.0371 14.2607 14.167 14.2607H12.8926C12.7871 14.5156 12.8223 14.9727 13.1035 15.2275H14.2109C15.6348 15.2275 16.6543 14.2168 16.6543 12.7842C16.6543 11.3516 15.6348 10.3232 14.2109 10.3232H10.6426C9.21875 10.3232 8.19922 11.3516 8.19922 12.7842ZM11.3369 14.6035C11.3369 16.0361 12.3564 17.0557 13.7803 17.0557H17.3486C18.7725 17.0557 19.792 16.0361 19.792 14.6035C19.792 13.1709 18.7812 12.1514 17.3486 12.1514H17.0762C17.1289 12.3975 17.1729 12.6963 17.1201 13.127H17.2871C18.166 13.127 18.7637 13.7246 18.7637 14.6035C18.7637 15.4736 18.166 16.0889 17.2871 16.0889H13.8242C12.9541 16.0889 12.3564 15.4736 12.3564 14.6035C12.3564 13.7246 12.9453 13.127 13.8242 13.127H15.0811C15.2041 12.8721 15.1689 12.3887 14.8877 12.1514H13.7803C12.3477 12.1514 11.3369 13.1709 11.3369 14.6035Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_813_269">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default PersonalhotspotCircleIcon;
