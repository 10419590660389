import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ArrowDownAppIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_606_10)">
          <path
            fill="currentColor"
            d="M14 22.733c4.966 0 9.08-4.104 9.08-9.079 0-4.966-4.114-9.079-9.089-9.079-4.966 0-9.07 4.113-9.07 9.08 0 4.974 4.113 9.078 9.079 9.078zm0-1.784a7.27 7.27 0 01-7.286-7.295 7.255 7.255 0 017.277-7.286 7.283 7.283 0 017.304 7.286A7.277 7.277 0 0114 20.95zm-3.797-4.21h7.594c.474 0 .764-.255.764-.641 0-.501-.456-.923-.887-1.345-.325-.316-.396-.958-.44-1.52a8.492 8.492 0 00-.061-.835 1.893 1.893 0 01-.8.167c-1.257 0-2.312-1.038-2.312-2.303 0-.59.22-1.108.59-1.512A1.35 1.35 0 0014 8.583c-.694 0-1.204.483-1.406 1.107-1.23.449-1.75 1.68-1.81 3.437-.054.615-.124 1.3-.458 1.626-.422.43-.888.844-.888 1.345 0 .386.29.641.765.641zm6.17-4.834a1.65 1.65 0 001.635-1.643 1.643 1.643 0 00-3.287 0c0 .905.747 1.643 1.652 1.643zM14 18.787c.835 0 1.45-.589 1.503-1.3h-3.006c.053.711.668 1.3 1.503 1.3z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_606_10">
            <path fill="#fff" d="M0 0H28V28H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ArrowDownAppIcon;
