import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { AllChanelsArrType } from '@app/interfaces/pages-types/anatylics-metric.type';

import { baseQuery } from '../baseQuery';
export const channelsInfoApi = createApi({
  reducerPath: 'channelsInfoApi',
  tagTypes: ['channelsInfo'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getChannels: build.query<AllChanelsArrType[], void>({
      query: () => ({
        url: serverRoutes.audioChannels,
      }),
    }),
  }),
});

export const { useGetChannelsQuery } = channelsInfoApi;
