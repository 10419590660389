module.exports = {
  popup_hints: {
    settings_button: 'Set up',
    delete_button: 'Delete',
    recalculate_metric: 'Recalculate metrics',
    templates_list_count: 'Number of templates in group',
    settings_template_icon: 'Set up template',
    delete_template_icon: 'Delete template',
    copy_template_icon: 'Copy template',
    copy_id: 'Copy ID'
  },
  system: {
    err403: 'A metric cannot be deleted because it is used in a composite metric or a manual metric.'
  },
  cant_create_group: 'Cannot create a group without a name',
  add_metric_template: 'Add metric template',
  metric_templates: 'Metrics templates',
  group_name: 'Group name',
  new_group: 'New group',
  recalculate: 'Recalculate for all records',
  recalculate_manual_metric_only: 'Recalculate only for records with manual metric changes',
  all: 'All',
  none_settings: 'No settings',
  first_secs: 'First N seconds',
  last_secs: 'Last N seconds',
  pause_duration: 'Pause duration threshold',
  warning: 'Warning',
  metric: 'Metrics',
  add_metric: 'Add metric',
  group_delete_confirm: 'Do you really want to delete this group?',
  metric_delete_confirm:
    'Deleting the metric will affect the work of this analytics project and the reports in it. Do you really want to delete this metric?',
  table: {
    name: 'NAME',
    type: 'METRIC TYPE',
    channel: 'CHANNEL OF SEARCH',
    settings: 'METRIC SETTINGS',
    actions: 'Actions',
  },
  meta_metrics: 'Meta metrics',
  vad_metrics: 'VAD metrics',
  chat_metrics: 'CHAT metrics',
  speech_metrics: 'SPEECH metrics',
  other_metrics: 'Other',
  help: 'Help',
  add: 'Add',
  cancel: 'Cancel',
  add_template: 'A metric will be added to the list of metric templates with the name',
  fill_all: 'Fill in all fields',
  metric_group: 'Metrics group',
  metric_id: 'metrics ID:',
  project_id: 'Project ID',
  copy_hint: 'Copy ID',
  one_metric: 'metric',
  metric_templates_group: 'Metrics template group',
  choose: 'Choose',
  channel_search: 'Channel of search',
  classifier_model: 'Classifier model',
  classifier_theme: 'Classifier theme',
  probably: "Probability",
  summ_silent: 'Unit of measure for total duration of silence',
  threshold: 'Pause duration threshold, (sec.)',
  save: 'Save',
  save_as_template: 'Save as template',
  all_words: 'Consider all word forms',
  color: 'Backlight color',
  alias: 'Metadata alias',
  input: 'Input',
  secs: 'Seconds',
  role: 'Roles',
  metric_create: 'Metric creation',
  clear_all: 'Clear all',
  from_template: 'Select from the template list',
  from_template_add: 'Add metric from template',
  add_new_template: 'Add new metric template',
  add_new_metric: 'Add new metric',
  metric_create_new: 'Create new metric',
  metric_name: 'Metric name',
  metric_description: 'Metric description',
  exclude_begin_sec: 'Exclude first N seconds from analysis',
  exclude_end_sec: 'Exclude last N seconds from analysis',
  exclude_begin_messages: 'Exclude first N phrases from analysis',
  exclude_end_messages: 'Exclude last N phrases from analysis',
  summ_value: 'Unit of measurement for total speech duration',
  meta: 'Transfer metadata values to metrics so that this data can be used in reports.',
  summ_speech_duration:
    'Shows how long the selected speaker spoke. The time of pure conversation without pauses is taken into account.',
  summ_silent_duration: 'Shows duration of all pauses in selected channel during recording.',
  interruptions:
    'Indicates number of episodes of interruptions. Interrupt is to start speaking when someone else is speaking. In this case there is an overlap of speech, i.e. two or more speakers are speaking at the same time.',
  pause_exceed:
    'Shows the number of pauses that exceed the duration specified in the metric settings.',
  avg_pause_duration: 'Shows average duration of pauses in selected channel.',
  max_pause_duration: 'Shows the duration of the longest pause in the selected channel.',
  find_words:
    'Shows the number of matches found with a given set of words/phrases in a given channel.',
  max_monolog_duration: 'Shows the duration of the longest part of the selected speakers speech.',
  tags_metric: 'Allows to manually enter values for records. Values can be specified in free form or selected from a specified list.',
  speech_rate_duration:
    'Allows to measure the pace at which the speakers are talking. The results of the metric for the recording indicate the average speech rate in the selected channel.',
  calculated:
    'Shows the result calculated using the specified formula. The result can be either numeric or text. Data from other metrics are used in the calculation. This metric is calculated only after all other metrics within this Analytical Project have been calculated.',
  success: 'Successfully',
  error: 'Failed',
  recalculate_modal:'Attention! Metrics recalculation is performed only when the project status is “Active”.' +
    'All metrics on the records below will be recalculated, except for “Manual Metrics” values modified by users.' +
    'This process may take a long time. Until the recalculation is complete, the project metrics will be reset to zero',
  group_delete: 'Do you really want to delete this group?',
  template_delete: 'Do you really want to delete this template?',
  manualMetric: {
    number: 'Number',
    text: 'Text',
    manual_entry: 'Manual entry',
    select_from_list: 'Select from list',
    pictogram: 'Pictogram',
    value: 'Value',
    both: 'Both',
    toast_edit_success_message: 'Metric edited',
    toast_edit_error_message: 'Failed to edit metric',
    toast_create_success_message: 'Metric was adeed',
    toast_create_error_message: 'Failed to add metric',
    toast_upDate_success_message: 'Value updated',
    toast_upDate_error_message: 'Failed to update the value',
    available_variant_list: 'List of available options',
    allow_multiple_selection: 'Allow multiple selections',
    display_type: 'Display type',
    by_default: 'By default',
    field_required: 'Field not filled in', 
    bad_format: 'Invalid format, characters are prohibited',
    placeholder: 'Enter value',
    add_field_to_search: 'Add a row to the list',
    value_by_default: 'Value by default',
    or: 'or',
    value_from_metric: 'Value from metric',
    metric_name: 'Metric name',
    name: 'Name',
    value_type: 'Value type',
    input_method: 'Input method',
    description: 'Description',
    fill_metric_details: 'Fill in metrics details',
    save: 'Save',
    cancel: 'Cancel'
  }
};
