import React, { FC } from 'react';

import { IconType } from '../icon.type';

const StethoscopeCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_813_315)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM13.332 18.3828C14.6416 18.3828 15.3711 17.4336 15.7139 15.3418C15.9248 14.0674 16.2148 13.584 16.7246 13.584C17.2168 13.584 17.4893 14.041 17.5771 15.0781C17.0498 15.2891 16.6807 15.8076 16.6807 16.4141C16.6807 17.2227 17.3223 17.8643 18.1309 17.8643C18.9395 17.8643 19.5898 17.2139 19.5898 16.4141C19.5898 15.7725 19.1592 15.2275 18.5791 15.043C18.4121 13.373 17.8232 12.582 16.7334 12.582C15.626 12.582 15.0283 13.3379 14.7119 15.1045C14.4395 16.7217 14.0967 17.3721 13.3145 17.3721C12.6113 17.3721 12.1895 16.6514 12.1279 15.333C13.4199 14.9463 14.7998 12.8633 14.7998 11.1318C14.7998 10.1475 14.2812 9.61133 13.3232 9.61133H13.2793C13.1387 9.44434 12.9277 9.33887 12.6992 9.33887C12.2773 9.33887 11.9258 9.68164 11.9258 10.1123C11.9258 10.543 12.2686 10.8945 12.6992 10.8945C12.9277 10.8945 13.1299 10.7891 13.2793 10.6309H13.3496C13.6484 10.6309 13.7891 10.7891 13.7891 11.1318C13.7891 12.4941 12.5322 14.3838 11.6182 14.3838C10.7129 14.3838 9.45605 12.4941 9.45605 11.1318C9.45605 10.7803 9.59668 10.6309 9.89551 10.6309H9.95703C10.0977 10.7891 10.3086 10.8945 10.5371 10.8945C10.9678 10.8945 11.3105 10.543 11.3105 10.1123C11.3105 9.68164 10.9678 9.33887 10.5371 9.33887C10.3086 9.33887 10.0977 9.44434 9.94824 9.61133H9.92188C8.96387 9.61133 8.44531 10.1475 8.44531 11.1318C8.44531 12.8545 9.81641 14.9463 11.1172 15.333C11.2051 17.2842 11.9873 18.3828 13.332 18.3828ZM18.1309 16.959C17.832 16.959 17.5947 16.7129 17.5947 16.4141C17.5947 16.1152 17.832 15.8779 18.1309 15.8779C18.4297 15.8779 18.6758 16.1152 18.6758 16.4141C18.6758 16.7129 18.4297 16.959 18.1309 16.959Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_813_315">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default StethoscopeCircleIcon;
