import React, { FC } from 'react';

import { IconType } from '../icon.type';

const LightbulbCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_813_433)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM12.374 16.5283H15.6084C15.7842 16.5283 15.8984 16.4229 15.8984 16.2559V15.5703C15.8984 14.498 17.4541 13.8213 17.4541 11.8877C17.4541 9.83105 16.0654 8.45117 13.9912 8.45117C11.917 8.45117 10.5195 9.83105 10.5195 11.8877C10.5195 13.8213 12.084 14.498 12.084 15.5703V16.2559C12.084 16.4229 12.1982 16.5283 12.374 16.5283ZM12.5059 17.75H15.4766C15.7139 17.75 15.9072 17.5566 15.9072 17.3281C15.9072 17.082 15.7139 16.8975 15.4766 16.8975H12.5059C12.2686 16.8975 12.0752 17.082 12.0752 17.3281C12.0752 17.5566 12.2686 17.75 12.5059 17.75ZM13.9912 18.8486C14.624 18.8486 15.0811 18.5586 15.125 18.1104H12.8486C12.8926 18.5586 13.3496 18.8486 13.9912 18.8486Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_813_433">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default LightbulbCircleIcon;
