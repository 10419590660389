import React, { FC } from 'react';

import { IconType } from '../icon.type';

const BooksVerticalCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_813_251)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM15.1426 17.8379H16.0566C16.4082 17.8379 16.6016 17.6445 16.6016 17.3018V12.7754L17.5508 17.416C17.6123 17.7412 17.8408 17.9082 18.1836 17.8379L18.7725 17.6973C19.1064 17.627 19.2559 17.3984 19.1943 17.0557L17.9111 10.7188C17.8408 10.376 17.6211 10.2002 17.2783 10.2793L16.6895 10.4287C16.6543 10.4287 16.6279 10.4375 16.6016 10.4463V9.64648C16.6016 9.29492 16.4082 9.10156 16.0566 9.10156H15.1426C14.7822 9.10156 14.5977 9.29492 14.5977 9.64648V17.3018C14.5977 17.6445 14.7822 17.8379 15.1426 17.8379ZM9.5791 17.8379H10.3086C10.6602 17.8379 10.8535 17.6445 10.8535 17.3018V10.7451C10.8535 10.4023 10.6602 10.209 10.3086 10.209H9.5791C9.21875 10.209 9.02539 10.4023 9.02539 10.7451V17.3018C9.02539 17.6445 9.21875 17.8379 9.5791 17.8379ZM11.9521 17.8379H13.4902C13.8418 17.8379 14.0264 17.6445 14.0264 17.3018V12.1338C14.0264 11.791 13.8418 11.5801 13.4902 11.5801H11.9521C11.6094 11.5801 11.4072 11.791 11.4072 12.1338V17.3018C11.4072 17.6445 11.6094 17.8379 11.9521 17.8379ZM12.3125 13.0566C12.1543 13.0566 12.0488 12.9248 12.0488 12.7842C12.0488 12.6348 12.1543 12.5117 12.3125 12.5117H13.1475C13.3057 12.5117 13.4111 12.6348 13.4111 12.7842C13.4111 12.9248 13.2969 13.0566 13.1475 13.0566H12.3125ZM12.3125 16.915C12.1543 16.915 12.0488 16.8008 12.0488 16.6426C12.0488 16.4844 12.1543 16.3701 12.3125 16.3701H13.1475C13.2969 16.3701 13.4111 16.4844 13.4111 16.6426C13.4111 16.8008 13.2969 16.915 13.1475 16.915H12.3125Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_813_251">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default BooksVerticalCircleIcon;
