import React from 'react';
import { Skeleton, SkeletonPageLoading } from '@ui';

const DashboardPageSkeleton = () => {
  return (
    <div>
      <SkeletonPageLoading />
      <div className=" w-full flex items-center gap-[16px] justify-end py-[24px] border-[#E5E8EB] border-t-[1px]">
        <Skeleton width={181} height={38} />
        <Skeleton width={73} height={38} />
        <Skeleton width={41} height={38} />
      </div>
      <div className="min-w-full justify-between flex gap-[10px] flex-wrap">
        {Array(6)
          .fill('_')
          .map((_, index) => (
            <div key={index} className="basis-[calc(50%-5px)]">
              <Skeleton height={360} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default DashboardPageSkeleton;
