import React, { FC } from 'react';

import { IconType } from '../icon.type';

const LeafCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_304)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM9.74609 10.3584C9.67578 10.7539 9.61426 11.2812 9.61426 11.6328C9.61426 14.5684 11.3721 16.5107 14.0439 16.5107C15.3623 16.5107 16.1445 15.9219 16.5312 15.4912C16.8477 15.9307 17.0234 16.4141 17.2344 17.0645C17.2871 17.249 17.4277 17.3281 17.5684 17.3281C17.8672 17.3281 18.1133 17.0645 18.1133 16.7129C18.1133 16.168 17.3311 15.2275 16.918 14.8496C15.1865 13.2588 12.5762 14.208 11.9258 12.459C11.873 12.3359 12.0049 12.2393 12.1279 12.3447C13.5605 13.5664 15.2217 12.5908 16.8916 14.1113C17.041 14.252 17.2168 14.1816 17.252 14.0322C17.2695 13.9092 17.2783 13.7334 17.2783 13.5664C17.2783 11.6592 15.96 10.71 14.0615 10.71C13.4287 10.71 12.6904 10.8682 12.1191 10.8682C11.5039 10.8682 10.8096 10.8066 10.2383 10.2266C10.0537 10.0508 9.79883 10.0771 9.74609 10.3584Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_304">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default LeafCircleIcon;
