import React, { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import {
  GroupBase,
  components,
  MultiValueRemoveProps,
  ValueContainerProps,
  MultiValueGenericProps,
  IndicatorsContainerProps,
  MenuProps,
  OptionProps,
} from 'react-select';

import Select from 'react-select/dist/declarations/src/Select';

import { v4 } from 'uuid';

import { MetricListArrType } from '@app/interfaces/pages-types/anatylics-metric.type';

import { FilterItem } from '@app/interfaces/filter';

import { cloneDeep } from 'lodash';

import useTranslation from '@app/hooks/use-translation';

import { CircleButton } from '../ui';
import Icon from '../ui/icons';

import { TriangleBottomIcon } from '../ui/icons/icons-list';

import { conditions } from './blocks/data/data';
import { inputValueStyle } from './blocks/styles/styles';
import { FilterValuesType, ShowValuesType } from './types/types';
type inputValuesArrayType = { value: string; label: string; front_id: string };
type SelectUniversalFilterTypes = {
  results: Array<MetricListArrType & { front_id: string }>;
  onChangeReturnFilter?: (filter: FilterItem[]) => void;
  isPending?: boolean;
};
const SelectUniversalFilter: FC<SelectUniversalFilterTypes> = (props) => {
  const { t } = useTranslation('components.selectUniversalFilter');
  const { results, onChangeReturnFilter, isPending } = props;
  const [deletableElem, setDeletableElem] = useState<string>('false');
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [cacheInputValue, setCacheInputValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [inputValuesArray, setInputValuesArray] = useState<inputValuesArrayType[]>([]);
  const [values, setValues] = useState<ShowValuesType[]>([]);
  const [showValues, setShowValues] = useState<ShowValuesType[]>([]);
  const [filterValues, setFilterValues] = useState<FilterValuesType[]>([]);
  const selectRef = useRef<Select<any, true, GroupBase<unknown>>>(null);

  function resetAllFilterValues() {
    setStep(1);
    setInputValue('');
    setInputValuesArray([]);
    setValues([]);
    setShowValues([]);
    setFilterValues([]);
    onChangeReturnFilter?.([]);
    setMenuIsOpen(false);

    isPending && focusInput();
  }
  function stepsCalcFunc() {
    setStep((prev) => (prev === 1 ? 2 : prev === 3 ? 1 : prev + 1));
  }
  function findFilterIndex(front_id: string) {
    if (filterValues.length === 0) return;
    return filterValues.findIndex((filter) => {
      if (Array.isArray(filter.value.front_id)) {
        return (
          filter.condition.front_id === front_id ||
          filter.value.front_id.includes(front_id) ||
          filter.id.front_id === front_id
        );
      } else {
        return (
          filter.condition.front_id === front_id ||
          filter.value.front_id === front_id ||
          filter.id.front_id === front_id
        );
      }
    });
  }
  function sizeDeletableElements(front_id: string) {
    const deletableElem = findFilterIndex(front_id);
    if (Array.isArray(filterValues[deletableElem as number].value.value)) {
      return filterValues[deletableElem as number].value.value.length - 1 + 3;
    } else return 3;
  }
  function formatFilterData(filterArr: FilterValuesType[]) {
    return filterArr.map((item) => ({
      condition: item.condition.value,
      id: item.id.value,
      value: item.value.value,
    })) as unknown as FilterItem[];
  }
  function removeFilter(front_id: string) {
    const indexElemOnFilterArr = findFilterIndex(front_id);
    if (indexElemOnFilterArr !== undefined) {
      const indexElemOnFilterArrForShowValuesDelete = indexElemOnFilterArr * 3;
      const filteredFilterValues = filterValues.filter(
        (_, index) => index !== indexElemOnFilterArr,
      );
      const filteredShowValues = showValues?.reduce<ShowValuesType[]>((acc, curr, index) => {
        if (
          index < indexElemOnFilterArrForShowValuesDelete ||
          index >= indexElemOnFilterArrForShowValuesDelete + sizeDeletableElements(front_id)
        ) {
          acc.push(curr);
        }
        return acc;
      }, []);
      setFilterValues(filteredFilterValues);
      onChangeReturnFilter?.(formatFilterData(filteredFilterValues));
      setShowValues(filteredShowValues);
    }
    return;
  }

  function getOptions() {
    if (step === 1) {
      const formatOptions = results.map((item) => ({
        ...item,
        fakeName: (
          <span>
            {item.name} - <b>{inputValue}</b>
          </span>
        ),
      }));
      if (!/[a-zA-zа-яА-Яё]/gim.test(inputValue)) {
        return formatOptions.filter((item) => item.result_value_type.includes('num'));
      } else return formatOptions.filter((item) => item.result_value_type.includes('str'));
    }
    if (step === 2) {
      return conditions
        .filter((item) => item.values.includes(values[0].result_value_type as string))
        .map((item) => ({
          ...item,
          fakeName: values[0].name + ' ' + item.label + ' ' + cacheInputValue,
        }));
    }
    return;
  }

  const customFilter = (option) => {
    const reg = new RegExp(`^`, 'gi');
    return reg.test(option.label || option.name);
  };

  function focusInput() {
    selectRef?.current?.focusInput();
  }

  const onChangeHandler = (e) => {
    setValues((prev) => {
      return [...prev, e];
    });
    setShowValues((prev) => {
      return [...prev, e];
    });
  };

  const filterConfirm = useCallback(
    (inputValue: inputValuesArrayType | inputValuesArrayType[]) => {
      if (!inputValue) return;
      const filterObj: FilterValuesType = {
        id: { value: values[0].metric_id as string, front_id: values[0].front_id as string },
        value: {
          value: Array.isArray(inputValue)
            ? /^[0-9]/gim.test(inputValue[0].value)
              ? inputValue.map((item) => Number(item.value))
              : inputValue.map((item) => item.value)
            : (values[1]?.values as string[]).length === 1
            ? Number(inputValue.value)
            : inputValue.value,
          front_id: Array.isArray(inputValue)
            ? inputValue.map((item) => item.front_id)
            : inputValue.front_id,
        },
        condition: { value: values[1].value as string, front_id: values[1].front_id as string },
      };
      setFilterValues((prev) => {
        onChangeReturnFilter?.(formatFilterData([...prev, filterObj]));
        return [...prev, filterObj];
      });
      setValues([]);
      setInputValuesArray([]);
      setInputValue('');
      setStep(1);
      focusInput();
    },
    [onChangeReturnFilter, values],
  );
  const creatableHandler = useCallback(
    (input) => {
      if (step === 3 && input !== '') {
        const creatable = { value: input, label: input, front_id: v4() };
        onChangeHandler({ ...creatable, style: inputValueStyle });
        if (inputValuesArray.length) {
          setInputValuesArray((prev) => [...prev, creatable]);
          filterConfirm([...cloneDeep(inputValuesArray), creatable]);
        } else {
          filterConfirm(creatable);
        }
        setMenuIsOpen(false);
      } else {
        return;
      }
    },
    [filterConfirm, inputValuesArray, step],
  );
  const onPlusIconClickToAddValuesArray = useCallback((input) => {
    if (!input) return;
    const creatable = { value: input, label: input, front_id: v4() };
    onChangeHandler({ ...creatable, style: inputValueStyle });
    setInputValuesArray((prev) => [...prev, creatable]);
    setCacheInputValue('');
    setStep(3);
  }, []);
  // components
  const multiValueSelectRemove: FC<MultiValueRemoveProps<any, true, GroupBase<any>>> = (props) => {
    return (
      <div className="flex relative">
        {[props.data.front_id].includes(deletableElem) && (
          <div
            onClick={() => {
              removeFilter(deletableElem);
            }}
            className="absolute cursor-pointer bottom-0 rounded-full max-w-[11px] max-h-[11px] overflow-hidden flex content-center items-center justify-center"
          >
            <Icon size={11} name="PlusIcon" className="rotate-[45deg] text-[white] bg-[red]" />
          </div>
        )}
      </div>
    );
  };

  const multiSelectValues: FC<MultiValueGenericProps<any, true, GroupBase<any>>> | undefined = (
    props,
  ) => {
    const { data } = props;
    return (
      <div id="value" className="flex items-center">
        <span
          onClick={() => {
            if (
              Array.isArray(filterValues[findFilterIndex(data.front_id) as number]?.value?.front_id)
            ) {
              setDeletableElem(
                filterValues[findFilterIndex(data.front_id) as number]?.value?.front_id[
                  filterValues[findFilterIndex(data.front_id) as number]?.value.front_id.length - 1
                ],
              );
            } else {
              setDeletableElem(
                filterValues[findFilterIndex(data.front_id) as number]?.value?.front_id as string,
              );
            }
          }}
          className="flex"
        >
          {data.label || data.name}
        </span>
      </div>
    );
  };

  const valuesContainer = useCallback(
    (props: ValueContainerProps<any, true, GroupBase<any>>) => {
      return (
        <div
          onBlur={() => {
            setDeletableElem('');
            setMenuIsOpen(false);
          }}
          onClick={() => {
            if (!showValues.length) {
              setDeletableElem('');
              // setMenuIsOpen((prev) => !prev);
            }
          }}
          id={'control'}
          className={`flex justify-between w-full min-h`}
        >
          <components.ValueContainer className="!pl-[17px]" {...props}>
            <div className="flex items-center flex-wrap w-full">
              <Icon className="text-3color pr-[10px]" size={14} name={'SearchIcon'} />
              {props.children}
              {step === 3 && (
                <div className="flex items-center">
                  {values.length && (values[1].value === '=' || values[1].value === '!=') && (
                    <div
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onPlusIconClickToAddValuesArray(props.selectProps.inputValue);
                      }}
                      className="flex items-center"
                    >
                      <Icon className="text-action cursor-pointer" name={'PlusIcon'} />
                    </div>
                  )}
                  <div
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      creatableHandler(props.selectProps.inputValue);
                    }}
                    className="flex items-center ml-[10px]"
                  >
                    <Icon
                      size={12}
                      name={'CheckIcon'}
                      className="text-white bg-action p-[5px] rounded-[10px]"
                    />
                  </div>
                </div>
              )}
            </div>
          </components.ValueContainer>
        </div>
      );
    },
    [creatableHandler, onPlusIconClickToAddValuesArray, showValues.length, step, values],
  );

  const indicatorsContainer: FC<IndicatorsContainerProps<any, true, GroupBase<any>>> = () => {
    return (
      <div className="flex items-center">
        <CircleButton
          size={14}
          onClick={resetAllFilterValues}
          icon="CancelIcon"
          className="text-basic_red"
        />
        <CircleButton
          size={14}
          onClick={() => {
            setContentHeight(!contentHeight);
          }}
          className={`${!contentHeight ? 'rotate-[180deg]' : ''} `}
          icon="ChevronDownIcon"
        />
      </div>
    );
  };
  const MenuContainer: FC<MenuProps<any, true, GroupBase<any>>> = (props) => {
    return (
      <Fragment>
        <components.Menu className="" {...props}>
          <div className={`absolute top-[-20px] left-[60px]`}>
            <TriangleBottomIcon
              size={13}
              className="shadow-[0px_8px_60px_rgba(61,74,88,0.01),0px_8px_25px_rgba(61,74,88,0)] -rotate-90 text-white shadow-lg"
            />
          </div>
          <div className="text-3color py-[10px] px-[20px] font-[500] text-[13px] leading-[110%] tracking-tight">
            {step === 1 ? t('choose_metric') : t('choose_condition')}
          </div>
          <div className="truncate px-[20px] block">{props.children}</div>
        </components.Menu>
      </Fragment>
    );
  };

  const OptionItem: FC<OptionProps<any, true, GroupBase<unknown>>> = (props) => {
    return <components.Option className="truncate " {...props} />;
  };
  // components

  useEffect(() => {
    showValues.length && focusInput();
  }, [showValues]);
  useEffect(() => {
    step === 1 && setCacheInputValue(inputValue);
  }, [cacheInputValue, inputValue, step]);
  useEffect(() => {
    if (step === 3) {
      if ((values[1]?.values as string[]).length === 1) {
        setInputValue(cacheInputValue.replace(/\D/g, ''));
      } else setInputValue(cacheInputValue);
    }
  }, [cacheInputValue, step, values]);
  return (
    <div className="w-full flex">
      <CreatableSelect
        openMenuOnClick={false}
        openMenuOnFocus={false}
        ref={selectRef}
        placeholder={t('filter_placeholder')}
        onKeyDown={(key) => {
          if (key.code === 'Enter' && step === 3) {
            creatableHandler(inputValue);
          }
        }}
        styles={
          {
            input: (base) => {
              if (step === 3) {
                return { ...base, ...inputValueStyle };
              }
              return {
                ...base,
                display: 'flex',
                flex: 'auto',
                minWidth: '20px',
                width: 'min-content',
              };
            },
            menu: (base) => ({
              ...base,
              width: '450px',
              display: step === 3 ? 'none' : '',
              zIndex: 99,
              position: 'absolute',
              borderRadius: '10px',
              paddingBottom: '10px',
              boxShadow: '0px 8px 60px rgba(61, 74, 88, 0.1),0px 8px 25px rgba(61, 74, 88, 0.08)',
              left:
                selectRef.current?.inputRef?.getBoundingClientRect()?.left &&
                selectRef.current?.inputRef?.getBoundingClientRect()?.left - 400 + 'px',
            }),
            valueContainer: (base) => ({
              ...base,
              display: 'flex',
              alignContent: 'flex-end',
              height: `${!contentHeight ? 'fit-content' : '36px'}`,
              overflow: 'hidden',
            }),
            control: () => ({
              background: '#fff',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '10px',
              borderWidth: '1px',
              borderColor: '#DCE0E5',
              outline: 'none',
            }),
            multiValue: (base, { data }) => ({
              ...data.style,
              marginRight: '6px',
              display: 'flex',
              alignItems: 'center',
            }),
            multiValueRemove: (base) => ({ ...base, background: 'red', padding: '20px' }),
            placeholder: (base, { isFocused }) => ({
              ...base,
              fontSize: '14px',
              fontWeight: '400',
              color: '#A6ABBA',
              lineHeight: '17px',
              display: isFocused ? 'none' : '',
            }),
            option: (base, { isFocused }) => ({
              ...base,
              fontWeight: '500',
              fontSize: '13px',
              lineHeight: '14.21px',
              color: '#2E3842',
              padding: '10px 0px',
              overflow: 'hidden',
              background: isFocused ? '#F1F2F3' : '',
              borderRadius: '10px',
              paddingLeft: '8px',
            }),
            // container: (base) => ({ ...base, height: '38px' }),
          } as any
        }
        components={{
          MultiValueRemove: multiValueSelectRemove,
          MultiValueLabel: multiSelectValues,
          ValueContainer: valuesContainer,
          IndicatorsContainer: indicatorsContainer,
          Menu: MenuContainer,
          Option: OptionItem,
        }}
        isSearchable
        isLoading={getOptions.length === 0}
        getOptionValue={(option) => option.front_id}
        getOptionLabel={(option) => (step === 1 ? option.name : option.label)}
        formatOptionLabel={(option) => option.fakeName}
        inputValue={inputValue}
        onInputChange={(event, actions) => {
          setMenuIsOpen(true);
          if (actions.action !== 'input-blur' && actions.action !== 'menu-close') {
            if (step === 3) {
              (values[1]?.values as string[]).length === 1
                ? setInputValue(event.replace(/\D/g, ''))
                : setInputValue(event);
            } else setInputValue(event);
          }
        }}
        isOptionSelected={(option, selectValue) => selectValue.some((i) => i === option)}
        className="w-full py-[10px]"
        isMulti
        backspaceRemovesValue={false}
        classNamePrefix="select"
        value={showValues}
        menuIsOpen={inputValue && step === 1 ? menuIsOpen : step !== 1 ? menuIsOpen : false}
        onCreateOption={creatableHandler}
        hideSelectedOptions={false}
        onChange={(_, actions) => {
          const { removedValue, option, action } = actions;
          if (action === 'pop-value') {
            values.length !== 0 &&
              setValues(values.filter((item) => item.front_id !== removedValue.front_id));
            setShowValues(showValues.filter((item) => item.front_id !== removedValue.front_id));
            if (values.length === 0 && showValues.length) {
              filterValues.pop();
              for (let i = showValues.length - 3; i <= showValues.length - 2; i++) {
                setValues((prev) => [...prev, showValues[i]]);
              }
            }
          }
          if (!removedValue && option) {
            stepsCalcFunc();
            onChangeHandler({ ...option, front_id: v4() });
          } else {
            showValues.length &&
              setStep((prev) =>
                prev === 1 ? 3 : prev === 1 && showValues.length === 1 ? 1 : prev - 1,
              );
          }
          focusInput();
        }}
        name="color"
        filterOption={customFilter}
        key={'id'}
        options={getOptions()}
      />
    </div>
  );
};
export default SelectUniversalFilter;
