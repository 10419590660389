module.exports = {
  part_of_speech_data: {
    all: 'Все части речи',
    NOUN: 'Существительное',
    VERB: 'Глагол',
    ADJ: 'Прилагательное',
    ADVB: 'Наречие',
    NUMR: 'Числительное',
    NPRO: 'Местоимение',
    CONJ: 'Предлоги/союзы/частицы',
  },
  occurrence: 'Встречаемость',
  word: 'слово',
  meets: 'встречается',
  cloud_deep: 'Глубина облака',
  // placeholders
  channel_select_placeholder: 'Канал',
  permitted_part_of_speech_select_placeholder: 'Части речи',
  frequency_select_placeholder: 'Встречаемость',
  stop_words_select_placeholder: 'Стоп-слова',
  //
  all_channels_option: 'Все каналы',
  apply_button: 'Применить'
};
