import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ArrowUpSquareIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_614_2323)">
          <path
            d="M8.67383 21.8721H19.3174C21.2158 21.8721 22.2002 20.8877 22.2002 19.0156V8.31934C22.2002 6.43848 21.2158 5.46289 19.3174 5.46289H8.67383C6.77539 5.46289 5.79102 6.43848 5.79102 8.31934V19.0156C5.79102 20.8965 6.77539 21.8721 8.67383 21.8721ZM8.7793 20.1494C7.96191 20.1494 7.51367 19.7188 7.51367 18.8662V8.45996C7.51367 7.60742 7.96191 7.18555 8.7793 7.18555H19.2119C20.0205 7.18555 20.4775 7.60742 20.4775 8.45996V18.8662C20.4775 19.7188 20.0205 20.1494 19.2119 20.1494H8.7793ZM14.0088 18.084C14.457 18.084 14.7822 17.7764 14.7822 17.3281V13.1797L14.7031 11.3604L15.5117 12.3623L16.4873 13.3818C16.6191 13.5312 16.8037 13.6104 17.0146 13.6104C17.4277 13.6104 17.7441 13.3115 17.7441 12.9072C17.7441 12.6963 17.6738 12.5293 17.5332 12.3799L14.6328 9.44434C14.4395 9.25098 14.2461 9.1543 14.0088 9.1543C13.7715 9.1543 13.5869 9.24219 13.3936 9.44434L10.4844 12.3799C10.3438 12.5293 10.2822 12.6963 10.2822 12.9072C10.2822 13.3115 10.5898 13.6104 10.9941 13.6104C11.2139 13.6104 11.3984 13.5225 11.5215 13.3818L12.5146 12.3623L13.3057 11.3691L13.2354 13.1797V17.3281C13.2354 17.7764 13.5605 18.084 14.0088 18.084Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_614_2323">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ArrowUpSquareIcon;
