import { EasyModal } from '@app/components/ui';
import { UserIcon } from '@app/components/ui/icons/icons-list';
import { UserRoleList } from '@app/interfaces/users';
import React, { FC } from 'react';
import useTranslation from '@app/hooks/use-translation';
import { UserFormFields } from '@app/pages/users/blocks/user-form-fields';

type UserModalFormPropsType = {
  isOpenAddUserModal: boolean;
  changeOpenAddUserModal: React.Dispatch<React.SetStateAction<boolean>>;
  userRole: UserRoleList;
  onFormSubmit: (data: {
    username: string;
    role_id: string;
    login: string;
    password?: string;
    enable?: boolean;
  }) => void;
  isEditMode: boolean;
  currentUserData: any;
};

export const UserModalForm: FC<UserModalFormPropsType> = ({
  isOpenAddUserModal,
  changeOpenAddUserModal,
  userRole,
  onFormSubmit,
  isEditMode,
  currentUserData,
}) => {
  const { t } = useTranslation('pages.usersPage');

  return (
    <EasyModal
      show={isOpenAddUserModal}
      label={
        <div className="flex flex-col gap-[13px] items-center justify-center">
          <div className="w-[57px] h-[57px] rounded-full bg-blue_pal flex items-center justify-center">
            <UserIcon className="text-action" size={25} />
          </div>
          <span className="text-0color text-[24px] font-[700] leading-[29px]">
            {isEditMode ? t('modal.title_edit') : t('modal.title_create')}
          </span>
        </div>
      }
      onClose={() => {
        changeOpenAddUserModal(false);
      }}
      variant="mediumW650"
      withoutContentPadding
      withoutFooter
    >
      <div className="px-[50px] pb-[48px] pt-[35px]">
        <UserFormFields
          userRole={userRole}
          onSubmit={onFormSubmit}
          isEditMode={isEditMode}
          currentUserData={currentUserData}
        />
      </div>
    </EasyModal>
  );
};
