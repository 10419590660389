import { Button, CircleButton, Modal, PageHeader, TooltipWrapper } from '@app/components/ui';
import Icon from '@app/components/ui/icons';
import RubberTable from 'src/components/rubber-table';
import useTranslation from '@app/hooks/use-translation';
import { getFrontendDate } from '@app/utils/helpers';
import { navigationRoutes } from '@app/utils/navigation-routes';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import {
  useGetAllDictionariesListQuery,
  useDeleteDictionaryMutation,
} from '@app/store/api/dictionaries.api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { DictionariesSkeleton } from '@app/pages/dictionaries-page/blocks/dictionaries-skeleton';
import { useNavigate } from 'react-router-dom';
import { DictionariesType } from '@app/interfaces/slices-types/dictionaries-slice.type';

import { tableUtilIconsClassName } from '../records/blocks/records-table/records-table.styles';
export const DictionariesPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('pages.dictionaries');
  const [deleteModal, setDeleteModal] = useState<DictionariesType | null>(null);
  const { data: dictionariesData, isLoading } = useGetAllDictionariesListQuery();
  const [deleteDictionary] = useDeleteDictionaryMutation();
  const [dictionaries, changeDictionariesList] = useState(dictionariesData);

  useEffect(() => {
    changeDictionariesList(dictionariesData);
  }, [dictionariesData]);

  const filteredArr = () => {
    return dictionaries?.map((dataItem) => ({
      ...dataItem,
      nameCol: (
        <div className="flex items-center truncate pr-[5px]" data-id={dataItem.name}>
          <b
            className="truncate hover:text-action cursor-pointer"
            onClick={() =>
              navigate(`/${navigationRoutes.dictionaries}/${dataItem.dictionary_id}/edit`)
            }
          >
            {dataItem.name}{' '}
          </b>
          {dataItem.is_system && (
            <div className="flex items-center justify ml-[10px]">
              <Icon name={'LockCircle'} className="text-2color ml-[px]" size={19} />
            </div>
          )}
        </div>
      ),
      utils: (
        <div className="flex pl-[10px] w-full gap-[10px] items-center border-l-[1px] border-[#F1F2F3]">
          <CircleButton
            hintTitle={t('popup_hints.settings_icon')}
            link={`/${navigationRoutes.dictionaries}/${dataItem.dictionary_id}/edit`}
            icon="SettingIcon"
            size={19}
            dataId={`Configure ${dataItem.name}`}
            className={tableUtilIconsClassName}
          />
          <CircleButton
            hintTitle={t('popup_hints.copy_icon')}
            link={`/${navigationRoutes.dictionaries}/${dataItem.dictionary_id}/copy`}
            icon="CopyIcon"
            size={19}
            dataId={`Copy ${dataItem.name}`}
            className={tableUtilIconsClassName}
          />
          <CircleButton
            hintTitle={t('popup_hints.delete_button')}
            dataId={`Delete ${dataItem.name}`}
            onClick={() => {
              if (dataItem.num_child === 0) {
                setTimeout(() => {
                  setDeleteModal(dataItem);
                }, 0);
              } else {
                toast.error(t('cant_delete'));
              }
            }}
            icon="TrashIcon"
            size={19}
            className={`text-basic_red`}
            disable={dataItem.is_system}
          />
        </div>
      ),
      date: (
        <div className="flex max-w-[140px] gap-[10px] items-center">
          <Icon name={'CalendarIcon'} />
          <div>{getFrontendDate(dataItem.dt_update, 'dd.MM.yyyy')}</div>
        </div>
      ),
      params: (
        <div className="flex items-center gap-[8px]">
          <TooltipWrapper
            content={t('popup_hints.words_count_icon')}
            id={t('popup_hints.words_count_icon') + v4()}
          >
            <span className="flex items-center gap-[8px] bg-blue_pal rounded-[6px] p-[2px_8px_3px]">
              <Icon className="text-3color" name={'CharacterBook'} />
              {dataItem.num_items}
            </span>
          </TooltipWrapper>
          <TooltipWrapper
            content={t('popup_hints.childs_count_icon')}
            id={t('popup_hints.childs_count_icon') + v4()}
          >
            <span className="flex items-center gap-[8px] bg-blue_pal rounded-[6px] p-[2px_8px_3px]">
              <Icon className="text-3color" name={'JoystickTilt'} />
              {dataItem.num_child}
            </span>
          </TooltipWrapper>
          <TooltipWrapper
            content={t('popup_hints.parents_count_icon')}
            id={t('popup_hints.parents_count_icon') + v4()}
          >
            <span className="flex items-center gap-[8px] bg-blue_pal rounded-[6px] p-[2px_8px_3px]">
              <Icon className="text-3color" name={'ParentIcon'} />
              {dataItem.num_parents}
            </span>
          </TooltipWrapper>
        </div>
      ),
      description: (
        <TooltipWrapper content={dataItem.description} id={dataItem.description + v4()}>
          <div className="truncate">{dataItem.description}</div>
        </TooltipWrapper>
      ),
    }));
  };
  const tableSortKeyList = (sort: { [x: string]: 1 | -1 | undefined }) => {
    if (!dictionaries) return;
    const value = Object.entries(sort)[0];
    if (value === undefined) {
      changeDictionariesList(dictionariesData);
      return;
    }
    if (value[0] === 'nameCol') {
      if (value[1] === -1) {
        const sortArr = [...dictionaries].sort((a, b) => -a.name.localeCompare(b.name));
        changeDictionariesList(sortArr);
      } else if (value[1] === 1) {
        const sortArr = [...dictionaries].sort((a, b) => a.name.localeCompare(b.name));
        changeDictionariesList(sortArr);
      }
    } else if (value[0] === 'date') {
      if (value[1] === 1) {
        const sortArr = [...dictionaries].sort(
          (a, b) => Date.parse(a.dt_update) - Date.parse(b.dt_update),
        );
        changeDictionariesList(sortArr);
      } else if (value[1] === -1) {
        const sortArr = [...dictionaries].sort(
          (a, b) => Date.parse(b.dt_update) - Date.parse(a.dt_update),
        );
        changeDictionariesList(sortArr);
      }
    }
  };
  function onApplyDictionaryDelete() {
    deleteModal &&
      deleteDictionary({ id: deleteModal.dictionary_id }).then((data) => {
        const err = (
          data as {
            error: FetchBaseQueryError;
          }
        ).error;
        err.status === 403 && toast.info(t('dictDeleteToastError'));
      });

    setDeleteModal(null);
  }
  const columns = [
    {
      title: t('table.name'),
      index: 'nameCol',
      filter: true,
    },
    {
      title: t('table.desc'),
      index: 'description',
    },
    {
      title: t('table.params'),
      index: 'params',
      maxWidth: 215,
    },
    {
      title: t('table.date'),
      index: 'date',
      filter: true,
      maxWidth: 180,
    },
    {
      title: t('table.actions'),
      index: 'utils',
      maxWidth: 150,
    },
  ];
  if (isLoading) return <DictionariesSkeleton />;
  return (
    <>
      <Modal
        id={'deleteDictionary'}
        variant={'removal'}
        value={!!deleteModal}
        setValue={setDeleteModal.bind(null, null)}
        title={
          <div className="px-[5px] w-[100%] break-words" title={deleteModal?.name}>
            {t('warning')}
            <br />
            {deleteModal?.name}
          </div>
        }
        size={'xs'}
        onApply={onApplyDictionaryDelete}
      >
        <div className="px-[50px]">{t('dict_confirm_delete')}</div>
      </Modal>
      <PageHeader icon="BookIcon" label={t('dictionaries')}>
        <Button
          data-id="new-dictionary"
          label={t('add_dictionaries')}
          icon="CirclePlusIcon"
          link={navigationRoutes.createDictionaries}
        />
      </PageHeader>
      <RubberTable onFilter={tableSortKeyList} columns={columns} dataSource={filteredArr() || []} />
    </>
  );
};
