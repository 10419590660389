import React, { useCallback, useEffect, useMemo } from 'react';

import { NavigationTabs } from '@ui';
import { NavigationTabsItem } from '@app/components/ui/navigation-tabs/navigation-tabs';
import { navigationRoutes } from '@app/utils/navigation-routes';
import useTranslation from '@app/hooks/use-translation';
import { userPermissions } from '@app/utils/user-permissions';
import { useGetUserInfoQuery } from '@app/store/api/get-user-info.api';
import { useNavigate, useLocation } from 'react-router-dom';

const SettingsNavigationBlock = () => {
  const { t } = useTranslation('pages.settings.navigation');
  const navigate = useNavigate();
  const { state } = useLocation();
  const navigationItemsList: NavigationTabsItem[] = useMemo(
    () => [
      {
        link: navigationRoutes.settingsCommon,
        icon: 'FolderIcon',
        label: t('common_settings'),
        index: 'commonSettings',
        dataId: 'settings-section-common-settings',
        tag: userPermissions.display.displayChannels,
      },
      {
        link: `${navigationRoutes.settingsAlias}`,
        icon: 'LetterA',
        label: t('alias'),
        index: 'alias',
        iconSize: 15,
        dataId: 'settings-section-alias-settings',
        tag: userPermissions.display.displayAliases,
      },
      {
        link: `${navigationRoutes.settingsRecognitionRules}`,
        icon: 'RecordChannelIcon',
        label: t('recognition_rules'),
        index: 'recognitionRules',
        dataId: 'settings-section-recognition-rules',
        tag: userPermissions.display.displayRecognizeRules,
      },
      {
        link: `${navigationRoutes.integration}`,
        icon: 'PuzzleIcon',
        label: t('integration_settings'),
        index: 'integrationSettings',
        dataId: 'settings-section-integration-settings',
        tag: userPermissions.display.displayIntegrationAuth,
      },
      {
        link: `${navigationRoutes.messagesManaging}`,
        icon: 'PuzzleIcon',
        label: t('messagesManaging'),
        index: 'messagesManaging',
        dataId: 'settings-section-messages-managing',
        tag: userPermissions.display.displaySender,
      },
      {
        link: `${navigationRoutes.messagesHistory}`,
        icon: 'PuzzleIcon',
        label: t('messagesHistory'),
        index: 'messagesHistory',
        dataId: 'settings-section-messages-history',
        tag: userPermissions.display.displaySender,
      },
    ],
    [t],
  );
  const { data: displayPermissions } = useGetUserInfoQuery();
  const check = useCallback(
    (tag) => {
      const permissions = 'display';
      if (!tag) return false;
      if (!displayPermissions?.permissions?.[permissions]) return true;
      if (!displayPermissions?.permissions?.[permissions][tag]) return true;
      return !displayPermissions?.permissions?.[permissions][tag];
    },
    [displayPermissions?.permissions],
  );

  console.log(state);

  useEffect(() => {
    if (state?.['settingsRedirect'] === 'false') return;
    const filtered = navigationItemsList.filter((item) => !check(item.tag));
    if (!filtered[0]?.link) return;
    navigate(filtered[0]?.link);
  }, [check, navigate, navigationItemsList, state]);

  return <NavigationTabs items={navigationItemsList} />;
};

export default SettingsNavigationBlock;
