import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import {
  MetricListArrType,
  MetricObjType,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import { CompositeMetricData, CompositeMetricVisualization } from '@app/interfaces/metric.type';
import { Input, Select, Skeleton } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import connector, { PropsFromRedux } from '@app/utils/store';

import { decodeMetricToXml } from './data/json-rebuild.data';
import { createCustomMetric } from './elements/metric.element';
import './data/metric-localization-ru.data';
import './elements/metric.element';
import './elements/logic.element';
import './elements/math.element';
import './elements/generators.blockly';
import BlocklyNative from './blockly-native';

export type BlocklyBlockProps = {
  metric: Array<MetricListArrType>;
  metricSettings: MetricObjType | null;
  minWidth: string;
  height: string;
  isEditModal: boolean;
  previousState: CompositeMetricData;
  onChange: Dispatch<SetStateAction<CompositeMetricData>>;
} & PropsFromRedux;

const BlocklyBlock: FC<BlocklyBlockProps> = (props) => {
  const { metric, onChange, metricSettings, height, isEditModal, previousState } = props;
  const { t } = useTranslation('pages.metric');
  const METRIC_TYPES = [
    { title: t('select.composite_metric_type.options.str'), value: 'str' },
    { title: t('select.composite_metric_type.options.num'), value: 'num' },
  ];
  const METRIC_VISUALIZATION = [
    {
      title: t('select.composite_visualization.options.composite_visualization_native'),
      value: 'native',
    },
    {
      title: t('select.composite_visualization.options.composite_visualization_score'),
      value: 'score5',
    },
    {
      title: t('select.composite_visualization.options.composite_visualization_score100'),
      value: 'score100',
    },
  ];
  const [javascriptCode, setJavascriptCode] = useState('');

  const metricTOString: string = isEditModal
    ? decodeMetricToXml(metricSettings?.settings.formula.root.element || {}).join('')
    : '';
  const initialXml = `<xml xmlns="https://developers.google.com/blockly/xml"><block type="formula_start" x="50%" y="50%"><value name="FORMULA">${metricTOString}</value></block></xml>`;

  useEffect(() => {
    createCustomMetric(metric, metricSettings?.metric_id || undefined);
  });
  function handleChangeBlockly(field: { [x: string]: string | CompositeMetricData['formula'] }) {
    const keyForChange = Object.keys(field)[0];
    onChange?.((previousState) => ({ ...previousState, [keyForChange]: field[keyForChange] })); //{ name: getValues('name'), metricType: getValues('metricType'), formula });
  }

  return (
    <div>
      <div className="flex relative align-left justify-between w-full pb-[10px]">
        <div className="flex-1">
          <div className="block text-left mt-[5px] pl-[10px] text-[12px] text-3color">
            {t('composite_name')}:
          </div>
          <div className="flex-1 pl-[10px]">
            <Input
              defaultValue={previousState.name}
              onChange={({ target: { value } }) => handleChangeBlockly({ name: value })}
              name="name"
            />
          </div>
        </div>
        <div className="flex-1">
          <div className="block text-left mt-[5px] pl-[10px] text-[12px] text-3color">
            {t('select.composite_metric_type.label')}:
          </div>
          <div className="flex-1 pl-[10px]">
            <Select
              dropWidth={245}
              placeholder={t('select.composite_metric_type.label')}
              defaultValue={previousState.metricType}
              onChange={(value) => handleChangeBlockly({ metricType: String(value) })}
              options={METRIC_TYPES}
            />
          </div>
        </div>
        <div className="flex-1">
          <div className="block text-left mt-[5px] pl-[10px] text-[12px] text-3color">
            {t('select.composite_metric_type.labelDisplayType')}:
          </div>
          <div className="flex-1 pl-[10px]">
            <Select
              dropWidth={245}
              placeholder={t('select.composite_metric_type.labelDisplayType')}
              defaultValue={
                previousState.metricType === 'str'
                  ? 'native'
                  : (previousState.visualization as CompositeMetricVisualization)
              }
              onChange={(value) => handleChangeBlockly({ visualization: String(value) })}
              options={METRIC_VISUALIZATION}
              disable={previousState.metricType === 'str'}
            />
          </div>
        </div>
        <div className="flex-1">
          <div className="block text-left mt-[5px] pl-[10px] text-[12px] text-3color">
            {t('composite_formula_name')}:
          </div>
          <div className="flex-1 pl-[10px]">
            <Input name="code" defaultValue={javascriptCode} disabled />
          </div>
        </div>
      </div>
      <div className="w-full h-full w-max-[600px] z-50" style={{ minHeight: height }}>
        <div>
          {initialXml ? (
            <BlocklyNative
              initialXml={initialXml.replace(/[\r\n]/gm, '')}
              onChange={handleChangeBlockly}
              onCode={setJavascriptCode}
            />
          ) : (
            <Skeleton width="100%" height={height} />
          )}
        </div>
      </div>
    </div>
  );
};

export default connector(BlocklyBlock);
