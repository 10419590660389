module.exports = {
  cant_delete: 'Cannot be deleted, this dictionary is used in another dictionary',
  warning: 'Warning',
  remove_all:'Clear all',
  dict_confirm_delete:
    'Deleting a dictionary will affect the metrics in which it is used. Do you really want to delete this dictionary?',
  dictionaries: 'Dictionaries',
  dictionary: 'Dictionary',
  popup_hints: {
    to_dictionary: 'Go to dictionary',
    delete_button: 'Delete',
    words_count_icon: 'Number of words/phrases in dictionary',
    childs_count_icon: 'Number of dictionaries included in this dictionary ',
    parents_count_icon: 'Number of dictionaries in which this dictionary is used ',
    copy_icon: 'Copy the dictionary and complete it',
    settings_icon: 'Edit dictionary',
  },
  table: {
    name: 'NAME',
    desc: 'DESCRIPTION',
    params: 'Parameters',
    Date: 'Update Date',
    actions: 'Actions',
  },
  add_dictionaries: 'Add dictionary',
  copy: 'copy',
  dict_desc: 'Dictionary Description',
  input: 'Input',
  Date_upDate: 'Update Date',
  dict_used: 'Dictionary is using',
  save: 'Save',
  cancel: 'Cancel',
  copy_dict: 'Copy dictionary',
  delete: 'Delete',
  dictDeleteToastError: 'To delete a dictionary, you must exclude it from all analytic project metrics',
  fillAllFields: 'Fill in all fields',
};
