import React, { FC } from 'react';

import { IconType } from '../icon.type';

const BoltSquareIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_606_18)">
          <path
            d="M8.67383 21.8721H19.3174C21.2158 21.8721 22.2002 20.8877 22.2002 19.0156V8.31934C22.2002 6.43848 21.2158 5.46289 19.3174 5.46289H8.67383C6.77539 5.46289 5.79102 6.43848 5.79102 8.31934V19.0156C5.79102 20.8965 6.77539 21.8721 8.67383 21.8721ZM8.7793 20.1494C7.96191 20.1494 7.51367 19.7188 7.51367 18.8662V8.45996C7.51367 7.60742 7.96191 7.18555 8.7793 7.18555H19.2119C20.0205 7.18555 20.4775 7.60742 20.4775 8.45996V18.8662C20.4775 19.7188 20.0205 20.1494 19.2119 20.1494H8.7793ZM10.2471 14.2344C10.2471 14.4629 10.4404 14.6299 10.6777 14.6299H13.5166L12.0049 18.6816C11.7852 19.2705 12.4092 19.5869 12.7959 19.1211L17.4629 13.3115C17.5596 13.1973 17.6123 13.0742 17.6123 12.96C17.6123 12.7227 17.4189 12.5557 17.1816 12.5557H14.3428L15.8545 8.50391C16.0742 7.92383 15.4502 7.59863 15.0635 8.06445L10.3965 13.874C10.2998 13.9883 10.2471 14.1113 10.2471 14.2344Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_606_18">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default BoltSquareIcon;
