import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  AggregateSelectForm,
  CreateNewReportGraphType,
  NewLiveReportGraph,
  OrderByChartSettings,
  ReportChartGraphType,
  SettingsDateStep,
} from '@app/interfaces/report.type';

import useTranslation from '@app/hooks/use-translation';
import { CircleButton, ColorInput, EasyModal, Input, Select, Toggle } from '@ui';
import useChartDateIntervals from '@app/hooks/use-chart-date-intervals';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { useOutletContext, useParams } from 'react-router-dom';
import {
  useAddNewLiveReportGraphMutation,
  useEditNewLiveReportGraphMutation,
} from '@app/store/api/live-reports-charts.api';
import { chartsTypeData } from '@app/pages/reports/data/report-status-properties.data';
import cn from 'classnames';
import ConditionalFormattingNestedField from '@app/pages/reports/blocks/chart-modal/conditional-formatting-nested-field';
import { IconNameType } from '@app/components/ui/icons/icon-name.type';
import Icon from '@app/components/ui/icons';

import { pieChartPalette } from '@app/pages/reports/data/palette';

import { ChartsLayoutType } from '@app/interfaces/dashboards.type';
// TODO:
import { changeChartLoading } from '@app/store/reducers/report-charts.slice';

import {
  useAddNewChatLiveReportGraphMutation,
  useEditNewChatLiveReportGraphMutation,
} from '@app/store/api/chat-api/chat-live-reports-charts.api';

import DropMenu from '../../../../components/ui/drop-menu';
import { ReportOutletContext } from '../../report.outlet';

type SettingsFormBlockProps = {
  setup?: NewLiveReportGraph;
  isOpen: boolean;
  onClose: (status: boolean) => void;
  onRemove?: (status: boolean) => void;
};

// type SettingsMetricStep = string;

const chartSvgData: { [x: string]: IconNameType } = {
  barchart: 'BarChartIcon',
  linechart: 'LineChartIcon',
  horizontal_barchart: 'HorizontalBarChartIcon',
  piechart: 'PieChartIcon',
  columnchart: 'ColumnChartIcon',
};

const SettingsFormBlock: FC<SettingsFormBlockProps> = (props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { charts: voiceCharts } = useAppSelector((state) => state.reportCharts);
  const { charts: chatCharts } = useAppSelector((state) => state.chatReportCharts);
  const charts = useMemo(
    () => ({ chat: chatCharts, voice: voiceCharts }),
    [chatCharts, voiceCharts],
  );
  //css
  const headerPathClassName = 'text-[18px] font-[700] mb-[38px]';
  const valuesLabelPathClassName =
    'text-[15px] font-[500] leading-[16.4px] tracking-tight text-0color whitespace-nowrap';
  const fieldAndLabelBlock = 'flex items-center justify-between w-full';
  const firstBlockValueWidth = 'w-[220px]';
  const settingsNavStyles = (active = false) =>
    cn(
      'flex items-center cursor-pointer hover:text-1color w-full justify-center pl-[18px] pr-[22px] pt-[12px] pb-[10px] text-base text-2color',
      active
        ? 'bg-gradient-to-b from-white to-gray rounded-t-[10px]  cursor-default'
        : 'cursor-pointer',
    );
  const { setup, onClose, isOpen, onRemove } = props;
  const [activeMenuItem, changeActiveMenuItem] = useState<'main' | 'axis' | 'additional'>('main');
  const { metricList, currentTemplate } = useOutletContext<ReportOutletContext>();

  //api
  // const { data: charts } = useGetLiveReportsGraphListQuery(id || '', { skip: !id });
  // const { data: liveReport } = useGetLiveReportSettingsQuery({ id: id || '' }, { skip: !id });
  // const { data: metricList } = useGetProjectMetricsListQuery(
  //   {
  //     project_id: liveReport?.project.project_id || '',
  //   },
  //   { skip: !liveReport?.project.project_id },
  // );
  const [createChartVoice] = useAddNewLiveReportGraphMutation();
  const [editChartVoice] = useEditNewLiveReportGraphMutation();
  const [createChartChat] = useAddNewChatLiveReportGraphMutation();
  const [editChartChat] = useEditNewChatLiveReportGraphMutation();

  const createChart = { chat: createChartChat, voice: createChartVoice };
  const editChart = { chat: editChartChat, voice: editChartVoice };
  // const [layoutsData, changeLayoutsData] = useState<ChartsLayoutType[]>();
  // useEffect(() => {
  //   if (!charts) return;
  //   changeLayoutsData(charts?.map((chart) => chart.layout));
  // }, [charts]);
  // hooks
  const { t } = useTranslation('pages.chartPage');
  const { daysSelectOptions, dateIntervals, dateToString } = useChartDateIntervals();
  const reportsTimeIntervals = [...daysSelectOptions];
  const submitFormRef = useRef<HTMLInputElement>(null);
  // constants
  const aggregateSelectOptions = [
    { title: t('aggregate.mid'), value: 'avg' },
    {
      title: t('aggregate.sum'),
      value: 'sum',
    },
    {
      title: t('aggregate.count'),
      value: 'count',
    },
    {
      title: t('aggregate.min'),
      value: 'min',
    },
    {
      title: t('aggregate.max'),
      value: 'max',
    },
    {
      title: t('aggregate.prop_num'),
      value: 'prop_num',
    },
  ];
  const dateSelectOptions = [
    { title: t('date_select.h'), value: 'h' },
    { title: t('date_select.d'), value: 'd' },
    { title: t('date_select.w'), value: 'w' },
    { title: t('date_select.m'), value: 'm' },
  ];

  const defaultColorValue = '#c592f7';
  const chartDataDefaultValue = {
    metric_id: metricList?.[0].metric_id || '',
    aggregate:
      getMetricResultValueType(metricList?.[0].metric_id || '') === 'str'
        ? (aggregateSelectOptions.filter(
            (item) => item.value === 'count' || item.value === 'prop_num',
          )[0].value as AggregateSelectForm)
        : (aggregateSelectOptions[0].value as AggregateSelectForm),
    color: [defaultColorValue],
    type: 'column',
    conditional_formatting: [],
  };

  const settingsNavMenuData = [
    { name: t('settings'), value: 'main' },
    { name: t('axisSettings'), value: 'axis' },
    { name: t('additionalSettings'), value: 'additional' },
  ];
  const getLayoutSetup = () => {
    const maxAvailableWidth = 24;
    const defaultHeight = 6;
    const defaultWidth = 12;
    if (!charts[currentTemplate || 'voice']?.length) {
      return { w: defaultWidth, h: defaultHeight, x: 0, y: 0 };
    }
    const layoutsData = charts[currentTemplate || 'voice']?.map((chart) => chart.layout);
    const yMaxIntoLayout = Math.max(...(layoutsData?.map((layout) => layout.y) || []));
    const xMaxIntoLayout = Math.max(
      ...(layoutsData?.filter((layout) => layout.y === yMaxIntoLayout).map((layout) => layout.x) ||
        []),
    );
    const layoutFromYMaxInLayout = layoutsData?.find(
      (layout) => layout.x === xMaxIntoLayout && layout.y === yMaxIntoLayout,
    ) as ChartsLayoutType;
    const widthCalculations =
      maxAvailableWidth - (layoutFromYMaxInLayout.x + layoutFromYMaxInLayout.w);
    return {
      w: widthCalculations >= 6 ? (widthCalculations < 0 ? 0 : widthCalculations) : defaultWidth,
      h: defaultHeight,
      x: widthCalculations >= 6 ? layoutFromYMaxInLayout.x + layoutFromYMaxInLayout.w : 0,
      y: widthCalculations >= 6 ? yMaxIntoLayout : yMaxIntoLayout + 1,
    };
  };
  // hookForm
  const methods = useForm<CreateNewReportGraphType>({
    defaultValues: {
      name: setup?.name || '',
      live_report_id: id || '',
      layout: setup?.layout,
      settings: {
        display_data: setup?.settings.display_data || false,
        yaxis_min_value: setup?.settings.yaxis_min_value || false,
        display_legend: setup?.settings.display_legend || false,
        empty_values: setup?.settings.empty_values || false,
        nullable_values: setup?.settings.nullable_values || false,
        max_group_display: setup?.settings.max_group_display,
        order_by: setup?.settings.order_by || 'none',
        type: setup?.settings.type || 'barchart',
        range: dateToString(setup?.settings.range) || 'week',
        data: setup?.settings.data || [chartDataDefaultValue],
        group: setup?.settings.group || {
          type: 'date',
          step: dateSelectOptions[0].value as SettingsDateStep,
          metric_id: metricList?.[0].metric_id,
        },
        line_settings: setup?.settings.line_settings || {
          value: [1],
          color: defaultColorValue,
          active: false,
        },
        styles: setup?.settings.styles || {
          grid: false,
          header_size: 18,
          legend_position: 'bottom',
          value_position: 'outside',
          text_size: 12,
          text_color: '#000',
        },
      },
    },
  });

  const { register, watch, reset, control, setValue, handleSubmit } = methods;
  //chartDataFields
  const {
    fields: chartDataFields,
    append: appendChartDataField,
    remove: removeChartDataField,
  } = useFieldArray({
    name: 'settings.data',
    control,
  });
  //conditionalFormattingFields
  // handlers
  function getSelectOptionsFromMetrics() {
    return (
      metricList?.map((metric) => ({
        title: metric.name,
        value: metric?.metric_id,
      })) || []
    );
  }
  function getMetricResultValueType(id: string) {
    if (id) {
      return metricList?.find((metric) => metric.metric_id === id)?.result_value_type;
    } else {
      return metricList?.find((metric) => metric.metric_id === metricList[0]?.metric_id)
        ?.result_value_type;
    }
  }
  function handlerRemove() {
    onRemove?.(true);
  }
  function handlerSuccess() {
    submitFormRef?.current?.click();
  }
  function onSubmitForm(data: CreateNewReportGraphType) {
    dispatch(changeChartLoading(setup?.graph_id || null));
    const formattedData: CreateNewReportGraphType = {
      ...data,
      layout: data.layout ? data.layout : (getLayoutSetup() as ChartsLayoutType),
      settings: {
        ...data.settings,
        line_settings: data.settings.line_settings,
        // line_settings: data.settings.line_settings.active ? data.settings.line_settings : {},
        group:
          data.settings.group.type === 'metric'
            ? { type: 'metric', metric_id: data.settings.group.metric_id }
            : { type: 'date', step: data.settings.group.step },
        max_group_display: !data.settings.max_group_display
          ? undefined
          : data.settings.max_group_display,
        range: data.settings.range
          ? dateIntervals[data.settings.range as string]
          : dateIntervals['all'],
        data: data.settings.data.map((axis) => ({
          ...axis,
          conditional_formatting:
            data.settings.type === 'columnchart' ||
            data.settings.type === 'piechart' ||
            data.settings.type === 'linechart'
              ? []
              : axis.conditional_formatting,
        })),
      },
    };
    if (!setup) {
      createChart[currentTemplate || 'voice'](formattedData).then(() => onClose(false));
    } else {
      delete formattedData.live_report_id;
      editChart[currentTemplate || 'voice']({
        params: { id: setup.graph_id },
        body: formattedData,
        liveReportId: data.live_report_id,
      }).then(() => onClose(false));
    }
    reset();
    setTimeout(() => dispatch(changeChartLoading(null)), 400);
  }

  useEffect(() => {
    changeActiveMenuItem('main');
  }, [isOpen]);

  // jsx markup data
  const renderSortDataSelect = {
    metric: (
      <Select
        defaultValue={watch('settings.group.metric_id')}
        onChange={(value) => setValue('settings.group.metric_id', value as string)}
        options={getSelectOptionsFromMetrics()}
        dropWidth={216}
      />
    ),
    date: (
      <Select
        defaultValue={watch('settings.group.step')}
        dropWidth={216}
        onChange={(value) => setValue('settings.group.step', value as SettingsDateStep)}
        options={dateSelectOptions}
      />
    ),
  };
  const renderMenuItems = {
    main: (
      <div>
        <div className="flex items-start justify-between ">
          <div className="w-[500px]">
            <div className="flex items-start pl-[25px] flex-col gap-[25px]">
              <div className={fieldAndLabelBlock}>
                <span className={valuesLabelPathClassName}>{t('chart_name')}</span>
                <div className={firstBlockValueWidth}>
                  {/*name*/}
                  <Input {...register('name')} />
                </div>
              </div>
              <div className={fieldAndLabelBlock}>
                <span className={valuesLabelPathClassName}>{t('diagram_type')}</span>
                {/*diagram_type*/}
                <div className={firstBlockValueWidth}>
                  <Select
                    defaultValue={String(watch('settings.type'))}
                    dropWidth={216}
                    onChange={(value) => setValue('settings.type', value as ReportChartGraphType)}
                    options={chartsTypeData.map((item) => ({
                      title: t(item.name),
                      value: item.type,
                    }))}
                    tooltip={{
                      place: 'left',
                      content:
                        t(
                          chartsTypeData?.find((chart) => chart.type === watch(`settings.type`))
                            ?.name || chartsTypeData?.[0]?.name,
                        ) || '',
                      id: 'chartType',
                    }}
                  />
                </div>
              </div>
              <div className={fieldAndLabelBlock}>
                <span className={valuesLabelPathClassName}>{t('default')}</span>
                <div className={firstBlockValueWidth}>
                  {/*range*/}
                  <Select
                    defaultValue={String(watch('settings.range'))}
                    placeholder={t('select.date_placeholder')}
                    dropWidth={216}
                    onChange={(value) => setValue('settings.range', value)}
                    options={[...reportsTimeIntervals.splice(0, 6), daysSelectOptions[6]]}
                  />
                </div>
              </div>
              {watch('settings.type') !== 'columnchart' &&
                watch('settings.type') !== 'piechart' && (
                  <div className={fieldAndLabelBlock}>
                    <span className={valuesLabelPathClassName}>{t('order_by')}</span>
                    <div className={firstBlockValueWidth}>
                      {/*order_by*/}
                      <Select
                        defaultValue={watch('settings.order_by')}
                        onChange={(value) =>
                          setValue('settings.order_by', value as OrderByChartSettings)
                        }
                        dropWidth={216}
                        options={[
                          { title: t('order_by_options.asc'), value: 'asc' },
                          { title: t('order_by_options.desc'), value: 'desc' },
                          { title: t('order_by_options.none'), value: 'none' },
                        ]}
                      />
                    </div>
                  </div>
                )}
              <div className={fieldAndLabelBlock}>
                <span className={valuesLabelPathClassName}>{t('display_values')}</span>
                <div className={firstBlockValueWidth}>
                  {/*display_data*/}
                  <Toggle
                    checked={watch('settings.display_data')}
                    onChecked={(status) => setValue('settings.display_data', status)}
                  />
                </div>
              </div>
              <div className={fieldAndLabelBlock}>
                <span className={valuesLabelPathClassName}>{t('display_legend')}</span>
                <div className={cn(firstBlockValueWidth, 'flex items-center gap-[50px]')}>
                  {/*display_legend*/}
                  <Toggle
                    checked={watch('settings.display_legend')}
                    onChecked={(status) => setValue('settings.display_legend', status)}
                  />
                  {watch('settings.display_legend') && (
                    <Select
                      defaultValue={watch('settings.styles.legend_position')}
                      dropWidth={136}
                      options={[
                        { title: t('legend_position_options.bottom'), value: 'bottom' },
                        { title: t('legend_position_options.left'), value: 'left' },
                        { title: t('legend_position_options.right'), value: 'right' },
                      ]}
                      onChange={(value) =>
                        setValue(
                          'settings.styles.legend_position',
                          value as 'bottom' | 'left' | 'right',
                        )
                      }
                    />
                  )}
                </div>
              </div>
              <div className={fieldAndLabelBlock}>
                <span className={valuesLabelPathClassName}>{t('empty_values')}</span>
                <div className={firstBlockValueWidth}>
                  {/*empty_values*/}
                  <Toggle
                    checked={watch('settings.empty_values')}
                    onChecked={(status) => setValue('settings.empty_values', status)}
                  />
                </div>
              </div>
              {watch('settings.type') !== 'columnchart' &&
                watch('settings.type') !== 'piechart' &&
                watch('settings.type') !== 'linechart' && (
                  <div className={fieldAndLabelBlock}>
                    <span className={valuesLabelPathClassName}>{t('nullable_values')}</span>
                    <div className={firstBlockValueWidth}>
                      {/*nullable_values*/}
                      <Toggle
                        checked={watch('settings.nullable_values')}
                        onChecked={(status) => setValue('settings.nullable_values', status)}
                      />
                    </div>
                  </div>
                )}
            </div>
          </div>
          {/*image graph*/}
          <div>
            <Icon name={chartSvgData[watch('settings.type') || 'barchart']} size={431} />
          </div>
          {/*image graph*/}
        </div>
      </div>
    ),
    axis: (
      <>
        <div className=" w-[750px]">
          <div className={headerPathClassName}>{t('data_hor_x')}</div>
          <div className="flex items-start pl-[25px] flex-col gap-[25px]">
            <div className={fieldAndLabelBlock}>
              <span className={valuesLabelPathClassName}>{t('data_x')}</span>
              <div className="w-[270px]">
                {/*settings.group.type*/}
                <Select
                  defaultValue={watch('settings.group.type')}
                  onChange={(value) => setValue('settings.group.type', value as 'date' | 'metric')}
                  dropWidth={216}
                  options={[
                    { title: t('date_call'), value: 'date' },
                    { title: t('metric'), value: 'metric' },
                  ]}
                />
              </div>
            </div>
            <div className={fieldAndLabelBlock}>
              <span className={valuesLabelPathClassName}>{t('choose_category')}</span>
              <div className="w-[270px]">
                {watch('settings.group.type')
                  ? renderSortDataSelect[watch('settings.group.type')]
                  : renderSortDataSelect['date']}
              </div>
            </div>
          </div>
        </div>
        <div className="pt-[38px] w-[750px]">
          {chartDataFields.map((dataField, dataIndex) => (
            <div className="pb-[38px]" key={dataField.id}>
              <div className="flex items-start gap-[20px] ">
                <div className={headerPathClassName}>
                  {t('data_settings')} (y{dataIndex + 1})
                </div>
                {watch('settings.type') !== 'piechart' && (
                  <div className="mt-[3px] flex items-center gap-[5px]">
                    <div>
                      <CircleButton
                        onClick={() => appendChartDataField(chartDataDefaultValue)}
                        size={14}
                        className="text-action"
                        icon="PlusIcon"
                      />
                    </div>
                    {chartDataFields.length > 1 && (
                      <div>
                        <CircleButton
                          onClick={() => removeChartDataField(dataIndex)}
                          size={12}
                          className="text-basic_red"
                          icon="TrashIcon"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="flex items-start pl-[25px] flex-col gap-[25px]">
                <div className={fieldAndLabelBlock}>
                  <span className={valuesLabelPathClassName}>{t('labels.metric_axis')}</span>
                  <div className="w-[270px]">
                    <Select
                      defaultValue={watch(`settings.data.${dataIndex}.metric_id`)}
                      onChange={(value) =>
                        setValue(`settings.data.${dataIndex}.metric_id`, value as string)
                      }
                      options={getSelectOptionsFromMetrics()}
                      dropWidth={216}
                      tooltip={{
                        place: 'left',
                        content:
                          metricList?.find(
                            (metric) =>
                              metric.metric_id === watch(`settings.data.${dataIndex}.metric_id`),
                          )?.name ||
                          metricList?.[0]?.name ||
                          '',
                        id: `metric_axis_y2-${dataField.id}`,
                      }}
                    />
                  </div>
                </div>
                <div className={fieldAndLabelBlock}>
                  <span className={valuesLabelPathClassName}>{t('labels.aggregate_data')}</span>
                  <div className="w-[270px]">
                    <Select
                      defaultValue={watch(`settings.data.${dataIndex}.aggregate`)}
                      onChange={(value) =>
                        setValue(
                          `settings.data.${dataIndex}.aggregate`,
                          value as AggregateSelectForm,
                        )
                      }
                      options={
                        getMetricResultValueType(watch(`settings.data.${dataIndex}.metric_id`)) ===
                        'str'
                          ? aggregateSelectOptions.filter(
                              (item) => item.value === 'count' || item.value === 'prop_num',
                            )
                          : aggregateSelectOptions
                      }
                      dropWidth={216}
                    />
                  </div>
                </div>

                <div className={fieldAndLabelBlock}>
                  <span className={valuesLabelPathClassName}>
                    {watch('settings.type') !== 'linechart'
                      ? t('labels.label_color_col')
                      : t('labels.label_color_line')}
                  </span>
                  <div className={cn('w-[270px]', 'h-full flex items-center')}>
                    {watch('settings.type') !== 'piechart' ? (
                      <ColorInput
                        color={watch(`settings.data.${dataIndex}.color.0`)}
                        changeColor={(color) =>
                          setValue(`settings.data.${dataIndex}.color.0`, color)
                        }
                        label={t('select.color_choices')}
                      />
                    ) : (
                      <div>
                        <DropMenu
                          vladSpecialProp
                          position="start"
                          menu={pieChartPalette.map((palette, paletteIndex) => ({
                            key: palette.colors.join(','),
                            content: (
                              <div
                                key={paletteIndex}
                                className="flex items-center justify-between w-full"
                              >
                                <div className="text-[14px] text-1color">
                                  {t('palette')} {paletteIndex + 1}
                                </div>
                                <div className="flex items-center gap-[5px]">
                                  {palette.colors.map((color, indexColor) => (
                                    <div
                                      key={indexColor}
                                      style={{ background: color }}
                                      className="w-[20px] rounded-full overflow-hidden h-[20px] border-[1px] border-solid border-1color"
                                    ></div>
                                  ))}
                                </div>
                              </div>
                            ),
                          }))}
                          onClick={(key) => {
                            setValue(`settings.data.${dataIndex}.color`, key.split(','));
                          }}
                        >
                          <div className="text-action text-[16px] font-[600] cursor-pointer">
                            {t('palettes')}
                          </div>
                        </DropMenu>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <ConditionalFormattingNestedField nestedFieldIndex={dataIndex} />
            </div>
          ))}
        </div>
      </>
    ),
    additional: (
      <div className="w-[700px]">
        {/*<div className={headerPathClassName}>{t('styles')}</div>*/}
        <div className="flex items-start pl-[25px] flex-col gap-[25px]">
          {/*todo header size escape on few time*/}
          {/*<div className={fieldAndLabelBlock}>*/}
          {/*  <span className={valuesLabelPathClassName}>{t('styles_header_size')}</span>*/}
          {/*  <div className={firstBlockValueWidth}>*/}
          {/*    <Input*/}
          {/*      iconColor="primary"*/}
          {/*      onClick={() => setValue('settings.styles.header_size', 18)}*/}
          {/*      icon="CancelIcon"*/}
          {/*      {...register('settings.styles.header_size')}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={fieldAndLabelBlock}>
            <span className={valuesLabelPathClassName}>{t('styles_text_size')}</span>
            <div className={firstBlockValueWidth}>
              <Input
                iconColor="primary"
                onClick={() => setValue('settings.styles.text_size', 12)}
                icon="CancelIcon"
                {...register('settings.styles.text_size')}
              />
            </div>
          </div>
          <div className={fieldAndLabelBlock}>
            <span className={valuesLabelPathClassName}>{t('styles_text_color')}</span>
            <div className={cn(firstBlockValueWidth, 'h-full flex items-center')}>
              <ColorInput
                color={watch('settings.styles.text_color')}
                changeColor={(color) => setValue('settings.styles.text_color', color)}
                label={t('select.color_choices')}
              />
            </div>
          </div>
          <div className={fieldAndLabelBlock}>
            <span className={valuesLabelPathClassName}>{t('styles_value_position')}</span>
            <div className={firstBlockValueWidth}>
              <Select
                defaultValue={watch('settings.styles.value_position')}
                options={[
                  { title: t('value_positions_options.inside'), value: 'inside' },
                  { title: t('value_positions_options.outside'), value: 'outside' },
                ]}
                onChange={(value) =>
                  setValue('settings.styles.value_position', value as 'inside' | 'outside')
                }
              />
            </div>
          </div>
          {watch('settings.type') !== 'piechart' && (
            <div className={fieldAndLabelBlock}>
              <span className={valuesLabelPathClassName}>{t('styles_grid')}</span>
              <div className={firstBlockValueWidth}>
                <Toggle
                  checked={watch('settings.styles.grid')}
                  onChecked={(status) => setValue('settings.styles.grid', status)}
                />
              </div>
            </div>
          )}
          {watch('settings.type') === 'linechart' && (
            <div className={fieldAndLabelBlock}>
              <span className={valuesLabelPathClassName}>{t('yaxis_min_value')}</span>
              <div className={firstBlockValueWidth}>
                {/*yaxis_min_value*/}
                <Toggle
                  checked={watch('settings.yaxis_min_value')}
                  onChecked={(status) => setValue('settings.yaxis_min_value', status)}
                />
              </div>
            </div>
          )}
          {watch('settings.type') !== 'piechart' && (
            <div className={fieldAndLabelBlock}>
              <span className={valuesLabelPathClassName}>{t('max_group_display')}</span>
              <div className={firstBlockValueWidth}>
                {/*max_group_display*/}
                <Input {...register('settings.max_group_display')} />
              </div>
            </div>
          )}
          {watch('settings.type') !== 'piechart' && (
            <div className="w-[675px]">
              <div className="flex items-start justify-between">
                <div className={valuesLabelPathClassName}>{t('line_settings')}</div>
                <div className={firstBlockValueWidth}>
                  <Toggle
                    checked={watch('settings.line_settings.active')}
                    onChecked={(status) => setValue('settings.line_settings.active', status)}
                  />
                </div>
              </div>
              {watch('settings.line_settings.active') && (
                <div className="flex items-start mt-[25px] flex-col gap-[25px]">
                  <div className={fieldAndLabelBlock}>
                    <span className={valuesLabelPathClassName}>{t('line_settings_value')}</span>
                    <div className={firstBlockValueWidth}>
                      {/*settings.line_settings.value*/}
                      <div className="flex items-center gap-[10px]">
                        <span className={valuesLabelPathClassName}>
                          {t('conditional_formatting_from')}
                        </span>
                        <Input {...register(`settings.line_settings.value.0`)} />
                        <span className={valuesLabelPathClassName}>
                          {t('conditional_formatting_before')}
                        </span>
                        <Input {...register(`settings.line_settings.value.1`)} />
                        <ColorInput
                          color={watch('settings.line_settings.color')}
                          changeColor={(color) => setValue('settings.line_settings.color', color)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    ),
  };

  return (
    <EasyModal
      variant="large"
      onRemove={handlerRemove}
      onSuccess={handlerSuccess}
      label={setup?.name || t('add_chart')}
      show={isOpen}
      onClose={() => onClose(false)}
      isAutoClosable={false}
    >
      <>
        <div className="flex item-center gap-[10px] my-[20px] bg-basic_app_bg rounded-defaultR border-b border-b_medium">
          {settingsNavMenuData.map(({ name, value }, index) => (
            <div
              key={index}
              onClick={() => changeActiveMenuItem(value as 'main' | 'additional' | 'axis')}
              className={settingsNavStyles(value === activeMenuItem)}
            >
              {name}
            </div>
          ))}
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="py-[30px]">{renderMenuItems[activeMenuItem]}</div>
            {/*submit*/}
            <input className="hidden" type="submit" ref={submitFormRef} />
            {/*submit*/}
          </form>
        </FormProvider>
      </>
    </EasyModal>
  );
};

export default SettingsFormBlock;
