import { GetFilterMetricResultParams } from '@app/interfaces/filter';

import apiInstance from '../common.api';

export const getMetricsBulk = async (data: GetFilterMetricResultParams) => {
  return await apiInstance.post('/api/front/project/metric_result/bulk', data, {
    params: {
      project_id: data.project_id,
    },
  });
};
