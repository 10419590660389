module.exports = {
  // A component inside the metrics words and phrases search and in dictionaries (pop-up window)
  word_err: 'This word/word combination is already in use',
  dict_err: 'This dictionary is already in use',
  word_for_search: 'Words/phrases for search.',
  dict: 'Dictionaries',
  popup_hints: {
    delete_icon: 'Delete',
    add_dictionary_from_list: 'Add dictionary from the list',
    to_dictionary: 'Go to dictionary',
  },
};
