import React, { ReactNode } from 'react';
import { CalculatedRecordMetric, Dialog } from '@app/interfaces/records';

export default function activeMetricBuilder({
  activeMetricSetup,
  dialog,
  lastDistance,
}: {
  activeMetricSetup: CalculatedRecordMetric[string] | null;
  dialog: Dialog;
  lastDistance: { distance: number; owner: string };
}) {
  let forgotDistance = lastDistance.distance;
  let coloredCountIterator = 0;
  const currentActiveMetric =
    forgotDistance > 0 && dialog.channel_id === '0' && activeMetricSetup
      ? {
          ...activeMetricSetup,
          settings: {
            ids: [
              dialog.words[0].word_idx,
              ...(activeMetricSetup?.settings?.ids ? activeMetricSetup.settings.ids : []),
            ],
            distance: [
              lastDistance.distance,
              ...(activeMetricSetup.settings?.distance ? activeMetricSetup.settings.distance : []),
            ],
          },
        }
      : activeMetricSetup;
  const phrase = currentActiveMetric ? (
    dialog.words
      .reduce<Array<ReactNode>>((acc, wordList, index) => {
        const coloredIndex = currentActiveMetric?.settings?.ids.includes(wordList.word_idx);
        const coloredCount =
          coloredIndex &&
          currentActiveMetric?.settings?.distance[
            currentActiveMetric?.settings?.ids.indexOf(wordList.word_idx)
          ];
        forgotDistance = forgotDistance < 1 ? coloredCount || 0 : forgotDistance;
        if (coloredIndex && coloredCount) {
          forgotDistance -= coloredCount;
          acc.push(
            <span key={index}>
              <span
                className="text-white rounded-[3px] p-[1px]"
                style={{ backgroundColor: `#${currentActiveMetric.color}` }}
              >
                {[...dialog.words]
                  .splice(index, coloredCount)
                  .map((wordsInfo) => wordsInfo.word)
                  .join(' ')}
              </span>
            </span>,
          );
          coloredCountIterator = coloredCount;
        } else {
          coloredCountIterator -= 1;
          if (coloredCountIterator < 1) acc.push(wordList.word);
        }
        return acc;
      }, [])
      .map((word, index) => <span key={index}>{word} </span>)
  ) : (
    <>{dialog.words.map(({ word }) => word).join(' ')}</>
  );
  return {
    phrase,
    lastDistance: { coloredCountIterator: forgotDistance, owner: dialog.channel_id },
  };
}
