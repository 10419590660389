import React, { FC, useMemo } from 'react';
import { Outlet } from 'react-router';
import { useGetAnalyticsProjectsListQuery } from '@app/store/api/analytics-projects.api';
import { NotFoundedPage } from '@app/pages';
import useTranslation from '@app/hooks/use-translation';
import { useGetChatGroupQuery } from '@app/store/api/chat-api/chat-analytic.api';

type LastProjectOutletPropsType = {
  currentTemplate: 'chat' | 'voice';
};
const LastProjectOutlet: FC<LastProjectOutletPropsType> = (props) => {
  const { currentTemplate } = props;
  const { t } = useTranslation('pages.recordsByAnalytics');
  const { data: analyticsProjectsGroupsVoice } = useGetAnalyticsProjectsListQuery(null, {
    skip: currentTemplate === 'chat',
  });
  const { data: analyticsProjectsGroupsChat } = useGetChatGroupQuery(null, {
    skip: currentTemplate === 'voice',
  });

  const analyticsProjectsGroups = useMemo(
    () => ({
      voice: analyticsProjectsGroupsVoice,
      chat: analyticsProjectsGroupsChat,
    }),
    [analyticsProjectsGroupsChat, analyticsProjectsGroupsVoice],
  );

  const projectsCounter = analyticsProjectsGroups[currentTemplate]?.reduce(
    (acc, cur) => (acc += cur.projects.length),
    0,
  );
  if (!projectsCounter) return <NotFoundedPage text={t('error_page_label')} />;

  return <Outlet />;
};

export default LastProjectOutlet;
