import { PopoverMenuItem } from '@app/components/ui/popover/popover.type';
import { actionsIds } from '@app/interfaces/analytics';

export const tableColumns = [
  {
    title: 'name',
    index: 'name',
    className: 'ml-[45px]',
  },
  {
    title: 'status',
    index: 'status',
  },
  {
    title: 'open',
    index: 'open',
  },
  {
    title: 'actions',
    index: 'actions',
    maxWidth: 110,
  },
];
export const actionsMenu: PopoverMenuItem[] = [
  {
    title: 'move_group',
    key: actionsIds.ACTIONS_MOVE_TO_GROUP,
    icon: 'MoveIcon',
  },
  {
    title: 'remove_choosen',
    key: actionsIds.ACTIONS_DELETE_SELECTED,
    icon: 'TrashIcon',
    color: 'danger',
  },
];
