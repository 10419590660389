import React from 'react';

import { Box, PageHeader } from '@ui';
import { navigationRoutes } from '@app/utils/navigation-routes';
import useTranslation from '@app/hooks/use-translation';

import MainSettings from './blocks/main-settings';

const NewProjectPage = () => {
  const { t } = useTranslation('pages.mainSettingsAp');
  return (
    <div>
      <PageHeader popLink={`/${navigationRoutes.analytics}`} label={t('create_new_ap_title')} />
      <Box className="mt-[42px]">
        <MainSettings newProjFlag={true} />
      </Box>
    </div>
  );
};

export default NewProjectPage;
