module.exports = {
  recipients: 'Messages recipients',
  true: 'Confirmed',
  false: 'Unconfirmed',
  email_create_description: 'An email will be sent to the new recipient at the specified email address asking them to acknowledge receipt of the messages. Sending messages will start after confirmation of the action.““An email will be sent to the new recipient at the specified email address to confirm receipt of messages. Sending messages will start after the action is confirmed.',
  telegram_create_description: 'To confirm Telegram user, after adding to the list of recipients, you need to go to the bot page at the link XXXXXXXXXX and enter the authorization key, which you will see after adding the recipient. You can read more about working with the bot in the User Manual section.',
  hash_modal_label: 'Telegram bot authorization key',
  hash_modal_description: 'To add a Telegram user to the list of recipients, you need to visit the bot page by <botLink>%{tgLink}</botLink> and enter the authorization key specified below. You can read more about working with the bot in the <manualLink>User Guide</manualLink> section.',
  hash_modal_key_description: 'Copy the key. It will not be possible to restore the key after closing the window',
  copy_success: 'Key is copied',
  copy_error: 'Failed to copy the key',
  tg_bot_link: 'https://t.me/asdf6463ggbot',
  addRecipientNavigationHelper:"New message recipients are added under <manualLink>Message Recipients</manualLink>"
}