import React, { FC } from 'react';
import { PlayCircleIcon } from '@app/components/ui/icons/icons-list';
import useTimeLengthHumanize from '@app/hooks/use-time-length-humanize';
import { NavLink } from 'react-router-dom';

const TimeDuration: FC<{ duration: number; link: string }> = (props) => {
  const { duration, link } = props;
  const timeFormat = useTimeLengthHumanize('H:M:S');
  return (
    <NavLink
      to={link}
      target={'_blank'}
      defaultChecked={true}
      className="rounded-full h-[21px] font-[700] visited:!text-4color text-0color leading-1 pr-[10px] text-sm bg-bg_3 inline-flex items-center"
    >
      <PlayCircleIcon size={22} className="text-3color mr-[8px]" />
      {timeFormat(duration)}
    </NavLink>
  );
};

export default TimeDuration;
