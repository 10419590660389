module.exports = {
  success: {
    title: 'You have successfully subscribed to receive messages from the speech analytics system.',
    description: 'You can unsubscribe from receiving messages by clicking on the link inside each message, or by changing the mailing settings inside the system'
  },
  error: {
    title: 'Error when attempting to subscribe to receive messages from the speech analytics system.',
    description: 'Try to subscribe again in a few minutes. In case of failure - report the problem to the technical support service'
  },
  button_label: 'Go to system',
  report: {
    title: 'Your address has been removed from the list of recipients of this report.',
    description: 'You will continue to receive messages from other reports if you have been subscribed to them.',
    title_err: 'Error when attempting to unsubscribe you from this report.',
    description_err: 'Try to subscribe again in a few minutes. In case of failure - report the problem to the technical support service'
  },
  trigger: {
    title: 'Your address has been removed from the list of recipients of this trigger.',
    description: 'You will continue to receive messages from other triggers if you have been subscribed to them.',
    title_err: 'Error attempting to unsubscribe you from this trigger.',
    description_err: 'Try to subscribe again in a few minutes. In case of failure - report the problem to the technical support service'
  },
  all: {
    title: 'Your address has been removed from the recipient list for all mail lists.',
    description: 'To renew the subscription to the mailing list it is necessary to add the recipient to the System again.',
    title_err: 'Error when trying to unsubscribe you from all mailing lists.',
    description_err: 'Try to subscribe again in a few minutes. In case of failure - report the problem to the technical support service'
  },
}