import {
  ChatMetricObjType,
  MetricTypesArrType,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Button, CircleButton, Input, Select, Toggle, TooltipWrapper } from '@ui';

import { AutoFocusHook } from '@app/utils/helpers';

import { AllStatusProject } from '@app/interfaces/analytics';

import useTranslation from '@app/hooks/use-translation';

import { Controller, useForm } from 'react-hook-form';

import { WordsListComponent } from '@app/components/ui/add-words-and-dictionaries-container/wordsListComponent';

import { ChatMetricTemplatesType } from '@app/interfaces/slices-types/metric-templates.type';

import {
  useCreateMetricTemplateMutation,
  useEditMetricTemplateMutation,
  useGetMetricTemplateGroupsListQuery,
} from '@app/store/api/metric-template.api';

import { useGetAllDictionariesListQuery } from '@app/store/api/dictionaries.api';

import {
  useCreateProjectChatMetricGroupMutation,
  useEditProjectChatMetricGroupMutation,
} from '@app/store/api/chat-api/chat-metric.api';

import { ChatRoleSettingsType } from '@app/interfaces/chat-roles-settings.type';

import { ColorInput } from '../../color-input/color-input';

import { AddToTemplateModal } from '../../add-to-template-modal/add-to-template-modal';
import { VadModalsChildrenType } from '../../constantsForModals';

type FormType = {
  activeColor: string;
  switchValue: boolean;
  name: string;
  c_role: string;
  exclude_begin_messages: number;
  exclude_end_messages: number;
  select: string;
  wordsListArray: string[];
  dictionariesListArray: string[];
};

export const ChatFindWordsModal: React.FC<{
  modalsSetState: (type: string) => void;
  currentTemplate: 'voice' | 'chat';
  copyModal: boolean;
  dataFields: VadModalsChildrenType[];
  metric: MetricTypesArrType;
  editModal?: boolean;
  metricTemplate?: boolean;
  createFromTemplate?: boolean;
  status?: AllStatusProject | undefined;
  page?: string;
  viewOnly?: boolean;
  chatRoles?: ChatRoleSettingsType[];
  metricSettings?: ChatMetricObjType | undefined;
  metricTemplateSettings?: ChatMetricTemplatesType | undefined;
}> = ({
  dataFields,
  metric,
  editModal,
  chatRoles,
  modalsSetState,
  metricTemplate,
  createFromTemplate,
  status,
  page,
  copyModal,
  viewOnly,
  metricTemplateSettings,
  metricSettings,
  currentTemplate,
}) => {
  const { id } = useParams();
  const { t } = useTranslation('pages.metricTemplates');
  const { t: tf } = useTranslation('common.forms');
  const [createTemplateModal, setCreateTemplateModal] = React.useState(false);
  //api
  const { data: metricTemplates } = useGetMetricTemplateGroupsListQuery(currentTemplate);
  const { data: dictionaries } = useGetAllDictionariesListQuery();
  const [createMetric] = useCreateProjectChatMetricGroupMutation();
  const [editMetric] = useEditProjectChatMetricGroupMutation();
  const [editMetricTemplate] = useEditMetricTemplateMutation();
  const [createMetricTemplate] = useCreateMetricTemplateMutation();
  const defaultValue = (): FormType => {
    if (editModal) {
      if (metricTemplate) {
        return {
          wordsListArray: metricTemplateSettings?.settings.wordlist || [],
          dictionariesListArray: metricTemplateSettings?.settings.dictionaries || [],
          activeColor: `#${metricTemplateSettings?.settings.color?.toLowerCase() || ''}`,
          switchValue: metricTemplateSettings?.settings.all_word_forms || false,
          name: metricTemplateSettings?.name || '',
          c_role: metricTemplateSettings?.settings.c_role || '',
          exclude_begin_messages: metricTemplateSettings?.settings.exclude_begin_messages || 0,
          exclude_end_messages: metricTemplateSettings?.settings.exclude_end_messages || 0,
          select: metricTemplateSettings?.metric_templates_group_id || 'default',
        };
      } else {
        return {
          wordsListArray: metricSettings?.settings.wordlist || [],
          dictionariesListArray: metricSettings?.settings.dictionaries || [],
          activeColor: `#${metricSettings?.settings.color?.toLowerCase() || ''}`,
          switchValue: metricSettings?.settings.all_word_forms || false,
          name: metricSettings?.name || '',
          c_role: metricSettings?.settings.c_role || '',
          exclude_begin_messages: metricSettings?.settings.exclude_begin_messages || 0,
          exclude_end_messages: metricSettings?.settings.exclude_end_messages || 0,
          select: '',
        };
      }
    } else {
      if (createFromTemplate) {
        return {
          wordsListArray: metricTemplateSettings?.settings.wordlist || [],
          dictionariesListArray: metricTemplateSettings?.settings.dictionaries || [],
          activeColor: `#${metricTemplateSettings?.settings.color?.toLowerCase() || ''}`,
          switchValue: metricTemplateSettings?.settings.all_word_forms || false,
          name: metricTemplateSettings?.name || '',
          c_role: metricTemplateSettings?.settings.c_role || '',
          exclude_begin_messages: metricTemplateSettings?.settings.exclude_begin_messages || 0,
          exclude_end_messages: metricTemplateSettings?.settings.exclude_end_messages || 0,
          select: metricTemplateSettings?.metric_templates_group_id || 'default',
        };
      } else
        return {
          activeColor: '',
          switchValue: false,
          name: '',
          c_role: '',
          exclude_begin_messages: 0,
          exclude_end_messages: 0,
          select: 'default',
          wordsListArray: [],
          dictionariesListArray: [],
        };
    }
  };
  const {
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    clearErrors,
    control,
  } = useForm<FormType>({ defaultValues: defaultValue() });
  const copyToClipboard = useCallback(() => {
    event?.preventDefault();
    let textToCopy: string | undefined = '';
    if (metricSettings && !metricTemplate) {
      textToCopy = metricSettings.metric_id;
    } else if (metricTemplateSettings && metricTemplate) {
      textToCopy = metricTemplateSettings.metric_template_id;
    }
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy);
    }
  }, [metricSettings, metricTemplateSettings, metricTemplate]);
  const disableFunc = () => {
    if (status === AllStatusProject.PAUSE) {
      return false;
    }
    return status !== AllStatusProject.CREATED;
  };
  const resetAsyncForm = () => {
    modalsSetState(metric.type);
  };

  const onSubmit = (data) => {
    const obj: ChatMetricObjType = {
      name: data.name,
      description: ' ',
      type: metric.type,
      result_value_type: metric.result_value_type,
      settings: {
        c_role: data.c_role,
        all_word_forms: data.switchValue,
        color: data.activeColor?.slice(1).toUpperCase(),
        wordlist: data.wordsListArray,
        dictionaries: data.dictionariesListArray,
        exclude_begin_messages: data.exclude_begin_messages,
        exclude_end_messages: data.exclude_end_messages,
      },
    };
    if (!editModal) {
      if (metricTemplate) {
        createMetricTemplate({ params: { metric_templates_group_id: watch('select') }, body: obj });
      } else {
        id && createMetric({ params: { project_id: id }, body: obj });
      }
    } else {
      if (metricSettings?.metric_id) {
        id &&
          editMetric({
            params: { project_id: id, metric_id: metricSettings.metric_id },
            body: obj,
          });
      } else if (metricTemplate) {
        if (copyModal) {
          createMetricTemplate({
            params: { metric_templates_group_id: watch('select') },
            body: obj,
          });
        } else {
          metricTemplateSettings?.metric_template_id &&
            editMetricTemplate({
              params: {
                metric_templates_group_id: watch('select'),
                id: metricTemplateSettings?.metric_template_id,
              },
              body: obj,
            });
        }
      }
    }
    resetAsyncForm();
  };
  const getDefaultTemplate = () => {
    return metricTemplates?.find((e) => e.is_default)?.metric_templates_group_id;
  };
  const createMetricTemplateApplyBtn = () => {
    const obj: ChatMetricObjType = {
      name: watch('name') as string,
      description: ' ',
      type: metric.type,
      result_value_type: metric.result_value_type,
      settings: {
        c_role: watch('c_role'),
        all_word_forms: watch('switchValue'),
        color: watch('activeColor').slice(1).toUpperCase(),
        wordlist: watch('wordsListArray'),
        dictionaries: watch('dictionariesListArray'),
        exclude_begin_messages: watch('exclude_begin_messages'),
        exclude_end_messages: watch('exclude_end_messages'),
      },
    };
    createMetricTemplate({
      params: { metric_templates_group_id: getDefaultTemplate() as string },
      body: obj,
    }).then(() => {
      resetAsyncForm();
      setCreateTemplateModal(false);
    });
    // .catch(() => {
    //   toast.error(t('fill_all'));
    // });
  };

  const clickColor = (event) => {
    setValue('activeColor', event.currentTarget.value);
  };
  const inputFocusRef = AutoFocusHook();

  const getSelectItems = () => {
    return metricTemplates?.map((e) => {
      return { title: e.name, value: e.metric_templates_group_id };
    });
  };
  function changeDictionariesArray(word: string[]) {
    setValue('dictionariesListArray', word);
  }
  function changeWordsArray(word: string[]) {
    setValue('wordsListArray', word);
  }
  function handleClearWordsComponent() {
    setValue('wordsListArray', []);
    setValue('dictionariesListArray', []);
  }
  return (
    <div className={`w-full justify-between flex gap-[30px]`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full relative">
          <div className={`flex flex-col gap-[20px] w-[500px]`}>
            <div className="flex items-center justify-between">
              <span className="max-w-[218px] text-0color text-[15px]">{t('metric_group')}</span>
              <span className="mr-[150px]">
                <b>
                  {metric.group.toUpperCase()} {t('one_metric')}
                </b>
              </span>
            </div>
            <div>
              {dataFields.map((e) => {
                return e.inputText.map((e) => {
                  return (
                    <div className="flex items-center justify-between" key={e.id}>
                      <span className="max-w-[218px] text-0color text-[15px]">{e.labelTitle}</span>
                      <div className="w-[280px]">
                        <Input
                          {...inputFocusRef}
                          {...register('name', {
                            required: { value: true, message: tf('required') },
                          })}
                          name="name"
                          placeholder={e.placeholder}
                          error={errors.name?.message}
                        />
                      </div>
                    </div>
                  );
                });
              })}
            </div>
            {metricTemplate && (
              <div className="flex items-center justify-between">
                <span className="text-0color text-[15px]">{t('metric_templates_group')}</span>
                <div className="w-[280px]">
                  <Controller
                    render={() => (
                      <Select
                        error={errors.select?.message}
                        defaultValue={watch('select')}
                        options={[
                          ...(getSelectItems() || []),
                          {
                            title: t('choose') + '...',
                            value: 'default',
                            disabled: true,
                            nullable: true,
                          },
                        ]}
                        onChange={(value) => {
                          setValue('select', value as string);
                          clearErrors('select');
                        }}
                      />
                    )}
                    name={'select'}
                    rules={{ validate: (value) => value !== 'default' || tf('required') }}
                    control={control}
                  />
                </div>
              </div>
            )}
            <div className="flex items-center justify-between mr-[140px] relative">
              <span className="max-w-[218px] text-0color text-[15px]">{t('role')}</span>
              <Controller
                render={() => (
                  <div className="flex flex-col min-w-[130px] max-w-[130px]">
                    <div className="flex items-center gap-[10px]">
                      <input
                        name="c_role"
                        onChange={(e) => {
                          setValue('c_role', e.target.value);
                          clearErrors('c_role');
                        }}
                        type="checkbox"
                        value={'all'}
                        checked={watch('c_role') === 'all'}
                        className="focus:ring-offset-0 focus:ring-0 rounded-full"
                      />
                      <label className="text-[14px] text-0color">{t('all')}</label>
                    </div>
                    {chatRoles?.map((roleSettings) => {
                      return (
                        <div className="flex items-center gap-[10px]" key={roleSettings.crole_id}>
                          <input
                            name="c_role"
                            onChange={(e) => {
                              setValue('c_role', e.target.value);
                              clearErrors('c_role');
                            }}
                            type="checkbox"
                            checked={watch('c_role') === roleSettings.crole_id}
                            value={roleSettings.crole_id}
                            className="focus:ring-offset-0 focus:ring-0 rounded-full"
                          />
                          <label className="text-[14px] text-0color">{roleSettings.name}</label>
                        </div>
                      );
                    })}
                  </div>
                )}
                name={'c_role'}
                control={control}
                rules={{ validate: (value) => !!value || tf('required') }}
              />
              {errors.c_role?.message && (
                <div
                  className={
                    'absolute bottom-[-17px] right-[20px] text-sm text-red-600 dark:text-red-500'
                  }
                >
                  {errors.c_role?.message}
                </div>
              )}
            </div>
            <>
              <div className="flex items-center justify-between mr-[247px]">
                <span className="max-w-[218px] text-0color text-[15px]">{t('all_words')}</span>
                <Toggle
                  onChecked={(e) => setValue('switchValue', e)}
                  size={'large'}
                  checked={watch('switchValue')}
                />
              </div>
              <div className="flex items-center justify-between relative">
                <span className="max-w-[218px] text-0color text-[15px]">{t('color')}</span>
                <Controller
                  render={() => (
                    <div id="colors" className="flex items-center gap-[6px] mr-[23px]">
                      <ColorInput
                        onClick={(event) => {
                          clickColor(event);
                          clearErrors('activeColor');
                        }}
                        active={watch('activeColor')}
                      />
                    </div>
                  )}
                  name={'activeColor'}
                  control={control}
                  rules={{ validate: (value) => !!value || tf('required') }}
                />
                {errors.activeColor?.message && (
                  <div
                    className={
                      'absolute bottom-[-17px] right-[160px] text-sm text-red-600 dark:text-red-500'
                    }
                  >
                    {errors.activeColor?.message}
                  </div>
                )}
              </div>
            </>
            {dataFields.map((e) => {
              return e.inputNumberChat.map((e, index) => {
                return (
                  <div key={e.id} className="flex items-center justify-between">
                    <span className="text-0color max-w-[200px] text-[15px]">{e.labelTitle}</span>
                    <div className="bg-transparent w-[180px] mr-[101px]">
                      <Input
                        {...register(e.id as 'exclude_begin_messages' | 'exclude_end_messages')}
                        max={1000000}
                        min={0}
                        name={e.id}
                        key={index}
                        type={'number'}
                      />
                    </div>
                  </div>
                );
              });
            })}
          </div>
          <div className="sticky z-50 bottom-0 w-full">
            <div className="bg-[#fff] ">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center justify-between gap-[15px] py-4">
                  <div className="gap-[15px] flex">
                    <Button
                      disabled={(page !== 'metricTemplate' && disableFunc()) || viewOnly}
                      type="submit"
                      label={t('save')}
                    />
                    <Button onClick={() => resetAsyncForm()} fill="linked" label={t('cancel')} />
                  </div>
                  <div>
                    {page !== 'metricTemplate' && !createFromTemplate && (
                      <Button
                        onClick={() => setCreateTemplateModal(true)}
                        fill="linked"
                        icon="FolderConfigIcon"
                        label={t('save_as_template')}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <Button
                    onClick={() => handleClearWordsComponent()}
                    fill="linked"
                    variant="danger"
                    label={t('clear_all')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="flex flex-col">
        <WordsListComponent
          dictionaries={dictionaries}
          className="!w-[503px]"
          wordsListArray={watch('wordsListArray')}
          setWordsListArray={changeWordsArray}
          dictionariesListArray={watch('dictionariesListArray')}
          setDictionariesListArray={changeDictionariesArray}
        />
        <AddToTemplateModal
          show={createTemplateModal}
          name={watch('name')}
          applyBtn={createMetricTemplateApplyBtn}
          onClose={setCreateTemplateModal}
        />
        {editModal && (
          <div className="text-0color text-[15px] w-full h-full flex items-end justify-start gap-3 mb-[20px]">
            <div className="mb-[3px]">{t('metric_id')}</div>
            <div className="flex gap-1">
              <p>
                {metricTemplate
                  ? metricTemplateSettings?.metric_template_id
                  : metricSettings?.metric_id}
              </p>
              <TooltipWrapper content={t('popup_hints.copy_id')} id={'spech_metrick_copy'}>
                <CircleButton
                  onClick={copyToClipboard}
                  icon="CopyIcon"
                  className="text-action mb-[5px]"
                  size={16}
                />
              </TooltipWrapper>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
