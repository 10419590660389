import React, { FC } from 'react';

import { IconType } from '../icon.type';

const StarBubbleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_609_2)">
          <path
            d="M9.80762 23.4453C10.2998 23.4453 10.6689 23.208 11.2666 22.6807L14.2812 20.0088H19.5986C22.2441 20.0088 23.7295 18.4971 23.7295 15.8867V9.06641C23.7295 6.45605 22.2441 4.94434 19.5986 4.94434H8.39258C5.74707 4.94434 4.26172 6.45605 4.26172 9.06641V15.8867C4.26172 18.4971 5.78223 20.0088 8.33105 20.0088H8.69141V22.1797C8.69141 22.9619 9.10449 23.4453 9.80762 23.4453ZM10.2383 21.5117V19.0068C10.2383 18.4883 10.0186 18.2861 9.51758 18.2861H8.43652C6.77539 18.2861 5.98438 17.4512 5.98438 15.834V9.11914C5.98438 7.50195 6.77539 6.66699 8.43652 6.66699H19.5459C21.207 6.66699 22.0068 7.50195 22.0068 9.11914V15.834C22.0068 17.4512 21.207 18.2861 19.5459 18.2861H14.1846C13.6572 18.2861 13.3936 18.374 13.0244 18.7607L10.2383 21.5117ZM12.1631 15.7988L14.0176 14.4365L15.8721 15.7988C16.25 16.0801 16.6982 15.8252 16.5225 15.3154L15.8018 13.1445L17.6914 11.7822C18.0781 11.501 17.9287 11.0176 17.4541 11.0176H15.1162L14.4219 8.85547C14.2725 8.40723 13.7539 8.40723 13.6133 8.85547L12.9102 11.0176H10.5635C10.0977 11.0176 9.93945 11.501 10.335 11.7822L12.2334 13.1445L11.5039 15.3154C11.3281 15.8164 11.7764 16.0801 12.1631 15.7988Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_609_2">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default StarBubbleIcon;
