import React, { FC } from 'react';

import { IconType } from '../icon.type';

const BoltRingClosedIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_903_18)">
          <path
            d="M4.91211 13.6543C4.91211 18.6641 8.99023 22.7422 14 22.7422C19.0098 22.7422 23.0879 18.6641 23.0879 13.6543C23.0879 9.11914 19.7568 5.35742 15.4062 4.68066C15.5908 4.95312 15.7051 5.27832 15.7051 5.63867C15.7051 6.0957 15.5205 6.51758 15.2217 6.81641C18.4648 7.40527 20.9434 10.2529 20.9434 13.6543C20.9434 17.4775 17.832 20.5977 14 20.5977C10.1768 20.5977 7.05664 17.4775 7.05664 13.6543C7.05664 9.82227 10.1768 6.71094 14 6.71094C14.5889 6.71094 15.0723 6.22754 15.0723 5.63867C15.0723 5.0498 14.5889 4.56641 14 4.56641C8.99023 4.56641 4.91211 8.64453 4.91211 13.6543ZM10.3877 14.1992C10.3877 14.4277 10.5723 14.5947 10.8008 14.5947H13.5166L12.0664 18.4619C11.8643 19.0244 12.4619 19.3408 12.8398 18.8838L17.3047 13.3291C17.3838 13.2148 17.4453 13.1006 17.4453 12.9863C17.4453 12.7578 17.2607 12.5908 17.0322 12.5908H14.3164L15.7666 8.72363C15.9688 8.16992 15.3711 7.84473 14.9932 8.30176L10.5283 13.8564C10.4492 13.9707 10.3877 14.085 10.3877 14.1992Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_903_18">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default BoltRingClosedIcon;
