import React, { useState, useRef, useEffect } from 'react';

type InputFieldProps = {
  aliasId: string;
  typeMeta: string;
  typeValue: string;
  initialValue: string;
  error: string;
  activeInput: any;
  onChange: (aliasId: string, typeMeta: string, typeValue: string, value: string) => void;
};
export const InputField = React.memo(
  ({
    aliasId,
    typeMeta,
    typeValue,
    initialValue,
    error,
    onChange,
    activeInput,
  }: InputFieldProps) => {
    const [localValue, setLocalValue] = useState(initialValue || '');
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (e) => {
      const value = e.target.value;
      setLocalValue(value);
      onChange(aliasId, typeMeta, typeValue, value);
    };

    useEffect(() => {
      if (inputRef.current && activeInput === aliasId) {
        inputRef.current.focus();
      }
    }, [localValue, activeInput, aliasId]);

    return (
      <>
        <input
          ref={inputRef}
          className="w-[214px] text-[17px] rounded-[10px] border-[1px] px-[10px] py-[3px]"
          value={localValue}
          onChange={handleChange}
        />
        {error && <div className="text-basic_red text-[12px]">{error}</div>}
      </>
    );
  },
);

InputField.displayName = 'InputField';
