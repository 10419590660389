module.exports = {
  analytic_title: 'Call analytics',
  templates_title: 'Templates',
  settings_title: 'Settings',
  main: '',
  dashboard: 'Dashboard',
  all_records: 'All calls records',
  analytic_proj: 'Analytical projects',
  proj_records: 'Project records',
  reports: 'Reports',
  presets_templates: 'Presets templates',
  metrics_templates: 'Metrics templates',
  dictionaries: 'Dictionaries',
  system_settings: 'System settings',
  audit_logs: 'Audit logs',
  users: 'Users',
  words_cloud: 'Words cloud',
  words_n_gramm: 'Phrase cloud',
  all_chats: "All chats records",
  chat_analytic: "Chat analytics",
  chat_metric_templates: "Chat metrics templates",
};
