module.exports = {
  error_page_label: 'No projects found',
  popup_hints: {
    to_record_title: 'View record',
    download_record: 'Download record',
  },
  filter: {
    title: 'Filter project records',
    buttons: {
      create_report: 'Create a LIVE report based on a filter',
      create_report_rtk: 'Create report based on a filter',
      reset_filter: 'Clear',
    }
  },
  select_analytic_project: {
    label: 'Analytical project',
    select_placeholder: 'Specify analytical project'
  },
  table: {
    first_column_title: 'Duration',
    last_column_title: 'Actions',
  },
  metric_records: 'Records are in line for calculation: '
}
