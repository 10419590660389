import React from 'react';
import 'core-js/actual';
import ReactDOM from 'react-dom/client';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

import './index.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import './App.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
// import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import App from '@app/App';

import 'tw-elements';
import { logout } from './utils/reactTokenAuth';

registerLocale('ru', ru);
setDefaultLocale('ru');

// ====

window.addEventListener('storage', () => {
  if (!localStorage.getItem('REACT_TOKEN_AUTH_KEY')) {
    logout();
    if (window.location.pathname !== '/authorization') {
      if (process.env.REACT_APP_EXT_AUTH === 'true' && process.env.REACT_APP_EXT_AUTH_URL) {
        window.location.replace(process.env.REACT_APP_EXT_AUTH_URL);
      } else window.location.href = '/authorization';
    }
  }
});

// ====

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
