import { useCallback } from 'react';
import { useTranslation as i18nUseTranslation } from 'react-i18next';
import { defaultLanguage } from '@app/i18n';

export const useTranslation = (translationContextKey) => {
  const { t, ...i18nProps } = i18nUseTranslation();
  //TODO: load other language here
  const lang = localStorage.getItem('lang') || 'ru';
  const translate = useCallback(
    (translationKey: string, ...rest: any) => {
      const result = translationContextKey
        ? t(
            [
              `${lang}.${translationContextKey}.${translationKey}`,
              `${defaultLanguage}.${translationContextKey}.${translationKey}`,
            ],
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...rest,
          )
        : t(
            translationKey,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...rest,
          );
      return result as unknown as string;
    },
    [t, translationContextKey, lang],
  );

  return { t: translate, ...i18nProps };
};

export default useTranslation;
