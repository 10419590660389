import { EasyModal } from '@app/components/ui';
import { LetterA } from '@app/components/ui/icons/icons-list';
import React, { FC } from 'react';

import useTranslation from '@app/hooks/use-translation';

import { AliasSettingsForCreate, AliasSettingsObj } from '@app/interfaces/settings.type';

import { AliasEditCreateForm } from './alias-edit-create-form';
type ModalWithForm = {
  isOpenAliasModal: boolean;
  changeIsOpenAliasModal: React.Dispatch<React.SetStateAction<boolean>>;
  changeEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  onFormSubmitHandler(data: AliasSettingsForCreate): void;
  editMode: boolean;
  aliasData: AliasSettingsObj | undefined;
  deleteAliasHandle(id: string): void;
};
export const ModalWithFormAliasSettings: FC<ModalWithForm> = (props) => {
  const {
    isOpenAliasModal,
    changeIsOpenAliasModal,
    onFormSubmitHandler,
    editMode,
    changeEditMode,
    aliasData,
    deleteAliasHandle,
  } = props;
  const { t } = useTranslation('pages.settings');
  return (
    <EasyModal
      show={isOpenAliasModal}
      onRemove={() => changeEditMode(false)}
      label={
        <div className="flex flex-col gap-[13px] items-center justify-center">
          <div className="w-[57px] h-[57px] rounded-full bg-blue_pal flex items-center justify-center">
            <LetterA className="text-action" size={19} />
          </div>
          <span className="text-0color text-[24px] font-[700] leading-[29px]">
            {editMode ? t('alias.modal_label_edit') : t('alias.modal_label')}
          </span>
        </div>
      }
      onClose={() => {
        editMode && changeEditMode(false);
        changeIsOpenAliasModal(false);
      }}
      variant="medium484"
      withoutContentPadding
      withoutFooter
    >
      <AliasEditCreateForm
        deleteAliasHandle={deleteAliasHandle}
        aliasData={aliasData}
        editMode={editMode}
        onFormSubmit={onFormSubmitHandler}
      />
    </EasyModal>
  );
};
