module.exports = {
  popup_hints: {
    settings_icon: 'Set up',
    delete_icon: 'Delete',
    templates_list_count: 'Number of templates in group',
    delete_template_icon: 'Delete template',
    settings_template_icon: 'Set up template',
    copy_template_icon: 'Copy template',
  },
  create_new_project_button: 'Create project',
  move_group: 'Move to group',
  refresh: 'Refresh',
  start_project: 'Start project',
  stop_project: 'Stop project',
  remove_choosen: 'Delete selected',
  name: 'Name',
  status: 'Status',
  open: 'Open',
  actions: 'Actions',
  records: 'Records',
  reports: 'Reports',
  group_name_not_selected: 'Group name was not selected',
  status_will_be: 'Project status will be',
  status_will_be_changed: 'Project status will be changed from',
  to: 'to',
  cant_change_status: 'Failed to change project status',
  write_name: 'Enter the name',
  analytic_proj: 'Analytical projects',
  not_projects: 'No projects',
  not_groups: 'No groups created',
  new_group: 'New group',
  delete_group: 'Delete group',
  delete_proj: 'Delete project',
  delete_selected_proj: 'Delete selected projects',
  delete_group_confirm: 'Do you really want to delete this group?',
  delete_proj_confirm: 'All analytical project data, including reports, will be deleted.',
  delete_proj_active: 'Active analytical project cannot be deleted. It is required to stop the project.',
  delete_projects_confirm: 'All analytical projects data, including reports, will be deleted.',
  preset_fill_error: 'The project cannot be started. The required project settings are missing',
};
