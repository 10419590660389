import React, { FC } from 'react';

import { IconType } from '../icon.type';

const MaxConflict: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 57 57"
      >
        <circle cx="28.5" cy="28.5" r="28.5" fill="#EEF9FD"></circle>
        <g clipPath="url(#clip0_2319_16832)">
          <path
            fill="#2987CC"
            d="M44.578 38.21l-12-24a4 4 0 00-7.155 0l-12 24A3.999 3.999 0 0017 44h24a4.001 4.001 0 003.578-5.79zm-13.58.79a1 1 0 01-1 1h-2a.998.998 0 01-1-1v-2a1 1 0 011-1h2a1 1 0 011 1v2zm0-8a.999.999 0 01-1 1h-2a1 1 0 01-1-1v-9.999a1 1 0 011-1.002h2a1 1 0 011 1v10.002z"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default MaxConflict;
