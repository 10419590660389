import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  AsrModerType,
  ExtAsrAndUsrModerType,
  ExtAsrModerType,
} from '@app/interfaces/asr-moder.type';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { baseQuery } from '../baseQuery';
export const asrModelApi = createApi({
  reducerPath: 'asrModelApi',
  tagTypes: ['asrModel'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getAsrModelsList: build.query<AsrModerType[], void>({
      query: () => ({
        url: serverRoutes.asr_models,
      }),
    }),
    getExtAsrModelsList: build.query<ExtAsrModerType[], void>({
      query: () => ({
        url: serverRoutes.asr_ext_models,
      }),
    }),
    getExtAndUsrModels: build.query<ExtAsrAndUsrModerType[], void>({
      async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
        const data: ExtAsrAndUsrModerType[] = [];
        const getExtAsrModels = async () =>
          (await fetchWithBQ({
            url: serverRoutes.asr_ext_models,
          })) as unknown as { data: ExtAsrModerType[] };
        const getAsrModels = async () =>
          (await fetchWithBQ({
            url: serverRoutes.asr_models,
          })) as unknown as { data: AsrModerType[] };
        await getExtAsrModels().then((models) => {
          const newModals: ExtAsrAndUsrModerType[] = models.data.map((item) => ({
            model_id: item.asr_model_id,
            name: item.name,
            description: item.description,
            default: false,
          }));
          for (const modal of newModals) {
            data.push({ ...modal });
          }
        });
        await getAsrModels().then((models) => {
          const newModals: ExtAsrAndUsrModerType[] = models.data.map((item) => ({
            model_id: item.model_id,
            name: item.name,
            description: item.description,
            default: item.default,
          }));
          for (const modal of newModals) {
            data.push({ ...modal });
          }
        });
        return data.length
          ? { data }
          : { error: { error: 'error', data: [] } as FetchBaseQueryError };
      },
    }),
  }),
});

export const { useGetAsrModelsListQuery, useGetExtAndUsrModelsQuery } = asrModelApi;
