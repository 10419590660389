module.exports = {
  variables: 'Variables',
  CANNOT_DELETE_VARIABLE_PROCEDURE: "It is not possible to delete the variable '%1' because it is part of the function definition '%2'",
  add_comment: 'Add comment',
  expressions: 'Expressions',
  formula_expression: 'Logical functions',
  add: 'Add operation',
  delete: 'Delete operation',
  add_val: 'Add value',
  delete_val: 'Delete value',
  if: 'If',
  then: 'Then',
  otherwise: 'Otherwise',
  not: 'not',
  metrics: 'Metrics',
  metric: 'Metric',
  and: 'and',
  or: 'or',
  none_metric: 'No metrics',
  not_help: 'Changes the result of a logical expression from True to False and from False to True.',
  logic_operators_help: 'Performs the logical AND or OR operation between given expressions.',
  rate_func: 'Evaluates the given logical expression and returns with a second parameter if true, or a third if false.',
  formula_relop: 'Performs the selected relational operation on the given expressions.',
  formula_math_help: 'Performs the selected mathematical operation with the given expressions.',
  logic_func: 'Any expression (string, number, logical)/n. This block can replace other expression blocks.',
  formula_start: 'This is the beginning of the formula. Anything you place outside of this block will not be used.',
  success_create_metric: 'Metric was adeed',
  composite_formula_name: 'formula',
  composite_name: 'Metric name',
  composite_success_edit_metric: 'Metric edited',
  composite_fail_edit_metric: 'Failed to edit metric. The formula may be incorrect and cannot be saved, please refer to the instruction manual',
  composite_fail_create_metric: 'Failed to create a metric. The formula may be incorrect and cannot be saved, please refer to the instruction manual.',
  composite_fail_metric_formula: 'Incorrect formula',
  error_metric_build: 'Error loading metrics, try again',
  logic_expressions: 'Logical',
  math_expressions: 'Mathematical',
  metric_expressions: 'Metrics',
  select: {
    composite_visualization: {
      label: 'Display format',
      options: {
        composite_visualization_native: 'Standard',
        composite_visualization_score: 'Bar from 1 to 5',
        composite_visualization_score100: 'Bar from 0 to 100',
      },
    },
    composite_metric_type: {
      labelDisplayType: 'Select display type',
      label: 'Select result type',
      options: {
        str: 'String',
        num: 'Number',
      },
    },
  },
};
