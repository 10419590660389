module.exports = {
  pageTitle: 'Users',
  add_user_button: 'Add User',
  table: {
    userName: 'User name',
    email: 'Login',
    role: 'User Role',
    status: 'Status',
    actions: 'Actions',
    delete: 'Delete user',
    edit: 'Edit user'
  },
  modal: {
    title_create: 'Add User',
    username: 'User name',
    login:'Login/Email',
    role:'User Role',
    password:'User Password',
    save_button: 'Сохранить',
    name: 'Your name',
    select_role: 'Select role',
    select_enable: 'User status',
    enabled: 'Active',
    disabled: 'Not active',
    title_edit: 'Change user'
  },
  errors: {
    required: 'This field is mandatory',
    max_length: 'Max. length is 64 characters',
    invalid_characters: 'Invalid characters',
    password_too_short: 'Password is less than 12 characters',
    no_uppercase: 'No uppercase letters',
    no_lowercase: 'No lowercase letters',
    no_number: 'No numbers in the password',
    no_special_char: 'No special characters',
    invalid_email: 'Invalid email'
  },
  warning: 'Warning',
  delete_user_text: 'Are you sure you want to delete the user?'
}
