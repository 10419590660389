module.exports = {
  project_records: 'Project records',
  records: 'Records',
  recordsId: 'Audio record',
  analytic_project: 'Project',
  analytics: 'Analytical projects',
  template: 'Templates',
  reports: 'Reports',
  report: 'Report',
  dictionaries: 'Dictionaries',
  dictionariesCreate: 'Adding a dictionary',
  report_settings: 'Report settings',
  report_list: 'Report records',
  settings: 'Settings',
  preset_templates: 'Preset templates',
  preset_template: 'Preset',
  metric_templates: 'Metrics templates',
  wordsCloud: 'Words cloud',
  wordsNGram: 'Phrase cloud',
  metrics: "Metrics",
  triggers: "Triggers",
  ap_settings: 'Main settings',
  all_chats: "All chats",
  chat_analytic: "Chat analytics",
  chat_metric_templates: "Chat metrics templates",
};
