import React, { FC } from 'react';

import { IconType } from '../icon.type';

const HandRaisedCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_429)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM10.5459 15.2012C10.5459 17.3809 11.9873 18.7168 14.0088 18.7168C15.4678 18.7168 16.4785 18.1455 17.1729 16.8271C17.6123 16.0713 17.9375 15.0781 18.2188 14.1465C18.3594 13.6895 18.5527 13.1357 18.5703 12.8896C18.5791 12.5908 18.377 12.3799 18.0781 12.3711C17.7529 12.3535 17.5332 12.5469 17.3223 12.9951L16.6367 14.4629C16.5752 14.6123 16.5049 14.665 16.4346 14.665C16.3467 14.665 16.2939 14.5947 16.2939 14.4541V9.27734C16.2939 8.96973 16.0303 8.71484 15.7227 8.71484C15.4062 8.71484 15.1602 8.96973 15.1602 9.27734V13.0039C15.0283 12.9688 14.9053 12.9248 14.7559 12.8984V8.57422C14.7559 8.2666 14.5098 8.01172 14.1934 8.01172C13.877 8.01172 13.6309 8.2666 13.6309 8.57422V12.8369C13.4902 12.8633 13.3496 12.8721 13.2178 12.8984V8.99609C13.2178 8.67969 12.9629 8.4248 12.6465 8.4248C12.3389 8.4248 12.0752 8.67969 12.0752 8.99609V13.2412C11.9258 13.3027 11.8115 13.3818 11.6885 13.4521V10.5342C11.6885 10.209 11.4336 9.9541 11.1172 9.9541C10.8008 9.9541 10.5459 10.209 10.5459 10.5342V15.2012Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_429">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default HandRaisedCircleIcon;
