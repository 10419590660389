import React, { FC } from 'react';

import { IconType } from '../icon.type';

const MinusCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_614_3033)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM10.6602 14.4893H17.3311C17.8584 14.4893 18.2275 14.1816 18.2275 13.6719C18.2275 13.1621 17.876 12.8457 17.3311 12.8457H10.6602C10.1152 12.8457 9.75488 13.1621 9.75488 13.6719C9.75488 14.1816 10.1328 14.4893 10.6602 14.4893Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_614_3033">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default MinusCircleIcon;
