import { FetchBaseQueryError, createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  ReportTaskFormType,
  ReportTaskListType,
  ReportTaskListTypeRequest,
  ReportTaskObjectType,
} from '@app/interfaces/report-tasks';

import {
  MetricTriggerRecipientsListType,
  MixedTriggerRecipientsType,
} from '@app/interfaces/metric-trigger.type';

import { baseQuery } from '../baseQuery';

import { tenantRecipientsApi } from './tenant-recipients.api';

export const reportRecipientsApi = createApi({
  reducerPath: 'reportRecipientsApi',
  tagTypes: ['reportRecipients'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getReportTasks: build.query<ReportTaskListType, ReportTaskListTypeRequest>({
      query: (params) => ({
        url: serverRoutes.reportTasksBulk,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.live_report_tasks.map(({ report_task_id }) => ({
                type: 'reportRecipients' as const,
                report_task_id,
              })),
              'reportRecipients',
            ]
          : ['reportRecipients'],
    }),
    viewReportTasks: build.query<ReportTaskObjectType, { report_task_id: string }>({
      query: (params) => ({
        url: serverRoutes.reportTasks,
        params,
      }),
    }),
    createReportTasks: build.mutation<
      ReportTaskObjectType,
      { params: { live_report_id: string }; body: ReportTaskFormType }
    >({
      query: ({ params, body }) => ({
        method: 'POST',
        url: serverRoutes.reportTasks,

        body: { ...body, settings: { type: body.settings }, ...params },
      }),
      invalidatesTags: ['reportRecipients'],
    }),
    editReportTasks: build.mutation<
      ReportTaskObjectType,
      { params: { report_task_id: string }; body: ReportTaskFormType }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.reportTasks,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['reportRecipients'],
    }),
    deleteReportTasks: build.mutation({
      query: (params: { report_task_id: string }) => ({
        url: serverRoutes.reportTasks,
        params,
        method: 'DELETE',
      }),
      invalidatesTags: ['reportRecipients'],
    }),
    // report recipients
    getReportRecipientsBulk: build.query<MixedTriggerRecipientsType, { report_task_id: string }>({
      async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
        const telegramMessages = (await fetchWithBQ({
          url: serverRoutes.reportTasksTgRecipientsBulk,
          params: arg,
        })) as unknown as { data: { telegram_recipients: MetricTriggerRecipientsListType[] } };

        const emailMessages = (await fetchWithBQ({
          url: serverRoutes.reportTasksEmailRecipientsBulk,
          params: arg,
        })) as unknown as { data: { email_recipients: MetricTriggerRecipientsListType[] } };

        const result: MixedTriggerRecipientsType = {
          telegram: telegramMessages.data.telegram_recipients.map((recipient) => ({
            recipient_id: recipient.recipient_id,
            trigger_recipient_id: recipient.id,
          })),
          email: emailMessages.data.email_recipients.map((recipient) => ({
            recipient_id: recipient.recipient_id,
            trigger_recipient_id: recipient.id,
          })),
        };
        return result ? { data: result } : { error: { error: '123' } as FetchBaseQueryError };
      },
    }),
    // create recipients
    createNewEmailRecipientReport: build.mutation({
      query: (body: { report_task_id: string; email: string; description: string }) => ({
        url: serverRoutes.reportEmailRecipientNew,
        method: 'POST',
        body,
      }),
      onQueryStarted(_, { dispatch }) {
        dispatch(tenantRecipientsApi.util.invalidateTags(['tenantRecipients']));
      },
    }),
    createNewTelegramRecipientReport: build.mutation<
      MetricTriggerRecipientsListType & { hash: string },
      { report_task_id: string; description: string }
    >({
      query: (body) => ({
        url: serverRoutes.reportTelegramRecipientNew,
        method: 'POST',
        body,
      }),
      onQueryStarted(_, { dispatch }) {
        dispatch(tenantRecipientsApi.util.invalidateTags(['tenantRecipients']));
      },
    }),
    // report email recipients mutations
    addRecipientToEmailReport: build.mutation({
      query: (body: { report_task_id: string; recipient_id: string }) => ({
        url: serverRoutes.reportEmailRecipient,
        method: 'POST',
        body,
      }),
      // invalidatesTags: ['onCreateRecipient'],
    }),
    deleteRecipientFromEmailReport: build.mutation({
      query: (params: { report_task_id: string; report_task_email_recipient_id: string }) => ({
        url: serverRoutes.reportEmailRecipient,
        method: 'DELETE',
        params,
      }),
    }),
    // trigger TG recipients mutations
    addRecipientToTgReport: build.mutation({
      query: (body: { report_task_id: string; recipient_id: string }) => ({
        url: serverRoutes.reportTelegramRecipient,
        method: 'POST',
        body,
      }),
    }),
    deleteRecipientFromTgReport: build.mutation({
      query: (params: { report_task_id: string; report_task_telegram_recipient_id: string }) => ({
        url: serverRoutes.reportTelegramRecipient,
        method: 'DELETE',
        params,
      }),
    }),
  }),
});

export const {
  useGetReportTasksQuery,
  useDeleteReportTasksMutation,
  useCreateReportTasksMutation,
  useEditReportTasksMutation,
  useLazyViewReportTasksQuery,
  useLazyGetReportRecipientsBulkQuery,
  useCreateNewEmailRecipientReportMutation,
  useCreateNewTelegramRecipientReportMutation,
  useAddRecipientToEmailReportMutation,
  useDeleteRecipientFromEmailReportMutation,
  useAddRecipientToTgReportMutation,
  useDeleteRecipientFromTgReportMutation,
} = reportRecipientsApi;
