import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';
import { MetricSettings } from '@app/pages/analytics/metric/analytics-metric-tab/analytics-metric-tab';

const MetricPage = () => {
  const { project } = useOutletContext<AnalyticPageOutlet>();

  return (
    <div>
      <MetricSettings project={project} />
    </div>
  );
};

export default MetricPage;
