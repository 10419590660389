import React from 'react';
import { Skeleton, SkeletonPageLoading } from '@ui';

export const ProjectMetricsSkeleton = () => {
  const skeletonTitleCellClassName = 'w-full px-[20px] py-[12px]';
  return (
    <div>
      <SkeletonPageLoading />
      <div>
        <div className="flex w-full items-start sticky z-50 top-0 min-w-[80px] bg-basic_app_bg  ">
          <div className={skeletonTitleCellClassName}>
            <Skeleton width={84} height={14} />
          </div>
          <div className={skeletonTitleCellClassName}>
            <Skeleton width={84} height={14} />
          </div>
          <div className={skeletonTitleCellClassName}>
            <Skeleton width={84} height={14} />
          </div>
          <div className={skeletonTitleCellClassName}>
            <Skeleton width={84} height={14} />
          </div>
          <div className={`${skeletonTitleCellClassName} align-end`}>
            <Skeleton width={84} height={14} className="ml-[45px] text-right" />
          </div>
        </div>
        <div className="w-full">
          <Skeleton height={400} />
        </div>
      </div>
    </div>
  );
};
