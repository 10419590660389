import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useOutletContext, useParams } from 'react-router';
import { v4 } from 'uuid';

import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

import { Button, DropMenu, Empty } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { DropMenuItem } from '@app/components/ui/drop-menu/drop-menu.type';
import { ReportChart, ReportListChart, ReportTimeRange } from '@app/interfaces/report.type';
import useChartDateIntervals from '@app/hooks/use-chart-date-intervals';
import { SelectUniversalFilter } from '@app/components/select-universal-filter';
import { GroupSharedStatus } from '@app/interfaces/analytics';
import { FilterItem } from '@app/interfaces/filter';

import {
  useLazyGetChatGraphDataQuery,
  useLazyGetGraphDataQuery,
} from '@app/store/api/graph-data.api';

import { GridLayoutChartsContainer } from '@app/components/grid-layout-charts-container';

import { useGetLiveReportsGraphListQuery } from '@app/store/api/live-reports-charts.api';

import { ChartsLayoutType } from '@app/interfaces/dashboards.type';

import { useAppDispatch, useAppSelector } from '@app/store/store';

import { toast } from 'react-toastify';

import ReportChartsSkeleton from '@app/pages/reports/blocks/report-charts-skeleton';

import {
  changeAllReportCharts,
  changeChartLoading,
  changeReportChartsInitialLayout,
  changeReportChartsLoading,
} from '@app/store/reducers/report-charts.slice';

import { usePermissionCheck } from '@app/hooks/use-permission';

import { userPermissions } from '@app/utils/user-permissions';

import { useGetChatLiveReportsGraphListQuery } from '@app/store/api/chat-api/chat-live-reports-charts.api';

import { ReportOutletContext } from '../report.outlet';

import ChartDatePickerModalBlock from './chart-date-picker-modal.block';
import ModalSettingsChartBlock from './chart-modal/modal-settings-chart.block';

const ChartListBlock: FC<{ live_report_id: string }> = () => {
  const { id } = useParams();
  const customDateTrigger = 'customDate';
  const { t } = useTranslation('pages.chartPage');
  const dispatch = useAppDispatch();
  const {
    charts: voiceCharts,
    isLoading: voiceLoading,
    reportLayout: voiceReportLayout,
  } = useAppSelector((state) => state.reportCharts);
  const {
    charts: chatCharts,
    isLoading: chatsLoading,
    reportLayout: chatReportLayout,
  } = useAppSelector((state) => state.chatReportCharts);

  const { metricList, liveReport, currentTemplate } = useOutletContext<ReportOutletContext>();
  const charts = useMemo(
    () => ({ chat: chatCharts, voice: voiceCharts }),
    [chatCharts, voiceCharts],
  );

  const reportLayout = useMemo(
    () => ({ chat: chatReportLayout, voice: voiceReportLayout }),
    [chatReportLayout, voiceReportLayout],
  );

  const isLoading = chatsLoading || voiceLoading;

  const chartCanBeEdited = usePermissionCheck({
    tag:
      currentTemplate === 'chat'
        ? userPermissions.action.actionEditChatLiveReportGraph
        : userPermissions.action.actionEditLiveReportGraph,
    permissions: 'action',
  });
  const chartCanBeResized = usePermissionCheck({
    tag:
      currentTemplate === 'chat'
        ? userPermissions.action.actionPutChatLiveReportGraphsLayout
        : userPermissions.action.actionPutLiveReportGraphsLayout,
    permissions: 'action',
  });

  // const reportLayout useMemo=() =>(( { chat: chatReportLayout, voice: voiceReportLayout }));
  // api
  useGetLiveReportsGraphListQuery(id || '', {
    skip: currentTemplate === 'chat' || !id,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  useGetChatLiveReportsGraphListQuery(id || '', {
    skip: currentTemplate === 'voice' || !id,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const [getVoiceGraphData] = useLazyGetGraphDataQuery();
  const [getChatGraphData] = useLazyGetChatGraphDataQuery();

  const getGraphData = { chat: getChatGraphData, voice: getVoiceGraphData };
  // useState
  const [chartsLoading, changeChartsLoading] = useState(true);
  const [flagForSendChartLayoutRequestChange, changeFlagForSendChartLayoutRequestChange] =
    useState<boolean>(false);
  const [editReportChartsState, changeEditReportChartsState] = useState<boolean>(false);
  const [isShowModelDatePicker, changeShowingModelDatePicker] = useState<boolean>(false);
  const [showEditChartModal, changeShowOpenChartModel] = useState<boolean>(false);
  const [commonRange, setCommonRange] = useState<ReportTimeRange | null>(null);
  // const [charts, changeChartList] = useState<(ReportListChart & ReportChart)[]>();
  const [filterValue, changeFilterValue] = useState<FilterItem[]>();
  const { daysSelectOptions, dateIntervals, intervalTimeBuilder } = useChartDateIntervals();
  useEffect(() => {
    return () => {
      dispatch(changeAllReportCharts(null));
      dispatch(changeChartLoading(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) setTimeout(() => changeChartsLoading(false), 350);
    isLoading && changeChartsLoading(true);
  }, [charts, isLoading]);

  const changeLayoutSettings = useCallback(
    (layout: ChartsLayoutType[]) => dispatch(changeReportChartsInitialLayout(layout)),
    [dispatch],
  );

  async function handlerDateDropTrigger(key: string | number) {
    if (!charts) return;
    dispatch(changeReportChartsLoading(true));
    const changedCharts: (ReportListChart & ReportChart)[] = [];
    if (key === customDateTrigger) {
      changeShowingModelDatePicker(true);
      return;
    }
    for await (const chart of charts[currentTemplate || 'voice'] || []) {
      const layout = reportLayout[currentTemplate || 'voice']?.find(
        (layout) => layout.i === chart.graph_id,
      );

      const chartData = (await getGraphData[currentTemplate || 'voice']({
        id: chart.graph_id,
        settings: {
          range: dateIntervals[key],
          filter: filterValue,
        },
      })) as unknown as {
        data: {
          data: ReportChart;
        };
      };
      changedCharts.push({
        ...chart,
        categories: chartData.data.data.categories,
        series: chartData.data.data.series,
        system_date_diapason: chartData.data.data.system_date_diapason,
        range: chartData.data.data.range,
        layout: layout || chart.layout,
      });
    }
    setCommonRange(dateIntervals[key]);
    !!changedCharts.length && dispatch(changeAllReportCharts(changedCharts));
    setTimeout(() => dispatch(changeReportChartsLoading(false)), 200);
  }

  async function handlerChangeCustomRangeInterval({
    start,
    end,
  }: {
    start: string | null | Date;
    end: string | null | Date;
  }) {
    if (!charts) return;
    dispatch(changeReportChartsLoading(true));
    const changedCharts: (ReportListChart & ReportChart)[] = [];
    const range = intervalTimeBuilder(start, end) as unknown as ReportTimeRange;
    for await (const chart of charts[currentTemplate || 'voice'] || []) {
      const layout = reportLayout[currentTemplate || 'voice']?.find(
        (layout) => layout.i === chart.graph_id,
      );
      const chartData = (await getGraphData[currentTemplate || 'voice']({
        id: chart.graph_id,
        settings: {
          // width: chart.build.width,
          range,
          filter: filterValue,
        },
      })) as unknown as {
        data: {
          data: ReportChart;
        };
      };
      changedCharts.push({
        ...chart,
        categories: chartData.data.data.categories,
        series: chartData.data.data.series,
        system_date_diapason: chartData.data.data.system_date_diapason,
        range: chartData.data.data.range,
        layout: layout || chart.layout,
      });
    }
    !!changedCharts.length && dispatch(changeAllReportCharts(changedCharts));
    setTimeout(() => dispatch(changeReportChartsLoading(false)), 200);
  }
  const renderCharts = useMemo(() => {
    return (
      <GridLayoutChartsContainer
        currentTemplate={currentTemplate}
        flagForSendChartLayoutRequestChange={flagForSendChartLayoutRequestChange}
        layoutSettings={reportLayout[currentTemplate || 'voice'] || []}
        changeLayoutSettings={changeLayoutSettings}
        viewOnly={
          !liveReport?.project?.owner && liveReport?.project?.shared === GroupSharedStatus.VIEW
        }
        emptyElement={<Empty title={t('empty')} />}
        commonRange={commonRange}
        charts={charts[currentTemplate || 'voice'] || []}
        isDraggable={editReportChartsState}
        filterValue={filterValue}
        currentDashboardOrReportId={liveReport?.live_report_id}
      />
    );
  }, [
    flagForSendChartLayoutRequestChange,
    reportLayout,
    currentTemplate,
    changeLayoutSettings,
    liveReport?.project?.owner,
    liveReport?.project?.shared,
    liveReport?.live_report_id,
    t,
    commonRange,
    charts,
    editReportChartsState,
    filterValue,
  ]);

  async function onChangeFilterHandler(filter: FilterItem[]) {
    if (!charts) return;
    dispatch(changeReportChartsLoading(true));
    const changedCharts: (ReportListChart & ReportChart)[] = [];
    changeFilterValue(filter);
    for await (const chart of charts[currentTemplate || 'voice'] || []) {
      const layout = reportLayout[currentTemplate || 'voice']?.find(
        (layout) => layout.i === chart.graph_id,
      );
      const chartData = (await getGraphData[currentTemplate || 'voice']({
        id: chart.graph_id,
        settings: {
          range: chart.range,
          filter: filter,
        },
      })) as unknown as {
        data: {
          data: ReportChart;
        };
      };
      changedCharts.push({
        ...chart,
        categories: chartData.data.data.categories,
        series: chartData.data.data.series,
        system_date_diapason: chartData.data.data.system_date_diapason,
        range: chartData.data.data.range,
        layout: layout || chart.layout,
      });
    }
    !!changedCharts.length && dispatch(changeAllReportCharts(changedCharts));
    setTimeout(() => dispatch(changeReportChartsLoading(false)), 200);
  }
  const METRIC_CONFIG = { fontSize: '15px', color: '#000', fontWeight: '400', lineHeight: '18px' };
  const resultItemsForSelectUniversalFilter =
    metricList?.map((item) => ({
      ...item,
      front_id: v4(),
      style: METRIC_CONFIG,
    })) || [];

  return (
    <div>
      <div>
        <div className="w-full inline-flex justify-between min-h items-center gap-[37px]">
          <SelectUniversalFilter
            onChangeReturnFilter={(filter) => onChangeFilterHandler(filter)}
            results={resultItemsForSelectUniversalFilter}
          />
          <div />
          <div className="flex  items-center relative z-10 my-[10px]">
            <Button
              icon="CirclePlusIcon"
              disabled={(() => {
                if (!chartCanBeEdited) {
                  return (
                    showEditChartModal ||
                    (!liveReport?.project?.owner &&
                      liveReport?.project?.shared === GroupSharedStatus.VIEW)
                  );
                } else return true;
              })()}
              label={t('popover.add_diagram')}
              onClick={() => {
                if (!metricList?.length) {
                  toast.info(t('system.none_projectMetric_info'));
                } else changeShowOpenChartModel(true);
              }}
            />
            <div className="ml-[13px]">
              <DropMenu
                dropSize={140}
                hintTitle={t('drop_menu.intervals')}
                onClick={(key) => handlerDateDropTrigger(key)}
                menu={daysSelectOptions as unknown as Array<DropMenuItem>}
              >
                <Button icon="CalendarEmptyIcon" />
              </DropMenu>
            </div>
            {(liveReport?.project?.owner ||
              liveReport?.project?.shared !== GroupSharedStatus.VIEW) && (
              <div className="ml-[13px]">
                <Button
                  disabled={!!chartCanBeResized}
                  onClick={() => {
                    editReportChartsState
                      ? changeFlagForSendChartLayoutRequestChange(true)
                      : changeFlagForSendChartLayoutRequestChange(false);
                    changeEditReportChartsState((prev) => !prev);
                  }}
                  hintTitle={t('popup_hints.grid_layout_settings')}
                  icon="SettingIcon"
                  fill={'outlined'}
                  variant={editReportChartsState ? 'danger' : 'primary'}
                />
              </div>
            )}
          </div>
        </div>
        {/* // )} */}
        <div className="w-full">
          {chartsLoading ? <ReportChartsSkeleton count={4} /> : renderCharts}
        </div>
      </div>
      <ModalSettingsChartBlock
        currentTemplate={currentTemplate || 'voice'}
        isOpen={showEditChartModal}
        onClose={changeShowOpenChartModel}
      />
      <ChartDatePickerModalBlock
        dataIdStartDate="reports-all-charts-interval-date-from"
        dataIdEndDate="reports-all-charts-interval-date-to"
        onSuccess={handlerChangeCustomRangeInterval}
        show={isShowModelDatePicker}
        onClose={changeShowingModelDatePicker.bind(null, false)}
      />
    </div>
  );
};

export default React.memo(ChartListBlock);
