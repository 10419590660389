import { v4 } from 'uuid';

import { conditionsStyles } from '../styles/styles';

export const conditions = [
  {
    value: '>',
    label: '>',
    values: ['num'],
    type_meta: ['record', 'base', 'system'],
    style: conditionsStyles,
    front_id: v4(),
  },
  {
    value: '<',
    label: '<',
    values: ['num'],
    type_meta: ['record', 'base', 'system'],
    front_id: v4(),
    style: conditionsStyles,
  },
  {
    value: '>=',
    label: '>=',
    values: ['num'],
    type_meta: ['record', 'base', 'system'],
    front_id: v4(),
    style: conditionsStyles,
  },
  {
    value: '<=',
    label: '<=',
    values: ['num'],
    type_meta: ['record', 'base', 'system'],
    front_id: v4(),
    style: conditionsStyles,
  },
  {
    value: '=',
    label: '=',
    values: ['num', 'str', 'datetime'],
    type_meta: ['channel', 'record', 'base', 'system'],
    front_id: v4(),
    style: conditionsStyles,
  },
  {
    value: '!=',
    label: '!=',
    values: ['num', 'str', 'datetime'],
    type_meta: ['channel', 'record', 'base', 'system'],
    front_id: v4(),
    style: conditionsStyles,
  },
  {
    value: 'exists',
    label: 'Существует',
    values: ['num', 'str'],
    type_meta: ['record', 'base'],
    front_id: v4(),
    style: conditionsStyles,
  },
];
