import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { BreadcrumbsItem } from '@app/layouts/layout';
import RequiredAuth from '@app/routes/required-auth.route';
import MetricTemplates from '@app/pages/metric-templates';

export const chatMetricTemplateRoute = (
  <Route
    path={navigationRoutes.chatMetricTemplates}
    handle={{
      crumb: () => (
        <BreadcrumbsItem path={navigationRoutes.records} crumb="chat_metric_templates" />
      ),
    }}
  >
    <Route
      index
      element={
        <RequiredAuth>
          <MetricTemplates currentTemplate="chat" />
        </RequiredAuth>
      }
    />
  </Route>
);
