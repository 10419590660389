import React, { FC } from 'react';

import { IconType } from '../icon.type';

const PawprintCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_300)" filter="url(#filter0_d_610_300)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM11.5127 10.4814C11.5127 11.3428 11.9873 12.0195 12.585 12.0195C13.1826 12.0195 13.6396 11.3428 13.6396 10.4814C13.6396 9.62891 13.1826 8.95215 12.585 8.95215C11.9873 8.95215 11.5127 9.62012 11.5127 10.4814ZM14.3428 10.4814C14.3428 11.3428 14.8086 12.0195 15.4062 12.0195C16.0039 12.0195 16.4785 11.3428 16.4785 10.4814C16.4785 9.62012 16.0039 8.95215 15.4062 8.95215C14.8086 8.95215 14.3428 9.62891 14.3428 10.4814ZM9.40332 12.9951C9.40332 13.8652 9.86914 14.5332 10.4668 14.5332C11.0645 14.5332 11.5391 13.8652 11.5391 12.9951C11.5391 12.1338 11.0645 11.457 10.4668 11.457C9.86914 11.457 9.40332 12.1338 9.40332 12.9951ZM16.4521 12.9951C16.4521 13.8652 16.9268 14.5332 17.5244 14.5332C18.1221 14.5332 18.5879 13.8652 18.5879 12.9951C18.5879 12.1338 18.1221 11.457 17.5244 11.457C16.9268 11.457 16.4521 12.1338 16.4521 12.9951ZM10.7305 16.335C10.7305 17.2314 11.3281 17.8643 12.207 17.8643C12.6377 17.8643 12.9453 17.75 13.2441 17.6357C13.4902 17.5566 13.7188 17.4863 13.9912 17.4863C14.2725 17.4863 14.501 17.5566 14.7471 17.6357C15.0371 17.75 15.3535 17.8643 15.7842 17.8643C16.6631 17.8643 17.2607 17.2314 17.2607 16.335C17.2607 15.5967 16.8125 15.21 16.3818 14.8584C16.1533 14.6738 15.9248 14.4893 15.7666 14.252C15.6348 14.0674 15.5381 13.8564 15.4414 13.6455C15.1338 12.9863 14.8877 12.3623 13.9912 12.3623C13.1035 12.3623 12.8574 12.9863 12.5498 13.6455C12.4531 13.8477 12.3564 14.0674 12.2334 14.2432C12.0664 14.4893 11.8379 14.6738 11.6094 14.8584C11.1787 15.21 10.7305 15.5967 10.7305 16.335Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_610_300"
            x="-4"
            y="0"
            width="36"
            height="36"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_610_300" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_610_300"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_610_300">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default PawprintCircleIcon;
