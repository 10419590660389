module.exports = {
  recipients: 'Получатели сообщений',
  true: 'Подтвержден',
  false: 'Не подтвержден',
  email_create_description: 'Новому получателю на указанный адрес электронной почты будет отправлено письмо с просьбой подтвердить получение сообщений. Отправка сообщений начнется после подтверждения действия.““Новому получателю на указанный адрес электронной почты будет отправлено письмо с просьбой подтвердить получение сообщений. Отправка сообщений начнется после подтверждения действия.',
  telegram_create_description: 'Для подтверждения пользователя Телеграмм, после добавления в список получателей необходимо зайти на страницу бота по ссылке <botLink>%{tgLink}</botLink> и ввести ключ авторизации, который вы увидите после добавления получателя. Более подробно про работу с ботом вы можете прочитать в разделе <manualLink>Руководство пользователя</manualLink>',
  hash_modal_label: 'Ключ авторизации бота Telegram',
  hash_modal_description: 'Для добавления пользователя Телеграмм в список получателей необходимо зайти на страницу бота по ссылке <botLink>%{tgLink}</botLink> и ввести ключ авторизации, указанный ниже. Более подробно про работу с ботом вы можете прочитать в разделе <manualLink>Руководство пользователя</manualLink>',
  hash_modal_key_description: 'Скопируйте ключ. После закрытия окна восстановить ключ будет невозможно',
  copy_success: 'Ключ скопирован',
  copy_error: 'Ключ не удалось скопировать',
  tg_bot_link: 'https://t.me/asdf6463ggbot',
  addRecipientNavigationHelper:"Новые получатели сообщений добавляются в разделе <manualLink>Получатели рассылок</manualLink>"
}
