import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ArrowBackwardSquare: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_614_2309)">
          <path
            d="M8.67383 21.8721H19.3174C21.2158 21.8721 22.2002 20.8877 22.2002 19.0156V8.31934C22.2002 6.43848 21.2158 5.46289 19.3174 5.46289H8.67383C6.77539 5.46289 5.79102 6.43848 5.79102 8.31934V19.0156C5.79102 20.8965 6.77539 21.8721 8.67383 21.8721ZM8.7793 20.1494C7.96191 20.1494 7.51367 19.7188 7.51367 18.8662V8.45996C7.51367 7.60742 7.96191 7.18555 8.7793 7.18555H19.2119C20.0205 7.18555 20.4775 7.60742 20.4775 8.45996V18.8662C20.4775 19.7188 20.0205 20.1494 19.2119 20.1494H8.7793ZM9.46484 13.6455C9.46484 13.8916 9.57031 14.085 9.75488 14.2695L12.7344 17.2051C12.8838 17.3457 13.0508 17.4248 13.2617 17.4248C13.666 17.4248 13.9648 17.1084 13.9648 16.7041C13.9648 16.4844 13.8857 16.2998 13.7451 16.168L12.6729 15.1484L11.6797 14.3486L13.4902 14.4277H17.8057C18.2451 14.4277 18.5615 14.0938 18.5615 13.6455C18.5615 13.1973 18.2451 12.8721 17.8057 12.8721H13.4902L11.6885 12.9512L12.6816 12.1514L13.7451 11.123C13.877 10.9912 13.9648 10.8066 13.9648 10.5957C13.9648 10.1826 13.666 9.88379 13.2617 9.88379C13.0508 9.88379 12.8838 9.94531 12.7344 10.0859L9.75488 13.0303C9.56152 13.2324 9.46484 13.417 9.46484 13.6455Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_614_2309">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ArrowBackwardSquare;
