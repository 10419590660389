import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const TextIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 18 19"
      >
        <path
          fill="currentColor"
          d="M11.935 18.997h5.09c.492 0 .747-.228.747-.624 0-.404-.255-.624-.748-.624h-1.819V1.507h1.82c.492 0 .747-.22.747-.633 0-.395-.255-.606-.748-.606h-5.089c-.492 0-.747.21-.747.606 0 .413.255.633.747.633h1.838v16.242h-1.838c-.492 0-.747.22-.747.624 0 .396.255.624.747.624zm-6.433-2.76c.633 0 1.046-.395 1.046-1.028V5.075H9.87c.536 0 .923-.351.923-.896 0-.536-.387-.888-.923-.888H1.143c-.537 0-.923.352-.923.888 0 .545.386.896.923.896h3.322V15.21c0 .633.404 1.028 1.037 1.028z"
        ></path>
      </svg>
    </div>
  );
};

export default TextIcon;
