import * as Blockly from 'blockly';

Blockly.Msg['ADD_COMMENT'] = 'Добавить комментарий';
Blockly.Msg['CANNOT_DELETE_VARIABLE_PROCEDURE'] =
  "Невозможно удалить переменную '%1', поскольку она является частью определения функции '%2'";
Blockly.Msg['CHANGE_VALUE_TITLE'] = 'Измените значение:';
Blockly.Msg['CLEAN_UP'] = 'Убрать блоки';
Blockly.Msg['COLLAPSED_WARNINGS_WARNING'] = 'Свёрнутые блоки содержат предупреждения.';
Blockly.Msg['COLLAPSE_ALL'] = 'Свернуть блоки';
Blockly.Msg['COLLAPSE_BLOCK'] = 'Свернуть блок';
Blockly.Msg['COLOUR_BLEND_COLOUR1'] = 'цвет 1';
Blockly.Msg['COLOUR_BLEND_COLOUR2'] = 'цвет 2';
Blockly.Msg['COLOUR_BLEND_HELPURL'] = 'https://meyerweb.com/eric/tools/color-blend/#:::rgbp'; // untranslated
Blockly.Msg['COLOUR_BLEND_RATIO'] = 'доля цвета 1';
Blockly.Msg['COLOUR_BLEND_TITLE'] = 'смешать';
Blockly.Msg['COLOUR_BLEND_TOOLTIP'] = 'Смешивает два цвета в заданном соотношении (0.0 - 1.0).';
Blockly.Msg['COLOUR_PICKER_HELPURL'] = 'https://ru.wikipedia.org/wiki/Цвет';
Blockly.Msg['COLOUR_PICKER_TOOLTIP'] = 'Выберите цвет из палитры.';
Blockly.Msg['COLOUR_RANDOM_HELPURL'] = 'http://randomcolour.com'; // untranslated
Blockly.Msg['COLOUR_RANDOM_TITLE'] = 'случайный цвет';
Blockly.Msg['COLOUR_RANDOM_TOOLTIP'] = 'Выбирает цвет случайным образом.';
Blockly.Msg['COLOUR_RGB_BLUE'] = 'синего';
Blockly.Msg['COLOUR_RGB_GREEN'] = 'зелёного';
Blockly.Msg['COLOUR_RGB_HELPURL'] = 'https://www.december.com/html/spec/colorpercompact.html'; // untranslated
Blockly.Msg['COLOUR_RGB_RED'] = 'красного';
Blockly.Msg['COLOUR_RGB_TITLE'] = 'цвет из';
Blockly.Msg['COLOUR_RGB_TOOLTIP'] =
  'Создаёт цвет с указанной пропорцией  красного, зеленого и синего.  Все значения должны быть между 0 и 100.';
Blockly.Msg['CONTROLS_FLOW_STATEMENTS_HELPURL'] =
  'https://github.com/google/blockly/wiki/Loops#loop-termination-blocks'; // untranslated
Blockly.Msg['CONTROLS_FLOW_STATEMENTS_OPERATOR_BREAK'] = 'выйти из цикла';
Blockly.Msg['CONTROLS_FLOW_STATEMENTS_OPERATOR_CONTINUE'] = 'перейти к следующему шагу цикла';
Blockly.Msg['CONTROLS_FLOW_STATEMENTS_TOOLTIP_BREAK'] = 'Прерывает этот цикл.';
Blockly.Msg['CONTROLS_FLOW_STATEMENTS_TOOLTIP_CONTINUE'] =
  'Пропускает остаток цикла и переходит к следующему шагу.';
Blockly.Msg['CONTROLS_FLOW_STATEMENTS_WARNING'] =
  'Предупреждение: этот блок может использоваться только внутри цикла.';
Blockly.Msg['CONTROLS_FOREACH_HELPURL'] = 'https://github.com/google/blockly/wiki/Loops#for-each'; // untranslated
Blockly.Msg['CONTROLS_FOREACH_TITLE'] = 'для каждого элемента %1 в списке %2';
Blockly.Msg['CONTROLS_FOREACH_TOOLTIP'] =
  "Для каждого элемента в списке, присваивает переменной '%1' значение элемента  и выполняет указанные  команды.";
Blockly.Msg['CONTROLS_FOR_HELPURL'] = 'https://github.com/google/blockly/wiki/Loops#count-with'; // untranslated
Blockly.Msg['CONTROLS_FOR_TITLE'] = 'цикл по %1 от %2 до %3 с шагом %4';
Blockly.Msg['CONTROLS_FOR_TOOLTIP'] =
  "Присваивает переменной '%1' значения от начального до конечного с заданным шагом и выполняет указанные команды.";
Blockly.Msg['CONTROLS_IF_ELSEIF_TOOLTIP'] = 'Добавляет условие к блоку "если"';
Blockly.Msg['CONTROLS_IF_ELSE_TOOLTIP'] =
  'Добавить заключительный подблок для случая, когда все условия ложны.';
Blockly.Msg['CONTROLS_IF_HELPURL'] = 'https://github.com/google/blockly/wiki/IfElse'; // untranslated
Blockly.Msg['CONTROLS_IF_IF_TOOLTIP'] =
  'Добавьте, удалите, переставьте фрагменты для переделки блока "если".';
Blockly.Msg['CONTROLS_IF_MSG_ELSE'] = 'иначе';
Blockly.Msg['CONTROLS_IF_MSG_ELSEIF'] = 'иначе если';
Blockly.Msg['CONTROLS_IF_MSG_IF'] = 'если';
Blockly.Msg['CONTROLS_IF_TOOLTIP_1'] = 'Если условие истинно, выполняет команды.';
Blockly.Msg['CONTROLS_IF_TOOLTIP_2'] =
  'Если условие истинно, выполняет первый блок команд. Иначе выполняется второй блок команд.';
Blockly.Msg['CONTROLS_IF_TOOLTIP_3'] =
  'Если первое условие истинно, то выполняет первый блок команд. Иначе, если второе условие истинно, выполняет второй блок команд.';
Blockly.Msg['CONTROLS_IF_TOOLTIP_4'] =
  'Если первое условие истинно, то выполняет первый блок команд.  В противном случае, если второе условие истинно, выполняет второй блок команд.  Если ни одно из условий не истинно, выполняет последний блок команд.';
Blockly.Msg['CONTROLS_REPEAT_HELPURL'] = 'https://ru.wikipedia.org/wiki/Цикл_(программирование)';
Blockly.Msg['CONTROLS_REPEAT_INPUT_DO'] = 'выполнить';
Blockly.Msg['CONTROLS_REPEAT_TITLE'] = 'повторить %1 раз';
Blockly.Msg['CONTROLS_REPEAT_TOOLTIP'] = 'Выполняет некоторые команды несколько раз.';
Blockly.Msg['CONTROLS_WHILEUNTIL_HELPURL'] = 'https://github.com/google/blockly/wiki/Loops#repeat'; // untranslated
Blockly.Msg['CONTROLS_WHILEUNTIL_OPERATOR_UNTIL'] = 'повторять, пока не';
Blockly.Msg['CONTROLS_WHILEUNTIL_OPERATOR_WHILE'] = 'повторять, пока';
Blockly.Msg['CONTROLS_WHILEUNTIL_TOOLTIP_UNTIL'] = 'Пока значение ложно, выполняет команды';
Blockly.Msg['CONTROLS_WHILEUNTIL_TOOLTIP_WHILE'] = 'Пока значение истинно, выполняет команды.';
Blockly.Msg['DELETE_ALL_BLOCKS'] = 'Удалить все блоки (%1)?';
Blockly.Msg['DELETE_BLOCK'] = 'Удалить блок';
Blockly.Msg['DELETE_VARIABLE'] = "Удалить переменную '%1'";
Blockly.Msg['DELETE_VARIABLE_CONFIRMATION'] = "Удалить %1 использований переменной '%2'?";
Blockly.Msg['DELETE_X_BLOCKS'] = 'Удалить %1 блоков';
Blockly.Msg['DISABLE_BLOCK'] = 'Отключить блок';
Blockly.Msg['DUPLICATE_BLOCK'] = 'Дублировать';
Blockly.Msg['DUPLICATE_COMMENT'] = 'Клонировать комментарий';
Blockly.Msg['ENABLE_BLOCK'] = 'Включить блок';
Blockly.Msg['EXPAND_ALL'] = 'Развернуть блоки';
Blockly.Msg['EXPAND_BLOCK'] = 'Развернуть блок';
Blockly.Msg['EXTERNAL_INPUTS'] = 'Вставки снаружи';
Blockly.Msg['HELP'] = 'Справка';
Blockly.Msg['INLINE_INPUTS'] = 'Вставки внутри';
Blockly.Msg['IOS_CANCEL'] = 'Отмена';
Blockly.Msg['IOS_ERROR'] = 'Ошибка';
Blockly.Msg['IOS_OK'] = 'ОК';
Blockly.Msg['IOS_PROCEDURES_ADD_INPUT'] = '+ Добавить входную переменную';
Blockly.Msg['IOS_PROCEDURES_ALLOW_STATEMENTS'] = 'Разрешить операторы';
Blockly.Msg['IOS_PROCEDURES_DUPLICATE_INPUTS_ERROR'] =
  'У этой функции есть дублирующиеся входные переменные.';
Blockly.Msg['IOS_PROCEDURES_INPUTS'] = 'ВХОД';
Blockly.Msg['IOS_VARIABLES_ADD_BUTTON'] = 'Добавить';
Blockly.Msg['IOS_VARIABLES_ADD_VARIABLE'] = '+ Добавить переменную';
Blockly.Msg['IOS_VARIABLES_DELETE_BUTTON'] = 'Удалить';
Blockly.Msg['IOS_VARIABLES_EMPTY_NAME_ERROR'] = 'Вы не можете использовать пустое имя переменной.';
Blockly.Msg['IOS_VARIABLES_RENAME_BUTTON'] = 'Переименовать';
Blockly.Msg['IOS_VARIABLES_VARIABLE_NAME'] = 'Имя переменной';
Blockly.Msg['LISTS_CREATE_EMPTY_HELPURL'] =
  'https://github.com/google/blockly/wiki/Lists#create-empty-list'; // untranslated
Blockly.Msg['LISTS_CREATE_EMPTY_TITLE'] = 'создать пустой список';
Blockly.Msg['LISTS_CREATE_EMPTY_TOOLTIP'] = 'Возвращает список длины 0, не содержащий данных';
Blockly.Msg['LISTS_CREATE_WITH_CONTAINER_TITLE_ADD'] = 'список';
Blockly.Msg['LISTS_CREATE_WITH_CONTAINER_TOOLTIP'] =
  'Добавьте, удалите, переставьте элементы для переделки блока списка.';
Blockly.Msg['LISTS_CREATE_WITH_HELPURL'] =
  'https://github.com/google/blockly/wiki/Lists#create-list-with';
Blockly.Msg['LISTS_CREATE_WITH_INPUT_WITH'] = 'создать список из';
Blockly.Msg['LISTS_CREATE_WITH_ITEM_TOOLTIP'] = 'Добавляет элемент к списку.';
Blockly.Msg['LISTS_CREATE_WITH_TOOLTIP'] = 'Создаёт список с любым числом элементов.';
Blockly.Msg['LISTS_GET_INDEX_FIRST'] = 'первый';
Blockly.Msg['LISTS_GET_INDEX_FROM_END'] = '№ с конца';
Blockly.Msg['LISTS_GET_INDEX_FROM_START'] = '#'; // untranslated
Blockly.Msg['LISTS_GET_INDEX_GET'] = 'взять';
Blockly.Msg['LISTS_GET_INDEX_GET_REMOVE'] = 'взять и удалить';
Blockly.Msg['LISTS_GET_INDEX_LAST'] = 'последний';
Blockly.Msg['LISTS_GET_INDEX_RANDOM'] = 'произвольный';
Blockly.Msg['LISTS_GET_INDEX_REMOVE'] = 'удалить';
Blockly.Msg['LISTS_GET_INDEX_TAIL'] = ''; // untranslated
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_FIRST'] = 'Возвращает первый элемент списка.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_FROM'] = 'Возвращает элемент в указанной позиции списка.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_LAST'] = 'Возвращает последний элемент списка.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_RANDOM'] = 'Возвращает случайный элемент списка.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_FIRST'] =
  'Удаляет и возвращает первый элемент списка.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_FROM'] =
  'Удаляет и возвращает элемент в указанной позиции списка.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_LAST'] =
  'Удаляет и возвращает последний элемент списка.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_RANDOM'] =
  'Удаляет и возвращает случайный элемент списка.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_REMOVE_FIRST'] = 'Удаляет первый элемент списка.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_REMOVE_FROM'] = 'Удаляет элемент в указанной позиции списка.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_REMOVE_LAST'] = 'Удаляет последний элемент списка.';
Blockly.Msg['LISTS_GET_INDEX_TOOLTIP_REMOVE_RANDOM'] = 'Удаляет случайный элемент списка.';
Blockly.Msg['LISTS_GET_SUBLIST_END_FROM_END'] = 'по № с конца';
Blockly.Msg['LISTS_GET_SUBLIST_END_FROM_START'] = 'по №';
Blockly.Msg['LISTS_GET_SUBLIST_END_LAST'] = 'по последний';
Blockly.Msg['LISTS_GET_SUBLIST_HELPURL'] =
  'https://github.com/google/blockly/wiki/Lists#getting-a-sublist'; // untranslated
Blockly.Msg['LISTS_GET_SUBLIST_START_FIRST'] = 'взять подсписок с первого';
Blockly.Msg['LISTS_GET_SUBLIST_START_FROM_END'] = 'взять подсписок с № с конца';
Blockly.Msg['LISTS_GET_SUBLIST_START_FROM_START'] = 'взять подсписок с №';
Blockly.Msg['LISTS_GET_SUBLIST_TAIL'] = ''; // untranslated
Blockly.Msg['LISTS_GET_SUBLIST_TOOLTIP'] = 'Создаёт копию указанной части списка.';
Blockly.Msg['LISTS_INDEX_FROM_END_TOOLTIP'] = '%1 - последний элемент.';
Blockly.Msg['LISTS_INDEX_FROM_START_TOOLTIP'] = '%1 - первый элемент.';
Blockly.Msg['LISTS_INDEX_OF_FIRST'] = 'найти первое вхождение элемента';
Blockly.Msg['LISTS_INDEX_OF_HELPURL'] =
  'https://github.com/google/blockly/wiki/Lists#getting-items-from-a-list'; // untranslated
Blockly.Msg['LISTS_INDEX_OF_LAST'] = 'найти последнее вхождение элемента';
Blockly.Msg['LISTS_INDEX_OF_TOOLTIP'] =
  'Возвращает номер позиции первого/последнего вхождения элемента в списке. Возвращает %1, если элемент не найден.';
Blockly.Msg['LISTS_INLIST'] = 'в списке';
Blockly.Msg['LISTS_ISEMPTY_HELPURL'] = 'https://github.com/google/blockly/wiki/Lists#is-empty'; // untranslated
Blockly.Msg['LISTS_ISEMPTY_TITLE'] = '%1 пуст';
Blockly.Msg['LISTS_ISEMPTY_TOOLTIP'] = 'Возвращает значение истина, если список пуст.';
Blockly.Msg['LISTS_LENGTH_HELPURL'] = 'https://github.com/google/blockly/wiki/Lists#length-of'; // untranslated
Blockly.Msg['LISTS_LENGTH_TITLE'] = 'длина %1';
Blockly.Msg['LISTS_LENGTH_TOOLTIP'] = 'Возвращает длину списка.';
Blockly.Msg['LISTS_REPEAT_HELPURL'] =
  'https://github.com/google/blockly/wiki/Lists#create-list-with'; // untranslated
Blockly.Msg['LISTS_REPEAT_TITLE'] = 'создать список из элемента %1, повторяющегося %2 раз';
Blockly.Msg['LISTS_REPEAT_TOOLTIP'] =
  'Создаёт список, состоящий из заданного числа копий элемента.';
Blockly.Msg['LISTS_REVERSE_HELPURL'] =
  'https://github.com/google/blockly/wiki/Lists#reversing-a-list';
Blockly.Msg['LISTS_REVERSE_MESSAGE0'] = 'изменить порядок на обратный %1';
Blockly.Msg['LISTS_REVERSE_TOOLTIP'] = 'Изменить порядок списка на обратный.';
Blockly.Msg['LISTS_SET_INDEX_HELPURL'] =
  'https://github.com/google/blockly/wiki/Lists#in-list--set'; // untranslated
Blockly.Msg['LISTS_SET_INDEX_INPUT_TO'] = '=';
Blockly.Msg['LISTS_SET_INDEX_INSERT'] = 'вставить в';
Blockly.Msg['LISTS_SET_INDEX_SET'] = 'присвоить';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_INSERT_FIRST'] = 'Вставляет элемент в начало списка.';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_INSERT_FROM'] =
  'Вставляет элемент в указанной позиции списка.';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_INSERT_LAST'] = 'Добавляет элемент в конец списка.';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_INSERT_RANDOM'] =
  'Вставляет элемент в случайное место в списке.';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_SET_FIRST'] = 'Присваивает значение первому элементу списка.';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_SET_FROM'] =
  'Присваивает значение элементу в указанной позиции списка.';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_SET_LAST'] =
  'Присваивает значение последнему элементу списка.';
Blockly.Msg['LISTS_SET_INDEX_TOOLTIP_SET_RANDOM'] =
  'Присваивает значение случайному элементу списка.';
Blockly.Msg['LISTS_SORT_HELPURL'] = 'https://github.com/google/blockly/wiki/Lists#sorting-a-list';
Blockly.Msg['LISTS_SORT_ORDER_ASCENDING'] = 'по возрастанию';
Blockly.Msg['LISTS_SORT_ORDER_DESCENDING'] = 'по убыванию';
Blockly.Msg['LISTS_SORT_TITLE'] = 'сортировать %1 %2 %3';
Blockly.Msg['LISTS_SORT_TOOLTIP'] = 'Сортировать копию списка.';
Blockly.Msg['LISTS_SORT_TYPE_IGNORECASE'] = 'по алфавиту, без учёта регистра';
Blockly.Msg['LISTS_SORT_TYPE_NUMERIC'] = 'числовая';
Blockly.Msg['LISTS_SORT_TYPE_TEXT'] = 'по алфавиту';
Blockly.Msg['LISTS_SPLIT_HELPURL'] =
  'https://github.com/google/blockly/wiki/Lists#splitting-strings-and-joining-lists';
Blockly.Msg['LISTS_SPLIT_LIST_FROM_TEXT'] = 'сделать список из текста';
Blockly.Msg['LISTS_SPLIT_TEXT_FROM_LIST'] = 'собрать текст из списка';
Blockly.Msg['LISTS_SPLIT_TOOLTIP_JOIN'] = 'Соединяет список текстов в один текст с разделителями.';
Blockly.Msg['LISTS_SPLIT_TOOLTIP_SPLIT'] = 'Разбивает текст в список текстов, по разделителям.';
Blockly.Msg['LISTS_SPLIT_WITH_DELIMITER'] = 'с разделителем';
Blockly.Msg['LOGIC_BOOLEAN_FALSE'] = 'ложь';
Blockly.Msg['LOGIC_BOOLEAN_HELPURL'] = 'https://github.com/google/blockly/wiki/Logic#values'; // untranslated
Blockly.Msg['LOGIC_BOOLEAN_TOOLTIP'] = 'Возвращает значение истина или ложь.';
Blockly.Msg['LOGIC_BOOLEAN_TRUE'] = 'истина';
Blockly.Msg['LOGIC_COMPARE_HELPURL'] = 'https://ru.wikipedia.org/wiki/Неравенство';
Blockly.Msg['LOGIC_COMPARE_TOOLTIP_EQ'] = 'Возвращает положительное значение, если вводы равны.';
Blockly.Msg['LOGIC_COMPARE_TOOLTIP_GT'] =
  'Возвращает значение истина, если первая вставка больше второй.';
Blockly.Msg['LOGIC_COMPARE_TOOLTIP_GTE'] =
  'Возвращает значение истина, если первая вставка больше или равна  второй.';
Blockly.Msg['LOGIC_COMPARE_TOOLTIP_LT'] =
  'Возвращает положительное значение, если первый ввод меньше второго.';
Blockly.Msg['LOGIC_COMPARE_TOOLTIP_LTE'] =
  'Возвращает значение истина, если первая вставка меньше или равна  второй.';
Blockly.Msg['LOGIC_COMPARE_TOOLTIP_NEQ'] =
  'Возвращает положительное значение, если вводы не равны.';
Blockly.Msg['LOGIC_NEGATE_HELPURL'] = 'https://github.com/google/blockly/wiki/Logic#not'; // untranslated
Blockly.Msg['LOGIC_NEGATE_TITLE'] = 'не %1';
Blockly.Msg['LOGIC_NEGATE_TOOLTIP'] =
  'Возвращает значение истина, если вставка ложна. Возвращает значение ложь, если вставка истинна.';
Blockly.Msg['LOGIC_NULL'] = 'ничто';
Blockly.Msg['LOGIC_NULL_HELPURL'] = 'https://en.wikipedia.org/wiki/Nullable_type';
Blockly.Msg['LOGIC_NULL_TOOLTIP'] = 'Возвращает ничто.';
Blockly.Msg['LOGIC_OPERATION_AND'] = 'и';
Blockly.Msg['LOGIC_OPERATION_HELPURL'] =
  'https://github.com/google/blockly/wiki/Logic#logical-operations'; // untranslated
Blockly.Msg['LOGIC_OPERATION_OR'] = 'или';
Blockly.Msg['LOGIC_OPERATION_TOOLTIP_AND'] =
  'Возвращает значение истина, если обе вставки истинны.';
Blockly.Msg['LOGIC_OPERATION_TOOLTIP_OR'] =
  'Возвращает значение истина, если хотя бы одна из вставок истинна.';
Blockly.Msg['LOGIC_TERNARY_CONDITION'] = 'выбрать по';
Blockly.Msg['LOGIC_TERNARY_HELPURL'] = 'https://ru.wikipedia.org/wiki/Тернарная_условная_операция';
Blockly.Msg['LOGIC_TERNARY_IF_FALSE'] = 'если ложь';
Blockly.Msg['LOGIC_TERNARY_IF_TRUE'] = 'если истина';
Blockly.Msg['LOGIC_TERNARY_TOOLTIP'] =
  'Проверяет условие выбора. Если условие истинно, возвращает первое значение, в противном случае возвращает второе значение.';
Blockly.Msg['MATH_ADDITION_SYMBOL'] = '+'; // untranslated
Blockly.Msg['MATH_ARITHMETIC_HELPURL'] = 'https://ru.wikipedia.org/wiki/Арифметика';
Blockly.Msg['MATH_ARITHMETIC_TOOLTIP_ADD'] = 'Возвращает сумму двух чисел.';
Blockly.Msg['MATH_ARITHMETIC_TOOLTIP_DIVIDE'] =
  'Возвращает частное от деления первого числа на второе.';
Blockly.Msg['MATH_ARITHMETIC_TOOLTIP_MINUS'] = 'Возвращает разность двух чисел.';
Blockly.Msg['MATH_ARITHMETIC_TOOLTIP_MULTIPLY'] = 'Возвращает произведение двух чисел.';
Blockly.Msg['MATH_ARITHMETIC_TOOLTIP_POWER'] =
  'Возвращает первое число, возведённое в степень второго числа.';
Blockly.Msg['MATH_ATAN2_HELPURL'] = 'https://en.wikipedia.org/wiki/Atan2';
Blockly.Msg['MATH_ATAN2_TITLE'] = 'atan2 от X:%1 Y:%2';
Blockly.Msg['MATH_ATAN2_TOOLTIP'] = 'Возвращает арктангенс точки (X, Y) в градусах от -180 до 180.';
Blockly.Msg['MATH_CHANGE_HELPURL'] =
  'https://ru.wikipedia.org/wiki/%D0%98%D0%B4%D0%B8%D0%BE%D0%BC%D0%B0_%28%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5%29#.D0.98.D0.BD.D0.BA.D1.80.D0.B5.D0.BC.D0.B5.D0.BD.D1.82';
Blockly.Msg['MATH_CHANGE_TITLE'] = 'увеличить %1 на %2';
Blockly.Msg['MATH_CHANGE_TOOLTIP'] = "Добавляет число к переменной '%1'.";
Blockly.Msg['MATH_CONSTANT_HELPURL'] = 'https://ru.wikipedia.org/wiki/Математическая_константа';
Blockly.Msg['MATH_CONSTANT_TOOLTIP'] =
  'Возвращает одну из распространённых  констант: π (3.141...), e (2.718...), φ (1.618...), sqrt(2) (1.414...), sqrt(½) (0.707...) или ∞ (бесконечность).';
Blockly.Msg['MATH_CONSTRAIN_HELPURL'] = 'https://en.wikipedia.org/wiki/Clamping_(graphics)'; // untranslated
Blockly.Msg['MATH_CONSTRAIN_TITLE'] = 'ограничить %1 снизу %2 сверху %3';
Blockly.Msg['MATH_CONSTRAIN_TOOLTIP'] =
  'Ограничивает число нижней и верхней границами (включительно).';
Blockly.Msg['MATH_DIVISION_SYMBOL'] = '÷'; // untranslated
Blockly.Msg['MATH_IS_DIVISIBLE_BY'] = 'делится на';
Blockly.Msg['MATH_IS_EVEN'] = 'чётное';
Blockly.Msg['MATH_IS_NEGATIVE'] = 'отрицательное';
Blockly.Msg['MATH_IS_ODD'] = 'нечётное';
Blockly.Msg['MATH_IS_POSITIVE'] = 'положительное';
Blockly.Msg['MATH_IS_PRIME'] = 'простое';
Blockly.Msg['MATH_IS_TOOLTIP'] =
  'Проверяет, является ли число чётным, нечётным, простым, целым, положительным, отрицательным или оно кратно определённому числу.  Возвращает значение истина или ложь.';
Blockly.Msg['MATH_IS_WHOLE'] = 'целое';
Blockly.Msg['MATH_MODULO_HELPURL'] = 'https://ru.wikipedia.org/wiki/Деление_с_остатком';
Blockly.Msg['MATH_MODULO_TITLE'] = 'остаток от %1 : %2';
Blockly.Msg['MATH_MODULO_TOOLTIP'] = 'Возвращает остаток от деления двух чисел.';
Blockly.Msg['MATH_MULTIPLICATION_SYMBOL'] = '×'; // untranslated
Blockly.Msg['MATH_NUMBER_HELPURL'] = 'https://ru.wikipedia.org/wiki/Число';
Blockly.Msg['MATH_NUMBER_TOOLTIP'] = 'Число.';
Blockly.Msg['MATH_ONLIST_HELPURL'] = ''; // untranslated
Blockly.Msg['MATH_ONLIST_OPERATOR_AVERAGE'] = 'среднее арифметическое списка';
Blockly.Msg['MATH_ONLIST_OPERATOR_MAX'] = 'наибольшее в списке';
Blockly.Msg['MATH_ONLIST_OPERATOR_MEDIAN'] = 'медиана списка';
Blockly.Msg['MATH_ONLIST_OPERATOR_MIN'] = 'наименьшее в списке';
Blockly.Msg['MATH_ONLIST_OPERATOR_MODE'] = 'моды списка';
Blockly.Msg['MATH_ONLIST_OPERATOR_RANDOM'] = 'случайный элемент списка';
Blockly.Msg['MATH_ONLIST_OPERATOR_STD_DEV'] = 'стандартное отклонение списка';
Blockly.Msg['MATH_ONLIST_OPERATOR_SUM'] = 'сумма списка';
Blockly.Msg['MATH_ONLIST_TOOLTIP_AVERAGE'] = 'Возвращает среднее арифметическое списка.';
Blockly.Msg['MATH_ONLIST_TOOLTIP_MAX'] = 'Возвращает наибольшее число списка.';
Blockly.Msg['MATH_ONLIST_TOOLTIP_MEDIAN'] = 'Возвращает медиану списка.';
Blockly.Msg['MATH_ONLIST_TOOLTIP_MIN'] = 'Возвращает наименьшее число списка.';
Blockly.Msg['MATH_ONLIST_TOOLTIP_MODE'] =
  'Возвращает список наиболее часто встречающихся элементов списка.';
Blockly.Msg['MATH_ONLIST_TOOLTIP_RANDOM'] = 'Возвращает случайный элемент списка.';
Blockly.Msg['MATH_ONLIST_TOOLTIP_STD_DEV'] = 'Возвращает стандартное отклонение списка.';
Blockly.Msg['MATH_ONLIST_TOOLTIP_SUM'] = 'Возвращает сумму всех чисел в списке.';
Blockly.Msg['MATH_POWER_SYMBOL'] = '^'; // untranslated
Blockly.Msg['MATH_RANDOM_FLOAT_HELPURL'] =
  'https://ru.wikipedia.org/wiki/Генератор_псевдослучайных_чисел';
Blockly.Msg['MATH_RANDOM_FLOAT_TITLE_RANDOM'] = 'случайное число от 0 (включительно) до 1';
Blockly.Msg['MATH_RANDOM_FLOAT_TOOLTIP'] =
  'Возвращает случайное число от 0.0 (включительно) до 1.0.';
Blockly.Msg['MATH_RANDOM_INT_HELPURL'] =
  'https://ru.wikipedia.org/wiki/Генератор_псевдослучайных_чисел';
Blockly.Msg['MATH_RANDOM_INT_TITLE'] = 'случайное целое число от %1 для %2';
Blockly.Msg['MATH_RANDOM_INT_TOOLTIP'] =
  'Возвращает случайное число между двумя заданными пределами (включая и их).';
Blockly.Msg['MATH_ROUND_HELPURL'] = 'https://ru.wikipedia.org/wiki/Округление';
Blockly.Msg['MATH_ROUND_OPERATOR_ROUND'] = 'округлить';
Blockly.Msg['MATH_ROUND_OPERATOR_ROUNDDOWN'] = 'округлить к меньшему';
Blockly.Msg['MATH_ROUND_OPERATOR_ROUNDUP'] = 'округлить к большему';
Blockly.Msg['MATH_ROUND_TOOLTIP'] = 'Округляет число до большего или меньшего.';
Blockly.Msg['MATH_SINGLE_HELPURL'] = 'https://ru.wikipedia.org/wiki/Квадратный_корень';
Blockly.Msg['MATH_SINGLE_OP_ABSOLUTE'] = 'модуль';
Blockly.Msg['MATH_SINGLE_OP_ROOT'] = 'квадратный корень';
Blockly.Msg['MATH_SINGLE_TOOLTIP_ABS'] = 'Возвращает модуль числа';
Blockly.Msg['MATH_SINGLE_TOOLTIP_EXP'] = 'Возвращает е в указанной степени.';
Blockly.Msg['MATH_SINGLE_TOOLTIP_LN'] = 'Возвращает натуральный логарифм числа.';
Blockly.Msg['MATH_SINGLE_TOOLTIP_LOG10'] = 'Возвращает десятичный логарифм числа.';
Blockly.Msg['MATH_SINGLE_TOOLTIP_NEG'] = 'Возвращает противоположное число.';
Blockly.Msg['MATH_SINGLE_TOOLTIP_POW10'] = 'Возвращает 10 в указанной степени.';
Blockly.Msg['MATH_SINGLE_TOOLTIP_ROOT'] = 'Возвращает квадратный корень числа.';
Blockly.Msg['MATH_SUBTRACTION_SYMBOL'] = '-'; // untranslated
Blockly.Msg['MATH_TRIG_ACOS'] = 'acos'; // untranslated
Blockly.Msg['MATH_TRIG_ASIN'] = 'asin'; // untranslated
Blockly.Msg['MATH_TRIG_ATAN'] = 'atan'; // untranslated
Blockly.Msg['MATH_TRIG_COS'] = 'cos'; // untranslated
Blockly.Msg['MATH_TRIG_HELPURL'] = 'https://ru.wikipedia.org/wiki/Тригонометрические_функции';
Blockly.Msg['MATH_TRIG_SIN'] = 'sin'; // untranslated
Blockly.Msg['MATH_TRIG_TAN'] = 'tan'; // untranslated
Blockly.Msg['MATH_TRIG_TOOLTIP_ACOS'] = 'Возвращает арккосинус (в градусах).';
Blockly.Msg['MATH_TRIG_TOOLTIP_ASIN'] = 'Возвращает арксинус (в градусах).';
Blockly.Msg['MATH_TRIG_TOOLTIP_ATAN'] = 'Возвращает арктангенс (в градусах)';
Blockly.Msg['MATH_TRIG_TOOLTIP_COS'] = 'Возвращает косинус угла в градусах.';
Blockly.Msg['MATH_TRIG_TOOLTIP_SIN'] = 'Возвращает синус угла в градусах.';
Blockly.Msg['MATH_TRIG_TOOLTIP_TAN'] = 'Возвращает тангенс угла в градусах.';
Blockly.Msg['NEW_COLOUR_VARIABLE'] = 'Создать переменную цвета...';
Blockly.Msg['NEW_NUMBER_VARIABLE'] = 'Создать числовую переменную...';
Blockly.Msg['NEW_STRING_VARIABLE'] = 'Создать строковую переменную...';
Blockly.Msg['NEW_VARIABLE'] = 'Создать переменную…';
Blockly.Msg['NEW_VARIABLE_TITLE'] = 'Имя новой переменной:';
Blockly.Msg['NEW_VARIABLE_TYPE_TITLE'] = 'Новый тип переменной:';
Blockly.Msg['ORDINAL_NUMBER_SUFFIX'] = ''; // untranslated
Blockly.Msg['PROCEDURES_ALLOW_STATEMENTS'] = 'разрешить операторы';
Blockly.Msg['PROCEDURES_BEFORE_PARAMS'] = 'с:';
Blockly.Msg['PROCEDURES_CALLNORETURN_HELPURL'] = 'https://ru.wikipedia.org/wiki/Подпрограмма';
Blockly.Msg['PROCEDURES_CALLNORETURN_TOOLTIP'] =
  "Исполняет определённую пользователем процедуру '%1'.";
Blockly.Msg['PROCEDURES_CALLRETURN_HELPURL'] = 'https://ru.wikipedia.org/wiki/Подпрограмма';
Blockly.Msg['PROCEDURES_CALLRETURN_TOOLTIP'] =
  "Исполняет определённую пользователем процедуру '%1' и возвращает вычисленное  значение.";
Blockly.Msg['PROCEDURES_CALL_BEFORE_PARAMS'] = 'с:';
Blockly.Msg['PROCEDURES_CREATE_DO'] = "Создать вызов '%1'";
Blockly.Msg['PROCEDURES_DEFNORETURN_COMMENT'] = 'Опишите эту функцию…';
Blockly.Msg['PROCEDURES_DEFNORETURN_DO'] = ''; // untranslated
Blockly.Msg['PROCEDURES_DEFNORETURN_HELPURL'] = 'https://en.wikipedia.org/wiki/Subroutine'; // untranslated
Blockly.Msg['PROCEDURES_DEFNORETURN_PROCEDURE'] = 'выполнить что-то';
Blockly.Msg['PROCEDURES_DEFNORETURN_TITLE'] = 'чтобы';
Blockly.Msg['PROCEDURES_DEFNORETURN_TOOLTIP'] = 'Создаёт процедуру, не возвращающую значение.';
Blockly.Msg['PROCEDURES_DEFRETURN_HELPURL'] = 'https://en.wikipedia.org/wiki/Subroutine'; // untranslated
Blockly.Msg['PROCEDURES_DEFRETURN_RETURN'] = 'вернуть';
Blockly.Msg['PROCEDURES_DEFRETURN_TOOLTIP'] = 'Создаёт процедуру, возвращающую значение.';
Blockly.Msg['PROCEDURES_DEF_DUPLICATE_WARNING'] =
  'Предупреждение: эта функция имеет повторяющиеся параметры.';
Blockly.Msg['PROCEDURES_HIGHLIGHT_DEF'] = 'Выделить определение процедуры';
Blockly.Msg['PROCEDURES_IFRETURN_HELPURL'] = 'http://c2.com/cgi/wiki?GuardClause';
Blockly.Msg['PROCEDURES_IFRETURN_TOOLTIP'] =
  'Если первое значение истинно, возвращает второе значение.';
Blockly.Msg['PROCEDURES_IFRETURN_WARNING'] =
  'Предупреждение: Этот блок может использоваться только внутри определения функции.';
Blockly.Msg['PROCEDURES_MUTATORARG_TITLE'] = 'имя параметра:';
Blockly.Msg['PROCEDURES_MUTATORARG_TOOLTIP'] = 'Добавить входной параметр в функцию.';
Blockly.Msg['PROCEDURES_MUTATORCONTAINER_TITLE'] = 'параметры';
Blockly.Msg['PROCEDURES_MUTATORCONTAINER_TOOLTIP'] =
  'Добавить, удалить или изменить порядок входных параметров для этой функции.';
Blockly.Msg['REDO'] = 'Повторить';
Blockly.Msg['REMOVE_COMMENT'] = 'Удалить комментарий';
Blockly.Msg['RENAME_VARIABLE'] = 'Переименовать переменную…';
Blockly.Msg['RENAME_VARIABLE_TITLE'] = "Переименовать все переменные '%1' в:";
Blockly.Msg['TEXT_APPEND_HELPURL'] =
  'https://github.com/google/blockly/wiki/Text#text-modification'; // untranslated
Blockly.Msg['TEXT_APPEND_TITLE'] = 'к %1 добавить текст %2';
Blockly.Msg['TEXT_APPEND_TOOLTIP'] = 'Добавить текст к переменной «%1».';
Blockly.Msg['TEXT_CHANGECASE_HELPURL'] =
  'https://github.com/google/blockly/wiki/Text#adjusting-text-case'; // untranslated
Blockly.Msg['TEXT_CHANGECASE_OPERATOR_LOWERCASE'] = 'в строчные буквы';
Blockly.Msg['TEXT_CHANGECASE_OPERATOR_TITLECASE'] = 'в Заглавные Начальные Буквы';
Blockly.Msg['TEXT_CHANGECASE_OPERATOR_UPPERCASE'] = 'в ЗАГЛАВНЫЕ БУКВЫ';
Blockly.Msg['TEXT_CHANGECASE_TOOLTIP'] =
  'Возвращает копию текста с ЗАГЛАВНЫМИ или строчными буквами.';
Blockly.Msg['TEXT_CHARAT_FIRST'] = 'взять первую букву';
Blockly.Msg['TEXT_CHARAT_FROM_END'] = 'взять букву № с конца';
Blockly.Msg['TEXT_CHARAT_FROM_START'] = 'взять букву №';
Blockly.Msg['TEXT_CHARAT_HELPURL'] = 'https://github.com/google/blockly/wiki/Text#extracting-text'; // untranslated
Blockly.Msg['TEXT_CHARAT_LAST'] = 'взять последнюю букву';
Blockly.Msg['TEXT_CHARAT_RANDOM'] = 'взять случайную букву';
Blockly.Msg['TEXT_CHARAT_TAIL'] = ''; // untranslated
Blockly.Msg['TEXT_CHARAT_TITLE'] = 'в тексте %1 %2';
Blockly.Msg['TEXT_CHARAT_TOOLTIP'] = 'Возвращает букву в указанной позиции.';
Blockly.Msg['TEXT_COUNT_HELPURL'] =
  'https://github.com/google/blockly/wiki/Text#counting-substrings';
Blockly.Msg['TEXT_COUNT_MESSAGE0'] = 'подсчитать количество %1 в %2';
Blockly.Msg['TEXT_COUNT_TOOLTIP'] =
  'Подсчитать, сколько раз отрывок текста появляется в другом тексте.';
Blockly.Msg['TEXT_CREATE_JOIN_ITEM_TOOLTIP'] = 'Добавить элемент к тексту.';
Blockly.Msg['TEXT_CREATE_JOIN_TITLE_JOIN'] = 'соединить';
Blockly.Msg['TEXT_CREATE_JOIN_TOOLTIP'] =
  'Добавьте, удалите, переставьте фрагменты для переделки текстового блока.';
Blockly.Msg['TEXT_GET_SUBSTRING_END_FROM_END'] = 'по букву № с конца';
Blockly.Msg['TEXT_GET_SUBSTRING_END_FROM_START'] = 'по букву №';
Blockly.Msg['TEXT_GET_SUBSTRING_END_LAST'] = 'по последнюю букву';
Blockly.Msg['TEXT_GET_SUBSTRING_HELPURL'] =
  'https://github.com/google/blockly/wiki/Text#extracting-a-region-of-text'; // untranslated
Blockly.Msg['TEXT_GET_SUBSTRING_INPUT_IN_TEXT'] = 'в тексте';
Blockly.Msg['TEXT_GET_SUBSTRING_START_FIRST'] = 'взять подстроку с первой буквы';
Blockly.Msg['TEXT_GET_SUBSTRING_START_FROM_END'] = 'взять подстроку с буквы № с конца';
Blockly.Msg['TEXT_GET_SUBSTRING_START_FROM_START'] = 'взять подстроку с буквы №';
Blockly.Msg['TEXT_GET_SUBSTRING_TAIL'] = ''; // untranslated
Blockly.Msg['TEXT_GET_SUBSTRING_TOOLTIP'] = 'Возвращает указанную часть текста.';
Blockly.Msg['TEXT_INDEXOF_HELPURL'] = 'https://github.com/google/blockly/wiki/Text#finding-text'; // untranslated
Blockly.Msg['TEXT_INDEXOF_OPERATOR_FIRST'] = 'найти первое вхождение текста';
Blockly.Msg['TEXT_INDEXOF_OPERATOR_LAST'] = 'найти последнее вхождение текста';
Blockly.Msg['TEXT_INDEXOF_TITLE'] = 'в тексте %1 %2 %3';
Blockly.Msg['TEXT_INDEXOF_TOOLTIP'] =
  'Возвращает номер позиции первого/последнего вхождения первого текста во  втором.  Возвращает %1, если текст не найден.';
Blockly.Msg['TEXT_ISEMPTY_HELPURL'] =
  'https://github.com/google/blockly/wiki/Text#checking-for-empty-text'; // untranslated
Blockly.Msg['TEXT_ISEMPTY_TITLE'] = '%1 пуст';
Blockly.Msg['TEXT_ISEMPTY_TOOLTIP'] =
  'Возвращает значение истина, если предоставленный текст пуст.';
Blockly.Msg['TEXT_JOIN_HELPURL'] = 'https://github.com/google/blockly/wiki/Text#text-creation'; // untranslated
Blockly.Msg['TEXT_JOIN_TITLE_CREATEWITH'] = 'создать текст из';
Blockly.Msg['TEXT_JOIN_TOOLTIP'] = 'Создаёт фрагмент текста, объединяя любое число элементов';
Blockly.Msg['TEXT_LENGTH_HELPURL'] =
  'https://github.com/google/blockly/wiki/Text#text-modification'; // untranslated
Blockly.Msg['TEXT_LENGTH_TITLE'] = 'длина %1';
Blockly.Msg['TEXT_LENGTH_TOOLTIP'] =
  'Возвращает число символов (включая пробелы) в заданном тексте.';
Blockly.Msg['TEXT_PRINT_HELPURL'] = 'https://github.com/google/blockly/wiki/Text#printing-text'; // untranslated
Blockly.Msg['TEXT_PRINT_TITLE'] = 'напечатать %1';
Blockly.Msg['TEXT_PRINT_TOOLTIP'] = 'Печатает текст, число или другой объект.';
Blockly.Msg['TEXT_PROMPT_HELPURL'] =
  'https://github.com/google/blockly/wiki/Text#getting-input-from-the-user'; // untranslated
Blockly.Msg['TEXT_PROMPT_TOOLTIP_NUMBER'] = 'Запросить у пользователя число.';
Blockly.Msg['TEXT_PROMPT_TOOLTIP_TEXT'] = 'Запросить у пользователя текст.';
Blockly.Msg['TEXT_PROMPT_TYPE_NUMBER'] = 'запросить число с подсказкой';
Blockly.Msg['TEXT_PROMPT_TYPE_TEXT'] = 'запросить текст с подсказкой';
Blockly.Msg['TEXT_REPLACE_HELPURL'] =
  'https://github.com/google/blockly/wiki/Text#replacing-substrings';
Blockly.Msg['TEXT_REPLACE_MESSAGE0'] = 'заменить %1 на %2 в %3';
Blockly.Msg['TEXT_REPLACE_TOOLTIP'] = 'Заменить все вхождения некоторого текста другим текстом.';
Blockly.Msg['TEXT_REVERSE_HELPURL'] = 'https://github.com/google/blockly/wiki/Text#reversing-text';
Blockly.Msg['TEXT_REVERSE_MESSAGE0'] = 'изменить порядок на обратный %1';
Blockly.Msg['TEXT_REVERSE_TOOLTIP'] = 'Меняет порядок символов в тексте на обратный.';
Blockly.Msg['TEXT_TEXT_HELPURL'] = 'https://ru.wikipedia.org/wiki/Строковый_тип';
Blockly.Msg['TEXT_TEXT_TOOLTIP'] = 'Буква, слово или строка текста.';
Blockly.Msg['TEXT_TRIM_HELPURL'] =
  'https://github.com/google/blockly/wiki/Text#trimming-removing-spaces'; // untranslated
Blockly.Msg['TEXT_TRIM_OPERATOR_BOTH'] = 'обрезать пробелы с двух сторон';
Blockly.Msg['TEXT_TRIM_OPERATOR_LEFT'] = 'обрезать пробелы слева';
Blockly.Msg['TEXT_TRIM_OPERATOR_RIGHT'] = 'обрезать пробелы справа';
Blockly.Msg['TEXT_TRIM_TOOLTIP'] =
  'Возвращает копию текста с пробелами, удалёнными с одного или обоих концов.';
Blockly.Msg['TODAY'] = 'Сегодня';
Blockly.Msg['UNDO'] = 'Отменить';
Blockly.Msg['UNNAMED_KEY'] = 'без названия';
Blockly.Msg['VARIABLES_DEFAULT_NAME'] = 'элемент';
Blockly.Msg['VARIABLES_GET_CREATE_SET'] = 'Создать блок "присвоить" для %1';
Blockly.Msg['VARIABLES_GET_HELPURL'] = 'https://github.com/google/blockly/wiki/Variables#get'; // untranslated
Blockly.Msg['VARIABLES_GET_TOOLTIP'] = 'Возвращает значение этой переменной.';
Blockly.Msg['VARIABLES_SET'] = 'присвоить %1 = %2';
Blockly.Msg['VARIABLES_SET_CREATE_GET'] = 'Создать вставку %1';
Blockly.Msg['VARIABLES_SET_HELPURL'] = 'https://github.com/google/blockly/wiki/Variables#set'; // untranslated
Blockly.Msg['VARIABLES_SET_TOOLTIP'] = 'Присваивает переменной значение вставки.';
Blockly.Msg['VARIABLE_ALREADY_EXISTS'] = "Переменная с именем '%1' уже существует.";
Blockly.Msg['VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE'] =
  "Название переменной '%1' уже используется другой типа: '%2'.";
Blockly.Msg['WORKSPACE_ARIA_LABEL'] = 'Рабочая область Blockly';
Blockly.Msg['WORKSPACE_COMMENT_DEFAULT_TEXT'] = 'Напишите здесь что-нибудь...';
Blockly.Msg['CONTROLS_FOREACH_INPUT_DO'] = Blockly.Msg['CONTROLS_REPEAT_INPUT_DO'];
Blockly.Msg['CONTROLS_FOR_INPUT_DO'] = Blockly.Msg['CONTROLS_REPEAT_INPUT_DO'];
Blockly.Msg['CONTROLS_IF_ELSEIF_TITLE_ELSEIF'] = Blockly.Msg['CONTROLS_IF_MSG_ELSEIF'];
Blockly.Msg['CONTROLS_IF_ELSE_TITLE_ELSE'] = Blockly.Msg['CONTROLS_IF_MSG_ELSE'];
Blockly.Msg['CONTROLS_IF_IF_TITLE_IF'] = Blockly.Msg['CONTROLS_IF_MSG_IF'];
Blockly.Msg['CONTROLS_IF_MSG_THEN'] = Blockly.Msg['CONTROLS_REPEAT_INPUT_DO'];
Blockly.Msg['CONTROLS_WHILEUNTIL_INPUT_DO'] = Blockly.Msg['CONTROLS_REPEAT_INPUT_DO'];
Blockly.Msg['LISTS_CREATE_WITH_ITEM_TITLE'] = Blockly.Msg['VARIABLES_DEFAULT_NAME'];
Blockly.Msg['LISTS_GET_INDEX_HELPURL'] = Blockly.Msg['LISTS_INDEX_OF_HELPURL'];
Blockly.Msg['LISTS_GET_INDEX_INPUT_IN_LIST'] = Blockly.Msg['LISTS_INLIST'];
Blockly.Msg['LISTS_GET_SUBLIST_INPUT_IN_LIST'] = Blockly.Msg['LISTS_INLIST'];
Blockly.Msg['LISTS_INDEX_OF_INPUT_IN_LIST'] = Blockly.Msg['LISTS_INLIST'];
Blockly.Msg['LISTS_SET_INDEX_INPUT_IN_LIST'] = Blockly.Msg['LISTS_INLIST'];
Blockly.Msg['MATH_CHANGE_TITLE_ITEM'] = Blockly.Msg['VARIABLES_DEFAULT_NAME'];
Blockly.Msg['PROCEDURES_DEFRETURN_COMMENT'] = Blockly.Msg['PROCEDURES_DEFNORETURN_COMMENT'];
Blockly.Msg['PROCEDURES_DEFRETURN_DO'] = Blockly.Msg['PROCEDURES_DEFNORETURN_DO'];
Blockly.Msg['PROCEDURES_DEFRETURN_PROCEDURE'] = Blockly.Msg['PROCEDURES_DEFNORETURN_PROCEDURE'];
Blockly.Msg['PROCEDURES_DEFRETURN_TITLE'] = Blockly.Msg['PROCEDURES_DEFNORETURN_TITLE'];
Blockly.Msg['TEXT_APPEND_VARIABLE'] = Blockly.Msg['VARIABLES_DEFAULT_NAME'];
Blockly.Msg['TEXT_CREATE_JOIN_ITEM_TITLE_ITEM'] = Blockly.Msg['VARIABLES_DEFAULT_NAME'];

Blockly.Msg['MATH_HUE'] = '230';
Blockly.Msg['LOOPS_HUE'] = '120';
Blockly.Msg['LISTS_HUE'] = '260';
Blockly.Msg['LOGIC_HUE'] = '210';
Blockly.Msg['VARIABLES_HUE'] = '330';
Blockly.Msg['TEXTS_HUE'] = '160';
Blockly.Msg['PROCEDURES_HUE'] = '290';
Blockly.Msg['COLOUR_HUE'] = '20';
Blockly.Msg['VARIABLES_DYNAMIC_HUE'] = '310';
