import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ArrowForwardSquareIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_614_2318)">
          <path
            d="M8.67383 21.8721H19.3174C21.2158 21.8721 22.2002 20.8877 22.2002 19.0156V8.31934C22.2002 6.43848 21.2158 5.46289 19.3174 5.46289H8.67383C6.77539 5.46289 5.79102 6.43848 5.79102 8.31934V19.0156C5.79102 20.8965 6.77539 21.8721 8.67383 21.8721ZM8.7793 20.1494C7.96191 20.1494 7.51367 19.7188 7.51367 18.8662V8.45996C7.51367 7.60742 7.96191 7.18555 8.7793 7.18555H19.2119C20.0205 7.18555 20.4775 7.60742 20.4775 8.45996V18.8662C20.4775 19.7188 20.0205 20.1494 19.2119 20.1494H8.7793ZM18.5352 13.6455C18.5352 13.417 18.4473 13.2324 18.2451 13.0303L15.2656 10.0859C15.125 9.94531 14.958 9.88379 14.7383 9.88379C14.334 9.88379 14.0352 10.1826 14.0352 10.5957C14.0352 10.8066 14.1318 10.9912 14.2637 11.123L15.3271 12.1514L16.3203 12.9512L14.5186 12.8721H10.2031C9.76367 12.8721 9.44727 13.1973 9.44727 13.6455C9.44727 14.0938 9.76367 14.4277 10.2031 14.4277H14.5186L16.3291 14.3486L15.3359 15.1484L14.2637 16.168C14.123 16.2998 14.0352 16.4844 14.0352 16.7041C14.0352 17.1084 14.334 17.4248 14.7383 17.4248C14.958 17.4248 15.125 17.3457 15.2656 17.2051L18.2451 14.2695C18.4385 14.085 18.5352 13.8916 18.5352 13.6455Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_614_2318">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ArrowForwardSquareIcon;
