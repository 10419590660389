import { Button, Input, Select } from '@app/components/ui';
import useTranslation from '@app/hooks/use-translation';
import { useGetAliasesListQuery } from '@app/store/api/alias-settings-page.api';
import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Icon from '@app/components/ui/icons';
import { ChatRoleSettingsType, CreateRoleType } from '@app/interfaces/chat-roles-settings.type';
import { useGetChatRolesQuery } from '@app/store/api/chat-api/get-chat-roles.api';

type CommonSettingsAddFormType = {
  onFormSubmit: (data: CreateRoleType) => void;
  editMode: boolean;
  channelData?: ChatRoleSettingsType | undefined;
};

export const ChatCommonSettingsAddForm: FC<CommonSettingsAddFormType> = ({
  onFormSubmit,
  editMode,
  channelData,
}) => {
  const { t } = useTranslation('pages.settings');
  const { data: aliasList } = useGetAliasesListQuery();
  const { data: chatRoleBulk } = useGetChatRolesQuery();

  const formSetup = useMemo(() => {
    return editMode
      ? {
          name: channelData?.name,
          alias: channelData?.alias ? { alias_id: channelData.alias.alias_id } : undefined,
          icon: channelData?.icon,
        }
      : undefined;
  }, [channelData, editMode]);

  const { handleSubmit, register, setValue, watch } = useForm<CreateRoleType>({
    defaultValues: formSetup,
  });

  const onSubmit = (data: CreateRoleType) => {
    const { alias, ...restData } = data;

    const formattedData = {
      ...restData,
      alias_id: alias?.alias_id || '',
    };

    if (editMode && channelData) {
      onFormSubmit({ ...formattedData, crole_id: channelData.crole_id });
    } else {
      onFormSubmit(formattedData);
    }
  };

  const getRoleModalOptionsList = () => {
    if (!aliasList || !chatRoleBulk) return [];
    const existingAliases = new Set(chatRoleBulk.map((role) => role?.alias?.alias_id));
    const filteredAliases = aliasList.aliases.filter((alias) => {
      return alias.type_value === 'str' && !existingAliases.has(alias.alias_id);
    });
    return filteredAliases.map((alias) => ({
      title: alias.meta_name || alias.name,
      value: alias.alias_id,
    }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-[20px]">
        <div className="flex items-center gap-[55px]">
          <span className="text-[15px] font-[500] leading-[110%] text-1color tracking-tight whitespace-nowrap">
            {t('common_setting.chatRoles.modal.role_name')}
          </span>
          <Input
            {...register('name')}
            placeholder={t('common_setting.chatRoles.modal.role_name_palceholder')}
            name="name"
          />
        </div>
        {!editMode ? (
          <div className="flex items-center gap-[116px] pr-[0px]">
            <span className="text-[15px] font-[500] leading-[110%] text-1color tracking-tight whitespace-nowrap">
              {t('common_setting.chatRoles.alias')}
            </span>
            <Select
              options={getRoleModalOptionsList() || []}
              defaultValue={watch('alias')?.alias_id || ''}
              onChange={(value) => setValue('alias', { alias_id: value as string })}
            />
          </div>
        ) : null}
        <div className="flex items-start gap-[120px]">
          <span className="text-[15px] font-[500] leading-[110%] text-1color tracking-tight whitespace-nowrap">
            {t('common_setting.form.icon')}
          </span>
          <div>
            <div className="flex items-center gap-[8px]">
              <input
                name="icon"
                onChange={() => setValue('icon', 'operator')}
                type="checkbox"
                checked={watch().icon === 'operator'}
                value="operator"
                className="focus:ring-offset-0 focus:ring-0 rounded-full"
              />
              <Icon className="text-3color" size={17} name="HeadphoneIcon" />
              <span className="text-1color text-[14px] font-[400] leading-[30px] tracking-tight">
                {t('common_setting.form.operator')}
              </span>
            </div>
            <div className="flex items-center gap-[8px]">
              <input
                name="icon"
                onChange={() => setValue('icon', 'client')}
                type="checkbox"
                checked={watch().icon === 'client'}
                value="client"
                className="focus:ring-offset-0 focus:ring-0 rounded-full"
              />
              <Icon className="text-3color" size={17} name="ProfileIcon" />
              <span className="text-1color text-[14px] font-[400] leading-[30px] tracking-tight">
                {t('common_setting.form.client')}
              </span>
            </div>
            <div className="flex items-center gap-[8px]">
              <input
                name="icon"
                onChange={() => setValue('icon', 'other')}
                type="checkbox"
                checked={watch().icon === 'other'}
                value="other"
                className="focus:ring-offset-0 focus:ring-0 rounded-full"
              />
              <Icon className="text-3color" size={17} name="HelpCircleIcon" />
              <span className="text-1color text-[14px] font-[400] leading-[30px] tracking-tight">
                {t('common_setting.form.other')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full">
        <Button
          type="submit"
          label={t('common_setting.form.button_label')}
          isFull
          className="!px-[58px] !py-[20px] mt-[32px]"
        />
      </div>
    </form>
  );
};
