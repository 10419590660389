module.exports = {
  loading: 'Loading...',
  from: 'from',
  show: 'Show',
  go_to_page: 'Go to page:',
  input: {
    offset_placeholder: 'Select page',
  },
  records_queue: 'Records in the calculation queue:'
};
