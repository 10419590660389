import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { Filter, Range } from '@app/components/ui/easy-filter/types';

import { baseQuery } from '../baseQuery';

export const analyticsProjectPresetApi = createApi({
  reducerPath: 'analyticsProjectPresetApi',
  tagTypes: ['analyticsProjectPreset'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getAnalyticsProjectPreset: build.query<
      { preset: (Filter & Range) | null },
      { project_id: string }
    >({
      query: (params) => ({
        url: serverRoutes.analyticsProjectPreset,
        params,
      }),
      providesTags: ['analyticsProjectPreset'],
    }),
    editProjectPreset: build.mutation<
      { preset: (Filter & Range) | null },
      { params: { project_id: string }; body: Filter & Range }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.analyticsProjectPreset,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['analyticsProjectPreset'],
    }),
    getChatAnalyticsProjectPreset: build.query<
      { preset: (Filter & Range) | null },
      { project_id: string }
    >({
      query: (params) => ({
        url: serverRoutes.analyticsChatProjectPreset,
        params,
      }),
      providesTags: ['analyticsProjectPreset'],
    }),
    editChatProjectPreset: build.mutation<
      { preset: (Filter & Range) | null },
      { params: { project_id: string }; body: Filter & Range }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.analyticsChatProjectPreset,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['analyticsProjectPreset'],
    }),
  }),
});

export const {
  useGetAnalyticsProjectPresetQuery,
  useEditProjectPresetMutation,
  useGetChatAnalyticsProjectPresetQuery,
  useEditChatProjectPresetMutation,
} = analyticsProjectPresetApi;
