import classNames from 'classnames';

export const circleBlueButtonClassName = classNames(
  'w-[30px]',
  'h-[30px]',
  'bg-action',
  'text-white',
  'inline-flex',
  'justify-center',
  'items-center',
  'rounded-full',
  'mr-[16px]',
);
