import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { setUserSettings } from '@app/store/reducers/user-settings.slice';

import { UserSettingsType } from '@app/interfaces/slices-types/user-settings-type';

import { baseQuery } from '../baseQuery';

export const userSettingsApi = createApi({
  reducerPath: 'userSettingsApi',
  tagTypes: ['userSettings'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getUserSettings: build.query<UserSettingsType, void>({
      query: () => ({
        url: serverRoutes.userSettings,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUserSettings(data));
        } catch (error) {
          console.error(error);
        }
      },
      providesTags: ['userSettings'],
    }),
    updateUserSettings: build.mutation({
      query: (body: UserSettingsType) => ({
        url: serverRoutes.userSettings,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['userSettings'],
    }),
    // editProjectPreset: build.mutation<
    //   { preset: (Filter & Range) | null },
    //   { params: { project_id: string }; body: Filter & Range }
    // >({
    //   query: ({ params, body }) => ({
    //     url: serverRoutes.analyticsProjectPreset,
    //     method: 'PUT',
    //     params,
    //     body,
    //   }),
    //   invalidatesTags: ['analyticsProjectPreset'],
    // }),
  }),
});

export const { useGetUserSettingsQuery, useUpdateUserSettingsMutation } = userSettingsApi;
