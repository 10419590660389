module.exports = {
  range_by_days_input_error_label:'Maximum value 90 days',
  Date_time: 'Date and time',
  last: 'last',
  last_from_capital: 'Last',
  days: 'days',
  or: 'or',
  search_conditions: 'No search conditions are specified',
  change: 'Change', //
  confirm: 'Confirm', //
  cancel: 'Cancel', //
  edit_filter: 'Edit filter', //
  alias: 'Parameter',
  condition: 'Condition',
  values: 'Value options',
  value: 'Value',
  filter_by_Date: 'Filter by date',
  from: 'from',
  before: 'before',
  and: 'and',
  write_Date: 'Enter date',
  write_time: 'Enter time',
  write_value: 'Enter value',
  time: 'Time',
  calendar_bind: 'Calendar linkage',
  add_preset_from_template_title: 'Add preset from template',
  fillFromTemplate: 'Fill in from template',
};
