import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { UnsubscribeFromReportError } from '@app/pages/email-confirmation';

export const unsubscribeFromReportErrorRoute = (
  <Route
    path={navigationRoutes.unsubscribeFromReportError + '/*'}
    element={<UnsubscribeFromReportError />}
  />
);
