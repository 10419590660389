import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const FirstAnswerDuration: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 57 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M57 28.5C57 36.0587 53.9973 43.3078 48.6525 48.6525C43.3078 53.9973 36.0587 57 28.5 57C20.9413 57 13.6922 53.9973 8.34746 48.6525C3.00267 43.3078 0 36.0587 0 28.5C0 20.9413 3.00267 13.6922 8.34746 8.34746C13.6922 3.00267 20.9413 0 28.5 0C36.0587 0 43.3078 3.00267 48.6525 8.34746C53.9973 13.6922 57 20.9413 57 28.5Z"
          fill="#EEF9FD"
        />
        <path
          d="M30.5552 32.5667C30.8011 32.5667 31.0854 32.5966 31.2783 32.7895C31.3213 32.8324 31.5363 33.0596 31.5363 33.3055C31.5363 33.5513 31.4073 33.6924 31.2783 33.8214C31.1045 33.9953 30.8011 33.9464 30.5552 33.9464L26.8472 33.9464C26.5832 33.9464 26.3559 33.6966 26.3559 33.6966C26.182 33.5228 26.1365 33.2653 26.1365 33.0194L26.1365 29.3113C26.1365 29.0655 26.182 28.8297 26.3559 28.6558C26.5297 28.482 26.6468 28.4038 26.8926 28.4038C27.1385 28.4038 27.2796 28.5328 27.4086 28.6618C27.5162 28.7908 27.5162 29.0655 27.5162 29.3113L27.5162 32.5667L30.5552 32.5667Z"
          fill="#2987CC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6334 32.2573C38.6334 38.4561 33.6086 43.4809 27.4099 43.4809C21.2111 43.4809 16.1863 38.4561 16.1863 32.2573C16.1863 26.0585 21.2111 21.0337 27.4099 21.0337C33.6086 21.0337 38.6334 26.0585 38.6334 32.2573ZM37.3374 32.2573C37.3374 34.6386 36.1335 37.6132 34.4496 39.297C32.7657 40.9809 29.7912 42.1848 27.4099 42.1848C25.0285 42.1848 22.054 40.7645 20.3701 39.0806C18.6863 37.3968 17.4823 34.6386 17.4823 32.2573C17.4823 29.8759 18.6863 26.9014 20.3701 25.2175C22.054 23.5337 25.0285 22.3297 27.4099 22.3297C29.7912 22.3297 32.5494 23.75 34.2332 25.4339C35.9171 27.1178 37.3374 29.8759 37.3374 32.2573Z"
          fill="#2987CC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.4309 9.57031H39.6206C41.51 9.5846 43.0302 11.1276 43.0164 13.0171V17.202C43.023 18.1095 42.6689 18.9824 42.0319 19.6288C41.3949 20.2752 40.5272 20.6421 39.6197 20.6487H33.4309L30.0342 22.5525V13.0171C30.0275 12.1096 30.3817 11.2366 31.0187 10.5902C31.6557 9.94379 32.5234 9.57693 33.4309 9.57031Z"
          fill="#2987CC"
        />
        <path d="M33.2797 13.5271H39.7708" stroke="white" strokeLinecap="round" />
        <path d="M34.0912 15.9007H38.9596" stroke="white" strokeLinecap="round" />
      </svg>
    </div>
  );
};

export default FirstAnswerDuration;
