import React, { FC } from 'react';

import { IconType } from '../icon.type';

const HandThumbsdownCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_434)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM16.584 12.2656C16.6104 10.5518 15.2305 9.27734 13.0684 9.25098L12.4443 9.24219C11.8555 9.2334 11.4072 9.28613 11.1523 9.34766C10.7656 9.44434 10.4053 9.67285 10.4053 10.1211C10.4053 10.2881 10.458 10.4287 10.502 10.5254C10.5371 10.5781 10.5283 10.6221 10.4844 10.6484C10.1943 10.7627 9.95703 11.0527 9.95703 11.4043C9.95703 11.6152 10.0186 11.7998 10.124 11.9316C10.1768 11.9932 10.1592 12.0547 10.0977 12.0986C9.87793 12.2305 9.7373 12.4854 9.7373 12.793C9.7373 13.0127 9.79883 13.25 9.92188 13.3643C9.9834 13.417 9.97461 13.4521 9.89551 13.5137C9.76367 13.6455 9.67578 13.874 9.67578 14.1113C9.67578 14.5947 10.0361 14.9639 10.502 14.9639H12.1279C12.541 14.9639 12.7871 15.166 12.7871 15.4912C12.7871 16.1064 12.0225 17.2402 12.0225 18.0576C12.0225 18.4971 12.3125 18.7695 12.6904 18.7695C13.0332 18.7695 13.2002 18.5498 13.376 18.1807C14.0791 16.8447 14.9844 15.7637 15.6787 14.8232C16.2764 14.0322 16.5752 13.3467 16.584 12.2656ZM18.8516 12.2305C18.8516 10.8242 18.0078 9.68164 16.8477 9.68164H16.0391C16.8652 10.2969 17.2432 11.2109 17.2168 12.2744C17.208 13.417 16.7861 14.2344 16.373 14.7793H17.0059C18.0605 14.7793 18.8516 13.6631 18.8516 12.2305Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_434">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default HandThumbsdownCircleIcon;
