import { createSlice } from '@reduxjs/toolkit';
import { httpRequest } from '@app/store/middleware/rest';

export type AuthInitialState = {
  isLoginIn: boolean;
  error: string | null;
  accessToken: string | null;
};

const initialState: AuthInitialState = {
  isLoginIn: false,
  error: null,
  accessToken: localStorage.getItem('REACT_TOKEN_AUTH_KEY'),
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginResponse: (state, { payload }) => {
      state.error = null;
      state.accessToken = payload.accessToken;
      localStorage.setItem('accessToken', payload.accessToken);
    },
    loginError: (state, { payload }) => {
      state.error = payload;
    },
    logout: (state) => {
      state.error = null;
      state.accessToken = null;
      localStorage.removeItem('accessToken');
    },
    toggleLoginLoader: (state, { payload }) => {
      state.isLoginIn = payload;
    },
  },
});
export const { loginResponse, loginError, toggleLoginLoader, logout } = slice.actions;
export default slice.reducer;

export const login = (data: { username: string; password: string }) =>
  httpRequest({
    url: `login`,
    method: 'POST',
    data,
    onLoading: toggleLoginLoader.type,
    onSuccess: loginResponse.type,
    onError: loginError.type,
  });
