module.exports = {
  popup_hints: {
    delete_preset_group_button: 'Delete condition',
    templates_list_count: 'Number of templates in group',
    to_record_title: 'View record',
    download_record: 'Download record',
    settings_template_icon: 'Set up template',
    delete_template_icon: 'Delete template',
    copy_template_icon: 'Copy template',
  },
  system: {
    write_template_name: 'Enter template name',
    calls: 'calls',
    additional_name_to_copy: 'copy 1',
  },
  filter: {
    title: 'Filter selection conditions',
      buttons: {
        save_as_preset: 'Save as new preset',
        save: 'Save',
        delete: 'Delete',
        cancel: 'Cancel',
      }
  },
  preset_remove_modal: {
    remove_label: 'Delete preset',
    remove_description: 'Confirm deletion of a preset',
  },
  preset_settings_table: {
    first_label: 'Duration',
    last_label: 'Actions',
  },
  page_header_group: 'Group',
  page_header_group_placeholder: 'Выбрать проект',
  cant_create_group: 'Cannot create a group without a name',
  template_name: 'Template name',
  presets_templates: 'Preset templates',
  create_preset_template: 'Create a preset template',
  group_name: 'Group name',
  remove_presets_group_title: 'Warning',
  new_group: 'New group',
  confirm_remove_presets_group: 'Do you really want to delete this group?',
  all_preset_templates_table: {
    name: 'NAME',
    time: 'Time interval',
    desc: 'Description',
    actions: 'Actions',
  },
  copy: 'copy 1',
  from: 'from',
  to: 'to',
  last_days: 'last 24 hours',
  warning: 'Warning',
  confirm_group_delete: 'Do you really want to delete this template?',
};
