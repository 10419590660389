import React, { useCallback, useEffect, useState } from 'react';
import ReactWordcloud, { Word } from 'react-wordcloud';

import { Box, Skeleton } from '@app/components/ui';

import { useGetWordsCloudQuery } from '@app/store/api/get-words-cloud.api';
import { useOutletContext } from 'react-router';

import { Empty } from '@ui';

import { WordCloudContext } from '../types/types';

export const WordsCloudContent = () => {
  const { data: dataForCloud, changeWordForSearch } = useOutletContext<WordCloudContext>();
  const [cloudData, changeCloudData] = useState(dataForCloud);
  const { data, isFetching } = useGetWordsCloudQuery(cloudData, {
    skip: cloudData === undefined,
  });
  useEffect(() => {
    changeCloudData(dataForCloud);
  }, [dataForCloud]);
  const SimpleWordCloud = useCallback(() => {
    if (!data) return;
    return data?.stat?.map((item) => ({ text: item.word, value: item.nentry })) as Word[];
  }, [data]);
  if (isFetching) return <Skeleton height={550} />;
  return (
    <Box className="w-full h-full flex justify-center">
      <div>
        {SimpleWordCloud()?.length ? (
          <ReactWordcloud
            options={{
              fontSizes: [13, 49],
              rotations: 0,
              deterministic: true,
              fontFamily: 'SF Pro Display',
              randomSeed: '0.6',
              padding: 2.5,
              tooltipOptions: { theme: 'tomato' },
              spiral: 'archimedean',
              scale: 'sqrt',
            }}
            size={[600, 630]}
            words={SimpleWordCloud() || []}
            callbacks={{
              onWordClick: (word) => {
                changeWordForSearch((prevent) => [...(prevent as string[]), word.text]);
                changeCloudData((prev) => ({
                  ...prev,
                  data: {
                    ...prev.data,
                    words: prev.data?.words?.length
                      ? [...(prev.data?.words as string[]), word.text]
                      : [word.text],
                  },
                }));
              },
            }}
          />
        ) : (
          <div className={'w-[600px] flex items-center justify-center h-[400px]'}>
            <Empty />
          </div>
        )}
      </div>
    </Box>
  );
};
