import React, { FC } from 'react';
import { useOutletContext } from 'react-router-dom';

import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';

import ReportsTableBlock from './blocks/reports-table.block';

const ProjectReportsPage: FC<{ currentTemplate: 'chat' | 'voice' }> = ({ currentTemplate }) => {
  const { project } = useOutletContext<AnalyticPageOutlet>();

  return (
    <div>
      <ReportsTableBlock
        currentTemplate={currentTemplate}
        projectAp={project}
        currentProjectId={project?.project_id}
      />
    </div>
  );
};

export default ProjectReportsPage;
