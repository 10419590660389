import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import styles from '@app/pages/analytics/project-settings/analytics-main-settings/styles.module.scss';
import Icon from '@app/components/ui/icons';
import { Box, Button, CircleButton, EasyModal, Input, Loading, Select } from '@ui';
import {
  AllStatusProject,
  CreateProjectAnalyticsParams,
  GroupSharedStatus,
  ProjectItem,
} from '@app/interfaces/analytics';
import { Controller, useForm } from 'react-hook-form';
import useTranslation from '@app/hooks/use-translation';
import { AutoFocusHook } from '@app/utils/helpers';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { useNavigate } from 'react-router-dom';

import {
  useGetChatGroupQuery,
  useCreateSingleChatProjectMutation,
  useGetSingleChatProjectQuery,
  useEditSingleChatAnalyticProjectMutation,
  useEditSingleChatAnalyticProjectOwnerMutation,
} from '@app/store/api/chat-api/chat-analytic.api';
import { OptionItem } from '@app/components/ui/select/select.type';
import { useGetExtAndUsrModelsQuery } from '@app/store/api/asr_model.api';
type MainSettingsPropsType = {
  newProjFlag?: boolean;
  changeStatusFunctions?: {
    REMOVING: () => void;
  };
};
const MainChatSettings: FC<MainSettingsPropsType> = (props) => {
  const { newProjFlag = false, changeStatusFunctions } = props;
  const { t } = useTranslation('pages.mainSettingsAp');
  const { t: ts } = useTranslation('common.sharedStatus');
  const { t: form } = useTranslation('common.forms');
  const { t: tf } = useTranslation('pages.metricTemplates');
  const navigate = useNavigate();
  const { id } = useParams();
  const inputFocusRef = AutoFocusHook();
  const {
    data: projectData,
    isFetching,
    isLoading,
  } = useGetSingleChatProjectQuery(
    {
      id: String(id || 0),
    },
    { skip: typeof id === undefined, refetchOnMountOrArgChange: true },
  );
  const isLoadingFormData = isFetching || isLoading;
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { data: analyticGroups, isLoading: analyticsGroupsLoading } = useGetChatGroupQuery();
  const { data: models, isLoading: modelsLoading } = useGetExtAndUsrModelsQuery();
  const [createAnalyticsProject, { isLoading: createLoading }] =
    useCreateSingleChatProjectMutation();
  const [editAnalyticsProject, { isLoading: editLoading }] =
    useEditSingleChatAnalyticProjectMutation();
  const [editProjectOwner] = useEditSingleChatAnalyticProjectOwnerMutation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    clearErrors,
  } = useForm<Omit<CreateProjectAnalyticsParams, 'id'>>();
  const copyToClipboard = useCallback(() => {
    event?.preventDefault();
    const textToCopy = projectData?.project_id;
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy);
    }
  }, [projectData?.project_id]);

  useEffect(() => {
    projectData &&
      reset({
        name: projectData?.name,
        project_group_id: projectData?.project_group_id,
        shared: projectData?.shared,
        timezone: projectData?.timezone,
        description: ' ',
        asr_model_id: projectData?.asr_model_id,
      });
  }, [projectData, reset]);

  useEffect(() => {
    if (!newProjFlag) return;
    if (!models) return;
    setValue('asr_model_id', models?.find((item) => item.default)?.model_id || '');
  }, [models, newProjFlag, setValue]);

  function handleBeOwnerClick() {
    editProjectOwner({ params: { id: id || '' } });
  }
  function onSubmit(data: CreateProjectAnalyticsParams) {
    !newProjFlag
      ? projectData?.project_id &&
        editAnalyticsProject({ body: data, params: { id: projectData?.project_id } }).then(() => {
          navigate(`/${navigationRoutes.chatAnalytic}`);
        })
      : createAnalyticsProject({ ...data, description: data.name }).then((data) => {
          const { data: projectData } = data as { data: ProjectItem };
          if (projectData?.project_id) {
            navigate(`/${navigationRoutes.chatAnalytic}/${projectData.project_id}`);
          }
        });
  }
  const disableStatusForSubmit = () => {
    if (createLoading) return true;
    if (editLoading) return true;
    if (!newProjFlag) {
      if (!projectData?.owner && projectData?.shared === GroupSharedStatus.VIEW) return true;
      if (projectData?.status === AllStatusProject.PAUSE) {
        return false;
      }
      return projectData?.status !== AllStatusProject.CREATED;
    }
  };
  const disableStatusForButton = () => {
    if (!newProjFlag) {
      return projectData?.status !== 'PAUSE';
    } else return false;
  };
  const deleteProjectClick = () => {
    if (newProjFlag) {
      navigate(`/${navigationRoutes.chatAnalytic}`);
    } else {
      setDeleteModal(true);
    }
  };
  function getSelectOptions() {
    const newArr: OptionItem[] = [];
    const values = Object.values(GroupSharedStatus).filter((i) => isNaN(Number(i)));
    for (let i = 0; i < values.length; i++) {
      newArr.push({
        value: values[i],
        title: ts(values[i]),
      });
    }
    return newArr;
  }
  const UTCOptions = useMemo(
    () => [
      { value: 'default', title: t('system.timezone_default') },
      ...new Array(24).fill('_').map<OptionItem>((_, index) => {
        const currentIndex = ++index;
        const currentValue = Number(currentIndex > 12 ? currentIndex - 25 : currentIndex);
        return {
          value: Number(currentValue),
          title: `UTC${currentValue > 0 ? '+' : ''}${currentValue}`,
        };
      }),
    ],
    [t],
  );
  function onSuccessModelChange() {
    setModalVisible(false);
  }
  function deleteModalApply() {
    changeStatusFunctions && changeStatusFunctions.REMOVING();
  }

  if (analyticsGroupsLoading || modelsLoading || isLoadingFormData) return <Loading />;
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ minHeight: '594px', display: 'flex', flexDirection: 'column' }}>
          <div className={classNames(styles.main)}>
            <div className={classNames(styles.folderIcon)}>
              <Icon size={70} className="text-speech_analitics" name={'FolderOpenIcon'} />
            </div>
            <div className={classNames(styles.content)}>
              <h4 className="mb-[27px] text-0color text-[18px] leading-[21px]">
                <b>{t('main_settings_title')}</b>
              </h4>
              <div className="flex items-center mb-[23px]  w-[550px]">
                <div className="flex items-center gap-[61px] w-full justify-between">
                  <span className="w-full">{t('form_fields.proj_name')}</span>
                  <div className="min-w-[346px]">
                    <Input
                      {...inputFocusRef}
                      {...register('name', {
                        required: { value: true, message: form('required') },
                      })}
                      disabled={
                        !projectData?.owner && projectData?.shared === GroupSharedStatus.VIEW
                      }
                      error={errors.name?.message}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', marginBottom: '23px' }}
                className="justify-between w-[550px]"
              >
                <label className={classNames(styles.first, 'text-gray-700')} htmlFor="group">
                  {t('form_fields.group_proj')}
                </label>
                <div className="w-[346px]">
                  <Controller
                    render={() => (
                      <Select
                        disable={
                          !projectData?.owner && projectData?.shared === GroupSharedStatus.VIEW
                        }
                        error={errors.project_group_id?.message}
                        dropHeight={127}
                        dropWidth={342}
                        options={
                          analyticGroups?.map((e) => {
                            return { title: e.name, value: e.project_group_id };
                          }) || []
                        }
                        defaultValue={watch('project_group_id')}
                        onChange={(value) => {
                          setValue('project_group_id', String(value));
                          clearErrors('project_group_id');
                        }}
                        placeholder={newProjFlag ? t('system.placeholder') + '...' : undefined}
                      />
                    )}
                    name={'project_group_id'}
                    control={control}
                    rules={{ validate: (value) => !!value || form('required') }}
                  />
                </div>
              </div>

              <div
                style={{ display: 'flex', alignItems: 'center' }}
                className="justify-between w-[550px]"
              >
                <label className={classNames(styles.four, 'text-gray-700')} htmlFor="group">
                  {t('form_fields.proj_shared')}
                </label>
                <div className="w-[346px]">
                  <Controller
                    render={() => (
                      <Select
                        disable={!newProjFlag ? !projectData?.owner : false}
                        dropHeight={127}
                        dropWidth={342}
                        error={errors.shared?.message}
                        options={getSelectOptions()}
                        defaultValue={watch('shared')}
                        onChange={(item) => {
                          setValue('shared', String(item) as GroupSharedStatus);
                          clearErrors('shared');
                        }}
                        placeholder={newProjFlag ? t('system.placeholder') + '...' : undefined}
                      />
                    )}
                    name={'shared'}
                    control={control}
                    rules={{ validate: (value) => !!value || form('required') }}
                  />
                </div>
              </div>
              <div className="flex items-center mt-[23px] gap-[31px] justify-between w-[550px]">
                <label className={classNames(styles.third, 'text-gray-700')} htmlFor="model">
                  {t('form_fields.timezone')}
                </label>
                <div className="w-[346px] flex justify-end">
                  <Controller
                    render={() => (
                      <Select
                        dropHeight={127}
                        dropWidth={342}
                        defaultValue={Number(watch('timezone')) || 'default'}
                        options={UTCOptions}
                        onChange={(item) => {
                          setValue('timezone', item === 'default' ? undefined : Number(item));
                          clearErrors('timezone');
                        }}
                      />
                    )}
                    name={'timezone'}
                    control={control}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between w-[550px] gap-[115px] mt-[23px]">
                <span className="text-gray-700 text-[16px]">{tf('project_id')}</span>
                <div className="flex gap-2">
                  <p>{projectData?.project_id}</p>
                  <CircleButton
                    onClick={copyToClipboard}
                    icon="CopyIcon"
                    className="text-action mb-[5px]"
                    hintTitle={tf('copy_hint')}
                    size={16}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(styles.footer)}>
            <div className="flex items-center justify-between h-full py-6 px-6">
              <div className="flex gap-[20px]">
                <Button
                  disabled={disableStatusForSubmit()}
                  type="submit"
                  label={newProjFlag ? t('buttons.create_project') : t('buttons.save_project')}
                />
              </div>
              <div className="flex items-center gap-[10px]">
                {projectData?.shared === 'EDIT' && !projectData?.owner && (
                  <Button
                    onClick={handleBeOwnerClick}
                    fill="outlined"
                    label={t('buttons.be_owner')}
                  />
                )}
                <Button
                  disabled={disableStatusForButton()}
                  variant="danger"
                  fill="outlined"
                  icon="TrashIcon"
                  onClick={() => deleteProjectClick()}
                  label={t('buttons.delete_project')}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      {/*modals  */}
      {modalVisible && (
        <EasyModal
          variant={'medium484'}
          label={<div className="m-[0px_auto] text-center">{t('recog_model_modal_title')}</div>}
          onSuccess={() => onSuccessModelChange()}
          show={modalVisible}
          isAutoClosable={false}
          onClose={() => {
            setModalVisible(false);
            setValue('asr_model_id', projectData?.asr_model_id || '');
          }}
        >
          <div className="w-[340px] m-[0px_auto] pt-[10px] pb-[150px]">
            <Select
              dropHeight={100}
              dropWidth={336}
              options={[
                ...(models?.map((item) => ({ title: item.name, value: item.model_id })) || []),
              ]}
              onChange={(value) => setValue('asr_model_id', String(value))}
              defaultValue={watch('asr_model_id')}
            />
          </div>
        </EasyModal>
      )}
      <EasyModal
        variant={'removal'}
        show={deleteModal}
        onClose={setDeleteModal.bind(null, false)}
        label={`${t('delete_proj')} "${projectData?.name}"`}
        onRemove={deleteModalApply}
      >
        <p className="px-[15px] flex justify-center">{t('delete_proj_confirm')}</p>
      </EasyModal>
      {/*modals  */}
    </Box>
  );
};

export default MainChatSettings;
