import { AUTH_KEY } from '@app/utils/constants';
type StorageDataType = {
  detail: string;
  access_token: string;
  refresh_token: string;
  token_type: string;
};
const authStorage = {
  signIn: async (tokens: StorageDataType) => {
    if (tokens) {
      localStorage.setItem(AUTH_KEY, JSON.stringify(tokens));
    }
  },
  logOut: async () => {
    localStorage.removeItem(AUTH_KEY);
  },
};

export default authStorage;
