import axios from 'axios';
import { AuthResponse } from '@app/interfaces/auth';
import { createAuthProvider } from 'react-token-auth';

export const { useAuth, login, logout, getSessionState } = createAuthProvider<AuthResponse>({
  getAccessToken: (session) => session.access_token,
  storage: localStorage,
  onUpdateToken: (token) =>
    axios.get('/api/auth/refresh', {
      headers: {
        Authorization: `Bearer${token.refresh_token}`,
      },
    }),
});
