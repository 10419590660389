import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ChecklistIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_505)">
          <path
            d="M8.29492 12.459C10.4043 12.459 12.1621 10.7012 12.1621 8.5918C12.1621 6.46484 10.4043 4.70703 8.29492 4.70703C6.16797 4.70703 4.41895 6.46484 4.41895 8.5918C4.41895 10.7012 6.17676 12.459 8.29492 12.459ZM7.8291 10.666C7.63574 10.6748 7.49512 10.5693 7.36328 10.4111L6.37891 9.2334C6.28223 9.11914 6.24707 9.01367 6.24707 8.86426C6.24707 8.5918 6.45801 8.38086 6.72168 8.38086C6.89746 8.38086 7.02051 8.45117 7.13477 8.60938L7.81152 9.45312L9.37598 6.93945C9.49023 6.76367 9.63965 6.67578 9.81543 6.67578C10.0791 6.67578 10.2988 6.87793 10.2988 7.12402C10.2988 7.24707 10.2725 7.36133 10.1846 7.49316L8.3125 10.4023C8.19824 10.5693 8.03125 10.6748 7.8291 10.666ZM14.9482 9.36523H23.79C24.2207 9.36523 24.5723 9.02246 24.5723 8.5918C24.5723 8.16113 24.2207 7.81836 23.79 7.81836H14.9482C14.5176 7.81836 14.1836 8.16113 14.1836 8.5918C14.1836 9.02246 14.5176 9.36523 14.9482 9.36523ZM8.29492 22.6191C10.4043 22.6191 12.1621 20.8613 12.1621 18.7432C12.1621 16.6162 10.4043 14.8672 8.29492 14.8672C6.16797 14.8672 4.41895 16.625 4.41895 18.7432C4.41895 20.8613 6.17676 22.6191 8.29492 22.6191ZM8.29492 21.125C6.99414 21.125 5.91309 20.0352 5.91309 18.7432C5.91309 17.4512 7.00293 16.3525 8.29492 16.3525C9.57812 16.3525 10.6768 17.4512 10.6768 18.7432C10.6768 20.0352 9.57812 21.125 8.29492 21.125ZM14.9482 19.5342H23.79C24.2207 19.5342 24.5723 19.1914 24.5723 18.7607C24.5723 18.3301 24.2207 17.9873 23.79 17.9873H14.9482C14.5176 17.9873 14.1836 18.3301 14.1836 18.7607C14.1836 19.1914 14.5176 19.5342 14.9482 19.5342Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_505">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ChecklistIcon;
