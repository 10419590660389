import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { BreadcrumbsItem } from '@app/layouts/layout';
import RequiredAuth from '@app/routes/required-auth.route';
import { DictionariesPage } from '@app/pages/dictionaries-page/dictionaries.page';
import { CreateDictionaries } from '@app/pages/dictionaries-page/blocks/create-dictionaries';

export const dictionariesRoute = (
  <Route
    path={navigationRoutes.dictionaries}
    handle={{
      crumb: () => <BreadcrumbsItem path={navigationRoutes.dictionaries} crumb="dictionaries" />,
    }}
  >
    <Route
      index
      element={
        <RequiredAuth>
          <DictionariesPage />
        </RequiredAuth>
      }
    />
    <Route
      path={navigationRoutes.createDictionaries}
      element={
        <RequiredAuth>
          <CreateDictionaries edit={false} copy={false} newDictionary={true} />
        </RequiredAuth>
      }
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={navigationRoutes.dictionaries} crumb="dictionariesCreate" />
        ),
      }}
    />
    <Route
      path={`:id/copy`}
      element={
        <RequiredAuth>
          <CreateDictionaries edit={false} copy={true} newDictionary={false} />
        </RequiredAuth>
      }
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={navigationRoutes.dictionaries} crumb="dictionariesCreate" />
        ),
      }}
    />
    <Route
      path={`:id/edit`}
      element={
        <RequiredAuth>
          <CreateDictionaries edit={true} copy={false} newDictionary={false} />
        </RequiredAuth>
      }
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={navigationRoutes.dictionaries} crumb="dictionariesCreate" />
        ),
      }}
    />
  </Route>
);
