import React, { FC, useCallback, useEffect, useState } from 'react';
import RubberTable from '@app/components/rubber-table';
import { FormProvider, useForm } from 'react-hook-form';
import useTranslation from '@app/hooks/use-translation';
import { useGetAliasesListQuery } from '@app/store/api/alias-settings-page.api';
import { AliasSettingsObj } from '@app/interfaces/settings.type';
import { ColsWithoutTable } from '@app/components/rubber-table/blocks/cols-without-table';
import Icon from '@app/components/ui/icons';
import { LetterA } from '@icons';
import { TableItem } from '@app/components/rubber-table/table.types';

import { InputField } from './tabel-input-field';

export type InputFieldType = {
  name: string;
  type_meta: string;
  type_value: string;
  userInput: string;
};

export type RecordMetaType = {
  alias_id: string;
  name: string;
  type_meta: 'system' | 'base' | 'record' | 'channel';
  type_value: number | string;
};
type FormFieldsType = {
  recordMeta: RecordMetaType[];
};

type MetadataModalBlockPropsType = {
  show: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  onInputFieldsChange(InputFieldType): void;
  onValidationErrors: (hasErrors: boolean) => void;
};

const iconData = {
  num: 'NumberIcon',
  str: 'TextIcon',
};

export const MetadataModalBlock: FC<MetadataModalBlockPropsType> = React.memo((props) => {
  const { t } = useTranslation('pages.settings');
  const { onInputFieldsChange, onValidationErrors } = props;
  const { data } = useGetAliasesListQuery();
  const [inputField, setInputField] = useState<InputFieldType[]>([]);
  const [errors, setErrors] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [filteredAliases, setFilteredAliases] = useState<AliasSettingsObj[]>([]);
  const [activeInput, setActiveInput] = useState(null);
  const methods = useForm<FormFieldsType>({
    defaultValues: {
      recordMeta: [{ type_value: '' }],
    },
    mode: 'onBlur',
  });
  const typesTranslateData = {
    num: t('alias.values_translate.num'),
    str: t('alias.values_translate.str'),
    record: t('alias.values_translate.record'),
  };
  const validateInput = (value, typeValue) => {
    if (typeValue === 'str') {
      return /^[a-zA-Zа-яА-ЯёЁ.0-9\-_: @]{0,64}$/.test(value);
    } else if (typeValue === 'num') {
      return value === '' || /^[0-9. ]+$/.test(value);
    }
    return true;
  };

  const getFilteredAliases = useCallback(() => {
    if (!data || !data.aliases) return [];
    return data.aliases.filter((alias) => alias.type_meta === 'record');
  }, [data]);
  useEffect(() => {
    setFilteredAliases(getFilteredAliases());
  }, [getFilteredAliases]);
  const handleInputChange = useCallback(
    (aliasId, typeMeta, typeValue, value) => {
      const alias = filteredAliases.find((alias) => alias.alias_id === aliasId);
      if (!alias) return;
      setActiveInput(aliasId);
      const name = alias.meta_name;
      const isValid = validateInput(value, typeValue);
      const newErrors = { ...errors, [aliasId]: isValid ? '' : t('invalid_format') };
      const newInputValues = { ...inputValues, [aliasId]: value };
      const newInputField = inputField.filter((field) => field.name !== name);

      if (value !== '') {
        newInputField.push({ name, type_meta: typeMeta, type_value: typeValue, userInput: value });
      }

      setErrors(newErrors);
      setInputValues(newInputValues);
      setInputField(newInputField);

      onInputFieldsChange(newInputField);
      onValidationErrors(Object.values(newErrors).some((error) => error !== ''));
    },
    [filteredAliases, errors, t, inputValues, inputField, onInputFieldsChange, onValidationErrors],
  );

  const getData = (aliases) => {
    return aliases.map((item) => ({
      ...item,
      type_value: (
        <div className="flex gap-[7px] h-full">
          <Icon
            className="mb-[-6px] text-3color"
            size={17}
            name={iconData[item.type_value] || 'HelpCircleIcon'}
          />
          {typesTranslateData[item.type_value] || item.type_value}
        </div>
      ),
      name: (
        <div className="flex items-center gap-[10px]">
          <LetterA className="text-[#1B97F1]" size={14} />
          <div className="font-[700]">{item.name}</div>
        </div>
      ),
      metadata_value: (
        <>
          <InputField
            key={item.alias_id}
            aliasId={item.alias_id}
            typeMeta={item.type_meta}
            activeInput={activeInput}
            typeValue={item.type_value}
            initialValue={inputValues[item.alias_id]}
            error={errors[item.alias_id]}
            onChange={handleInputChange}
          />
        </>
      ),
    })) as unknown as TableItem[];
  };
  const columns = [
    {
      title: t('manual_upload.alias'),
      index: 'name',
    },
    {
      title: t('manual_upload.type'),
      index: 'type_value',
    },
    {
      title: t('manual_upload.params'),
      index: 'metadata_value',
    },
  ];
  return (
    <div>
      <FormProvider {...methods}>
        <div className="w-full py-[30px] flex flex-col gap-[10px]">
          <div className="pb-[15px]">
            <ColsWithoutTable columns={columns} />
            <RubberTable dataSource={getData(filteredAliases)} columns={columns} withoutCols />
          </div>
        </div>
      </FormProvider>
    </div>
  );
});

MetadataModalBlock.displayName = 'MetadataModalBlock';
