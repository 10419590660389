import React, { FC } from 'react';

import { IconType } from '../icon.type';

const TextViewfinderIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_312)">
          <path
            d="M6.15137 10.8154C6.71387 10.8154 7.0127 10.5078 7.0127 9.94531V7.95898C7.0127 7.10645 7.46094 6.68457 8.27832 6.68457H10.3086C10.8711 6.68457 11.1787 6.37695 11.1787 5.82324C11.1787 5.26953 10.8711 4.96191 10.3086 4.96191H8.17285C6.27441 4.96191 5.29004 5.9375 5.29004 7.80957V9.94531C5.29004 10.5078 5.59766 10.8154 6.15137 10.8154ZM21.8398 10.8154C22.4023 10.8154 22.7012 10.5078 22.7012 9.94531V7.80957C22.7012 5.9375 21.7168 4.96191 19.8184 4.96191H17.6826C17.1201 4.96191 16.8125 5.26953 16.8125 5.82324C16.8125 6.37695 17.1201 6.68457 17.6826 6.68457H19.7129C20.5215 6.68457 20.9785 7.10645 20.9785 7.95898V9.94531C20.9785 10.5078 21.2861 10.8154 21.8398 10.8154ZM9.37695 10.3496C9.37695 10.6924 9.6582 10.9736 10.0098 10.9736H17.9814C18.333 10.9736 18.6143 10.6924 18.6143 10.3496C18.6143 9.99805 18.333 9.7168 17.9814 9.7168H10.0098C9.6582 9.7168 9.37695 9.99805 9.37695 10.3496ZM9.37695 13.6543C9.37695 13.9971 9.6582 14.2783 10.0098 14.2783H17.9814C18.333 14.2783 18.6143 13.9971 18.6143 13.6543C18.6143 13.3027 18.333 13.0215 17.9814 13.0215H10.0098C9.6582 13.0215 9.37695 13.3027 9.37695 13.6543ZM9.37695 16.959C9.37695 17.3018 9.6582 17.583 10.0098 17.583H15.248C15.5908 17.583 15.8721 17.3018 15.8721 16.959C15.8721 16.6074 15.5908 16.3262 15.248 16.3262H10.0098C9.6582 16.3262 9.37695 16.6074 9.37695 16.959ZM8.17285 22.373H10.3086C10.8711 22.373 11.1787 22.0654 11.1787 21.5117C11.1787 20.958 10.8711 20.6504 10.3086 20.6504H8.27832C7.46094 20.6504 7.0127 20.2285 7.0127 19.3672V17.3809C7.0127 16.8184 6.70508 16.5107 6.15137 16.5107C5.58887 16.5107 5.29004 16.8184 5.29004 17.3809V19.5166C5.29004 21.3975 6.27441 22.373 8.17285 22.373ZM17.6826 22.373H19.8184C21.7168 22.373 22.7012 21.3975 22.7012 19.5166V17.3809C22.7012 16.8184 22.3936 16.5107 21.8398 16.5107C21.2773 16.5107 20.9785 16.8271 20.9785 17.3809V19.3672C20.9785 20.2285 20.5215 20.6504 19.7129 20.6504H17.6826C17.1201 20.6504 16.8125 20.958 16.8125 21.5117C16.8125 22.0654 17.1201 22.373 17.6826 22.373Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_312">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default TextViewfinderIcon;
