import axios, { AxiosResponse } from 'axios';

import { AuthResponse } from '@app/interfaces/auth';

import { toast } from 'react-toastify';

import { getAuthRefresh } from './auth.api';

type StorageKeys = {
  detail: string;
  access_token: string;
  refresh_token: string;
  token_type: string;
};
export const getSessionState = (): StorageKeys | null => {
  const session = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
  if (session) {
    return JSON.parse(session) as StorageKeys;
  } else {
    return null;
  }
};

export const logoutSession = () => {
  if (process.env.REACT_APP_THEME === 'rtk' && process.env.REACT_APP_RTK_AUTH_URL) {
    localStorage.clear();
    localStorage.setItem('LAST_URL', window.location.pathname);
    const path = window.location.pathname.replace(/^\//, '');
    window.location.replace(process.env.REACT_APP_RTK_AUTH_URL + `?context=${path}`);
  } else {
    localStorage.clear();
    localStorage.setItem('LAST_URL', window.location.pathname);
    window.location.href = window.location.origin + '/authorization';
  }
  controller.abort();
};
export const loginSession = (data: AuthResponse) => {
  localStorage.setItem('REACT_TOKEN_AUTH_KEY', JSON.stringify(data));
  window.location.href = window.location.pathname;
};

// eslint-disable-next-line compat/compat
const controller = new AbortController();

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
  },
  signal: controller.signal,
});

apiInstance.interceptors.request.use(
  (config) => {
    if (config.headers && !Object.prototype.hasOwnProperty.call(config.headers, 'Authorization')) {
      if (getSessionState()?.access_token)
        config.headers.Authorization = 'Bearer ' + getSessionState()?.access_token;
    }
    return config;
  },
  (error) => {
    throw error;
  },
);
let authTokenRequest;
function getAuthToken() {
  if (!authTokenRequest) {
    authTokenRequest = getAuthRefresh(
      JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY') as string).refresh_token,
    );
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }

  return authTokenRequest;
}

function resetAuthTokenRequest() {
  authTokenRequest = null;
}
const ignoreStatusCodeList = [400, 404];
apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    !ignoreStatusCodeList.includes(error.response.status) && getErrorToast(error);
    if (error.response.status === 401) {
      getAuthToken()
        .then((response: AxiosResponse<AuthResponse>) => {
          loginSession(response.data);
        })
        .catch(() => {
          logoutSession();
        });
    }
    throw error;
  },
);

const getErrorToast = (error) => {
  return toast.error(error?.response?.data.error || error.message);
};

export default apiInstance;
