import React, { FC, ReactNode } from 'react';

import {
  AllChanelsArrType,
  MetricListArrType,
  MetricObjType,
  MetricTypesArrType,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import { EasyModal } from '@ui';
import { ClassifierMetricForm } from '@app/pages/analytics/metric/analytics-metric-tab/blocks/modals/classifier-metric-modal/classifier-metric-form';
import { useGetClassifierModelsListQuery } from '@app/store/api/metrics.api';

type HandleMetricModalBlockProps = {
  show: boolean;
  onClose: () => void;
  label: ReactNode;
  metric: MetricTypesArrType;
  metricSettings: MetricObjType;
  editModal: boolean;
  metricList?: MetricListArrType[];
  allChannels?: AllChanelsArrType[];
};

const ClassifierModalBlock: FC<HandleMetricModalBlockProps> = (props) => {
  const { show, onClose, label, allChannels, metric, editModal, metricSettings } = props;
  const { data: classifierModels, isLoading } = useGetClassifierModelsListQuery();
  if (isLoading) return <></>;
  return (
    <EasyModal
      show={show}
      label={label}
      // customLayer={90}
      isAutoClosable={false}
      withoutFooter
      onClose={onClose}
      variant="mediumW650"
    >
      <div className="h-full w-full  px-[20px]">
        <ClassifierMetricForm
          allChannels={allChannels}
          editModal={editModal}
          metricSettings={metricSettings}
          classifierModels={classifierModels}
          metricType={metric}
          onClose={onClose}
        />
      </div>
    </EasyModal>
  );
};

export default ClassifierModalBlock;
