import React, { FC } from 'react';

import { IconType } from '../icon.type';

const YensignCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_614_1983)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM14 18.0312C14.3955 18.0312 14.6592 17.7588 14.6592 17.3369V16.2559H16.2588C16.5049 16.2559 16.6719 16.0801 16.6719 15.8428C16.6719 15.6055 16.5049 15.4209 16.2588 15.4209H14.6592V14.7705H16.2588C16.5049 14.7705 16.6719 14.5947 16.6719 14.3486C16.6719 14.1113 16.5049 13.9355 16.2588 13.9355H15.0635L17.1113 10.6309C17.1992 10.4814 17.2432 10.3496 17.2432 10.1914C17.2432 9.84863 16.9619 9.56738 16.5752 9.56738C16.3203 9.56738 16.127 9.68164 15.9863 9.94531L14.0088 13.3467L12.0225 9.93652C11.873 9.66406 11.6797 9.56738 11.416 9.56738C11.0381 9.56738 10.7568 9.83984 10.7568 10.1914C10.7568 10.3496 10.792 10.4814 10.8799 10.6221L12.9277 13.9355H11.7676C11.5127 13.9355 11.3545 14.1113 11.3545 14.3486C11.3545 14.5947 11.5215 14.7705 11.7676 14.7705H13.3408V15.4209H11.7676C11.5127 15.4209 11.3545 15.6055 11.3545 15.8428C11.3545 16.0801 11.5215 16.2559 11.7676 16.2559H13.3408V17.3369C13.3408 17.7588 13.5957 18.0312 14 18.0312Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_614_1983">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default YensignCircleIcon;
