import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  LiveReport,
  LiveReportSettingsRequestData,
  LiveReportsRequestData,
} from '@app/interfaces/report.type';

import { Filter, Range } from '@app/components/ui/easy-filter/types';

import { RecordLiveDataResponse, RecordRequestData } from '@app/interfaces/records';

import { baseQuery } from '../../baseQuery';

export const chatLiveReportsApi = createApi({
  reducerPath: 'chatLiveReportsApi',
  tagTypes: ['liveReports', 'singleLiveReport', 'liveReportRecords'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getAllChatLiveReportsList: build.query<
      { live_reports: LiveReport[]; total: number },
      LiveReportsRequestData
    >({
      query: (body) => ({
        url: serverRoutes.chat_live_reports,
        method: 'POST',
        body,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.live_reports.map(({ live_report_id }) => ({
                type: 'liveReports' as const,
                live_report_id,
              })),
              'liveReports',
            ]
          : ['liveReports'],
    }),
    getChatLiveReportSettings: build.query<LiveReport, LiveReportSettingsRequestData>({
      query: (params) => ({
        url: serverRoutes.chat_live_report,
        params,
      }),
      providesTags: ['singleLiveReport'],
    }),
    deleteChatLiveReport: build.mutation({
      query: (params: { id: string }) => ({
        url: serverRoutes.chat_live_report,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['liveReports'],
    }),
    editChatLiveReport: build.mutation<
      LiveReport,
      { params: { id: string }; body: { name: string; filter: Filter & Range } }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.chat_live_report,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['liveReports', 'singleLiveReport', 'liveReportRecords'],
    }),
    createChatLiveReport: build.mutation<
      LiveReport,
      { name: string; project_id: string; description: string; filter: Filter & Range }
    >({
      query: (body) => ({
        url: serverRoutes.chat_live_report,
        method: 'POST',
        body,
      }),
    }),
    copyChatLiveReport: build.mutation<
      LiveReport,
      { name: string; live_report_id: string; description?: string }
    >({
      query: (body) => ({
        url: serverRoutes.chat_live_report_clone,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['liveReports'],
    }),
    // // liveReportStatus
    // editLiveReportStatus: build.mutation({
    //   query: (body: ChangeStatusRequest) => ({
    //     url: serverRoutes.changeStatus,
    //     method: 'PUT',
    //     body,
    //   }),
    //   invalidatesTags: ['liveReports', 'singleLiveReport'],
    // }),
    getChatLiveReportRecords: build.query<
      RecordLiveDataResponse<'chats'>,
      { params: { id: string }; body: Omit<RecordRequestData, 'id'> }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.chat_live_report_records,
        method: 'POST',
        params,
        body,
      }),
      providesTags: ['liveReportRecords'],
    }),
  }),
});

export const {
  useGetAllChatLiveReportsListQuery,
  useCreateChatLiveReportMutation,
  useDeleteChatLiveReportMutation,
  useEditChatLiveReportMutation,
  useGetChatLiveReportSettingsQuery,
  useGetChatLiveReportRecordsQuery,
  useCopyChatLiveReportMutation,
} = chatLiveReportsApi;
