import React, { FC, ReactNode, useState } from 'react';
import { ChevronDownIcon } from '@app/components/ui/icons/icons-list';
import { headerBoxClassName } from '@app/components/ui/box-spoiler/box-spoiler.style';
import classNames from 'classnames';

type BoxSpoilerProps = {
  title: string;
  children: ReactNode;
  defaultStatus?: boolean;
  sideComponent?: ReactNode;
};

const BoxSpoiler: FC<BoxSpoilerProps> = (props) => {
  const { children, title, defaultStatus = false, sideComponent } = props;
  const [isOpen, changeOpen] = useState<boolean>(defaultStatus);

  const chevronClassName = classNames(
    'transition',
    !isOpen && '-rotate-90',
    'text-3color',
    'mr-[10px]',
  );

  const renderBoxBody = isOpen && <div>{children}</div>;

  function toggleOpenBox() {
    changeOpen((prevState) => !prevState);
  }

  const renderSideComponent = sideComponent ? <div>{sideComponent}</div> : <></>;

  return (
    <div className="bg-white rounded-[10px] min-h-[62px]">
      <div className={headerBoxClassName({ isOpen })} onClick={toggleOpenBox.bind(null)}>
        <div className="text-0color text-lg font-bold inline-flex items-center ">
          <ChevronDownIcon className={chevronClassName} size={12} />
          {title}
        </div>
        <div>{renderSideComponent}</div>
      </div>
      {renderBoxBody}
    </div>
  );
};

export default BoxSpoiler;
