module.exports = {
  report_task: {
    fields: {
      settings:'Report sending settings',
      subject:'Name',
      description:'Description',
      enabled:'Status',
      actions:'Actions',
      period:'Report generation period',
    },
    prev_day: 'Previous day',
    prev_week: 'Previous week',
    prev_month: 'Previous month',
    delete_modal_text: 'Are you sure you want to delete this task?',
    delete_modal_label: 'Delete task',
    create_task: 'Add a task to send the report',
    submit_form_button: 'Save',
    block_title: 'Sending reports on schedule',
    true: 'Active',
    false: 'Not active'
  },
  popup_hints: {
    to_charts: 'Go to report charts',
    to_records: 'Go to report records',
    to_record_watch: 'View record',
    download_record: 'Download audio',
    download_report: 'Download report',
    settings_icon: 'Report settings',
    delete_icon: 'Delete',
    copy_report: 'Copy report',
  },
  system:{
    empty_records: 'No records',
    copy: 'copy',
    download_as_csv: 'Download in CSV format',
    download_as_xls: 'Download in Excel'
  },
  title: 'Reports',
  choose_project: 'Select project',
  empty: 'No reports found',
  changed_status: 'Status changed',
  filter_title: 'Filter report records',
  filter_clear: 'Clear',
  filter_settings: 'Conditions for selecting records to the report',
  select: {
    all_projects: 'All analytical projects',
  },
  settings: 'Settings',
  not_found: 'Report is not found',
  button_settings: {
    save: 'Save',
    remove: 'Delete',
    cancel: 'Cancel',
  },
  status: {
    created: 'New',
    life: 'LIVE',
    updating: 'Updating',
    done: 'Done',
    archive: 'Archive',
    pause: 'Pause',
    deleting: 'Deleting',
    error: 'Error',
  },
  records_table:{
    actions: 'Actions',
    duration: 'Duration',
  },
  table: {
    report_name: 'Report name',
    analytic_project: 'Analytical project',
    status: 'Status',
    Date_created: 'Creation Date',
    Date_next_upDate: 'Next upвate Date',
    actions: 'Actions',
    download_report: 'Download report',
  },
  navigation: {
    charts: 'Graphs and diagramms',
    settings: 'Settings',
    records: 'Report records',
  },
  modal: {
    remove_report: 'Delete report',
    confirm_remove_report: 'All data and report settings will be deleted.',
  },
  pagination: {
    calls: '%{call_number} calls',
  },
};
