import React, { FC } from 'react';

import { IconType } from '../icon.type';

const BedDoubleCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_279)" filter="url(#filter0_d_610_279)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM9.81641 12.4854H10.8623V11.9404C10.8623 11.5537 11.082 11.334 11.4775 11.334H12.9717C13.3584 11.334 13.5693 11.5537 13.5693 11.9404V12.4854H14.4746V11.9404C14.4746 11.5537 14.6855 11.334 15.0986 11.334H16.5049C16.918 11.334 17.1377 11.5537 17.1377 11.9404V12.4854H18.1836V11.3428C18.1836 10.5869 17.7705 10.2002 17.0322 10.2002H10.9678C10.2295 10.2002 9.81641 10.5869 9.81641 11.3428V12.4854ZM8.83203 16.7568C8.83203 16.9678 8.97266 17.1084 9.18359 17.1084H9.42969C9.64062 17.1084 9.78125 16.9678 9.78125 16.7568V16.1768C9.8252 16.1855 9.96582 16.1855 10.0713 16.1855H17.9287C18.043 16.1855 18.1748 16.1855 18.2188 16.1768V16.7568C18.2188 16.9678 18.3594 17.1084 18.5703 17.1084H18.8164C19.0273 17.1084 19.168 16.9678 19.168 16.7568V14.3223C19.168 13.5312 18.7285 13.1006 17.9375 13.1006H10.0713C9.27148 13.1006 8.83203 13.5312 8.83203 14.3223V16.7568Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_610_279"
            x="-4"
            y="0"
            width="36"
            height="36"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_610_279" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_610_279"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_610_279">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default BedDoubleCircleIcon;
