import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';

import { FilterTableData, TableProps } from '@app/components/table/table.type';
import { Empty, TooltipWrapper } from '@ui';
import { tableWhiteDateCellClassName } from '@app/components/table/table.style';
import CellWrapper from '@app/components/table/cell-wrapper';
import { TableWrapperForColsAndData } from '@app/components/table/table-wrapper-for-cols-and-data';
import { ColumnsBuilder } from '@app/components/table/blocks/columnsBuilder';
import { ChartsLayoutType } from '@app/interfaces/dashboards.type';
import { Layout } from 'react-grid-layout';
// import { isEqual } from 'lodash';

const DEFAULT_CELL_SIZE = 180;
/* Всегда больше длинны все слоев */
const NO_SCROLL_TABLE_LIMIT = 6;
const Table: FC<TableProps> = (props) => {
  const { onFilter, allRec, hideDragMode, onLayoutChange, columns, layout, dataSource } = props;
  const [filterItem, changeFilterItem] = useState<FilterTableData>();
  const [colsLayout, changeColsLayout] = useState<ChartsLayoutType[]>([]);
  const [widthSum, changeWidthSum] = useState<number>();
  // const fullScrollRef = useRef<HTMLDivElement | null>(null);
  const [dragEnable, changeDragDisable] = useState(false);
  const [fullScrollRef, changeFullScrollRef] = useState<HTMLDivElement | null>(null);
  const [scrollHeadTableRef, changeScrollHeadTableRef] = useState<HTMLDivElement | null>(null);
  const [scrollBodyTableRef, changeScrollBodyTableRef] = useState<HTMLDivElement | null>(null);
  const [scrollWidth, changeScrollWidth] = useState<number>(scrollBodyTableRef?.scrollWidth || 0);
  const rangeRef = useRef<HTMLInputElement>(null);
  const isScrollArea = columns.length < NO_SCROLL_TABLE_LIMIT;
  // useEffect
  useEffect(() => {
    layout?.length
      ? changeColsLayout(
          columns.map((column) => ({
            w: layout.find((lay) => lay.i === column.index)?.w || 6,
            h: 1,
            i: `${column.index}`,
            y: 0,
            x:
              layout.find((lay) => lay.i === column.index)?.x ??
              Math.max(...[...(layout?.map((i) => i.x) || [])]) + 1,
            maxH: 1,
            maxW: 16,
          })),
        )
      : changeColsLayout(
          columns.map((item, index) => ({
            w: 6,
            h: 1,
            i: `${item.index}`,
            y: 0,
            x: index,
            maxH: 1,
            maxW: 16,
          })),
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout, allRec ? null : columns, allRec ? null : dataSource]);
  // handlers
  // useEffect(() => {
  //   !dragEnable && layout && onLayoutChange?.(colsLayout);
  // }, [colsLayout, dragEnable, layout, onLayoutChange]);
  function onLayoutChangeHandler(layout: Layout[], saveToServer = false) {
    changeColsLayout(layout);
    changeWidthSum(layout.reduce((partialSum, a) => partialSum + a.w, 0) - layout.length * 6);
    changeScrollWidth((prev) => (prev !== scrollWidth ? prev + (widthSum || 0) * colWidth : prev));
    saveToServer &&
      onLayoutChange?.(
        layout.map((layoutItem) => ({
          h: layoutItem.h,
          maxH: layoutItem.maxH || 1,
          maxW: layoutItem.maxW || 24,
          w: layoutItem.w,
          x: layoutItem.x,
          y: layoutItem.y,
          i: layoutItem.i,
        })),
      );
  }
  function changeFilterHandler(params: FilterTableData | undefined) {
    let paramsValues = params;
    if (
      filterItem?.sortBy === paramsValues?.sortBy &&
      filterItem?.sortDesc === paramsValues?.sortDesc
    ) {
      paramsValues = { sortBy: undefined, sortDesc: undefined } as unknown as FilterTableData;
    }
    changeFilterItem(paramsValues);
    onFilter?.(paramsValues);
  }
  const changeRangeHandler = useCallback(
    (value = 0, isScrollAction = false) => {
      const newRangeValue = `${value}%`;
      if (fullScrollRef && fullScrollRef.style.width !== newRangeValue && scrollBodyTableRef) {
        fullScrollRef.style.width = newRangeValue;
        if (!isScrollAction) {
          scrollBodyTableRef.scrollLeft =
            ((scrollBodyTableRef?.scrollWidth - scrollBodyTableRef?.clientWidth) / 100) * value;
        }
      }
    },
    [fullScrollRef, scrollBodyTableRef],
  );

  const onEventScroll = useCallback(
    (scrollLeft: number) => {
      if (scrollHeadTableRef && scrollBodyTableRef && fullScrollRef) {
        switch (true) {
          case scrollLeft !== scrollHeadTableRef.scrollLeft:
            scrollHeadTableRef.scrollLeft = scrollLeft;
            break;
          case scrollLeft !== scrollBodyTableRef.scrollLeft:
            scrollBodyTableRef.scrollLeft = scrollLeft;
            break;
        }
        changeRangeHandler(
          Math.ceil(
            (scrollLeft / (scrollBodyTableRef?.scrollWidth - scrollBodyTableRef?.clientWidth)) *
              100,
          ),
          true,
        );
      }
    },
    [changeRangeHandler, fullScrollRef, scrollBodyTableRef, scrollHeadTableRef],
  );
  // calculations
  const colsNum = useMemo(() => {
    const widthSum =
      colsLayout.reduce((partialSum, a) => partialSum + a.w, 0) - colsLayout.length * 6;
    return columns.length * 6 + widthSum;
  }, [colsLayout, columns]);
  useEffect(() => {
    changeScrollWidth(colsNum * 30);
  }, [colsNum]);
  const colWidth = useMemo(() => scrollWidth / colsNum, [colsNum, scrollWidth]);
  // jsx markup | builders
  const dataBuilder = dataSource?.map((row, index) => {
    return [
      columns[0],
      ...columns
        .filter((_, index) => index !== 0 && index !== columns.length - 1)
        .sort(
          (a, b) =>
            (colsLayout?.find((item) => item.i === a.index)?.x as number) -
            (colsLayout?.find((item) => item.i === b.index)?.x as number),
        ),
      columns[columns.length - 1],
    ].map((column, indexColumn) => {
      return (
        <CellWrapper
          key={`${index}_${indexColumn}`}
          size={column.size || 180}
          position={
            indexColumn === 0 ? 'first' : indexColumn === columns.length - 1 ? 'last' : undefined
          }
        >
          <TooltipWrapper
            content={
              row[column.index] &&
              (typeof row[column.index] === 'string' || typeof row[column.index] === 'number')
                ? row[column.index]
                : column.hintTitle && String(column.hintTitle)
            }
            className={'w-full'}
            id={`${column.index}_${indexColumn}_${index}`}
          >
            <div className={tableWhiteDateCellClassName(column.disableDefaultHeight)}>
              <div
                className={cn(
                  'w-[95%]  flex items-center justify-center ',
                  !column.disableDefaultHeight && 'truncate',
                )}
              >
                {row[column.index]}
              </div>
            </div>
          </TooltipWrapper>
        </CellWrapper>
      );
    });
  });
  const tableData = useMemo(
    () => (
      <div
        ref={(ref) => {
          changeScrollBodyTableRef(ref);
        }}
        className="width-[90px]  overflow-x-scroll scrollHidden scroll-auto"
        onScroll={(event) => onEventScroll(event.currentTarget.scrollLeft)}
      >
        <TableWrapperForColsAndData
          scrollWidth={scrollWidth}
          colWidth={colWidth}
          colsLayout={colsLayout}
          firstSize={columns[0]?.size}
          secondSize={columns[columns.length - 1]?.size}
          columns={columns
            .filter((_, index) => index !== 0 && index !== columns.length - 1)
            .sort(
              (a, b) =>
                (colsLayout?.find((item) => item.i === a.index)?.x as number) -
                (colsLayout?.find((item) => item.i === b.index)?.x as number),
            )}
          isScrollArea={isScrollArea}
        >
          {dataBuilder}
        </TableWrapperForColsAndData>
      </div>
    ),
    [colWidth, colsLayout, columns, dataBuilder, isScrollArea, onEventScroll, scrollWidth],
  );
  //  useEffect
  useEffect(() => {
    const el = scrollBodyTableRef;
    const secondEl = scrollHeadTableRef;
    if (el && secondEl) {
      const onWheel = (event: { deltaY: number; preventDefault: () => void }) => {
        if (event.deltaY == 0) return;
        event.preventDefault();
        secondEl.scrollTo({
          left: secondEl.scrollLeft + event.deltaY,
          behavior: 'auto',
        });
        el.scrollTo({
          left: el.scrollLeft + event.deltaY,
          behavior: 'auto',
        });
      };
      el.addEventListener('wheel', onWheel);
      secondEl.addEventListener('wheel', onWheel);
      return () => {
        el.removeEventListener('wheel', onWheel);
        secondEl.removeEventListener('wheel', onWheel);
      };
    }
  }, [scrollBodyTableRef, scrollHeadTableRef]);
  if (!dataSource?.length) return <Empty />;
  return (
    <div className="relative pt-[10px] z-1">
      <div className="sticky justify-between bg-defaultBg top-0 z-[999]">
        <ColumnsBuilder
          hideDragMode={hideDragMode}
          dragEnable={dragEnable}
          changeDragDisable={changeDragDisable}
          isScrollArea={isScrollArea}
          columns={columns}
          changeFilterHandler={changeFilterHandler}
          filterItem={filterItem}
          changeScrollHeadTableRef={changeScrollHeadTableRef}
          onEventScroll={onEventScroll}
          dataSource={dataSource}
          colsLayout={colsLayout}
          scrollWidth={scrollWidth}
          colsNum={colsNum}
          onLayoutChangeHandler={onLayoutChangeHandler}
        />
        {!isScrollArea && (
          <div
            className=" pb-[8px] opacity-50 hover:opacity-100 transition"
            style={{
              // top: DEFAULT_COL_SIZE + 6 || `${scrollHeadTableRef?.clientHeight}px`,
              marginLeft: `${columns[0]?.size || DEFAULT_CELL_SIZE}px`,
              // zIndex: (dataSource?.length || 0) + 10,
              maxWidth: `calc(100% - 360px)`,
              // maxWidth: `calc(100% - ${
              //   (columns[0]?.size || DEFAULT_CELL_SIZE) +
              //   (columns[columns.length - 1]?.size || DEFAULT_CELL_SIZE)
              // }px)`,
              height: '1px',
            }}
          >
            <label
              htmlFor="table_scroll"
              className="relative w-full my-[10px] block rounded-full h-[6px] bg-[#CCC]"
            >
              <div
                className="bg-speech_analitics absolute top-[0px] rounded-lg left-0 h-[6px] z-0"
                ref={(ref) => changeFullScrollRef(ref)}
              />
              <input
                ref={rangeRef}
                id="table_scroll"
                type="range"
                min={0}
                max={100}
                step={0.1}
                onChange={(event) => changeRangeHandler(Number(event.currentTarget.value))}
                className="tableScroll w-full absolute top-[0px] rounded-lg cursor-pointer  z-10"
              />
            </label>
          </div>
        )}
      </div>
      <div className="relative">
        <div className="z-10 relative">{tableData}</div>
        <div className="h-full w-full flex flex-col gap-y-[5px] absolute top-0 z-[1]">
          {dataSource?.map((row, index) => {
            return (
              <CellWrapper key={`${index}`} size={1500}>
                <div className={tableWhiteDateCellClassName()}>
                  <div className={cn('w-full flex items-center justify-center')}></div>
                </div>
              </CellWrapper>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Table;
