import React, { FC } from 'react';

import { IconType } from '../icon.type';

const DollarsignCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_614_1974)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM13.9912 18.5938C14.2197 18.5938 14.3955 18.4355 14.3955 18.1895V17.6006C15.8193 17.4688 16.8828 16.6602 16.8828 15.3242C16.8828 14.1201 16.1094 13.417 14.5801 13.1357L14.3955 13.1006V10.8857C14.958 10.9912 15.3535 11.3252 15.5205 11.8525C15.6436 12.1338 15.8369 12.3096 16.1445 12.3096C16.4961 12.3096 16.7334 12.0723 16.7334 11.7383C16.7334 11.6328 16.7158 11.5361 16.6895 11.4395C16.4521 10.5254 15.5645 9.875 14.3955 9.75195V9.16309C14.3955 8.91699 14.2197 8.75 13.9912 8.75C13.7539 8.75 13.5869 8.91699 13.5869 9.16309V9.75195C12.207 9.88379 11.2314 10.7275 11.2314 11.958C11.2314 13.0918 11.9873 13.7686 13.4551 14.1025L13.5869 14.1289V16.4756C12.875 16.3701 12.4531 15.9834 12.3125 15.4648C12.1982 15.1484 11.9961 14.9902 11.6973 14.9902C11.3369 14.9902 11.0996 15.2363 11.0996 15.5791C11.0996 15.6846 11.1172 15.7812 11.1523 15.8867C11.4072 16.8271 12.3477 17.4775 13.5869 17.5918V18.1895C13.5869 18.4355 13.7627 18.5938 13.9912 18.5938ZM13.5869 12.8896L13.5254 12.8721C12.8135 12.6699 12.4971 12.3535 12.4971 11.8789C12.4971 11.3867 12.9102 10.9912 13.5869 10.877V12.8896ZM14.3955 14.3398L14.5625 14.375C15.3359 14.5859 15.6084 14.9023 15.6084 15.4121C15.6084 15.9746 15.2041 16.3877 14.3955 16.4756V14.3398Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_614_1974">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default DollarsignCircleIcon;
