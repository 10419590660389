import React from 'react';
import { Skeleton, SkeletonPageLoading } from '@ui';

const AnalyticProjectSkeleton = () => {
  const skeletonTitleCellClassName = 'w-full px-[20px] py-[12px]';
  return (
    <div>
      <SkeletonPageLoading />
      <div className="mt-4 mb-10">
        <div className="flex w-full items-center sticky z-50 top-0 min-w-[80px] truncate bg-basic_app_bg uppercase ">
          <div className={skeletonTitleCellClassName}>
            <Skeleton width={64} height={14} className="ml-[45px] text-left" />
          </div>
          <div className={skeletonTitleCellClassName}>
            <Skeleton width={64} height={14} className="ml-[45px] text-right" />
          </div>
          <div className={skeletonTitleCellClassName}>
            <Skeleton width={64} height={14} className="ml-[45px] text-right" />
          </div>
          <div className={`${skeletonTitleCellClassName} align-end`}>
            <Skeleton width={64} height={14} className="ml-[45px] text-right" />
          </div>
        </div>
        <div className="w-full">
          <Skeleton height={400} />
        </div>
      </div>
      <div className="w-full">
        <Skeleton height={34} width={152} />
      </div>
    </div>
  );
};

export default AnalyticProjectSkeleton;
