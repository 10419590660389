import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import AudioBuild from '@app/components/audio-build';
import { NotFoundedPage } from '@app/pages';

const SingleRecordPage: FC<{ currentTemplate: 'chat' | 'voice' }> = ({ currentTemplate }) => {
  const { id } = useParams();
  if (!id) return <NotFoundedPage />;
  return <AudioBuild recordId={id} currentTemplate={currentTemplate} />;
};

export default SingleRecordPage;
