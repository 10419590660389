const loadingEn = require('./loading');
const recordEn = require('./record');
const paginatorEn = require('./paginator');
const emptyEn = require('./empty');
const reportsStatusLabelEn = require('./reportsStatusLabel');
const footerEn = require('./footer');
const easyModalEn = require('./easyModal');
const chartEditModalEn = require('./chartEditModal');
const audioEn = require('./audio');
const recordTableWithFilterEn = require('./recordTableWithFilter');
const filterEn = require('./filter');
const wordListComponentEn = require('./wordListComponent');
const menuBarEn = require('./menuBar');
const navigateTabsEn = require('./navigateTabs');
const selectUniversalFilterEn = require('./line-select-universal-filter')
const headerEn = require('./header')
const uploadEn = require('./upload')
const recipientsModalEn = require('./recipientsModal')

module.exports = {
  loading:loadingEn,
  record:recordEn,
  empty:paginatorEn,
  paginator:emptyEn,
  reportsStatusLabel:reportsStatusLabelEn,
  footer:footerEn,
  easyModal:easyModalEn,
  chartEditModal:chartEditModalEn,
  audio:audioEn,
  recordTableWithFilter:recordTableWithFilterEn,
  filter:filterEn,
  wordListComponent:wordListComponentEn,
  menuBar:menuBarEn,
  navigateTabs:navigateTabsEn,
  selectUniversalFilter:selectUniversalFilterEn,
  header:headerEn,
  upload:uploadEn,
  recipientsModal:recipientsModalEn
};