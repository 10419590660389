module.exports = {
  popup_hints: {
    settings_icon: 'Настроить',
    delete_icon: 'Удалить',
    templates_list_count: 'Количество шаблонов в группе',
    delete_template_icon: 'Удалить шаблон',
    settings_template_icon: 'Настроить шаблон ',
    copy_template_icon: 'Копировать шаблон',
  },
  create_new_project_button: 'Создать проект',
  move_group: 'Переместить в группу',
  refresh: 'Обновить',
  start_project: 'Запустить проект',
  stop_project: 'Остановить проект',
  remove_choosen: 'Удалить выбранные',
  name: 'Название',
  status: 'Статус',
  open: 'Открыть',
  actions: 'Действия',
  records: 'Записи',
  reports: 'Отчеты',
  group_name_not_selected: 'Название группы не было указано',
  status_will_be: 'Статус проекта будет',
  status_will_be_changed: 'Статус проекта будет изменен с',
  to: 'на',
  cant_change_status: 'Не удалось изменить статус проекта',
  write_name: 'Укажите наименование',
  analytic_proj: 'Аналитические проекты',
  not_projects: 'Нет проектов',
  not_groups: 'Нет созданных групп',
  new_group: 'Новая группа',
  delete_group: 'Удалить группу',
  delete_proj: 'Удалить проект',
  delete_selected_proj: 'Удалить выбранные проекты',
  delete_group_confirm: 'Вы действительно хотите удалить данную группу?',
  delete_proj_confirm: 'Все данные аналитического проекта, в том числе отчёты, будут удалены.',
  delete_proj_active: 'Активный аналитический проект не может быть удален. Требуется остановить проект.',
  delete_projects_confirm: 'Все данные аналитических проектов, в том числе отчёты, будут удалены.',
  preset_fill_error: 'Проект не может быть запущен. Отсутствуют необходимые настройки проекта',
};
