import cn from 'classnames';

export function dropAreaClassName(dragActive: boolean) {
  return cn(
    dragActive ? 'bg-bg_1' : 'bg-bg_3',
    'shadow-2xl',
    'rounded-[8px]',
    'flex',
    'items-center',
    'justify-center',
    'h-full',
    'shadow-hard',
    'py-8 px-4',
    'ring-[2px] ring-bg_1',
  );
}

export function withoutDropAreaClassName() {
  return cn('');
}
