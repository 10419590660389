import React, { ChangeEvent, FC, ForwardedRef, forwardRef } from 'react';
import cn from 'classnames';

import lineIcon from '../../../imgs/imagesHeader/Line.svg';

// const lineIcon = require('');
const Checkbox: FC<{
  checked?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  touched?: boolean;
  disabled?: boolean;
  className?: string;
}> = forwardRef((props, ref: ForwardedRef<HTMLInputElement>) => {
  const { checked, onChange, touched, className, disabled, ...rest } = props;
  const checkboxClassName = cn(
    'form-check-input',
    'ring-none',
    'outline-0',
    'appearance-none',
    'h-3',
    'w-3',
    'border',
    'border-gray-300',
    'rounded-sm',
    touched && !checked ? 'bg-action' : 'bg-white',
    'checked:bg-action',
    'checked:border-action',
    'focus:outline-none',
    'transition',
    'duration-200',
    'align-top',
    'bg-no-repeat',
    'bg-center',
    'bg-contain',
    'float-left',
    'cursor-pointer',
    className ? className : '',
  );
  return (
    <div>
      <input
        ref={ref}
        className={checkboxClassName}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        style={
          touched && !checked
            ? {
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)), url(${lineIcon})`,
                backgroundSize: '2px 70%',
                rotate: '90deg',
                backgroundRepeat: 'no-repeat',
                border: 'none',
              }
            : {}
        }
        onChange={(e) => onChange?.(e)}
        {...rest}
      />
      {/*<img src={lineIcon} width={100} height={100} alt="a" />*/}
      {/*{touched && !checked && (*/}
      {/*  // <div className="bg-action/[0.9] w-3 h-3 z-[1] absolute flex justify-center items-center">*/}
      {/*  <div className="w-2 h-[2px] bg-[#000] absolute z-[1]"></div>*/}
      {/*  // </div>*/}
      {/*)}*/}
    </div>
  );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
