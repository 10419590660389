import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ReportChart, ReportChartResponse, ReportTimeRange } from '@app/interfaces/report.type';
import ChartComponent from '@app/components/chart/chart-component';
import GridLayout, { Layout } from 'react-grid-layout';
import useResizeObserver from '@app/hooks/use-resize-observer';
import ChartDatePickerModalBlock from '@app/pages/reports/blocks/chart-date-picker-modal.block';
import { ChartEditModal } from '@app/pages/dashboards/blocks/chart-edit-modal';
import ModalSettingsChartBlock from '@app/pages/reports/blocks/chart-modal/modal-settings-chart.block';
import { EasyModal } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import {
  useLazyGetChatGraphDataQuery,
  useLazyGetGraphDataQuery,
} from '@app/store/api/graph-data.api';
import useChartDateIntervals from '@app/hooks/use-chart-date-intervals';
import {
  useDeleteGraphOnDashboardMutation,
  useEditDashboardGraphLayoutMutation,
} from '@app/store/api/dashboard-charts.api';
import { FilterItem } from '@app/interfaces/filter';
import { ChartsLayoutType, EditGraphLayoutType } from '@app/interfaces/dashboards.type';
import {
  useDeleteNewLiveReportGraphMutation,
  useEditLiveReportGraphLayoutMutation,
} from '@app/store/api/live-reports-charts.api';
import { useAppDispatch } from '@app/store/store';
import { editSelectedChartRangeDashboard } from '@app/store/reducers/dashboard-charts.slice';
import { editSelectedReportChartRange } from '@app/store/reducers/report-charts.slice';
import {
  useDeleteNewChatLiveReportGraphMutation,
  useEditChatLiveReportGraphLayoutMutation,
} from '@app/store/api/chat-api/chat-live-reports-charts.api';
type GridLayoutChartsContainerPropsType = {
  dashboard?: boolean;
  commonRange?: ReportTimeRange | null;
  layoutSettings: ChartsLayoutType[];
  changeLayoutSettings(layout: ChartsLayoutType[]): void;
  //todo
  charts?: any[];
  viewOnly?: boolean;
  emptyElement?: ReactNode;
  filterValue?: FilterItem[];
  currentDashboardOrReportId?: string;
  isDraggable?: boolean;
  flagForSendChartLayoutRequestChange?: boolean;
  currentTemplate?: 'chat' | 'voice';
};
const LAYOUT_ROW_HEIGHT = 70;

const GridLayoutChartsContainer: FC<GridLayoutChartsContainerPropsType> = (props) => {
  const { t } = useTranslation('pages.chartPage');
  const {
    dashboard = false,
    viewOnly = false,
    currentTemplate,
    charts,
    emptyElement,
    commonRange,
    filterValue,
    currentDashboardOrReportId,
    isDraggable: dragable = true,
    layoutSettings,
    changeLayoutSettings,
    flagForSendChartLayoutRequestChange,
  } = props;
  //api
  const dispatch = useAppDispatch();
  const [getGraphDataVoice] = useLazyGetGraphDataQuery();
  const [deleteLiveReportChartVoice] = useDeleteNewLiveReportGraphMutation();
  const [editLiveReportChartLayoutVoice] = useEditLiveReportGraphLayoutMutation();
  const [deleteChartFromDashboard] = useDeleteGraphOnDashboardMutation();
  const [editDashboardLayoutSettings] = useEditDashboardGraphLayoutMutation();

  //chat api
  const [deleteLiveReportChartChat] = useDeleteNewChatLiveReportGraphMutation();
  const [editLiveReportChartLayoutChat] = useEditChatLiveReportGraphLayoutMutation();
  const [getGraphDataChat] = useLazyGetChatGraphDataQuery();
  //chat api

  const getGraphData = { chat: getGraphDataChat, voice: getGraphDataVoice };
  const editLiveReportChartLayout = useMemo(
    () => ({
      chat: editLiveReportChartLayoutChat,
      voice: editLiveReportChartLayoutVoice,
    }),
    [editLiveReportChartLayoutChat, editLiveReportChartLayoutVoice],
  );
  const deleteLiveReportChart = {
    chat: deleteLiveReportChartChat,
    voice: deleteLiveReportChartVoice,
  };
  //api

  // state
  const { dateToString, intervalTimeBuilder } = useChartDateIntervals();
  const [openCustomDateModal, setOpenedCustomDateModal] = useState<boolean>(false);
  const [openModal, setMobile] = useState<boolean>(false);
  const [isOpenRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [isDraggable, changeIsDraggable] = useState<boolean>(dragable);
  const [data, setData] = useState<ReportChartResponse['data']>();
  const { reversedDateIntervals } = useChartDateIntervals();
  // state
  // functions
  useMemo(() => {
    changeIsDraggable(dragable);
    localStorage.setItem('layoutDrag', dragable.toString());
  }, [dragable]);

  function changeCustomDateInterval({ start, end }: { start: string | null; end: string | null }) {
    const range = intervalTimeBuilder(start, end) as unknown as ReportTimeRange;
    if (!data) return;
    if (dashboard) {
      getGraphData[currentTemplate || 'voice']({
        id: data.graph_id,
        settings: {
          width: data.width,
          range,
        },
      }).then((Cdata) => {
        const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
        const layout = layoutSettings.find((layout) => layout.i === data.dashboard_graph_id);
        dispatch(
          editSelectedChartRangeDashboard({ layout, chartData, id: data.dashboard_graph_id || '' }),
        );
      });
    } else {
      getGraphData[currentTemplate || 'voice']({
        id: data.graph_id,
        settings: {
          width: data.width,
          range,
          filter: filterValue,
        },
      }).then((Cdata) => {
        if (!Cdata.data) return;
        const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
        const layout = layoutSettings.find((layout) => layout.i === chartData.graph_id);
        dispatch(
          editSelectedReportChartRange({
            layout,
            chartData,
            id: chartData.graph_id,
          }),
        );
      });
    }
    setOpenedCustomDateModal(false);
  }
  function handlerRemoveModal() {
    if (!data) return;
    if (data.dashboard_graph_id) {
      deleteChartFromDashboard({
        dashboard_graph_id: data.dashboard_graph_id,
        dashboard_id: currentDashboardOrReportId,
      });
    } else
      deleteLiveReportChart[currentTemplate || 'voice']({
        id: data?.graph_id,
        live_report_id: currentDashboardOrReportId,
      });
  }
  // functions
  // grid layout
  const [gridLayoutLoading, changeGridLayoutLoading] = useState(true);
  const [gridWidth, changeGridWidth] = useState<number>(1055);
  const [layoutWidthRef, changeLayoutWidthRef] = useState<HTMLDivElement | null>(null);
  // const layoutRef = useRef<HTMLDivElement>(null);
  const [layoutForServerRequest, layoutChangeForServerRequest] = useState<EditGraphLayoutType[]>();
  const onLayoutChange = useCallback(
    (data: Layout[]) => {
      changeLayoutSettings(data);
      layoutChangeForServerRequest(
        dashboard
          ? data.map((item) => ({
              dashboard_graph_id: item.i,
              layout: { x: item.x, y: item.y, w: item.w, h: item.h },
            }))
          : data.map((item) => ({
              graph_id: item.i,
              layout: { x: item.x, y: item.y, w: item.w, h: item.h },
            })),
      );
    },
    [changeLayoutSettings, dashboard],
  );
  useResizeObserver(layoutWidthRef, changeGridWidth, changeGridLayoutLoading);

  useEffect(() => {
    if (
      layoutSettings.length &&
      flagForSendChartLayoutRequestChange &&
      layoutForServerRequest &&
      !isDraggable &&
      currentDashboardOrReportId
    ) {
      dashboard
        ? editDashboardLayoutSettings({
            body: layoutForServerRequest,
            params: { dashboard_id: currentDashboardOrReportId },
          })
        : editLiveReportChartLayout[currentTemplate || 'voice']({
            body: layoutForServerRequest,
            liveReportId: currentDashboardOrReportId,
          });
    }
  }, [
    currentDashboardOrReportId,
    currentTemplate,
    dashboard,
    editDashboardLayoutSettings,
    editLiveReportChartLayout,
    flagForSendChartLayoutRequestChange,
    isDraggable,
    layoutForServerRequest,
    layoutSettings.length,
  ]);
  // grid layout
  const layoutWidth = useCallback(
    (id: string) => layoutSettings.find((item) => item.i === id)?.w || 0,
    [layoutSettings],
  );
  const layoutHeight = useCallback(
    (id: string) => layoutSettings.find((item) => item.i === id)?.h || 0,
    [layoutSettings],
  );
  const chartWidth = useCallback(
    (graph) =>
      (gridWidth / 24) * layoutWidth(dashboard ? graph.dashboard_graph_id : graph.graph_id),
    [dashboard, gridWidth, layoutWidth],
  );
  // helpers
  const getChartRange = (range: ReportTimeRange | undefined) => {
    if (!range) return;
    return reversedDateIntervals[
      range?.type === 'l' ? `l${range?.parameters?.[0].num}` : range.type
    ];
  };

  const chartsRender = useMemo(() => {
    return charts?.map((graph, index) => (
      <div key={graph.layout.i || `key_${index + 1}`} className={'w-fit h-fit'}>
        <ChartComponent
          dashboard_graph_id={graph.dashboard_graph_id}
          viewOnly={viewOnly}
          layoutSettings={layoutSettings}
          commonRange={commonRange}
          isDraggable={isDraggable}
          dashboard={dashboard}
          data={graph}
          setData={setData}
          setOpenedCustomDateModal={setOpenedCustomDateModal}
          setMobile={setMobile}
          setOpenRemoveModal={setOpenRemoveModal}
          filterValue={filterValue}
          currentTemplate={currentTemplate === 'chat' ? 'chats' : graph.template || 'voice'}
          chartHeight={
            LAYOUT_ROW_HEIGHT * layoutHeight(dashboard ? graph.dashboard_graph_id : graph.graph_id)
          }
          chartWidth={chartWidth(graph)}
        />
      </div>
    ));
  }, [
    charts,
    viewOnly,
    layoutSettings,
    commonRange,
    isDraggable,
    dashboard,
    filterValue,
    currentTemplate,
    layoutHeight,
    chartWidth,
  ]);

  if (!charts?.length && charts !== null) return <>{emptyElement}</>;
  return (
    <>
      <div ref={(layoutRef) => changeLayoutWidthRef(layoutRef)} className="w-full h-full">
        {gridLayoutLoading ? null : (
          <GridLayout
            className="layout relative w-fit h-fit z-[1]"
            draggableCancel={'.noDrag'}
            layout={layoutSettings}
            cols={24}
            rowHeight={LAYOUT_ROW_HEIGHT}
            width={gridWidth || 1000}
            onDragStop={onLayoutChange}
            onResizeStop={onLayoutChange}
            isDraggable={isDraggable}
            isResizable={isDraggable}
            compactType={null}
            preventCollision={true}
            containerPadding={[0, 0]}
          >
            {chartsRender}
          </GridLayout>
        )}
      </div>
      <ChartDatePickerModalBlock
        dataIdStartDate="chart-interval-date-from"
        dataIdEndDate="chart-interval-date-to"
        show={openCustomDateModal}
        onSuccess={changeCustomDateInterval}
        onClose={setOpenedCustomDateModal.bind(null, false)}
      />
      {dashboard
        ? openModal && (
            <ChartEditModal
              width={data?.width}
              range={dateToString(data?.range)}
              editMode={true}
              dashboard_graph_id={data?.dashboard_graph_id}
              show={openModal}
              chartType={data?.template}
              live_report_graph_id={data?.graph_id || ''}
              onClose={setMobile.bind(null, false)}
              getChartName={data?.name || ''}
              getChartRange={getChartRange(data?.range) || ''}
            />
          )
        : openModal && (
            <ModalSettingsChartBlock
              isOpen={openModal}
              chartId={data?.graph_id}
              onClose={setMobile.bind(null, false)}
              onRemove={setOpenRemoveModal}
            />
          )}
      <EasyModal
        show={isOpenRemoveModal}
        variant="removal"
        label={t('modal_remove_chart_title')}
        onClose={setOpenRemoveModal.bind(null, false)}
        onRemove={handlerRemoveModal}
        withoutFooter
      >
        <div className="flex items-center justify-center">{t('button.remove_chart')}</div>
      </EasyModal>
    </>
  );
};

export default React.memo(GridLayoutChartsContainer);
