import React, { FC } from 'react';
import TitleWrapper from '@app/components/table/title-wrapper';
import { CircleButton } from '@ui';
import cn from 'classnames';
import {
  sortActiveClassName,
  tableTitleCellClassName,
  tableTitleCellItemClassName,
} from '@app/components/table/table.style';
import GridLayout from 'react-grid-layout';
import { TableWrapperForColsAndData } from '@app/components/table/table-wrapper-for-cols-and-data';
import { FilterTableData, TableItem, TableTitle } from '@app/components/table/table.type';
import { ChartsLayoutType } from '@app/interfaces/dashboards.type';
import Icon from '@app/components/ui/icons';

const Z_INDEX_TITLE_GROW = 2;
const DEFAULT_COL_SIZE = 45;
type ColumnsBuilderType = {
  columns: TableTitle[];
  changeFilterHandler(params: FilterTableData): void;
  filterItem: FilterTableData | undefined;
  changeScrollHeadTableRef: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  changeDragDisable: React.Dispatch<React.SetStateAction<boolean>>;
  onEventScroll(scrollLeft: number): void;
  dataSource: TableItem[] | undefined;
  colsLayout: ChartsLayoutType[];
  isScrollArea: boolean;
  hideDragMode?: boolean;
  dragEnable: boolean;
  scrollWidth: number;
  colsNum: number;
  onLayoutChangeHandler(layout: ChartsLayoutType[], saveToServer?: boolean): void;
};
export const ColumnsBuilder: FC<ColumnsBuilderType> = (props) => {
  const {
    columns,
    changeFilterHandler,
    filterItem,
    changeScrollHeadTableRef,
    onEventScroll,
    dataSource,
    colsLayout,
    isScrollArea,
    scrollWidth,
    colsNum,
    onLayoutChangeHandler,
    dragEnable,
    changeDragDisable,
    hideDragMode,
  } = props;

  function onLayoutChange(layout: ChartsLayoutType[]) {
    onLayoutChangeHandler(layout, false);
  }

  return (
    <div
      style={{
        gridTemplateColumns: `${columns[0].size || 160}px 1fr ${
          (columns[columns.length - 1].size || 140) + 7
        }px`,
      }}
      className="grid "
    >
      <TitleWrapper size={180}>
        <div className="px-[4px] text-clip break-words">{columns[0].title}</div>
        {columns[0].filter && (
          <div className="inline-flex items-center  ml-[3px]">
            <CircleButton
              icon="ArrowUpIcon"
              size={7}
              onClick={changeFilterHandler.bind(null, {
                sortBy: columns[0].index,
                sortDesc: false,
              })}
              className={cn(
                sortActiveClassName(
                  filterItem?.sortBy === columns[0].index && !filterItem?.sortDesc,
                ),
                'ml-[3px]',
              )}
            />
            <CircleButton
              icon="ArrowDownIcon"
              size={7}
              onClick={changeFilterHandler.bind(null, {
                sortBy: columns[0].index,
                sortDesc: true,
              })}
              className={cn(
                sortActiveClassName(
                  filterItem?.sortBy === columns[0].index && filterItem?.sortDesc,
                ),
                'ml-[-8px]',
              )}
            />
          </div>
        )}
      </TitleWrapper>
      <div
        ref={(ref) => changeScrollHeadTableRef(ref)}
        onScroll={(event) => onEventScroll(event.currentTarget.scrollLeft)}
        className="width-[100%] h-full overflow-hidden overflow-x-scroll scrollHidden scroll-auto bg-basic_app_bg  top-0"
        style={{ zIndex: (dataSource?.length || 0) + Z_INDEX_TITLE_GROW }}
      >
        <TableWrapperForColsAndData
          colsLayout={colsLayout}
          columns={columns}
          isScrollArea={isScrollArea}
          colsMode={true}
        >
          <GridLayout
            className="layout relative w-full content-start"
            draggableCancel={'.noDrag'}
            layout={colsLayout}
            // style={{ background: 'red' }}
            // margin={[20, 0]}
            cols={colsNum}
            onLayoutChange={onLayoutChange}
            rowHeight={DEFAULT_COL_SIZE}
            width={scrollWidth}
            maxRows={1}
            compactType={'horizontal'}
            isDraggable={dragEnable}
            isResizable={dragEnable}
          >
            {columns
              .filter((_, index) => index !== 0 && index !== columns.length - 1)
              .map((column) => (
                <div key={`${column.index}`} className={cn(tableTitleCellClassName)}>
                  <div className={cn(tableTitleCellItemClassName(dragEnable))}>
                    <div className="px-[4px] text-clip break-words truncate">{column.title}</div>
                    {column.filter && !dragEnable && (
                      <div className="inline-flex items-center  ml-[3px]">
                        <CircleButton
                          icon="ArrowUpIcon"
                          size={7}
                          onClick={changeFilterHandler.bind(null, {
                            sortBy: column.index,
                            sortDesc: false,
                          })}
                          className={cn(
                            sortActiveClassName(
                              filterItem?.sortBy === column.index && !filterItem?.sortDesc,
                            ),
                            'ml-[3px]',
                          )}
                        />
                        <CircleButton
                          icon="ArrowDownIcon"
                          size={7}
                          onClick={changeFilterHandler.bind(null, {
                            sortBy: column.index,
                            sortDesc: true,
                          })}
                          className={cn(
                            sortActiveClassName(
                              filterItem?.sortBy === column.index && filterItem?.sortDesc,
                            ),
                            'ml-[-8px]',
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </GridLayout>
        </TableWrapperForColsAndData>
      </div>
      <TitleWrapper size={180}>
        <div className="px-[4px] text-clip break-words">{columns[columns.length - 1].title}</div>
        {columns[columns.length - 1].filter && (
          <div className="inline-flex items-center  ml-[3px]">
            <CircleButton
              icon="ArrowUpIcon"
              size={7}
              onClick={changeFilterHandler.bind(null, {
                sortBy: columns[columns.length - 1].index,
                sortDesc: false,
              })}
              className={cn(
                sortActiveClassName(
                  filterItem?.sortBy === columns[columns.length - 1].index && !filterItem?.sortDesc,
                ),
                'ml-[3px]',
              )}
            />
            <CircleButton
              icon="ArrowDownIcon"
              size={7}
              onClick={changeFilterHandler.bind(null, {
                sortBy: columns[columns.length - 1].index,
                sortDesc: true,
              })}
              className={cn(
                sortActiveClassName(
                  filterItem?.sortBy === columns[columns.length - 1].index && filterItem?.sortDesc,
                ),
                'ml-[-8px]',
              )}
            />
          </div>
        )}
        {!hideDragMode && (
          <div
            onClick={() => {
              dragEnable && onLayoutChangeHandler(colsLayout, true);
              changeDragDisable((prev) => !prev);
            }}
            className=" pr-[10px]"
          >
            <Icon className={`${dragEnable && '!text-basic_red'}`} name="SettingIcon" />
          </div>
        )}
      </TitleWrapper>
    </div>
  );
};
