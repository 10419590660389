import { Button, Input } from '@app/components/ui';
import Icon from '@app/components/ui/icons';
import { IconNameType } from '@app/components/ui/icons/icon-name.type';
import useTranslation from '@app/hooks/use-translation';
import { AliasSettingsForCreate, AliasSettingsObj } from '@app/interfaces/settings.type';
import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
type AliasEditCreateFormPropsType = {
  onFormSubmit: (data: AliasSettingsForCreate) => void;
  editMode: boolean;
  aliasData: AliasSettingsObj | undefined;
  deleteAliasHandle(id: string): void;
};
const iconData = {
  num: 'NumberIcon',
  str: 'TextIcon',
};

export const AliasEditCreateForm: FC<AliasEditCreateFormPropsType> = (props) => {
  const { onFormSubmit, editMode, aliasData, deleteAliasHandle } = props;
  const formSetup = useMemo(
    () =>
      editMode
        ? {
            name: aliasData?.name,
            meta_name: aliasData?.meta_name,
            type_value: aliasData?.type_value,
            type_meta: aliasData?.type_meta,
          }
        : undefined,
    [aliasData, editMode],
  );
  const { handleSubmit, register, setValue, watch } = useForm<AliasSettingsForCreate>({
    defaultValues: formSetup,
  });
  const { t } = useTranslation('pages.settings');
  function onSubmit(data) {
    onFormSubmit(data);
  }
  const typesTranslateData = {
    num: t('alias.values_translate.num'),
    str: t('alias.values_translate.str'),
    system: t('alias.values_translate.system'),
    base: t('alias.values_translate.base'),
    channel: t('alias.values_translate.channel'),
    record: t('alias.values_translate.record'),
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-[35px_40px_40px_40px]">
      <div>
        <div className="flex items-center gap-[78px] mb-[20px]">
          <span className="text-1color text-[15px] font-[500] leading-[110%] tracking-tight	">
            {t('alias.form.alias')}
          </span>
          <div className={`w-full ${aliasData?.ro && 'opacity-[0.4]'}`}>
            <Input
              {...register('name')}
              disabled={aliasData?.ro}
              placeholder={t('alias.form.placeholder_name')}
              name={'name'}
            />
          </div>
        </div>
        <div className="flex items-center gap-[35px] mb-[30px]">
          <span className="text-1color text-[15px] font-[500] leading-[110%] tracking-tight	">
            {t('alias.form.meta')}
          </span>
          {editMode ? (
            <span className="text-0color text-[15px] leading-[18px] truncate font-[400] ml-[11px]">
              {aliasData?.meta_name}
            </span>
          ) : (
            <Input
              placeholder={t('alias.form.placeholder_meta_name')}
              {...register('meta_name')}
              name={'meta_name'}
            />
          )}
        </div>
        <div className="mb-[20px] flex gap-[44px] mb-[30px]">
          <span
            className={`${
              !editMode && 'mt-[4px]'
            } text-1color text-[15px] font-[500] leading-[110%] tracking-tight`}
          >
            {t('alias.form.type_value')}
          </span>
          <div className="flex flex-col items-start gap-[3px]">
            {editMode && aliasData?.type_value ? (
              <div className="flex items-center h-full gap-[7px]">
                <Icon
                  className="text-3color"
                  size={17}
                  name={iconData[aliasData?.type_value] || 'HelpCircleIcon'}
                />
                <span className="text-0color text-[15px] leading-[18px] font-[400]">
                  {typesTranslateData[aliasData?.type_value] || aliasData?.type_value}
                </span>
              </div>
            ) : (
              <>
                <div className="flex items-center gap-[8px]">
                  <input
                    name="type_value"
                    onChange={() => setValue('type_value', 'str')}
                    type="checkbox"
                    checked={watch().type_value === 'str'}
                    value={'str'}
                    className="focus:ring-offset-0 focus:ring-0 rounded-full"
                  />
                  <div className="flex items-center h-full gap-[7px]">
                    <Icon
                      className="text-3color"
                      size={17}
                      name={iconData['str'] as IconNameType}
                    />
                    <span className="text-1color text-[14px] font-[400] leading-[30px] tracking-tight">
                      {t('alias.form.text')}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-[8px]">
                  <input
                    name="type_value"
                    onChange={() => setValue('type_value', 'num')}
                    type="checkbox"
                    checked={watch().type_value === 'num'}
                    value={'num'}
                    className="focus:ring-offset-0 focus:ring-0 rounded-full"
                  />
                  <div className="flex items-center h-full gap-[7px]">
                    <Icon
                      className="text-3color"
                      size={17}
                      name={iconData['num'] as IconNameType}
                    />
                    <span className="text-1color text-[14px] font-[400] leading-[30px] tracking-tight">
                      {t('alias.form.number')}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex gap-[20px] mb-[30px]">
          <span className="mt-[4px] text-1color text-[15px] font-[500] leading-[110%] tracking-tight">
            {t('alias.form.type_meta')}
          </span>
          <div className="flex flex-col items-start gap-[3px]">
            {editMode && aliasData?.type_meta ? (
              <span className="text-0color text-[15px] leading-[18px] font-[400] mt-[3px]">
                {typesTranslateData[aliasData?.type_meta] || aliasData?.type_meta}
              </span>
            ) : (
              <>
                <div className="flex items-center gap-[8px]">
                  <input
                    name="type_meta"
                    onChange={() => setValue('type_meta', 'record')}
                    type="checkbox"
                    checked={watch().type_meta === 'record'}
                    value={'record'}
                    className="focus:ring-offset-0 focus:ring-0 rounded-full"
                  />
                  <span className="text-1color text-[14px] font-[400] leading-[30px] tracking-tight">
                    {t('alias.form.record_level')}
                  </span>
                </div>
                <div className="flex items-center gap-[8px]">
                  <input
                    name="type_meta"
                    onChange={() => setValue('type_meta', 'channel')}
                    type="checkbox"
                    checked={watch().type_meta === 'channel'}
                    value={'channel'}
                    className="focus:ring-offset-0 focus:ring-0 rounded-full"
                  />
                  <span className="text-1color text-[14px] font-[400] leading-[30px] tracking-tight">
                    {t('alias.form.channel_level')}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center flex-col gap-[18px] pl-[64px] pr-[72px]">
        <Button
          type="submit"
          isFull
          className="!px-[60px] !py-[20px] mt-[10px]"
          label={t('alias.form.button_label')}
        />
        {editMode && (
          <div className="flex w-full justify-center items-center ml-[-4px]">
            <Button
              variant="danger"
              fill="linked"
              className="w-full"
              label={t('alias.form.button_label_delete')}
              onClick={() => aliasData?.alias_id && deleteAliasHandle(aliasData?.alias_id)}
            />
          </div>
        )}
      </div>
    </form>
  );
};
