import { createAction, Middleware } from '@reduxjs/toolkit';
import { AxiosRequestHeaders } from 'axios';
import apiInstance from '@app/api/common.api';

export type HttpRequest<T> = {
  url: string;
  params?: { [x: string]: string | number | boolean | Array<string> };
  method: 'POST' | 'GET' | 'PUT' | 'DELETE';
  data?: T;
  headers?: { [key: string]: string };
  onLoading?: string;
  onSuccess?: string;
  onError?: string;
  onStatus?: string;
};
type HttpRequestSuccess<T> = {
  onLoading: string;
  onSuccess?: string;
  onStatus?: string;
  status?: number;
  requestData?: T;
  data: T;
};
type HttpRequestError<T> = {
  url: string;
  params?: { [x: string]: string | number | boolean | Array<string> };
  method?: 'POST' | 'GET' | 'PUT' | 'DELETE';
  data?: T;
  headers?: { [key: string]: string };
  onSuccess?: string;
  onStatus?: string;
  onLoading: string;
  onError: string;
  error: AxiosRequestHeaders;
};

export const httpRequest = createAction<HttpRequest<unknown>>('http/request');
const httpRequestSuccess = createAction<HttpRequestSuccess<unknown>>('http/requestSuccess');
const httpRequestError = createAction<HttpRequestError<unknown>>('http/requestError');

const restMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    const { type, payload } = action;
    switch (type) {
      case httpRequest.type: {
        const {
          url,
          method,
          data: requestData,
          params,
          headers,
          onSuccess,
          onStatus,
          onLoading,
          onError,
        } = payload;
        if (onLoading) dispatch({ type: onLoading, payload: true });
        return apiInstance
          .request({
            url,
            params,
            method,
            headers,
            data: requestData,
          })
          .then((response) => {
            const { data, status } = response;
            dispatch(
              httpRequestSuccess({
                onLoading,
                onSuccess,
                onStatus,
                data,
                status,
                requestData: { ...requestData, ...params },
              }),
            );
            return data;
          })
          .catch((httpError) => {
            const error = { status: httpError.status, headers: httpError.headers };
            dispatch(
              httpRequestError({
                url,
                method,
                data: requestData,
                params,
                headers,
                onSuccess,
                onStatus,
                onLoading,
                onError,
                error,
              }),
            );
            return Promise.reject(error);
          });
      }
      case httpRequestSuccess.type: {
        const { onLoading, onStatus, onSuccess, data, status, requestData } = payload;
        if (onSuccess) dispatch({ type: onSuccess, payload: data });
        if (onStatus) dispatch({ type: onStatus, payload: { status, requestData, data } });
        if (onLoading) dispatch({ type: onLoading, payload: false });
        break;
      }
      case httpRequestError.type: {
        const { onLoading, onError, error } = payload;
        if (error.code === 401) {
          console.log('i catch error in auth');
        }
        if (onError) dispatch({ type: onError, payload: error });
        if (onLoading) dispatch({ type: onLoading, payload: false });
        break;
      }
      default:
        return next(action);
    }
  };

export default restMiddleware;
