module.exports = {
  popup_hints: {
    to_record_title: 'Перейти к просмотру записи',
    download_record_button: 'Скачать аудиозапись',
  },
  filter: {
    title: 'Фильтровать записи проекта',
    buttons: {
      create_report: 'Создать LIVE отчет на основе фильтра',
      create_report_rtk: 'Создать отчет на основе фильтра',
      reset_filter: 'Сбросить',
      cancel: 'Очистить'
    }
  },
  table: {
    first_column_title: 'Длительность',
    last_column_title: 'Действия',
  },
};
