module.exports = {
  current_day: 'Today',
  current_week: 'Current week',
  current_month: 'Current month',
  without_interval: 'Without specifying date interval',
  with_interval: 'Date and time : Interval',
  Date_n_days: 'Date and time : N days',
  calendar_interval: 'Date and time : Calendar linkage',
  exists: 'Exists',
  not_exists: 'Not exist',
  calculated: 'Calculated',
  not_calculated: 'Not calculated',
  // type diapason  time
  range_from: 'Date and time : Interval',
  range_to: 'Date and time : Latest',
  calendar_bin: 'Date and time : Calendar linkage',
  // type diapason  time
  // display special conditions translate like exist
  display_exists: 'e',
  display_not_exists: '!e',
  display_calculated: 'c',
  display_not_calculated: '!c',
  // display special conditions translate like exist,
  server_error: 'Server error',
  server_no_access: 'No access',
  print_report: 'Print report',
}
