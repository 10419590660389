import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { UsersListType, UserRoleList, UserObjectType } from '@app/interfaces/users';

import { baseQuery } from '../baseQuery';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: ['users'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getUsers: build.query<UsersListType, void>({
      query: () => ({
        url: serverRoutes.usersBulk,
      }),
    }),
    getUserRole: build.query<UserRoleList, void>({
      query: () => ({
        url: serverRoutes.usersRole,
      }),
    }),
    addUser: build.mutation<
      UserObjectType,
      { username: string; role_id: string; login: string; password?: string }
    >({
      query: (body) => ({
        url: serverRoutes.users,
        method: 'POST',
        body,
      }),
    }),
    deleteUser: build.mutation({
      query: (body: string) => ({
        url: serverRoutes.users,
        method: 'DELETE',
        params: { id: body },
      }),
    }),
    editUser: build.mutation<UserObjectType, UserObjectType>({
      query: (body) => ({
        url: serverRoutes.users,
        method: 'PUT',
        params: { id: body.id },
        body,
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserRoleQuery,
  useAddUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
} = usersApi;
