import React from 'react';
import { Skeleton } from '@ui';

const ReportChartsSkeleton = ({ count, withHeader }: { count?: number; withHeader?: boolean }) => {
  return (
    <div className="w-full">
      {withHeader && (
        <div className="w-full h-[58px] mt-[10px]">
          <Skeleton height={48} />
        </div>
      )}
      <div className="w-full h-full grid grid-cols-2 grid-flow-dense gap-[10px]">
        {Array(count)
          .fill('_')
          .map((_, index) => (
            <div key={index} className="w-full h-[360px]">
              <Skeleton width={'100%'} height={'100%'} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ReportChartsSkeleton;
