import { EasyModal } from '@app/components/ui';
import { RecordChannelIcon } from '@app/components/ui/icons/icons-list';
import React, { FC } from 'react';

import {
  RecognitionRulesSettingsForCreate,
  RecognitionRulesSettingsObj,
} from '@app/interfaces/settings.type';

import useTranslation from '@app/hooks/use-translation';
import { RecognitionRulesForm } from '@app/pages/settings/blocks/recognition-rules-settings/recognition-rules-form';
import { AsrModerType } from '@app/interfaces/asr-moder.type';

type ModalWithForm = {
  isOpenRecognitionModal: boolean;
  changeIsOpenRecognitionModal: React.Dispatch<React.SetStateAction<boolean>>;
  changeEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  onFormSubmitHandler(data: RecognitionRulesSettingsForCreate): void;
  editMode: boolean;
  recognitionRuleData: RecognitionRulesSettingsObj | undefined;
  asr_model: AsrModerType[] | undefined;
};
const RecognitionRulesModalWithForm: FC<ModalWithForm> = (props) => {
  const { t } = useTranslation('pages.settings');
  const {
    isOpenRecognitionModal,
    changeIsOpenRecognitionModal,
    onFormSubmitHandler,
    editMode,
    changeEditMode,
    asr_model,
    recognitionRuleData,
  } = props;
  return (
    <EasyModal
      show={isOpenRecognitionModal}
      onRemove={() => changeEditMode(false)}
      label={
        <div className="flex flex-col gap-[13px] items-center justify-center">
          <div className="w-[57px] h-[57px] rounded-full bg-blue_pal flex items-center justify-center">
            <RecordChannelIcon className="text-action" size={25} />
          </div>
          <span className="text-0color text-[24px] font-[700] leading-[29px]">
            {editMode ? t('recog_rules.modal_label_edit') : t('recog_rules.modal_label')}
          </span>
        </div>
      }
      onClose={() => {
        editMode && changeEditMode(false);
        changeIsOpenRecognitionModal(false);
      }}
      variant="mediumW650"
      withoutContentPadding
      withoutFooter
    >
      <div className="px-[50px] pb-[48px] pt-[35px]">
        <RecognitionRulesForm
          editMode={editMode}
          onFormSubmit={onFormSubmitHandler}
          asr_model={asr_model}
          recognitionRuleData={recognitionRuleData}
        />
      </div>
    </EasyModal>
  );
};
export default RecognitionRulesModalWithForm;
