import React, { FC } from 'react';

import { IconType } from '../icon.type';

const CartCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_614_1961)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM12.5234 16.5723H17.2168C17.498 16.5723 17.6914 16.3789 17.6914 16.0889C17.6914 15.7988 17.498 15.6055 17.2168 15.6055H12.5762C12.3389 15.6055 12.1895 15.4736 12.1631 15.2363L12.1191 14.9111H17.4102C18.0693 14.9111 18.4033 14.5156 18.5 13.8564L18.834 11.6416C18.8428 11.5889 18.8516 11.5098 18.8516 11.4746C18.8516 11.2285 18.6846 11.0615 18.3945 11.0615H11.5479L11.4775 10.5254C11.416 10.1387 11.1172 9.88379 10.6953 9.88379H9.23633C8.94629 9.88379 8.73535 10.0947 8.73535 10.3848C8.73535 10.6748 8.94629 10.8857 9.23633 10.8857H10.5371L11.1963 15.5C11.3018 16.1943 11.7412 16.5723 12.5234 16.5723ZM12.6992 18.7168C13.1475 18.7168 13.499 18.3652 13.499 17.9258C13.499 17.4688 13.1475 17.1172 12.6992 17.1172C12.2598 17.1172 11.8994 17.4688 11.8994 17.9258C11.8994 18.3652 12.2598 18.7168 12.6992 18.7168ZM16.5137 18.7168C16.9531 18.7168 17.3135 18.3652 17.3135 17.9258C17.3135 17.4688 16.9531 17.1172 16.5137 17.1172C16.0654 17.1172 15.7051 17.4688 15.7051 17.9258C15.7051 18.3652 16.0654 18.7168 16.5137 18.7168Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_614_1961">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CartCircleIcon;
