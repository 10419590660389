import { PresetsDataType } from '@app/interfaces/pages-types/presets-page-types/presets-table-props.type';
import React from 'react';

import { TableChildren } from './tableChildren';

type PropsType = {
  group: PresetsDataType[];
  groupName: string;
};
export const Container: React.FC<PropsType> = ({ group, groupName }) => {
  return <TableChildren groupName={groupName} presetGroup={group} />;
};
