import React, { FC } from 'react';

import { IconType } from '../icon.type';

const WaveformAndMagnifyingglassIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_460)">
          <path
            d="M12.4795 23.0234C12.9189 23.0234 13.2617 22.6719 13.2617 22.25V5.06738C13.2617 4.64551 12.9189 4.29395 12.4795 4.29395C12.04 4.29395 11.7061 4.64551 11.7061 5.06738V22.25C11.7061 22.6719 12.04 23.0234 12.4795 23.0234ZM17.7617 14.4629C18.1572 13.9004 18.6934 13.4346 19.3086 13.1094V7.00977C19.3086 6.5791 18.9746 6.21875 18.5352 6.21875C18.0869 6.21875 17.7617 6.5791 17.7617 7.00977V14.4629ZM9.45605 19.6045C9.89551 19.6045 10.2383 19.2529 10.2383 18.8311V8.48633C10.2383 8.05566 9.89551 7.7041 9.45605 7.7041C9.0166 7.7041 8.68262 8.05566 8.68262 8.48633V18.8311C8.68262 19.2529 9.0166 19.6045 9.45605 19.6045ZM15.5117 18.4619C15.9424 18.4619 16.2852 18.1191 16.2852 17.6973V9.62012C16.2852 9.18945 15.9424 8.83789 15.5117 8.83789C15.0635 8.83789 14.7295 9.18945 14.7295 9.62012V17.6973C14.7295 18.1191 15.0635 18.4619 15.5117 18.4619ZM20.7852 12.6436C21.2949 12.5645 21.8223 12.5645 22.3408 12.6523V11.3428C22.3408 10.9121 21.998 10.5518 21.5586 10.5518C21.1191 10.5518 20.7852 10.9121 20.7852 11.3428V12.6436ZM6.42383 16.0625C6.87207 16.0625 7.21484 15.7109 7.21484 15.2803V12.0371C7.21484 11.6064 6.87207 11.2461 6.42383 11.2461C5.99316 11.2461 5.65918 11.6064 5.65918 12.0371V15.2803C5.65918 15.7109 5.99316 16.0625 6.42383 16.0625ZM21.4971 20.9316C22.165 20.9316 22.7891 20.7471 23.3252 20.4307L25.3643 22.4609C25.5576 22.6455 25.7686 22.7422 26.0234 22.7422C26.4893 22.7422 26.8232 22.3906 26.8232 21.916C26.8232 21.6963 26.7266 21.4854 26.5684 21.3271L24.5205 19.2705C24.8721 18.7168 25.083 18.0576 25.083 17.3457C25.083 15.3857 23.4746 13.7686 21.4971 13.7686C19.5371 13.7686 17.9199 15.3857 17.9199 17.3457C17.9199 19.3145 19.5371 20.9316 21.4971 20.9316ZM21.4971 19.6396C20.249 19.6396 19.2119 18.6025 19.2119 17.3457C19.2119 16.0977 20.249 15.0693 21.4971 15.0693C22.7539 15.0693 23.7822 16.0977 23.7822 17.3457C23.7822 18.6025 22.7539 19.6396 21.4971 19.6396Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_460">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default WaveformAndMagnifyingglassIcon;
