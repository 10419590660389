import React, { FC } from 'react';

import { IconType } from '../icon.type';

const DesktopcomputerAndArrowDownIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_609_158)">
          <path
            d="M13.2529 3.08105V9.9541L13.3408 11.6504L11.8115 9.91895L11.3193 9.43555C11.1787 9.30371 10.9941 9.21582 10.7832 9.21582C10.3701 9.21582 10.0801 9.51465 10.0801 9.91895C10.0801 10.1299 10.1504 10.3145 10.291 10.4463L13.3936 13.5576C13.5781 13.751 13.7891 13.8477 13.9912 13.8477C14.1934 13.8477 14.4131 13.751 14.5977 13.5576L17.7002 10.4463C17.8408 10.3145 17.9111 10.1299 17.9111 9.91895C17.9111 9.51465 17.6211 9.21582 17.208 9.21582C16.9971 9.21582 16.8125 9.30371 16.6719 9.43555L16.1797 9.91895L14.6504 11.6504L14.7383 9.9541V3.08105C14.7383 2.63281 14.3867 2.34277 13.9912 2.34277C13.6045 2.34277 13.2529 2.63281 13.2529 3.08105ZM5.51855 19.8154H11.126V21.4062H11.0908C10.6514 21.4062 10.291 21.7666 10.291 22.2061C10.291 22.6367 10.6514 22.9971 11.0908 22.9971H16.9004C17.3398 22.9971 17.7002 22.6367 17.7002 22.2061C17.7002 21.7666 17.3398 21.4062 16.9004 21.4062H16.8652V19.8154H22.4639C23.835 19.8154 24.6611 18.998 24.6611 17.627V7.03613C24.6611 5.66504 23.835 4.84766 22.4639 4.84766H16.0127V6.52637H22.3672C22.7451 6.52637 22.9736 6.76367 22.9736 7.13281V15.3857C22.9736 15.6582 22.8594 15.7549 22.6045 15.7549H5.38672C5.13184 15.7549 5.01758 15.6582 5.01758 15.3857V7.13281C5.01758 6.76367 5.24609 6.52637 5.62402 6.52637H11.9697V4.84766H5.51855C4.15625 4.84766 3.33008 5.66504 3.33008 7.03613V17.627C3.33008 18.998 4.15625 19.8154 5.51855 19.8154Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_609_158">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default DesktopcomputerAndArrowDownIcon;
