import React from 'react';
import { Route } from 'react-router-dom';
import { navigationRoutes } from '@app/utils/navigation-routes';
import RequiredAuth from '@app/routes/required-auth.route';
import WordsNGramm from '@app/pages/words-n-gramm/words-n-gramm';
import { WordsNGramCloudContent } from '@app/pages/words-n-gramm/blocks/cloud-content';
import { BreadcrumbsItem } from '@app/layouts/layout';
import { TableContentNGramCloud } from '@app/pages/words-n-gramm/blocks/table-content';

export const wordsNGramm = (
  <Route
    path={navigationRoutes.wordsNGramm}
    element={
      <RequiredAuth>
        <WordsNGramm currentTemplate="voice" />
      </RequiredAuth>
    }
  >
    <Route
      index
      element={
        <RequiredAuth>
          <WordsNGramCloudContent />
        </RequiredAuth>
      }
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={`/${navigationRoutes.wordsNGramm}`} crumb="wordsNGram" />
        ),
      }}
    />
    <Route
      path="table"
      element={<TableContentNGramCloud />}
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={`/${navigationRoutes.wordsNGramm}`} crumb="wordsNGramm" />
        ),
      }}
    />
  </Route>
);
