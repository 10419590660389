// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { javascriptGenerator } from 'blockly/javascript';
import * as Blockly from 'blockly';

// removing contextMenu items
Blockly.ContextMenuRegistry.registry.unregister('blockCollapseExpand');
Blockly.ContextMenuRegistry.registry.unregister('blockDisable');
Blockly.ContextMenuRegistry.registry.unregister('blockComment');

javascriptGenerator.forBlock['formula_expression'] = function (block) {
  let value = block.getFieldValue('STATEMENT');

  if (isInteger(value)) {
    value = toInteger(value);
  } else if (isFloat(value)) {
    value = toFloat(value);
  } else if (isBoolean(value)) {
    value = toBoolean(value);
  } else if (isString(value)) {
    value = toString(value);
  }

  return [value, javascriptGenerator.ORDER_NONE];
};
javascriptGenerator.forBlock['formula_metric'] = function (block) {
  const metricId = block.getFieldValue('METRIC_ID');
  return ['{metric:' + metricId + '}', javascriptGenerator.ORDER_NONE];
};

function isString(value) {
  return Object.prototype.toString.call(value) === '[object String]';
}

function isInteger(value) {
  return !isNaN(value) && parseInt(String(Number(value))) == value && !isNaN(parseInt(value, 10));
}

function isFloat(value) {
  return Number(value) === value && value % 1 !== 0;
}

function isBoolean(value) {
  switch (value.toLowerCase().trim()) {
    case 'true':
    case 'false':
      return true;
  }
  return value === true || value === false || toString.call(null, value) === '[object Boolean]';
}

function toString(value) {
  return '"' + value + '"';
}

function toInteger(value) {
  return parseInt(value);
}

function toFloat(value) {
  return parseFloat(value);
}

function toBoolean(value) {
  switch (value.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(value);
  }
}

javascriptGenerator.forBlock['formula_start'] = function (block) {
  return javascriptGenerator.valueToCode(block, 'FORMULA', javascriptGenerator.ORDER_NONE || '');
};
javascriptGenerator.forBlock['formula_mathops'] = function (block) {
  const parenthesis =
    block.getParent() != null &&
    (block.getParent().type === 'formula_mathops' || block.getParent().type === 'formula_logicops');
  const count = (block.inputList.length - 1) / 2;
  const tmp = [];

  const left = javascriptGenerator.valueToCode(block, 'LEFT', javascriptGenerator.ORDER_NONE || '');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  tmp.push(left);

  for (let i = 0; i < count; i++) {
    const operator = block.getFieldValue(block.inputOperatorFieldName + '_' + (i + 1));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tmp.push(operator);
    const right = javascriptGenerator.valueToCode(
      block,
      block.inputRightName + '_' + (i + 1),
      javascriptGenerator.ORDER_NONE || '',
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tmp.push(right);
  }

  let expresion = tmp.join(' ');
  if (parenthesis) {
    expresion = '(' + expresion + ')';
  }

  return [expresion, javascriptGenerator.ORDER_NONE];
};

javascriptGenerator.forBlock['formula_relops'] = function (block) {
  const parenthesis =
    block.getParent() != null &&
    (block.getParent().type === 'formula_mathops' || block.getParent().type === 'formula_logicops');
  const operator = block.getFieldValue('OPERATOR');

  let left = javascriptGenerator.valueToCode(block, 'LEFT', javascriptGenerator.ORDER_NONE || '');
  left = parenthesis ? '(' + left : left;

  let right = javascriptGenerator.valueToCode(block, 'RIGHT', javascriptGenerator.ORDER_NONE || '');
  right = parenthesis ? right + ')' : right;

  return [left + ' ' + operator + ' ' + right, javascriptGenerator.ORDER_NONE];
};

javascriptGenerator.forBlock['formula_if'] = function (block) {
  const expression = javascriptGenerator.valueToCode(
    block,
    'EXPRESSION',
    javascriptGenerator.ORDER_NONE || '',
  );
  const ifTrue = javascriptGenerator.valueToCode(
    block,
    'VALUE_IF_TRUE',
    javascriptGenerator.ORDER_NONE || '',
  );
  const ifFalse = javascriptGenerator.valueToCode(
    block,
    'VALUE_IF_FALSE',
    javascriptGenerator.ORDER_NONE || '',
  );

  return [
    ' (' + expression + ' ? ' + ifTrue + ' : ' + ifFalse + ') ',
    javascriptGenerator.ORDER_NONE,
  ];
};

javascriptGenerator.forBlock['formula_and_or'] = function (block) {
  const operator = block.getFieldValue('NAME') === 'OR' ? '||' : '&&';

  const tmp = [];
  for (let i = 0; i < block.inputList.length - 1; i++) {
    tmp.push(
      javascriptGenerator.valueToCode(
        block,
        'EXPRESSION_' + (i + 1),
        javascriptGenerator.ORDER_NONE || '',
      ) as never,
    );
  }
  const expression = tmp.join(' ' + operator + ' ');

  return [' (' + expression + ') ', javascriptGenerator.ORDER_NONE];
};

javascriptGenerator.forBlock['formula_not'] = function (block) {
  const expression = javascriptGenerator.valueToCode(
    block,
    'EXPRESSION',
    javascriptGenerator.ORDER_NONE || '',
  );

  return ['( !' + expression + ' )', javascriptGenerator.ORDER_NONE];
};
