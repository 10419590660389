module.exports = {
  popup_hints: {
    dashboard_list: 'Open list of dashboards',
    dashboard_intervals: 'Set the time period for all charts',
    grid_layout_settings: 'Resize and rearrange charts'
  },
  system : {
    not_owner_only: 'Visible to all users',
    owner_only: 'Visible only to the owner',
    add_new_dashboard: 'Add dashboard',
    new_dashboard_system_name: 'Dashboard %{name}',
    dashboard_empty: 'No charts on the dashboard',
    access_change_owner: 'Authorize to change owner'
  },
  buttons: {
    add_new_graph : 'Add graph',
    add_dashboard: 'Add dashboard',
    be_owner: 'Become a dashboard owner'
  },
  modals: {
    editModal : {
      choose_interval: 'Select the default period',
      warning_text: 'The selected period will be saved and act on the graph regardless of the configured graph period in the report, as well as the selected period on the desktop.',
      save_chart_settings: 'Save',
      add_chart: 'Add',
    },
    create_chart_modal: {
      title: 'Add chart from report'
    },
    delete_modal: {
      main_text: 'Do you really want to delete this dashboard?',
    }
  }
}
