import React, { FC, useState } from 'react';

import useTranslation from '@app/hooks/use-translation';

import { v4 } from 'uuid';

import { TableFilter } from '../table/table.type';

import Empty from '../ui/empty';

import TableSkeleton from '../table/table-skeleton';

import { TableProps } from './table.types';
import { Columns } from './blocks/columns';
import { Rows } from './blocks/rows';

const RubberTable: FC<TableProps> = (props) => {
  const {
    dataSource = [],
    columns,
    onFilter,
    isEmpty,
    isPending,
    skeletons = 6,
    truncate = true,
    withoutCols = false,
  } = props;
  const [filterData, setFilterData] = useState<TableFilter>();
  const { t } = useTranslation('components.empty');
  if (isPending) return <TableSkeleton count={skeletons} />;
  function handlerResetFilters() {
    setFilterData({});
    onFilter?.({});
  }
  function handleOnFilter(titleIndex: string, isFilter: boolean | undefined) {
    if (!isFilter) return;
    if (filterData && filterData[titleIndex] === 1) {
      handlerResetFilters();
      return;
    }
    const updatedFilter: TableFilter = {
      [titleIndex]: filterData?.[titleIndex] !== undefined ? 1 : -1,
    };
    setFilterData(updatedFilter);
    onFilter?.(updatedFilter);
  }

  const renderColumns = (
    <thead className="bg-basic_app_bg">
      <tr className="sticky z-50 top-0 min-w-[80px] truncate bg-basic_app_bg  uppercase ">
        {columns?.map(({ title, filter, index, tag, truncate }) => {
          return (
            <Columns
              index={index}
              title={title}
              filterData={filterData}
              handleOnFilter={handleOnFilter}
              key={index + v4()}
              tag={tag}
              filter={filter}
              truncate={truncate}
            />
          );
        })}
      </tr>
    </thead>
  );

  const renderCells = (
    <tbody className="relative z-30">
      {dataSource.map((row, index) => {
        return (
          <tr key={index + v4()} className={``}>
            {columns?.map((column) => {
              return (
                <Rows
                  row={row}
                  column={column}
                  truncate={typeof column?.truncate === 'undefined' ? truncate : column.truncate}
                  key={index + v4()}
                />
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );

  return (
    <div className={'w-full h-full z-[1] relative'}>
      {dataSource.length !== 0 ? (
        <table
          style={{
            borderSpacing: '0px 2px',
            borderCollapse: 'separate',
            tableLayout: 'fixed',
            width: '100%',
          }}
          align="left"
        >
          <colgroup>
            {columns?.map((e) => {
              return <col key={e.index} width={e.maxWidth || e.minWidth || '100%'} />;
            })}
          </colgroup>
          {!withoutCols && renderColumns}
          {renderCells}
        </table>
      ) : (
        <Empty title={isEmpty || t('title')} />
      )}
    </div>
  );
};

export default RubberTable;
