module.exports = {
  success: {
    title: 'Вы успешно подписаны на получение сообщений от  системы речевой аналитики.',
    description: 'Отписаться от получения сообщений можно перейдя по ссылке внутри каждого сообщения, либо изменив настройки рассылки внутри системы'
  },
  error: {
    title: 'Ошибка при попытке подключится к подписке на получение сообщений от системы речевой аналитики.',
    description: 'Возможно вы уже успешно отписались ранее. Если это не так, повторите попытку подписаться через несколько минут. В случае неудачи - сообщите о проблеме в службу технической поддержки'
  },
  button_label: 'Перейти в Систему',
  report: {
    title: 'Ваш адрес  удален из списка получателей данного отчета.',
    description: 'Вы продолжите получать сообщения из других отчетов, если были подписаны на них.',
    title_err: 'Ошибка при попытке отписать вас от данного отчета.',
    description_err: 'Возможно вы уже успешно отписались ранее. Если это не так, повторите попытку подписаться через несколько минут. В случае неудачи - сообщите о проблеме в службу технической поддержки'
  },
  trigger: {
    title: 'Ваш адрес  удален из списка получателей данного триггера.',
    description: 'Вы продолжите получать сообщения от других триггеров, если были подписаны на них.',
    title_err: 'Ошибка при попытке отписать вас от данного триггера.',
    description_err: 'Возможно вы уже успешно отписались ранее. Если это не так, повторите попытку подписаться через несколько минут. В случае неудачи - сообщите о проблеме в службу технической поддержки'
  },
  all: {
    title: 'Ваш адрес удален из списка получателей для всех рассылок.',
    description: 'Для возобновление подписки на рассылку необходимо заново добавить получателя в Систему.',
    title_err: 'Ошибка при попытке отписать вас от всех рассылок.',
    description_err: 'Возможно вы уже успешно отписались ранее. Если это не так, повторите попытку подписаться через несколько минут. В случае неудачи - сообщите о проблеме в службу технической поддержки'
  },
}