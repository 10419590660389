import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  InformMessagesResponseType,
  MixedMessagesRequestType,
  MixedMessagesType,
} from '@app/interfaces/inform-messages';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { baseQuery } from '../baseQuery';

export const informMessagesApi = createApi({
  reducerPath: 'informMessagesApi',
  tagTypes: ['informMessagesApi'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getInformMessagesFromTgAndEmail: build.query<MixedMessagesType, MixedMessagesRequestType>({
      async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
        const telegramMessages = (await fetchWithBQ({
          url: serverRoutes.telegramMessages,
          params: arg.telegram,
        })) as unknown as { data: InformMessagesResponseType };

        const emailMessages = (await fetchWithBQ({
          url: serverRoutes.emailMessages,
          params: arg.email,
        })) as unknown as { data: InformMessagesResponseType };

        const result: MixedMessagesType = {
          telegram: { ...telegramMessages.data, source: arg.telegram.source },
          email: {
            ...emailMessages.data,
            source: arg.email.source,
          },
        };

        return result ? { data: result } : { error: { error: '123' } as FetchBaseQueryError };
      },
    }),
  }),
});

export const { useGetInformMessagesFromTgAndEmailQuery } = informMessagesApi;
