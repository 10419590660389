module.exports = {
  popup_hints: {
    recog_model_button: 'Select recognition model',
  },
  system: {
    timezone_default: 'By default',
    placeholder: 'Choose'
  },
  create_new_ap_title: 'Create project',
  main_settings_title: 'Main settings',
  recog_model_modal_title: 'List of available recognition models',
  delete_proj: 'Delete the project',
  delete_proj_confirm: 'All analytical project data, including reports, will be deleted.',
  form_fields: {
    proj_name: 'Project name',
    group_proj: 'Projects group',
    proj_shared: 'Access to project',
    timezone: 'Time zone',
    recog_model: 'Recognition Model',
  },
  buttons: {
    create_project: 'Create',
    save_project: 'Save',
    be_owner: 'Become an owner of analytical project',
    delete_project: 'Delete the project',

  }
};
