import React, { FC } from 'react';
import cn from 'classnames';

type DivideProps = {
  size: string | number;
  orientation?: 'vertical' | 'horizontal';
  className?: string;
  span?: number;
};

const Divide: FC<DivideProps> = (props) => {
  const { size, span = 1, orientation = 'vertical', className } = props;
  const formattedSize = typeof size == 'string' ? size : `${size}px`;
  const divideStyle =
    orientation === 'vertical'
      ? { height: formattedSize, width: `${span}px` }
      : { width: formattedSize, height: `${span}px` };
  return <div className={cn('bg-b_dark', className)} style={divideStyle}></div>;
};

export default Divide;
