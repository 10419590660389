import React from 'react';

import { Box, PageHeader } from '@ui';
import { navigationRoutes } from '@app/utils/navigation-routes';
import useTranslation from '@app/hooks/use-translation';

import MainChatSettings from '../main-chat-settings';

const NewChatProjectPage = () => {
  const { t } = useTranslation('pages.mainSettingsAp');
  return (
    <div>
      <PageHeader popLink={`/${navigationRoutes.chatAnalytic}`} label={t('create_new_ap_title')} />
      <Box className="mt-[42px]">
        <MainChatSettings newProjFlag={true} />
      </Box>
    </div>
  );
};

export default NewChatProjectPage;
