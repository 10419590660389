import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import { Box, CircleButton, Divide, DropMenu, Skeleton } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { DropMenuItem } from '@app/components/ui/drop-menu/drop-menu.type';
import {
  ChartTypes,
  ReportChart,
  ReportChartResponse,
  ReportTimeRange,
} from '@app/interfaces/report.type';
import useChartDateIntervals, { ChartOptionDate } from '@app/hooks/use-chart-date-intervals';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { useGetDashboardQuery } from '@app/store/api/dashboard-page.api';
import { useLazyGetGraphDataQuery } from '@app/store/api/graph-data.api';
import { FilterItem } from '@app/interfaces/filter';

import { CheckIcon } from '@icons';

import { TooltipWrapper } from '@ui';

import { useAppDispatch, useAppSelector } from '@app/store/store';

import { RecordRangeRangeParametersItemForRType } from '@app/interfaces/record';

import moment from 'moment';

// import { getBrowserType } from '@app/utils/get-browser-type';

import { ChartsLayoutType } from '@app/interfaces/dashboards.type';

import { Filter, Range } from '@app/components/ui/easy-filter/types';

import { ChartConfig } from '@app/components/chart/chart-native';

import { editSelectedChartRangeDashboard } from '@app/store/reducers/dashboard-charts.slice';

import { editSelectedReportChartRange } from '@app/store/reducers/report-charts.slice';

import { usePermissionCheck } from '@app/hooks/use-permission';

import { userPermissions } from '@app/utils/user-permissions';

import { chartBuildData } from './chart-build.data';
// import ChartNative, { ChartConfig } from './chart-native';

type ChartComponentProps = {
  data: ReportChartResponse['data'];
  chartId?: string;
  dashboard_graph_id?: string;
  dashboard?: boolean;
  commonRange?: ReportTimeRange | null;
  viewOnly?: boolean;
  isDraggable?: boolean;
  layoutSettings: ChartsLayoutType[];
  setOpenedCustomDateModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setMobile?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenRemoveModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setData?: React.Dispatch<React.SetStateAction<ReportChart | undefined>>;
  filterValue?: FilterItem[];
  chartWidth?: number;
  chartHeight?: number;
  currentTemplate: 'chats' | 'chat' | 'voice';
};
const tooltipOffsetX = {
  8: -5,
  7: -10,
  6: -15,
  5: -20,
  4: -25,
  3: -30,
  2: -35,
};
const ChartBlock: FC<ChartComponentProps> = (props) => {
  const {
    data,
    dashboard,
    viewOnly,
    setOpenedCustomDateModal,
    setOpenRemoveModal,
    setMobile,
    setData,
    filterValue,
    chartWidth,
    layoutSettings,
    currentTemplate,
    dashboard_graph_id,
    chartHeight,
  } = props;
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const { chartLoading: chartLoadingId } = useAppSelector((state) => state.reportCharts);
  const { currentData: currentDashboard } = useGetDashboardQuery(
    userSettings?.currentDashboardId || '',
    { skip: !dashboard },
  );
  const chartCanBeEdited = usePermissionCheck({
    tag:
      currentTemplate === 'chats' || currentTemplate === 'chat'
        ? userPermissions.action.actionEditChatLiveReportGraph
        : userPermissions.action.actionEditLiveReportGraph,
    permissions: 'action',
  });
  const chartLoading = useMemo(() => chartLoadingId, [chartLoadingId]);
  const [visibleToolbarState, changeVisibleToolbarState] = useState(false);
  const [getGraphData] = useLazyGetGraphDataQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('pages.chartPage');
  const { daysSelectOptions, dateIntervals, dateToString, dateKeys } = useChartDateIntervals();
  const noDataTextMap: Record<number, string> = useMemo(
    () => ({
      500: t('system.no_metric'),
      403: t('system.no_access'),
    }),
    [t],
  );
  const chartSeriesLength = useMemo(
    () =>
      data.series.reduce<number[]>((acc, item) => {
        acc.push(...item.data);
        return acc;
      }, []).length,
    [data.series],
  );
  const editChartOptions: Array<DropMenuItem> = [
    {
      title: t('drop_menu.edit'),
      icon: 'EditIcon',
      key: 'edit',
      disable: (() => {
        if (!dashboard) {
          if (!chartCanBeEdited) {
            return dashboard && !currentDashboard?.owner;
          } else return true;
        } else return dashboard && !currentDashboard?.owner;
      })(),
    },
    {
      title: t('drop_menu.to_report'),
      icon: 'MetricIcon',
      key: 'to_report',
      disable: !!data.errorStatus,
    },
    {
      content: (
        <div className="flex items-center gap-[5px]">
          <div>{t('drop_menu.toolbar')}</div>
          {visibleToolbarState && (
            <div>
              <CheckIcon className="text-action" size={12} />
            </div>
          )}
        </div>
      ),
      icon: 'ChartIcon',
      disable: !chartSeriesLength,
      key: 'toolbar',
    },
    {
      title: t('drop_menu.remove'),
      color: 'danger',
      icon: 'TrashIcon',
      key: 'remove',
      disable: (() => {
        if (!dashboard) {
          if (!chartCanBeEdited) {
            return dashboard && !currentDashboard?.owner;
          } else return true;
        } else return dashboard && !currentDashboard?.owner;
      })(),
    },
  ];
  const handlerChangeChartDateSelect = useCallback(
    (dateInterval: string) => {
      if (dashboard) {
        if (dateKeys.customDate === dateInterval) {
          setData && setData(data);
          setOpenedCustomDateModal && setOpenedCustomDateModal(true);
        } else {
          getGraphData({
            id: data.graph_id,
            settings: {
              width: data.width,
              range: dateIntervals[dateInterval],
            },
          }).then((Cdata) => {
            const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
            const layout = layoutSettings?.find((layout) => layout.i === dashboard_graph_id);
            dispatch(
              editSelectedChartRangeDashboard({
                layout,
                chartData,
                id: dashboard_graph_id || '',
              }),
            );
          });
        }
      } else {
        if (dateKeys.customDate === dateInterval) {
          setData && setData(data);
          setOpenedCustomDateModal && setOpenedCustomDateModal(true);
        } else {
          getGraphData({
            id: data.graph_id,
            settings: {
              width: data.width,
              range: dateIntervals[dateInterval],
              filter: filterValue,
            },
          }).then((Cdata) => {
            if (!Cdata.data) return;
            const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
            const layout = layoutSettings.find((layout) => layout.i === chartData.graph_id);
            dispatch(
              editSelectedReportChartRange({
                layout,
                chartData,
                id: chartData.graph_id,
              }),
            );
          });
        }
      }
    },
    [
      dashboard,
      dashboard_graph_id,
      data,
      dateIntervals,
      dateKeys.customDate,
      dispatch,
      filterValue,
      getGraphData,
      layoutSettings,
      setData,
      setOpenedCustomDateModal,
    ],
  );
  function handleEditChart({ key }: { key: string }) {
    switch (key) {
      case 'edit': {
        setData && setData(data);
        setMobile && setMobile(true);
        break;
      }
      case 'toolbar': {
        changeVisibleToolbarState((prev) => !prev);
        break;
      }
      case 'remove': {
        setData && setData(data);
        setOpenRemoveModal && setOpenRemoveModal(true);
        break;
      }
      case 'to_report': {
        currentTemplate === 'chats' || currentTemplate === 'chat'
          ? navigate(`/${navigationRoutes.chatReports}/${data.live_report_id}`)
          : navigate(`/${navigationRoutes.reports}/${data.live_report_id}`);
        break;
      }
    }
  }

  const onChartPointHandleClick = useCallback(
    ({
      seriesIndex,
      dataPointIndex,
    }: {
      seriesIndex: number;
      dataPointIndex: number;
      config: ChartConfig;
    }) => {
      const filterParams: Filter & Range = {
        range: {
          type: 'n',
          parameters: [],
        },
        filter: [],
      };
      const seriesMetrics = data.series[seriesIndex]?.metric_id;
      const seriesAggregate = data.series[seriesIndex]?.aggregate;
      // const seriesMetrics = data.series.map((data) => data.name);
      const chartGroupType = data.group.type;
      // const currentSeriesPoint = data.series[seriesIndex].data[dataPointIndex];
      const currentCategoryPoint =
        chartGroupType === 'metric'
          ? data.categories[dataPointIndex]
          : Object.values(data.system_date_diapason?.[dataPointIndex] || {});

      switch (chartGroupType) {
        case 'date':
          filterParams.range.type = 'r';
          for (let i = 0; i < currentCategoryPoint.length; i++) {
            filterParams.range.parameters.push({
              value: currentCategoryPoint[i],
              condition: i === 0 ? '>=' : '<=',
            });
          }
          break;
        case 'metric':
          filterParams.filter.push({
            id: data.group.metric_id || '',
            value: currentCategoryPoint,
            condition: '=',
          });
          break;
        default:
          break;
      }
      localStorage.setItem(
        'filterFromChart',
        JSON.stringify({
          filter: filterParams,
          series: { metric_id: seriesMetrics, aggregate: seriesAggregate },
        }),
      );
      window.open(
        `/${
          currentTemplate === 'chats' || currentTemplate === 'chat'
            ? navigationRoutes.chatReports
            : navigationRoutes.reports
        }/${data.live_report_id}/${navigationRoutes.reportRecords}`,
        '_blank',
      );
    },
    [
      currentTemplate,
      data.categories,
      data.group.metric_id,
      data.group.type,
      data.live_report_id,
      data.series,
      data.system_date_diapason,
    ],
  );

  const currentChartBuild = useMemo(
    () =>
      chartBuildData({
        chart: data,
        showToolbar: visibleToolbarState,
        tooltipDynamicOffset: tooltipOffsetX[Math.floor((chartWidth as number) / 100)] || 0,
        noDataText: data.errorStatus
          ? { text: noDataTextMap[data.errorStatus] }
          : { text: t('system.no_data') },
        chartWidth,
        events: {
          markerClick: (_, __, chartPointInfo) => {
            if (
              !(chartPointInfo as unknown as any).w.config.chart.toolbar.tools.pan &&
              localStorage.getItem('layoutDrag') !== 'true'
            ) {
              onChartPointHandleClick(chartPointInfo);
            }
          },
          dataPointSelection: (_, __, chartPointInfo) => {
            if (
              !(chartPointInfo as unknown as any).w.config.chart.toolbar.tools.pan &&
              localStorage.getItem('layoutDrag') !== 'true'
            ) {
              onChartPointHandleClick(chartPointInfo);
            }
          },
        },
        translationList: {
          download: t('chart_toolbar.download'),
          selectionZoom: t('chart_toolbar.selectionZoom'),
          zoomIn: t('chart_toolbar.zoomIn'),
          zoomOut: t('chart_toolbar.zoomOut'),
          pan: t('chart_toolbar.pan'),
          reset: t('chart_toolbar.reset'),
        },
      }),
    [chartWidth, data, noDataTextMap, onChartPointHandleClick, t, visibleToolbarState],
  );
  const range = useMemo(() => {
    return [
      ...(data.range?.parameters as unknown as RecordRangeRangeParametersItemForRType[]),
    ].sort((a, b) => {
      if (a.condition > b.condition) {
        return -1;
      }
      if (a.condition < b.condition) {
        return 1;
      }
      return 0;
    });
  }, [data.range?.parameters]);

  const rangeMarkup: Record<ChartOptionDate, ReactNode> = {
    all: <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'all')?.title}</div>,
    day: <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'day')?.title}</div>,
    week: <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'week')?.title}</div>,
    month: <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'month')?.title}</div>,
    today: <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'today')?.title}</div>,
    currentWeek: (
      <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'currentWeek')?.title}</div>
    ),
    currentMonth: (
      <div>
        {daysSelectOptions.find((daysOptions) => daysOptions.key === 'currentMonth')?.title}
      </div>
    ),
    customDate: (
      <div className="flex items-center gap-[10px]">
        <div className="flex items-center gap-[5px]">
          {range.map((rangeItem, index) => (
            <div key={index}>
              {rangeItem.condition === '>'
                ? t('interval_under_label.from')
                : t('interval_under_label.to')}{' '}
              &nbsp;
              {moment(rangeItem.value).format('DD.MM.YYYY  HH:mm')}
            </div>
          ))}
        </div>
      </div>
    ),
  };
  const chartRender = useMemo(
    () => (
      <ReactApexChart
        key={currentChartBuild.options.chart.id}
        options={currentChartBuild?.options as unknown as ApexOptions}
        series={currentChartBuild?.series}
        width="100%"
        height="100%"
        type={(currentChartBuild?.options?.chart?.type as ChartTypes) || 'bar'}
      />
    ),
    [currentChartBuild.options, currentChartBuild?.series],
  );
  if (!dashboard && chartLoading === data.graph_id)
    return <Skeleton width={chartWidth} height={chartHeight} />;
  if (!data) return null;
  return (
    <Box className={`${data.errorStatus && '!bg-basic_red/[.4]'} min-w-full h-full pt-4 pb-16`}>
      <div className="h-full">
        <div className="flex items-start justify-between pl-[20px] w-full">
          <div className="flex flex-col w-full">
            {/**/}
            <div className="flex items-center justify-between w-full">
              <div
                style={{
                  maxWidth: visibleToolbarState
                    ? (chartWidth || 0) - 210
                    : (chartWidth || 0) - 100 + 'px',
                }}
              >
                <TooltipWrapper content={data?.name} id={data?.name + data.graph_id}>
                  {/*todo header font size escape */}
                  <h2 style={{ fontSize: 18 + 'px' }} className=" truncate text-1color font-[700]">
                    {data?.name}
                  </h2>
                </TooltipWrapper>
              </div>
              {(chartWidth || 0) > 120 && (
                <div className="flex items-center z-[1] ">
                  {!viewOnly && (
                    <div className="flex items-center noDrag mr-[15px]">
                      <Divide
                        size={15}
                        span={1}
                        orientation="vertical"
                        className="ml-[16px] mr-[5px]"
                      />
                      <DropMenu
                        dropSize={210}
                        onClick={(key) => handleEditChart({ key })}
                        hintTitle={t('menu')}
                        menu={
                          !dashboard
                            ? editChartOptions.filter((item) => item.key !== 'to_report')
                            : editChartOptions
                        }
                      >
                        <CircleButton icon="VerticalDotsIcon" size={12} className="text-3color" />
                      </DropMenu>
                    </div>
                  )}
                </div>
              )}
            </div>
            {/*/*/}
            {data && (chartWidth || 0) > 230 ? (
              <div className="h-full w-full">
                <DropMenu
                  dropHeight={
                    (chartHeight || 0) - 100 > 70
                      ? (chartHeight || 0) > 350
                        ? 280
                        : (chartHeight || 0) - 100
                      : 55
                  }
                  dropSize={210}
                  onClick={(key) => handlerChangeChartDateSelect(key)}
                  menu={daysSelectOptions.map((item) => ({
                    ...item,
                    isActive: dateToString(data?.range) === item.key,
                  }))}
                  position="start"
                >
                  <div className="noDrag text-4color font-[400] hover:underline  leading-[10px] tracking-tight cursor-pointer text-[12px]">
                    {rangeMarkup[dateToString(data?.range)]}
                  </div>
                </DropMenu>
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-[95%] h-[99%]">
          <div className="h-full w-full">{chartRender}</div>
        </div>
        {/*<div className="w-full h-[50px]"></div>*/}
      </div>
    </Box>
  );
};

export default React.memo(ChartBlock);
