import React from 'react';
import { Skeleton, SkeletonPageLoading } from '@ui';

const WordsCloudSkeleton = () => {
  return (
    <div>
      <SkeletonPageLoading />
      <div className="pb-[20px] w-full flex gap-[10px]">
        <Skeleton height={450} width={'70%'} />
        <Skeleton height={550} width={'30%'} />
      </div>
    </div>
  );
};

export default WordsCloudSkeleton;
