const rtk = require('./rtk.theme');
const generic = require('./default.theme');
const calltraffic = require('./calltraffic.theme');
const themesList = {
  rtk,
  generic,
  calltraffic,
};
const currentTheme =
  process.env.REACT_APP_THEME &&
  Object.prototype.hasOwnProperty.call(themesList, process.env.REACT_APP_THEME)
    ? themesList[process.env.REACT_APP_THEME]
    : themesList['generic'];
module.exports = currentTheme;
