import React, { FC } from 'react';

import { IconType } from '../icon.type';

const QuestionmarkAppIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_468)">
          <path
            d="M21.1191 6.5791C20.2139 5.67383 18.9658 5.49805 17.3662 5.49805H10.5986C9.02539 5.49805 7.76855 5.68262 6.87207 6.5791C5.9668 7.48438 5.7998 8.72363 5.7998 10.3057V17.0117C5.7998 18.6113 5.9668 19.8506 6.86328 20.7559C7.77734 21.6523 9.02539 21.8281 10.625 21.8281H17.3662C18.9658 21.8281 20.2139 21.6523 21.1191 20.7559C22.0244 19.8506 22.1914 18.6113 22.1914 17.0117V10.3232C22.1914 8.72363 22.0244 7.47559 21.1191 6.5791ZM20.4688 10.0859V17.249C20.4688 18.1631 20.3457 19.0068 19.8623 19.4902C19.3701 19.9824 18.5176 20.1055 17.6123 20.1055H10.3789C9.47363 20.1055 8.62109 19.9824 8.12891 19.4902C7.63672 19.0068 7.52246 18.1631 7.52246 17.249V10.1035C7.52246 9.17188 7.63672 8.32812 8.12012 7.83594C8.6123 7.34375 9.47363 7.2207 10.3965 7.2207H17.6123C18.5176 7.2207 19.3701 7.35254 19.8623 7.83594C20.3545 8.32812 20.4688 9.17188 20.4688 10.0859ZM13.8066 15.4033C14.29 15.4033 14.5977 15.1309 14.6152 14.7705C14.624 14.7354 14.624 14.6914 14.624 14.665C14.6504 14.208 14.958 13.9004 15.5293 13.5312C16.3994 12.96 16.9619 12.459 16.9619 11.4395C16.9619 9.97168 15.6436 9.13672 14.0879 9.13672C12.5762 9.13672 11.5566 9.82227 11.2842 10.6484C11.2314 10.7979 11.2051 10.9473 11.2051 11.1055C11.2051 11.5273 11.5303 11.791 11.8906 11.791C12.207 11.791 12.4268 11.6592 12.6025 11.4219L12.7344 11.2461C13.0332 10.7627 13.4551 10.5078 13.9824 10.5078C14.6943 10.5078 15.1689 10.9297 15.1689 11.5273C15.1689 12.0811 14.7998 12.3535 14.0527 12.8721C13.4287 13.3027 12.9805 13.7598 12.9805 14.5596V14.6562C12.9805 15.1484 13.2705 15.4033 13.8066 15.4033ZM13.7891 18.1016C14.3516 18.1016 14.8174 17.6885 14.8174 17.1348C14.8174 16.5811 14.3604 16.1768 13.7891 16.1768C13.2266 16.1768 12.7607 16.5898 12.7607 17.1348C12.7607 17.6885 13.2354 18.1016 13.7891 18.1016Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_468">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default QuestionmarkAppIcon;
