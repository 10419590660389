import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Modals } from '@app/pages/analytics/metric/analytics-metric-tab/blocks/modals';
import { Button, EditText, PageHeader } from '@app/components/ui';
import AccordionItemUI from '@app/components/ui/Accordion/AccordionItem';
import AccordionUI from '@app/components/ui/Accordion/Accordion';
import { ColsWithoutTable } from '@app/components/rubber-table/blocks/cols-without-table';
import useTranslation from '@app/hooks/use-translation';
import { AccordionItemUIPropsClickChildParams } from '@app/interfaces/userInterface';
import {
  useCreateMetricTemplateGroupMutation,
  useGetMetricTemplateGroupsListQuery,
  useLazyGetSingleMetricTemplateQuery,
} from '@app/store/api/metric-template.api';
import { useGetChannelsQuery } from '@app/store/api/get-channels-info.api';
import { useGetMetricTypesListQuery } from '@app/store/api/metrics.api';
import { useGetAllDictionariesListQuery } from '@app/store/api/dictionaries.api';
import { useAppSelector } from '@app/store/store';
import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';

import { useGetChatMetricTypesListQuery } from '@app/store/api/chat-api/chat-metric.api';

import { useGetChatRolesQuery } from '@app/store/api/chat-api/get-chat-roles.api';

import { AccordionTitle } from './blocks/accordion-title';
import { MetricTemplateTable } from './blocks/container';
import MetricTemplateSkeleton from './blocks/metric-template.skeleton';

export const MetricTemplates: React.FC<{ currentTemplate: 'chat' | 'voice' }> = ({
  currentTemplate,
}) => {
  const { t } = useTranslation('pages.metricTemplates');
  //useState
  const [edit, setEdit] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [groupName, setNewGroupName] = useState('');
  const [activeModal, setActiveModal] = useState<string>('');
  const [editModal, setEditModal] = useState(false);
  const [createMetric, setCreateMetric] = useState(false);
  //api
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const { data: dictionaries, isLoading: dictionariesLoading } = useGetAllDictionariesListQuery();
  const { data: chatMetricTypes, isLoading: chatMetricTypesLoading } =
    useGetChatMetricTypesListQuery(undefined, { skip: currentTemplate === 'voice' });
  const { data: voiceMetricTypes, isLoading: metricTypesLoading } = useGetMetricTypesListQuery(
    undefined,
    {
      skip: currentTemplate === 'chat',
    },
  );
  const { data: chatRoles, isLoading: chatRolesLoading } = useGetChatRolesQuery(undefined, {
    skip: currentTemplate === 'voice',
  });
  const { data: allChannels, isLoading: channelsLoading } = useGetChannelsQuery();
  const { data: metricTemplates, isLoading } = useGetMetricTemplateGroupsListQuery(currentTemplate);
  const [createMetricTemplate] = useCreateMetricTemplateGroupMutation();
  const [getSingleMetricTemplate, { currentData }] = useLazyGetSingleMetricTemplateQuery();

  const metricTypes = {
    chat: chatMetricTypes,
    voice: voiceMetricTypes,
  };
  // const metricList = {
  //   chat: metricListChat,
  //   voice: metricListVoice,
  // };
  const onClickCreateModal = () => {
    setEditModal(false);
    setCreateMetric(true);
  };
  function getMetricTemplateSettingsHandler(metric_id: string, type: string, mode: string) {
    getSingleMetricTemplate({ id: metric_id }).then(() => {
      if (mode === 'copy') {
        setCopyModal(true);
        setEditModal(true);
      } else {
        setCopyModal(false);
        setEditModal(true);
      }
      setActiveModal(type);
    });
  }
  // React.useEffect(() => {
  //   dispatch(getMetricTemplatesThunks.getAllMetricTemplatesThunk());
  //   dispatch(getMetricThunks.getAllTypesOfMetricThunk());
  //   dispatch(getMetricThunks.getAllChannels());
  //   dispatch(getPresetsThunks.getAliasesThunk());
  // }, [dispatch]);

  const createMetricGroup = (inputValue: string) => {
    setNewGroupName(inputValue);
    if (inputValue !== '') {
      createMetricTemplate({ name: inputValue }).then(() => {
        setNewGroupName('');
      });
    } else {
      toast.error(t('cant_create_group'));
    }
    setEdit(false);
  };

  const [activeAccordionItem, setActiveAccordionItem] = React.useState<Array<string | undefined>>(
    userSettings?.accordions?.metricTemplates || [],
  );

  useEffect(() => {
    const accordionListFromStorage = userSettings?.accordions?.metricTemplates;
    if (!accordionListFromStorage?.length) {
      const defaultGroup = metricTemplates?.find(
        (group) => group.is_default === true,
      )?.metric_templates_group_id;
      setActiveAccordionItem([defaultGroup]);
      defaultGroup &&
        updateUserSettings({
          ...userSettings,
          accordions: {
            ...userSettings?.accordions,
            metricTemplates: [defaultGroup],
          },
        });
    }
  }, [metricTemplates, updateUserSettings, userSettings]);

  useEffect(() => {
    setActiveAccordionItem(userSettings?.accordions?.metricTemplates || []);
  }, [userSettings?.accordions?.metricTemplates]);
  const clickAccordionHeaderBtn = (event: AccordionItemUIPropsClickChildParams) => {
    setActiveAccordionItem((prev) => {
      if (!prev.includes(event.id)) {
        userSettings?.accordions?.metricTemplates &&
          updateUserSettings({
            ...userSettings,
            accordions: {
              ...userSettings?.accordions,
              metricTemplates: [...(userSettings?.accordions?.metricTemplates || []), event.id],
            },
          });
        return [...prev, event.id];
      } else {
        const accordionListFromStorage = userSettings?.accordions?.metricTemplates;
        const filteredAccordionListFromStorage = accordionListFromStorage?.filter(
          (item) => item !== event.id,
        ) as string[];
        updateUserSettings({
          ...userSettings,
          accordions: {
            ...userSettings?.accordions,
            metricTemplates: filteredAccordionListFromStorage,
          },
        });

        return prev.filter((item) => item !== event.id);
      }
    });
  };

  const columns = [
    {
      title: t('table.name'),
      index: 'name',
    },
    {
      title: t('table.type'),
      index: 'type',
    },
    {
      title: t('table.channel'),
      index: 'channel',
      maxWidth: 130,
    },
    {
      title: t('table.settings'),
      index: 'metricSettings',
    },
    {
      title: t('table.actions'),
      index: 'utils',
      maxWidth: 150,
    },
  ];
  if (
    isLoading ||
    channelsLoading ||
    metricTypesLoading ||
    dictionariesLoading ||
    chatMetricTypesLoading ||
    chatRolesLoading
  )
    return <MetricTemplateSkeleton />;
  return (
    <>
      <Modals
        currentTemplate={currentTemplate}
        copyModal={copyModal}
        page={'metricTemplate'}
        metricTypes={metricTypes[currentTemplate || 'voice']}
        allChannels={allChannels}
        setEditModal={setEditModal}
        editModal={editModal}
        setFirstModal={setCreateMetric}
        firstModal={createMetric}
        activeModal={activeModal}
        chatRoles={chatRoles}
        setActiveModal={setActiveModal}
        metricTemplateSettings={currentData}
      />
      <PageHeader icon="TemplateListIcon" label={t('metric_templates')}>
        <Button
          data-id="new-metric-template"
          label={t('add_metric_template')}
          icon="CirclePlusIcon"
          onClick={() => onClickCreateModal()}
        />
      </PageHeader>
      <div>
        <ColsWithoutTable columns={columns} />
        <div className={'flex flex-col gap-[20px]'}>
          <AccordionUI>
            {metricTemplates?.map((group) => {
              return (
                <AccordionItemUI
                  variant="filled"
                  dataId={`metric-group-${group.name}`}
                  key={group.metric_templates_group_id}
                  id={group.metric_templates_group_id}
                  disabled={
                    activeAccordionItem.length === 1
                      ? activeAccordionItem[0] === group.metric_templates_group_id
                      : false
                  }
                  title={
                    <AccordionTitle
                      setActiveAccordionItem={setActiveAccordionItem}
                      isDefault={group.is_default}
                      name={group.name}
                      active={activeAccordionItem.includes(group.metric_templates_group_id)}
                      templatesCount={group?.metric_templates?.length}
                      metric_templates_group_id={group.metric_templates_group_id}
                    />
                  }
                  clickBtnChild={clickAccordionHeaderBtn}
                  active={activeAccordionItem.includes(group.metric_templates_group_id)}
                >
                  {activeAccordionItem.includes(group.metric_templates_group_id) && (
                    <MetricTemplateTable
                      // loading={loading}
                      chatRoles={chatRoles}
                      groupName={group.name}
                      getMetricTemplateSettingsHandler={getMetricTemplateSettingsHandler}
                      data={group.metric_templates}
                      allChannels={allChannels}
                      metricTypes={metricTypes[currentTemplate || 'voice']}
                      dictionaries={dictionaries}
                    />
                  )}
                </AccordionItemUI>
              );
            })}
            {edit && (
              <AccordionItemUI
                title={
                  <div className="max-w-[240px]">
                    <EditText
                      textStyle="font-[700] text-[13px] text-2color leading-[14px]"
                      editIconSize={14}
                      text={groupName}
                      onEdited={createMetricGroup}
                      dataId="new-metric-group-name"
                      dataIdSubmit="new-metric-group-name-submit"
                      defaultOpen
                    />
                  </div>
                }
                id={''}
                variant="filled"
              >
                {''}
              </AccordionItemUI>
            )}
          </AccordionUI>
          <div className="">
            <Button
              data-id="new-group"
              icon="CirclePlusIcon"
              label={t('new_group')}
              onClick={() => setEdit(!edit)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
