module.exports = {
  module_name: 'Облако фраз',
  choose_project: 'Выберите отчет',
  select_all_projects: 'Все отчеты',
  error_page: 'В проекте нет созданных отчетов',
  calculate_button_tooltip: 'Расчёт облака может занять несколько минут',
  part_of_speech_data: {
    all: 'Все части речи',
    NOUN: 'Существительное',
    VERB: 'Глагол',
    ADJ: 'Прилагательное',
    ADVB: 'Наречие',
    NUMR: 'Числительное',
    NPRO: 'Местоимение',
    CONJ: 'Предлоги/союзы/частицы',
  },
  startpoint_values: {
    after_phrase: 'После фразы со словом',
    before_phrase: 'До фразы со словом',
    begin: 'От начала диалога',
    end: 'В конце диалога'
  },
  form: {
    additionalSettings: 'Дополнительные настройки',
    channel:'В каналах: ',
    fields_labels: {
      search_area_number_phrases: 'фразы',
      in_channels_find: 'Искать в каналах',
      startpoint_type: 'Область поиска',
      startpoint_channels: 'Фразы со словом в каналах',
      exclude_from_search: 'Исключить из поиска',
      exclude_first: 'первые',
      exclude_last: 'последние фразы',
      all_forms: 'Все словоформы',
      stopwords: 'Исключить фразы со словом',
    },
    fields_placeholders: {
      filter_input: 'Отобразить фразы со словом'
    }
  },
  occurrence: 'Встречаемость',
  all_channels_option: 'Все каналы',
  first_phrases_count: 'Колличество первых фраз',
  change_cloud_settings: 'Изменить настройки облака',
  start_cloud_calculate: 'Запустить расчет облака',
  filter_error_input_message: 'Разрешено одно или два слова',
  apply_button: 'Применить',
  // placeholders
  channel_select_placeholder: 'Канал',
  //
  word: 'слово',
  meets: 'встречается',
  cloud_deep: 'Отобразить фраз в облаке:',
  system: {
    cloud_is_empty: 'Отсутствует ранее рассчитанное облако фраз, необходимо указать настройки и запустить расчет.',
    cloud_is_empty_by_request: 'В облаке фраз отсутствуют фразы с данным словом.',
    cloud_is_calculating: 'Производится расчет облака.Расчет может занять несколько минут.После окончания расчета результат отобразится на данной странице.Вы можете оставаться на странице до окончания расчета либо вернуться в данный раздел позже',
  }
};
