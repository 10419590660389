import React, { FC } from 'react';

import { IconType } from '../icon.type';

const HeartSquareIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_605_1164)">
          <path
            d="M8.67383 21.8721H19.3174C21.2158 21.8721 22.2002 20.8877 22.2002 19.0156V8.31934C22.2002 6.43848 21.2158 5.46289 19.3174 5.46289H8.67383C6.77539 5.46289 5.79102 6.43848 5.79102 8.31934V19.0156C5.79102 20.8965 6.77539 21.8721 8.67383 21.8721ZM8.7793 20.1494C7.96191 20.1494 7.51367 19.7188 7.51367 18.8662V8.45996C7.51367 7.60742 7.96191 7.18555 8.7793 7.18555H19.2119C20.0205 7.18555 20.4775 7.60742 20.4775 8.45996V18.8662C20.4775 19.7188 20.0205 20.1494 19.2119 20.1494H8.7793ZM11.9258 9.83984C10.4756 9.83984 9.40332 10.9473 9.40332 12.5029C9.40332 14.8936 11.9873 16.9766 13.5518 17.9609C13.7012 18.0488 13.9033 18.1631 14.0264 18.1631C14.1406 18.1631 14.3252 18.0488 14.4658 17.9609C16.0127 16.959 18.6143 14.8936 18.6143 12.5029C18.6143 10.9473 17.5332 9.83984 16.083 9.83984C15.1514 9.83984 14.4219 10.3848 14.0088 11.1406C13.5869 10.3848 12.875 9.83984 11.9258 9.83984Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_605_1164">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default HeartSquareIcon;
