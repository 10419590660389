import React, { FC } from 'react';

import { IconType } from '../icon.type';

const BriefcaseCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_609_89)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM12.2686 10.0859C12.2686 9.89258 12.4004 9.76074 12.6113 9.76074H15.3975C15.6084 9.76074 15.7402 9.89258 15.7402 10.0859V10.5078H12.2686V10.0859ZM14 13.0918C15.7051 13.0918 17.3047 12.8457 18.8867 12.1865V11.8789C18.8867 10.9648 18.4297 10.5078 17.5068 10.5078H16.6367V10.0508C16.6367 9.35645 16.1885 8.94336 15.4502 8.94336H12.5498C11.8115 8.94336 11.3633 9.35645 11.3633 10.0508V10.5078H10.4932C9.5791 10.5078 9.11328 10.9648 9.11328 11.8789V12.1865C10.7041 12.8457 12.2949 13.0918 14 13.0918ZM10.4932 17.4775H17.5068C18.4297 17.4775 18.8867 17.0205 18.8867 16.1064V13.0215C17.5859 13.4961 16.3994 13.7158 15.1953 13.8037V14.1465C15.1953 14.4717 15.002 14.6475 14.6855 14.6475H13.3232C13.0068 14.6475 12.8135 14.4717 12.8135 14.1465V13.8037C11.6006 13.7158 10.4141 13.4961 9.11328 13.0215V16.1064C9.11328 17.0205 9.5791 17.4775 10.4932 17.4775Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_609_89">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default BriefcaseCircleIcon;
