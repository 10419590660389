import React from 'react';
import { Route } from 'react-router-dom';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { CommonSettingsPage, SettingsOutlet } from '@app/pages';
import RequiredAuth from '@app/routes/required-auth.route';
import { BreadcrumbsItem } from '@app/layouts/layout';
import {
  AliasSettings,
  IntegrationSettingsPage,
  RecognitionRulesSettings,
  InformMessagesPage,
  MessagesManagingPage,
} from '@app/pages/settings';

import { userPermissions } from '@app/utils/user-permissions';

import RequiredPermission from './required-permission.route';
const routesList = [
  {
    link: navigationRoutes.settingsCommon,
    tag: userPermissions.display.displayChannels,
    element: <CommonSettingsPage />,
    crumb: <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
  },
  {
    link: navigationRoutes.settingsAlias,
    tag: userPermissions.display.displayAliases,
    element: <AliasSettings />,
    crumb: <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
  },
  {
    link: navigationRoutes.settingsRecognitionRules,
    tag: userPermissions.display.displayRecognizeRules,
    element: <RecognitionRulesSettings />,
    crumb: <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
  },
  {
    link: navigationRoutes.integration,
    tag: userPermissions.display.displayIntegrationAuth,
    element: <IntegrationSettingsPage />,
    crumb: <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
  },
  {
    link: navigationRoutes.messagesManaging,
    tag: userPermissions.display.displaySender,
    element: <MessagesManagingPage />,
    crumb: <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
  },
  {
    link: navigationRoutes.messagesHistory,
    tag: userPermissions.display.displaySender,
    element: <InformMessagesPage />,
    crumb: <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
  },
];

export const settingsRoute = (
  <Route
    path={navigationRoutes.settings}
    element={
      <RequiredAuth>
        <RequiredPermission tag={userPermissions.display.displaySettings}>
          <SettingsOutlet />
        </RequiredPermission>
      </RequiredAuth>
    }
  >
    {routesList.map(({ element, link, crumb, tag }, index) => {
      // function check(tag?: string) {
      //   const permissions = 'display';
      //   if (!tag) return false;
      //   if (!displayPermissions?.permissions?.[permissions]) return true;
      //   if (!displayPermissions?.permissions?.[permissions][tag]) return true;
      //   return !displayPermissions?.permissions?.[permissions][tag];
      // }
      return (
        <Route
          key={index + '_' + link}
          path={link}
          element={
            <RequiredAuth>
              <RequiredPermission tag={tag} permissions="display">
                {element}
              </RequiredPermission>
            </RequiredAuth>
          }
          handle={{
            crumb: () => crumb,
          }}
        />
      );
    })}
    {/* <Route
      index
      element={
        <RequiredAuth>
          <CommonSettingsPage />
        </RequiredAuth>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
      }}
    />
    <Route
      path={navigationRoutes.settingsAlias}
      element={
        <RequiredAuth>
          <AliasSettings />
        </RequiredAuth>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
      }}
    />
    <Route
      path={navigationRoutes.settingsRecognitionRules}
      element={
        <RequiredAuth>
          <RecognitionRulesSettings />
        </RequiredAuth>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
      }}
    />
    <Route
      path={navigationRoutes.integration}
      element={
        <RequiredAuth>
          <IntegrationSettingsPage />
        </RequiredAuth>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
      }}
    />
    <Route
      path={navigationRoutes.messagesHistory}
      element={
        <RequiredAuth>
          <InformMessagesPage />
        </RequiredAuth>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
      }}
    />
    <Route
      path={navigationRoutes.messagesManaging}
      element={
        <RequiredAuth>
          <MessagesManagingPage />
        </RequiredAuth>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
      }}
    /> */}
  </Route>
);
