import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const Character: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 57 57"
      >
        <circle cx="28.5" cy="28.5" r="28.5" fill="color(display-p3 0.9338 0.9781 0.9917)"></circle>
        <path
          fill="color(display-p3 0.1600 0.5312 0.8000)"
          fillRule="evenodd"
          d="M20.803 15h12.396a6.853 6.853 0 016.8 6.903v8.381a6.853 6.853 0 01-6.802 6.903H20.803L14 42.444V21.903A6.853 6.853 0 0120.803 15z"
          clipRule="evenodd"
        ></path>
        <path
          fill="color(display-p3 0.1600 0.5312 0.8000)"
          d="M20.803 15v-.65h-.005l.005.65zm12.396 0l.005-.65h-.005V15zm6.8 6.903l-.65-.005v.005H40zm0 8.381h-.65v.005l.65-.005zm-1.97 4.86l-.464-.456.463.456zm-4.832 2.043v.65h.005l-.005-.65zm-12.394 0v-.65a.65.65 0 00-.398.136l.398.514zM14 42.444h-.65a.65.65 0 001.048.514L14 42.444zm0-20.541h.65v-.005l-.65.005zm1.972-4.86l-.463-.457.463.457zm4.83-1.393H33.2v-1.3H20.803v1.3zm12.392 0a6.203 6.203 0 016.156 6.248l1.3.01a7.503 7.503 0 00-7.446-7.558l-.01 1.3zm6.156 6.253v8.381h1.3v-8.381h-1.3zm0 8.386a6.203 6.203 0 01-1.785 4.4l.926.912a7.503 7.503 0 002.159-5.322l-1.3.01zm-1.785 4.4a6.203 6.203 0 01-4.372 1.848l.009 1.3a7.503 7.503 0 005.29-2.236l-.927-.913zm-4.368 1.848H20.803v1.3h12.394v-1.3zm-12.792.136l-6.802 5.257.795 1.028 6.802-5.257-.795-1.028zm-5.755 5.771V21.903h-1.3v20.541h1.3zm0-20.546a6.202 6.202 0 011.785-4.4l-.926-.912a7.503 7.503 0 00-2.159 5.322l1.3-.01zm1.785-4.4a6.203 6.203 0 014.373-1.848l-.01-1.3a7.503 7.503 0 00-5.29 2.236l.927.913z"
        ></path>
        <path
          fill="color(display-p3 0.9333 0.9765 0.9922)"
          d="M30.388 30.889l-.82-2.803h-3.774l-.82 2.803h-2.307l3.73-11.556h2.643l3.738 11.556h-2.39zm-2.733-9.345l-1.377 4.732h2.807l-1.378-4.732h-.052z"
        ></path>
        <circle cx="38.5" cy="36.5" r="5.5" fill="color(display-p3 0.9333 0.9765 0.9922)"></circle>
        <path
          fill="color(display-p3 0.1600 0.5312 0.8000)"
          d="M43.11 42.091a.888.888 0 01-.597-.257l-1.366-1.591a.725.725 0 01-.054-1.017.656.656 0 01.931 0l1.719 1.37a.899.899 0 01-.597 1.525l-.036-.03z"
        ></path>
        <path
          stroke="color(display-p3 0.1600 0.5312 0.8000)"
          strokeMiterlimit="10"
          d="M38.633 40.498a3.849 3.849 0 100-7.698 3.849 3.849 0 000 7.698z"
        ></path>
      </svg>
    </div>
  );
};

export default Character;
