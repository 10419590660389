import React, { FC } from 'react';

import { IconType } from '../icon.type';

const CreditcardCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_614_1969)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM9.03418 11.7646H18.957V11.3955C18.957 10.499 18.4912 10.0332 17.5859 10.0332H10.3965C9.5 10.0332 9.03418 10.499 9.03418 11.3955V11.7646ZM10.3965 17.2842H17.5859C18.4912 17.2842 18.957 16.8271 18.957 15.9307V12.749H9.03418V15.9307C9.03418 16.8271 9.5 17.2842 10.3965 17.2842ZM10.7832 15.957C10.5371 15.957 10.3701 15.79 10.3701 15.5527V14.7793C10.3701 14.542 10.5371 14.375 10.7832 14.375H11.8027C12.0488 14.375 12.2158 14.542 12.2158 14.7793V15.5527C12.2158 15.79 12.0488 15.957 11.8027 15.957H10.7832Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_614_1969">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CreditcardCircleIcon;
