import React, { FC } from 'react';

import { IconType } from '../icon.type';

const BellBadgeCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_606_10)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM10.2031 16.7393H17.7969C18.2715 16.7393 18.5615 16.4844 18.5615 16.0977C18.5615 15.5967 18.1045 15.1748 17.6738 14.7529C17.3486 14.4365 17.2783 13.7949 17.2344 13.2324C17.2256 12.9424 17.208 12.6699 17.1729 12.3975C16.9268 12.5117 16.6543 12.5645 16.373 12.5645C15.1162 12.5645 14.0615 11.5273 14.0615 10.2617C14.0615 9.67285 14.2812 9.1543 14.6504 8.75C14.457 8.64453 14.2373 8.58301 14 8.58301C13.3057 8.58301 12.7959 9.06641 12.5938 9.69043C11.3633 10.1387 10.8447 11.3691 10.7832 13.127C10.7305 13.7422 10.6602 14.4277 10.3262 14.7529C9.9043 15.1836 9.43848 15.5967 9.43848 16.0977C9.43848 16.4844 9.72852 16.7393 10.2031 16.7393ZM16.373 11.9053C17.2607 11.9053 18.0078 11.167 18.0078 10.2617C18.0078 9.35645 17.2607 8.62695 16.373 8.62695C15.4678 8.62695 14.7207 9.35645 14.7207 10.2617C14.7207 11.167 15.4678 11.9053 16.373 11.9053ZM14 18.7871C14.835 18.7871 15.4502 18.1982 15.5029 17.4863H12.4971C12.5498 18.1982 13.165 18.7871 14 18.7871Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_606_10">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default BellBadgeCircleIcon;
