import React, { FC } from 'react';

import { IconType } from '../icon.type';

const GiftcardIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_614_1970)">
          <path
            d="M6.48535 21.2744H21.5146C23.4131 21.2744 24.3975 20.29 24.3975 18.418V8.91699C24.3975 7.03613 23.4131 6.06055 21.5146 6.06055H6.48535C4.58691 6.06055 3.60254 7.03613 3.60254 8.91699V18.418C3.60254 20.2988 4.58691 21.2744 6.48535 21.2744ZM6.59082 7.7832H13.4287V10.543C12.875 9.49707 11.9434 8.84668 10.8887 8.84668C9.64941 8.84668 8.71777 9.77832 8.71777 11.0264C8.71777 12.0723 9.4209 12.9424 10.4316 13.4346H5.3252V9.06641C5.3252 8.21387 5.77344 7.7832 6.59082 7.7832ZM17.5684 13.4346C18.5791 12.9424 19.2822 12.0723 19.2822 11.0264C19.2822 9.77832 18.3506 8.84668 17.1113 8.84668C16.0566 8.84668 15.125 9.49707 14.5713 10.543V7.7832H21.4092C22.2178 7.7832 22.6748 8.21387 22.6748 9.06641V13.4346H17.5684ZM13.0332 13.3291C11.416 13.3291 10.0273 12.2041 10.0273 11.167C10.0273 10.5518 10.4053 10.1738 10.9941 10.1738C12.207 10.1738 13.2705 11.5361 13.2705 13.0918V13.3291H13.0332ZM14.9668 13.3291H14.7295V13.0918C14.7295 11.5361 15.7842 10.1738 16.9971 10.1738C17.5947 10.1738 17.9639 10.5518 17.9639 11.167C17.9639 12.2041 16.584 13.3291 14.9668 13.3291ZM21.4092 19.5518H14.5713V15.1924H14.6328C15.2041 16.915 17.1816 18.3828 18.4912 18.585C19.0361 18.6641 19.3613 18.2686 19.3613 17.8555C19.3613 17.416 19.0977 17.126 18.6143 17.0557C17.4717 16.9062 15.9248 15.4824 15.4326 14.4893H22.6748V18.2686C22.6748 19.1211 22.2178 19.5518 21.4092 19.5518ZM6.59082 19.5518C5.77344 19.5518 5.3252 19.1211 5.3252 18.2686V14.4893H12.5674C12.0752 15.4824 10.5283 16.9062 9.38574 17.0557C8.90234 17.126 8.63867 17.416 8.63867 17.8555C8.63867 18.2686 8.96387 18.6641 9.50879 18.585C10.8184 18.3828 12.7871 16.915 13.3672 15.1924H13.4287V19.5518H6.59082Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_614_1970">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default GiftcardIcon;
