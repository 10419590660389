import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ForkKnifeCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_413)">
          <path
            d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM12.1807 19.2617C12.5938 19.2617 12.8574 19.0244 12.8486 18.6289L12.7607 13.2324C12.7607 13.0479 12.8223 12.9336 12.9717 12.8633C13.6221 12.582 13.9121 12.2744 13.8682 11.4043L13.7275 8.48633C13.7188 8.25781 13.5957 8.12598 13.3936 8.12598C13.1914 8.12598 13.0684 8.2666 13.0684 8.49512L13.1123 11.334C13.1123 11.501 12.998 11.6152 12.8398 11.6152C12.6729 11.6152 12.5586 11.501 12.5586 11.3428L12.5234 8.40723C12.5234 8.19629 12.3828 8.06445 12.1807 8.06445C11.9785 8.06445 11.8467 8.19629 11.8467 8.40723L11.7939 11.3428C11.7939 11.501 11.6885 11.6152 11.5215 11.6152C11.3721 11.6152 11.2578 11.501 11.2578 11.334L11.3018 8.49512C11.3018 8.2666 11.1787 8.12598 10.9766 8.12598C10.7744 8.12598 10.6426 8.25781 10.6338 8.48633L10.502 11.4043C10.4668 12.2744 10.748 12.582 11.3984 12.8633C11.5479 12.9336 11.6094 13.0479 11.6094 13.2324L11.5215 18.6289C11.5127 19.0244 11.7764 19.2617 12.1807 19.2617ZM15.5469 15.166L15.4414 18.6113C15.4326 19.0244 15.7051 19.2617 16.1094 19.2617C16.5312 19.2617 16.7861 19.042 16.7861 18.6465V8.43359C16.7861 8.17871 16.6104 8.06445 16.4346 8.06445C16.25 8.06445 16.127 8.16113 15.9775 8.38965C15.1953 9.4707 14.668 11.5537 14.668 13.4521V13.7773C14.668 14.1201 14.791 14.3574 15.0547 14.5332L15.3008 14.709C15.4766 14.832 15.5557 14.9551 15.5469 15.166Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_413">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ForkKnifeCircleIcon;
