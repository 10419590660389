import { FetchBaseQueryError, createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  MetricTriggerBulkRequestType,
  MetricTriggerBulkResponseType,
  MetricTriggerCreateType,
  MetricTriggerObject,
  MetricTriggerRecipientsListType,
  MixedTriggerRecipientsType,
} from '@app/interfaces/metric-trigger.type';

import { baseQuery } from '../baseQuery';

import { tenantRecipientsApi } from './tenant-recipients.api';
export const metricTriggerApi = createApi({
  reducerPath: 'metricTriggerApi',
  tagTypes: ['triggers', 'onCreateRecipient'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getMetricTriggersBulk: build.query<MetricTriggerBulkResponseType, MetricTriggerBulkRequestType>(
      {
        query: (params) => ({
          url: serverRoutes.metricTriggerBulk,
          params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.project_triggers.map(({ trigger_task_id }) => ({
                  type: 'triggers' as const,
                  trigger_task_id,
                })),
                'triggers',
              ]
            : ['triggers'],
      },
    ),
    getMetricTriggerObject: build.query<MetricTriggerObject, { trigger_id: string }>({
      query: (params) => ({
        url: serverRoutes.metricTrigger,
        params,
      }),
    }),

    createMetricTrigger: build.mutation({
      query: (body: MetricTriggerCreateType) => ({
        url: serverRoutes.metricTrigger,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['triggers'],
    }),

    editMetricTrigger: build.mutation({
      query: ({
        params,
        body,
      }: {
        body: MetricTriggerCreateType;
        params: { trigger_id: string };
      }) => ({
        url: serverRoutes.metricTrigger,
        method: 'PUT',
        body,
        params,
      }),
      invalidatesTags: ['triggers'],
    }),

    deleteMetricTrigger: build.mutation({
      query: (params: { trigger_id: string }) => ({
        url: serverRoutes.metricTrigger,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['triggers'],
    }),
    //recipients
    //new recipients to tenant and trigger

    createNewEmailRecipientTrigger: build.mutation({
      query: (body: { trigger_id: string; email: string; description: string }) => ({
        url: serverRoutes.triggerEmailRecipientNew,
        method: 'POST',
        body,
      }),
      onQueryStarted(_, { dispatch }) {
        dispatch(tenantRecipientsApi.util.invalidateTags(['tenantRecipients']));
      },
    }),
    createNewTelegramRecipientTrigger: build.mutation<
      MetricTriggerRecipientsListType & { hash: string },
      { trigger_id: string; description: string }
    >({
      query: (body) => ({
        url: serverRoutes.triggerTelegramRecipientNew,
        method: 'POST',
        body,
      }),
      onQueryStarted(_, { dispatch }) {
        dispatch(tenantRecipientsApi.util.invalidateTags(['tenantRecipients']));
      },
      invalidatesTags: ['onCreateRecipient'],
    }),
    //  current trigger recipients list
    getTriggerRecipientsBulk: build.query<MixedTriggerRecipientsType, { trigger_id: string }>({
      async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
        const telegramMessages = (await fetchWithBQ({
          url: serverRoutes.triggerTelegramRecipientsBulk,
          params: arg,
        })) as unknown as { data: { telegram_recipients: MetricTriggerRecipientsListType[] } };

        const emailMessages = (await fetchWithBQ({
          url: serverRoutes.triggerEmailRecipientsBulk,
          params: arg,
        })) as unknown as { data: { email_recipients: MetricTriggerRecipientsListType[] } };

        const result: MixedTriggerRecipientsType = {
          telegram: telegramMessages.data.telegram_recipients.map((recipient) => ({
            recipient_id: recipient.recipient_id,
            trigger_recipient_id: recipient.id,
          })),
          email: emailMessages.data.email_recipients.map((recipient) => ({
            recipient_id: recipient.recipient_id,
            trigger_recipient_id: recipient.id,
          })),
        };
        return result ? { data: result } : { error: { error: '123' } as FetchBaseQueryError };
      },
    }),
    // trigger email recipients mutations
    addRecipientToEmailTrigger: build.mutation({
      query: (body: { trigger_id: string; recipient_id: string }) => ({
        url: serverRoutes.triggerEmailRecipient,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['onCreateRecipient'],
    }),
    deleteRecipientFromEmailTrigger: build.mutation({
      query: (params: { trigger_id: string; trigger_email_recipient_id: string }) => ({
        url: serverRoutes.triggerEmailRecipient,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['onCreateRecipient'],
    }),
    // trigger TG recipients mutations
    addRecipientToTgTrigger: build.mutation({
      query: (body: { trigger_id: string; recipient_id: string }) => ({
        url: serverRoutes.triggerTelegramRecipient,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['onCreateRecipient'],
    }),
    deleteRecipientFromTgTrigger: build.mutation({
      query: (params: { trigger_id: string; trigger_telegram_recipient_id: string }) => ({
        url: serverRoutes.triggerTelegramRecipient,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['onCreateRecipient'],
    }),
  }),
});

export const {
  useGetMetricTriggersBulkQuery,
  useCreateMetricTriggerMutation,
  useLazyGetMetricTriggerObjectQuery,
  useEditMetricTriggerMutation,
  useDeleteMetricTriggerMutation,
  useCreateNewEmailRecipientTriggerMutation,
  useCreateNewTelegramRecipientTriggerMutation,
  useLazyGetTriggerRecipientsBulkQuery,
  useAddRecipientToEmailTriggerMutation,
  useDeleteRecipientFromEmailTriggerMutation,
  useAddRecipientToTgTriggerMutation,
  useDeleteRecipientFromTgTriggerMutation,
} = metricTriggerApi;
