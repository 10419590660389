export const part_of_speech_data = [
  // { title: 'all', value: 'all' },
  { title: 'NOUN', value: 'NOUN' },
  { title: 'VERB', value: 'VERB' },
  { title: 'ADJ', value: 'ADJ' },
  { title: 'ADVB', value: 'ADVB' },
  { title: 'NUMR', value: 'NUMR' },
  { title: 'NPRO', value: 'NPRO' },
  { title: 'CONJ', value: 'CONJ' },
];
export const occurrenceData = [
  { title: 'occurrence', value: '0' },
  { title: 'occurrence', value: '1' },
  { title: 'occurrence', value: '2' },
  { title: 'occurrence', value: '3' },
  { title: 'occurrence', value: '4' },
  { title: 'occurrence', value: '5' },
  { title: 'occurrence', value: '10' },
  { title: 'occurrence', value: '15' },
  { title: 'occurrence', value: '20' },
  { title: 'occurrence', value: '50' },
  { title: 'occurrence', value: '100' },
];
