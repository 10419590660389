import React, { FC } from 'react';

import { IconType } from '../icon.type';

const WaveformBadgePlusIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <g clipPath="url(#clip0_610_454)">
          <path
            d="M12.4795 23.0234C12.9189 23.0234 13.2617 22.6719 13.2617 22.25V5.06738C13.2617 4.64551 12.9189 4.29395 12.4795 4.29395C12.04 4.29395 11.7061 4.64551 11.7061 5.06738V22.25C11.7061 22.6719 12.04 23.0234 12.4795 23.0234ZM17.7617 14.7881C18.166 14.1992 18.7021 13.6895 19.3086 13.3115V7.00977C19.3086 6.5791 18.9746 6.21875 18.5352 6.21875C18.0869 6.21875 17.7617 6.5791 17.7617 7.00977V14.7881ZM9.45605 19.6045C9.89551 19.6045 10.2383 19.2529 10.2383 18.8311V8.48633C10.2383 8.05566 9.89551 7.7041 9.45605 7.7041C9.0166 7.7041 8.68262 8.05566 8.68262 8.48633V18.8311C8.68262 19.2529 9.0166 19.6045 9.45605 19.6045ZM15.5117 18.4619C15.9424 18.4619 16.2852 18.1191 16.2852 17.6973V9.62012C16.2852 9.18945 15.9424 8.83789 15.5117 8.83789C15.0635 8.83789 14.7295 9.18945 14.7295 9.62012V17.6973C14.7295 18.1191 15.0635 18.4619 15.5117 18.4619ZM20.7852 12.6875C21.2773 12.5381 21.7959 12.4502 22.3408 12.4502V11.3428C22.3408 10.9121 21.998 10.5518 21.5586 10.5518C21.1191 10.5518 20.7852 10.9121 20.7852 11.3428V12.6875ZM6.42383 16.0625C6.87207 16.0625 7.21484 15.7109 7.21484 15.2803V12.0371C7.21484 11.6064 6.87207 11.2461 6.42383 11.2461C5.99316 11.2461 5.65918 11.6064 5.65918 12.0371V15.2803C5.65918 15.7109 5.99316 16.0625 6.42383 16.0625ZM22.3408 22.7422C24.8018 22.7422 26.8672 20.6943 26.8672 18.2158C26.8672 15.7373 24.8281 13.6982 22.3408 13.6982C19.8623 13.6982 17.8232 15.7461 17.8232 18.2158C17.8232 20.7031 19.8623 22.7422 22.3408 22.7422ZM22.3408 21.0898C21.9805 21.0898 21.7432 20.8438 21.7432 20.4922V18.8223H20.082C19.7217 18.8223 19.4844 18.585 19.4844 18.2246C19.4756 17.8555 19.7217 17.627 20.082 17.627H21.7432V15.957C21.7432 15.6055 21.9805 15.3594 22.3408 15.3594C22.71 15.3594 22.9473 15.5967 22.9473 15.957V17.627H24.6084C24.9688 17.627 25.2061 17.8555 25.2061 18.2246C25.2061 18.585 24.9688 18.8223 24.6084 18.8223H22.9473V20.4922C22.9473 20.8438 22.71 21.0898 22.3408 21.0898Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_610_454">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default WaveformBadgePlusIcon;
