import {
  deleteMetric,
  getAllChannels,
  getAllTypesOfMetric,
  getMetricList,
  getSingleMetricInfo,
} from '@app/api/analytics-api/analytics-metric.api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { httpRequest } from '@app/store/middleware/rest';
import serverRoutes from '@app/utils/server-routes';
import {
  AllChanelsArrType,
  MetricCasesWords,
  MetricListArrType,
  MetricObjType,
  MetricRecordDataResult,
  MetricTypesArrType,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import { GetFilterMetricResultParams } from '@app/interfaces/filter';
import { getMetricsBulk } from '@app/api/analytics-api/analytics-records.api';
import { CalculatedMetricData, MetricList, MetricsBulkItems } from '@app/interfaces/metric.type';

type MetricSliceState = {
  metricTypes: MetricTypesArrType[];
  metricList: MetricListArrType[];
  allChanels: AllChanelsArrType[];
  metricRecordData: MetricRecordDataResult<MetricCasesWords> | undefined;
  metricSettings: MetricObjType | null;
  metricsItems: MetricsBulkItems;
  totalMetricsItems: number;
  isPending: boolean;
  error: string | null;
};
const initialState: MetricSliceState = {
  metricTypes: [],
  metricList: [],
  allChanels: [],
  metricRecordData: undefined,
  metricSettings: null,
  metricsItems: {
    headers: [],
    metrics: [],
  },
  totalMetricsItems: 0,
  isPending: false,
  error: null,
};
export const getMetricThunks = {
  getAllTypesOfMetricThunk: createAsyncThunk(
    'MetricSlice/getAllTypesOfMetric',
    async (_, { rejectWithValue }) => {
      try {
        const response = await getAllTypesOfMetric();
        return response.data;
      } catch (err) {
        return rejectWithValue(`${err.response.status}`);
      }
    },
  ),
  getMetricList: createAsyncThunk(
    'MetricSlice/getMetricList',
    async (id: string, { rejectWithValue }) => {
      try {
        const response = await getMetricList(id);
        return response.data;
      } catch (err) {
        return rejectWithValue(`${err.response.status}`);
      }
    },
  ),
  getMetricSettings: createAsyncThunk(
    'MetricSlice/getMetricSettings',
    async (
      { project_id, metric_id }: { project_id: string; metric_id: string },
      { rejectWithValue },
    ) => {
      try {
        const response = await getSingleMetricInfo(project_id, metric_id);
        return response.data;
      } catch (err) {
        return rejectWithValue(`${err.response.status}`);
      }
    },
  ),
  getAllChannels: createAsyncThunk('MetricSlice/getAllChannels', async (_, { rejectWithValue }) => {
    try {
      const response = await getAllChannels();
      return response.data;
    } catch (err) {
      return rejectWithValue(`${err.response.status}`);
    }
  }),
  getMetricsBulk: createAsyncThunk<
    MetricsBulkItems & { total: number },
    GetFilterMetricResultParams
  >('MetricsSlice/getMetricsBulk', async (data, { rejectWithValue }) => {
    return await getMetricsBulk(data)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.status + ''));
  }),
  deleteMetric: createAsyncThunk<unknown, { project_id: string; metric_id: string }>(
    'MetricsSlice/deleteMetric',
    async (data, { rejectWithValue }) => {
      return await deleteMetric(data)
        .then((response) => response.data)
        .catch((error) => rejectWithValue(error.response.status + ''));
    },
  ),
};

const metricSlice = createSlice({
  name: 'MetricSlice',
  initialState,
  reducers: {
    metricSettingsReset: (state) => {
      state.metricSettings = null;
    },
    createMetricResponse: (state, { payload }: { payload: MetricListArrType }) => {
      state.metricList = [...state.metricList, payload];
    },
    metricListResponse: (state, { payload }: { payload: Array<MetricList> }) => {
      state.metricList = payload;
    },
    metricsResponse: (state, { payload }) => {
      state.metricsItems = payload;
    },
    metricsPending: (state, { payload }) => {
      state.isPending = payload;
    },
    metricsErrors: (state, { payload }) => {
      state.error = payload;
    },
    metricRecordResponse: (
      state,
      { payload }: { payload: MetricRecordDataResult<MetricCasesWords> },
    ) => {
      state.metricRecordData = payload;
    },
    metricEditResponse: (state, { payload }) => {
      console.log(payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMetricThunks.getAllTypesOfMetricThunk.fulfilled, (state, action) => {
      state.metricTypes = action.payload;
    });
    builder.addCase(getMetricThunks.getMetricList.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getMetricThunks.getMetricList.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(getMetricThunks.getMetricList.fulfilled, (state, action) => {
      state.metricList = action.payload;
      state.isPending = false;
    });
    builder.addCase(getMetricThunks.getAllChannels.fulfilled, (state, action) => {
      state.allChanels = action.payload;
    });
    builder.addCase(getMetricThunks.getMetricSettings.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getMetricThunks.getMetricSettings.fulfilled, (state, action) => {
      state.metricSettings = action.payload;
      state.isPending = false;
    });
    builder.addCase(getMetricThunks.getMetricsBulk.fulfilled, (state, action) => {
      state.metricsItems = {
        headers: action.payload.headers,
        metrics: action.payload.metrics,
      };
      state.totalMetricsItems = action.payload.total;
    });
  },
});

export const {
  createMetricResponse,
  metricsResponse,
  metricsPending,
  metricsErrors,
  metricListResponse,
  metricRecordResponse,
  metricEditResponse,
  metricSettingsReset,
} = metricSlice.actions;

export default metricSlice.reducer;

export const metricRequest = (data: GetFilterMetricResultParams) =>
  httpRequest({
    url: serverRoutes.metrics,
    method: 'POST',
    data,
    params: {
      project_id: data.project_id,
    },
    onSuccess: metricsResponse.type,
    onLoading: metricsPending.type,
    onError: metricsErrors.type,
  });

export type MetricListRequestParams = {
  project_id: string;
};

export const metricListRequest = (params: MetricListRequestParams) =>
  httpRequest({
    url: serverRoutes.metrics,
    method: 'GET',
    params,
    onSuccess: metricsResponse.type,
    onError: metricsErrors.type,
    onLoading: metricsPending.type,
  });

export type CreateMetricRequestType = {
  params: { project_id: string };
  data: { metric_settings: CalculatedMetricData };
};
export const createMetricRequest = ({ params, data }: CreateMetricRequestType) =>
  httpRequest({
    url: `${serverRoutes.projectMetric}`,
    method: 'POST',
    params,
    data: data.metric_settings,
    onSuccess: createMetricResponse.type,
    onError: metricsErrors.type,
  });

export const projectMetricListRequest = (params: MetricListRequestParams) =>
  httpRequest({
    url: serverRoutes.metric,
    method: 'GET',
    params,
    onSuccess: metricListResponse.type,
    onError: metricsErrors.type,
    onLoading: metricsPending.type,
  });

export type RecordMetricListRequestType = {
  project_id: string;
  record_id: string;
};

export const recordMetricListRequest = (params: RecordMetricListRequestType) =>
  httpRequest({
    url: serverRoutes.projectMetricResult,
    method: 'GET',
    params,
    onSuccess: metricRecordResponse.type,
    onError: metricsErrors.type,
    onLoading: metricsPending.type,
  });

export type EditMetricRequestData = {
  params: { metric_id: string; project_id: string };
  data: {
    settings: unknown;
  };
};

export const editMetricRequest = ({ params, data }: EditMetricRequestData) =>
  httpRequest({
    url: serverRoutes.metricProject,
    method: 'PUT',
    params,
    data,
    onSuccess: metricEditResponse.type,
    onError: metricsErrors.type,
  });
